<mat-form-field appearance="standard">
    <mat-label class="textarea">CUPS</mat-label>
    <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="9"
    ></textarea>
</mat-form-field>
