<div class="sidebar-wrapper" [ngClass]="{ 'sidebar-wrapper__mini': !opened }">
    <!-- establece el ancho del sidebar <OK que cambiará al pulsar el botón -->
    <div class="logo-container" [ngClass]="{ 'logo-container__mini': !opened }">
        <img src="assets/img/acciona-transparent.png" alt="Logo Acciona" class="logo" routerLink="home" />
        <app-base-icon
            iconName="chevron_left"
            (click)="toggleSidenav()"
            [size]="30"
            *ngIf="opened"
            class="open_menu"
        ></app-base-icon>
        <app-base-icon
            iconName="chevron_right"
            (click)="toggleSidenav()"
            *ngIf="!opened"
            class="open_menu"
        ></app-base-icon>
    </div>
    <div class="d-flex flex-column flex-shrink-0 p-3 sidebar-content">
        <ul class="subitem-desplegable">
            <li *ngFor="let item of itemsMenu" class="nav-item">
                <app-sidebar-menu-item [item]="item" [opened]="opened" [hierarchicalLevel]="0"> </app-sidebar-menu-item>
            </li>
        </ul>
    </div>
</div>
