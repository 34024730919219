<div class="margin-left">
    <div>
        <mat-label>{{ title }}</mat-label>
    </div>
    <div class="align_toggle">
        <mat-label>{{ 'PAGE.MODALS.NO' | translate }}</mat-label>
        <mat-slide-toggle
            [disabled]="disableToggle"
            style="margin: 0px 10px"
            (click)="activeDefaultSelec()"
        ></mat-slide-toggle>
        <mat-label>{{ 'PAGE.MODALS.YES' | translate }}</mat-label>
    </div>
</div>
