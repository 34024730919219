<div class="container" style="justify-content: flex-start" *ngIf="!isOnlyQueryRole">
    <app-spinner></app-spinner>
    <!-- FILTRO SELECT DE CONCEPTOS -->
    <div class="filter" *ngIf="enableFilterConcept">
        <mat-form-field appearance="standard">
            <mat-label class="concept">
                {{ 'PAGE.ACTIONS.CONCEPT' | translate }}
            </mat-label>
            <mat-select
                [disabled]="this.maestroService.selectId === 'procesosProgramar'"
                [value]="value?.name"
                (selectionChange)="getSubfilters($event.value)"
                (selectionChange)="getSelectId($event.value)"
                (selectionChange)="resetFilters()"
            >
                <mat-option *ngFor="let concepto of filteredProcessConceptsList" [value]="concepto.name">
                    {{ concepto.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <!-- SUBFILTROS -->
    <div class="subfilter" *ngFor="let filtro of subfilters; let i = index">
        <app-filtro-date
            *ngIf="filtro.type === 'Date' && showSelect && !disableFilter"
            [isDisabled]="toggleActive && resetSelectedOption.nameStepFunction !== 'calculateAggregatedForecast'"
            [name]="filtro.name"
            (select)="restartValue($event, i, filtro.key, filtro.type)"
            (select)="receiveDate($event, i)"
        ></app-filtro-date>
        <app-filtro-input
            *ngIf="filtro.type === 'Input' && showSelect"
            [name]="filtro.name"
            (select)="restartValue($event, i, filtro.key, filtro.type)"
        ></app-filtro-input>
        <app-filtro-textarea
            *ngIf="filtro.type === 'Textarea' && showSelect"
            (select)="restartValue($event, i, filtro.key, filtro.type)"
        ></app-filtro-textarea>
        <app-filtro-cups
            *ngIf="filtro.type === 'cups' && showSelect"
            [disabledCUPS]="
                (toggleActive && resetSelectedOption.nameStepFunction !== 'calculateAggregatedForecast') ||
                checkFilterEnablement(filtro.key) ||
                (cuentaNegociadoraActive && (processCodePOST === 'PR0003' || processCodePOST === 'PR0002'))
            "
            [filtroUrl]="filtro.data"
            [name]="filtro.name"
            [reset]="resetCups"
            (resetComplete)="resetComplete($event)"
            (select)="restartValue($event, i, filtro.key, filtro.type)"
            (reactivateCUPs)="reactivateCUPs()"
        ></app-filtro-cups>
        <app-filtro-toggle
            *ngIf="filtro.type === 'toggle' && showSelect && filtro.key !== 'deleteModified'"
            [disableToggle]="
                checkFilterEnablement(filtro.key) ||
                (cuentaNegociadoraActive && (processCodePOST === 'PR0003' || processCodePOST === 'PR0002'))
            "
            [title]="titleToggle | translate"
            (activeToggle)="activeToggle()"
        ></app-filtro-toggle>
        <ng-container *ngIf="filtro.key === 'accountId'">
            <app-filtro-select
                [disableSelect]="checkFilterEnablement(filtro.key)"
                [toggleLabelLeft]="filtro.isToggle?.leftLabel"
                [toggleLabelRight]="filtro.isToggle?.rightLabel"
                [charactersControl]="filtro.isSearchable?.specialSearch"
                [searchByToggle]="filtro.isToggle?.active"
                [isSearchable]="filtro.isSearchable?.active"
                [filtroUrl]="filtro.data"
                [title]="filtro.name"
                [key]="filtro.key"
                [id]="filtro.id"
                (valueChange)="onValueChangeGetOption($event)"
                (searchBy)="getFiltersBy($event)"
                (select)="restartValue($event, i, filtro.key, filtro.type)"
                (select)="receiveDate($event, i, filtro)"
                (openSelect)="byOpenSelect($event)"
                (openSelect)="receiveDate2($event)"
                (specialSearch)="specialSearch($event)"
            ></app-filtro-select>
        </ng-container>
        <ng-container *ngIf="filtro.key !== 'accountId'">
            <app-filtro-select
                *ngIf="
                    filtro.type === 'Select' &&
                    showSelect &&
                    filtro.name !== 'Unidad de Programa' &&
                    filtro.name !== 'Sistema eléctrico' &&
                    filtro.name !== 'Cuenta Negociadora' &&
                    filtro.name !== 'Tarifas'
                "
                [disableSelect]="
                    checkFilterEnablement(filtro.key) ||
                    (cuentaNegociadoraActive && (processCodePOST === 'PR0003' || processCodePOST === 'PR0002'))
                "
                [toggleLabelLeft]="filtro.isToggle?.leftLabel"
                [toggleLabelRight]="filtro.isToggle?.rightLabel"
                [charactersControl]="filtro.isSearchable?.specialSearch"
                [searchByToggle]="filtro.isToggle?.active"
                [isSearchable]="filtro.isSearchable?.active"
                [filtroUrl]="filtro.data"
                [title]="filtro.name"
                [key]="filtro.key"
                [id]="filtro.id"
                (valueChange)="onValueChangeGetOption($event)"
                (searchBy)="getFiltersBy($event)"
                (select)="restartValue($event, i, filtro.key, filtro.type)"
                (select)="receiveDate($event, i)"
                (openSelect)="byOpenSelect($event)"
                (openSelect)="receiveDate2($event)"
                (specialSearch)="specialSearch($event)"
            ></app-filtro-select>
        </ng-container>
        <div *ngIf="filtro.type === 'Select' && showSelect && filtro.name === 'Unidad de Programa'">
            <mat-form-field appearance="standard" class="select">
                <mat-label>{{ 'PAGE.FILTERS.PROGRAM_UNIT' | translate }}</mat-label>
                <mat-select
                    [formControl]="unitPrograms"
                    [disabled]="
                        checkFilterEnablement(filtro.key) ||
                        (cuentaNegociadoraActive && (processCodePOST === 'PR0003' || processCodePOST === 'PR0002'))
                    "
                    (selectionChange)="onChangeProgramUnit($event)"
                    multiple
                >
                    <mat-option *ngFor="let option of unitProgramList" [value]="option">
                        {{ option.programUnitCode ? option.programUnitCode : option }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="filtro.type === 'Select' && showSelect && filtro.name === 'Tarifas'">
            <mat-form-field appearance="standard" class="select">
                <mat-label>{{ 'PAGE.FILTERS.RATES' | translate }}</mat-label>
                <mat-select
                    [disabled]="
                        checkFilterEnablement(filtro.key) ||
                        (cuentaNegociadoraActive && (processCodePOST === 'PR0003' || processCodePOST === 'PR0002'))
                    "
                >
                    <mat-option *ngFor="let option of rateList" [value]="option" (click)="obtainRate(option)">
                        {{ option }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="filtro.type === 'Select' && showSelect && filtro.name === 'Sistema eléctrico'">
            <mat-form-field appearance="standard" class="select">
                <mat-label>{{ 'PAGE.FILTERS.ELECTRIC_SYSTEM' | translate }}</mat-label>
                <mat-select
                    #selectElectricSystem
                    [disabled]="checkFilterEnablement(filtro.key)"
                    (selectionChange)="obtainSelectedOptionElectrictSystem($event)"
                >
                    <mat-option
                        *ngFor="let option of electricSystemList"
                        [value]="option"
                        (click)="filterUnitProgram(option)"
                    >
                        {{ option }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div [ngClass]="{ months: monthSelected === false, monthsSelected: monthSelected }">
            <div *ngIf="filtro.type === 'SelectMonth' && !disableMonthFilter" [formGroup]="formGroup">
                <mat-form-field appearance="fill">
                    <mat-label>{{
                        filtro.required ? filtro.name : ('PAGE.FILTERS.SELECT.MONTH' | translate)
                    }}</mat-label>
                    <mat-select
                        #matSelectMonth
                        formControlName="month"
                        (selectionChange)="restartValue($event, 0, filtro.key, filtro.type)"
                    >
                        <mat-option *ngFor="let month of months" [value]="month">
                            {{ monthDisplay(month) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div [ngClass]="{ year: yearSelected === false, yearsSelected: yearSelected }">
            <div *ngIf="filtro.type === 'SelectYear' && !disableYearFilter" [formGroup]="formGroup">
                <mat-form-field appearance="fill">
                    <mat-label>{{
                        filtro.required ? filtro.name : ('PAGE.FILTERS.SELECT.YEAR' | translate)
                    }}</mat-label>
                    <mat-select
                        formControlName="year"
                        (selectionChange)="restartValue($event, 1, filtro.key, filtro.type)"
                    >
                        <mat-option *ngFor="let year of generateYears(filtro.key)" [value]="year">
                            {{ year }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <app-filtro-toggle
            [title]="filtro.name"
            *ngIf="filtro.key === 'deleteModified'"
            (activeToggle)="activeToggleDeleteRegisters()"
        >
        </app-filtro-toggle>
    </div>
</div>
<div *ngIf="isOnlyQueryRole">
    <h3 class="invalidRole">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
</div>
<div *ngIf="this.maestroService.selectId !== 'procesosProgramar'" class="buttonsFilter">
    <div class="buttonsActionsDisplay"></div>
    <div class="buttonsFilterNoMargin">
        <button class="delete" (click)="resetFilters()" (mouseup)="hideSelect()" mat-button>
            {{ 'PAGE.ACTIONS.DELETE' | translate }}
            <app-base-icon iconName="filter_alt_off" class=""></app-base-icon>
        </button>
        <button
            class="search"
            [disabled]="isDisabled"
            (click)="loadTable(); loadNotification()"
            mat-flat-button
            color="warn"
        >
            {{ 'PAGE.ACTIONS.EXECUTION' | translate | uppercase }}
            <app-base-icon iconName="settings" class=""></app-base-icon>
        </button>
    </div>
</div>
