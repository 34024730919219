export enum KeyReports {
    SOCIEDAD_ACCIONA = 'issuerCompany',
    CUENTA_NEGOCIADORA = 'accountId',
    SOCIEDAD = 'society',
    CUPS = 'cups',
    MES = 'month',
    AÑO = 'year',
    TIPO_MEDIDA = 'measureType',
    ORIGEN = 'origin',
    TIPO_DE_CIERRE = 'closeOrigin',
    TIPO_LIQUIDACION = 'liquidationType',
    TIPO_DE_NEGOCIO = 'clientType',
    TARIFAS = 'rate',
    PERDIDAS = 'losses',
    AMBITO_MEDIDA = 'scope',
    TIPO_PUNTO_MEDIDA = 'measurePointType',
    RANGO_FECHAS = 'rango',
    RANGO_EMISION = 'rangoEmision',
    RANGO_FACTURACION = 'rangoFacturacion',
    RANGO_CREACION = 'rangoCreacion',
    FECHA = 'dateOnly',
    MAGNITUD = 'magnitude',
    ORIGEN_DE_DATOS = 'forecastType',
    TIPO_DE_INFORME = 'type',
    ID_TIPO_INFORME = '1205',
    RESULTADOS = 'results',
    TRATADA = 'treated',
    FACTURA = 'invoiceFiscalCode',
    IMPORTE_TOTAL = 'totalImport',
    TIPO_FACTURA = 'invoiceType',
    AYUNTAMIENTO = 'council',
    DISTRIBUIDORA = 'distributor',
    CODIGO = 'code',
    LIQUIDACION = 'liquidation',
    TIPO_AUTOCONSUMO = 'selfConsumptionType'
}

export enum Reports {
    PREVISIONES_AGREGADO = 'informes_previsiones_agregado',
    PREVISIONES_HORARIAS = 'informes_previsiones_horarias',
    CHECK_CIERRES = 'informes_check_cierres',
    CHECK_MEDIDAS = 'informes_check_medidas',
    MEDIDAS_AGRUPADAS = 'informes_medidas_agrupadas',
    CONSUMO_REAL = 'informes_consumo_real',
    ANALISIS_RESULTADOS = 'informes_analisis_resultados',
    PUNTO_MEDIDA = 'informes_punto_medida',
    CIERRES = 'informes_cierres',
    MEDIDA_HORARIA = 'informes_medida_horaria',
    INVENTARIO_PUNTOS = 'informes_inventario_puntos',
    OBJECIONES = 'informes_objeciones',
    CALCULATE_OBJECTIONS_DISTRIBUIDORA = 'informes_calculate_objections_distribuidora',
    CALCULATE_OBJECTIONS_POTENTIAL = 'informes_calculate_objections_potential',
    CALIDAD = 'informes_calidad',
    PRECIOS_INDEXADOS = 'informes_precios_indexados',
    ATR = 'informes_atr',
    RESUMEN_ATR = 'informes_resumen_atr',
    CONCEPTOS_REPERCUTIBLES_ATR = 'informes_conceptos_repercutibles_atr',
    CONSUMOS_ATR_DISTRIBUIDORA = 'informes_consumos_atr_por_distribuidora',
}

export enum ReportArea {
    MEDIDAS = 'Medidas',
    PREVISIONES = 'Previsiones',
    CONCILIACION = 'Conciliacion',
    DATOS_MAESTROS = 'Datos_maestros',
}

export enum ReportStatus {
    SAVED = 'saved',
    IN_PROGRESS = 'In Progress',
    ERR_NO_DATA = 'ERR_NO_DATA',
    ERROR = 'ERROR'
}
