import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { Filtro, ListaFiltros, ConceptCategory } from 'src/app/shared/models/filtros.interface';
import { CuadroMandosService } from '../../services/cuadro-mandos.service';
import { MonthListEnum } from '../../../../shared/enums/monthListEnum';
import { MaestroService } from 'src/app/pages/maestro/services/maestro.service';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DashboardSections, DashboardFilterIds } from 'src/app/shared/enums/dashboard.enum';

@Component({
    selector: 'app-select-cuadro-mandos',
    templateUrl: './select-cuadro-mandos.component.html',
    styleUrls: ['./select-cuadro-mandos.component.scss'],
})
export class SelectCuadroMandosComponent implements OnInit {
    @Output() onButtonColumn: EventEmitter<any> = new EventEmitter();
    @Output() optionSelectedId = new EventEmitter<string>();
    @Output() resetTable = new EventEmitter<boolean>();
    @Output() launchReport: EventEmitter<any> = new EventEmitter();

    filters: ListaFiltros[] = [];
    subfilters: Filtro[] | undefined;
    showSelect: boolean = true;
    inputValues: { id: string; value: string }[] = [];
    selectId: any;
    searchBy: boolean = this.maestroService.searchBy;
    isFilterDisabled: boolean = true;
    resetCups: boolean;
    subcriptions: Subscription[] = [];
    cupsInstNameList: any;
    bankSearchCtrl: FormControl = new FormControl();
    showColumns: boolean = false;
    placeholder$ = this._translateService.get('PAGE.MASTER.MASTER_CRM.MESSAGES.MESSAGE_PLACEHOLDER_SPECIAL_SELECTS');
    negotiatingAccountDescription: any;
    clientType: any = 'clientType=(1;2;3;4;5;6)';
    instName: any;
    cupFilter: any;
    month: string = '';
    year: any = '';
    defaultYearValue: string = '';
    discrepancia: any;
    arraydefiltros: any;
    buttonsColumns = [
        { id: DashboardSections.SUMMARY, name: 'Resumen', isEnabled: true },
        { id: DashboardSections.TECHNICAL_INFO, name: 'Info Técnica', isEnabled: false },
        { id: DashboardSections.CHECK, name: 'Check', isEnabled: false },
        { id: DashboardSections.MEASURES, name: 'Curvas', isEnabled: false },
        { id: DashboardSections.CLOSES, name: 'Cierres', isEnabled: false },
        { id: DashboardSections.BEST_MEASURE_CLOSE, name: 'Mejor Curva/Cierre', isEnabled: false },
        { id: DashboardSections.SETTLEMENT, name: 'Liquidación/Facturación', isEnabled: false },
    ];

    constructor(
        private cuadroMandosService: CuadroMandosService,
        private globalService: GlobalService,
        public maestroService: MaestroService,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.maestroService.validCups = undefined;
        this.buttonsColumns[0].isEnabled = true;
        this.resetCups = false;
        let filtersList = sessionStorage.getItem('filters');
        if (filtersList) {
            this.filters = JSON.parse(filtersList);
            this.subfilters = this.filters.filter(
                (filtro: ListaFiltros) => filtro.name === ConceptCategory.CUADRO_MANDOS
            )[0].filters;
            this.subfilters = this.subfilters.map((filtro: any) => {
                if (filtro.required === true) {
                    filtro.name += ' *';
                }
                return filtro;
            });
        } else {
            this.globalService.getFilters().subscribe((filtersResponse) => {
                this.subfilters = filtersResponse.data.filter(
                    (filtro: ListaFiltros) => filtro.name === ConceptCategory.CUADRO_MANDOS
                )[0].filters;
                this.subfilters = this.subfilters.map((filtro: any) => {
                    if (filtro.required === true) {
                        filtro.name += ' *';
                    }
                    return filtro;
                });
            });
        }
    }

    ngOnChange(): void {
        this.resetCups = false;
    }

    onChangeSaveSelectedOptionAndCheckOptions(event: { id: any; value: any }) {
        if (event && event.id) {
            switch (event.id) {
                case DashboardFilterIds.MONTH_FILTER_ID:
                    this.month =
                        Number(MonthListEnum[event.value]) < 10
                            ? `0${MonthListEnum[event.value]}`
                            : `${MonthListEnum[event.value]}`;
                    break;
                case DashboardFilterIds.YEAR_FILTER_ID:
                    this.year = event.value.split('=');
                    this.year = this.year[1];
                    break;
                case DashboardFilterIds.NEGOTIATING_ACCOUNT_ID:
                    this.negotiatingAccountDescription = event.value.split('=');
                    this.negotiatingAccountDescription =
                        'negotiatingAccountDescription=' + this.negotiatingAccountDescription[1];
                    break;
                case DashboardFilterIds.INSTALLATION_ID:
                    this.instName = event.value.split('=');
                    this.instName = 'instName=' + this.instName[1];
                    break;
                case DashboardFilterIds.CLIENT_TYPE_ID:
                    this.clientType = event.value.split('=');
                    this.clientType = this.clientType[1];
                    if (this.clientType === 'Pymes') {
                        this.clientType = 'clientType=6';
                    } else if (this.clientType === 'Grandes cuentas') {
                        this.clientType = 'clientType=(1;2;3;4;5)';
                    } else {
                        this.clientType = 'clientType=(1;2;3;4;5;6)';
                    }
                    break;
                case DashboardFilterIds.MEASURE_DISCREPANCY_ID:
                    const valueSelected: string = event.value.split('=')[1];
                    if (valueSelected === 'Sí') {
                        this.discrepancia = 'bestDefinitiveMeasureVsReeStatus=true,';
                    } else if (valueSelected === 'No') {
                        this.discrepancia = 'bestDefinitiveMeasureVsReeStatus=false,';
                    }
                    break;
            }
        }
        this.checkFilterAndReportVisibility();

        if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }
    }

    checkFilterAndReportVisibility(): void {
        if (this.year && this.month) {
            if (
                this.negotiatingAccountDescription ||
                this.instName ||
                this.maestroService.validCups ||
                this.clientType !== 'clientType=(1;2;3;4;5;6)' ||
                this.discrepancia
            ) {
                this.isFilterDisabled = false;
            } else {
                this.isFilterDisabled = false;
            }
        } else {
            this.isFilterDisabled = true;
        }
    }

    onClickLoadTable() {
        this.buttonsColumns.forEach((column) => {
            if (column.id === DashboardSections.SUMMARY) {
                column.isEnabled = true;
            } else {
                column.isEnabled = false;
            }
        });
        this.cuadroMandosService.activeColumn = DashboardSections.SUMMARY;
        this.arraydefiltros = [];
        let yearFilter: any;
        yearFilter =
            'closeDateBegin>=' +
            this.year +
            '-' +
            this.month +
            '-01,closeDateEnd<=' +
            this.year +
            '-' +
            this.month + '-' 
            + this._getMonthDays();
        if (this.maestroService.validCups !== undefined) {
            this.maestroService.validCups = this.maestroService.validCups
                .split(';')
                .filter((cup: any) => cup !== 'undefined')
                .toString()
                .replace(/,/g, ';');
            this.cupFilter = 'cups=(' + this.maestroService.validCups + ')';
        }

        this.arraydefiltros.push(
            this.negotiatingAccountDescription,
            this.clientType,
            this.cupFilter,
            this.instName,
            yearFilter,
            this.discrepancia
        );
        let selectFilter: any = [this.selectId, this.subfilters, this.arraydefiltros];
        if (this.subfilters !== undefined) {
            this.optionSelectedId.emit(selectFilter);
        } else {
            this.optionSelectedId.emit(this.selectId);
        }
        this.showColumns = true;
    }

    onSelectCheckSearchType(event: any): void {
        if (event.newValue === true) {
            let subfilter = this.subfilters?.find((f: any) => f.key === 'accountId');
            if (subfilter) {
                subfilter.data = [];
            }
        }
    }

    onClickChangeDisplayColumn(selectedColumnId: any) {
        /** Recorremos las columnnas. Siempre hay una seleccionada.
         *  Si la columna seleccionada ya estaba marcada, no hacemos nada.
         *  Si la columna seleccionada es distinta:
         *      1. desmarcamos la anterior.
         *      2. Marcamos la nueva y lanzamos la petición.
         */
        this.buttonsColumns.forEach((column) => {
            if (column.id === selectedColumnId) {
                if (!column.isEnabled) {
                    column.isEnabled = true;
                    this.cuadroMandosService.activeColumn = selectedColumnId;
                    this.onButtonColumn.emit(selectedColumnId);
                }
            } else {
                column.isEnabled = false;
            }
        });
    }

    launchInstallationsSpecialSearch(event: any) {
        let subfilter = this.subfilters?.find((f: any) => f.key === 'instName');
        if (event.key === 'instName') {
            subfilter!.data = [];
            this.cuadroMandosService.getSelectInstallations(event.search).subscribe((code) => {
                code.data.forEach((element: any) => {
                    subfilter!.data.push({ name: element.instName });
                });
            });
        }
    }

    launchNegotiatingAccountSpecialSearch(event: any) {
        let subfilter = this.subfilters?.find((f: any) => f.key === 'accountId');
        this.maestroService
            .getNegotiatingAccounts({
                search: event.search,
                mode: subfilter!.toggleState ? 'negotiatingAccountCode' : 'negotiatingAccountDescription',
            })
            .subscribe((neAccounts) => {
                subfilter!.data = [];
                neAccounts.data.forEach((element: any) => {
                    if (subfilter && subfilter.toggleState === true) {
                        subfilter.data.push({
                            name: element.negotiatingAccountCode,
                            value: element.negotiatingAccountCode,
                        });
                    } else if (subfilter) {
                        subfilter.data.push({
                            name: element.negotiatingAccountDescription,
                            value: element.negotiatingAccountCode,
                        });
                    }
                });
            });
    }

    onClickResetFilters() {
        this.showSelect = true;
        this.resetTable.emit(true);
        this.negotiatingAccountDescription = undefined;
        this.clientType = 'clientType=(1;2;3;4;5;6)';
        this.cupFilter = undefined;
        this.instName = undefined;
        this.discrepancia = undefined;
        this.maestroService.validCups = undefined;
        this.isFilterDisabled = true;
        this.showColumns = false;
        this.year = '';
        this.month = '';
        this.inputValues = [];
        this.buttonsColumns.forEach((column) => {
            if (column.id === DashboardSections.SUMMARY) {
                column.isEnabled = true;
            } else {
                column.isEnabled = false;
            }
        });
    }

    onClickLaunchReport() {
        if (this.maestroService.validCups !== undefined) {
            this.maestroService.validCups = this.maestroService.validCups
                .split(';')
                .filter((cup: any) => cup !== 'undefined')
                .toString()
                .replace(/,/g, ';');
            this.cupFilter = '(' + this.maestroService.validCups + ')';
        }
        this.clientType = this.clientType.includes('=') ? this.clientType.split('=')[1] : this.clientType;
        this.clientType = !this.clientType.includes('(') ? `(${this.clientType})` : this.clientType;
        this.launchReport.emit({
            year: this.year,
            month: this.month,
            clientType: this.clientType !== undefined ? this.clientType : '',
            cups: this.cupFilter !== undefined ? this.cupFilter : '',
            instalation: this.instName !== undefined ? this.instName.split('=')[1] : '',
            accountId:
                this.negotiatingAccountDescription !== undefined
                    ? this.negotiatingAccountDescription.split('=')[1]
                    : '',
            bestDefinitiveMeasureVsReeStatus: this.discrepancia !== undefined ? this.discrepancia.split('=')[1] : '',
        });
    }

    onMouseUpHideSelect() {
        this.showSelect = false;
    }

    private _getMonthDays() {
        let days:string = '';
        switch (this.month) {
            case '01':
            case '03':
            case '05':
            case '07':
            case '08':
            case '10':
            case '12':
                days = '31';
                break;
            case '02':
                if (parseInt(this.year) % 4 == 0) {
                    days = '29';
                } else {
                    days = '28';
                }
                break;
            default:
                days = '30';
                break;
        }
        return days;
    }
}
