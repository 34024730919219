<div *ngIf="validRole">
    <div class="container">
        <div class="subfilter" *ngFor="let filtro of subfilters; let i = index">
            <app-filtro-date
                *ngIf="filtro.type === 'Date' && showSelect"
                [name]="filtro.name"
                [page]="'forecastsAggregation'"
                (select)="receiveDate($event, i)"
                (invalidDate)="invalidDate($event)"
            >
            </app-filtro-date>

            <app-filtro-select
                #selects
                *ngIf="
                    filtro.type === 'Select' &&
                    checkConditions(filtro.displayConditions) &&
                    showSelect &&
                    filtro.key !== 'electricSystem' &&
                    filtro.key !== 'programUnitCode'
                "
                [toggleLabelLeft]="filtro.isToggle?.leftLabel"
                [toggleLabelRight]="filtro.isToggle?.rightLabel"
                (searchBy)="getFiltersBy($event)"
                [charactersControl]="filtro.isSearchable?.specialSearch"
                [searchByToggle]="filtro.isToggle?.active"
                [isSearchable]="filtro.isSearchable?.active"
                [filtroUrl]="filtro.data"
                [title]="filtro.name"
                [key]="filtro.key"
                [id]="filtro.id"
                (valueChange)="obtainOption($event)"
                (select)="restarValue($event, i, filtro.key, filtro.type)"
                (selectSelected)="checkValueSelects($event)"
                [specialFeeActivated]="specialFeeActivated"
            ></app-filtro-select>

            <div *ngIf="filtro.type === 'Select' && showSelect && filtro.key === 'electricSystem'">
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.FILTERS.ELECTRIC_SYSTEM' | translate }}</mat-label>
                    <mat-select
                        #selectElectricSystem
                        [disabled]="toggleActive"
                        (selectionChange)="obtainSelectedOptionElectrictSystem($event)"
                    >
                        <mat-option
                            *ngFor="let opcion of electricSystemList"
                            [value]="opcion"
                            (click)="filterUnitProgram(opcion)"
                        >
                            {{ opcion }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="filtro.type === 'Select' && showSelect && filtro.name === 'Unidad de Programa'">
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.FILTERS.PROGRAM_UNIT' | translate }}</mat-label>
                    <mat-select
                        [formControl]="unitPrograms"
                        [disabled]="!electrictSystemSelected"
                        (selectionChange)="obtainUnitsPrograms($event)"
                        multiple
                    >
                        <mat-option *ngFor="let opcion of unitProgramList" [value]="opcion">
                            {{ opcion }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <app-filtro-input
                *ngIf="filtro.type === 'Input' && checkConditions(filtro.displayConditions) && showSelect"
                [name]="filtro.name"
            >
            </app-filtro-input>

            <app-filtro-textarea *ngIf="filtro.type === 'Textarea'"></app-filtro-textarea>

            <app-filtro-year
                *ngIf="filtro.type === 'Year'"
                [value]="yearValue"
                [title]="filtro.name"
                (select)="restarValue($event, i, filtro.key, filtro.type)"
                [id]="filtro.id"
            ></app-filtro-year>
        </div>
        <hr />
        <ng-container *ngIf="isDateInvalid">
            <div class="clarifyingMessage">
                <h3 class="clarifyingMessageH3">
                    {{ 'PAGE.FILTERS.DATE_RANGE_FILTER_ONLY_ACCEPTS_1_WEEK_OF_SEARCH' | translate }}
                </h3>
            </div>
        </ng-container>
    </div>
</div>
<div *ngIf="!validRole">
    <h3 class="invalidRole">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
</div>
<div class="buttonsFilter">
    <button class="delete" (click)="clearFilters()" (mouseup)="hideSelect()" mat-button>
        {{ 'PAGE.ACTIONS.DELETE' | translate }} <app-base-icon iconName="filter_alt_off" class=""></app-base-icon>
    </button>
    <button class="search" mat-flat-button color="warn" (click)="loadTable()" [disabled]="isDisabled">
        {{ 'PAGE.ACTIONS.FILTER' | translate | uppercase }}<app-base-icon iconName="search"> </app-base-icon>
    </button>
</div>
