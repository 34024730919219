import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { ExportService } from 'src/app/services/export.service';
import { HeaderTitleService } from '../../../services/header-title.service';
import { ProcesosService } from '../services/procesos.service';
import { DownloadExcelComponent } from '../../../shared/components/download-excel/download-excel.component';
import { ProcesosPage } from 'src/app/shared/models/procesos-page.data';
import { SortingObject } from 'src/app/shared/models/files.interface';
import { Filtro } from 'src/app/shared/models/filtros.interface';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { PaginationObject } from 'src/app/shared/models/paginationObject.interface';
import { ConceptsLabel, EndpointsProcesos, Status } from 'src/app/shared/enums/procesos';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-procesos-resultados',
    templateUrl: './procesos-resultados.component.html',
    styleUrls: ['./procesos-resultados.component.scss'],
})
export class ProcesosResultadosComponent implements OnInit {
    dataBusiness: any;
    dataHeaders: any;
    dataPagination: PaginationObject;
    dataSorting: SortingObject;
    columns: any;
    permissions: any;
    headerEdit: IColumnsTable;
    headerDelete: IColumnsTable;
    filtersTable: any[]; // nombre de la tabla seleccionada en el concepto
    filters: any;
    idFilters: any;
    subfilters: Filtro[];
    openModalExcel: boolean;

    dataHeaders_valueSelect: any;
    ocultar: boolean = true;
    element: any = false;
    newDataBusiness: any;

    systemSelect: string;

    headers: any[];

    search: any = [];

    message: string = '';
    action: string = '';
    config: any;

    pageSizeOptions: number[] = [10, 25, 50, 100];
    pageIndex: any;
    sortActive: any;
    sortDir: any;
    totalRegisters: number;

    pageSize: number;
    totalPages: number;
    numPage: number;
    usersList: any[];
    userSystem = { username: 'SYSTEM', name: 'SYSTEM' };

    constructor(
        public dialogRef: MatDialogRef<DownloadExcelComponent>,
        public readonly _headerTitleService: HeaderTitleService,
        private _procesosService: ProcesosService,
        private _globalService: GlobalService,
        private _exportService: ExportService,
        private _translateService: TranslateService
    ) {}

    checkvalue(asd: any) {
        this.columns = [];
        for (let i = 0; i < asd.length; i++) {
            this.columns.push(asd[i].id);
        }
    }

    pageChange(event: any) {
        this.pagination(event);
    }

    ngOnInit(): void {
        if (ProcesosPage.subItems.length === 1) {
            this._headerTitleService.currentHeaderTranslationCode = 'PROCESS_LAUNCH';
        } else {
            this._headerTitleService.currentHeaderTranslationCode = 'PROCESS_RESULTS';
        }
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
        this.message = this._translateService.instant('PAGE.MODALS.LOADING_MESSAGE_EXCEL');
        this.action = this._translateService.instant('PAGE.MODALS.CLOSE');
        this._globalService.getUsers().subscribe((users) => {
            this.usersList = [];
            this.usersList = users.data;
            this.usersList.push(this.userSystem);
        });
    }

    botonClick() {
        if (this.element) {
            //Mostrar-ocultar Etiqueta Span "Columnas"
            this.element = false;
        } else {
            this.element = true;
        }
        if (!this.ocultar) {
            //Mostrar-ocultar select de columnas
            this.ocultar = true;
        } else {
            this.ocultar = false;
        }
    }

    protected openModalExcelExport() {
        this._exportService.openDialog(this.dataBusiness, 'procesos', this.totalRegisters);
    }

    buildTable(res: any, idHeaders: number): void {
        this.dataBusiness = res[0].data;
        this.dataHeaders = res[1].data[idHeaders].headers;
        this.totalRegisters = res[0].page.totalElements;
        this.numPage = res[0].page.number;
        this.pageSize = res[0].page.size;
        this.totalPages = res[0].page.totalPages;
    }

    receiveIdFilters($event: any) {
        this.getInfoTablaByFilterSort($event);
    }
    saveSearch: any;
    async receiveIdSelect($event: any) {
        let selectedUser;
        let selectedProcess;
        let selectedDates;
        let selectedInitDate;
        let selectedEndDate;
        let selectedStatus;

        for (let i = 0; i < $event[1].length; i++) {
            //Sacar Usuario
            if ($event[1][i].key === 'user' && $event[1][i].selected !== undefined) {
                this.usersList.forEach((user) => {
                    if (user.username === $event[1][i].selected) {
                        let encodedUsername = user.username;
                        selectedUser = 'creationUser=' + encodedUsername;
                    }
                });
                this.search.push(selectedUser);
            }
        }

        // Filtro Medidas o Previsiones
        if ($event[2]) {
            this.search.push(`processCode.area=${$event[2]}`);
        }
        for (let i = 0; i < $event[1].length; i++) {
            //Sacar tipo de proceso
            if ($event[1][i].key === 'type' && $event[1][i].selected !== undefined) {
                let code;
                if ($event[1][i].selected === ConceptsLabel.DASHBOARD) {
                    code = 'APR0001';
                } else if ($event[1][i].selected === ConceptsLabel.PREVISIONES_INICIALES) {
                    code = 'PR0002';
                } else if ($event[1][i].selected === ConceptsLabel.PREVISIONES_PROYECTADAS) {
                    code = 'PR0003';
                } else if ($event[1][i].selected === ConceptsLabel.CIERRE_VIRTUAL) {
                    code = 'PR0004';
                } else if ($event[1][i].selected === ConceptsLabel.MEDIDAS_OBJECIONES) {
                    code = 'PR0005';
                } else if ($event[1][i].selected === ConceptsLabel.MEDIDAS_CUARTO_HORARIO) {
                    code = 'PR0006';
                } else if ($event[1][i].selected === ConceptsLabel.AGREGADO_PREVISIONES) {
                    code = 'PR0007';
                } else if ($event[1][i].selected === ConceptsLabel.ENVIO_METEOLOGICA) {
                    code = 'PR0008';
                } else if ($event[1][i].selected === ConceptsLabel.ANALISIS_RESULTADOS) {
                    code = 'PR0009';
                } else if ($event[1][i].selected === ConceptsLabel.LIQUIDACION_POTESTATIVA) {
                    code = 'PR0011';
                }
                selectedProcess = `processCode.processCode=${code}`;
                // selectedProcess = "processCode.processCode=" + $event[1][i].selected;
                this.search.push(selectedProcess);
            }
        }

        for (let i = 0; i < $event[1].length; i++) {
            //Sacar fechas
            if ($event[1][i].key === 'initDate' && $event[1][i].selected !== undefined) {
                selectedDates = $event[1][i].selected.split('~');
                selectedInitDate = 'initDate>=' + selectedDates[0];
                selectedEndDate = 'endDate<=' + selectedDates[1];
                this.search.push(selectedInitDate);
                this.search.push(selectedEndDate);
            }
        }

        for (let i = 0; i < $event[1].length; i++) {
            //Sacar estado
            if ($event[1][i].key === 'status' && $event[1][i].selected !== undefined) {
                selectedStatus = $event[1][i].selected;
                if (selectedStatus === 'Error') {
                    selectedStatus = 'status=ERROR';
                    this.search.push(selectedStatus);
                }
                if (selectedStatus === 'En curso') {
                    selectedStatus = 'status=IN_PROGRESS';
                    this.search.push(selectedStatus);
                }
                if (selectedStatus === 'OK') {
                    selectedStatus = 'status=OK';
                    this.search.push(selectedStatus);
                }
            }
        }

        this._procesosService.searchParam = this.search.toString();

        this._procesosService
            .getProcessResultFilter(
                1,
                10,
                EndpointsProcesos.SortByInitDate,
                EndpointsProcesos.ASC,
                this.search.toString()
            )
            .subscribe((dataProcesosResult) => {
                this.dataBusiness = [];

                for (let i = 0; i < dataProcesosResult.data.length; i++) {
                    this.dataBusiness.push(dataProcesosResult.data[i]);
                }

                this.totalRegisters = dataProcesosResult.page.totalElements;
                this.numPage = dataProcesosResult.page.number;
                this.pageSize = dataProcesosResult.page.size;
                this.totalPages = dataProcesosResult.page.totalPages;
            });
        this.saveSearch = this.search;

        this.search = [];

        this.loadTable();
    }

    resetSearch(event: any) {
        if (event) {
            this.search = [];
            this.dataBusiness = [];
            this.dataHeaders = undefined;
        }
    }

    /**
     * Cargamos la tabla
     */
    loadTable() {
        if (!this.dataHeaders) {
            this._globalService.getHeaders().subscribe((headersProcesos) => {
                this.dataHeaders = headersProcesos.data.filter((x: any) => {
                    return x.name === 'procesos_resultados'; // TODO: crear sitio común para todos estos ids de los headers
                })[0]?.headers;
                this.dataHeaders_valueSelect = this.dataHeaders;
            });
        }

        this.columns = [];
        this.columns = [
            'initDate',
            'endDate',
            'launchId.name',
            'processCode.name',
            'status',
            'description',
            'launchId.creationUser',
        ];
    }

    reset() {}

    getInfoTablaByFilterSort(s: any) {}

    pagination(event: any) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        // Ordenar por ASC o DESC
        if (!this.sortDir) {
            this.sortDir = 'asc';
        }
        event.pageIndex = event.pageIndex + 1;

        this._procesosService
            .getProcessResultFilter(
                event.pageIndex,
                event.pageSize,
                EndpointsProcesos.SortByInitDate,
                EndpointsProcesos.ASC,
                this.saveSearch.toString()
            )
            .subscribe((dataProcesosResult) => {
                this.dataBusiness = dataProcesosResult.data;
                this.totalRegisters = dataProcesosResult.page.totalElements;
                this.numPage = dataProcesosResult.page.number;
                this.pageSize = dataProcesosResult.page.size;
                this.totalPages = dataProcesosResult.page.totalPages;
            });
    }

    chargeFiltersInTable($event: any) {
        this.filters = $event;
    }

    closeModal() {
        this.openModalExcel = false;
    }

    openModal() {
        this.openModalExcel = true;
        this.downloadExcell();
    }

    downloadExcell() {
        this._procesosService.getProcessResult().subscribe((res) => {
            this.newDataBusiness = res.data;
            this._exportService.exportToExcel(this.newDataBusiness, 'export_');
        });
    }

    checkAndReturnColumns(element: any, columnId: string) {
        if (columnId === 'status' && (element[columnId] === Status.IN_PROGRESS || element[columnId] === Status.OK)) {
            if (element[columnId] === Status.IN_PROGRESS) {
                this._translateService.get('PAGE.PROCESSES').subscribe((message) => {
                    element[columnId] = message.IN_PROGRESS;
                });
                return element[columnId];
            } else {
                this._translateService.get('PAGE.PROCESSES').subscribe((message) => {
                    element[columnId] = message.OK;
                });
                return element[columnId];
            }
        }
        if (columnId.indexOf('.') > -1) {
            let columnIdSplitted = columnId.split('.');
            if (element[columnIdSplitted[0]]) {
                return element[columnIdSplitted[0]][columnIdSplitted[1]];
            }
            return undefined;
        }
        if (columnId === 'initDate' || columnId === 'endDate') {
            if (columnId === 'endDate' && element.status === 'IN_PROGRESS') {
                return '';
            } else {
                return this._transformDate(element[columnId], true);
            }
        }
        return element[columnId];
    }

    private _transformDate(date: string, largeFormat: boolean) {
        if (date.includes('/')) {
            date = date.replaceAll('/', '-');
        }
        const datePipe = new DatePipe('en-US');
        const dates = new Date(date);
        let formatDate;
        if (isNaN(dates.getTime())) {
            formatDate = '-';
        } else {
            if (largeFormat) {
                formatDate = datePipe.transform(
                    dates,
                    `${this._headerTitleService.currentLang !== 'en' ? 'dd/MM/yyyy' : 'yyyy/MM/dd'} HH:mm:ss`
                );
            } else {
                formatDate = datePipe.transform(
                    dates,
                    `${this._headerTitleService.currentLang !== 'en' ? 'dd/MM/yyyy' : 'yyyy/MM/dd'}`
                );
            }
        }
        return formatDate;
    }
}
