import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Pipe({
    name: 'momentDateFormat',
})
export class MomentDateFormatPipe implements PipeTransform {
    constructor(private readonly _headerTitleService: HeaderTitleService) {}
    transform(value: any, valueType: 'moment' | 'date'): string {
        if (!value) return '';
        const isEnglish: boolean = this._headerTitleService.currentLang === 'en';
        if (valueType === 'date' && value instanceof Date && !isNaN(value.getTime())) {
            const year: number = value.getFullYear();
            let month: number | string = value.getMonth() + 1;
            let day: number | string = value.getDate();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            return isEnglish ? `${year}/${month}/${day}` : `${day}/${month}/${year}`;
        } else if (valueType === 'moment' && moment.isMoment(value)) {
            return value.format(`${isEnglish ? 'YYYY/MM/DD' : 'DD/MM/YYYY'}`);
        }
        return '';
    }
}
