import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ErrorHttpService } from 'src/app/services/error-http.service';
import { GlobalService } from 'src/app/services/global.service';
import { FiltroSelectComponent } from 'src/app/shared/components/filtros/filtro-select/filtro-select.component';
import { Filtro, ListaFiltros } from 'src/app/shared/models/filtros.interface';
import { InformesService } from '../../../services/informes.service';
import { MaestroService } from '../../../../maestro/services/maestro.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Months } from '../../../../../shared/components/custom-cron/custom-cron.enum';
import { KeyReports, Reports } from '../../../../../shared/enums/reports';
import { HeaderTitleService } from 'src/app/services/header-title.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-select-ejecucion-informes',
    templateUrl: './select-ejecucion-informes.component.html',
    styleUrls: ['./select-ejecucion-informes.component.scss'],
})
export class SelectEjecucionInformesComponent implements OnInit {
    @Output() optionSelectedId = new EventEmitter<string>();
    @Output() resetTable = new EventEmitter<string>();
    @Output() changeSelectReset = new EventEmitter<any>();
    @Output() filtersList = new EventEmitter<any>();
    @Output() chargeRegions = new EventEmitter<any>();
    @ViewChild('matRefReport') matRefReport: MatSelect | any;
    @ViewChild('matRefSystem') matRefSystem: MatSelect | any;
    @ViewChildren('selects') selects: QueryList<FiltroSelectComponent>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    durationInSeconds = 5;

    subfilters: Filtro[] | undefined | any;
    reportFilters: any[] = [];
    systemFilter: any = [];
    isReportSelected: boolean = false;
    filters: ListaFiltros[] = [];
    subscriptions: Subscription[] = [];
    selectId: any;
    isDisabled: boolean = true;
    inputValues: { id: string; value: string }[] = [];
    resetSubfilter: any;
    showinput: any;
    isShowSelect: boolean = true;
    showRequiredFiltersInfoMessageCUPS: boolean = false;
    showRequiredFiltersInfoMessageNegotiatingAccountOrCUPS: boolean = false;
    selectedName: string;
    resetCups: boolean;
    isFirstTime: boolean = true;
    unitPrograms = new FormControl('');
    validRole: boolean = true;
    showSelfConsumption: boolean = false;
    selfsupplySubList:string[] = [];

    specialFeeActivated: boolean;

    electricSystemSelected: any;
    unitProgramListNoFilter: any;
    rateListNoFilter: any;
    unitProgramList: any;
    rateList: any;
    selfConsumptionList: any;
    programUnit: any;
    rate: any;
    selfConsumption: any;
    electrictSystemSelected: boolean = false;
    electricSystemList: any;

    eventvalue: string;
    filtersBodyPost: any;
    toggleActive: boolean = false;
    validatedReport: number = 0;
    forecastTypeSelected: any;
    forecastType: any;
    copyReportsFilters: any[];
    copySystemFilter: any = [];

    months = Array.from({ length: 12 }, (increment, monthNumber) => monthNumber + 1);
    selectedYear: string | number;
    selectedMonth: string | number;
    differenceInDays: number = 0;

    formGroup = new FormGroup({
        month: new FormControl(undefined),
        year: new FormControl(undefined),
    });
    splitedDates: any;
    splitedEmit: any;
    splitedFact: any;
    splitedCreat: any;
    placeholder$ = this._translateService.get('PAGE.MASTER.MASTER_CRM.MESSAGES.MESSAGE_PLACEHOLDER_SPECIAL_SELECTS');
    isDisabledDate: boolean = false;
    isDisabledYear: boolean = false;
    isDateRange: boolean = true;
    system: any;
    reports: any[];
    years: number[];
    isCheckCierres: boolean = false;
    isMessageClarify: boolean = false;
    isMoreThanOneCups: boolean = false;
    isModalOpened: boolean = false;
    isDisableSubfilters: boolean = false;
    isAnalysisResults: boolean = false;
    isOutEnergy: boolean = false;
    yearSelected: boolean = false;
    monthSelected: boolean = false;
    cups: any;
    singleDateSelected: boolean = false;
    copyUnitProgramList: any;
    copyElectricSystemList: never[];
    copyRateList: never[];
    copyProgramUnit: undefined;
    copyClientType: any;
    copyLiquidationType: any;
    copyRate: any;
    count: number = 0;
    selectedReportName: string = '';
    emissionDifferenceInDays: number = 0;
    billingDifferenceInDays: number = 0;
    creationDifferenceInDays: number = 0;

    constructor(
        protected readonly _headerTitleService: HeaderTitleService,
        private _globalService: GlobalService,
        private _errorHttpServ: ErrorHttpService,
        private _translateService: TranslateService,
        private _reportsServices: InformesService,
        public maestroService: MaestroService,
        private _snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.maestroService.validCups = undefined;
        this._reportsServices.getSelectReports().subscribe((status: any) => {
            status.data = status.data.filter((e: any) => e.reportCode !== 'OBJ001' && e.reportCode !== 'OBJ002');
            status.data.forEach((element: any) => {
                this.copySystemFilter.push(element.area);
            });
            this.copySystemFilter = Array.from(new Set(this.copySystemFilter));
            this.copySystemFilter.forEach((element: any) => {
                element === 'Conciliacion' ? (element = 'Conciliación') : element;
                this.systemFilter.push({ area: element });
            });
            this.getReportConcept();
        });
        this.getFilters(this._reportsServices.searchBy);
        this._reportsServices.getUnitProgram().subscribe((UP) => {
            this.unitProgramListNoFilter = UP.data;
            this.unitProgramList = UP.data;
            this.electricSystemList = [];
            UP.data.forEach((element: any) => {
                this.electricSystemList.push(element.electricSystem);
            });
            let electricSystemFilter: any = Array.from(new Set(this.electricSystemList)); //Se filtra la lista de "Sist. eléctrico" para eliminar elementos repetidos
            electricSystemFilter = electricSystemFilter.map(function (word: string) {
                word === 'PENINSULA'
                    ? (word = 'Península')
                    : (word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                return word;
            });
            this.electricSystemList = electricSystemFilter;
        });

        this._reportsServices.getFeesCode().subscribe((UP) => {
            this.rateList = [];
            UP.data.forEach((element: any) => {
                this.rateList.push(element.codeRate);
            });
        });

        this.generateYears();
    }

    ngOnChange(): void {
        this.resetCups = false;
    }

    getFiltersBy(searchBy: [boolean, string]) {
        let subfilter = this.subfilters?.find((filter: any) => filter.key === searchBy[1]);
        if (subfilter) {
            subfilter.toggleState = searchBy[0];
        }
    }

    /**
     * Función para preparar un selector cuando se abre.
     * @param event 
     */
    byOpenSelect(event: any) {
        if (event.newValue) {
            if (event.key === KeyReports.SOCIEDAD_ACCIONA) {
                let subfilter = this.subfilters?.find((f: any) => f.key === event.key);
                this._reportsServices.getSelectAccionaSociety().subscribe((society) => {
                    subfilter.data = [];
                    society.data.forEach((element: any) => {
                        subfilter.data.push({ name: element.description, value: element.nif });
                    });
                });
            }
            if (event.key === KeyReports.CUENTA_NEGOCIADORA ||
                event.key === KeyReports.SOCIEDAD ||
                event.key === KeyReports.DISTRIBUIDORA ||
                event.key === KeyReports.AYUNTAMIENTO) {
                let subfilter = this.subfilters?.find((f: any) => f.key === event.key);
                subfilter.data = [];
            }
            if (event.key === KeyReports.SOCIEDAD) {
                let subfilter = this.subfilters?.find((f: any) => f.key === event.key);
                subfilter.data = [];
            }
        } 
    }

    /**
     * Rellena los datos del subfiltro del evento para poder seleccionar una opción.
     * @param event 
     */
    specialSearch(event: any) {
        let subfilter = this.subfilters?.find((f: any) => f.key === event.key);
        subfilter.data = [];
        switch (event.key) {
            case KeyReports.CUENTA_NEGOCIADORA:
                this._reportsServices
                    .getNegotiatingAccounts({
                        search: event.search,
                        mode: subfilter.toggleState ? 'negotiatingAccountCode' : 'negotiatingAccountDescription',
                    })
                    .subscribe(neAccounts => {
                        neAccounts.data.forEach((element: any) => {
                            if (subfilter && subfilter.toggleState) {
                                subfilter.toggleStatus = true;
                                subfilter.data.push({
                                    name: element.negotiatingAccountCode,
                                    value: element.negotiatingAccountCode,
                                });
                            } else if (subfilter && !subfilter.toggleState) {
                                subfilter.toggleStatus = false;
                                subfilter.data.push({
                                    name: element.negotiatingAccountDescription,
                                    value: element.negotiatingAccountCode,
                                });
                            }
                        });
                    });
                break;
            case KeyReports.SOCIEDAD:
                this._reportsServices
                    .getClients({
                        search: event.search,
                        mode: subfilter.toggleState ? 'cif' : 'description',
                    })
                    .subscribe(client => {
                        client.data.forEach((element: any) => {
                            if (subfilter && subfilter.toggleState) {
                                subfilter.toggleStatus = true;
                                subfilter.data.push({ name: element.idNumber, value: element.idNumber });
                            } else if (subfilter && !subfilter.toggleState) {
                                subfilter.toggleStatus = false;
                                subfilter.data.push({ name: element.clientName, value: element.idNumber });
                            }
                        });
                    });
                break;
            case KeyReports.AYUNTAMIENTO:
                this._reportsServices
                    .getCouncils({
                        search: event.search,
                        mode: subfilter.toggleState ? 'councilCif' : 'councilDescription',
                    })
                    .subscribe(client => {
                        client.data.forEach((element: any) => {
                            if (subfilter && subfilter.toggleState) {
                                subfilter.toggleStatus = true;
                                subfilter.data.push({ name: element.councilCode, value: element.councilCode });
                            } else if (subfilter && !subfilter.toggleState) {
                                subfilter.toggleStatus = false;
                                subfilter.data.push({ name: element.councilName, value: element.councilCode });
                            }
                        });
                    });
                break;
            case KeyReports.DISTRIBUIDORA:
                this._reportsServices
                    .getDistributors({
                        search: event.search,
                        mode: subfilter.toggleState ? 'distributorCode' : 'distributorDescription',
                    })
                    .subscribe(client => {
                        client.data.forEach((element: any) => {
                            if (subfilter && subfilter.toggleState) {
                                subfilter.toggleStatus = true;
                                subfilter.data.push({ name: element.code, value: element.code });
                            } else if (subfilter && !subfilter.toggleState) {
                                subfilter.toggleStatus = false;
                                subfilter.data.push({ name: element.description, value: element.code });
                            }
                        });
                    });
                break;
            default:
                break;
        }
    }

    getFilters(filterByBoolean: boolean) {
        this.subscriptions.push(
            this._globalService.getAllFilters(filterByBoolean).subscribe((filtros) => (this.filters = filtros.data))
        );
    }

    getSubfilters(selectedOption: any) {
        this.count = 0;
        this.programUnit = '';
        this.unitProgramList = [];
        this.isDateRange = true;
        this.singleDateSelected = false;
        this.isDisabledDate = false;
        this.isDisabledYear = false;
        this.isDisableSubfilters = false;
        this.isMessageClarify = false;
        this.isAnalysisResults = false;
        this.isMoreThanOneCups = false;
        this.formGroup = new FormGroup({
            month: new FormControl(undefined),
            year: new FormControl(undefined),
        });
        this.monthSelected = false;
        this.yearSelected = false;
        this.getFilters(this._reportsServices.searchBy);
        this.resetTable.emit();

        this.selectedReportName = '';
        switch (selectedOption.reportCode) {
            case 'AR0001':
                this.selectedReportName = Reports.ANALISIS_RESULTADOS;
                break;
            case 'DM0001':
                this.selectedReportName = Reports.PRECIOS_INDEXADOS;
                break;
            case 'GM0001':
                this.selectedReportName = Reports.CIERRES;
                break;
            case 'GM0002':
                this.selectedReportName = Reports.CHECK_CIERRES;
                break;
            case 'GM0003':
                this.selectedReportName = Reports.PUNTO_MEDIDA;
                break;
            case 'GM0004':
                this.selectedReportName = Reports.CHECK_MEDIDAS;
                break;
            case 'GM0005':
                this.selectedReportName = Reports.MEDIDAS_AGRUPADAS;
                break;
            case 'GM0006':
                this.selectedReportName = Reports.MEDIDA_HORARIA;
                break;
            case 'GM0007':
                this.selectedReportName = Reports.INVENTARIO_PUNTOS;
                this.isDateRange = false;
                break;
            case 'GM0008':
                this.selectedReportName = Reports.OBJECIONES;
                break;
            case 'GM0009':
                this.selectedReportName = ''; // Informe de Cuadro de Mandos
                break;
            case 'GM0010':
                this.selectedReportName = Reports.CALIDAD;
                break;
            case 'GM0011':
                this.selectedReportName = Reports.ATR;
                break;
            case 'GM0012':
                this.selectedReportName = Reports.RESUMEN_ATR;
                break;
            case 'GM0013':
                this.selectedReportName = Reports.CONCEPTOS_REPERCUTIBLES_ATR;
                break;
            case 'GM0014':
                this.selectedReportName = Reports.CONSUMOS_ATR_DISTRIBUIDORA;
                break;
            case 'OBJ001':
                this.selectedReportName = Reports.CALCULATE_OBJECTIONS_POTENTIAL;
                break;
            case 'OBJ002':
                this.selectedReportName = Reports.CALCULATE_OBJECTIONS_DISTRIBUIDORA;
                break;
            case 'PR0001':
                this.selectedReportName = Reports.PREVISIONES_HORARIAS;
                break;
            case 'PR0002':
                this.selectedReportName = Reports.PREVISIONES_AGREGADO;
                break;
            case 'PR0003':
                this.selectedReportName = Reports.CONSUMO_REAL;
                break;
            default:
                this.selectedReportName = '';
                break;
        }
        selectedOption.filtrosCategory = this.selectedReportName;
        this.subfilters = this.filters.find((fil: any) => fil.name === this.selectedReportName)?.filters;
        this.inputValues = [];
        this.showSelfConsumption = false;
        this._setLosses(false);
        this.filtersList.emit({ subfilters: this.subfilters, selectedOption });
        if (this.subfilters) {
            this.subfilters.forEach((element: any) => {
                if (element.required) {
                    if (element.name.includes('*')) {
                        return;
                    } else {
                        element.name = element.name + ' *';
                    }
                }
            });
        }
        if (selectedOption.filtrosCategory === Reports.ANALISIS_RESULTADOS) {
            let subfilterClientType = this.subfilters?.find((f: any) => f.key === KeyReports.TIPO_DE_NEGOCIO);
            let subfilterLiquidationType = this.subfilters?.find((f: any) => f.key === KeyReports.TIPO_LIQUIDACION);
            if (
                this.copyClientType !== undefined &&
                subfilterClientType !== undefined &&
                subfilterClientType.data.length === 0
            ) {
                subfilterClientType.data = this.copyClientType;
            }
            if (
                this.copyLiquidationType !== undefined &&
                subfilterLiquidationType !== undefined &&
                subfilterLiquidationType.data.length === 0
            ) {
                subfilterLiquidationType.data = this.copyLiquidationType;
            }
            if (this.copyElectricSystemList !== undefined) {
                this.electricSystemList = this.copyElectricSystemList;
            }
            if (this.copyRateList !== undefined) {
                this.rateList = this.copyRateList;
            }
        }
        this.getIdSelect(selectedOption);
        this.fillSelect()
    }

    getIdSelect(selectedOption: any) {
        if (selectedOption.name === 'Check Cierres') {
            this.isCheckCierres = true;
        }
        if (this.subfilters) {
            this.subfilters.forEach((element: any) => {
                if (element.selected !== undefined || this.subfilters !== null) {
                    element.selected = '';
                }
            });
        }
        this.resetSubfilter = selectedOption;
        this.selectId = selectedOption;
        this._reportsServices.selectId = this.selectId.filtrosCategory;
        this.isDisableButtonRequired();
        this.showinput = undefined;

        this._reportsServices.validCups = '';
        this._errorHttpServ.hide();
    }

    getReports(selectedOption: any) {
        if (selectedOption.value.area) {
            this.subfilters = [];
            this.copyReportsFilters = [];
            this.programUnit = '';
            this.unitProgramList = [];
            this.electrictSystemSelected = false;
            this.matRefReport.options.forEach((data: MatOption) => data.deselect());
            this.isReportSelected = false;
            let area: string;
            selectedOption.value.area === 'Conciliación' ? (area = 'Conciliacion') : (area = selectedOption.value.area);
            this.copyReportsFilters = this.reportFilters.filter((i: any) => i.area === area);
            this.copyReportsFilters = this.copyReportsFilters.map((obj) => {
                return {
                    ...obj,
                    name: obj.name.charAt(0).toUpperCase() + obj.name.slice(1).toLowerCase(),
                };
            });
        }
        this.isDisableButtonRequired();
    }

    /**
     * Función que comprueba los distintos subfiltros para comprobar si se puede habilitar o no el botón de ejecución.
     */
    isDisableButtonRequired() {
        let countRequired = 0;
        let countSelected = 0;
        let multipleRangeFlag = false;
        this.matRefReport.options.forEach((data: MatOption) => {
            if (data.selected) {
                this.isReportSelected = true;
            } else {
                this.isDisabled = true;
                return;
            }
        });

        if (this.isReportSelected && this.validatedReport === 0) {
            this.validatedReport = 1;
            countRequired++;
            countSelected++;
        } else if (!this.isReportSelected) {
            countRequired++;
        }

        if (this.subfilters) {
            this.subfilters.forEach((element: any) => {
                if (element.key === KeyReports.ORIGEN_DE_DATOS) {
                    countRequired++;
                    if (element.selected !== '') {
                        countSelected++;
                        countRequired--;
                    }
                }
                if (element.required) {
                    countRequired++;
                    if (element.selected) {
                        countSelected++;
                    }
                }
                // Condicionamiento especial para los informes de ATR en los que solo es necesario uno de los dos filtros de rango.
                // También se añade un condicionamiento especial para que los rangos de fechas de creación no puedan ser mayores de un año.
                if ((element.key == KeyReports.RANGO_EMISION || element.key == KeyReports.RANGO_FACTURACION || element.key == KeyReports.RANGO_CREACION)
                    && element.selected !== '' && element.selected !== undefined) {
                    let elementDates = element.selected.split('~');
                    let differenceInDays = (new Date(elementDates[1]).getTime() - new Date(elementDates[0]).getTime()) / (1000 * 3600 * 24);
                    this.emissionDifferenceInDays = element.key == KeyReports.RANGO_EMISION ? differenceInDays : this.emissionDifferenceInDays;
                    this.billingDifferenceInDays = element.key == KeyReports.RANGO_FACTURACION ? differenceInDays : this.billingDifferenceInDays;
                    this.creationDifferenceInDays = element.key == KeyReports.RANGO_CREACION ? differenceInDays : this.creationDifferenceInDays;
                    if (element.selected !== '' 
                        && this.emissionDifferenceInDays <= 365 
                        && this.billingDifferenceInDays <= 365 
                        && this.creationDifferenceInDays <= 365) {
                        multipleRangeFlag = true;
                    // En el caso especial de los tres rangos anteriores, si cualquiera tuviese una diferencia mayor de lo que debería, entonces
                    // ponemos trabas al contador de las seleccionadas y falseamos el flag de multiplicidad de rango especial
                    } else if (this.emissionDifferenceInDays > 365 || this.billingDifferenceInDays > 365 || this.creationDifferenceInDays > 365) {
                        countSelected = countSelected - 1;
                        multipleRangeFlag = false;
                    }
                }
                if (this.isDisabledDate && this.subfilters[1]?.type === 'SelectYear' && this.subfilters[1].selected !== '' && element.key === 'rango') {
                    countRequired = countRequired - 1;
                }
                if (this.isDisabledYear && this.subfilters[1]?.type === 'SelectYear' && this.subfilters[1].selected === '' && element.key === 'year') {
                    countRequired -= 2;
                }
            });
        }
        if (countRequired === 0 || multipleRangeFlag) {
            this.isDisabled = false;
        } else {
            if (countRequired === countSelected) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        }

        // Check conditions to disable button if difference is > 30 days
        if (!this.isDisabled && this.differenceInDays > 30) {
            let cups: string | string[] = '';
            const cupsFilterIndex: number = this.subfilters.findIndex((filter: any) => filter.key === 'cups');
            if (cupsFilterIndex !== -1) {
                if (this.subfilters[cupsFilterIndex].selected.length > 0) {
                    this.subfilters[cupsFilterIndex].selected = this.subfilters[cupsFilterIndex].selected.filter(
                        (cups: string) => cups !== ''
                    );
                }
                cups = this.subfilters[cupsFilterIndex].selected;
            }

            switch (this.selectId.filtrosCategory) {
                case Reports.PUNTO_MEDIDA:
                    this.showRequiredFiltersInfoMessageNegotiatingAccountOrCUPS = false;
                    this.isDisabled = cups[0] === undefined || cups[0] === '' || cups === '';
                    this.showRequiredFiltersInfoMessageCUPS = this.isDisabled;
                    break;

                case Reports.MEDIDA_HORARIA:
                case Reports.PREVISIONES_HORARIAS:
                    this.showRequiredFiltersInfoMessageCUPS = false;
                    this.isDisabled = (cups[0] === undefined || cups[0] === '' || cups === '') &&
                        this.subfilters.find((filter: any) => filter.key === 'accountId').selected === '';
                    this.showRequiredFiltersInfoMessageNegotiatingAccountOrCUPS = this.isDisabled;
                    break;

                default:
                    break;
            }
        } else if (this.differenceInDays <= 30 || !this.isDisabled) {
            this.showRequiredFiltersInfoMessageCUPS = false;
            this.showRequiredFiltersInfoMessageNegotiatingAccountOrCUPS = false;
        }
    }

    loadTable() {
        switch (this.selectedReportName) {
            case Reports.PRECIOS_INDEXADOS:
                this.splitedDates = this.subfilters[0].selected!.split('~');
                this.filtersBodyPost = {
                    initDate: this.splitedDates[0],
                    endDate: this.splitedDates[1],
                    liquidationREE: this.getSelectedSubfilters(KeyReports.LIQUIDACION),
                    priceCode: this.getSelectedSubfilters(KeyReports.CODIGO),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.PREVISIONES_AGREGADO:
                this.splitedDates = this.subfilters[0].selected!.split('~');
                this.getFiltersBodyPost();
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.PREVISIONES_HORARIAS:
                this.splitedDates = this.subfilters[0].selected!.split('~');
                this.getFiltersBodyPost();
                this.filtersBodyPost.cups = this.getSelectedSubfilters(KeyReports.CUPS);
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.CHECK_CIERRES:
                this.filtersBodyPost = {
                    month: this.getSelectedSubfilters(KeyReports.MES),
                    year: this.getSelectedSubfilters(KeyReports.AÑO),
                    cups: this.getSelectedSubfilters(KeyReports.CUPS),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.CHECK_MEDIDAS:
                this.splitedDates = this.subfilters[0].selected!.split('~');
                this.filtersBodyPost = {
                    initDate: this.splitedDates[0],
                    endDate: this.splitedDates[1],
                    measureType: this.getSelectedSubfilters(KeyReports.TIPO_MEDIDA),
                    issuerCompany: this.getSelectedSubfilters(KeyReports.SOCIEDAD_ACCIONA),
                    cups: this.getSelectedSubfilters(KeyReports.CUPS),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.MEDIDAS_AGRUPADAS:
                this.selectSplitedDates();
                this.filtersBodyPost = {
                    initDate: this.splitedDates[0],
                    endDate: this.splitedDates[1],
                    month: this.getSelectedSubfilters(KeyReports.MES),
                    year: this.getSelectedSubfilters(KeyReports.AÑO),
                    origin: this.getSelectedSubfilters(KeyReports.ORIGEN),
                    liquidationType: this.getSelectedSubfilters(KeyReports.TIPO_LIQUIDACION),
                    clientType: this.getSelectedSubfilters(KeyReports.TIPO_DE_NEGOCIO),
                    accountId: this.getSelectedSubfilters(KeyReports.CUENTA_NEGOCIADORA),
                    issuerCompany: this.getSelectedSubfilters(KeyReports.SOCIEDAD_ACCIONA),
                    society: this.getSelectedSubfilters(KeyReports.SOCIEDAD),
                    cups: this.getSelectedSubfilters(KeyReports.CUPS),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.CONSUMO_REAL:
                this.splitedDates = this.subfilters[0].selected!.split('~');
                this.filtersBodyPost = {
                    initDate: this.splitedDates[0],
                    endDate: this.splitedDates[1],
                    losses: this.getSelectedSubfilters(KeyReports.PERDIDAS),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.ANALISIS_RESULTADOS:
                this.filtersBodyPost = {
                    month: this.getSelectedSubfilters(KeyReports.MES),
                    year: this.getSelectedSubfilters(KeyReports.AÑO),
                    results: this.subfilters[2].selected,
                    programUnit: this.programUnit,
                    rate: this.getSelectedSubfilters(KeyReports.TARIFAS),
                    liquidationType: this.getSelectedSubfilters(KeyReports.TIPO_LIQUIDACION),
                    clientType: this.getSelectedSubfilters(KeyReports.TIPO_DE_NEGOCIO),
                    accountId: this.getSelectedSubfilters(KeyReports.CUENTA_NEGOCIADORA),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.PUNTO_MEDIDA:
                this.splitedDates = this.subfilters[0].selected!.split('~');
                this.filtersBodyPost = {
                    initDate: this.splitedDates[0],
                    endDate: this.splitedDates[1],
                    scope: this.getSelectedSubfilters(KeyReports.AMBITO_MEDIDA),
                    measurePointType: this.getSelectedSubfilters(KeyReports.TIPO_PUNTO_MEDIDA),
                    liquidationType: this.getSelectedSubfilters(KeyReports.TIPO_LIQUIDACION),
                    origin: this.getSelectedSubfilters(KeyReports.ORIGEN),
                    measureType: this.getSelectedSubfilters(KeyReports.TIPO_MEDIDA),
                    clientType: this.getSelectedSubfilters(KeyReports.TIPO_DE_NEGOCIO),
                    issuerCompany: this.getSelectedSubfilters(KeyReports.SOCIEDAD_ACCIONA),
                    cups: this.getSelectedSubfilters(KeyReports.CUPS),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.CIERRES:
                this.selectSplitedDates();
                this.filtersBodyPost = {
                    initDate: this.splitedDates[0],
                    endDate: this.splitedDates[1],
                    month: this.getSelectedSubfilters(KeyReports.MES),
                    year: this.getSelectedSubfilters(KeyReports.AÑO),
                    accountId: this.getSelectedSubfilters(KeyReports.CUENTA_NEGOCIADORA),
                    society: this.getSelectedSubfilters(KeyReports.SOCIEDAD),
                    closeOrigin: this.getSelectedSubfilters(KeyReports.TIPO_DE_CIERRE),
                    clientType: this.getSelectedSubfilters(KeyReports.TIPO_DE_NEGOCIO),
                    cups: this.getSelectedSubfilters(KeyReports.CUPS),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.MEDIDA_HORARIA:
                this.selectSplitedDates();
                this.filtersBodyPost = {
                    initDate: this.splitedDates[0],
                    endDate: this.splitedDates[1],
                    month: this.getSelectedSubfilters(KeyReports.MES),
                    year: this.getSelectedSubfilters(KeyReports.AÑO),
                    magnitude: this.getSelectedSubfilters(KeyReports.MAGNITUD),
                    measureType: this.getSelectedSubfilters(KeyReports.TIPO_MEDIDA),
                    liquidationType: this.getSelectedSubfilters(KeyReports.TIPO_LIQUIDACION),
                    measurePointType: this.getSelectedSubfilters(KeyReports.TIPO_PUNTO_MEDIDA),
                    issuerCompany: this.getSelectedSubfilters(KeyReports.SOCIEDAD_ACCIONA),
                    accountId: this.getSelectedSubfilters(KeyReports.CUENTA_NEGOCIADORA),
                    society: this.getSelectedSubfilters(KeyReports.SOCIEDAD),
                    clientType: this.getSelectedSubfilters(KeyReports.TIPO_DE_NEGOCIO),
                    origin: this.getSelectedSubfilters(KeyReports.ORIGEN),
                    cups: this.getSelectedSubfilters(KeyReports.CUPS),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.INVENTARIO_PUNTOS:
                this.filtersBodyPost = {
                    date: this.subfilters[0].selected,
                    issuerCompany: this.getSelectedSubfilters(KeyReports.SOCIEDAD_ACCIONA),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;
            case Reports.OBJECIONES:
                this.filtersBodyPost = {
                    month: this.getSelectedSubfilters(KeyReports.MES),
                    year: this.getSelectedSubfilters(KeyReports.AÑO),
                    clientType: this.getSelectedSubfilters(KeyReports.TIPO_DE_NEGOCIO),
                    liquidationType: this.getSelectedSubfilters(KeyReports.TIPO_LIQUIDACION),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;
            case Reports.CALIDAD:
                this.filtersBodyPost = {
                    month: this.getSelectedSubfilters(KeyReports.MES),
                    year: this.getSelectedSubfilters(KeyReports.AÑO),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.ATR:
                this.selectSplitedDates();
                this.filtersBodyPost = {
                    initDateBroadcast: this.splitedEmit[0],
                    endDateBroadcast: this.splitedEmit[1],
                    initDateBilling: this.splitedFact[0],
                    endDateBilling: this.splitedFact[1],
                    initDateCreation: this.splitedCreat[0],
                    endDateCreation: this.splitedCreat[1],
                    liquidationType: this.getSelectedSubfilters(KeyReports.TIPO_LIQUIDACION),
                    accountId: this.getSelectedSubfilters(KeyReports.CUENTA_NEGOCIADORA),
                    invoiceType: this.getSelectedSubfilters(KeyReports.TIPO_FACTURA),
                    treated: this.getSelectedSubfilters(KeyReports.TRATADA),
                    council: this.getSelectedSubfilters(KeyReports.AYUNTAMIENTO),
                    clientType: this.getSelectedSubfilters(KeyReports.TIPO_DE_NEGOCIO),
                    distributor: this.getSelectedSubfilters(KeyReports.DISTRIBUIDORA),
                    society: this.getSelectedSubfilters(KeyReports.SOCIEDAD),
                    cups: this.getSelectedSubfilters(KeyReports.CUPS),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.RESUMEN_ATR:
                this.selectSplitedDates();
                this.filtersBodyPost = {
                    initDateBroadcast: this.splitedEmit[0],
                    endDateBroadcast: this.splitedEmit[1],
                    initDateBilling: this.splitedFact[0],
                    endDateBilling: this.splitedFact[1],
                    initDateCreation: this.splitedCreat[0],
                    endDateCreation: this.splitedCreat[1],
                    liquidationType: this.getSelectedSubfilters(KeyReports.TIPO_LIQUIDACION),
                    accountId: this.getSelectedSubfilters(KeyReports.CUENTA_NEGOCIADORA),
                    invoiceType: this.getSelectedSubfilters(KeyReports.TIPO_FACTURA),
                    treated: this.getSelectedSubfilters(KeyReports.TRATADA),
                    council: this.getSelectedSubfilters(KeyReports.AYUNTAMIENTO),
                    clientType: this.getSelectedSubfilters(KeyReports.TIPO_DE_NEGOCIO),
                    distributor: this.getSelectedSubfilters(KeyReports.DISTRIBUIDORA),
                    society: this.getSelectedSubfilters(KeyReports.SOCIEDAD),
                    cups: this.getSelectedSubfilters(KeyReports.CUPS),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.CONCEPTOS_REPERCUTIBLES_ATR:
                this.selectSplitedDates();
                this.filtersBodyPost = {
                    initDateBroadcast: this.splitedEmit[0],
                    endDateBroadcast: this.splitedEmit[1],
                    initDateBilling: this.splitedFact[0],
                    endDateBilling: this.splitedFact[1],
                    initDateCreation: this.splitedCreat[0],
                    endDateCreation: this.splitedCreat[1],
                    liquidationType: this.getSelectedSubfilters(KeyReports.TIPO_LIQUIDACION),
                    treated: this.getSelectedSubfilters(KeyReports.TRATADA),
                    clientType: this.getSelectedSubfilters(KeyReports.TIPO_DE_NEGOCIO),
                    society: this.getSelectedSubfilters(KeyReports.SOCIEDAD),
                    invoiceFiscalCode: this.getSelectedSubfilters(KeyReports.FACTURA),
                    totalImport: this.getSelectedSubfilters(KeyReports.IMPORTE_TOTAL),
                    cups: this.getSelectedSubfilters(KeyReports.CUPS),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;

            case Reports.CONSUMOS_ATR_DISTRIBUIDORA:
                this.selectSplitedDates();
                this.filtersBodyPost = {
                    year: this.getSelectedSubfilters(KeyReports.AÑO),
                };
                this.deleteEmptyItems();
                this.postBodyReports();
                break;
        }
    }

    getUnitsPrograms(programUnit: any) {
        this.programUnit = programUnit.value;
    }

    getRates(rate: any) {
        this.rate = rate.value;
    }

    getSelfsupply(selfConsumption: any) {
        this.selfConsumption = selfConsumption.value;
    }

    getSelectedOptionElectrictSystem(eSystem: any) {
        if (eSystem.value) {
            this.electrictSystemSelected = true;
        } else {
            this.electrictSystemSelected = false;
        }
    }

    getOption(event: any) {
        this.selectedName = '';
        if (this.inputValues.find((inpVal) => inpVal.id === event.id) && this.showSelfConsumption) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = "Perdidas *=No";
        } else if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }
    }

    checkConditions(filtro: string[] | undefined): boolean {
        if (this.showinput === false) {
            return true;
        }
        if (!filtro || !filtro.length) {
            return true;
        }
        let solution = false;
        filtro.forEach((cond) => {
            if (this.inputValues.some((inpVal) => inpVal.value === cond)) {
                solution = true;
            }
        });
        return solution;
    }

    receiveDate($event: any, filtro: any, reset?: boolean) {
        //El if es para que funcione cuando no es un rango de fechas
        if(this.isDateRange){
            this.differenceInDays =
            (new Date($event.split('~')[1]).getTime() - new Date($event.split('~')[0]).getTime()) / (1000 * 3600 * 24);
        }

        if ($event.newValue && ($event.newValue !== undefined || $event.newValue !== '')) {
            this.singleDateSelected = true;
            this.subfilters[filtro].selected = $event.newValue;
        }
        if (this.subfilters && !$event.newValue) {
            this.subfilters[filtro].selected = $event;
        }
        if (this.subfilters[filtro].selected !== '') {
            this.isDisabledYear = true;
        }
        this.isDisableButtonRequired();
    }

    restartValue(event: any, filtro: any, key: string, filtroType?: any) {
        this.formatSelectedValue(event, filtro, key, filtroType);
        if (this.subfilters && event.newValue) {
            this.subfilters[filtro].selected = event.newValue;
            this.isDisableButtonRequired();
        }
    }

    /**
     * Función que dependiendo del filtro formatea los datos llegados desde el evento para su correcto envío al endpoint más tarde.
     * @param event 
     * @param filtro 
     * @param key 
     * @param filtroType 
     */
    formatSelectedValue(event: any, filtro: any, key: string, filtroType: string) {
        if (
            this.selectId.filtrosCategory === Reports.ANALISIS_RESULTADOS &&
            key === KeyReports.CUENTA_NEGOCIADORA &&
            event.newValue !== undefined &&
            event.newValue !== ''
        ) {
            this.isDisableSubfilters = true;
        }

        if (filtroType === 'SelectMonth' || filtroType === 'SelectYear') {
            if (this.subfilters && event.value) {
                if (filtroType === 'SelectMonth') {
                    this.monthSelected = true;
                    this.selectedMonth = event.value;
                } else if (filtroType === 'SelectYear') {
                    this.yearSelected = true;
                    this.selectedYear = event.value;
                }
                // Condicionamiento especial para cuando se use el filtro de año solo
                if (filtroType === 'SelectYear' && filtro == this.subfilters.length) {
                    this.subfilters[filtro-1].selected = event.value;
                } else {
                    this.subfilters[filtro].selected = event.value;
                }
            }
        }

        if (
            ((this.subfilters[0].type === 'SelectMonth' || this.subfilters[1].type === 'SelectYear')) &&
            (this.subfilters[0].selected !== '' ||
            this.subfilters[1]?.selected !== '')
        ) {
            this.isDisabledDate = true;
        }

        if (filtroType !== 'Date' && this.subfilters && filtroType !== 'SelectMonth' && filtroType !== 'SelectYear') {
            this.subfilters[filtro].selected = '';

            if (this.subfilters[filtro].id === KeyReports.ID_TIPO_INFORME && key === KeyReports.TIPO_DE_INFORME) {
                this.subfilters[2].selected = '';
                this.subfilters[3].selected = '';
            }

            if (
                key !== KeyReports.TARIFAS &&
                key !== KeyReports.CUPS &&
                key !== KeyReports.CUENTA_NEGOCIADORA &&
                key !== KeyReports.SOCIEDAD &&
                key !== KeyReports.SOCIEDAD_ACCIONA &&
                key !== KeyReports.TIPO_PUNTO_MEDIDA &&
                key !== KeyReports.TIPO_AUTOCONSUMO &&
                key !== KeyReports.FACTURA && 
                key !== KeyReports.IMPORTE_TOTAL &&
                key !== KeyReports.DISTRIBUIDORA &&
                key !== KeyReports.AYUNTAMIENTO
            ) {
                this.subfilters[filtro].selected = event.eventValue.id;
            } else if (key === KeyReports.CUENTA_NEGOCIADORA || 
                key === KeyReports.SOCIEDAD ||
                key === KeyReports.DISTRIBUIDORA ||
                key === KeyReports.AYUNTAMIENTO) {
                this.subfilters[filtro].selected = event.eventValue.value;
            } else if (key === KeyReports.TARIFAS) {
                this.subfilters[filtro].selected = event.value;
            } else if (key === KeyReports.SOCIEDAD_ACCIONA) {
                this.subfilters[filtro].selected = event.newValue;
            } else if (key === KeyReports.CUPS) {
                if (this.maestroService.validCups === '') {
                    this.maestroService.validCups = undefined;
                } else {
                    this.cups = this.maestroService.validCups.split(';');
                    if (
                        (this.selectId.filtrosCategory !== Reports.CHECK_CIERRES &&
                        this.selectId.filtrosCategory !== Reports.CHECK_MEDIDAS) || ((this.selectId.filtrosCategory === Reports.CHECK_CIERRES ||
                            this.selectId.filtrosCategory === Reports.CHECK_MEDIDAS) &&
                        this.cups.length === 1)
                    ) {
                        this.subfilters[filtro].selected = this.cups;
                        this.isMoreThanOneCups = false;
                    } else if (
                        (this.selectId.filtrosCategory === Reports.CHECK_CIERRES ||
                            this.selectId.filtrosCategory === Reports.CHECK_MEDIDAS) &&
                        this.cups.length > 1
                    ) {
                        this.subfilters[filtro].selected = this.cups;
                        this.isMoreThanOneCups = true;
                    }
                }
            } else if (key === KeyReports.TIPO_PUNTO_MEDIDA) {
                let eventValue = event.eventValue.id.split(',');
                this.subfilters[filtro].selected = eventValue;
            } else if (key === KeyReports.FACTURA || key === KeyReports.IMPORTE_TOTAL) {
                this.subfilters[filtro].selected = event.newValue;
            } else if (key === KeyReports.TIPO_AUTOCONSUMO) {
                this.subfilters[filtro].selected = event.value;
            }

            // Rellena el tipo autoconsumo y el tipo de pérdida o hace desaparecer el tipo de autoconsumo cuando sea necesario
            // en previsiones y agregado de previsiones
            if (this.selectedReportName === Reports.PREVISIONES_HORARIAS || this.selectedReportName === Reports.PREVISIONES_AGREGADO) {
                let typeProvisions = this.subfilters?.some((s:any) => s.key === 'type') ? 
                    this.subfilters?.find((f: any) => f.key === 'type').selected == 'Provisiones' : false;
                if (!this.showSelfConsumption && typeProvisions) {
                    const subfiltersKeyType = this.subfilters?.filter((f: any) => f.id === event.name);
                    if (subfiltersKeyType[0].id === '20' && (subfiltersKeyType[0].selected == 'measureBestOut' || subfiltersKeyType[0].selected == 'provisionOut')) {
                        // Autoconsumos
                        this.showSelfConsumption = true;
                        let subfilter = this.subfilters?.find((f: any) => f.key == KeyReports.TIPO_AUTOCONSUMO);
                        this._reportsServices.getSelfConsumption().subscribe((self) => {
                            var parameterizs: any[] = [];
                            subfilter.data = [];
                            self.data.forEach((element: any) => {
                                if (element.selfsupplyType != '00') {
                                    subfilter.data.push({ name: element.parameterization, value: element.selfsupplyType });
                                    parameterizs.push(element.parameterization);
                                }
                            });
                            this.selfConsumptionList = new Set(parameterizs);
                        });
                        // Pérdida
                        this._setLosses(true);
                    } else {
                        this.showSelfConsumption = false;
                        this.subfilters.find((f: any) => f.key === 'selfConsumptionType').selected = "";
                        if (key === KeyReports.PERDIDAS) {
                            this.subfilters[filtro].selected = event.eventValue.id
                        }
                    }
                } else if (this.showSelfConsumption && typeProvisions) {
                    if (key != KeyReports.TIPO_AUTOCONSUMO 
                        && ['forecastBest', 'measureBestIn', 'provisionIn'].includes(event.eventValue.id)
                        && event.eventValue.id != 'measureBestOut'
                        && event.eventValue.id != 'provisionOut') {
                        this.showSelfConsumption = false;
                        this.subfilters.find((f: any) => f.key === 'selfConsumptionType').selected = "";
                        this._setLosses(false);
                    }
                } else if (this.showSelfConsumption && !typeProvisions){
                    this.showSelfConsumption = false;
                    this.subfilters.find((f: any) => f.key === 'selfConsumptionType').selected = "";
                    this._setLosses(false);
                    this.getOption({id: "1205",value: "Tipo de informe *=Previsiones"});
                } else if (!this.showSelfConsumption && !typeProvisions) {
                    _.isNil(event.eventValue.id) === true ? this.subfilters[filtro].selected = event.eventValue.value : this.subfilters[filtro].selected = event.eventValue.id;
                }
            }

            if (this.isDisableSubfilters === true && this.count === 0) {
                this.count = 1;
                let filter = this.subfilters.filter(
                    (ft: any) =>
                        ft.key !== 'accountId' &&
                        ft.key !== 'results' &&
                        ft.key !== 'month' &&
                        ft.key !== 'year' &&
                        ft.selected !== ''
                );

                if (this.unitProgramList.length !== 0 && this.unitProgramList !== undefined) {
                    this.copyUnitProgramList = this.unitProgramList;
                }

                if (this.electricSystemList.length !== 0 && this.electricSystemList !== undefined) {
                    this.copyElectricSystemList = this.electricSystemList;
                }

                if (this.programUnit !== undefined && this.programUnit.length !== 0) {
                    this.copyProgramUnit = this.programUnit;
                }

                if (this.rateList.length !== 0 && this.rateList !== undefined) {
                    this.copyRateList = this.rateList;
                }
                
                filter.forEach((element: any) => {
                    switch (element.key) {
                        case 'liquidationType':
                            this.copyLiquidationType = element.data;
                            break;
                        case 'clientType':
                            this.copyClientType = element.data;
                            break;
                        case 'rate':
                            this.copyRate = element.data;
                            break;
                    }
                    element.data = [];
                    element.selected = undefined;
                });
                this.unitProgramList = [];
                this.electricSystemList = [];
                this.programUnit = undefined;
                this.rateList = [];
            }
        }
        this.isDisableButtonRequired();
    }

    reset() {
        if (this.subfilters != undefined) {
            this.subfilters.forEach(function (e: any) {
                delete e['selected'];
            });
        }
        if (this.programUnit) {
            delete this.programUnit;
        }
    }

    /**
     * Función que limpia los filtros reseteándo sus valores a lo original.
     */
    clearFilters() {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.count = 0;
        if (this.selectId.filtrosCategory === Reports.ANALISIS_RESULTADOS) {
            let subfilterRate = this.subfilters?.find((f: any) => f.key === KeyReports.TARIFAS);
            if (this.copyRate !== undefined) {
                subfilterRate.data = this.copyRate;
            }
        }
        this.unitProgramList = this.copyUnitProgramList;
        this.electricSystemList = this.copyElectricSystemList;
        this.programUnit = this.copyProgramUnit;
        this.rateList = this.copyRateList;
        this.singleDateSelected = false;
        this.isMessageClarify = false;
        this.isAnalysisResults = false;
        this.messageClarifyRequiredFilters();
        this.yearSelected = false;
        this.monthSelected = false;
        this.emissionDifferenceInDays = 0;
        this.billingDifferenceInDays = 0;
        this.creationDifferenceInDays = 0;
        this.isMoreThanOneCups = false;
        this.isReportSelected = false;
        this.programUnit = '';
        this.unitProgramList = [];
        this.isShowSelect = true;
        this.electrictSystemSelected = false;
        this.rateList = [];
        this.resetCups = true;
        this.copyReportsFilters = [];
        this.reportFilters = [];
        this.systemFilter = [];
        this._errorHttpServ.hide();
        this.specialFeeActivated = false;
        this.isFirstTime = true;
        this.isDisabled = true;
        let subfilter = this.subfilters?.find((f: any) => f.key === KeyReports.CUENTA_NEGOCIADORA);
        let subfilter1 = this.subfilters?.find((f: any) => f.key === KeyReports.SOCIEDAD);
        let subfilter2 = this.subfilters?.find((f: any) => f.key === KeyReports.DISTRIBUIDORA);
        let subfilter3 = this.subfilters?.find((f: any) => f.key === KeyReports.AYUNTAMIENTO);
        this.isDisabledDate = false;
        this.isDisabledYear = false;
        this.isDisableSubfilters = false;
        if (subfilter !== undefined && subfilter.selected !== '') {
            subfilter.data = [];
            subfilter.selected = '';
        }
        if (subfilter1 !== undefined && subfilter1.selected !== '') {
            subfilter1.data = [];
            subfilter1.selected = '';
        }
        if (subfilter2 !== undefined && subfilter2.selected !== '') {
            subfilter2.data = [];
            subfilter2.selected = '';
        }
        if (subfilter3 !== undefined && subfilter3.selected !== '') {
            subfilter3.data = [];
            subfilter3.selected = '';
        }
        this.subfilters = [];
        this.formGroup = new FormGroup({
            month: new FormControl(undefined),
            year: new FormControl(undefined),
        });
        this.ngOnInit();
    }

    resetComplete($event: any) {
        this.resetCups = $event;
    }

    hideSelect() {
        this.isShowSelect = false;
    }

    filterUnitProgram(electricSystemSelected: any) {
        electricSystemSelected === 'Península'
            ? (electricSystemSelected = 'PENINSULA')
            : (electricSystemSelected = electricSystemSelected.toUpperCase());
        let unitProgramFilter: any[] = [];
        this.unitProgramListNoFilter.forEach((element: any) => {
            if (element.electricSystem === electricSystemSelected) {
                unitProgramFilter.push(element);
            }
        });

        this.unitProgramList = [];
        this.programUnit = [];
        this.unitProgramList = unitProgramFilter;
        this.unitProgramList = this.unitProgramList.map((uP: any) => uP.programUnitCode);
        this.unitPrograms = new FormControl(this.unitProgramList);
        this.programUnit = this.unitProgramList;
    }

    activeToggleDeleteRegisters() {
        if (this.toggleActive) {
            this.toggleActive = false;
        } else {
            this.toggleActive = true;
        }
    }

    getReportConcept() {
        this._reportsServices.getConceptsReports().subscribe((res: any) => {
            // Deletes dashboard, potential objections and distributor objections reports
            res = res.data
                ? res.data.filter(
                      (e: any) => e.reportCode !== 'OBJ001' && e.reportCode !== 'OBJ002' && e.reportCode !== 'GM0009'
                  )
                : res;
            res.forEach((element: any) => {
                element.name = element.name.charAt(0).toUpperCase() + element.name.slice(1).toLowerCase();
            });
            this.reportFilters = res;
            this.copyReportsFilters = [];
            this.matRefReport.options.forEach((data: MatOption) => data.deselect());
            this.copyReportsFilters = this.reportFilters;
            this.subfilters = [];
        });
    }

    generateYears() {
        const actualYear = new Date().getFullYear();
        this.years = Array.from({ length: 9 }, (element, index) => actualYear - 2 + index);
        return this.years;
    }

    monthDisplay(month: number): string {
        return Months[month];
    }

    _loadNotification(error?: string) {
        this._translateService.get('PAGE.MODALS').subscribe((message) => {
            this._snackBar.open(message[`${error ? error : 'LOADING_MESSAGE_REPORTS'}`], message['ACTION_CLOSE'], {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        });
        setTimeout(() => {
            this._snackBar.dismiss();
        }, 4000);
    }

    getSelectedSubfilters(key: string) {
        if (key === KeyReports.TIPO_DE_NEGOCIO) {
            this.subfilters = this.subfilters.map((fil: any) => {
                if (fil.key === KeyReports.TIPO_DE_NEGOCIO && fil.selected && fil.selected.includes('[')) {
                    fil.selected = JSON.parse(fil.selected);
                }
                return fil;
            });
        } else if (key === KeyReports.TIPO_AUTOCONSUMO) {
            var subfilter = this.subfilters?.find((f: any) => f.key === key);
            this.selfsupplySubList = [];
            subfilter.data.forEach((element:any) => {
                if (element.name == subfilter.selected) {
                    this.selfsupplySubList.push(element.value);
                }
            });
            return this.selfsupplySubList;
        }
        var subfilter = this.subfilters?.find((f: any) => f.key === key);
        return subfilter==null || subfilter==undefined ? '' : subfilter.selected;
    }

    getFiltersBodyPost() {
        this.forecastTypeSelected = this.subfilters.filter(
            (ft: any) => ft.key === KeyReports.ORIGEN_DE_DATOS && ft.selected !== ''
        );
        if (this.forecastTypeSelected.length !== 0) {
            this.forecastType = this.forecastTypeSelected[0];
        }

        this.filtersBodyPost = {
            initDate: this.splitedDates[0],
            endDate: this.splitedDates[1],
            liquidationType: this.getSelectedSubfilters(KeyReports.TIPO_LIQUIDACION),
            forecastType: this.forecastType.selected,
            clientType: this.getSelectedSubfilters(KeyReports.TIPO_DE_NEGOCIO),
            programUnit: this.programUnit,
            rate: this.getSelectedSubfilters(KeyReports.TARIFAS),
            accountId: this.getSelectedSubfilters(KeyReports.CUENTA_NEGOCIADORA),
            losses: this.getSelectedSubfilters(KeyReports.PERDIDAS),
        };

        const selfConsumptionData = this.getSelectedSubfilters(KeyReports.TIPO_AUTOCONSUMO);
        if (selfConsumptionData.length > 0) {
            this.filtersBodyPost.selfConsumptionType = selfConsumptionData;
        }
    }

    deleteEmptyItems() {
        this.subfilters.forEach((element: any) => {
            if (element.selected === undefined) {
                element.selected = '';
            }
            if (
                (element.selected === '' ||
                (Array.isArray(element.selected) && element.selected.length === 0)) &&
                    (this.filtersBodyPost[element.key] === undefined ||
                        this.filtersBodyPost[element.key] === '' ||
                        this.filtersBodyPost[element.key][0] === '') &&
                    (element.key !== ' ' || this.programUnit === undefined) ||
                element.selected[0] === ''
            ) {
                switch (element.key){
                    case "liquidation":
                        delete this.filtersBodyPost['liquidationREE'];
                        break;
                    case "code":
                        delete this.filtersBodyPost['priceCode'];
                        break;
                    default:
                        delete this.filtersBodyPost[element.key];
                        break;
                }
                
            }
        });
        this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
    }

    postBodyReports() {
        let postBody: any;
        postBody = {
            reportCode: this.selectId.reportCode,
            name: this.selectId.queueName,
            filters: this.filtersBodyPost,
        };
        if (!this.isMoreThanOneCups) {
            this._reportsServices
                .launchInformesPostPrevisiones(postBody)
                .subscribe((uF: { status: string | undefined }) => {
                    if (uF && uF.status !== undefined && uF.status === 'In Progress') {
                        this._loadNotification();
                    } else {
                        this._loadNotification('REPORT_LAUNCH_ERROR');
                    }
                });
            this.filtersBodyPost = '';
        } else {
            this.isModalOpened = true;
            this.openModal(true);
        }
    }

    // Inicializa las vaiables globales de todos los rangos de fechas. Si está vacío se inicializa a undefined.
    selectSplitedDates() {
        if (this.getSelectedSubfilters(KeyReports.RANGO_FECHAS) !== '') {
            this.splitedDates = this.getSelectedSubfilters(KeyReports.RANGO_FECHAS).split('~');
        } else {
            this.splitedDates = this.getSelectedSubfilters(KeyReports.RANGO_FECHAS).split('~');
            this.splitedDates[0] = undefined;
            this.splitedDates[1] = undefined;
        }
        if (this.getSelectedSubfilters(KeyReports.RANGO_EMISION) !== '') {
            this.splitedEmit = this.getSelectedSubfilters(KeyReports.RANGO_EMISION).split('~');
        } else {
            this.splitedEmit = this.getSelectedSubfilters(KeyReports.RANGO_EMISION).split('~');
            this.splitedEmit[0] = undefined;
            this.splitedEmit[1] = undefined;
        }
        if (this.getSelectedSubfilters(KeyReports.RANGO_FACTURACION) !== '') {
            this.splitedFact = this.getSelectedSubfilters(KeyReports.RANGO_FACTURACION).split('~');
        } else {
            this.splitedFact = this.getSelectedSubfilters(KeyReports.RANGO_FACTURACION).split('~');
            this.splitedFact[0] = undefined;
            this.splitedFact[1] = undefined;
        }
        if (this.getSelectedSubfilters(KeyReports.RANGO_CREACION) !== '') {
            this.splitedCreat = this.getSelectedSubfilters(KeyReports.RANGO_CREACION).split('~');
        } else {
            this.splitedCreat = this.getSelectedSubfilters(KeyReports.RANGO_CREACION).split('~');
            this.splitedCreat[0] = undefined;
            this.splitedCreat[1] = undefined;
        }
    }

    messageClarifyRequiredFilters(): boolean {
        if (this.selectId !== undefined) {
            if (
                this.selectId.filtrosCategory === Reports.CHECK_CIERRES ||
                this.selectId.filtrosCategory === Reports.CHECK_MEDIDAS
            ) {
                this.isMessageClarify = true;
            }
            if (this.selectId.filtrosCategory === Reports.ANALISIS_RESULTADOS) {
                this.isMessageClarify = true;
                this.isAnalysisResults = true;
            }
        }
        return this.isMessageClarify;
    }

    openModal(operation: boolean) {
        if (operation) {
            this.isModalOpened = true;
        } else {
            this.isModalOpened = false;
        }
    }

    closeModal() {
        this.openModal(false);
    }

    /** 
    * En esta función actualizamos los datos que se enviarán posteriormente de los subfiltros.
    * Concretamente se actualizan los datos relacionados con el multiselect de estado del contrato.
    *    @param opcion - any (Formato { key: string; value: string[] }) 
    */
    protected filterContract(opcion: any): void {
        this.subfilters?.forEach((subfilter: any) => {
            if (subfilter.key === opcion.key && [KeyReports.LIQUIDACION, KeyReports.CODIGO].includes(subfilter.key)) {
                subfilter.selected = opcion.value;
              }
        });
    }

    /**
     * Función que rellena de datos aquellos filtros que necesiten obtener sus datos de un endpoint.
     * Si en un futuro se añaden más filtros así, habra que rellenar esta función con los sucesivos casos.
     */
    private fillSelect() {
        /* En este 'if' se rellenan los datos de los filtros de Liquidación y Código */
        if (this.selectedReportName == Reports.PRECIOS_INDEXADOS) {
            let liquidations: any = [];
            let codes: any = [];

            this.maestroService.getLiquidations().subscribe((resp: any) => {
                resp.data.forEach((element: any) => {
                    if (element.area === 'Precios') {
                        if (element.origin == 'M') {
                            liquidations.push({ name: 'Inicial' });
                        } else {
                            liquidations.push({ name: element.origin });
                        }
                    }
                });
                liquidations.push({ name: 'Mejor Cierre'})
                liquidations.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name));
                this.subfilters?.forEach((subfilter: any) => {
                    if (subfilter.key === KeyReports.LIQUIDACION) {
                        subfilter.data = liquidations;
                    }
                });
            });

            this.maestroService.getPreciosCode().subscribe((code) => {
                code.data.forEach((element: any) => {
                    if (element.type === 'Hourly' && element.description.endsWith('(€/MWh)')) {
                        codes.push({
                            code: element.priceCode,
                            name: element.priceCode,
                            description: element.description,
                        });
                    }
                });
                this.subfilters?.forEach((subfilter: any) => {
                    if (subfilter.key === KeyReports.CODIGO) {
                        subfilter.data = codes;
                    }
                });
            });
        }
    }

    private _setLosses(activated:boolean){
        this.selects.forEach((selectComponent: FiltroSelectComponent) => {
            if (selectComponent.key === KeyReports.PERDIDAS) {
                selectComponent.resetSelect();
                selectComponent.bankCtrl.reset();
            }
        });
        let subfiltroPerdidas = this.subfilters?.find((f: any) => f.key == KeyReports.PERDIDAS);
        if (activated) {
            subfiltroPerdidas.selected = "no";
            this.inputValues.push({id:subfiltroPerdidas.id,value:"Perdidas *=No"});
        } else if (this.inputValues.find((inpVal) => inpVal.id === subfiltroPerdidas.id)) {
            subfiltroPerdidas.selected = "";
            this.inputValues.find((inpVal) => inpVal.id === subfiltroPerdidas.id)!.value = "";
        }
    }
}
