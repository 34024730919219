import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList, OnChanges } from '@angular/core';
import { UntypedFormControl, FormGroup, FormControl } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { Concepto } from 'src/app/shared/models/concepto.interface';
import { ListaFiltros, Filtro, FiltroSelect } from 'src/app/shared/models/filtros.interface';
import { ProcesosService } from '../../services/procesos.service';
import { FiltroSelectComponent } from '../../../../shared/components/filtros/filtro-select/filtro-select.component';
import { KeysMaestro, MaestrosAWS, MaestrosCRM } from 'src/app/shared/enums/maestros';
import { MaestroService } from 'src/app/pages/maestro/services/maestro.service';
import { ContractClientType, ContractStatusEnum, liqTypeEnum } from 'src/app/shared/enums/contractEnums';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Months } from 'src/app/shared/components/custom-cron/custom-cron.enum';
import { Concepts, SistemasProcesosEnum } from 'src/app/shared/enums/procesos';
import { LoginService } from 'src/app/core/services/login.service';
import { SistemaProcesosOption } from 'src/app/shared/interfaces/procesos.interface';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-select-procesos',
    templateUrl: './select-procesos.component.html',
    styleUrls: ['./select-procesos.component.scss'],
})
export class SelectProcesosComponent implements OnInit, OnChanges {
    @ViewChildren('selects') selects: QueryList<FiltroSelectComponent>;

    @Output() optionSelectedId = new EventEmitter<string>();
    @Output() resetTable = new EventEmitter<any>();
    @Output() OptionFilters = new EventEmitter<any>();
    @Output() changeSelectReset = new EventEmitter<any>();
    @Output() filtersList = new EventEmitter<any>();

    @Input() tipoConcepto: string;
    @Input() value: any;
    @Input() disableFilter: boolean = false;
    @Input() disableMonthFilter: boolean = false;
    @Input() disableYearFilter: boolean = false;
    @Input() enableFilterConcept: boolean;
    @Input() refilledFiltersToSend: any;
    @Input() closeType?: any;
    @Input() reset$: Subject<void>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    durationInSeconds = 5;

    selectOptionsYears = this.generateYears();

    formGroup = new FormGroup({
        month: new FormControl(undefined),
        year: new FormControl(undefined),
    });

    unitPrograms = new FormControl('');
    subfilters: Filtro[] | undefined;
    displayedSubfilters: Filtro[] = [];
    selectSubfilter: FiltroSelect[];
    conceptosProcesos: Concepto[] = [];
    filters: ListaFiltros[] = [];
    disableSelect = new UntypedFormControl(false);
    subcriptions: Subscription[] = [];
    tablaSeleccionada: string | undefined;
    selectId: any;
    isDisabled: boolean = true;
    inputValues: { id: string; value: string }[] = [];
    resetSelectedOption: any;
    resetSubfilter: any;
    showinput: any;
    showSelect: boolean = true;
    intermedio: boolean = true;
    resetCups: boolean;
    defaultSelect: string = '';
    toggleActive: boolean = false;
    specialFeeActivated?: boolean = undefined;
    disableSelectFirst: boolean = false;
    titleToggle = 'PAGE.FILTERS.DEFAULT_EXECUTION';
    // booleano para controlar si filtrar los filtros por ejemplo por codigo o por descripcion
    searchBy: boolean = false;
    disableToggle: boolean = true;
    unitProgramList: any;
    unitProgramListNoFilter: any;
    electricSystemList: any;
    cuentaNegociadoraActive: boolean;
    negotiatingAccountCode: string | undefined = undefined;

    eventvalue: any;
    rateList: any;

    //POST//
    filtersBodyPost: any;

    cups: any;
    processCodePOST: any;
    nameProcessPOST: any;
    defaultExecution: any;

    rate: any;
    programUnit: any = [];
    liquidationType: any;
    clientType: any;
    accountId: any;
    initDate: any;
    endDate: any;

    measureInitDate: any;
    measureEndDate: any;
    forecastInitDate: any;
    forecastEndDate: any;
    society: any;

    months = Array.from({ length: 12 }, (_v, k) => k + 1);
    selectedYear: string | number;
    selectedMonth: string | number;

    electrictSystemSelected: boolean = false;
    isInitialForecastsSelected = false;
    previsionesProyectadasSelected = false;
    dateRangeSameMonth: boolean = false;
    yearSelected: boolean = false;
    monthSelected: boolean = false;

    processConceptsList: any;
    filteredProcessConceptsList: any;
    userRoles: string[] = [];
    selectSystems: SistemaProcesosOption[] = [];
    isOnlyQueryRole: boolean = false;
    selectCopy: any;

    constructor(
        public maestroService: MaestroService,
        protected readonly _headerTitleService: HeaderTitleService,
        private readonly procesosService: ProcesosService,
        private readonly globalService: GlobalService,
        private readonly translateService: TranslateService,
        private readonly _snackBar: MatSnackBar,
        private readonly _loginService: LoginService
    ) {}

    ngOnInit(): void {
        this.maestroService.validCups = undefined;
        this.isOnlyQueryRole = false;
        this.getConcepts();
        this.getFilters(this.searchBy);
        this.resetCups = false;
        this.procesosService.getUnidadPrograma().subscribe((UP) => {
            this.unitProgramListNoFilter = UP.data;
            this.unitProgramList = UP.data;
            this.electricSystemList = [];

            UP.data.forEach((data: any) => {
                this.electricSystemList.push(data.electricSystem);
            });

            let electricSystemFilter: string[] = Array.from(new Set(this.electricSystemList));
            electricSystemFilter = electricSystemFilter.map(function (word) {
                word === 'PENINSULA'
                    ? (word = 'Península')
                    : (word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                return word;
            });
            //Se filtra la lista de "Sist. eléctrico" para eliminar elementos repetidos
            this.electricSystemList = electricSystemFilter;
        });

        this.maestroService.getFeesCode().subscribe((RATES) => {
            this.rateList = [];
            for (let i = 0; i < RATES?.data?.length; i++) {
                this.rateList.push(RATES.data[i].codeRate);
            }
        });

        this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
        this.userRoles = this.userRoles.map((element: any) => element.trim());
        if (this.userRoles.length === 1 && this.userRoles[0] === 'CONSULTA') {
            this.isOnlyQueryRole = true;
        }
        this.selectSystems = [
            {
                id: 1,
                name: SistemasProcesosEnum.MEDIDAS,
                translation: 'MEASURES',
            },
            {
                id: 2,
                name: SistemasProcesosEnum.PREVISIONES,
                translation: 'FORECAST',
            },
        ];
        this._populateSystemsByUserRoles();
        this.procesosService.getProcessConcepts().subscribe((processConcepts) => {
            this.processConceptsList = [];
            processConcepts.data.forEach((process: any) => {
                const firstChar = process.processCode.substring(0, 1);
                if (firstChar !== 'A') {
                    process.name = process.name.charAt(0).toUpperCase() + process.name.slice(1).toLowerCase();
                    this.processConceptsList.push(process);
                }
            });
            this._populateConceptsByUserRoles();
        });

        this.reset$?.subscribe(() => {
            this.formGroup.reset();
        });
        this.isDisableButtonRequired();
    }

    ngOnChanges() {
        if (this.value) {
            this.getSubfilters(this.value.name);
            this.getSelectId(this.value.name);
        }
    }

    private _populateSystemsByUserRoles(): void {
        if (!this.userRoles.includes('ADMIN')) {
            if (!this.userRoles.includes('MEDIDA')) {
                this.selectSystems = this.selectSystems.filter(
                    (system) => system.name !== SistemasProcesosEnum.MEDIDAS
                );
            }
            if (!this.userRoles.includes('PREVISION')) {
                this.selectSystems = this.selectSystems.filter(
                    (system) => system.name !== SistemasProcesosEnum.PREVISIONES
                );
            }
        }
    }

    private _populateConceptsByUserRoles(): void {
        this.filteredProcessConceptsList = this.processConceptsList;
        if (!this.userRoles.includes('ADMIN')) {
            if (!this.userRoles.includes('MEDIDA')) {
                this.filteredProcessConceptsList = this.filteredProcessConceptsList.filter(
                    (concept: any) => concept.area !== SistemasProcesosEnum.MEDIDAS
                );
            }
            if (!this.userRoles.includes('PREVISION')) {
                this.filteredProcessConceptsList = this.filteredProcessConceptsList.filter(
                    (concept: any) => concept.area !== SistemasProcesosEnum.PREVISIONES
                );
            }
            if (!this.userRoles.includes('CONCILIACION')) {
                this.filteredProcessConceptsList = this.filteredProcessConceptsList.filter(
                    (concept: any) => concept.area !== SistemasProcesosEnum.CONCILIACION
                );
            }
        }
    }

    onSelectChangeTypes(selectedType: string) {
        if (selectedType === 'reset') {
            this._populateConceptsByUserRoles();
        }
        if (selectedType === SistemasProcesosEnum.MEDIDAS) {
            this.filteredProcessConceptsList = this.processConceptsList.filter(
                (concept: any) => concept.area === SistemasProcesosEnum.MEDIDAS
            );
        }
        if (selectedType === SistemasProcesosEnum.PREVISIONES) {
            this.filteredProcessConceptsList = this.processConceptsList.filter(
                (concept: any) => concept.area === SistemasProcesosEnum.PREVISIONES
            );
        }
        this.resetFilters();
        this.subfilters = [];
        this.isDisabled = true;
    }

    /**
     * Metodo para controlar el booleano de filtrar por
     * @param searchBy  Boolean para controlar si filtrar por una cosa u otra
     */
    getFiltersBy(searchBy: [boolean, string]) {
        let subfilter = this.subfilters?.find((filter: any) => filter.key === searchBy[1]);
        if (subfilter) {
            subfilter.toggleState = searchBy[0];
        }
    }

    getConcepts() {
        if (this.tipoConcepto === 'procesos') {
            this.procesosService.getConceptsProcesosLanzar().subscribe((res) => {
                this.conceptosProcesos = res;
            });
        }
    }

    getFilters(filterByBoolean: boolean) {
        this.subcriptions.push(
            this.globalService.getAllFilters(filterByBoolean).subscribe((filtros) => (this.filters = filtros.data))
        );
    }

    getSubfilters(process: any) {
        let selectedOption = this.processConceptsList?.find((p: any) => p.name === process);
        this.formGroup = new FormGroup({
            month: new FormControl(undefined),
            year: new FormControl(undefined),
        });
        this.yearSelected = false;
        this.monthSelected = false;
        this.toggleActive = false;
        this.setCategoryFilters(selectedOption);
        this.resetTable.emit();
        this.resetSelectedOption = selectedOption;
        this.subfilters = this.filters.find((fil) => fil?.name === selectedOption?.filtrosCategory)?.filters;
        if (
            this.selects.first !== undefined &&
            this.selects.first.bankCtrl !== undefined &&
            this.subfilters !== undefined
        ) {
            this.subfilters[1].data = [{ name: 'adios' }];
            this.selects.first.bankCtrl.setValue({
                name: 'adios',
            });
            this.selects.first.bankSearchCtrl.setValue(['']);
        }
        this.inputValues = [];

        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element) => {
                if (element.required) {
                    if (!element.name.includes('*')) {
                        element.name = element.name + ' *';
                    }
                }
            });
        }
        this.filtersList.emit(this.subfilters);
        this.accountId = undefined;
        this.maestroService.validCups = undefined;
        this.clientType = undefined;
        this.liquidationType = undefined;
        this.isDisableButtonRequired();
        this.unitPrograms.reset();
    }

    private setCategoryFilters(selectedOption: any) {
        if (selectedOption?.processCode === 'PR0003') {
            selectedOption.filtrosCategory = 'procesos_previsionesProyectadas';
        }

        if (selectedOption?.processCode === 'PR0002') {
            selectedOption.filtrosCategory = 'procesos_previsionesIniciales';
        }

        if (selectedOption?.processCode === 'PR0004') {
            selectedOption.filtrosCategory = 'procesos_cierreVirtual';
        }

        if (selectedOption?.processCode === 'PR0005') {
            selectedOption.filtrosCategory = 'procesos_medidasObjeciones';
        }

        if (selectedOption?.processCode === 'PR0006') {
            selectedOption.filtrosCategory = 'procesos_medidasHorariosCuartoHorario';
        }

        if (selectedOption?.processCode === 'PR0007') {
            selectedOption.filtrosCategory = 'procesos_agregadoPrevisiones';
        }

        if (selectedOption?.processCode === 'PR0008') {
            selectedOption.filtrosCategory = 'procesos_envioMeteologica';
        }

        if (selectedOption?.processCode === 'PR0009') {
            selectedOption.filtrosCategory = 'procesos_analisisResultados';
        }

        if (selectedOption?.processCode === 'PR0011') {
            selectedOption.filtrosCategory = 'procesos_liquidacionPotestativa';
        }
    }

    obtainSelectSubfiltros() {
        this.subfilters?.filter((filter) => filter.type === 'Select').forEach((subfilter) => subfilter.url);
    }

    protected checkFilterEnablement(key: string | undefined): boolean {
        if (key) {
            switch (key) {
                case 'cups':
                    if (
                        (this.processCodePOST === 'PR0003' || this.processCodePOST === 'PR0002') &&
                        ((this.accountId &&
                            this.accountId !== '' &&
                            this.negotiatingAccountCode &&
                            this.negotiatingAccountCode !== '') ||
                            (this.defaultExecution && this.defaultExecution !== ''))
                    ) {
                        return true;
                    }
                    return false;
                case 'defaultExecution':
                    if (
                        this.processCodePOST === 'PR0002' &&
                        ((this.accountId &&
                            this.accountId !== '' &&
                            this.negotiatingAccountCode &&
                            this.negotiatingAccountCode !== '') ||
                            (this.maestroService.validCups && this.maestroService.validCups !== ''))
                    ) {
                        return true;
                    }
                    return false;
                case 'accountId':
                    if (
                        (this.processCodePOST === 'PR0003' || this.processCodePOST === 'PR0002') &&
                        ((this.defaultExecution && this.defaultExecution !== '') ||
                            this.toggleActive ||
                            (this.maestroService.validCups && this.maestroService.validCups !== ''))
                    ) {
                        return true;
                    }
                    return false;
                default:
                    if (
                        (this.processCodePOST === 'PR0003' || this.processCodePOST === 'PR0002') &&
                        ((this.defaultExecution && this.defaultExecution !== '') ||
                            (this.maestroService.validCups && this.maestroService.validCups !== '') ||
                            (this.accountId &&
                                this.accountId !== '' &&
                                this.negotiatingAccountCode &&
                                this.negotiatingAccountCode !== '') ||
                            this.toggleActive ||
                            this.cuentaNegociadoraActive)
                    ) {
                        return true;
                    }
                    return false;
            }
        }
        return false;
    }

    getSelectId(process: any) {
        const processAux = process || process.name;
        const selectedOption = this.processConceptsList?.find(
            (p: any) => p.name === processAux || p.name === processAux.name
        );
        this.processCodePOST = selectedOption?.processCode;
        this.nameProcessPOST = selectedOption?.name;

        if (this.processCodePOST === Concepts.PREVISIONES_INICIALES) {
            this.isInitialForecastsSelected = true;
            this.previsionesProyectadasSelected = false;
        } else {
            this.isInitialForecastsSelected = false;
            this.cuentaNegociadoraActive = false;
        }

        if (this.processCodePOST === Concepts.PREVISIONES_PROYECTADAS) {
            this.previsionesProyectadasSelected = true;
            this.isInitialForecastsSelected = false;
        } else {
            this.previsionesProyectadasSelected = false;
            this.cuentaNegociadoraActive = false;
        }

        this.subfilters?.forEach((data) => {
            if (data.selected !== undefined || this.subfilters !== null) {
                data.selected = '';
            }
        });

        this.resetSubfilter = selectedOption;
        this.selectId = selectedOption.id;
        this.showinput = undefined;
        this.isDisableButtonRequired();
    }

    isDisableButtonRequired() {
        let countRequired = 0;
        let countSelected = 0;
        if (this.subfilters) {
            this.subfilters.forEach((element) => {
                if (element.required) {
                    countRequired++;
                    if (element.selected) {
                        countSelected++;
                    }
                } else if (element.selected != '' && this.selectId === MaestrosCRM.CONTRATOS) {
                    countSelected++;
                }
            });
        }

        if (countRequired == 0) {
            this.isDisabled = false;

            if (countSelected < 1 && this.selectId === MaestrosCRM.CONTRATOS) {
                this.isDisabled = true;
            } else if (countSelected >= 1 && this.selectId === MaestrosCRM.CONTRATOS) {
                this.isDisabled = false;
            }
        } else {
            if (countRequired === countSelected) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        }

        if (this.dateRangeSameMonth) {
            this.isDisabled = true;
        }
        if (this.processCodePOST === 'PR0003' || (this.processCodePOST === 'PR0002' && this.subfilters)) {
            const filterIndex: number | undefined = this.subfilters?.findIndex(
                (fil: any) => fil.key === 'accountId' && fil.data?.length > 0
            );
            if (filterIndex && filterIndex !== -1) {
                this.cuentaNegociadoraActive = true;
            } else {
                this.cuentaNegociadoraActive = false;
            }
        }
    }

    resetFilters() {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this._headerTitleService.measureInitDate = '';
        this._headerTitleService.measureEndDate = '';
        this.showSelect = true;
        this.resetTable.emit(true);
        this.toggleActive = false;
        // Esto restablecer bien el filtro de unit Program al "Borrar"
        this.unitProgramList = this.unitProgramListNoFilter;
        this.maestroService.validCups = undefined;
        let subfilter = this.subfilters?.find(
            (f: any) =>
                f.key === KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA || f.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA
        );
        if (subfilter) {
            subfilter.data = [];
            subfilter.selected = undefined;
        }
        this.accountId = undefined;
        this.negotiatingAccountCode = undefined;
        this.cuentaNegociadoraActive = false;
        this.inputValues = [];
        this.rate = undefined;
        this.programUnit = [];
        this.liquidationType = undefined;
        this.clientType = undefined;
        this.accountId = undefined;
        this.initDate = undefined;
        this.endDate = undefined;
        this.closeType = undefined;
        this.measureInitDate = undefined;
        this.measureEndDate = undefined;
        this.forecastInitDate = undefined;
        this.forecastEndDate = undefined;
        this.society = undefined;
        this.filtersBodyPost = undefined;
        this.unitPrograms.reset();
        if (this.subfilters) {
            this.subfilters.forEach((element) => {
                if (element.selected !== '' || element.selected !== undefined) {
                    element.selected = undefined;
                }
            });
        }
        this.formGroup = new FormGroup({
            month: new FormControl(undefined),
            year: new FormControl(undefined),
        });
        this.yearSelected = false;
        this.monthSelected = false;
    }

    loadTable() {
        let postBody: any;
        let selectFilter: any = [this.selectId, this.subfilters];
        this.cups = this.maestroService.validCups;

        // POST PREVISIONES INICIALES...................
        if (this.processCodePOST === 'PR0002') {
            if (this.toggleActive) {
                this.defaultExecution = true;
            } else {
                this.defaultExecution = false;
            }

            //CASO 1, DEFAULT SELECTION ACTIVO
            if (this.defaultExecution === true) {
                this.filtersBodyPost = {
                    defaultExecution: this.defaultExecution,
                };
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                postBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
                this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
            }

            //CASO 2, CUPS
            if (this.cups !== undefined) {
                this.filtersBodyPost = {};
                if (this.cups && this.cups !== '') {
                    this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
                }
                this.filtersBodyPost = {
                    ...this.filtersBodyPost,
                    defaultExecution: this.defaultExecution,
                };
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                postBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
                this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
            }

            //CASO 3, CUENTA NEGOCIADORA
            if (this.accountId !== undefined) {
                this.filtersBodyPost = {
                    accountId: this.negotiatingAccountCode,
                    defaultExecution: this.defaultExecution,
                };
                if (!this.negotiatingAccountCode) {
                    delete this.filtersBodyPost.accountId;
                }
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                postBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
                this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((_) => {});
            }

            //CASO 4, RESTO DE FILTROS
            if (this.defaultExecution === false && this.cups === undefined && this.accountId === undefined) {
                this.filtersBodyPost = {
                    programUnit: this.programUnit,
                    rate: this.rate,
                    liquidationType: this.liquidationType,
                    clientType: this.clientType,
                    accountId: this.negotiatingAccountCode,
                    defaultExecution: this.defaultExecution,
                };
                if (!this.negotiatingAccountCode) {
                    delete this.filtersBodyPost.accountId;
                }
                if (
                    this.filtersBodyPost.programUnit &&
                    (this.filtersBodyPost.programUnit.length === 0 || this.filtersBodyPost.programUnit === '[]')
                ) {
                    delete this.filtersBodyPost.programUnit;
                }
                if (
                    this.filtersBodyPost.rate &&
                    (this.filtersBodyPost.rate.length === 0 || this.filtersBodyPost.rate === '[]')
                ) {
                    delete this.filtersBodyPost.rate;
                }
                if (this.cups && this.cups !== '') {
                    this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
                }
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                postBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
                this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
            }
        }
        // ...................POST PREVISIONES INICIALES

        // POST PREVISIONES PROYECTADAS...................
        if (this.processCodePOST === 'PR0003') {
            // CASO 1: CUPS
            if (this.cups !== undefined) {
                this.filtersBodyPost = {
                    measureInitDate: this.measureInitDate,
                    measureEndDate: this.measureEndDate,
                    forecastInitDate: this.forecastInitDate,
                    forecastEndDate: this.forecastEndDate,
                };
                if (this.cups && this.cups !== '') {
                    this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
                }
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                postBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
                this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
            }

            //CASO 2, CUENTA NEGOCIADORA

            if (this.accountId !== undefined) {
                this.filtersBodyPost = {
                    accountId: this.negotiatingAccountCode,
                    measureInitDate: this.measureInitDate,
                    measureEndDate: this.measureEndDate,
                    forecastInitDate: this.forecastInitDate,
                    forecastEndDate: this.forecastEndDate,
                };
                if (!this.negotiatingAccountCode) {
                    delete this.filtersBodyPost.accountId;
                }
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                postBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
                this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
            }

            if (this.cups === undefined && this.accountId === undefined) {
                this.filtersBodyPost = {
                    measureInitDate: this.measureInitDate,
                    measureEndDate: this.measureEndDate,
                    forecastInitDate: this.forecastInitDate,
                    forecastEndDate: this.forecastEndDate,
                    programUnit: this.programUnit,
                    rate: this.rate,
                    liquidationType: this.liquidationType,
                    clientType: this.clientType,
                    accountId: this.negotiatingAccountCode,
                };
                if (!this.negotiatingAccountCode) {
                    delete this.filtersBodyPost.accountId;
                }
                if (this.cups && this.cups !== '') {
                    this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
                }
                if (
                    this.filtersBodyPost.programUnit &&
                    (this.filtersBodyPost.programUnit.length === 0 || this.filtersBodyPost.programUnit === '[]')
                ) {
                    delete this.filtersBodyPost.programUnit;
                }
                if (
                    this.filtersBodyPost.rate &&
                    (this.filtersBodyPost.rate.length === 0 || this.filtersBodyPost.rate === '[]')
                ) {
                    delete this.filtersBodyPost.rate;
                }
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                postBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
                this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
            }
        }
        //...................POST PREVISIONES PROYECTADAS.

        //POST CIERRE VIRTUAL...................
        if (this.processCodePOST === 'PR0004') {
            this.filtersBodyPost = {
                initDate: this.initDate,
                endDate: this.endDate,
                accountId: this.negotiatingAccountCode,
                closeType: this.closeType,
                society: this.society,
                clientType: this.clientType,
            };
            if (!this.negotiatingAccountCode) {
                delete this.filtersBodyPost.accountId;
            }
            if (this.cups && this.cups !== '') {
                this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
            }
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            postBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
            this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
        }
        //...................POST CIERRE VIRTUAL

        //POST CALCULO OBJECIONES ...................
        if (this.processCodePOST === 'PR0005') {
            this.filtersBodyPost = {
                month: this.selectedMonth,
                year: this.selectedYear,
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            let postBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
            this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
        }
        //...................

        //POST Curva horaria a partir de cuarto-horaria ...................
        if (this.processCodePOST === 'PR0006') {
            this.filtersBodyPost = {
                initDate: this.initDate,
                endDate: this.endDate,
            };
            if (this.cups && this.cups !== '') {
                this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
            }
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            let postBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
            this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
        }
        //...................

        //POST Agregado Previsiones ...................
        if (this.processCodePOST === 'PR0007') {
            this.filtersBodyPost = {
                initDate: this.initDate,
                endDate: this.endDate,
                programUnit: this.programUnit,
                deleteModified: this.toggleActive,
            };
            if (
                this.filtersBodyPost.programUnit &&
                (this.filtersBodyPost.programUnit.length === 0 || this.filtersBodyPost.programUnit === '[]')
            ) {
                delete this.filtersBodyPost.programUnit;
            }
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            let postBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
            this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
        }
        //...................

        //POST Envío Meteológica ...................
        if (this.processCodePOST === 'PR0008') {
            this.filtersBodyPost = {
                initDate: this.initDate,
                endDate: this.endDate,
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            let postBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
            this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
        }
        //...................

        //POST Informe Analisis Resultados ...................
        if (this.processCodePOST === 'PR0009') {
            this.filtersBodyPost = {
                month: this.selectedMonth,
                year: this.selectedYear,
                results: this.subfilters![2].selected,
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            let postBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
            this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
        }
        //...................

        //POST Liquidación potestativa ...................
        if (this.processCodePOST === 'PR0011') {
            this.filtersBodyPost = {
                initDate: this.initDate,
                endDate: this.endDate,
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            let postBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
            this.procesosService.launchPostPrevisionesIniciales(postBody).subscribe((uF) => {});
        }
        //...................

        if (this.subfilters !== undefined) {
            this.optionSelectedId.emit(selectFilter);
        } else {
            this.optionSelectedId.emit(this.selectId);
        }
    }

    loadProgramProcess(code?: string) {
        if (code) {
            this.processCodePOST = code;
        }
        let programProcessBody: any;
        this.cups = this.maestroService.validCups;

        //POST PREVISIONES INICIALES...................
        if (this.processCodePOST === 'PR0002') {
            if (this.toggleActive) {
                //Defaultexecution
                this.defaultExecution = true;
            } else {
                this.defaultExecution = false;
            }

            //CASO 1, DEFAULT SELECTION ACTIVO
            if (this.defaultExecution === true) {
                this.filtersBodyPost = {
                    defaultExecution: this.defaultExecution,
                };
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                programProcessBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
            }

            //CASO 2, CUPS
            if (this.cups !== undefined) {
                this.filtersBodyPost = {};
                if (this.cups && this.cups !== '') {
                    this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
                }
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                programProcessBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
            }

            //CASO 3, CUENTA NEGOCIADORA

            if (this.accountId !== undefined) {
                this.filtersBodyPost = { accountId: this.negotiatingAccountCode };
                if (!this.negotiatingAccountCode) {
                    delete this.filtersBodyPost.accountId;
                }
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                programProcessBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
            }

            //CASO 4, RESTO DE FILTROS

            if (this.defaultExecution === false && this.cups === undefined && this.accountId === undefined) {
                this.filtersBodyPost = {
                    programUnit: this.programUnit,
                    rate: this.rate,
                    liquidationType: this.liquidationType,
                    clientType: this.clientType,
                    accountId: this.negotiatingAccountCode,
                    defaultExecution: this.defaultExecution,
                };
                if (!this.negotiatingAccountCode) {
                    delete this.filtersBodyPost.accountId;
                }
                if (
                    this.filtersBodyPost.programUnit &&
                    (this.filtersBodyPost.programUnit.length === 0 || this.filtersBodyPost.programUnit === '[]')
                ) {
                    delete this.filtersBodyPost.programUnit;
                }
                if (
                    this.filtersBodyPost.rate &&
                    (this.filtersBodyPost.rate.length === 0 || this.filtersBodyPost.rate === '[]')
                ) {
                    delete this.filtersBodyPost.rate;
                }
                if (this.cups && this.cups !== '') {
                    this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
                }
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                programProcessBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
            }
        }

        //...................POST PREVISIONES INICIALES

        //POST PREVISIONES PROYECTADAS...................

        if (this.processCodePOST === 'PR0003') {
            this.filtersBodyPost = {
                measureInitDate: this.measureInitDate,
                measureEndDate: this.measureEndDate,
                forecastInitDate: this.forecastInitDate,
                forecastEndDate: this.forecastEndDate,
                programUnit: this.programUnit,
                rate: this.rate,
                liquidationType: this.liquidationType,
                clientType: this.clientType,
                accountId: this.negotiatingAccountCode,
            };
            if (!this.negotiatingAccountCode) {
                delete this.filtersBodyPost.accountId;
            }
            if (
                this.filtersBodyPost.programUnit &&
                (this.filtersBodyPost.programUnit.length === 0 || this.filtersBodyPost.programUnit === '[]')
            ) {
                delete this.filtersBodyPost.programUnit;
            }
            if (
                this.filtersBodyPost.rate &&
                (this.filtersBodyPost.rate.length === 0 || this.filtersBodyPost.rate === '[]')
            ) {
                delete this.filtersBodyPost.rate;
            }
            if (this.cups && this.cups !== '') {
                this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
            }
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }

        //...................POST PREVISIONES PROYECTADAS.

        //POST CIERRE VIRTUAL...................

        if (this.processCodePOST === 'PR0004') {
            this.filtersBodyPost = {
                initDate: this.initDate,
                endDate: this.endDate,
                accountId: this.negotiatingAccountCode,
                closeType: this.closeType,
                society: this.society,
                clientType: this.clientType,
            };
            if (!this.negotiatingAccountCode) {
                delete this.filtersBodyPost.accountId;
            }
            if (this.cups && this.cups !== '') {
                this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
            }
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }

        //POST CALCULO OBJECIONES ...................
        if (this.processCodePOST === 'PR0005') {
            this.filtersBodyPost = {
                month: this.selectedMonth,
                year: this.selectedYear,
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        //...................

        //POST Curva horaria a partir de cuarto-horaria ...................
        if (this.processCodePOST === 'PR0006') {
            this.filtersBodyPost = {
                initDate: this.initDate,
                endDate: this.endDate,
            };
            if (this.cups && this.cups !== '') {
                this.filtersBodyPost.cups = this.procesosService.convertCupsListStringToArray(this.cups);
            }
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        //...................

        //POST Agregado Previsiones ...................
        if (this.processCodePOST === 'PR0007') {
            this.filtersBodyPost = {
                initDate: this.initDate,
                endDate: this.endDate,
                programUnit: this.programUnit,
                deleteModified: this.toggleActive,
            };
            if (
                this.filtersBodyPost.programUnit &&
                (this.filtersBodyPost.programUnit.length === 0 || this.filtersBodyPost.programUnit === '[]')
            ) {
                delete this.filtersBodyPost.programUnit;
            }
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        //...................

        //POST Envio Meteológica ...................
        if (this.processCodePOST === 'PR0008') {
            this.filtersBodyPost = {
                initDate: this.initDate,
                endDate: this.endDate,
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }

        //POST Informe Analisis Resultados ...................
        if (this.processCodePOST === 'PR0009') {
            this.filtersBodyPost = {
                month: this.selectedMonth,
                year: this.selectedYear,
                results: this.subfilters![2].selected,
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        //...................

        //POST Liquidación potestativa ...................
        if (this.processCodePOST === 'PR0011') {
            this.filtersBodyPost = {
                initDate: this.initDate,
                endDate: this.endDate,
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
            //...................
        }

        return programProcessBody;
    }

    onValueChangeGetOption(event: { id: string; value: string }) {
        if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }
        this.resetTable.emit();
    }

    protected loadNotification() {
        this.translateService.get('PAGE.MODALS').subscribe((message) => {
            this._snackBar.open(message['FINISH_EXECUTION_PROCESS'], message['ACTION_CLOSE'], {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        });
        setTimeout(() => {
            this._snackBar.dismiss();
        }, 4000);
    }

    obtainOption3(event: { id: string; value: string }, valueCuentNeg: any) {
        if (this.isInitialForecastsSelected || this.previsionesProyectadasSelected) {
            if (event.value) {
                //Aquí esta el valor del select de cuenta negociadora
                this.cuentaNegociadoraActive = true;
                this.eventvalue = event.value;
            }
            this.cuentaNegociadoraActive = false;
            if (valueCuentNeg) {
                this.cuentaNegociadoraActive = true;
            }
        }
        if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }
    }

    activeToggle() {
        if (this.maestroService.validCups != undefined || this.cuentaNegociadoraActive) {
            return;
        }
        if (this.toggleActive) {
            this.toggleActive = false;
        } else {
            this.toggleActive = true;
        }
        this.resetTable.emit();
    }

    checkConditions(filtro: string[] | undefined): boolean {
        if (this.showinput === false) {
            return true;
        }
        if (!filtro || !filtro.length) {
            return true;
        }
        let solution = false;
        filtro.forEach((cond) => {
            if (this.inputValues.some((inpVal) => inpVal.value === cond)) {
                solution = true;
            }
        });
        return solution;
    }

    receiveDate($event: any, indexFiltro: any, filtro?: any) {
        if (filtro && filtro.key === 'accountId') {
            this.negotiatingAccountCode = $event.includes('=') ? $event.split('=')[1] : $event;
        }
        //PREPARACIÓN POST PREVISIONES INICIALES...................
        if (this.processCodePOST === 'PR0002') {
            if (indexFiltro === 2) {
                //rates
                this.rate = $event.split('=')[1];
            }
            if (indexFiltro === 3) {
                //tipodeliquidacion
                this.liquidationType = $event.split('=');
                this.liquidationType = this.liquidationType[1];
                if (this.liquidationType.includes('Factura ATR')) {
                    this.liquidationType = 'ATR';
                } else {
                    this.liquidationType = 'MH';
                }
            }
            if (indexFiltro === 4) {
                //tipo de negocio
                this.clientType = $event.split('=');
                this.clientType = this.clientType[1];
                if (this.clientType === 'Pymes') {
                    this.clientType = [6];
                }
                if (this.clientType === 'Grandes cuentas') {
                    this.clientType = [1, 2, 3, 4, 5];
                }
            }
            if (indexFiltro === 5) {
                //Cuenta negociadora
                this.accountId = $event.split('=');
                this.accountId = this.accountId[1];
            }
        }
        //...................PREPARACIÓN POST PREVISIONES INICIALES

        //PREPARACIÓN POST PREVISIONES PROYECTADAS...................
        if (this.processCodePOST === 'PR0003') {
            if (indexFiltro === 0) {
                //Fechas Medidas
                this.measureEndDate = $event.split('~');
                this.measureInitDate = this.measureEndDate[0];
                this.measureEndDate = this.measureEndDate[1];
            }

            if (indexFiltro === 1) {
                //Fechas Proyección
                this.forecastEndDate = $event.split('~');
                this.forecastInitDate = this.forecastEndDate[0];
                this.forecastEndDate = this.forecastEndDate[1];
            }

            if (indexFiltro === 5) {
                //tipodeliquidacion
                this.liquidationType = $event.split('=');
                this.liquidationType = this.liquidationType[1];
                if (this.liquidationType.includes(liqTypeEnum.FACTURA_ATR)) {
                    this.liquidationType = liqTypeEnum.FACTURA_ATR_CODE;
                } else {
                    this.liquidationType = liqTypeEnum.MEDIA_HORARIA_CODE;
                }
            }

            if (indexFiltro === 6) {
                //tipo de negocio
                this.clientType = $event.split('=');
                this.clientType = this.clientType[1];
                if (this.clientType === ContractClientType.PYMES) {
                    this.clientType = [6];
                }
                if (this.clientType === ContractClientType.GRANDES_CUENTAS) {
                    this.clientType = [1, 2, 3, 4, 5];
                }
            }

            if (indexFiltro === 7) {
                //Cuenta negociadora
                this.accountId = $event.split('=');
                this.accountId = this.accountId[1];
            }
        }
        //...................PREPARACIÓN POST PREVISIONES PROYECTADAS

        //PREPARACIÓN POST CIERRE VIRTUAL...................
        if (this.processCodePOST === 'PR0004') {
            if (indexFiltro === 0) {
                //fechas
                this.endDate = $event.split('~');
                this.initDate = this.endDate[0];
                this.endDate = this.endDate[1];
                let initDateOneMonthCheck = this.initDate.split('-');
                let endDateOneMonthCheck = this.endDate.split('-');

                if (initDateOneMonthCheck[1] != endDateOneMonthCheck[1]) {
                    this.dateRangeSameMonth = true;
                    this.translateService.get('PAGE.MODALS').subscribe((message) => {
                        this._snackBar.open(message['ONLY_ONE_MONTH'], message['ACTION_CLOSE'], {
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                        });
                    });
                    setTimeout(() => {
                        this._snackBar.dismiss();
                    }, 4000);
                } else {
                    this.dateRangeSameMonth = false;
                }
            }

            if (indexFiltro === 1) {
                //Cuenta negociadora
                this.accountId = $event.split('=');
                this.accountId = this.accountId[1];
            }

            if (indexFiltro === 2) {
                //tipo de cierre
                this.closeType = $event.split('=');
                this.closeType = this.closeType[1];
                if (this.closeType === 'Cuarto-horario') {
                    this.closeType = 'CCH';
                }
                if (this.closeType === 'Horario') {
                    this.closeType = 'CH';
                }
                if (this.closeType === 'Factura ATR') {
                    this.closeType = 'ATR';
                }
                if (this.closeType === 'Mixto') {
                    this.closeType = 'Mixto';
                }
            }
            if (indexFiltro === 5) {
                //tipo de negocio
                this.clientType;
                this.clientType = $event.split('=');
                this.clientType = this.clientType[1];
                if (this.clientType === 'Pymes') {
                    this.clientType = [6];
                }
                if (this.clientType === 'Grandes cuentas') {
                    this.clientType = [1, 2, 3, 4, 5];
                }
            }
            if (indexFiltro === 3) {
                //Sociedad
                this.society = $event.split('=');
                this.society = this.society[1];
            }
        }

        if (
            this.processCodePOST === 'PR0006' ||
            this.processCodePOST === 'PR0007' ||
            this.processCodePOST === 'PR0008' ||
            this.processCodePOST === 'PR0011'
        ) {
            if (indexFiltro === 0) {
                //fechas
                this.endDate = $event.split('~');
                this.initDate = this.endDate[0];
                this.endDate = this.endDate[1];
            }
        }
        //...................PREPARACIÓN POST CIERRE VIRTUAL

        if (this.processCodePOST === 'PR0009') {
            let resultFilter = $event.split('=');
            switch (resultFilter[1]) {
                case 'Cartera real':
                    this.subfilters![indexFiltro].selected = 'real';
                    break;
                case 'Contratos ficticios':
                    this.subfilters![indexFiltro].selected = 'fic';
                    break;
                case 'Total':
                    this.subfilters![indexFiltro].selected = 'all';
                    break;
                default:
                    this.subfilters![indexFiltro].selected = $event;
                    break;
            }
        }

        if (this.subfilters !== undefined && this.processCodePOST !== 'PR0009') {
            this.subfilters[indexFiltro].selected = $event;
        }
        this.isDisableButtonRequired();
    }

    receiveDate2(filtro: any) {
        this.isDisableButtonRequired();
    }

    resetComplete($event: any) {
        this.resetCups = $event;
    }

    hideSelect() {
        this.showSelect = false;
    }

    reset() {
        if (this.subfilters != undefined) {
            this.subfilters.forEach(function (e) {
                delete e['selected'];
            });
        }
    }

    /**
     * Método para controlar que filtros de Selects van a tener barra de busqueda con la lógica de buscar a raiz de escribir 3 carácteres
     *
     * @param filterName Valor name que nos viene de los subfilters
     * @returns
     */
    isSearchableBy3CharactersFilter(filterName: string): boolean {
        if (filterName === '45' || filterName === '46' || filterName === '37' || filterName === '41') {
            return true;
        } else {
            return false;
        }
    }

    restartValue(event: any, filtro: any, key: string, filtroType?: any) {
        let eventValue = event.newValue || event.value;

        if (filtroType === 'SelectMonth' || filtroType === 'SelectYear') {
            if (this.subfilters !== undefined && eventValue) {
                if (filtroType === 'SelectMonth') {
                    this.monthSelected = true;
                    this.selectedMonth = eventValue;
                } else if (filtroType === 'SelectYear') {
                    this.yearSelected = true;
                    this.selectedYear = eventValue;
                }
                this.subfilters[filtro].selected = eventValue;
                this.isDisableButtonRequired();
            }
        } else {
            this.formatSelectedValue(event, filtro, key, filtroType);

            if (this.subfilters !== undefined && eventValue) {
                this.subfilters[filtro].selected = eventValue;

                if (this.selectId === MaestrosCRM.CONTRATOS) {
                    this.isDisableButtonRequired();
                }
            } else if (!eventValue && this.selectId === MaestrosCRM.CONTRATOS) {
                this.isDisableButtonRequired();
            }
            this.intermedio = true;
        }
        this.resetTable.emit();
    }

    formatSelectedValue(event: any, filtro: any, key: string, filtroType: string) {
        if (
            this.selectId === MaestrosCRM.CONTRATOS ||
            this.selectId === MaestrosCRM.CONCEPTOS_CONTRATOS ||
            MaestrosCRM.CIERRES ||
            MaestrosCRM.CUENTA_NEGOCIADORA ||
            MaestrosAWS.FESTIVOS ||
            MaestrosCRM.SOCIEDAD
        ) {
            if (filtroType !== 'Date' && this.subfilters) {
                if (key === KeysMaestro.DESCRIPTION) {
                    this.subfilters[filtro].selected = event.newValue;
                } else if (key === KeysMaestro.CUENTA_NEGOCIADORA_DESCRIPCION) {
                    this.subfilters[filtro].selected = event.newValue;
                } else if (key === KeysMaestro.CUPS) {
                    this.subfilters[filtro].selected =
                        !this.maestroService.validCups && filtroType === 'cups' && event !== ''
                            ? event
                            : this.maestroService.validCups;
                    this.maestroService.validCups = this.subfilters[filtro].selected;
                } else if (key === 'instName' || key === 'idNumber' || key === 'clientName') {
                    this.subfilters[filtro].selected = event.newValue;
                } else {
                    let filtroFormateado = event.split('=');
                    if (key === KeysMaestro.CUPS_CONSUMO_ANUAL) {
                        this.subfilters[filtro].selected = filtroFormateado[1];
                    } else if (key === KeysMaestro.ESTADO_CONTRATO) {
                        switch (filtroFormateado[1]) {
                            case ContractStatusEnum.PRE_CONTRATO:
                                this.subfilters[filtro].selected = 1;
                                break;
                            case ContractStatusEnum.PENDING:
                                this.subfilters[filtro].selected = 2;
                                break;
                            case ContractStatusEnum.FORMALIZED:
                                this.subfilters[filtro].selected = 3;
                                break;
                            case ContractStatusEnum.CANCELED_REJECTED:
                                this.subfilters[filtro].selected = 4;
                                break;
                            case ContractStatusEnum.COMPLETED:
                                this.subfilters[filtro].selected = 5;
                                break;
                            default:
                                break;
                        }
                    } else if (key === KeysMaestro.NEGOCIO) {
                        switch (filtroFormateado[1]) {
                            case ContractClientType.GRANDES_CUENTAS:
                                this.subfilters[filtro].selected = ContractClientType.GRANDES_CUENTAS_STRING;
                                break;
                            case ContractClientType.PYMES:
                                this.subfilters[filtro].selected = ContractClientType.PYMES_STRING;
                                break;
                        }
                    } else if (key === KeysMaestro.TIPO_LIQUIDACION) {
                        switch (filtroFormateado[1]) {
                            case liqTypeEnum.MEDIA_HORARIA:
                                this.subfilters[filtro].selected = liqTypeEnum.MEDIA_HORARIA_STRING;
                                break;
                            case liqTypeEnum.FACTURA_ATR:
                                this.subfilters[filtro].selected = liqTypeEnum.FACTURA_ATR_STRING;
                                break;
                        }
                    } else {
                        this.subfilters[filtro].selected = filtroFormateado[1];
                    }
                }
            }
        }
    }

    byOpenSelect(event: any) {
        if (event.newValue === true) {
            if (event.key === KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA) {
            } else if (event.key === KeysMaestro.SOCIEDADES) {
                let subfilter = this.subfilters?.find(
                    (f: any) => f.key === KeysMaestro.SOCIEDADES || f.key === KeysMaestro.CONTRACT_SOCIEDADES
                );
                if (subfilter) {
                    subfilter.data = [];
                }
            } else if (event.key === KeysMaestro.CODIGO_CONTRATO) {
                let subfilter = this.subfilters?.find(
                    (f: any) => f.key === KeysMaestro.CODIGO_CONTRATO || f.key === KeysMaestro.CONTRACT_CODIGO_CONTRATO
                );
                if (subfilter) {
                    subfilter.data = [];
                }
            } else if (event.key === KeysMaestro.ESTADO_CONTRATO) {
                let contractState: any = [];
                this.maestroService.getContractState().subscribe((status) => {
                    status.data.forEach((element: any) => {
                        contractState.push({
                            name: element.description,
                            value: element.status,
                        });
                    });

                    // Guardo en los subsfilters de estado contrato lo de la petición
                    this.subfilters?.forEach((subfilter) => {
                        if (subfilter.key === KeysMaestro.ESTADO_CONTRATO) {
                            subfilter.data = contractState;
                        }
                    });
                });
            } else if (event.key === KeysMaestro.CRM_VARIABLE) {
                let crmVariables: any = [];
                this.maestroService.getCrmVariables().subscribe((price: any) => {
                    price.data.forEach((element: any) => {
                        crmVariables.push({ name: element });
                    });

                    // Guardo en los subsfilters de codigo contrato lo de la petición
                    this.subfilters?.forEach((subfilter) => {
                        if (subfilter.key === KeysMaestro.CRM_VARIABLE) {
                            subfilter.data = crmVariables;
                        }
                    });
                });
            }
        }
    }

    specialSearch(event: any) {
        if (
            (event.key === KeysMaestro.SOCIEDADES || event.key === KeysMaestro.CONTRACT_SOCIEDADES) &&
            this.selectId !== MaestrosCRM.COBERTURA_CONTRATO
        ) {
            let subfilter = this.subfilters?.find(
                (f: any) => f.key === KeysMaestro.SOCIEDADES || f.key === KeysMaestro.CONTRACT_SOCIEDADES
            );
            this.maestroService
                .getClients({
                    search: event.search,
                    mode: subfilter?.toggleState ? 'cif' : 'description',
                })
                .subscribe((client) => {
                    if (subfilter) {
                        subfilter.data = [];
                    }
                    client.data.forEach((element: any) => {
                        if (subfilter && subfilter.toggleState === true) {
                            subfilter.data.push({ name: element.idNumber });
                        } else if (subfilter) {
                            subfilter.data.push({ name: element.clientName });
                        }
                    });
                });
        } else if (
            event.key === KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA ||
            event.key === KeysMaestro.CUENTA_NEGOCIADORA ||
            event.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA
        ) {
            let subfilter = this.subfilters?.find(
                (f: any) =>
                    f.key === KeysMaestro.CUENTA_NEGOCIADORA ||
                    f.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA ||
                    f.key === KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA ||
                    f.key === 'account'
            );
            this.maestroService
                .getNegotiatingAccounts({
                    search: event.search,
                    mode: subfilter?.toggleState ? 'negotiatingAccountCode' : 'negotiatingAccountDescription',
                })
                .subscribe((neAccounts) => {
                    if (subfilter) {
                        subfilter.data = [];
                    }
                    neAccounts.data.forEach((element: any) => {
                        if (subfilter && subfilter.toggleState === true) {
                            subfilter.data.push({
                                name: element.negotiatingAccountCode,
                            });
                        } else if (subfilter) {
                            subfilter.data.push({
                                name: element.negotiatingAccountDescription,
                                code: element.negotiatingAccountCode,
                            });
                        }
                    });
                });
        } else if (event.key === KeysMaestro.CODIGO_CONTRATO || event.key === KeysMaestro.CONTRACT_CODIGO_CONTRATO) {
            let subfilter = this.subfilters?.find(
                (f: any) => f.key === KeysMaestro.CONTRACT_CODIGO_CONTRATO || f.key === KeysMaestro.CODIGO_CONTRATO
            );
            if (subfilter) {
                subfilter.data = [];
            }
            this.maestroService.getContractCode(event.search).subscribe((code) => {
                code.data.forEach((element: any) => {
                    if (subfilter) {
                        subfilter.data.push({ name: element.contractCode });
                    }
                });
            });
        }
    }

    activeToggleDeleteRegisters() {
        if (this.toggleActive) {
            this.toggleActive = false;
        } else {
            this.toggleActive = true;
        }
    }

    filterUnitProgram(electricSystemSelected: any) {
        electricSystemSelected === 'Península'
            ? (electricSystemSelected = 'PENINSULA')
            : (electricSystemSelected = electricSystemSelected.toUpperCase());
        let unitProgramFilter = [];
        for (let i = 0; i < this.unitProgramListNoFilter.length; i++) {
            if (this.unitProgramListNoFilter[i].electricSystem === electricSystemSelected) {
                unitProgramFilter.push(this.unitProgramListNoFilter[i]);
            }
        }
        this.unitProgramList = [];
        this.programUnit = [];
        this.unitProgramList = unitProgramFilter;

        // Rellenamos todos por defecto
        this.unitProgramList = this.unitProgramList.map((uP: any) => uP.programUnitCode);
        this.unitPrograms = new FormControl(this.unitProgramList);
        this.programUnit = this.unitProgramList;
    }

    protected onChangeProgramUnit(programUnit: any): void {
        this.programUnit = programUnit.value?.map((unit: any) => {
            if (unit.programUnitCode) {
                return unit.programUnitCode;
            } else if (typeof unit === 'string') {
                return unit;
            }
            return '';
        });
    }

    obtainSelectedOptionElectrictSystem(eSystem: any) {
        if (eSystem.value) {
            this.electrictSystemSelected = true;
        } else {
            this.electrictSystemSelected = false;
        }
    }

    obtainRate(rates: any) {
        this.rate = [rates];
    }

    reactivateCUPs() {
        this.maestroService.validCups = undefined;
    }

    monthDisplay(month: number): string {
        return Months[month];
    }

    generateYears(filtro?: string) {
        if (filtro === 'year_analisis') {
            const actualYear = new Date().getFullYear();
            return Array.from({ length: 9 }, (element, index) => actualYear - 2 + index);
        } else {
            const actualYear = new Date().getFullYear();
            return Array.from({ length: 3 }, (element, index) => actualYear - index);
        }
    }

    ngOnDestroy(): void {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.subcriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }
}
