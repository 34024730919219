import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { GlobalService } from 'src/app/services/global.service';
import { HeaderTitleService } from 'src/app/services/header-title.service';
import { InformesService } from '../../services/informes.service';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { PaginationObject } from 'src/app/shared/models/paginationObject.interface';
import { SortingObject } from 'src/app/shared/models/files.interface';
import { environment } from 'src/environments/environment';
import { KeysInformes, SortByInformes } from '../../../../shared/enums/informes';

@Component({
    selector: 'app-informes-resultado-page',
    templateUrl: './informes-resultado-page.component.html',
    styleUrls: ['./informes-resultado-page.component.scss'],
})
export class InformesResultadoPageComponent implements OnInit {
    isPageEnabled: boolean = true;

    dataBusiness: any;
    dataHeaders: IColumnsTable[];
    dataPagination: PaginationObject;
    dataSorting: SortingObject;
    columns: any;
    permissions: any;
    filters: any;
    idFilters: any;
    filtersTable: any[];
    idToCompare: string | [];

    totalRegisters: number;
    pageSize: number = 10;
    totalPages: number;
    numPage: number;
    pageIndex: number = 1;
    sortActive: any;
    sortDir: any;

    clients: any;
    sortIdFilter: any;
    sortFilter: any;
    sortDirection: any;
    sortParameter: any;
    sortId: any;
    headers: any;
    newTable: string[];
    tablaPivotada: [];
    unitProgramListNoFilter: [any];
    unitProgramList: [any];
    electricSystemList: any;

    constructor(
        private _informesServices: InformesService,
        private _globalService: GlobalService,
        private _loginService: LoginService,
        private _headerTitleService: HeaderTitleService
    ) {}

    ngOnInit(): void {
        this._headerTitleService.currentHeaderTranslationCode = 'REPORTS_RESULTS';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
    }

    transformHeaders() {
        this.columns = this.dataHeaders.map((col) => col.id);
    }

    /**
     * Método para formatear el parámetro de busqueda
     * @param idFiltersEvent id del filtro y subfiltros
     * @returns
     */
    formatSearch(idFiltersEvent: any): string {
        let params: string = '';
        let addParamFlag: boolean = false;
        if (idFiltersEvent[0]) {
            idFiltersEvent[1].forEach((filter: any, index: number) => {
                addParamFlag = false;
                if (filter.selected && filter.type === 'Date' && !addParamFlag) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    let splitedDates = idFiltersEvent[1][0].selected.split('~');
                    params = params.concat(`initDate=(${splitedDates[0]};${splitedDates[1]})`);
                    addParamFlag = true;
                } else {
                    if (filter.selected && filter.type !== 'Date' && !addParamFlag) {
                        if (index !== 0 && params !== '') {
                            params = params.concat(',');
                        }
                        if (filter.key === 'creationUser') {
                            filter.data.forEach((user: any) => {
                                if (user.value === filter.selected && !params.includes('creationUser')) {
                                    params = params.concat(`${filter.key}=${user.value}`);
                                }
                            });
                        } else {
                            params = params.concat(`${filter.key}=${filter.selected}`);
                        }
                        addParamFlag = true;
                    }
                }
            });

            if (idFiltersEvent[2]) {
                params = params.concat(',');
                params = params.concat(`programUnitCode=;(${idFiltersEvent[2]})`);
                params = params.split(';')[0] + params.split(';')[1].replace(/,/g, ';');
                addParamFlag = true;
            }
        }
        return params;
    }

    private getInfoTabla(idFilters: any) {
        this.permissions = this._globalService.transformPermissions(idFilters[0].name);
        this.idFilters = idFilters;
        this.headers = this._loginService.getHeaderSession();
        this._informesServices.searchParam = this.formatSearch(idFilters);
        if (this.headers != null) {
            combineLatest([
                this._informesServices.getTablaInformesRes(
                    this.pageIndex,
                    this.pageSize,
                    this.sortParameter,
                    this.sortDirection,
                    this.formatSearch(idFilters)
                ),
            ]).subscribe((res) => {
                this.buildTable(res, JSON.parse(this.headers), KeysInformes.REPORT_RESULTS);
            });
        } else {
            console.log('No se ha encontrado la tabla');
        }

        this.sortParameter = undefined;
    }

    onClickClearTable($event: any) {
        this.dataBusiness = undefined;
        this.sortActive = undefined;
    }

    onClickLoadTable(event: any) {
        let filter: boolean = false;

        event[1].forEach((eventFilter: any) => {
            if (eventFilter.selected) {
                filter = true;
            }
        });
        this.filtersTable = event;
        this.getInfoTabla(event);

        // TODO: cambiar las peticiones aqui
    }

    onChangeResetFilters() {}

    sort(event: any, id: string) {
        this.sortDir = event.direction;
        this.sortActive = event.active;
        this.sortDirection = event.sort.direction;
        this.sortParameter = event.sort.active;

        this.getInfoTabla(this.idFilters);
    }

    buildTable(res: any[], header: any, nameHeaders: string): void {
        if (environment.isMockActive) {
            this.dataBusiness = res[0].data;
            header.data.forEach((element: any) => {
                if (element.name === nameHeaders) {
                    this.dataHeaders = element.headers;
                }
            });
            this.dataHeaders = res[1].data[nameHeaders].headers;
            this.totalRegisters = res[1].totalRegisters;
        } else {
            this.dataBusiness = res[0].data;
            header.data.forEach((element: any) => {
                if (element.name === nameHeaders) {
                    this.dataHeaders = element.headers;
                }
            });
            this.totalRegisters = res[0].page.totalElements;
        }
        this.transformHeaders();
    }

    pagination(event: any) {
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        if (!this.sortActive) {
            this.sortActive = SortByInformes.ID;
        }
        if (!this.sortDir) {
            this.sortDir = 'asc';
        }
        combineLatest([
            this._informesServices.getTablaInformesRes(
                this.pageIndex,
                this.pageSize,
                this.sortActive,
                this.sortDir,
                this.formatSearch(this.idFilters)
            ),
        ]).subscribe((res) => {
            this.buildTable(res, JSON.parse(this.headers), KeysInformes.REPORT_RESULTS);
        });
    }

    deletingReport() {
        combineLatest([
            this._informesServices.getTablaInformesRes(
                this.pageIndex,
                this.pageSize,
                this.sortActive,
                this.sortDir,
                this.formatSearch(this.idFilters)
            ),
        ]).subscribe((res) => {
            this.buildTable(res, JSON.parse(this.headers), KeysInformes.REPORT_RESULTS);
        });
    }
}
