<mat-form-field class="input" appearance="standard">
    <mat-label class="name__input">{{ name }}</mat-label>
    <input
        [formControl]="control"
        [type]="type"
        [min]="min"
        [max]="max"
        [value]="value"
        [disabled]="disabled"
        matInput
        (select)="$event.stopPropagation()"
        (change)="emitValue($event)"
        [required]="required"
    />
</mat-form-field>
