<div class="modal-title-close">
    <h1 *ngIf="!showHistoric && this.data.type === PuntoFronteraTypeModal.MANAGE_PRECEDENCES" mat-dialog-title>{{ 'PAGE.MODALS.TITLE13' | translate }}</h1>
    <h1 *ngIf="showHistoric && this.data.type === PuntoFronteraTypeModal.MANAGE_PRECEDENCES" mat-dialog-title>{{ 'PAGE.MODALS.TITLE10' | translate }}</h1>
    <h1 *ngIf="!showHistoric && this.data.type === PuntoFronteraTypeModal.SET_PRECEDENCES" mat-dialog-title>{{ 'PAGE.MODALS.TITLE12' | translate }}</h1>

    <button id="buttonHistoric" *ngIf="historicPrecedenceData?.length > 0 && !showHistoric" mat-flat-button color="warn" (click)="showHistoricTable()">
        {{ 'PAGE.MODALS.HISTORIC' | translate }}
    </button>
    <button id="buttonHistoric" *ngIf="historicPrecedenceData?.length > 0 && showHistoric" mat-flat-button color="warn" (click)="showHistoricTable()">
        {{ 'PAGE.MODALS.HIDE_HISTORIC' | translate }}
    </button>

    <button mat-button mat-dialog-close class="hide-access close" (click)="close(false)">close</button>
</div>

<div *ngIf="!showHistoric && this.data.type === PuntoFronteraTypeModal.SET_PRECEDENCES">
    <mat-slide-toggle (toggleChange)="showSelectedCups($event)">{{ 'PAGE.MODALS.TITLE14' | translate }}</mat-slide-toggle>
    <div class="selectedCupsDiv" *ngIf="showSelectedCupsBoolean">
        <span *ngFor="let item of this.maestroService.boundaryPoints">{{ item }}</span>
    </div>
</div>

<div mat-dialog-content>
    <div class="container">
        <ng-container *ngIf="!showHistoric">
            <div class="dialog_content_top">
                <div class="dialog_content_top_left">
                    <span>{{ 'PAGE.MODALS.PRECEDENCE_TYPE' | translate }}</span>
                </div>
                <div class="dialog_content_top_right">
                    <app-filtro-select (selectSelected)="valueChange($event)" [filtroUrl]="filtroSelect.data" [value]="filtroSelect.value"></app-filtro-select>
                </div>
            </div>
            <div class="dialog_content_bottom">
                <div class="header">
                    <div class="header-table d-flex">{{ 'PAGE.ACTIONS.ORIGIN' | translate }}</div>
                    <div class="header-table d-flex">{{ 'PAGE.ACTIONS.ORDER' | translate }}</div>
                </div>

                <div [formGroup]="formGroup" *ngIf="formGroup" class="precedence_container">
                    <div *ngFor="let item of precedenceData?.length > 0 ? precedenceData : precedenceDataToCreate" class="precedenceData_div">
                        <div>
                            <span>{{ item.origin }}</span>
                        </div>
                        <div>
                            <app-filtro-input [formControlName]="item.origin" (select)="valueChangeInputCreateEdit($event, item)"></app-filtro-input>
                            <span class="text-danger" *ngIf="formGroup.get(item.origin)?.hasError('pattern')">
                                {{ 'PAGE.MODALS.INVALID_VALUES' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="showHistoric">
        <div class="historic_table_container">
            <table mat-table [dataSource]="historicPrecedenceData" multiTemplateDataRows matSort>
                <ng-container style="max-width: fit-content" [matColumnDef]="column.id" *ngFor="let column of this.dataHeaders">
                    <!-- Header TH -->
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                        <div class="header-table d-flex">
                            {{ column.label }}
                        </div>
                    </th>
                    <!-- Element TD -->
                    <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color': element.color }">
                        <!-- Element TD Text -->
                        <div *ngIf="column.type === 'text'">
                            <span>{{ element[column.id] }}</span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9999">
                        {{ 'PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA' | translate }}
                    </td>
                </tr>
            </table>
        </div>
    </ng-container>
</div>
<div *ngIf="!showHistoric" mat-dialog-actions>
    <!-- <button class="hide-access close" (click)="close(false)">close</button> -->
    <button mat-button class="secondary" (click)="close(false)">{{ 'PAGE.MODALS.CANCEL' | translate }}</button>
    <button mat-button mat-flat-button color="warn" class="primary" (click)="close(true)" cdkFocusInitial>{{ 'PAGE.MODALS.ACCEPT' | translate }}</button>
</div>
