import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltroSelect } from 'src/app/shared/models/filtros.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OpenDialogComponent } from '../../open-dialog/open-dialog.component';
import { MaestroService } from 'src/app/pages/maestro/services/maestro.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
    selector: 'app-filtro-cups',
    templateUrl: './filtro-cups.component.html',
    styleUrls: ['./filtro-cups.component.scss'],
})
export class FiltroCupsComponent implements OnInit {
    @Input() filtroUrl: FiltroSelect[]; // lo que te manda la pagina
    @Input() reset: boolean;
    @Input() disabledCUPS: boolean;
    @Input() title: string;
    @Input() name: string = '';
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() resetComplete: EventEmitter<any> = new EventEmitter();
    @Output() reactivateCUPs: EventEmitter<any> = new EventEmitter();
    @Output() selectSelected: EventEmitter<any> = new EventEmitter();

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    form: UntypedFormGroup;
    isChecked = false;
    lengthCups: number;
    text: string;
    validatedCups: string = '';
    cupsPreloaded: string = '';
    newCups: string;
    listCups: string[] = [];

    constructor(
        public dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private maestroService: MaestroService,
        private _translateService: TranslateService
    ) {
        this.form = this.fb.group({
            enable: false,
        });
        this.isChecked = this.form.value.enable;
    }

    ngOnInit(): void {}

    ngOnChanges(changes: any) {
        if (this.reset) {
            this.resetCups();
        }
    }

    onChange(enable: boolean) {
        this.isChecked = this.form.value.enable;
    }

    resetCups() {
        this.newCups = '';
        this.lengthCups = 0;
        this.maestroService.checkCups(this.newCups).subscribe((cupsV) => {
            this.listCups = cupsV || '';
            this.lengthCups = this.listCups?.length;
            this.resetComplete.emit(false);
        });
    }

    emitValue(event: any) {
        this.select.emit(event);
    }

    emitValueNew(event: any) {
        this.selectSelected.emit({ newValue: event });
    }

    reviewList(): void {
        const dialogRef = this.dialog.open(OpenDialogComponent, {
            width: '350px',
            data: {
                newCups: this.listCups,
                title: this._translateService.instant('PAGE.FILTERS.MODIFY_CUPS'),
                mode: 'read',
            },
        });
        // result.data es lo que me va a llegar del back para meter en el input
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.event && result.event === 'save' && result.data && result.data.length > 0) {
                this.validatedCups = result.data.replace(/,/g, ';');
                this.listCups = this.validatedCups.split(';');
                this.lengthCups = this.listCups?.length;
                this.emitValue(this.validatedCups);
            } else if (
                result &&
                result.event &&
                result.event === 'close' &&
                result.data &&
                result.data.newCups.length > 0
            ) {
                this.validatedCups = result.data.newCups.join(';');
                this.listCups = this.validatedCups.split(';');
                this.lengthCups = this.listCups?.length;
                this.emitValue(this.validatedCups);
            } else {
                this.validatedCups = '';
                this.listCups = [];
                this.lengthCups = this.listCups?.length;
                this.emitValue(this.validatedCups);
            }
        });
    }

    addList(): void {
        const dialogRef = this.dialog.open(OpenDialogComponent, {
            width: '350px',
            data: { newCups: this.listCups, title: this._translateService.instant('PAGE.FILTERS.INSERT_CUPS') },
        });
        this.cupsPreloaded = this.newCups ? this.newCups : '';
        // result.data es lo que me va a llegar del back para meter en el input
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.event && result.event === 'save') {
                if (result.data !== '') {
                    this.listCups = this.checkCupsArray(result, this.listCups);
                    this.newCups = this.listCups.toString().replace(/,/g, ';');
                    this.maestroService.validCups = this.newCups;
                    if (this.listCups && this.listCups.length > 0) {
                        this.listCups = this.newCups.split(';');
                        this._deleteRepeatedCUPS();
                    }
                    this.lengthCups = this.listCups?.length;
                    // Límite de 500 CUPS
                    if (this.lengthCups > 500) {
                        this._translateService.get('PAGE.MODALS').subscribe((message) => {
                            result = this.cupsPreloaded;
                            this.maestroService.validCups = this.cupsPreloaded;
                            this.newCups = this.cupsPreloaded;
                            this.listCups = this.cupsPreloaded.split(';');
                            this.lengthCups = this.listCups[0] === '' ? 0 : this.listCups.length;
                            this._snackBar.open(message['CUPS_EXCEDEED_TO_SET_RANKINGS'], message['ACTION_CLOSE'], {
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                            });
                            setTimeout(() => {
                                this._snackBar.dismiss();
                            }, 6000);
                        });
                    }
                } else {
                    if (result.data !== '' || result.data !== undefined) {
                        this.validatedCups = result.data;
                        if (this.listCups && this.listCups.length > 0) {
                            this.listCups = this.validatedCups?.split(';');
                            this._deleteRepeatedCUPS();
                            this.lengthCups = this.listCups?.length;
                        }
                    }
                }
                if (result.data) {
                    this.select.emit(result);
                }
            }
        });
    }

    /**
     * Método para comprobar si hay nuevos cups y si es así, que los meta en el array y lo retorne.
     * @param result  event y data del dialogRef
     * @param newCups Cups
     * @returns
     */
    checkCupsArray(result: any, newCups: any) {
        if (result?.data?.length > 0) {
            let newCupsCopy = newCups;
            let resultSplitted = result?.data?.split(';');
            let arraysCups = [...newCupsCopy, ...resultSplitted];
            return [...new Set(arraysCups)];
        } else {
            return [];
        }
    }

    remove(index: number) {
        if (this.listCups && this.listCups.length > 0) {
            this.listCups.splice(index, 1);
            this.newCups = this.listCups.toString().replace(/,/g, ';');
            this.maestroService.checkCupsMock(this.newCups).subscribe((cupsV) => {
                this.maestroService.validCups = `${this.newCups}`;
                this.lengthCups = this.newCups.length;
            });
            this.lengthCups = this.listCups.length;
            this.reactivateCUPs.emit(true);
            this.select.emit(this.newCups);
        }
    }

    private _deleteRepeatedCUPS(): void {
        this.listCups = [
            ...new Set(
                this.listCups.map((cup: any) => {
                    return cup;
                })
            ),
        ];
        this.listCups = this.listCups.filter((cup: any) => {
            return cup !== undefined;
        });
    }
}
