<div class="container" style="justify-content: flex-start">
    <div *ngIf="validRole" class="filter">
        <mat-form-field appearance="standard">
            <mat-label class="concept"> {{ 'PAGE.ACTIONS.CONCEPT' | translate }} * </mat-label>

            <mat-select (valueChange)="dataChanged($event, true)" [(ngModel)]="selectedValue">
                <mat-option *ngFor="let concept of sendTypeList" [value]="concept">
                    {{ 'PAGE.SENDINGS.' + concept | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <app-filtro-date
            [name]="'PAGE.FILTERS.DATE_RANGE' | translate"
            [reset$]="reset$"
            (date)="dateComplete($event)"
            (select)="dataChanged($event)"
        >
        </app-filtro-date>
    </div>
    <div *ngIf="validRole" class="subfilter">
        <div [ngClass]="{ meses: singleDateSelected === '', mesesSelected: singleDateSelected !== '' }">
            <!-- Date only -->
            <app-filtro-date
                [name]="'PAGE.FILTERS.SELECT.SENDING_DATE' | translate"
                [isDateRange]="false"
                [reset$]="reset$"
                (date)="singleDateComplete($event)"
                (select)="singleDataChanged($event)"
            >
            </app-filtro-date>
        </div>
        <ng-container *ngFor="let filtro of subfilters; let i = index">
            <mat-form-field appearance="standard" class="select">
                <mat-label> {{ filtro.name }} </mat-label>
                <mat-select
                    *ngIf="i !== 1"
                    #filtersSelect
                    (valueChange)="getOption($event, i)"
                    [(ngModel)]="optionValues[i]"
                >
                    <mat-option *ngFor="let option of filtro.data" [value]="option.value" (click)="filter(option, i)">
                        {{ option.name && option.surnames ? option.name + ' ' + option.surnames : option.name }}
                    </mat-option>
                </mat-select>
                <mat-select
                    *ngIf="i === 1"
                    [formControl]="unitsForm"
                    (valueChange)="getOption($event, i)"
                    [multiple]="isMultiple(i)"
                >
                    <mat-option
                        *ngFor="let option of unitProgramsFormControl"
                        [value]="option.value"
                        (click)="filter(option, i)"
                    >
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </div>

    <div *ngIf="!validRole">
        <h3 class="invalidRol">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
    </div>

    <div class="botones">
        <div class="buttonsFilter">
            <!-- BOTÓN BORRAR -->
            <button class="delete" (click)="clearFilters()" mat-button>
                {{ 'PAGE.ACTIONS.DELETE' | translate }}

                <app-base-icon iconName="filter_alt_off"></app-base-icon>
            </button>
            <!-- BOTÓN FILTRAR -->
            <button
                class="search"
                mat-flat-button
                color="warn"
                (click)="getDataTable($event)"
                [ngClass]="isButtonDisabled() ? 'no-hover' : ''"
                [disabled]="isButtonDisabled()"
            >
                {{ 'PAGE.ACTIONS.FILTER' | translate | uppercase }}
                <app-base-icon iconName="search"></app-base-icon>
            </button>
        </div>
    </div>
</div>
<div class="table" *ngIf="dataBusiness && validRole">
    <div class="table-header row">
        <div class="table-header-info">
            <span
                >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                {{ totalRegisters | numberFormat : _headerTitleService.currentLang }}</span
            >
        </div>
        <div class="table-header-actions display_flex actions-heigh">
            <button mat-button (click)="openModalExcel()">
                <span class="text"> {{ 'PAGE.ACTIONS.EXCEL_EXPORT' | translate }} </span>

                <span class="icon material-symbols-outlined">save_as </span>
            </button>
            <!-- INICIO>>> Botón mostrar/ocultar columnas -->
            <button class="buttonColumns" [ngClass]="ocultar === true ? 'mat' : 'mat_none'">
                <mat-form-field [hidden]="ocultar">
                    <mat-select
                        #selectColumns
                        name="selectColumns"
                        id="selectColumns"
                        [value]="dataHeadersValueSelect"
                        multiple
                    >
                        <mat-option
                            enableCheckAll="true"
                            [matColumnDef]="column.id"
                            [value]="column"
                            (click)="checkvalue(selectColumns.value)"
                            *ngFor="let column of filterDataHeader()"
                        >
                            {{ column.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="divColumns" (click)="botonClick()">
                    <span class="text spanColumn" *ngIf="!elemento">
                        {{ 'PAGE.ACTIONS.COLUMNS' | translate }}
                    </span>

                    <app-base-icon class="padding_button" iconName="view_column"></app-base-icon>
                </div>
            </button>
            <!-- FIN>>> Botón mostrar/ocultar columnas -->
        </div>
    </div>
    <div class="table-container-main mt-0">
        <div class="table-container">
            <table mat-table [dataSource]="dataBusiness" multiTemplateDataRows matSort>
                <ng-container
                    style="max-width: fit-content"
                    [matColumnDef]="column.id"
                    *ngFor="let column of dataHeaders; let i = index"
                >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                        <div class="header-table d-flex">{{ column.label }}</div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="column.type === 'text'">
                            <div class="tipoFormula">
                                <ng-container *ngIf="column.id === 'fileSend' || column.id === 'fileAnswer'">
                                    <button
                                        mat-button
                                        [disabled]="element[column.id] == undefined || element[column.id] == ''"
                                        color="secondary"
                                        [matTooltip]="element[column.id]"
                                        matTooltipShowDelay="300"
                                        (click)="downloadFile(element, column.id)"
                                    >
                                        <span class="material-symbols-outlined">download</span>
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="column.id === 'type'">
                                    <span [matTooltip]="element[column.id]" matTooltipShowDelay="300">
                                        {{ 'PAGE.SENDINGS.' + transtalionCodeSelectedType | translate }}
                                    </span>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        column.id === 'initDate' ||
                                        column.id === 'endDate' ||
                                        column.id === 'sendDate' ||
                                        column.id === 'creationDate' ||
                                        column.id === 'updateDate'
                                    "
                                >
                                    <span [matTooltip]="element[column.id]" matTooltipShowDelay="300">
                                        {{
                                            transformDate(
                                                element[column.id],
                                                column.id === 'creationDate' || column.id === 'updateDate'
                                            )
                                        }}
                                    </span>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        column.id !== 'fileSend' &&
                                        column.id !== 'fileAnswer' &&
                                        column.id !== 'sendDate' &&
                                        column.id !== 'initDate' &&
                                        column.id !== 'endDate' &&
                                        column.id !== 'creationDate' &&
                                        column.id !== 'updateDate' &&
                                        column.id !== 'type'
                                    "
                                >
                                    <span [matTooltip]="element[column.id]" matTooltipShowDelay="300">
                                        {{ element[column.id] }}
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9999">{{ 'PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA' | translate }}</td>
                </tr>
            </table>
        </div>
        <mat-paginator
            [length]="totalRegisters"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="pagination($event)"
            aria-label="Select page"
        >
        </mat-paginator>
    </div>
</div>
<div class="container containerNoData" *ngIf="!dataBusiness && validRole">
    <div class="row flex_column">
        <app-spinner></app-spinner>
        <app-http-error></app-http-error>
        <h3>{{ 'PAGE.ACTIONS.NO_DATA' | translate }}</h3>
    </div>
    <app-table-no-data></app-table-no-data>
</div>
