import { Component, OnInit } from '@angular/core';
import { ErrorHttpService } from 'src/app/services/error-http.service';

@Component({
  selector: 'app-http-error',
  template: `<div *ngIf="errorAppears$|async">
    <div class="content-spinner">
    <h1>Ha surgido un error</h1>
    </div>
  </div>`,
  styleUrls: ['./httperror.component.scss'],

})
export class HttpErrorComponent implements OnInit {
  errorAppears$ = this.errorHttpServ.errorAppears$;

  constructor(private errorHttpServ: ErrorHttpService) { }

  ngOnInit(): void {
  }
}
