import { Component, Input, OnInit } from '@angular/core';
import { FiltroSelect } from 'src/app/shared/models/filtros.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-filtro-input-multi',
    templateUrl: './filtro-input-multi.component.html',
    styleUrls: ['./filtro-input-multi.component.scss'],
})
export class FiltroInputMultiComponent implements OnInit {
    @Input() filtroUrl: FiltroSelect[]; // lo que te manda la pagina
    @Input() title: string;
    @Input() id: string;
    isChecked = false;
    form: UntypedFormGroup;
    text: string;

    constructor(private fb: UntypedFormBuilder) {
        this.form = this.fb.group({
            enable: false,
        });
        this.isChecked = this.form.value.enable;
    }

    ngOnInit(): void {}

    onChange(enable: boolean) {
        this.isChecked = this.form.value.enable;
    }

    saveSelect(event: any) {
        if (event.target.value.trim().length > 0) {
            this.filtroUrl.push(event.target.value);
            event.target.value = '';
        }
    }

    getInputs(multi: any): string {
        let opcion = '';
        if (multi.value) {
            multi.value.forEach((x: string) => (opcion += `${x}, `));
            if (opcion.length > 3) {
                opcion = opcion.substring(0, opcion.length - 2);
            }
        }
        return opcion;
    }
}
