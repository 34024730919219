<div class="container">
    <div class="header__row row-between" [ngStyle]="{'background-color': showEnvironment ? '#ffd740' : '#f8f7f7' }">
        <div class="header__name">
            <div class="return">
                <button mat-button (click)="goBack()" *ngIf="showBackButton">
                    <span class="icon material-symbols-outlined">arrow_back </span>
                    <span class="text">{{ 'PAGE.HEADER.BACK' | translate }}</span>
                </button>
            </div>
            <h1 class="title">{{ headerValue$ | async }}</h1>
        </div>
        <div class="header__actions">
            <div class="header__actions-notifications">
                <span class="icon material-symbols-outlined"> notifications </span>
            </div>
            <div class="header__actions-perfil">
                <button mat-button [matMenuTriggerFor]="menuUser">
                    <span class="icon material-symbols-outlined"> person </span>
                    <span class="text-subtitle"> {{ userName }} </span>
                </button>
                <mat-menu #menuUser="matMenu">
                    <button mat-menu-item routerLink="/user-management" *ngIf="isAdmin">
                        <span class="material-symbols-outlined icon"> group </span>
                        <span> {{ 'PAGE.HEADER.USERS_MANAGEMENT' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="logout()">
                        <span class="material-symbols-outlined icon"> logout </span>
                        <span> {{ 'PAGE.HEADER.LOGOUT' | translate }}</span>
                    </button>
                </mat-menu>
            </div>
            <div class="header__actions-languages">
                <div class="languages">
                    <button mat-button [matMenuTriggerFor]="countriesMenu" class="text">
                        <span class="fi fi-{{ currentCountry.toLowerCase() }} fis"></span>
                        <span class="icon material-symbols-outlined"> expand_more </span>
                    </button>
                    <mat-menu #countriesMenu="matMenu">
                        <button (click)="changeCountry('ES')" mat-menu-item>
                            <span class="fi fi-es fis"> </span>
                            <span class="header__actions--country">
                                {{ 'PAGE.HEADER.COUNTRIES.SPAIN' | translate }}
                            </span>
                        </button>
                        <button (click)="changeCountry('PT')" mat-menu-item>
                            <span class="fi fi-pt fis"> </span>
                            <span class="header__actions--country">
                                {{ 'PAGE.HEADER.COUNTRIES.PORTUGAL' | translate }}
                            </span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="header__actions-languages">
                <div class="languages">
                    <button mat-button [matMenuTriggerFor]="LanguagesMenu" class="text">
                        {{ currentLang }} <span class="icon material-symbols-outlined"> expand_more </span>
                    </button>
                    <mat-menu #LanguagesMenu="matMenu">
                        <button (click)="changeLanguage('es')" mat-menu-item>
                            {{ 'PAGE.HEADER.LANGUAGES.SPANISH' | translate }}
                        </button>
                        <button (click)="changeLanguage('pt')" mat-menu-item>
                            {{ 'PAGE.HEADER.LANGUAGES.PORTUGUESE' | translate }}
                        </button>
                        <button (click)="changeLanguage('en')" mat-menu-item>
                            {{ 'PAGE.HEADER.LANGUAGES.ENGLISH' | translate }}
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div *ngIf="showEnvironment">
                <h1>{{ environment }}</h1>
            </div>
        </div>
    </div>
</div>
