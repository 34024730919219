import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TablaMaestroComponent } from '../tabla-maestro.component';
import { LoginService } from '../../../../../core/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { MaestroService } from '../../../services/maestro.service';
import { IColumnsTable } from '../../../../../shared/models/columns-table.model';
import { EditModalComponent } from '../edit-modal/edit-modal.component';
import { KeysMaestro, MaestrosCRM } from '../../../../../shared/enums/maestros';
import { MatTable } from '@angular/material/table';

@Component({
    selector: 'app-boundary-points-modal',
    templateUrl: './boundary-points-modal.component.html',
    styleUrls: ['./boundary-points-modal.component.scss'],
})
export class BoundaryPointsModalComponent implements OnInit {
    constructor(
        public dialogShow: MatDialogRef<TablaMaestroComponent>,
        public EditModalComponent: MatDialog,
        private _loginService: LoginService,
        private _translateService: TranslateService,
        private _maestroService: MaestroService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.copyData = JSON.parse(JSON.stringify(data));
        this.boundaryPointsPermissions = this.copyData.permissions;
        this.boundaryCode = this.copyData.value.boundaryPointCode;
    }

    copyData: any;
    boundaryPointsPermissions: any;
    boundaryCode: string;
    boundaryPointsData: any;
    boundaryPointsHeaders: any;

    columns: any;
    dataHeaders: IColumnsTable[];
    @ViewChild(MatTable) table: MatTable<any>;

    ngOnInit(): void {
        this._maestroService.searchBoundaryPointsPuntoFrontera(this.boundaryCode).subscribe((boundaryPoints) => {
            this.boundaryPointsData = boundaryPoints.data.map((data: any) => ({
                boundaryPointCode: this.boundaryCode,
                cupsMP: data.cupsMP,
                cecoerActiveHub: data.cecoerActiveHub,
            }));
            this.boundaryPointsHeaders = this._loginService.getHeaderSession();
            this.buildTable(JSON.parse(this.boundaryPointsHeaders), 27);
        });
    }

    close(resp: boolean) {
        this.dialogShow.close();
    }

    edit(pos: any) {
        const dialogRef = this.EditModalComponent.open(EditModalComponent, {
            data: {
                header: this.dataHeaders,
                value: this.boundaryPointsData[pos],
                filter: this.copyData.filters,
                name: MaestrosCRM.PUNTO_MEDIDA,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result?.resp) {
                this._maestroService
                    .updatePuntoMedida(this.boundaryCode, result.cupsMP, result.data)
                    .subscribe((uF) => {
                        this.boundaryPointsData[pos] = { ...this.boundaryPointsData[pos], ...result.data };
                        this.table.renderRows();
                    });
            }
        });
    }

    transformHeaders() {
        this.columns = this.dataHeaders.map((col) => col.id);
    }

    buildTable(header: any, idHeaders: number): void {
        this.dataHeaders = header.data[idHeaders].headers;
        this.transformHeaders();
    }
}
