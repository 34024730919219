import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from 'src/app/core/services/login.service';
import { FileEnum } from 'src/app/shared/enums/cargas';
import { Files } from 'src/app/shared/interfaces/cargas.interface';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root',
})
export class CargasServiceService {
    private auth_token = this._loginService.getTokenSession();

    private headersFiles = new HttpHeaders({
        Accept: '*/*',
        Authorization: `Bearer ${this.auth_token}`,
    });

    private HttpUploadOptions = { headers: this.headersFiles };
    http: any;

    constructor(private _http: HttpClient, private _loginService: LoginService) {}

    getCargas(): Observable<Files[]> {
        const url = `${environment.apiUrl}/file/v0?page=-1`;
        return this._http.get<any>(url).pipe(
            map((e) => {
                return e.data as Files[];
            })
        );
    }

    getUrlDownload(param: string) {
        const url = `${environment.apiUrl}/file-process/v0/download?fileS3=${param}`;
        this._http.get(url, { responseType: 'blob' as 'json', observe: 'response' }).subscribe((response: any) => {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response.body);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            downloadLink.setAttribute('download', response.url.slice(response.url.lastIndexOf('/') + 1));
            document.body.appendChild(downloadLink);
            downloadLink.click();
        });
    }

    postFileProcess(body: any) {
        const url = `${environment.apiUrl}/file-process/v0`;
        return this._http.post<any>(url, body, this.HttpUploadOptions);
    }

    uploadFileProcess(body: any, url: string) {
        let reponse = this._http.put<any>(url, body);
        return reponse;
    }

    getSelectSistemas(): Observable<any> {
        const url = './assets/json/filtros.json';
        return this._http.get<any>(url).pipe(map((e) => e.filters));
    }

    downloadFile(filename: string) {
        return this._http.get(
            `${environment.apiUrl}/file-process/v0/download?fileS3=s3://dev-en-aws-seor-raw-s3-nqfs/data/distributor/f5d/year=2022/month=08/day=17/hour=13/F5D_0021_0522_20220523.0`,
            {
                responseType: 'arraybuffer',
            }
        );
    }

    downloadFileMultiple(filename: any) {
        return this._http.get(`${filename}`, {
            responseType: 'arraybuffer',
        });
    }

    postDummyContract(body: any) {
        const url = `${environment.apiUrl}/contract/dummy/v0`;
        return this._http.post<any>(url, body);
    }

    formatResponse(filesResponse: Files[]): Files[] {
        return filesResponse.map((cargas: Files) => {
            switch (cargas.description) {
                case 'MEDIDA DISTRIBUIDOR F1':
                    return { ...cargas, description: FileEnum.DIS_F1 };
                case 'MEDIDA DISTRIBUIDOR F5D':
                    return { ...cargas, description: FileEnum.DIS_F5D };
                case 'MEDIDA DISTRIBUIDOR P1':
                    return { ...cargas, description: FileEnum.DIS_P1 };
                case 'MEDIDA DISTRIBUIDOR P2':
                    return { ...cargas, description: FileEnum.DIS_P2 };
                case 'MEDIDA RED ELÉCTRICA F5D':
                    return { ...cargas, description: FileEnum.REE_F5D };
                case 'MEDIDA RED ELÉCTRICA EPFPH':
                    return { ...cargas, description: FileEnum.REE_EPFPH };
                case 'MEDIDA REE F5D':
                    return { ...cargas, description: FileEnum.M_REE_F5D };
                case 'MEDIDA REE EPFPH':
                    return { ...cargas, description: FileEnum.M_REE_EPFPH };
                case 'ACUM':
                    return { ...cargas, description: FileEnum.ACUM };
                case 'INMECLOS':
                    return { ...cargas, description: FileEnum.INMECLOS };
                case 'MEDIDA DISTRIBUIDOR RF5D':
                    return { ...cargas, description: FileEnum.DIS_RF5D };
                case 'MEDIDA CONCENTRADOR P1':
                    return { ...cargas, description: FileEnum.CONC_P1 };
                case 'MEDIDA CONCENTRADOR P2':
                    return { ...cargas, description: FileEnum.CONC_P2 };
                case 'PREVISION CLIENTES':
                    return { ...cargas, description: FileEnum.PREV_CLIENTES };
                case 'PREVISION METEOLOGICA':
                    return { ...cargas, description: FileEnum.PREV_MET };
                case 'PREVISION METEOLOGICA AGREGADA':
                    return { ...cargas, description: FileEnum.PREV_MET_AGR };
                case 'FACTURA ATR':
                    return { ...cargas, description: FileEnum.ATR };
                case 'CONSUMPTION RT':
                    return { ...cargas, description: FileEnum.RT };
                case 'LIQUIDACION POTESTATIVA':
                    return { ...cargas, description: FileEnum.LIQ_POS };
                default:
                    return {
                        ...cargas,
                        description:
                            cargas.description.charAt(0).toUpperCase() + cargas.description.slice(1).toLowerCase(),
                    };
            }
        });
    }
}
