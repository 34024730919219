import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    availableCountries: string[] = ['ES', 'PT'];
    defaultCountry = 'ES';
    preferredCountry: string | null;

    get countries(): string[] {
        return this.availableCountries;
    }

    constructor() {
        const preferredCountry = this.getPreferredCountry();
        this.preferredCountry = preferredCountry ? preferredCountry : this.defaultCountry;
    }

    public changeCountry(country: string): void {
        this.defaultCountry = country;
        this.setPreferredCountry(country);
    }

    public setPreferredCountry(country: string): void {
        localStorage.setItem('userPreferredCountry', country);
    }

    public getPreferredCountry(): string | null {
        return localStorage.getItem('userPreferredCountry');
    }
}
