<mat-form-field appearance="standard" class="select">
    <mat-label>{{ title }}</mat-label>
    <mat-select
        (selectionChange)="emitValueNew($event); emitValue(title + '=' + $event.value)"
        [(value)]="value"
        [placeholder]="value"
    >
        <mat-option *ngFor="let e of optionsYear" [value]="e">{{ e }}</mat-option>
    </mat-select>
</mat-form-field>
