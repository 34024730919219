import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ExportService } from 'src/app/services/export.service';
import { DownloadExcelComponent } from 'src/app/shared/components/download-excel/download-excel.component';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { Tabla } from 'src/app/shared/models/files.interface';
import { Permisos } from 'src/app/shared/models/permisos.interface';
import { ProcesosService } from '../../services/procesos.service';
import { AddModalProcessResComponent } from './add-modal-process-res/add-modal-process-res.component';
import { DeleteModalProcessResComponent } from './delete-modal-process-res/delete-modal-process-res.component';
import { EditModalProcessResComponent } from './edit-modal-process-res/edit-modal-process-res.component';
import { HeaderTitleService } from 'src/app/services/header-title.service';

export interface DialogData {
    value: any;
}

@Component({
    selector: 'app-tabla-procesos-res',
    templateUrl: './tabla-procesos-res.component.html',
    styleUrls: ['./tabla-procesos-res.component.scss'],
})
export class TablaProcesosResComponent implements OnInit {
    @Input() dataBusiness: any[];
    @Input() dataHeaders: IColumnsTable[] = [];
    @Input() dataHeaders2: IColumnsTable[] = [];
    @Input() dataHeaders_valueSelect: IColumnsTable[] = [];

    @Input() columns: any[];
    @Input() totalRegisters: number;
    @Output() sort: EventEmitter<any> = new EventEmitter();
    @Output() pagination: EventEmitter<any> = new EventEmitter();
    @Input() permissions: Permisos;
    @Input() filtersTable: any[];
    @Input() idFilters: any;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    dataSource: any;

    @Input() filters: any;
    // input con filtros

    @ViewChild(MatTable) table: MatTable<any>;

    displayedColumns: any;
    infotabla: string[];
    dataShow: Tabla[] = [];
    message: string = '';
    action: string = '';
    config: any;
    element = false;
    ocultar = true;
    dataHeaderChange = true;

    fechaInit: any[] = [];
    fechaEnd: any[] = [];
    horaIni: any[] = [];
    horaFin: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<DownloadExcelComponent>,
        public dialog: MatDialog,
        public DeleteModalProcessResComponent: MatDialog,
        public EditModalProcessResComponent: MatDialog,
        public AddModalProcessResComponent: MatDialog,
        public readonly _headerTitleService: HeaderTitleService,
        private excelService: ExportService,
        private exportService: ExportService,
        private procesosService: ProcesosService,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<any>(this.dataBusiness);
        this.message = this._translateService.instant('PAGE.MODALS.LOADING_MESSAGE_EXCEL');
        this.action = this._translateService.instant('PAGE.MODALS.CLOSE');
    }

    pageChange($event: any) {
        this.pagination.emit($event);
    }

    sortData(sort: Sort) {
        this.sort.emit(sort);
    }

    exportExcel(): void {
        this.excelService.exportToExcel(this.dataBusiness, 'export');
    }

    openModalExcel() {
        this.exportService.openDialog(this.dataBusiness, this.idFilters, this.totalRegisters);
    }

    delete(pos: any) {
        const dialogRef = this.DeleteModalProcessResComponent.open(DeleteModalProcessResComponent);

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                var idTable = this.dataBusiness[pos].id;
                this.dataBusiness.splice(pos, 1);
                this.table.renderRows();
            }
        });
    }

    edit(pos: any) {
        const dialogRef = this.EditModalProcessResComponent.open(EditModalProcessResComponent, {
            data: { header: this.dataHeaders_valueSelect, value: this.dataBusiness[pos], filter: this.filters },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result.resp) {
                this.dataBusiness[pos] = result.data.value;
                this.table.renderRows();
            }
        });
    }

    add() {
        const dialogRef = this.AddModalProcessResComponent.open(AddModalProcessResComponent, {
            data: { header: this.dataHeaders_valueSelect, value: this.dataBusiness, filter: this.filters },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result.resp) {
                this.dataBusiness.push(result.newValue);
                this.table.renderRows();
            }
        });
    }

    showTimeInit(pos: any) {
        const initDate = new Date(pos.initDate);
        if (this._headerTitleService.currentLang === 'en') {
            this.fechaInit[pos.id] =
                initDate.getFullYear() + '-' + (initDate.getMonth() + 1) + '-' + initDate.getDate();
        } else {
            this.fechaInit[pos.id] =
                initDate.getDate() + '-' + (initDate.getMonth() + 1) + '-' + initDate.getFullYear();
        }
        this.horaIni[pos.id] = initDate.getHours() + ':' + initDate.getMinutes() + ':' + initDate.getSeconds();
        return this.fechaInit[pos.id];
    }

    showTimeEnd(pos: any) {
        const endDate = new Date(pos.endDate);
        if (this._headerTitleService.currentLang === 'en') {
            this.fechaEnd[pos.id] = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
        } else {
            this.fechaEnd[pos.id] = endDate.getDate() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getFullYear();
        }
        this.horaFin[pos.id] = endDate.getHours() + ':' + endDate.getMinutes() + ':' + endDate.getSeconds();
        return this.fechaEnd[pos.id];
    }

    //>>>>>>>>>>>>>FUNCIONES PARA MOSTRAR/OCULTAR COLUMNAS
    checkvalue(dataHeaders2: any) {
        this.dataHeaders_valueSelect = dataHeaders2;
        this.columns = [];
        for (let i = 0; i < dataHeaders2.length; i++) {
            let columnId;
            columnId = dataHeaders2[i].id;
            this.columns.push(columnId);
        }
    }
    botonClick() {
        if (this.element) {
            //Mostrar-ocultar Etiqueta Span "Columnas"
            this.element = false;
        } else {
            this.element = true;
        }
        if (!this.ocultar) {
            //Mostrar-ocultar select de columnas
            this.ocultar = true;
        } else {
            this.ocultar = false;
        }
    }

    filterDataHeader() {
        if (this.dataHeaders2 != this.dataHeaders) {
            this.dataHeaders2 = this.dataHeaders;
            this.dataHeaders_valueSelect = [];
            this.dataHeaderChange = true;
        }
        if (this.dataHeaderChange === true) {
            for (let i = 0; i < this.dataHeaders.length; i++) {
                if (this.dataHeaders[i].isVisible === true! || this.dataHeaders[i].isVisible == undefined) {
                    this.dataHeaders_valueSelect.push(this.dataHeaders[i]);
                }
            }
            this.columns = [];
            for (let i = 0; i < this.dataHeaders_valueSelect.length; i++) {
                let columnId;
                columnId = this.dataHeaders_valueSelect[i].id;
                this.columns.push(columnId);
            }
        }

        this.dataHeaderChange = false;

        return this.dataHeaders;
    }
    //<<<<<<<<<<<<FUNCIONES PARA MOSTRAR/OCULTAR COLUMNAS
}
