import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ExportService } from 'src/app/services/export.service';
import { DownloadExcelComponent } from 'src/app/shared/components/download-excel/download-excel.component';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { Tabla } from 'src/app/shared/models/files.interface';
import { Permisos } from 'src/app/shared/models/permisos.interface';
import { PrevisionesService } from '../../services/previsiones.service';
import { MatPaginator } from '@angular/material/paginator';
import { ReturnVariableTypes, VariableTypes } from 'src/app/shared/enums/variableTypesContractPrices';
import { EditModalComponentPrevisions } from './edit-modal/edit-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-tabla-previsiones',
    templateUrl: './tabla-previsiones.component.html',
    styleUrls: ['./tabla-previsiones.component.scss'],
})
export class TablaPrevisionesComponent implements OnInit, AfterViewInit {
    @ViewChild(MatTable) table: MatTable<any>;
    @ViewChild('paginator') paginator: MatPaginator;

    @Input() dataBusiness: any[];
    @Input() dataHeaders: IColumnsTable[] = [];
    @Input() dataHeaders2: IColumnsTable[] = [];
    @Input() dataHeaders_valueSelect: IColumnsTable[] = [];

    @Input() columns: any[];

    @Input() totalRegisters: number;
    @Input() totalPages: number;
    @Input() numPage: number;
    @Input() pageSize: number;
    @Output() sort: EventEmitter<any> = new EventEmitter();
    @Output() pagination: EventEmitter<any> = new EventEmitter();
    pageSizeOptions: number[] = [10, 25, 50, 100];
    paginatorDataSource: MatTableDataSource<any>;

    @Input() permissions?: Permisos;
    @Input() filtersTable: any[];
    @Input() idFilters: any;
    @Input() regions?: any[] = [];
    @Input() filters: any;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    dataSource: any;
    numPagePaginator: number;
    selectId: string;
    salto: boolean;
    nuevoArray: any = [];
    month: any;

    displayedColumns: any;
    infotabla: string[];
    dataShow: Tabla[] = [];
    message: string = '';
    action: string = '';
    config: any;
    element = false;
    ocultar = true;
    dataHeaderChange = true;
    datosCambiados: any[] = [];
    envioPutBack: any[] = [];
    actualDate: Date = new Date();

    get VariableTypes() {
        return VariableTypes;
    }

    get ReturnVariableTypes() {
        return ReturnVariableTypes;
    }

    constructor(
        public dialogRef: MatDialogRef<DownloadExcelComponent>,
        public dialog: MatDialog,
        public previsionService: PrevisionesService,
        public EditModalComponent: MatDialog,
        public readonly _headerTitleService: HeaderTitleService,
        private exportService: ExportService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<any>(this.dataBusiness);
        this.message = this._translateService.instant('PAGE.MODALS.LOADING_MESSAGE_EXCEL');
        this.action = this._translateService.instant('PAGE.MODALS.CLOSE');
        this.selectId = this.previsionService.selectId;
    }

    ngAfterViewInit(): void {
        this.paginatorDataSource = new MatTableDataSource(this.dataBusiness);
        this.paginatorDataSource.paginator = this.paginator;
    }

    pageChange(event: any) {
        this.pagination.emit(event);
    }

    sortData(sort: Sort) {
        this.sort.emit({ sort: sort, id: this.idFilters[0] });
    }

    checkAndReturnColumns(element: any, columnId: string) {
        if (columnId !== 'date' && columnId !== 'programUnitCode') {
            const columnaHora = columnId.split('_');

            for (let i = 1; i <= 25; i++) {
                if (element === undefined || element[i] === undefined) {
                    element[columnId] = '-';
                } else {
                    if (columnaHora[1] == element[i].hour && element[i].hour !== undefined) {
                        element[columnId] = element[i].energy;
                        return this._formatNumber(element[columnId].toString());
                    }
                }
            }
        }
        if (columnId === 'programUnitCode') {
            if (columnId.indexOf('.') > -1) {
                let columnIdSplitted = columnId.split('.');
                return element[columnIdSplitted[0]][columnIdSplitted[1]];
            }

            if (element.zoneGroup === null) {
                if (element.zone === null) {
                    element.zone = '';
                    element.zoneGroup = '';
                } else {
                    element.zoneGroup = element.zone;
                }
            }
        }
        if (columnId === 'date') {
            return this._transformDate(element[columnId], false);
        }
        return element[columnId];
    }

    private _formatNumber(value: string): string {
        if (value !== null && !isNaN(Number.parseInt(value)) && !/[a-zA-Z@#$%^&*()_+{}\[\]:;<>?//-]/.test(value)) {
            value =
                value.includes('.') && this._headerTitleService.currentLang !== 'en'
                    ? value.replaceAll('.', ',')
                    : value;
            const decimalSeparator: '.' | ',' = this._headerTitleService.currentLang === 'en' ? '.' : ',';
            const thousandsSeparator: '.' | ',' = this._headerTitleService.currentLang === 'en' ? ',' : '.';
            if (value.includes(decimalSeparator)) {
                const valueParts: any[] = value.split(decimalSeparator);
                const decimalPart = valueParts[1] || '';
                const formattedInteger = valueParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
                return decimalPart ? `${formattedInteger}${decimalSeparator}${decimalPart}` : formattedInteger;
            } else {
                return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
            }
        } else {
            return '_';
        }
    }

    private _transformDate(date: string, largeFormat: boolean) {
        if (date.includes('/')) {
            date = date.replaceAll('/', '-');
        }
        const datePipe = new DatePipe('en-US');
        let dates = new Date(date);
        let formatDate;
        if (isNaN(dates.getTime())) {
            formatDate = '-';
        } else {
            if (largeFormat) {
                formatDate = datePipe.transform(
                    dates,
                    `${this._headerTitleService.currentLang !== 'en' ? 'dd/MM/yyyy' : 'yyyy/MM/dd'} HH:mm:ss`
                );
            } else {
                formatDate = datePipe.transform(
                    dates,
                    `${this._headerTitleService.currentLang !== 'en' ? 'dd/MM/yyyy' : 'yyyy/MM/dd'}`
                );
            }
        }
        return formatDate;
    }

    // FUNCIONES PARA MOSTRAR/OCULTAR COLUMNAS
    checkvalue(dataHeaders2: any) {
        this.dataHeaders_valueSelect = dataHeaders2;
        this.columns = [];
        this.dataHeaders_valueSelect.forEach((valueSelect: IColumnsTable) => {
            this.columns.push(valueSelect.id);
        });
    }

    botonClick() {
        if (this.element) {
            //Mostrar-ocultar Etiqueta Span "Columnas"
            this.element = false;
        } else {
            this.element = true;
        }
        if (!this.ocultar) {
            //Mostrar-ocultar select de columnas
            this.ocultar = true;
        } else {
            this.ocultar = false;
        }
    }

    openModalExcel() {
        this.dataBusiness.forEach((data: any) => {
            this.nuevoArray.push({
                programUnitCode: data.programUnitCode,
                date: data.date,
                hour_1: data.hour_1,
                hour_2: data.hour_2,
                hour_3: data.hour_3,
                hour_4: data.hour_4,
                hour_5: data.hour_5,
                hour_6: data.hour_6,
                hour_7: data.hour_7,
                hour_8: data.hour_8,
                hour_9: data.hour_9,
                hour_10: data.hour_10,
                hour_11: data.hour_11,
                hour_12: data.hour_12,
                hour_13: data.hour_13,
                hour_14: data.hour_14,
                hour_15: data.hour_15,
                hour_16: data.hour_16,
                hour_17: data.hour_17,
                hour_18: data.hour_18,
                hour_19: data.hour_19,
                hour_20: data.hour_20,
                hour_21: data.hour_21,
                hour_22: data.hour_22,
                hour_23: data.hour_23,
                hour_24: data.hour_24,
                hour_25: data.hour_25,
            });
        });
        this.exportService.openDialog(this.nuevoArray, this.idFilters[0].name, this.totalRegisters);
        this.nuevoArray = [];
    }

    filterDataHeader() {
        if (this.dataHeaders2 != this.dataHeaders) {
            this.dataHeaders2 = this.dataHeaders;
            this.dataHeaders_valueSelect = [];
            this.dataHeaderChange = true;
        }
        if (this.dataHeaderChange === true) {
            for (let i = 0; i < this.dataHeaders?.length; i++) {
                if (this.dataHeaders[i].isVisible === true! || this.dataHeaders[i].isVisible == undefined) {
                    this.dataHeaders_valueSelect.push(this.dataHeaders[i]);
                }
            }
            this.columns = [];
            this.dataHeaders_valueSelect.forEach((valueSelect: IColumnsTable) => {
                this.columns.push(valueSelect.id);
            });
        }
        this.dataHeaderChange = false;
        return this.dataHeaders;
    }

    edit(pos: any) {
        const dialogRef = this.EditModalComponent.open(EditModalComponentPrevisions, {
            data: {
                header: this.dataHeaders.map((h) => {
                    if (h.id === 'regionCode') {
                        return {
                            ...h,
                            data: this.regions?.map((r: any) => ({
                                name: r.regionDescription,
                                value: r.regionCode,
                            })),
                        };
                    }
                    return h;
                }),
                value: this.dataBusiness[pos],
                filter: this.filters,
                name: this.filtersTable[0],
                regions: this.regions,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result.resp === false) {
                return;
            } else {
                this.salto = false;
                // pusheamos en energyPerHour los valores que se han modificado (energia) y los que no se pushean como null
                for (let i = 1; i <= 26; i++) {
                    if (i === 26) {
                        this.salto = true;
                        break;
                    }

                    for (let l = 0; l < result.datosCambiados.length; l++) {
                        const maximaHora = result.datosCambiados.length - 1;
                        if (result.datosCambiados[l].name == i && i <= result.datosCambiados[maximaHora].name) {
                            i++;
                            const resultado = Number(result.datosCambiados[l].newValue);
                            result.data.energyPerHour.push(resultado);
                            if (i === 26) {
                                this.salto = true;
                                break;
                            }
                        } else {
                            l = l - 1;
                            i++;
                            result.data.energyPerHour.push(null);

                            if (i === 26) {
                                this.salto = true;
                                break;
                            }
                        }
                    }
                    i--;
                }
                if (this.salto === true) {
                    const datePipe = new DatePipe('en-US');
                    let date: string[] = result.data.date.replaceAll('/', '-').split('-');
                    let dates = new Date(result.data.date);
                    this.envioPutBack = [];
                    this.envioPutBack.push({
                        programUnitCode: result.data.programUnitCode,
                        date: datePipe.transform(dates, 'yyyy-MM-dd'),
                        energyPerHour: result.data.energyPerHour,
                    });

                    this.previsionService.updateAggregatedUnit(this.envioPutBack).subscribe((uF) => {
                        this.envioPutBack = [];
                        this.dataBusiness[pos] = {
                            ...this.dataBusiness[pos],
                            ...result.data,
                        };
                        for (let i = 1; i <= 25; i++) {
                            const hora = this.dataBusiness[pos]['h' + i];
                            if (this.dataBusiness[pos][i] === undefined) {
                                break;
                            } else if (i == this.dataBusiness[pos][i].hour) {
                                this.dataBusiness[pos][i].energy = hora;
                            }
                        }
                        this.table.renderRows();
                        this.successSnackBar();
                    });
                }
            }
        });
    }

    checkClass(elemento?: any) {
        if (elemento?.type === 'Modificado') {
            return 'colorColumnEnergy';
        }
        return '';
    }

    checkElementDate(elementDate: any): boolean {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        let elementFormattedDate = elementDate.date.split('/');
        elementFormattedDate = new Date(
            elementFormattedDate[2] + '/' + elementFormattedDate[1] + '/' + elementFormattedDate[0]
        );
        elementFormattedDate.setHours(0, 0, 0, 0);
        return elementFormattedDate >= currentDate;
    }

    successSnackBar() {
        this._translateService.get('PAGE.MODALS').subscribe((message) => {
            this._snackBar.open(message['EDIT_SUCCESS'], message['ACTION_CLOSE'], {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        });
        setTimeout(() => {
            this._snackBar.dismiss();
        }, 4000);
    }
}
