<span [formGroup]="getFormGroup(parent.control)">
    <ng-container *ngIf="!hideHours">
        <mat-form-field>
            <mat-label>{{ 'PAGE.CRON.DAILY_HOUR_FRAGMENT' | translate }}</mat-label>
            <mat-select formControlName="hours">
                <mat-option
                    *ngFor="let hour of hours"
                    [value]="hour"
                    (click)="onValueChanged(getFormGroup(parent.control).value)"
                    >{{ hour }}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!hideMinutes">
        <span *ngIf="!hideHours">:</span>
        <mat-form-field>
            <mat-label>{{ 'PAGE.CRON.DAILY_MINUTE_FRAGMENT' | translate }}</mat-label>
            <mat-select formControlName="minutes">
                <mat-option
                    *ngFor="let minute of minutes"
                    [value]="minute"
                    (click)="onValueChanged(getFormGroup(parent.control).value)"
                    >{{ minute }}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!use24HourTime">
        <span></span>.
        <mat-form-field>
            <mat-select formControlName="hourType">
                <mat-option
                    *ngFor="let hourType of hourTypes"
                    [value]="hourType"
                    (click)="onValueChanged(getFormGroup(parent.control).value)"
                    >{{ hourType }}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </ng-container>
</span>
