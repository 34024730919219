import { Roles } from '../enums/roles.enum';
import { ItemMenu } from '../interfaces/itemMenu.interface';

export const PrevisionesPage: ItemMenu = {
    id: '6',
    name: 'PREVISIONES',
    title: 'Datos Previsiones',
    route: 'previsiones',
    icon: 'calendar_view_month',
    subItems: [
        {
            id: '6.1',
            name: 'AGGREGATE_PROVISIONS',
            title: 'Agregado de previsiones',
            route: 'previsiones/agregado-previsiones',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.PREVISION],
        },
        {
            id: '6.2',
            name: 'BEST_FORECAST',
            title: 'Selección de la mejor previsión',
            route: 'previsiones/mejor-prevision',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.PREVISION],
        },
    ],
    roles: [Roles.ADMIN, Roles.PREVISION],
};
