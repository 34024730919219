<mat-form-field appearance="standard" class="date">
    <mat-label>{{ name }}</mat-label>
    <mat-date-range-input [rangePicker]="picker">
        <input
            matStartDate
            placeholder="{{ 'PAGE.FILTERS.SELECT.INIT_DATE' | translate }}"
            (dateInput)="getValue($event)"
            [(ngModel)]="dateInit"
        />
        <input
            matEndDate
            placeholder="{{ 'PAGE.FILTERS.SELECT.END_DATE' | translate }}"
            (dateInput)="getValue($event, 'fd')"
            [(ngModel)]="dateEnd"
        />
    </mat-date-range-input>
    <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
