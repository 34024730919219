export enum VariableTypes {
    VARIABLE_TYPE_F = 'F',
    VARIABLE_TYPE_I1 = 'I1',
    VARIABLE_TYPE_I2 = 'I2',
    VARIABLE_TYPE_I3 = 'I3',
    VARIABLE_TYPE_I4 = 'I4',
    VARIABLE_TYPE_I5 = 'I5',
    VARIABLE_TYPE_I6 = 'I6',
    VARIABLE_TYPE_I7 = 'I7',
}

export enum ReturnVariableTypes {
    TYPE_F = 'Fijo',
    TYPE_I1 = 'Indexado horario REE',
    TYPE_I2 = 'Indexado promedio REE',
    TYPE_I3 = 'Indexado horario CNMC',
    TYPE_I4 = 'Indexado promedio CNMC',
    TYPE_I5 = 'Indexado horario OMIE',
    TYPE_I6 = 'Indexado promedio OMIE',
    TYPE_I7 = 'Indexado promd OMIE sin perid tarif',
}
