import { AfterContentChecked } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { EnviosService } from 'src/app/pages/envios/envios.service';
import { HeaderTitleService } from 'src/app/services/header-title.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export function dateFormatFactory(headerTitleService: HeaderTitleService) {
    const dateFormat = headerTitleService.currentLang === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
    return {
        parse: {
            dateInput: dateFormat,
        },
        display: {
            dateInput: dateFormat,
            monthYearLabel: dateFormat,
            dateA11yLabel: 'LL',
            monthYearA11yLabel: dateFormat,
        },
    };
}

@Component({
    selector: 'app-filtro-date-range',
    templateUrl: './filtro-date-range.component.html',
    styleUrls: ['./filtro-date-range.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useFactory: dateFormatFactory, deps: [HeaderTitleService] },
        { provide: NG_VALUE_ACCESSOR, useExisting: FiltroDateRangeComponent, multi: true },
    ],
})
export class FiltroDateRangeComponent implements OnInit, AfterContentChecked {
    @Input() isSendsPage: boolean = false;
    @Input() isPeninsularSystem: boolean = false;
    @Input() name: string = '';
    @Input() dateInit: any = '';
    @Input() dateEnd: any = '';
    @Output() date: EventEmitter<any> = new EventEmitter();
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() isDateIncorrect: EventEmitter<any> = new EventEmitter();
    @Input() text: string = 'Rango de fechas';

    day: string = '';
    month: string = '';
    year: string = '';
    dateComplete: string = '';
    limit: string;
    rangoBuenoSemana: string = '';
    diaLimite: any;
    rangoBuenoDia: string;
    rangoBuenoMes: string;
    dateInitCopy: any;
    dateEndCopy: any;
    diasDiferencia: number;
    isCurrentDateIncorrect: boolean;

    constructor(private _enviosService: EnviosService, private readonly _headerTitleService: HeaderTitleService) {}

    ngAfterContentChecked(): void {
        let aux = this.dateComplete;
        this.dateComplete = aux;
    }

    ngOnInit(): void {
        this.dateInit = '';
        this.dateEnd = '';
        this._enviosService.getIfPeninsularSystem().subscribe((value: any) => {
            this.isPeninsularSystem = value;
            if (!this.isPeninsularSystem) {
                this.isDateIncorrect.emit(false);
            } else {
                this.isDateIncorrect.emit(this.isCurrentDateIncorrect);
            }
        });
        if (
            (this.dateInit && this.dateEnd) ||
            (this._headerTitleService.currentInitDate && this._headerTitleService.currentEndDate)
        ) {
            this._setDates();
        }
    }

    getValue($event: any, date?: string) {
        let startDate: string;
        let daySplit: string;
        let monthSplit: string;
        let yearSplit: string;

        if ($event?.value?._i?.date < 10) {
            this.day = this.addLeadingZeros($event.value._i.date, 2);
        } else {
            /*
          Uso un bucle para que, si $event.value._i.date no existe, los valores que le damos a mano al inputDate se guarden y se reflejen
          ACLARACION: $event.value._i.date no existe cuando intentamos meter un valor a mano sin el Datepicker.
          (solo existe $event.value._i y dentro nos da una cadena de texto con la fecha completa).
      */

            if (!$event?.value?._i?.date) {
                if (this.isSendsPage && this.isPeninsularSystem && !$event.value?._i) {
                    this.isDateIncorrect.emit(true);
                }
                daySplit = $event.value?._i?.split('/')[0];
                this.day = daySplit;
            } else {
                this.day = $event.value._i.date;
            }
        }

        if ($event?.value?._i?.month + 1 < 10) {
            this.month = this.addLeadingZeros($event.value._i.month, 2);
        } else {
            /*
          Uso un bucle para que, si $event.value._i.date no existe, los valores que le damos a mano al inputDate se guarden y se reflejen
          ACLARACION: $event.value._i.date no existe cuando intentamos meter un valor a mano sin el Datepicker.
          (solo existe $event.value._i y dentro nos da una cadena de texto con la fecha completa).
      */
            if (!$event?.value?._i?.month) {
                monthSplit = $event?.value?._i?.split('/')[1];
                this.month = monthSplit;
            } else {
                this.month = $event.value._i.month;
            }
        }

        /*
        Uso un bucle para que, si $event.value._i.date no existe, los valores que le damos a mano al inputDate se guarden y se reflejen
        ACLARACION: $event.value._i.date no existe cuando intentamos meter un valor a mano sin el Datepicker.
        (solo existe $event.value._i y dentro nos da una cadena de texto con la fecha completa).
    */
        if (!$event?.value?._i?.year) {
            yearSplit = $event?.value?._i?.split('/')[2];
            this.year = yearSplit;
        } else {
            this.year = $event.value._i.year;
        }
        if ($event?.value) {
            startDate = this.year + '-' + (parseInt(this.month) + 1) + '-' + this.day;
            this.dateEndCopy = startDate;
            if (date !== 'fd') {
                this.isDateIncorrect.emit(true);
                let rangoSemana: Date = new Date(startDate);
                this.diaLimite = new Date(startDate);

                this.diaLimite.setDate(rangoSemana.getDate() + 7);

                if (rangoSemana.getDate() <= 9) {
                    this.rangoBuenoDia = '0' + rangoSemana.getDate().toString();
                } else {
                    this.rangoBuenoDia = rangoSemana.getDate().toString();
                }
                if (rangoSemana.getMonth() < 9) {
                    this.rangoBuenoMes = '0' + (rangoSemana.getMonth() + 1);
                } else {
                    this.rangoBuenoMes = (rangoSemana.getMonth() + 1).toString();
                }
            }
            let fechaFinal: any = '';
            if (date === 'fd') {
                let mes = this.diaLimite.getMonth() + 1;
                let dia = this.diaLimite.getDate();
                if (dia <= 9) {
                    dia = '0' + dia;
                }
                if (mes <= 9) {
                    mes = '0' + mes;
                }

                fechaFinal = `${this.diaLimite.getFullYear()}-${mes}-${dia}`;

                this.dateComplete = this.dateComplete.concat('~' + fechaFinal);

                if (startDate !== undefined && this.dateEnd !== undefined && startDate !== '' && this.dateEnd !== '') {
                    let fecha1 = moment(this.dateInitCopy);
                    let fecha2 = moment(this.dateEndCopy);

                    (this.diasDiferencia = fecha2.diff(fecha1, 'days')), ' dias de diferencia';
                }

                if (parseInt(this.dateEndCopy.split('-')[1]) < 10) {
                    this.dateEndCopy =
                        this.dateEndCopy.split('-')[0] +
                        '-0' +
                        this.dateEndCopy.split('-')[1] +
                        '-' +
                        this.dateEndCopy.split('-')[2];
                }
                if (this.diasDiferencia < 7) {
                    this.dateComplete = this.dateInitCopy + '~' + this.dateEndCopy;
                    this.dateInit = this.dateInitCopy;
                    this.dateEnd = this.dateEndCopy;
                    this._headerTitleService.currentInitDate = this.dateInitCopy;
                    this._headerTitleService.currentEndDate = this.dateEndCopy;
                }
                startDate = '';
                this.date.emit(this.dateComplete);
                this.select.emit(this.dateComplete);
            } else {
                this.dateComplete = this.year + '-' + this.rangoBuenoMes + '-' + this.rangoBuenoDia;
                this.limit = this.year + '-' + this.rangoBuenoMes + '-' + this.rangoBuenoDia;
                this.dateInit = new Date(this.dateComplete);
                this.dateInitCopy = this.limit;
            }
            if (this.diasDiferencia > 6) {
                this.dateEnd = new Date(fechaFinal);
                this._headerTitleService.currentInitDate = this.dateInit;
                this._headerTitleService.currentEndDate = this.dateEnd;
            }
            if (this.isSendsPage && (this.diasDiferencia === 0 || this.diasDiferencia >= 7)) {
                const differenceInDays: number = moment(this.dateEnd).diff(moment(this.dateInitCopy), 'days');
                if (differenceInDays === 0 || differenceInDays >= 7) {
                    this.isCurrentDateIncorrect = false;
                    this.isDateIncorrect.emit(false);
                } else {
                    this.isCurrentDateIncorrect = true;
                    this.isDateIncorrect.emit(this.isPeninsularSystem ? this.isCurrentDateIncorrect : false);
                }
            } else if (this.isSendsPage && this.diasDiferencia !== 0 && this.diasDiferencia < 7) {
                this.isCurrentDateIncorrect = true;
            }
            if (!this.isSendsPage || !this.isPeninsularSystem) {
                this.isDateIncorrect.emit(false);
            }
        }
    }

    /**
     * Método para poner 0 delante si la longitud es X
     * @param num Número que le pasamos
     * @param totalLength Longitud total
     * @returns
     */
    addLeadingZeros(num: number, totalLength: number): string {
        return String(num).padStart(totalLength, '0');
    }

    private _setDates(): void {
        this.dateInit = moment(this._headerTitleService.currentInitDate).format('YYYY-MM-DD');
        this.dateEnd = moment(this._headerTitleService.currentEndDate).format('YYYY-MM-DD');
        this._headerTitleService.currentInitDate = this.dateInit;
        this._headerTitleService.currentEndDate = this.dateEnd;
    }
}
