<div class="modal-title-close">
    <h1 mat-dialog-title>{{ 'PAGE.MODALS.TITLE4' | translate }}</h1>
    <button mat-button mat-dialog-close class="hide-access close" (click)="close(false)">close</button>
</div>
<div mat-dialog-content>
    <ng-container *ngIf="isPuntoMedida">
        <ng-container *ngFor="let filtro of copyDataMapped; let i = index">
            <div
                *ngIf="
                    !inputIdNotShowedGeneral.includes(filtro.id) &&
                    isPuntoMedida &&
                    filtro.id === KeysMaestro.ACTIVE_C_CECOER
                "
            >
                <app-filtro-select
                    *ngIf="filtro.filter?.toLowerCase() === 'select' && filtro.id === KeysMaestro.ACTIVE_C_CECOER"
                    (selectSelected)="valueChange($event)"
                    [filtroUrl]="filtro.data"
                    [title]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                ></app-filtro-select>
            </div>
            <app-filtro-input
                *ngIf="filtro.filter?.toLowerCase() === 'text' && filtro.id === KeysMaestro.SINGULAR_POINTS"
                [name]="filtro.label"
                [id]="filtro.id"
                [value]="filtro.value"
                (select)="valueChange($event)"
            ></app-filtro-input>

            <app-filtro-date
                *ngIf="filtro.filter?.toLowerCase() === 'date' && filtro.id === KeysMaestro.COMMERCIAL_REQUEST"
                [name]="filtro.label"
                [id]="filtro.id"
                [preloadedUniqueDate]="filtro.value"
                [isDateRange]="false"
                (select)="valueChange($event)"
            ></app-filtro-date>
            <app-filtro-select
                *ngIf="filtro.filter?.toLowerCase() === 'select' && filtro.id === KeysMaestro.CONNECTION_ISSUE"
                (selectSelected)="valueChange($event)"
                [filtroUrl]="filtro.data"
                [title]="filtro.label"
                [id]="filtro.id"
                [value]="filtro.value"
            ></app-filtro-select>
            <app-filtro-input
                *ngIf="filtro.filter?.toLowerCase() === 'number' && filtro.id === KeysMaestro.PORT"
                [name]="filtro.label"
                [id]="filtro.id"
                [value]="filtro.value"
                (select)="valueChange($event)"
            ></app-filtro-input>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isFestivos">
        <ng-container *ngFor="let filtro of copyDataMapped; let i = index">
            <div *ngIf="!inputIdNotShowedGeneral.includes(filtro.id)">
                <app-filtro-date
                    *ngIf="filtro.filter?.toLowerCase() === 'date'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [preloadedUniqueDate]="filtro.value"
                    [isDateRange]="false"
                    (select)="valueChange($event)"
                ></app-filtro-date>
                <app-filtro-select
                    *ngIf="filtro.id === 'festiveType'"
                    (selectSelected)="valueChange($event)"
                    [filtroUrl]="filtro.data"
                    [title]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                ></app-filtro-select>
                <app-filtro-input
                    *ngIf="filtro.filter?.toLowerCase() === 'text'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                    (select)="valueChange($event)"
                ></app-filtro-input>
                <app-filtro-select
                    *ngIf="filtro.filter?.toLowerCase() === 'select' && filtro?.id !== 'festiveType' && isAutonomic"
                    (selectSelected)="valueChange($event)"
                    [filtroUrl]="filtro.data"
                    [title]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                ></app-filtro-select>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!isFestivos && !isPuntoMedida && !isPuntoFrontera && !isPreciosRegulados && !isParametros">
        <ng-container *ngFor="let filtro of copyData.header; let i = index">
            <div *ngIf="!inputIdNotShowedGeneral.includes(filtro.id)">
                <app-filtro-date
                    *ngIf="filtro.filter === 'date'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [preloadedUniqueDate]="copyData.value[filtro.id]"
                    [isDateRange]="false"
                    (select)="valueChange($event)"
                ></app-filtro-date>
                <app-filtro-select
                    *ngIf="filtro.filter === 'select'"
                    (selectSelected)="valueChange($event)"
                    [filtroUrl]="filtro.options"
                    [title]="filtro.label"
                    [id]="filtro.id"
                    [value]="copyData.value[filtro.id]"
                ></app-filtro-select>
                <app-filtro-input
                    *ngIf="filtro.filter == 'text'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [value]="copyData.value[filtro.id]"
                    (select)="valueChange($event)"
                ></app-filtro-input>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isPuntoFrontera">
        <ng-container *ngFor="let filtro of copyDataMapped; let i = index">
            <div
                *ngIf="
                    !inputIdNotShowedPuntoFrontera.includes(filtro.id) && !inputIdNotShowedGeneral.includes(filtro.id)
                "
            >
                <app-filtro-date
                    *ngIf="filtro.filter?.toLowerCase() === 'date'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [preloadedUniqueDate]="filtro.value"
                    [isDateRange]="false"
                    (select)="valueChange($event)"
                ></app-filtro-date>
                <app-filtro-select
                    *ngIf="filtro.filter?.toLowerCase() === 'select'"
                    (selectSelected)="valueChange($event)"
                    [filtroUrl]="filtro.data"
                    [title]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                ></app-filtro-select>
                <app-filtro-input
                    *ngIf="filtro.filter?.toLowerCase() === 'text'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                    (select)="valueChange($event)"
                ></app-filtro-input>
                <app-filtro-input
                    *ngIf="filtro.filter?.toLowerCase() === 'number'"
                    [type]="'number'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                    (select)="valueChange($event)"
                ></app-filtro-input>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isPreciosRegulados">
        <ng-container *ngFor="let filtro of copyDataMapped; let i = index">
            <div
                *ngIf="
                    !inputIdNotShowedPriceRegulated.includes(filtro.id) && !inputIdNotShowedGeneral.includes(filtro.id)
                "
            >
                <app-filtro-date
                    *ngIf="filtro.filter?.toLowerCase() === 'date'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [preloadedUniqueDate]="filtro.value"
                    [isDisabled]="filtro.id === 'initialDate'"
                    [isDateRange]="false"
                    (select)="valueChange($event)"
                ></app-filtro-date>
                <app-filtro-select
                    *ngIf="filtro.filter?.toLowerCase() === 'select'"
                    (selectSelected)="valueChange($event)"
                    [filtroUrl]="filtro.data"
                    [title]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                ></app-filtro-select>
                <app-filtro-input
                    (keypress)="
                        (filtro.label === 'P1' ||
                        filtro.label === 'P2' ||
                        filtro.label === 'P3' ||
                        filtro.label === 'P4' ||
                        filtro.label === 'P5' ||
                        filtro.label === 'P6'
                            ? ''
                            : $event.charCode >= 48 && $event.charCode < 58)
                    "
                    type="number"
                    *ngIf="filtro.filter?.toLowerCase() === 'text'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                    (select)="valueChange($event)"
                ></app-filtro-input>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isParametros">
        <ng-container *ngFor="let filtro of copyDataMapped; let i = index">
            <div *ngIf="!inputIdNotShowedGeneral.includes(filtro.id)">
                <app-filtro-select
                    *ngIf="filtro.filter?.toLowerCase() === 'select'"
                    (selectSelected)="valueChange($event)"
                    [filtroUrl]="filtro.data || filtro.options"
                    [title]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                ></app-filtro-select>
                <app-filtro-input
                    *ngIf="filtro.filter?.toLowerCase() === 'text'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [value]="filtro.value"
                    (select)="valueChange($event)"
                ></app-filtro-input>
            </div>
        </ng-container>
    </ng-container>
</div>
<div *ngIf="isIncorrectDate && (isPuntoFrontera || isPreciosRegulados)" class="incorrectDate">
    <h3 class="incorrectDateH3">
        {{ 'PAGE.MODALS.THE_END_DATE_MUST_BE_AFTER_THE_START_DATE' | translate }} ({{ dateInit }})
    </h3>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close class="secondary" (click)="close(false)">
        {{ 'PAGE.MODALS.CANCEL' | translate }}
    </button>
    <button
        mat-button
        mat-dialog-close
        mat-flat-button
        color="warn"
        class="primary"
        [disabled]="isIncorrectDate"
        (click)="close(true)"
        cdkFocusInitial
    >
        {{ 'PAGE.MODALS.ACCEPT' | translate }}
    </button>
</div>
