import { Roles } from '../enums/roles.enum';
import { ItemMenu } from '../interfaces/itemMenu.interface';

export const EnviosPage: ItemMenu = {
    id: '7',
    name: 'ENVIOS',
    title: 'Datos Envíos',
    route: 'envios',
    icon: 'electrical_services',
    subItems: [
        {
            id: '7.1',
            name: 'LAUNCH',
            title: 'Lanzar envíos',
            route: 'envios/lanzar',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.PREVISION],
        },
        {
            id: '7.22',
            name: 'RESULT',
            title: 'Resultados de envíos',
            route: 'envios/resultados',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.PREVISION],
        },
    ],
    roles: [Roles.ADMIN, Roles.PREVISION],
};
