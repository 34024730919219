import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TablaMaestroComponent } from '../tabla-maestro.component';


@Component({
  selector: 'delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {
  constructor(
    public DialogDelete: MatDialogRef<TablaMaestroComponent>
    ) {}
  close(resp: boolean){
    this.DialogDelete.close(resp);
  }

}
