<app-select-previsiones
    (optionSelectedId)="receiveIdSelect($event)"
    (resetTable)="clearTable($event)"
    (changeSelectReset)="reset()"
    (OptionFilters)="receiveIdFilters($event)"
></app-select-previsiones>

<app-tabla-previsiones
    [dataBusiness]="dataBusiness"
    [filtersTable]="filtersTable"
    [dataHeaders]="dataHeaders"
    [columns]="columns"
    [totalPages]="totalPages"
    [pageSize]="pageSize"
    [numPage]="numPage"
    [totalRegisters]="totalRegisters"
    [permissions]="permissions"
    (sort)="sort($event, idFilters)"
    [filters]="filters"
    [idFilters]="idFilters"
></app-tabla-previsiones>
