import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { ListaFiltros, Filtro, FiltroSelect } from 'src/app/shared/models/filtros.interface';
import { FiltroSelectComponent } from '../../../../shared/components/filtros/filtro-select/filtro-select.component';
import { PrevisionesService } from '../../services/previsiones.service';
import { LoginService } from '../../../../core/services/login.service';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-select-previsiones',
    templateUrl: './select-previsiones.component.html',
    styleUrls: ['./select-previsiones.component.scss'],
})
export class SelectPrevisionesComponent implements OnInit, OnDestroy {
    @Output() optionSelectedId = new EventEmitter<string>();
    @Output() resetTable = new EventEmitter<string>();
    @Output() OptionFilters = new EventEmitter<any>();
    @Output() changeSelectReset = new EventEmitter<any>();
    @Output() filtersList = new EventEmitter<any>();
    @Input() tipoConcepto: string;
    subfilters: Filtro[] | undefined;
    displayedSubfilters: Filtro[] = [];
    selectSubfilter: FiltroSelect[];
    filters: ListaFiltros[] = [];
    disableSelect = new FormControl(false);
    subcriptions: Subscription[] = [];
    tablaSeleccionada: string | undefined;
    selectId: any;
    isDisabled: boolean = true;
    inputValues: { id: string; value: string }[] = [];
    resetSelectedOption: any;
    resetSubfilter: any;
    showinput: any;
    showSelect: boolean = true;
    intermedio: boolean = true;
    resetCups: boolean;
    userID: any;
    unitPrograms = new FormControl('');
    electrictSystemSelected: boolean = false;
    toggleActive: boolean = false;

    yearValue: string;

    userAllRoles: any;
    validRole: any = true;

    @ViewChildren('selects') selects: QueryList<FiltroSelectComponent>;
    defaultSelect: string = '';

    specialFeeActivated?: boolean = undefined;
    disableSelectFirst: boolean = false;

    // booleano para controlar si filtrar los filtros por ejemplo por codigo o por descripcion
    unitProgramListNoFilter: any;
    unitProgramList: any;
    electricSystemList: any;
    programUnit: any;
    selectedInitialForecasts: any;
    eventvalue: string;
    userRoles: string[] = [];
    searchBy: boolean = this.previsionService.searchBy;
    cuentaNegociadoraActive: boolean;
    cuentaNegActive: boolean;
    isDateInvalid: boolean;

    constructor(
        private previsionService: PrevisionesService,
        private globalService: GlobalService,
        private _loginService: LoginService,
        protected _headerTitleService: HeaderTitleService
    ) {}

    ngOnInit(): void {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.getFilters(this.previsionService.searchBy);
        this.resetCups = false;

        this.previsionService.getUnidadPrograma().subscribe((UP) => {
            this.unitProgramListNoFilter = UP.data;
            this.unitProgramList = UP.data;
            this.electricSystemList = [];
            for (let i = 0; i < UP.data.length; i++) {
                this.electricSystemList.push(UP.data[i].electricSystem);
            }
            let electricSystemFilter: any = Array.from(new Set(this.electricSystemList)); //Se filtra la lista de "Sist. eléctrico" para eliminar elementos repetidos
            electricSystemFilter = electricSystemFilter.map(function (word: string) {
                word === 'PENINSULA'
                    ? (word = 'Península')
                    : (word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                return word;
            });
            this.electricSystemList = electricSystemFilter;
        });

        this.userAllRoles = this._loginService.getUserActiveRoles();
        this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
        this.userRoles = this.userRoles.map((element: any) => element.trim());
        if (
            this.userRoles.findIndex((role) => role === 'PREVISION') !== -1 ||
            this.userRoles.findIndex((role) => role === 'ADMIN') !== -1
        ) {
            this.validRole = true;
        } else {
            this.validRole = false;
        }
    }

    ngOnChange(): void {
        this.resetCups = false;
    }

    /**
     * Metodo para controlar el booleano de filtrar por
     * @param searchBy  Boolean para controlar si filtrar por una cosa u otra
     */
    getFiltersBy(searchBy: [boolean, string]) {
        let subfilter = this.subfilters?.find((filter: any) => filter.key === searchBy[1]);
        if (subfilter) {
            subfilter.toggleState = searchBy[0];
        }
    }

    ngOnDestroy(): void {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.subfilters = this.subfilters?.map((fil: any) => {
            fil.selected = undefined;
            return fil;
        });
        this.subcriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    getFilters(filterByBoolean: boolean) {
        this.subcriptions.push(
            this.globalService.getAllFilters(filterByBoolean).subscribe((filtros) => {
                filtros.data.forEach((filter: any) => {
                    if (filter['name'] === 'previsiones_agregado') {
                        this.filters = filter;
                        this.subfilters = filter.filters;
                    }
                });
                this.obtainSubfilters();
            })
        );
    }

    checkValueSelects(selectedOption: any) {
        this.previsionService.showColumnsBy = '';
    }

    obtainSubfilters() {
        this.resetTable.emit();
        this.inputValues = [];
        this.filtersList.emit({ subfilters: this.subfilters });
        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element) => {
                if (element.required) {
                    if (element.name.includes('*')) {
                        return;
                    } else {
                        element.name = element.name + ' *';
                    }
                }
            });
        }
    }

    obtainSelectSubfiltros() {
        this.subfilters
            ?.filter((f) => {
                f.type === 'Select';
            })
            .forEach((s) => s.url);
    }

    isDisableButtonRequired() {
        var countRequired = 0;
        var countSelected = 0;
        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element) => {
                if (element.required) {
                    countRequired++;
                    if (element.selected) {
                        countSelected++;
                    }
                }
            });
        }
        if (this.isDateInvalid) {
            this.isDisabled = true;
        } else if (countRequired === 0) {
            this.isDisabled = false;
        } else {
            if (countRequired === countSelected) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        }
    }

    loadTable() {
        let selectFilter: any = [this.filters, this.subfilters, this.programUnit];
        if (this.subfilters !== undefined) {
            this.optionSelectedId.emit(selectFilter);
        } else {
            this.optionSelectedId.emit(this.selectId);
        }
    }

    obtainOption(event: { id: string; value: string } | any) {
        if (!event.id && !event.programUnitCode) {
            const id = event;
            event = { id, event };

            this.inputValues.push(event);
        } else if (event.programUnitCode) {
            this.inputValues.push(event);
        } else {
            if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
                this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
            } else {
                this.inputValues.push(event);
                this.isDisableButtonRequired();
            }
        }
    }

    checkConditions(filtro: string[] | undefined): boolean {
        if (this.showinput === false) {
            return true;
        }
        if (!filtro || !filtro.length) {
            return true;
        }
        let solution = false;
        filtro.forEach((cond) => {
            if (this.inputValues.some((inpVal) => inpVal.value === cond)) {
                solution = true;
            }
        });
        return solution;
    }

    receiveDate($event: any, filtro: any, reset?: boolean) {
        if (this.subfilters !== undefined) {
            this.subfilters[filtro].selected = $event;
        }
        this.isDisableButtonRequired();
    }

    restarValue(event: any, filtro: any, key: string, filtroType?: any) {
        this.formatSelectedValue(event, filtro, key, filtroType);
        if (this.subfilters !== undefined && event.newValue) {
            this.subfilters[filtro].selected = event.newValue;
        }
    }
    /**
     *
     * @param event Dato seleccionado
     * @param filtro El número del select de filtros
     * @param key Key del filtro
     */
    formatSelectedValue(event: any, filtro: any, key: string, filtroType: string) {
        if (filtroType !== 'Date' && this.subfilters) {
            let filtroFormateado = event.split('=');
            this.subfilters[filtro].selected = filtroFormateado[1];
        }
    }

    clearFilters() {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.showSelect = true;
        this.getFilters(this.previsionService.searchBy);
        this.obtainSubfilters();
        this.resetCups = true;
        this.yearValue = '';
        this.isDisabled = true;
        this.reset();
        this.unitProgramList = [];
    }

    hideSelect() {
        this.showSelect = false;
    }

    electricSystemSelected: any;
    filterUnitProgram(electricSystemSelected: any) {
        electricSystemSelected === 'Península'
            ? (electricSystemSelected = 'PENINSULA')
            : (electricSystemSelected = electricSystemSelected.toUpperCase());
        let unitProgramFilter = [];
        for (let i = 0; i < this.unitProgramListNoFilter.length; i++) {
            if (this.unitProgramListNoFilter[i].electricSystem === electricSystemSelected) {
                unitProgramFilter.push(this.unitProgramListNoFilter[i]);
            }
        }
        this.unitProgramList = [];
        this.programUnit = [];
        this.unitProgramList = unitProgramFilter;

        // Rellenamos todos por defecto
        this.unitProgramList = this.unitProgramList.map((uP: any) => uP.programUnitCode);
        this.unitPrograms = new FormControl(this.unitProgramList);
        this.programUnit = this.unitProgramList;
    }

    obtainUnitsPrograms(programUnit: any) {
        this.programUnit = programUnit.value;
    }

    obtainSelectedOptionElectrictSystem(eSystem: any) {
        if (eSystem.value) {
            this.electrictSystemSelected = true;
        } else {
            this.electrictSystemSelected = false;
        }
    }

    obtainOption3(event: { id: string; value: string }, valueCuentNeg: any) {
        if (this.selectedInitialForecasts) {
            if (event.value) {
                //Aquí esta el valor del select de cuenta negociadora
                this.cuentaNegociadoraActive = true;
                this.eventvalue = event.value;
            }
            this.cuentaNegActive = true;
            if (valueCuentNeg) {
                this.cuentaNegociadoraActive = true;
            }
        }
        if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }
    }

    receiveDate2(filtro: any) {
        this.isDisableButtonRequired();
    }

    activeToggleDeleteRegisters() {
        if (this.toggleActive) {
            this.toggleActive = false;
        } else {
            this.toggleActive = true;
        }
    }

    reset() {
        if (this.subfilters != undefined) {
            this.subfilters.forEach(function (e) {
                delete e['selected'];
            });
        }
        if (this.programUnit) {
            delete this.programUnit;
        }
    }

    protected invalidDate($event: boolean): void {
        this.isDateInvalid = $event;
        this.isDisableButtonRequired();
    }
}
