import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ExportService } from 'src/app/services/export.service';
import { ReturnVariableTypes, VariableTypes } from 'src/app/shared/enums/variableTypesContractPrices';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { Tabla } from 'src/app/shared/models/files.interface';
import { Permisos } from 'src/app/shared/models/permisos.interface';
import { InformesService } from '../../../services/informes.service';
import { DeleteModalProcessResComponent } from '../../../../procesos/components/tabla-procesos-res/delete-modal-process-res/delete-modal-process-res.component';
import { ReportStatus } from '../../../../../shared/enums/reports';
import { DatePipe } from '@angular/common';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-tabla-informes-resultado',
    templateUrl: './tabla-informes-resultado.component.html',
    styleUrls: ['./tabla-informes-resultado.component.scss'],
})
export class TablaInformesComponent implements OnInit {
    @ViewChild(MatTable) table: MatTable<any>;
    @ViewChild('paginator') paginator: MatPaginator;

    @Input() dataBusiness: any[];
    @Input() dataHeaders: IColumnsTable[] = [];
    @Input() dataHeaders2: IColumnsTable[] = [];
    @Input() dataHeaders_valueSelect: IColumnsTable[] = [];

    @Input() columns: any[];

    @Input() totalRegisters: number;
    @Input() totalPages: number;
    @Input() numPage: number;
    @Input() pageSize: number;
    @Output() sort: EventEmitter<any> = new EventEmitter();
    @Output() pagination: EventEmitter<any> = new EventEmitter();
    @Output() deletingReport = new EventEmitter<any>();
    pageSizeOptions: number[] = [10, 25, 50, 100];
    paginatorDataSource: MatTableDataSource<any>;

    @Input() permissions?: Permisos;
    @Input() filtersTable: any[];
    @Input() idFilters: any;
    @Input() regions?: any[] = [];
    @Input() filters: any;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    dataSource: any;
    numPagePaginator: number;
    selectId: string;
    salto: boolean;
    nuevoArray: any = [];
    month: any;

    displayedColumns: any;
    infotabla: string[];
    dataShow: Tabla[] = [];
    message: string = '';
    action: string = '';
    config: any;
    element = false;
    ocultar = true;
    dataHeaderChange = true;
    datosCambiados: any[] = [];
    envioPutBack: any[] = [];
    actualDate: Date = new Date();
    isSaved: boolean = false;
    formattedUtc: any;
    currentUtc: string;

    get VariableTypes() {
        return VariableTypes;
    }

    get ReturnVariableTypes() {
        return ReturnVariableTypes;
    }

    constructor(
        public dialog: MatDialog,
        public DeleteModalProcessResComponent: MatDialog,
        public readonly _headerTitleService: HeaderTitleService,
        private _exportService: ExportService,
        private _informesServices: InformesService,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<any>(this.dataBusiness);
        this.message = this._translateService.instant('PAGE.MODALS.LOADING_MESSAGE_EXCEL');
        this.action = this._translateService.instant('PAGE.MODALS.CLOSE');
        this.selectId = this._informesServices.selectId;
    }

    ngAfterViewInit(): void {
        this.paginatorDataSource = new MatTableDataSource(this.dataBusiness);
        this.paginatorDataSource.paginator = this.paginator;
    }

    pageChange(event: any) {
        this.pagination.emit(event);
    }

    sortData(sort: Sort) {
        this.sort.emit({ sort: sort, id: this.idFilters[0] });
    }
    // This function uses the enum in Reports.ts to display a status 
    getStatus(element: any) {
        if (element.status === ReportStatus.SAVED) {
            return 'PAGE.REPORTS.SAVED'
        } else if (element.status == ReportStatus.ERR_NO_DATA) {
            return 'PAGE.REPORTS.ERR_NO_DATA'
        } else if (element.status === ReportStatus.IN_PROGRESS) {
            return 'PAGE.REPORTS.IN_PROGRESS' 
        } else {
            return 'PAGE.REPORTS.ERROR'
        }
    }

    checkAndReturnColumns(element: any, columnId: string) {
        this.isSaved = false;
        if (element.status === ReportStatus.SAVED) {
            this.isSaved = true;
        }
        if (columnId.indexOf('.') > -1) {
            let columnIdSplitted = columnId.split('.');

            return element[columnIdSplitted[0]][columnIdSplitted[1]];
        }
        if (element.zoneGroup === null) {
            element.zoneGroup = element.zone;
        }
        if (element.zoneGroup === null && element.zone === null) {
            element.zone = '';
            element.zoneGroup = '';
        }

        return element[columnId];
    }

    // FUNCIONES PARA MOSTRAR/OCULTAR COLUMNAS
    protected checkValue(dataHeaders2: any) {
        this.dataHeaders_valueSelect = dataHeaders2;
        this.columns = [];
        this.dataHeaders_valueSelect.forEach((valueSelect: IColumnsTable) => {
            this.columns.push(valueSelect.id);
        });
    }

    botonClick() {
        if (this.element) {
            //Mostrar-ocultar Etiqueta Span "Columnas"
            this.element = false;
        } else {
            this.element = true;
        }
        if (!this.ocultar) {
            //Mostrar-ocultar select de columnas
            this.ocultar = true;
        } else {
            this.ocultar = false;
        }
    }

    openModalExcel() {
        this._exportService.openDialog(this.dataBusiness, this.idFilters, this.totalRegisters);
    }

    filterDataHeader() {
        if (this.dataHeaders2 != this.dataHeaders) {
            this.dataHeaders2 = this.dataHeaders;
            this.dataHeaders_valueSelect = [];
            this.dataHeaderChange = true;
        }
        if (this.dataHeaderChange === true) {
            for (let i = 0; i < this.dataHeaders?.length; i++) {
                if (this.dataHeaders[i].isVisible === true! || this.dataHeaders[i].isVisible == undefined) {
                    this.dataHeaders_valueSelect.push(this.dataHeaders[i]);
                }
            }
            this.columns = [];
            this.dataHeaders_valueSelect.forEach((valueSelect: IColumnsTable) => {
                this.columns.push(valueSelect.id);
            });
        }
        this.dataHeaderChange = false;
        return this.dataHeaders;
    }

    protected downloadReport(report: any) {
        this._informesServices.downloadReport(report);
    }

    protected deleteReport(id: number) {
        const dialogRef = this.DeleteModalProcessResComponent.open(DeleteModalProcessResComponent);
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._informesServices.deleteReport(id).subscribe((x: any) => {
                    this.deletingReport.emit();
                });
            }
        });
    }

    showTimeInit(pos: any) {
        let formatDate: any;
        if (pos.initDate !== null) {
            const datePipe = new DatePipe('en-US');
            formatDate = datePipe.transform(
                pos.initDate,
                `${this._headerTitleService.currentLang === 'en' ? 'yyyy/MM/dd HH:mm:ss' : 'dd/MM/yyyy HH:mm:ss'}`
            );
        } else {
            formatDate = '-';
        }
        return formatDate;
    }

    showTimeEnd(pos: any) {
        let formatDate: any;
        if (pos.endDate !== null) {
            const datePipe = new DatePipe('en-US');
            formatDate = datePipe.transform(
                pos.endDate,
                `${this._headerTitleService.currentLang === 'en' ? 'yyyy/MM/dd HH:mm:ss' : 'dd/MM/yyyy HH:mm:ss'}`
            );
        } else {
            formatDate = '-';
        }
        return formatDate;
    }
}
