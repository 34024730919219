import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TablaProcesosResComponent } from '../tabla-procesos-res.component';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-add-modal-process-res',
    templateUrl: './add-modal-process-res.component.html',
    styleUrls: ['./add-modal-process-res.component.scss'],
})
export class AddModalProcessResComponent {
    copyData: any;
    newAdd: any;
    newValue: any = [];
    isSave: boolean = false;
    constructor(
        public DialogAdd: MatDialogRef<TablaProcesosResComponent>,
        protected readonly _headerTitleService: HeaderTitleService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.copyData = JSON.parse(JSON.stringify(data));
        this.copyData.header.forEach((e: any) => {
            this.newValue[e.id] = '';
        });
    }

    close(resp: boolean) {
        let key = Object.keys(this.newValue);
        for (let i = 0; i < key.length; i++) {
            let clave = key[i];
            if (this.newValue[clave] !== '') {
                this.isSave = true;
            }
        }

        if (this.isSave) {
            const newData = { resp: resp, data: this.data, newValue: this.newValue };
            this.DialogAdd.close(newData);
        }
    }
    valueChange($event: any) {
        this.copyData.header.forEach((element: any) => {
            if (element.id == $event.name) {
                this.newValue[$event.name] = $event.newValue;
            }
        });
    }
}
