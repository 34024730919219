<div
    class="hierarchical__item hierarchical__item__{{ hierarchicalLevel }}"
    routerLinkActive="active"
    routerLink="{{ item?.route }}"
    *ngIf="!hasChildren"
>
    <div class="item">
        <div class="icon-and-item" [ngClass]="{ item__mini: !opened }">
            <app-base-icon iconName="{{ item?.icon }}"></app-base-icon>
            <a class="item__name">{{ 'PAGE.SIDEBAR.SIDEBAR_SUB_ITEMS.' + item?.name | translate }} </a>
        </div>
        <app-base-icon iconName="chevron_right"> </app-base-icon>
    </div>
</div>

<div
    class="hierarchical__item hierarchical__item__{{ hierarchicalLevel }}"
    routerLinkActive="active"
    *ngIf="hasChildren"
    (click)="openCloseSubItems()"
    (mouseleave)="closeSubItem()"
>
    <div class="item">
        <div class="icon-and-item" [ngClass]="{ item__mini: !opened }">
            <app-base-icon iconName="{{ item?.icon }}" routerLink="{{ item?.route }}"></app-base-icon>
            <a class="item__name"> {{ 'PAGE.SIDEBAR.' + item?.name | translate }} </a>
        </div>
        <app-base-icon iconName="chevron_right"> </app-base-icon>
    </div>

    <ul *ngIf="hasChildren && isOpen" [ngClass]="{ mini: !opened }" (mouseleave)="closeSubItem()">
        <li *ngFor="let subItem of item?.subItems; let i = index">
            <app-sidebar-menu-item
                [opened]="opened"
                [item]="subItem"
                [hierarchicalLevel]="hierarchicalLevel + 1"
                class="position-submenu"
            ></app-sidebar-menu-item>
        </li>
    </ul>
</div>
