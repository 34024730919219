<div class="modal-title-close">
    <h1 mat-dialog-title>{{ 'PAGE.MODALS.TITLE2' | translate }}</h1>
    <button mat-button mat-dialog-close class="hide-access close" (click)="close(false)">close</button>
</div>
<div mat-dialog-content>
    <div *ngFor="let filtro of copyData.header; let i = index">
        <app-filtro-date
            *ngIf="filtro.filter === 'date'"
            [name]="filtro.label"
            [id]="filtro.id"
            [isDateRange]="false"
            (select)="valueChange($event)"
        ></app-filtro-date>
        <app-filtro-select
            *ngIf="filtro.filter === 'select'"
            (selectSelected)="valueChange($event)"
            [filtroUrl]="filtro.options"
            [title]="filtro.label"
            [id]="filtro.id"
        ></app-filtro-select>
        <app-filtro-input
            *ngIf="filtro.filter == 'text'"
            [name]="filtro.label"
            [id]="filtro.id"
            (select)="valueChange($event)"
        ></app-filtro-input>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close class="secondary" (click)="close(false)">
        {{ 'PAGE.MODALS.DECLINE' | translate }}
    </button>
    <button
        mat-button
        mat-dialog-close
        mat-flat-button
        color="warn"
        class="primary"
        (click)="close(true)"
        cdkFocusInitial
    >
        {{ 'PAGE.MODALS.ACCEPT' | translate }}
    </button>
</div>
