import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ErrorHttpService } from 'src/app/services/error-http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { GlobalService } from 'src/app/services/global.service';
import { LoginService } from 'src/app/core/services/login.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';

LoginService;
@Injectable()
export class httpErrorInterceptor implements HttpInterceptor {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        private HttpErrorServ: ErrorHttpService,
        private SpinnerServ: SpinnerService,
        private _globalService: GlobalService,
        private _loginService: LoginService,
        private readonly _matDialog: MatDialog,
        private _snackBar: MatSnackBar
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.HttpErrorServ.hide();
        finalize(() => this.SpinnerServ.show());

        return next.handle(req).pipe(
            catchError((err) => {
                if (err.status != 200 || err.status != '200') {
                    switch (err.status) {
                        case 400:
                            // Interception for errors due to manual uploads of more than 1000 files
                            if (
                                err.error?.errors?.status ===
                                'El numero maximo permitido de ficheros en una unica carga es 1000'
                            ) {
                                const dialogRef = this._matDialog.open(AlertModalComponent, {
                                    data: {
                                        title: 'PAGE.MODALS.EXCEED_LIMIT_1000_IN_LOADS_ERROR_TITLE',
                                        text: 'PAGE.MODALS.EXCEED_LIMIT_1000_IN_LOADS_ERROR_MESSAGE',
                                        hideCancelButton: true,
                                    },
                                });
                            }
                            break;
                        case 404:
                            // Intercept for errors in 'Best forecast selection for not finding forecasts that match the selected filters
                            if (err?.error?.message === 'Entrada no encontrada') {
                                const dialogRef = this._matDialog.open(AlertModalComponent, {
                                    data: {
                                        title: 'PAGE.MODALS.ENTRY_NOT_FOUND_TITLE',
                                        text: 'PAGE.MODALS.ENTRY_NOT_FOUND_MESSAGE',
                                        hideCancelButton: true,
                                    },
                                });
                            }
                            break;
                        case 500:
                            this.showSnackBar('Se ha producido un error en el servidor');
                            break;
                    }
                    return throwError(this.HttpErrorServ.show());
                } else {
                    return throwError(this.SpinnerServ.show());
                }
            })
        );
    }

    mensajeError(error: HttpErrorResponse) {
        this.HttpErrorServ.show();
        finalize(() => this.HttpErrorServ.hide());
    }

    showSnackBar(message: string) {
        this._snackBar.open(message, 'CLOSE', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 4000,
        });
    }
}
