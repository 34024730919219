<app-select-maestro
    (optionSelectedId)="receiveIdSelect($event)"
    (resetTable)="clearTable($event)"
    (OptionFilters)="receiveIdFilters($event)"
    (changeSelectReset)="reset()"
    [tipoConcepto]="'aws'"
></app-select-maestro>
<app-tabla-maestro
    [regions]="regions"
    [dataBusiness]="dataBusiness"
    [filtersTable]="filtersTable"
    [dataHeaders]="dataHeaders"
    [columns]="columns"
    [totalPages]="totalPages"
    [pageSize]="pageSize"
    [numPage]="numPage"
    [totalRegisters]="totalRegisters"
    [permissions]="permissions"
    (refreshAfterEdit)="refreshAfterEdit()"
    (sort)="sort($event)"
    (pagination)="pagination($event)"
    [filters]="filters"
    [idFilters]="idFilters"
></app-tabla-maestro>
