<div class="modal-bigger-size">
    <div class="modal-top">
        <h2>{{ title }}</h2>
        <div class="modal-title-close">
            <button mat-button mat-dialog-close class="hide-access close" (click)="close()">close</button>
        </div>
    </div>
    <!-- CLAIMS AND OBJECTIONS TABLES -->
    <div class="tableScroll">
        <table class="claimsTable" [ngClass]="objectionCode && objectionCode !== '' ? 'objectionsTable' : ''">
            <thead>
                <ng-container *ngFor="let column of displayedColumns">
                    <!-- HEADERS GROUPS -->
                    <ng-container *ngIf="column.group">
                        <ng-container *ngIf="column.group.isTheHeader">
                            <th [attr.colspan]="column.group.length" class="headerGroup">
                                <div class="headerGroup__title">
                                    {{ column.group.label }}
                                </div>
                                <ng-container *ngFor="let column of column.group.columns">
                                    <th colspan="1" class="headerGroup__content">
                                        {{ column.label }}
                                    </th>
                                </ng-container>
                            </th>
                        </ng-container>
                    </ng-container>
                    <!-- NORMAL HEADERS -->
                    <ng-container *ngIf="!column.group">
                        <th>{{ column.label }}</th>
                    </ng-container>
                </ng-container>
                <!-- EDIT BUTTONS COLUMN HEADER -->
                <th *ngIf="objectionCode && objectionCode !== ''">{{ 'PAGE.ACTIONS.EDIT' | translate }}</th>
            </thead>
            <tbody>
                <ng-container *ngFor="let register of modifiedDataSource">
                    <tr (click)="showTabs(register)" [ngClass]="claimCode && claimCode !== '' ? 'rowHover' : ''">
                        <ng-container *ngFor="let cell of register">
                            <ng-container
                                *ngIf="cell.id !== 'id' && cell.id !== 'sinceDate' && cell.id !== 'untilDate'"
                            >
                                <td [ngClass]="checkClass(cell.value)">
                                    {{ checkValue(cell.value) }}
                                </td>
                            </ng-container>
                            <ng-container *ngIf="cell.id === 'sinceDate' || cell.id === 'untilDate'">
                                <td [ngClass]="checkClass(cell.value)">
                                    {{ transformDate(cell.value) }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <!-- EDIT BUTTONS COLUMN -->
                        <td *ngIf="objectionCode && objectionCode !== ''" class="verDetalle">
                            <span (click)="editRow(register)" class="icon material-symbols-outlined">edit</span>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <!-- PAGINATION -->
    <div class="divForPaginator">
        <div>
            <span
                >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                {{ totalRegisters | numberFormat : _headerTitleService.currentLang }}</span
            >
            <mat-paginator
                [length]="totalRegisters"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChange($event)"
            >
            </mat-paginator>
        </div>
    </div>
    <!-- TABS SYSTEM -->
    <mat-tab-group animationDuration="500ms" *ngIf="isTabSystemVisible">
        <!-- UNIQUE FIELDS TAB -->
        <mat-tab label="{{ 'PAGE.MASTER.MASTER_AWS.CLAIMS.UNIQUE_FIELDS' | translate }}">
            <ng-container *ngFor="let detail of dataSourceUniqueFields | keyvalue">
                <div class="details">
                    <div class="detailLabel">{{ getLabelUniqueFieldsByKey(detail.key) }}</div>
                    <div
                        class="detailValue"
                        [ngClass]="checkClass(detail.value)"
                        *ngIf="
                            detail.key !== 'acceptDate' &&
                            detail.key !== 'sinceDate' &&
                            detail.key !== 'untilDate' &&
                            detail.key !== 'denyDate'
                        "
                    >
                        {{ checkValue(detail.value) }}
                    </div>
                    <div
                        class="detailValue"
                        [ngClass]="checkClass(detail.value)"
                        *ngIf="
                            detail.key === 'acceptDate' ||
                            detail.key === 'sinceDate' ||
                            detail.key === 'untilDate' ||
                            detail.key === 'denyDate'
                        "
                    >
                        {{ transformDate(detail.value) }}
                    </div>
                </div>
            </ng-container>
        </mat-tab>
        <!-- TABS WITH TABLE -->
        <ng-container *ngFor="let tab of tableTabsSource">
            <mat-tab label="{{ tab.label | translate }}">
                <div class="tableScroll tableTabs">
                    <table class="claimsTable">
                        <thead>
                            <ng-container *ngFor="let column of tab.headers">
                                <th>{{ column.label }}</th>
                            </ng-container>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let register of tab.data">
                                <tr>
                                    <ng-container *ngFor="let cell of register | keyvalue">
                                        <td *ngIf="cell.key !== 'updateDate'" [ngClass]="checkClass(cell.value)">
                                            {{ checkValue(cell.value) }}
                                        </td>
                                        <td *ngIf="cell.key === 'updateDate'" [ngClass]="checkClass(cell.value)">
                                            {{ transformDate(cell.value) }}
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!tab.data.length">
                                <tr>
                                    <ng-container *ngFor="let cell of tab.headers">
                                        <td [ngClass]="checkClass(null)">{{ checkValue(null) }}</td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
    <!-- EDIT CONFIRMATION MODAL -->
    <div *ngIf="isModalOpened" class="cdk-overlay-container">
        <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
        <div class="modal_box cdk-overlay-container">
            <div
                class="cdk-overlay-pane mat-dialog-container ng-tns-c122-25 ng-trigger ng-trigger-dialogContainer ng-star-inserted confirmationModal"
            >
                <div class="modal-title-close">
                    <h1 mat-dialog-title>
                        {{ 'PAGE.MODALS.DATA_MODIFICATION_CONFIRMATION' | translate }}
                    </h1>
                    <button mat-button mat-dialog-close class="hide-access close" (click)="openModal(false)">
                        close
                    </button>
                </div>
                <div mat-dialog-content class="div_buttons">
                    {{ 'PAGE.MODALS.DATA_MODIFICATION_SUCCESFUL' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
