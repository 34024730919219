<div class="container" *ngIf="!isOnlyQueryRole">
    <mat-form-field appearance="standard" class="select">
        <mat-label>{{ 'PAGE.LOADS.SYSTEMS' | translate }}</mat-label>
        <mat-select
            placeholder="{{ 'PAGE.LOADS.SELECT_SYSTEM' | translate }}"
            [value]="selectSystems.length === 1 ? selectSystems[0].name : ''"
        >
            <ng-container *ngIf="selectSystems.length > 1 ? selectSystems[0].name : ''">
                <mat-option [value]="" (click)="onSelectChangeTypes('reset')" id="0">
                    {{ 'PAGE.LOADS.ALL' | translate }}
                </mat-option>
            </ng-container>
            <ng-container *ngFor="let system of selectSystems">
                <mat-option [value]="system.name" (click)="onSelectChangeTypes(system.name)" id="{{ system.id }}">
                    {{ 'PAGE.LOADS.' + system.translation | translate }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <div>
        <mat-table #table [dataSource]="filteredFilesList">
            <ng-container matColumnDef="Sistemas">
                <mat-header-cell *matHeaderCellDef> {{ 'PAGE.LOADS.FILE_TYPE' | translate }} * </mat-header-cell>
                <mat-cell
                    class="cursor_pointer"
                    [class.selected_row]="element.selected"
                    *matCellDef="let element"
                    (click)="onClickSelectFileType(element)"
                >
                    <a> {{ element.description }} </a>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns[0]"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns[0]"></mat-row>
        </mat-table>
    </div>
    <div class="div_buttons">
        <button
            class="margin-r"
            [ngClass]="isDownloadDisabled === true || isRequestPending ? 'mat_none' : 'mat'"
            [disabled]="isDownloadDisabled"
            mat-button
        >
            <span (click)="getDownloadFileUrl(downloadFileUrl)" class="text">
                {{ 'PAGE.ACTIONS.DOWNLOAD_TEMPLATE' | translate }}
            </span>
            <span class="icon material-symbols-outlined"> archive </span>
        </button>
        <button
            [ngClass]="isAddFileButtonDisabled() ? 'mat_none' : 'mat'"
            [disabled]="isAddFileButtonDisabled()"
            mat-button
        >
            <input
                class="style_input_file"
                (click)="disableButtons()"
                (change)="addFile($event)"
                (mouseleave)="enableButtonsAfterAddingFiles()"
                type="file"
                multiple
            />
            <span class="text">{{ 'PAGE.ACTIONS.ADD_FILE' | translate }} </span>
            <span class="icon material-symbols-outlined"> cloud_upload </span>
        </button>
        <div class="uploading-files">
            <ng-container *ngIf="isRequestPending">
                <div class="uploading-files-message">
                    <div class="uploading-files-message__spinner"></div>
                    <div class="uploading-files-message__text">
                        <ng-container *ngIf="isAddingFiles">
                            <span>{{ 'PAGE.LOADS.ADDING_FILES' | translate }}</span>
                        </ng-container>
                        <ng-container *ngIf="!isAddingFiles">
                            <span>{{ 'PAGE.LOADS.UPLOADING_FILES' | translate }}</span>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="filesToUploadList.length > 0">
        <mat-table [dataSource]="filesToUploadList">
            <ng-container matColumnDef="Archivos">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'PAGE.LOADS.FILES' | translate }} ({{ uploadedFilesTotal }}
                    {{ 'PAGE.LOADS.UPLOADED_FILES_MESSAGE' | translate }} {{ filesToUploadTotal }})
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="fileInfo">
                        <div class="fileName">
                            <span
                                class="material-symbols-outlined"
                                matTooltip="{{ element.name }}"
                                matTooltipShowDelay="300"
                            >
                                {{ element.name }}
                            </span>
                        </div>
                        <div class="folderType">../{{ element.description }}</div>
                    </div>
                </mat-cell>
            </ng-container>
            <tbody>
                <div>
                    <ng-container matColumnDef="edit">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'PAGE.LOADS.UPLOAD' | translate }}
                        </mat-header-cell>
                        <mat-cell
                            [ngClass]="
                                !isRequestPending
                                    ? 'cursor_pointer'
                                    : element.uploaded
                                    ? 'icon_color_green'
                                    : 'uploadedFile'
                            "
                            disabled="isRequestPending"
                            (click)="uploadFiles(element)"
                            *matCellDef="let element"
                            id="{{ element.name + element.description }}"
                        >
                            <ng-container *ngIf="element.status === 'NOT_UPLOADED'">
                                <span
                                    class="icon material-symbols-outlined icon_color_gray"
                                    [ngClass]="!isRequestPending ? 'icon-button--hover' : ''"
                                    >cloud_upload</span
                                >
                            </ng-container>
                            <ng-container *ngIf="element.status === 'UPLOADING'">
                                <span class="icon material-symbols-outlined icon_color_gray fade-effect show"
                                    >cloud_sync</span
                                >
                            </ng-container>
                            <ng-container *ngIf="element.status === 'UPLOADED'">
                                <span class="icon material-symbols-outlined icon_color_green">cloud_done</span>
                            </ng-container>
                            <ng-container *ngIf="element.status === 'UPLOAD_ERROR'">
                                <span class="icon material-symbols-outlined icon_color_red">cloud_off</span>
                            </ng-container>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'PAGE.LOADS.DELETE' | translate }}
                        </mat-header-cell>
                        <mat-cell
                            [ngClass]="
                                isAddingFiles || element.status === 'UPLOADING' ? 'uploadedFile' : 'cursor_pointer'
                            "
                            disabled="isAddingFiles || element.status === 'UPLOADING'"
                            (click)="deleteFile(element)"
                            *matCellDef="let element"
                        >
                            <span
                                class="icon material-symbols-outlined icon_color_gray"
                                [ngClass]="isAddingFiles || element.status === 'UPLOADING' ? '' : 'icon-button--hover'"
                                >delete</span
                            >
                        </mat-cell>
                    </ng-container>
                </div>
            </tbody>
            <mat-header-row *matHeaderRowDef="displayedColumns[1]" class="header-row-fixed"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns[1]"></mat-row>
        </mat-table>
        <div class="box-upload">
            <button
                class="mat"
                [ngClass]="!isAnyFileToUpload() || isRequestPending ? 'mat_none' : 'mat'"
                mat-button
                type="button"
                [disabled]="isRequestPending || !isAnyFileToUpload()"
                (click)="uploadFiles()"
            >
                {{ 'PAGE.LOADS.UPLOAD_ALL' | translate }}
            </button>
        </div>
    </div>
</div>
<div *ngIf="isOnlyQueryRole">
    <h3 class="invalidRole">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
</div>
