<div class="container" *ngIf="validRole">
    <app-spinner></app-spinner>
    <div class="filter">
        <mat-form-field appearance="standard">
            <mat-label class="concept">{{ 'PAGE.REPORTS.SYSTEM' | translate }}</mat-label>
            <mat-select #matRefSystem (selectionChange)="getReports($event)">
                <mat-option *ngFor="let system of systemFilter" [value]="system">
                    {{ system.area }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="filter">
        <mat-form-field appearance="standard">
            <mat-label class="concept">{{ 'PAGE.REPORTS.REPORT' | translate }} *</mat-label>
            <mat-select #matRefReport (selectionChange)="getSubfilters($event.value)">
                <mat-option *ngFor="let report of copyReportsFilters" [value]="report">
                    {{ report.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="subfilter" *ngFor="let filtro of subfilters; let i = index">
        <div *ngIf="validRole">
            <app-filtro-date
                [isDisabled]="isDisabledDate"
                *ngIf="filtro.type === 'Date' && isShowSelect"
                [name]="filtro.name"
                [isDateRange]="isDateRange"
                (select)="receiveDate($event, i)"
            ></app-filtro-date>
            <app-filtro-select
                #selects
                *ngIf="
                    filtro.type === 'Select' &&
                    checkConditions(filtro.displayConditions) &&
                    isShowSelect &&
                    filtro.key !== 'programUnit' &&
                    filtro.key !== 'electricSystem' &&
                    filtro.key !== 'rate' &&
                    filtro.key !== 'selfConsumptionType'
                "
                [toggleLabelLeft]="filtro.isToggle?.leftLabel"
                [toggleLabelRight]="filtro.isToggle?.rightLabel"
                [charactersControl]="filtro.isSearchable?.specialSearch"
                (openSelect)="byOpenSelect($event)"
                [searchByToggle]="filtro.isToggle?.active"
                [isSearchable]="filtro.isSearchable?.active"
                (searchBy)="getFiltersBy($event)"
                [filtroUrl]="filtro.data"
                [title]= "filtro.name"
                [key]="filtro.key"
                [id]="filtro.id"
                [disableSelect]="(isDisableSubfilters && filtro.key !== 'accountId' && filtro.key !== 'results')
                || (showSelfConsumption && filtro.key == 'losses')"
                (valueChange)="getOption($event)"
                (specialSearch)="specialSearch($event)"
                (selectSelected)="formatSelectedValue($event, i, filtro.key, filtro.type)"
                [screen]="selectId"
                [theFirstTime]="isFirstTime"
                [placeholderLabel]="filtro?.isSearchable?.specialSearch ? (placeholder$ | async) : filtro.name"
                [specialFeeActivated]="specialFeeActivated"
            ></app-filtro-select>
            <app-filtro-input
                *ngIf="filtro.type === 'Input' && checkConditions(filtro.displayConditions)"
                [name]="filtro.name"
                (select)="restartValue($event, i, filtro.key, filtro.type)"
            >
            </app-filtro-input>
            <app-filtro-cups
                *ngIf="filtro.type === 'cups'"
                [filtroUrl]="filtro.data"
                (select)="restartValue($event, i, filtro.key, filtro.type)"
                (resetComplete)="resetComplete($event)"
                [name]="filtro.name"
                [reset]="resetCups"
            ></app-filtro-cups>
            <ng-container *ngIf="filtro.type === 'MultiSelect' 
                && (filtro.key === 'liquidation' || filtro.key === 'code' )
                && checkConditions(filtro.displayConditions) 
                && isShowSelect">
                <app-filtro-multiselect
                    [filtroUrl]="filtro.data"
                    [title]="filtro.name"
                    [key]="filtro.key"
                    (valueChange)="filterContract($event)"
                ></app-filtro-multiselect>
            </ng-container>

            <div *ngIf="filtro.type === 'Select' && isShowSelect && filtro.key === 'electricSystem'">
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.REPORTS.ELECTRIC_SYSTEM' | translate }}</mat-label>
                    <mat-select
                        #selectElectricSystem
                        [disabled]="toggleActive || isDisableSubfilters"
                        (selectionChange)="getSelectedOptionElectrictSystem($event)"
                    >
                        <mat-option
                            *ngFor="let option of electricSystemList"
                            [value]="option"
                            (click)="filterUnitProgram(option)"
                        >
                            {{ option }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="filtro.type === 'Select' && isShowSelect && filtro.key === 'programUnit'">
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.REPORTS.PROGRAM_UNIT' | translate }}</mat-label>
                    <mat-select
                        [formControl]="unitPrograms"
                        [disabled]="!electrictSystemSelected || isDisableSubfilters"
                        (selectionChange)="getUnitsPrograms($event)"
                        multiple
                    >
                        <mat-option *ngFor="let option of unitProgramList" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="filtro.type === 'Select' && isShowSelect && filtro.key === 'rate'">
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.REPORTS.RATE_CODE' | translate }}</mat-label>
                    <mat-select
                        (click)="(rateList)"
                        [disabled]="isDisableSubfilters"
                        (selectionChange)="getRates($event); formatSelectedValue($event, i, filtro.key, filtro.type)"
                        multiple
                    >
                        <mat-option *ngFor="let option of rateList" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="filtro.type === 'Select' && isShowSelect && filtro.key === 'selfConsumptionType' && showSelfConsumption">
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.MODALS.CONTRACT_DETAILS.SELF_SUPPLY_TYPE' | translate }}</mat-label>
                    <mat-select
                        (click)="(selfConsumptionList)"
                        [disabled]="isDisableSubfilters"
                        (selectionChange)="getSelfsupply($event); formatSelectedValue($event, i, filtro.key, filtro.type)"
                    >
                        <mat-option *ngFor="let option of selfConsumptionList" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div [ngClass]="{ months: monthSelected === false, monthsSelected: monthSelected === true }">
                <div *ngIf="filtro.type === 'SelectMonth'" [formGroup]="formGroup">
                    <mat-form-field appearance="fill">
                        <mat-label>{{
                            filtro.required ? filtro.name : ('PAGE.FILTERS.SELECT.MONTH' | translate)
                        }}</mat-label>
                        <mat-select
                            #matSelectMonth
                            formControlName="month"
                            (selectionChange)="formatSelectedValue($event, 0, filtro.key, filtro.type)"
                        >
                            <mat-option *ngFor="let month of months" [value]="month" [disabled]="isDisabledYear">
                                {{ monthDisplay(month) | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div [ngClass]="{ year: yearSelected === false, yearsSelected: yearSelected === true }">
                <div *ngIf="filtro.type === 'SelectYear'" [formGroup]="formGroup">
                    <mat-form-field appearance="fill">
                        <mat-label>{{
                            filtro.required ? filtro.name : ('PAGE.FILTERS.SELECT.YEAR' | translate)
                        }}</mat-label>
                        <mat-select
                            formControlName="year"
                            (selectionChange)="formatSelectedValue($event, 1, filtro.key, filtro.type)"
                        >
                            <mat-option *ngFor="let year of years" [value]="year" [disabled]="isDisabledYear">
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <!-- PANTALLA QUE SE MUESTRA SI USUARIO NO TIENE ROLES ADECUADOS -->
    <hr />
    <div *ngIf="messageClarifyRequiredFilters() === true && !isAnalysisResults" class="clarifyingMessage">
        <h3 class="clarifyingMessageH3">{{ 'PAGE.REPORTS.MESSAGE_YOU_CAN_ONLY_SELECT_ONE_CUPS' | translate }}</h3>
    </div>
    <div
        *ngIf="messageClarifyRequiredFilters() === true && isAnalysisResults"
        class="clarifyingMessageReportAnalysisResults"
    >
        <h3 class="clarifyingMessageH3">
            {{ 'PAGE.REPORTS.MESSAGE_FILTERS_ARE_DISABLE_IF_NEGOTIATIONG_ACCOUNT_IS_SELECTED' | translate }}
        </h3>
    </div>
</div>
<div *ngIf="!validRole">
    <h3 class="invalidRole">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
</div>
<div>
    <div *ngIf="isModalOpened" class="cdk-overlay-container">
        <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>

        <div class="modal_box cdk-overlay-container">
            <div
                style="
                    max-width: 50vw;
                    position: static;
                    padding: 24px;
                    height: initial;
                    margin: auto;
                    margin-top: 30vh;
                "
                class="cdk-overlay-pane"
                class="mat-dialog-container ng-tns-c122-25 ng-trigger ng-trigger-dialogContainer ng-star-inserted"
            >
                <div class="modal-title-close">
                    <h1 mat-dialog-title>
                        {{ 'PAGE.ACTIONS.ERROR' | translate }}
                    </h1>
                    <button mat-button mat-dialog-close class="hide-access close" (click)="closeModal()">close</button>
                </div>

                <div mat-dialog-content class="div_buttons">
                    {{ 'PAGE.FILTERS.SELECT.ONLY_ONE_CUPS_ALLOWED' | translate }}
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="showRequiredFiltersInfoMessageCUPS || showRequiredFiltersInfoMessageNegotiatingAccountOrCUPS">
        <div class="filters-required">
            <div class="clarifyingMessage">
                <h3 class="clarifyingMessageH3">
                    <ng-container *ngIf="showRequiredFiltersInfoMessageCUPS">
                        {{ 'PAGE.FILTERS.SELECT.REQUIRED_FILTERS_INFO_MESSAGE_CUPS' | translate }}
                    </ng-container>
                    <ng-container *ngIf="showRequiredFiltersInfoMessageNegotiatingAccountOrCUPS">
                        {{
                            'PAGE.FILTERS.SELECT.REQUIRED_FILTERS_INFO_MESSAGE_NEGOTIATING_ACCOUNT_OR_CUPS' | translate
                        }}
                    </ng-container>
                </h3>
            </div>
        </div>
    </ng-container>
    <div class="buttonsFilter">
        <button class="delete" (click)="clearFilters()" (mouseup)="hideSelect()" mat-button>
            {{ 'PAGE.ACTIONS.DELETE' | translate }} <app-base-icon iconName="filter_alt_off" class=""></app-base-icon>
        </button>
        <button
            class="search"
            [disabled]="isDisabled"
            (click)="loadTable()"
            mat-flat-button
            color="warn"
            [ngStyle]="{ 'text-transform': 'uppercase' }"
        >
            {{ 'PAGE.ACTIONS.EXECUTION' | translate | uppercase }}
            <app-base-icon iconName="settings" class=""></app-base-icon>
        </button>
    </div>
</div>
