import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { CargasPage } from 'src/app/shared/models/cargas-page.data';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { CargasService } from '../services/cargas.service';

import { combineLatest, Subject } from 'rxjs';
import { Filtro } from 'src/app/shared/models/filtros.interface';
import { environment } from 'src/environments/environment';
import { CargasRES, PaginationIDs, SortBy } from '../../../shared/enums/cargas';
import { PaginationObject } from 'src/app/shared/models/paginationObject.interface';
import { SortingObject } from 'src/app/shared/models/files.interface';
import { Permisos } from 'src/app/shared/models/permisos.interface';
import { HeaderTitleService } from '../../../services/header-title.service';

@Component({
    selector: 'app-cargas-resultado',
    templateUrl: './cargas-resultado.component.html',
    styleUrls: ['./cargas-resultado.component.scss'],
})
export class CargasResultadoComponent implements OnInit {
    // transformHeaders
    columns: any;

    // getInfoTabla
    permissions: Permisos;
    idFilters: any;

    dataBusiness: any;
    dataHeaders: IColumnsTable[];
    totalRegisters: number;
    mock: string;

    // receiveIdSelect
    filtersTable: any[]; // nombre de la tabla seleccionada en el concepto

    // changeFiltersInTable
    filters: any;

    dataPagination: PaginationObject;
    dataSorting: SortingObject;
    idToCompare: string | [];
    pageSize: number;
    totalPages: number;
    numPage: number;
    pageIndex: any;
    sortActive: any;
    sortDir: any;
    subfilters: Filtro[] = [];
    resetTableSubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private _cargasService: CargasService,
        private globalService: GlobalService,
        private _headerTitleService: HeaderTitleService
    ) {}

    ngOnInit(): void {
        if (CargasPage.subItems.length === 1) {
            this._headerTitleService.currentHeaderTranslationCode = 'MANUAL_LOADS_TITLE';
        } else {
            this._headerTitleService.currentHeaderTranslationCode = 'LOADS_RESULTS_TITLE';
        }
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
    }

    formatSearch(idFiltersEvent: any): string {
        let params: string = '';
        let addParamFlag: boolean = false;
        if (idFiltersEvent[1]) {
            idFiltersEvent[1].forEach((filter: any, index: number) => {
                addParamFlag = false;
                if (filter.selected && filter.key === 'area' && !addParamFlag && filter.selected !== 'reset') {
                    params = params.concat(`${filter.key}=${filter.selected},`);
                    addParamFlag = true;
                }
                if (filter.selected && filter.key === 'status' && !addParamFlag) {
                    if (index !== 0 && params !== '' && !params.endsWith(',')) {
                        params = params.concat(',');
                    }
                    params = params.concat(`${filter.key}=${filter.selected}`);
                    addParamFlag = true;
                }
                if (filter.selected && filter.key === 'file' && !addParamFlag) {
                    // dividimos el file y el origin
                    let splitedFiles = filter.selected.split('=');
                    params = params.concat(`file=${splitedFiles[0]},origin=${splitedFiles.at(-1)}`);
                    addParamFlag = true;
                }
                if (filter.type === 'Date' && filter.selected && !addParamFlag) {
                    let splitedDates = idFiltersEvent[1][1].selected.split('~');
                    params = params.concat(`initDate>=${splitedDates[0]},initDate<=${splitedDates.at(-1)}`);
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    addParamFlag = true;
                } else {
                    if (filter.selected && filter.type !== 'Date' && !addParamFlag && filter.selected !== 'reset') {
                        params = params.concat(`${filter.key}=${filter.selected}`);
                        addParamFlag = true;
                    }
                }
            });
        }
        if (params.includes(',,')) {
            params = params.replace(/,,/g, ',');
        }
        return params;
    }

    transformHeaders() {
        this.columns = this.dataHeaders.map((col) => col.id);
    }

    private getInfoTabla(idFilters: any, filter: boolean) {
        this.permissions = this.globalService.transformPermissions(idFilters[0]);
        this.idFilters = idFilters;
        if (idFilters[0] === CargasRES.RESULTS) {
            this._cargasService.searchParam = this.formatSearch(idFilters);
            combineLatest([
                this._cargasService.getTablaResultados(1, 10, SortBy.ID, 'asc', this.formatSearch(idFilters)),
                this._cargasService.getHeaders(),
            ]).subscribe((res: any) => {
                this.buildTable(res, 'cargas_resultados');
            });
        }
    }

    buildTable(res: any, nameHeaders: string): void {
        if (environment.isMockActive) {
            this.dataBusiness = res[0].data;
            res[1].data.forEach((element: any) => {
                if (element.name === nameHeaders) {
                    this.dataHeaders = element.headers;
                }
            });
            this.totalRegisters = res[1].totalRegisters;
        } else {
            this.dataBusiness = res[0].data;
            res[1].data.forEach((element: any) => {
                if (element.name === nameHeaders) {
                    this.dataHeaders = element.headers;
                }
            });
            this.totalRegisters = res[0].page.totalElements;
            this.numPage = res[0].page.number;
            this.pageSize = res[0].page.size;
            this.totalPages = res[0].page.totalPages;
            this.resetTableSubject.next(true);
        }
        this.transformHeaders();
    }

    receiveIdSelect($event: any) {
        let filter: boolean = false;
        for (let index = 0; index < $event[1].length; index++) {
            if ($event[1][index].selected) {
                filter = true;
            }
        }
        this.filtersTable = $event;

        this.getInfoTabla($event, filter);
    }

    clearTable($event: any) {
        this.dataBusiness = undefined;
    }

    receiveIdFilters($event: any) {
        this.getInfoTablaByFilterSort($event);
    }

    getInfoTablaByFilterSort(s: any) {
        //TODO: remove this method when Sonar checks
    }

    reset() {}

    sort(event: any, id: string) {
        this.sortDir = event.direction;
        this.sortActive = event.active;
    }

    pagination(event: any) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        // Filtrar por ...
        if (!this.sortActive && this.idFilters[0] === PaginationIDs.RESULTS) {
            this.sortActive = SortBy.ID;
        }

        // Ordenar por ASC o DESC
        if (!this.sortDir) {
            this.sortDir = 'asc';
        }

        if (this.idFilters[0] === PaginationIDs.RESULTS) {
            this._cargasService
                .getTablaResultados(
                    event.pageIndex + 1,
                    event.pageSize,
                    this.sortActive,
                    this.sortDir,
                    this._cargasService.searchParam
                )
                .subscribe((res: any) => {
                    this.dataBusiness = res.data;
                    this.totalRegisters = res.page.totalElements;
                    this.numPage = res.page.number;
                    this.pageSize = res.page.size;
                    this.totalPages = res.page.totalPages;
                });
        }
    }

    chargeFiltersInTable($event: any) {
        this.filters = $event;
    }
}
