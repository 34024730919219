<div class="container" style="justify-content: flex-start">
    <div class="subfilter" *ngFor="let filtro of subfilters; let i = index">
        <!-- Mes -->
        <app-filtro-month
            *ngIf="filtro.type === 'Month' && showSelect"
            [title]="filtro.name"
            [value]=""
            [id]="filtro.id"
            (valueChange)="onChangeSaveSelectedOptionAndCheckOptions($event)"
        ></app-filtro-month>
        <!-- Año -->
        <app-filtro-year
            *ngIf="filtro.type === 'Year' && showSelect"
            [title]="filtro.name"
            [value]=""
            [id]="filtro.id"
            (defaultValue)="(defaultYearValue)"
            (valueChange)="onChangeSaveSelectedOptionAndCheckOptions($event)"
        ></app-filtro-year>
        <!-- CUPS -->
        <app-filtro-cups
            *ngIf="filtro.type === 'cups' && showSelect"
            [filtroUrl]="filtro.data"
            [name]="filtro.name"
            [reset]="resetCups"
            (select)="onChangeSaveSelectedOptionAndCheckOptions($event)"
        ></app-filtro-cups>
        <!-- Cuenta negociadora, tipo de negocio y discrepancia de medida -->
        <app-filtro-select
            *ngIf="filtro.type === 'Select' && showSelect && filtro.name != 'Nombre CUP'"
            [toggleLabelLeft]="filtro.isToggle?.leftLabel"
            [toggleLabelRight]="filtro.isToggle?.rightLabel"
            [charactersControl]="filtro.isSearchable?.specialSearch"
            [searchByToggle]="filtro.isToggle?.active"
            [isSearchable]="filtro.isSearchable?.active"
            [filtroUrl]="filtro.data"
            [title]="filtro.name"
            [key]="filtro.key"
            [id]="filtro.id"
            [value]="filtro.key === 'clientType' ? filtro.data[0] : ''"
            (valueChange)="onChangeSaveSelectedOptionAndCheckOptions($event)"
            (specialSearch)="launchNegotiatingAccountSpecialSearch($event)"
        ></app-filtro-select>
        <!-- Instalaciones -->
        <app-filtro-select
            *ngIf="filtro.type === 'Select' && showSelect && filtro.name === 'Nombre CUP'"
            [charactersControl]="filtro.isSearchable?.specialSearch"
            [isSearchable]="filtro.isSearchable?.active"
            [filtroUrl]="filtro.data"
            [title]="'PAGE.DASHBOARD.INSTALLATIONS' | translate"
            [key]="filtro.key"
            [id]="filtro.id"
            [placeholderLabel]="filtro?.isSearchable?.specialSearch ? (placeholder$ | async) : filtro.name"
            (valueChange)="onChangeSaveSelectedOptionAndCheckOptions($event)"
            (specialSearch)="launchInstallationsSpecialSearch($event)"
        ></app-filtro-select>
    </div>
</div>
<div class="buttonsFilter">
    <div class="buttonsActionsDisplay"></div>
    <div class="buttonsFilterNoMargin">
        <button class="delete" (click)="onClickResetFilters()" (mouseup)="onMouseUpHideSelect()" mat-button>
            {{ 'PAGE.ACTIONS.DELETE' | translate }}
            <app-base-icon iconName="filter_alt_off" class=""></app-base-icon>
        </button>
        <button class="search" [disabled]="isFilterDisabled" (click)="onClickLoadTable()" mat-flat-button color="warn">
            {{ 'PAGE.ACTIONS.FILTER' | translate | uppercase }}
            <app-base-icon iconName="search"></app-base-icon>
        </button>
        <button class="search" [disabled]="isFilterDisabled" (click)="onClickLaunchReport()" mat-flat-button color="warn">
            {{ 'PAGE.ACTIONS.EXPORT_REPORT' | translate | uppercase }}
            <app-base-icon iconName="search"></app-base-icon>
        </button>
    </div>
</div>
<div class="buttonsColumnsDisplay" *ngIf="showColumns">
    <div *ngFor="let button of buttonsColumns">
        <button
            mat-button
            class="buttonsColumns"
            [ngClass]="button.isEnabled === true ? 'buttonsColumnsSelected' : 'buttonsColumns'"
            (click)="onClickChangeDisplayColumn(button.id)"
        >
            {{ button.name }}
        </button>
    </div>
</div>
