export interface ListaFiltros {
    name: ConceptCategory;
    filters: Filtro[];
}

export interface Filtro {
    id: string;
    name: string;
    type: string;
    isSearchable?: SearchSelect;
    isToggle?: ToggleSelect;
    key: string;
    toggleState?: boolean;
    required: boolean;
    displayConditions?: string[];
    url: string;
    data: FiltroSelect[];
    selected?: string | number | boolean;
    filters?: any;
}
export interface FiltroSelect {
    name: string;
    value?: string | number;
    code?: string;
    surnames?: string;
    username?: string;
    processCode?: any;
    launchId?: number;
}

export interface SearchSelect {
    active: boolean;
    specialSearch: boolean;
}

export interface ToggleSelect {
    active: true;
    leftLabel: string;
    rightLabel: string;
}

export enum ConceptCategory {
    TARIFAS = 'maestro_tarifas',
    FESTIVOS = 'maestro_festivos',
    PRECIOS_REGULADOS = 'maestro_preciosRegulados',
    CUADRO_MANDOS = 'cuadroMandos',
}

export interface FilterBackResponse {
    data: ListaFiltros[];
}
