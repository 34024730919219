import { ItemMenu } from '../interfaces/itemMenu.interface';
import { CargasPage } from './cargas-page.data';
import { InformesPage } from './informes-page.data';
import { MaestroPage } from './maestro-page.data';
import { MedidasPage } from './medidas-page.data';
import { ProcesosPage } from './procesos-page.data';
import { PrevisionesPage } from './previsiones-page.data';
import { EnviosPage } from './envios-page-data';

export const MenuItems: ItemMenu[] = [
    MaestroPage,
    CargasPage,
    ProcesosPage,
    InformesPage,
    MedidasPage,
    PrevisionesPage,
    EnviosPage,
];
