export enum SistemasCargasEnum {
    MEDIDA = 'Medida',
    CIERRE = 'Cierre',
    PREVISION = 'Prevision',
    CONCILIACION = 'Conciliacion',
    COMUN = 'Comun',
}

export enum TipoCargas {
    RESULTS = 'resultados',
    LAUNCH = 'manual',
}

export enum CargasRES {
    RESULTS = 'resultados',
}

export enum OriginEnum {
    ATR = 'ATR',
    DISTRIBUTOR = 'Distribuidor',
    ESIOS = 'ESIOS',
    HUB = 'Concentrador',
    MANLOAD = 'Error',
    MANUAL = 'Origen Manual',
    METEOLOGICA = 'Origen Meteológica',
    REE = 'Red Eléctrica de España',
}

export enum StatusEnum {
    CUPS_NOT_EXISTS = 'El CUPS no existe',
    FILE_ALREADY_PROCESSED = 'Fichero previamente procesado',
    IN_PROCESS = 'En proceso',
    COMPLETED = 'Completado correctamente',
    ERROR = 'Error',
    OK = 'Ok',
    ERROR_TRATADO = 'Error tratado',
    ERROR_SOLVED = 'Error solventado',
    COMPLETED_WITH_ERRORS = 'Completado con errores',
    SCHEMA_ATR_INVALID = 'Esquema ATR inválido',
    ATR_ENERGY_PERIOD_ERROR = 'Periodos de energía erróneos',
    ATR_POWER_PERIOD_ERROR = 'Periodos de potencia erróneos',
    ATR_CONTRACT_CONCEPTS_ERROR = 'Contrato o conceptos de contrato erróneos',
    ATR_POWER_COMPARISON_ERROR = 'La potencia ATR no coincide con la contratada',
    ATR_INVOICE_FISCAL_CODE_DUPLICATED_ERROR = 'Código fiscal de la factura ATR duplicado'
}

export enum FileEnum {
    DIS_F1 = 'Medida distribuidor F1',
    DIS_F5D = 'Medida distribuidor F5D',
    DIS_P1 = 'Medida distribuidor P1',
    DIS_P2 = 'Medida distribuidor P2',
    REE_F5D = 'Medida red eléctrica F5D',
    REE_EPFPH = 'Medida red eléctrica EPFPH',
    M_REE_F5D = 'Medida REE F5D',
    M_REE_EPFPH = 'Medida REE EPFPH',
    CIER_MANUAL = 'Cierre manual',
    CIER_DIST = 'Medida distribuidor cierre',
    CIER_CONC = 'Medida concentrador cierre',
    ACUM = 'ACUM',
    INMECLOS = 'INMECLOS',
    DIS_RF5D = 'Medida distribuidor RF5D',
    CONC_P1 = 'Medida concentrador P1',
    CONC_P2 = 'Medida concentrador P2',
    PREV_CLIENTES = 'Previsión clientes',
    PREV_MET = 'Previsión meteológica',
    PREV_MET_AGR = 'Previsión meteológica agregada',
    ATR = 'Factura ATR',
    RT = 'Consumption RT',
    LIQ_POS = 'Liquidación potestativa',
}

export enum FiltrosConceptosContrato {}

export enum FiltroSelectConceptoContrato {
    LIQUIDACION = 'Liquidación',
}

export enum FiltrosContrato {
    DESCRIPCION = 'Descripción',
}

export enum TypicalEnumToUse {
    CODE = 'Código',
}

export enum PaginationIDs {
    RESULTS = 'resultados',
}

export enum SortBy {
    DATE = 'initDate',
    STATE = 'status',
    ID = 'id',
}

export enum keysCargas {
    CUENTA_NEGOCIADORA = 'negotiatingAccountCode',
    SOCIEDADES = 'idNumber',
    CONTRACT_STATE = 'status',
    CUENTA_NECOGIADORA_ID = 'accountId',
    RESULTS = 'id',
    STATUS = 'status',
    FILE = 'file',
}
