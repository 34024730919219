import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { CargasRES, keysCargas, SistemasCargasEnum, StatusEnum } from 'src/app/shared/enums/cargas';
import { Concepto } from 'src/app/shared/models/concepto.interface';
import { ListaFiltros, Filtro, FiltroSelect } from 'src/app/shared/models/filtros.interface';
import { CargasService } from '../../services/cargas.service';
import { CargasServiceService } from '../../services/cargas-service.service';
import { FileName, Files, SistemaCargasOption } from 'src/app/shared/interfaces/cargas.interface';
import { LoginService } from 'src/app/core/services/login.service';
import { MatSelect } from '@angular/material/select';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-select-cargas-res',
    templateUrl: './select-cargas-res.component.html',
    styleUrls: ['./select-cargas-res.component.scss'],
})
export class SelectCargasResComponent implements OnInit {
    @ViewChild('matRefFileType') matRefFileType: MatSelect;
    @ViewChild('matRefSystem') matRefSystem: MatSelect;

    @Input() tipoConcepto: string;

    @Output() OptionSelectedId = new EventEmitter<string>();
    @Output() OptionFilters = new EventEmitter<any>();
    @Output() changeSelectReset = new EventEmitter<any>();
    @Output() resetTable = new EventEmitter<string>();

    displayedSubfilters: Filtro[] = [];
    subfilters: Filtro[] | undefined;
    selectSubfilter: FiltroSelect[];
    conceptosCargas: Concepto[] = [];
    filters: ListaFiltros[] = [];
    disableSelect = new UntypedFormControl(false);
    subcriptions: Subscription[] = [];
    tablaSeleccionada: string | undefined;
    selectId: any = 'resultados';
    selectedSystem: string;
    isDisabled: boolean = true;
    inputValues: { id: string; value: string }[] = [];
    resetSelectedOption: any;
    resetSubfilter: any;
    showinput: any;
    showSelect = true;
    intermedio = true;

    dataBusinessMedidas: any[];
    dataBusinessPrevisiones: any[];
    dataBusinessCierre: any[];
    dataBusinessSelectSistema: any[];
    cargas_rol: any[];
    nameSistems: any;
    archivos: any[];
    filesName: any[];
    filesName2: any[];
    disabled = false;

    selectedOption: any = { id: 'cargas_resultados', name: 'Resultados', filtrosCategory: 'cargas_resultados' };
    selected2 = this.conceptosCargas[0];

    searchBy: boolean = false;
    disableToggle: boolean = true;
    fileDataList: any[];
    filesTypeList: any[];
    filesNameList: FileName[];
    filesList: any[];
    filteredFilesList: any[];
    userLogRoles: any;

    displayedColumns: string[] = [];
    rolPlaceholder: any;
    emitSystemSelected: any;

    userRoles: string[] = [];
    selectSystems: SistemaCargasOption[] = [];

    constructor(
        private cargasService: CargasService,
        protected readonly _headerTitleService: HeaderTitleService,
        private globalService: GlobalService,
        private _loginService: LoginService
    ) {}

    ngOnInit(): void {
        this.getFilters(this.cargasService.searchBy);

        this.cargasService.getCargas().subscribe((filesResponse: Files[]) => {
            //filesResponse = this.cargasServiceService.formatResponse(filesResponse);
            this.filesList = filesResponse;
            this.filteredFilesList = this.filesList;
            this._setVariablesStartingValues();
        });
    }

    private _setVariablesStartingValues(): void {
        this.selectSystems = [
            {
                id: 1,
                name: SistemasCargasEnum.MEDIDA,
                translation: 'MEASURES',
            },
            {
                id: 2,
                name: SistemasCargasEnum.CIERRE,
                translation: 'CLOUSURES',
            },
            {
                id: 3,
                name: SistemasCargasEnum.PREVISION,
                translation: 'FORECAST',
            },
            {
                id: 4,
                name: SistemasCargasEnum.COMUN,
                translation: 'COMMON',
            },
            {
                id: 5,
                name: SistemasCargasEnum.CONCILIACION,
                translation: 'CONCILIATION',
            },
        ];
        this.matRefSystem.value = undefined;
        this.matRefFileType.value = undefined;
        this.displayedColumns = ['Sistemas'];
        this.fileDataList = [];
        this.filesTypeList = [];
        this.filesNameList = [];
        this.isDisabled = true;
        this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
        this.userRoles = this.userRoles.map((element: any) => element.trim());
    }

    getFilters(filterByBoolean: boolean) {
        this.subcriptions.push(
            this.globalService.getAllFilters(filterByBoolean).subscribe((filtro: any) => {
                let nameFilter: string = '';
                if (filtro['data']) {
                    // The first time the filters load, they do it inside filtro['data'], this IF is to avoid having to reload the page.
                    filtro['data'].every((firstLoadFilter: any) => {
                        nameFilter = firstLoadFilter.name;
                        if (nameFilter == 'cargas_resultados') {
                            this.subfilters = firstLoadFilter.filters;
                            return false;
                        }
                        return true;
                    });
                } else {
                    nameFilter = filtro.name;
                    if (nameFilter == 'cargas_resultados') {
                        this.subfilters = filtro.filters;
                    }
                }
            })
        );
    }

    onSelectChangeTypes(selectedType: string) {
        if (this.selectedSystem !== selectedType) {
            this.selectedSystem = selectedType;
            this.subfilters?.map((subfilter: any) => {
                if (subfilter.key === 'area' && this.selectId === 'resultados') {
                    subfilter.selected = this.selectedSystem;
                }
                if (subfilter.key === 'file' && this.selectId === 'resultados') {
                    subfilter.selected = undefined;
                }
            });
            if (selectedType === 'reset') {
                this.filteredFilesList = this.filesList;
            }
            if (selectedType === SistemasCargasEnum.MEDIDA) {
                this.filteredFilesList = this.filesList.filter((file) => file.area === SistemasCargasEnum.MEDIDA);
            }
            if (selectedType === SistemasCargasEnum.CIERRE) {
                this.filteredFilesList = this.filesList.filter((file) => file.area === SistemasCargasEnum.CIERRE);
            }
            if (selectedType === SistemasCargasEnum.PREVISION) {
                this.filteredFilesList = this.filesList.filter((file) => file.area === SistemasCargasEnum.PREVISION);
            }
            if (selectedType === SistemasCargasEnum.COMUN) {
                this.filteredFilesList = this.filesList.filter((file) => file.area === SistemasCargasEnum.COMUN);
            }
            if (selectedType === SistemasCargasEnum.CONCILIACION) {
                this.filteredFilesList = this.filesList.filter((file) => file.area === SistemasCargasEnum.CONCILIACION);
            }
            this.matRefFileType.value = undefined;
        }
    }

    ngOnDestroy(): void {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.subcriptions.forEach((sub) => {
            sub.unsubscribe();
        });
        this.subfilters?.map((subfilter: Filtro) => {
            if (subfilter.selected) {
                subfilter.selected = undefined;
            }
            return subfilter;
        });
    }

    byOpenSelect(event: any) {
        if (event.newValue === true) {
            if (event.key === keysCargas.RESULTS) {
                let results: any = [];
                this.cargasService.getSelects().subscribe((result) => {
                    result.data.forEach((element: any) => {
                        if (this.searchBy) {
                            results.push({ name: element.name });
                        } else {
                            results.push({ name: element.id });
                        }
                    });

                    // Guardo en los subsfilters de sociedades lo de la petición
                    this.subfilters?.forEach((subfilter) => {
                        if (subfilter.key === keysCargas.RESULTS) {
                            subfilter.data = results;
                        }
                    });
                });
            }
        }
    }

    /**
     * Este evento tiene 2 usos:
     * Si selectId === Contratos -> Método que comprueba los select de Tarifas y Zona para deshabilitar las opciones de Zona según hemos seleccionado la tarifa especial de 2.0TD o no.
     * Si selectId === Contratos -> Método que comprueba si hay algo seleccionado
     * @param selectedOption Valor seleccionado que viene emitido del select
     */
    checkValueSelects(selectedOption: any) {
        if (this.selectId === CargasRES.RESULTS) {
            this.getFilters(this.cargasService.searchBy);
        } else {
            this.cargasService.showColumnsBy = '';
        }
    }

    isDisableButtonRequired() {
        let countRequired = 0;
        let countSelected = 0;
        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element) => {
                if (element.required) {
                    countRequired++;
                    if (element.selected) {
                        countSelected++;
                    }
                }
            });
        }
        if (countRequired == 0) {
            this.isDisabled = false;
        } else {
            if (countRequired === countSelected) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        }
    }

    loadTable() {
        let selectFilter: any = [this.selectId, this.subfilters];
        if (this.subfilters !== undefined) {
            this.OptionSelectedId.emit(selectFilter);
        } else {
            this.OptionSelectedId.emit(this.selectId);
        }
    }

    checkConditions(filtro: string[] | undefined): boolean {
        let isConditionOk = false;
        if (this.showinput === false) {
            isConditionOk = true;
        }
        if (!filtro || !filtro.length) {
            isConditionOk = true;
        }
        return isConditionOk;
    }

    receiveDate($event: any, filtro: any, key: string, reset?: boolean) {
        if ($event === 'Origen *=Propio') {
            this.showinput = true;
        }
        if ($event === 'Origen *=REE final' || $event === 'Origen *=REE inicial') {
            this.showinput = false;
            this.checkConditions(undefined);
        }
        if (this.subfilters !== undefined) {
            this.subfilters[filtro].selected = $event;
        }
        this.isDisableButtonRequired();
    }

    restarValue(event: any, filtro: any, key: string) {
        this.formatSelectedValue(event, filtro, key);
        if (this.subfilters !== undefined && event.newValue) {
            this.subfilters[filtro].selected = event.newValue;
        }
        this.intermedio = true;
    }

    restarValueFileFilter(event: any, filtro: any, key: string) {
        // pillamos el valor del filtro
        let y = this.filteredFilesList.filter((x) => x.id === event['value']);
        // creamos un string que tenga el valor de file' y el valor de 'origin' de la entrada de 'Tipo' correspondiente
        // y las separamos con un '='
        let valor: string = y[0]['file'] + '=' + y[0]['origin'];
        this.formatSelectedValue(valor, filtro, key);
        if (this.subfilters !== undefined && event.newValue) {
            this.subfilters[filtro].selected = event.newValue;
        }
        this.intermedio = true;
    }
    /**
     *
     * @param event Dato seleccionado
     * @param filtro El número del select de filtros
     * @param key Key del filtro
     */
    formatSelectedValue(event: any, filtro: any, key: string) {
        if (this.selectId === CargasRES.RESULTS) {
            if (filtro === 3 && this.subfilters) {
                let filtroFormateado = event.split('=');
                if (key === keysCargas.STATUS) {
                    switch (filtroFormateado[1]) {
                        case StatusEnum.CUPS_NOT_EXISTS:
                            this.subfilters[filtro].selected = 'ATR_CUPS_ERROR';
                            break;
                        case StatusEnum.FILE_ALREADY_PROCESSED:
                            this.subfilters[filtro].selected = 'FILE_ALREADY_PROCESSED';
                            break;
                        case StatusEnum.IN_PROCESS:
                            this.subfilters[filtro].selected =
                                '(INIT_CATALOG;INIT_PROCESS;OK_CATALOG;OK_CATALOG_WITH_ERRORS)';
                            break;
                        case StatusEnum.COMPLETED:
                            this.subfilters[filtro].selected = 'OK_PROCESS';
                            break;
                        case StatusEnum.ERROR:
                            this.subfilters[filtro].selected = '(KO_CATALOG_SYSTEM;KO_PROCESS_SYSTEM)';
                            break;
                        case StatusEnum.ERROR_SOLVED:
                            this.subfilters[filtro].selected = 'KO_SOLVED';
                            break;
                        case StatusEnum.COMPLETED_WITH_ERRORS:
                            this.subfilters[filtro].selected = 'OK_PROCESS_WITH_ERRORS';
                            break;
                        case StatusEnum.SCHEMA_ATR_INVALID:
                            this.subfilters[filtro].selected = 'SCHEMA_ATR_INVALID';
                            break;
                        case StatusEnum.ATR_ENERGY_PERIOD_ERROR:
                            this.subfilters[filtro].selected = 'ATR_ENERGY_PERIOD_ERROR';
                            break;
                        case StatusEnum.ATR_POWER_PERIOD_ERROR:
                            this.subfilters[filtro].selected = 'ATR_POWER_PERIOD_ERROR';
                            break;
                        case StatusEnum.ATR_CONTRACT_CONCEPTS_ERROR:
                            this.subfilters[filtro].selected = 'ATR_CONTRACT_CONCEPTS_ERROR';
                            break;
                        case StatusEnum.ATR_POWER_COMPARISON_ERROR:
                            this.subfilters[filtro].selected = 'ATR_POWER_COMPARISON_ERROR';
                            break;
                        case StatusEnum.ATR_INVOICE_FISCAL_CODE_DUPLICATED_ERROR:
                            this.subfilters[filtro].selected = 'ATR_INVOICE_FISCAL_CODE_DUPLICATED_ERROR';
                            break;
                        default:
                            break;
                    }
                } else {
                    this.subfilters[filtro].selected = filtroFormateado[1];
                }
            }
            if (filtro === 2 && this.subfilters) {
                if (key === keysCargas.FILE) {
                    this.subfilters[filtro].selected = event;
                }
            }
        }
    }

    clearFilters() {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.showSelect = true;
        this.disableToggle = true;
        this.resetTable.emit();
        if (this.subfilters !== undefined) {
            this.subfilters.forEach((subfilter) => {
                if (subfilter.selected) subfilter.selected = undefined;
            });
        }
        this.onSelectChangeTypes('reset');
        this.matRefSystem.value = undefined;
        this.matRefFileType.value = undefined;
    }

    hideSelect() {
        this.showSelect = false;
    }

    reset() {
        if (this.subfilters != undefined) {
            this.subfilters.forEach(function (filter) {
                delete filter['selected'];
            });
        }
    }
}
