export interface IResponseClaimsOrObjections {
    data: IClaims[] | IObjections[];
    page: {
        number: number;
        size: number;
        totalElements: number;
        totalPages: number;
    };
}

export interface IClaims {
    acceptDate: string | null;
    attackExpNumber: string | null;
    claimsCommunicationParameters: ICommunicationParameters[];
    claimsDocument: IClaimsAttachedDocument[];
    claimsStep: IStep[];
    comments: string;
    creationDate: string;
    creationUser: string;
    cups: string;
    denyDate: string | null;
    fileName: string;
    fraudAnomalyExpNumber: string | null;
    modifDate: string;
    requestCode: string;
    result: string;
    resultDetail: string;
    sinceDate: string;
    status: string;
    step: string;
    subtype: string;
    type: string;
    untilDate: string;
    updateDate: string;
    updateUser: string;
}

export interface IObjections {
    acknowledgmentReceipt: string;
    article15Mods: string;
    atrManagement: number;
    autoObjetionsEnergy: number;
    creationDate: string;
    creationUser: string;
    cups: string;
    distributorInNetEnergy: number;
    distributorNHours: number;
    facturedInNetEnergy: string | null;
    fileName: string;
    finalDate: string;
    hubInNetEnergy: number;
    hubNHours: number;
    id: number;
    inEnergyAcumOrInmeclos: string | null;
    inNetEnergyMonthlyAtr: number;
    initDate: string;
    instName: string;
    liqType: string;
    maxEnergyRee: string;
    measurePointType: number;
    negotiatingAccountDescription: string;
    objectionAnswer: string;
    objectionAnswerComment: string;
    objectionFile: string;
    objectionId: string;
    objectionInEnergyProposed: string;
    objectionNoReason: string;
    programUnitElectricSystem: string;
    rate: string;
    reeEnergyHc: number;
    reeEnergyHdAfterObjection: number;
    reeEstimatedMeasure: number;
    reeFile: string;
    reeVsAtr: number;
    reeVsAtrPercentage: number;
    reeVsDistributor: number;
    reeVsFactured: number;
    reeVsHub: number;
    reviewFile: string;
    reviewIndicative: string;
    reviewReason: string;
    systemComment: string;
    updateDate: string;
    updateUser: string;
    userAutomaticObjectionReason: string;
    userCheck: string;
    userComment: string;
    userInEnergyProposed: string;
}

export interface ICommunicationParameters {
    accessKey: string;
    creationDate: string;
    creationUser: string;
    fileName: string;
    function: string;
    ipAddress: string | null;
    lineNumber: string | null;
    linkAddress: string;
    linkPort: string | null;
    measurePoint: string;
    requestCode: string;
    telphMeasure: string | null;
    updateDate: string;
    updateUser: string;
}
export interface IClaimsAttachedDocument {
    creationDate: string;
    creationUser: string;
    documentType: string;
    fileName: string;
    requestCode: string;
    updateDate: string | null;
    updateUser: string | null;
    urlAddress: string;
}
export interface IStep {
    comments: string;
    creationDate: string;
    creationUser: string;
    fileName: string;
    modifDate: string;
    requestCode: string;
    steps: string;
    updateDate: string;
    updateUser: string;
}

export interface IKeyValue {
    id: string;
    value: string | number;
}
