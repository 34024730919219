<div class="modal">
    <div class="modal-title-text-title">
        <div class="modal-title-close">
            <h1 mat-dialog-title>{{ data.title | translate }}</h1>
            <button mat-button mat-dialog-close class="hide-access close" (click)="close(false)">close</button>
        </div>

        <div mat-dialog-content>
            {{ data.text | translate }}
        </div>
    </div>

    <div mat-dialog-actions [ngStyle]="{ 'justify-content': !data.hideCancelButton ? 'space-between' : 'center' }">
        <ng-container *ngIf="!data.hideCancelButton">
            <button mat-button mat-dialog-close class="secondary" (click)="close(false)">
                {{ 'PAGE.MODALS.CANCEL' | translate }}
            </button>
        </ng-container>
        <button mat-button mat-flat-button color="warn" class="primary" cdkFocusInitial (click)="close(true)">
            {{ 'PAGE.MODALS.ACCEPT' | translate }}
        </button>
    </div>
</div>
