import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TablaMaestroComponent } from '../tabla-maestro.component';
import { MaestroService } from '../../../services/maestro.service';
import { Precedence } from '../../../../../shared/models/precedences';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { IColumnsTable } from '../../../../../shared/models/columns-table.model';
import { LoginService } from '../../../../../core/services/login.service';
import { MaestrosAWS, PuntoFronteraTypeModal } from 'src/app/shared/enums/maestros';
import { AlertModalComponent } from '../../../../../shared/components/alert-modal/alert-modal.component';

@Component({
    selector: 'app-show-modal',
    templateUrl: './show-modal.component.html',
    styleUrls: ['./show-modal.component.scss'],
})
export class ShowModalComponent implements OnInit {
    constructor(
        public dialogShow: MatDialogRef<TablaMaestroComponent>,
        public dialog: MatDialog,
        private _loginService: LoginService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        public maestroService: MaestroService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.copyData = JSON.parse(JSON.stringify(data));
        this.boundaryCode = this.copyData.value.boundaryPointCode;
    }

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    precedenceData: any = [];
    precedenceDataMassive: any = [];
    historicPrecedenceData: any = [];
    historicHeaders: any;
    precedenceDataToCreate: any = [];

    precedenceDataNew: Precedence[] = [];

    precedenceCreate: boolean = false;
    precedenceEdit: boolean = false;

    showHistoric: boolean = false;

    copyData: any;
    boundaryCode: string;

    formGroup: UntypedFormGroup;

    // Tabla Historico
    columns: any;
    dataHeaders: IColumnsTable[];

    // Cambios efectuados para validar
    changes: number = 0;

    // Filtro Medidas/Cierres
    filtroSelect = {
        data: [
            { name: MaestrosAWS.MEDIDAS, value: MaestrosAWS.MEDIDAS },
            { name: MaestrosAWS.CIERRES, value: MaestrosAWS.CIERRES },
        ],
        value: { name: '', value: '' },
    };

    // Key para saber si la edición es masiva o no.
    key: string = '';

    showSelectedCupsBoolean: boolean = false;

    get PuntoFronteraTypeModal() {
        return PuntoFronteraTypeModal;
    }

    ngOnInit(): void {}

    close(resp: boolean) {
        const dateNow = Date.now();
        const today = new Date(dateNow).toISOString().replace('Z', '');

        if (resp) {
            if (this.changes > 0) {
                if (this.checkErrors()) {
                    if (this.key === PuntoFronteraTypeModal.MANAGE_PRECEDENCES) {
                        // Crear
                        if (this.precedenceCreate) {
                            let dataToSend = this.precedenceDataNew.map((d: Precedence) => ({
                                cups: this.boundaryCode,
                                origin: d.origin,
                                area: d.area,
                                precedence: d.precedence,
                                description: d.description,
                            }));
                            this.maestroService.createPrecedence(dataToSend).subscribe((d) => {});
                            this.precedenceCreate = false;
                            // Editar
                        } else if (this.precedenceEdit) {
                            let dataToSend = this.precedenceDataNew.map((d: Precedence) => ({
                                cups: this.boundaryCode,
                                origin: d.origin,
                                area: d.area,
                                precedence: d.precedence,
                                description: d.description,
                            }));
                            this.maestroService.updatePrecedence(dataToSend).subscribe((d) => {});
                            this.precedenceEdit = false;
                        }

                        // Reinicio los cambios.
                        this.changes = 0;
                        // Cierro el modal
                        this.dialogShow.close();
                    } else if (this.key === PuntoFronteraTypeModal.SET_PRECEDENCES) {
                        const dialogRef = this.dialog.open(AlertModalComponent, { data: { title: 'PAGE.MODALS.TITLE12', text: 'PAGE.MODALS.TEXT_TITLE_5' } });
                        dialogRef.afterClosed().subscribe((result) => {
                            if (result) {
                                let cupsPrecedenceArray: any[] = [];
                                this.maestroService.boundaryPoints.forEach((cups: any) => {
                                    this.precedenceData.forEach((precedence: any) => {
                                        let cupsPrecedence: any = {
                                            cups,
                                            origin: precedence.origin,
                                            area: precedence.area,
                                            precedence: this.formGroup.controls[`${precedence.origin}`].value,
                                            description: precedence.description,
                                        };
                                        cupsPrecedenceArray.push(cupsPrecedence);
                                    });
                                });
                                this.maestroService.updatePrecedence(cupsPrecedenceArray).subscribe((d) => {});
                                this.precedenceEdit = false;
                                // Reinicio los cambios.
                                this.changes = 0;
                                // Cierro el modal
                                this.dialogShow.close();
                            }
                        });
                    }
                }
            } else {
                // Cierro el modal
                this.dialogShow.close();
            }
        } else {
            // Cierro el modal
            this.dialogShow.close();
        }
    }

    /**
     * Mostrar tabla de históricos
     */
    showHistoricTable() {
        if (!this.showHistoric) {
            this.showHistoric = true;
        } else {
            this.showHistoric = false;
        }

        if (!this.historicHeaders) {
            this.historicHeaders = this._loginService.getHeaderSession();
            this.buildTable(JSON.parse(this.historicHeaders), 26);
        }
    }

    /**
     * Método para cambiar el color de las celdas de la tabla de históricos
     * @param data Datos de la tabla
     * @returns
     */
    colorTable(data: any[]) {
        let group: boolean = false;
        let date: string = '';
        // fecha.substring(0, fecha.indexOf('T') + 9);
        data.forEach((item: any) => {
            if (item.endDate.substring(0, item.endDate.indexOf('T') + 6) !== date) {
                date = item.endDate.substring(0, item.endDate.indexOf('T') + 6);
                group = !group;
            }
            item.color = group ? '#ffe6e6' : '#f5f6fa';
        });
        return data;
    }

    showSelectedCups(event: any) {
        if (!this.showSelectedCupsBoolean) {
            this.showSelectedCupsBoolean = true;
        } else {
            this.showSelectedCupsBoolean = false;
        }
    }

    valueChange(event: any) {
        let eventValue = event.newValue || event.eventValue || event.value;
        this.filtroSelect.value.name = eventValue;
        this.filtroSelect.value.value = eventValue;

        // Cargamos los datos del back dependiendo si hay una prelación o no y por tanto vamos a que crear una nueva
        if (this.data.type === PuntoFronteraTypeModal.MANAGE_PRECEDENCES) {
            this.key = PuntoFronteraTypeModal.MANAGE_PRECEDENCES;
            if (this.boundaryCode && eventValue) {
                forkJoin([
                    this.maestroService.getPrecedencesShowModal(this.boundaryCode, eventValue),
                    this.maestroService.getPrecedenceStructure(eventValue),
                ]).subscribe(([precedenceData, precedenceDataStructure]) => {
                    let precedenteDataDateEndNull = [];
                    if (precedenceData?.data.length > 0) {
                        let auxData = precedenceData.data.filter((d: Precedence) => d.endDate !== null);

                        auxData.sort((a: any, b: any) => (a.endDate > b.endDate ? 1 : -1));

                        this.historicPrecedenceData = this.colorTable(auxData);
                        this.precedenceEdit = true;
                        this.precedenceCreate = false;
                    } else if (precedenceDataStructure?.data.length > 0) {
                        precedenceDataStructure.data = precedenceDataStructure.data.map((d: Precedence) => ({ ...d, precedence: 0 }));
                        this.precedenceCreate = true;
                        this.precedenceEdit = false;
                    }
                    if (this.precedenceEdit) {
                        this.precedenceData = precedenceData?.data.filter((d: Precedence) => d.endDate === null);
                        this.precedenceDataNew = this.precedenceData;
                    } else if (this.precedenceCreate) {
                        this.precedenceData = precedenceDataStructure?.data;
                        this.precedenceDataNew = this.precedenceData;
                    }
                    this.setupForm(this.precedenceData);
                });
            }
        } else if (this.data.type === PuntoFronteraTypeModal.SET_PRECEDENCES) {
            this.key = PuntoFronteraTypeModal.SET_PRECEDENCES;
            let boundaryPointsFormated = this.maestroService.boundaryPoints.join(';');
            this.precedenceEdit = true;
            if (this.maestroService.boundaryPoints && eventValue) {
                forkJoin([
                    this.maestroService.getPrecedencesMassiveShowModal(boundaryPointsFormated, eventValue),
                    this.maestroService.getPrecedenceStructure(eventValue),
                ]).subscribe(([precedenceData, precedenceDataStructure]) => {
                    this.precedenceDataMassive = precedenceData?.data;
                    this.precedenceData = precedenceDataStructure.data;
                    this.precedenceDataNew = this.precedenceData;
                    this.setupForm(this.precedenceData);
                });
            }
        }
    }

    private setupForm(data: Precedence[]) {
        this.formGroup = new UntypedFormGroup({});
        data.forEach((d) => {
            // Añadimos los controles y sus validadores
            this.formGroup.addControl(d.origin, new UntypedFormControl(d.precedence, [Validators.pattern('^([1-7])$')]));
        });
    }

    valueChangeInputCreateEdit(event: any, item: any) {
        if (this.key === PuntoFronteraTypeModal.MANAGE_PRECEDENCES) {
            // Comprobamos si existe un item en el array, si no es así lo añadimos.
            if (this.precedenceDataNew.length === 0) {
                item.precedence = Number(event.newValue);
                this.precedenceDataNew.push({ ...item });
            } else {
                if (this.precedenceDataNew.find((data: Precedence) => data.origin === item.origin)) {
                    this.precedenceDataNew.every((data) => {
                        if (data.origin === item.origin) {
                            data.precedence = Number(event.newValue);
                            this.changes++;
                            return false;
                        }
                        return true;
                    });
                } else {
                    item.precedence = Number(event.newValue);
                    this.precedenceDataNew.push({ ...item });
                    this.changes++;
                }
            }
        } else if (this.key === PuntoFronteraTypeModal.SET_PRECEDENCES) {
            if (this.precedenceDataNew.find((data: Precedence) => data.origin === item.origin)) {
                this.precedenceDataNew.every((data) => {
                    if (data.origin === item.origin) {
                        data.precedence = Number(event.newValue);
                        this.changes++;
                        return false;
                    }
                    return true;
                });
            } else {
                item.precedence = Number(event.newValue);
                this.precedenceDataNew.push({ ...item });
                this.changes++;
            }
        }
    }

    /**
     * Comprobar si hay errores
     * @returns True si hay errores y False si no los hay.
     */
    checkErrors(): boolean {
        let precedences = [];
        precedences = this.precedenceDataNew
            .map((data: Precedence) => Number(data.precedence))
            .join(',')
            .split(',');

        if (this.hasDuplicates(precedences)) {
            this._translateService.get('PAGE.MODALS').subscribe((message) => {
                this._snackBar.open(message['DUPLICATES'], message['ACTION_CLOSE'], {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: ['error-snackbar'],
                });
            });
        }

        if (this.formGroup.status === 'VALID' && !this.hasDuplicates(precedences)) {
            return true;
        } else {
            return false;
        }
    }

    hasDuplicates(array: any) {
        return new Set(array).size !== array.length;
    }

    transformHeaders() {
        this.columns = this.dataHeaders.map((col) => col.id);
    }

    buildTable(header: any, idHeaders: number): void {
        this.dataHeaders = header.data[idHeaders].headers;
        this.transformHeaders();
    }
}
