<div class="container" *ngIf="dataBusiness">
    <app-spinner></app-spinner>
    <app-http-error></app-http-error>
    <div class="row row-column">
        <div class="table-header">
            <div class="table-header-info">
                <span
                    >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                    {{ totalRegisters | numberFormat : _headerTitleService.currentLang }}</span
                >
            </div>
            <div class="table-header-actions display_flex actions-heigh">
                <button mat-button (click)="openModalExcel()">
                    <span class="text">{{ 'PAGE.ACTIONS.EXCEL_EXPORT' | translate }}</span>
                    <span class="icon material-symbols-outlined">save_as </span>
                </button>
                <button mat-button color="primary" (click)="getMultipleDownloadURLs()">
                    <span class="text">{{ 'PAGE.ACTIONS.DOWNLOAD' | translate }}</span>
                    <span class="icon material-symbols-outlined"> download </span>
                </button>
                <button mat-button color="primary" (click)="uncheckSelectedDocuments()">
                    <span class="text">{{ 'PAGE.ACTIONS.UNCHECK_ALL' | translate }}</span>
                    <span class="icon material-symbols-outlined"> check_box_outline_blank </span>
                </button>
                <button [hidden]="true" class="buttonColumns" [ngClass]="ocultar === true ? 'mat' : 'mat_none'">
                    <!-- INICIO>>> Botón mostrar/ocultar columnas -->
                    <mat-form-field [hidden]="ocultar">
                        <mat-select
                            #selectColumns
                            name="selectColumns"
                            id="selectColumns"
                            [value]="dataHeaders_valueSelect"
                            multiple
                        >
                            <mat-option
                                enableCheckAll="true"
                                [matColumnDef]="column.id"
                                [value]="column"
                                (click)="checkvalue(selectColumns.value)"
                                *ngFor="let column of filterDataHeader()"
                            >
                                {{ column.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="divColumns" (click)="botonClick()">
                        <span class="text spanColumn" *ngIf="!element">{{ 'PAGE.ACTIONS.COLUMNS' | translate }}</span>
                        <app-base-icon class="padding_button" iconName="view_column"></app-base-icon>
                    </div>
                </button>
                <!-- FIN>>> Botón mostrar/ocultar columnas -->
            </div>
        </div>
        <div class="table-container">
            <!-- Table -->
            <table
                mat-table
                [dataSource]="dataBusiness"
                multiTemplateDataRows
                matSort
                (matSortChange)="sortData($event)"
            >
                <ng-container
                    style="max-width: fit-content"
                    [matColumnDef]="column.id"
                    *ngFor="let column of dataHeaders"
                >
                    <!-- Header TH -->
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                        <div class="header-table d-flex">
                            {{ column.label }}
                        </div>
                    </th>
                    <!-- Element TD -->
                    <td mat-cell *matCellDef="let element">
                        <!-- Element TD Text -->
                        <div *ngIf="column.type === 'text'">
                            <div *ngIf="column.id === 'download'">
                                <button
                                    mat-button
                                    color="secondary"
                                    matTooltip="Descargar"
                                    matTooltipShowDelay="300"
                                    (click)="downloadButton(element)"
                                >
                                    <span class="material-symbols-outlined">download</span>
                                </button>
                            </div>
                            <!-- Aquí revisamos, en la columna 'mensaje', si el archivo tiene 'Errores' -->
                            <div
                                *ngIf="
                                    column.id === 'errors' || column.id === 'status' || column.id === 'origin';
                                    else not_error
                                "
                            >
                                <div
                                    *ngIf="
                                        element['errors'].length > 0 &&
                                            column.id !== 'status' &&
                                            column.id !== 'origin';
                                        else no_hay_error
                                    "
                                >
                                    <button
                                        mat-button
                                        color="secondary"
                                        matTooltip="Ver Errores"
                                        matTooltipShowDelay="300"
                                        (click)="openModalErrors(element['errors'])"
                                    >
                                        <span class="material-symbols-outlined">visibility</span>
                                    </button>
                                </div>
                                <!-- Si no hay error, el valor es un vacío -->
                                <ng-template #no_hay_error></ng-template>
                            </div>
                            <ng-template #not_error>
                                <span>{{ element[column.id] }}</span>
                            </ng-template>
                            <!-- Detectar columna 'solventar', y si hay 'Errores', mostrar botón-->
                            <div *ngIf="column.id === 'fix' && ((element['errors'].length > 0 && element['status'] === 'OK_PROCESS_WITH_ERRORS') || ((element['status'] === 'KO_CATALOG_SYSTEM') || element['status'] === 'KO_PROCESS_SYSTEM'))">
                                <button
                                    mat-button
                                    color="secondary"
                                    [disabled]="element.isRunning"
                                    matTooltipShowDelay="300"
                                    (click)="fix(element)"
                                    [ngClass]="{ enabled: element.enabled, disabled: element.disabled }"
                                >
                                    <span
                                        *ngIf="!element.isRunning"
                                        class="material-symbols-outlined"
                                        matTooltip="{{ 'PAGE.ACTIONS.FIX' | translate }}"
                                        >healing</span
                                    >
                                    <span
                                        *ngIf="element.isRunning"
                                        class="material-symbols-outlined"
                                        matTooltip="{{ 'PAGE.ACTIONS.FIXED' | translate }}"
                                        >healing</span
                                    >
                                </button>
                            </div>
                            <div *ngIf="column.id === 'origin'">
                                <span>{{ mapFileOrigin(element) }}</span>
                            </div>
                            <div *ngIf="column.id === 'status'">
                                <span>{{ mapFileStatus(element) }}</span>
                            </div>
                            <div *ngIf="column.id === 'initDateHeader'">
                                <span>{{ getFormatedDate(element.initDate) }}</span>
                            </div>
                            <div *ngIf="column.id === 'endDateHeader'">
                                <span>{{ getFormatedDate(element.endDate) }}</span>
                            </div>
                            <div *ngIf="column.id === 'time'">
                                {{ hora[element['id']] }}
                            </div>
                            <div *ngIf="column.id === 'downloadMultiple'">
                                <mat-checkbox
                                    (change)="downloadList(element)"
                                    [checked]="checkDownloadList(element)"
                                    class="no_font_size"
                                ></mat-checkbox>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
            </table>
            <mat-paginator
                aria-label="Select page"
                [length]="totalRegisters"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                [pageIndex]="numPage - 1"
                (page)="pageChange($event)"
            ></mat-paginator>
        </div>
    </div>
</div>
<div class="container containerNoData" *ngIf="!dataBusiness">
    <div class="row flex_column">
        <app-spinner></app-spinner>
        <app-http-error></app-http-error>
        <h3>{{ 'PAGE.ACTIONS.NO_DATA' | translate }}</h3>
    </div>
    <app-table-no-data></app-table-no-data>
</div>
