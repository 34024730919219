import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { CargasService } from 'src/app/pages/cargas/services/cargas.service';
import { MaestroService } from 'src/app/pages/maestro/services/maestro.service';
import { ExportService } from 'src/app/services/export.service';
import { ProcesosService } from '../../../pages/procesos/services/procesos.service';
import { EnviosService } from '../../../pages/envios/envios.service';
import { InformesService } from 'src/app/pages/informes/services/informes.service';
import { ContractDetails } from '../../../shared/enums/idsConcepts';
import { MaestrosAWS, MaestrosCRM, SortBy, PerfilesREE } from '../../enums/maestros';
import { KeysEnvios } from '../../enums/envios';
import { KeysInformes, SortByInformes } from '../../enums/informes';
import { KeysProcesos } from '../../enums/procesos';
import { CargasRES } from '../../enums/cargas';
import { EndpointsProcesos } from '../../enums/procesos';
import { KeysPrevisiones } from '../../enums/previsiones';
import { ReportStatus } from '../../enums/reports';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { HeaderTitleService } from 'src/app/services/header-title.service';
import { concatMap, firstValueFrom, forkJoin, lastValueFrom, mergeMap, of } from 'rxjs';

@Component({
    selector: 'app-download-excel',
    templateUrl: './download-excel.component.html',
    styleUrls: ['./download-excel.component.scss'],
})
export class DownloadExcelComponent {
    dataBusiness: any[];
    endpoint: string;
    prueba: any[];
    idFilters: any;
    totalRegisters: number;
    newDataBusiness: any[];
    closable = true;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    idToCompare: string | [];
    statusResult: any;

    constructor(
        public dialogRef: MatDialogRef<DownloadExcelComponent>,
        public dialog: MatDialog,
        private _excelService: ExportService,
        private _maestroService: MaestroService,
        private _cargasService: CargasService,
        private _procesosService: ProcesosService,
        private _enviosService: EnviosService,
        private _informesService: InformesService,
        public _translateService: TranslateService,
        private _headerTitleService: HeaderTitleService,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.dataBusiness = data.filtersTable;
        this.idFilters = data.idFilters;
        this.totalRegisters = data.totalRegisters;
    }

    close() {
        this.dialogRef.close();
    }

    exportExcel(): void {
        this.dialogRef.close('close');

        if (this.idFilters[0].name && this.idFilters[0].name === KeysInformes.REPORT_RESULTS) {
            this.idToCompare = this.idFilters[0].name;
        } else {
            if (this.idFilters.constructor === Array) {
                this.idToCompare = this.idFilters[0];
            } else {
                this.idToCompare = this.idFilters;
            }
        }
        switch (this.idToCompare) {
            case MaestrosAWS.PRECIOS_REGULADOS:
                this._maestroService
                    .getTablaPrecios(-1, 10, '', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        res.data.forEach((element: any) => {
                            switch (element.code) {
                                case 'H':
                                    element.code = this._translateService.instant(
                                        'PAGE.MASTER.MASTER_AWS.PRICES.ENERGY_CHARGES'
                                    );
                                    break;
                                case 'E':
                                    element.code = this._translateService.instant(
                                        'PAGE.MASTER.MASTER_AWS.PRICES.TD_ENERGY'
                                    );
                                    break;
                                case 'T':
                                    element.code = this._translateService.instant(
                                        'PAGE.MASTER.MASTER_AWS.PRICES.TDA_ENERGY'
                                    );
                                    break;
                                case 'X':
                                    element.code = this._translateService.instant(
                                        'PAGE.MASTER.MASTER_AWS.PRICES.EXCESSES'
                                    );
                                    break;
                                case 'Z':
                                    element.code = this._translateService.instant(
                                        'PAGE.MASTER.MASTER_AWS.PRICES.EXCESS_WITHOUT_KP'
                                    );
                                    break;
                                case 'C':
                                    element.code = this._translateService.instant(
                                        'PAGE.MASTER.MASTER_AWS.PRICES.PAYMENT_FOR_CAPACITY'
                                    );
                                    break;
                                case 'G':
                                    element.code = this._translateService.instant(
                                        'PAGE.MASTER.MASTER_AWS.PRICES.POWER_CHARGES'
                                    );
                                    break;
                                case 'P':
                                    element.code = this._translateService.instant(
                                        'PAGE.MASTER.MASTER_AWS.PRICES.TD_POWER'
                                    );
                                    break;
                            }
                        });
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosAWS.PRECIOS_REGULADOS
                        );
                    });
                break;
            case MaestrosAWS.PRECIOS_INDEXADOS:
                this._maestroService
                    .getTablaPreciosIndexados(-1, 10, 'date', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosAWS.PRECIOS_INDEXADOS
                        );
                    });
                break;
            case MaestrosAWS.PRECIOS_MENSUALES:
                this._maestroService
                    .getTablaPreciosMensuales(-1, 10, 'year', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosAWS.PRECIOS_MENSUALES
                        );
                    });
                break;
            case MaestrosAWS.FESTIVOS:
                this._maestroService
                    .getTablaFestivos(-1, 10, SortBy.FESTIVE, 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        // Modifica Autonómicos y Eléctricos para ponerles la tilde si está en español
                        if (this._headerTitleService.currentLang === 'es') {
                            this.newDataBusiness = this.newDataBusiness.map((e: any) => {
                                if (e.festiveType) {
                                    if (e.festiveType === 'Autonomicos') {
                                        e.festiveType = 'Autonómicos';
                                    }
                                    if (e.festiveType === 'Electricos') {
                                        e.festiveType = 'Eléctricos';
                                    }
                                }
                                return e;
                            });
                        }
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosAWS.FESTIVOS);
                    });
                break;
            case MaestrosAWS.PERFILES_REE:
                this._maestroService
                    .getTablaPerfiles(-1, 10, 'date', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        res.data = res.data.sort((a: any, b: any) => {
                            if (a.date !== b.date) {
                                return a.date.localeCompare(b.date);
                            }
                            if (a.hour !== b.hour) {
                                return a.hour - b.hour;
                            }
                            if (a.rate !== b.rate) {
                                return a.rate.localeCompare(b.rate);
                            }
                            const nameOrder: any = { INICIAL: 1, FINAL: 2 };
                            if (a.name && b.name && nameOrder[a.name] && nameOrder[b.name]) {
                                return nameOrder[a.name] - nameOrder[b.name];
                            }
                        });
                        if (res.data[0].origin === PerfilesREE.SELECTED_PROPIO) {
                            res.data.forEach((element: any) => {
                                element.name = this._maestroService.changeReeProfileName(element.name);
                            });
                        }
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosAWS.PERFILES_REE);
                    });
                break;
            case MaestrosAWS.PARAMETROS:
                this._maestroService
                    .getTablaParametros(-1, 10, 'code', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosAWS.PARAMETROS);
                    });
                break;
            case KeysPrevisiones.PREVISIONES_AGREGADO:
                this.dataBusiness = this._formatData(this.dataBusiness);
                this._excelService.exportToExcel(this.dataBusiness, 'export_' + KeysPrevisiones.PREVISIONES_AGREGADO);
                break;
            case MaestrosAWS.TARIFAS:
                this._maestroService
                    .getFeesTable(-1, 10, 'codeRate', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosAWS.TARIFAS);
                    });
                break;
            case MaestrosAWS.DISTRIBUIDOR:
                this._maestroService.getDistributorsTable(-1, 10, 'code', 'asc', true).subscribe((res) => {
                    this.newDataBusiness = this._formatData(res.data);
                    this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosAWS.DISTRIBUIDOR);
                });
                break;
            case MaestrosAWS.PRELACION:
                this._maestroService
                    .getTablaPrelacion(-1, 10, 'area', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosAWS.PRELACION);
                    });
                break;
            case MaestrosAWS.PERIODOS_TARIFARIOS:
                this._maestroService
                    .getTablaPeriodosTarifarios(-1, 10, 'id', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        // Filters data by zone selected if it's been selected
                        if (this.idFilters && this.idFilters[1]) {
                            const selectedZone: string = this.idFilters[1].find(
                                (e: any) => e.key === 'zoneGroup' || e.key === 'zone'
                            )?.selected;
                            if (selectedZone) {
                                res.data = res.data.filter(
                                    (e: any) => e.zone === (selectedZone === 'Península' ? 'Peninsula' : selectedZone)
                                );
                            }
                        }
                        // Sorts data by rate, date, hour and zone
                        res.data = res.data.sort((a: any, b: any) => {
                            if (a.rate !== b.rate) {
                                return a.rate.localeCompare(b.rate);
                            }
                            if (a.date !== b.date) {
                                return a.date.localeCompare(b.date);
                            }
                            if (a.hour !== b.hour) {
                                return a.hour - b.hour;
                            }
                            const zoneOrder: any = { Peninsula: 1, Baleares: 2, Canarias: 3, Ceuta: 4, Melilla: 5 };
                            return zoneOrder[a.zone] - zoneOrder[b.zone];
                        });
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosAWS.PERIODOS_TARIFARIOS
                        );
                    });
                break;
            case MaestrosAWS.UNIDAD_PROGRAMACION:
                this._maestroService
                    .getTablaUnidadProgramacion(
                        -1,
                        10,
                        'programUnitCode',
                        'asc',
                        this._maestroService.searchParam,
                        true
                    )
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosAWS.UNIDAD_PROGRAMACION
                        );
                    });
                break;
            case MaestrosAWS.COEFICIENTE_PERDIDAS:
                this._maestroService
                    .getTablaCoeficientePerdidas(-1, 10, 'date', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosAWS.COEFICIENTE_PERDIDAS
                        );
                    });
                break;
            case MaestrosAWS.K_ESTIMADA:
                this._maestroService
                    .getTablakEstimada(-1, 10, SortBy.K_ESTIMADA, 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        if (res && res.data) {
                            res.data = res.data.sort((a: any, b: any) => {
                                if (a.date !== b.date) {
                                    return a.date.localeCompare(b.date);
                                }
                                if (a.hour !== b.hour) {
                                    return a.hour - b.hour;
                                }
                                return a.code - b.code;
                            });
                        }
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosAWS.K_ESTIMADA);
                    });
                break;
            case CargasRES.RESULTS:
                this._cargasService
                    .getTablaResultados(-1, 0, 'id', 'asc', this._cargasService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + CargasRES.RESULTS);
                    });
                break;
            case ContractDetails.PRICES:
                this._maestroService
                    .getPrices(-1, 0, SortBy.CRM_VARIABLE, 'asc', this._maestroService.contractCode)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosCRM.PRICES_CONTRACTS
                        );
                    });
                break;
            case MaestrosCRM.CONCEPTS_CONTRACTS:
                this._maestroService
                    .getContractConcepts(-1, 0, SortBy.CONCEPT, 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        let contractCodes = res.data.map((f: any) => f.contract.contractCode);
                        res.data.forEach((element: any, index: any) => {
                            element.contract = contractCodes[index];
                        });
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosCRM.CONCEPTS_CONTRACTS
                        );
                    });
                break;
            case ContractDetails.CONTRACT_CONCEPTS:
                this._maestroService
                    .getContractConcepts(-1, 0, SortBy.CONCEPT, 'asc', this._maestroService.contractCode, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosCRM.CONCEPTS_CONTRACTS
                        );
                    });
                break;
            case ContractDetails.CLOSURES:
                this._maestroService
                    .getClosures(-1, 0, SortBy.CLOSED, 'asc', this._maestroService.contractCode, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'my_export' + MaestrosCRM.CLOSURES);
                    });
                break;
            case MaestrosCRM.PRECIOS_CONTRATOS:
                this._maestroService
                    .getTablaPreciosContratos(
                        -1,
                        0,
                        `${SortBy.CRM_VARIABLE},${SortBy.PERIOD}`,
                        'asc',
                        this._maestroService.searchParam,
                        true
                    )
                    .subscribe((res) => {
                        // EL EXCEL NO EXPORTA EL VALOR SI ES UN OBJETO ENTONCES SUSTITUIMOS EL OBJETO POR EL CONTRACT CODE QUE HAY DENTRO DE ESTE.
                        let contractCodes = res.data.map((f: any) => f.contract.contractCode);
                        res.data.forEach((element: any, index: any) => {
                            element.contract = contractCodes[index];
                        });
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosCRM.PRECIOS_CONTRATOS
                        );
                    });
                break;
            case MaestrosCRM.SOCIEDAD:
                this._maestroService
                    .getTablaSociedad(-1, 0, 'idNumber', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosCRM.SOCIEDAD);
                    });
                break;
            case MaestrosCRM.CIERRES:
                this._maestroService
                    .getTablaCierres(-1, 0, SortBy.CLOSED, 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'my_export' + MaestrosCRM.CIERRES);
                    });
                break;
            case MaestrosCRM.CONTRATOS:
                // Nueva implementación que exporta los contratos desde el microservicio
                this._maestroService.exportToExcel(MaestrosCRM.CONTRATOS, this._excelService.totalRegisters, SortBy.CONTRACT_CODE, 'asc', this._maestroService.searchParam).subscribe((res) => {
                    const fileName = 'export_' + MaestrosCRM.CONTRATOS + '.xlsx';
                    this.downloadFile(res, fileName);
                })
                break;
            case MaestrosCRM.CUENTA_NEGOCIADORA:
                this._maestroService
                    .getTablaCuentaNegociadora(
                        -1,
                        10,
                        SortBy.NEGOTIATING_ACCOUNT,
                        'asc',
                        this._maestroService.searchParam,
                        true
                    )
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosCRM.CUENTA_NEGOCIADORA
                        );
                    });
                break;
            case MaestrosCRM.CUPS:
                this._maestroService
                    .getTablaCUPS(-1, 10, 'cups', 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosCRM.CUPS);
                    });
                break;
            case MaestrosCRM.CONSUMO_ANUAL:
                this._maestroService
                    .getTablaConsumoAnual(-1, 10, SortBy.HISTORIC, 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosCRM.CONSUMO_ANUAL);
                    });
                break;
            case MaestrosCRM.PUNTO_MEDIDA:
                this._maestroService
                    .getTablaPuntoMedida(-1, 10, SortBy.MEASURE_POINT, 'asc', this._maestroService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + MaestrosCRM.PUNTO_MEDIDA);
                    });
                break;
            case MaestrosAWS.CONTRATOS_FICTICIOS:
                this._maestroService
                    .getContratosFicticiosTable(
                        -1,
                        10,
                        SortBy.CONTRATOS_FICTICIOS,
                        'asc',
                        this._maestroService.searchParam,
                        true
                    )
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosAWS.CONTRATOS_FICTICIOS
                        );
                    });
                break;
            case MaestrosAWS.VARIABLES_CONTRATOS_FICTICIOS:
                this._maestroService
                    .getVarContratosFicticiosTable(
                        -1,
                        10,
                        SortBy.VARIABLES_CONTRATOS_FICTICIOS,
                        'asc',
                        this._maestroService.searchParam,
                        true
                    )
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosAWS.VARIABLES_CONTRATOS_FICTICIOS
                        );
                    });
                break;
            case MaestrosAWS.PUNTO_FRONTERA:
                /* if (this.totalRegisters > 50000) {
                    const batchSize = 5000;
                    const fileName = 'export_' + MaestrosAWS.PUNTO_FRONTERA + '_';
                    this.exportToExcelAsync(this.totalRegisters, fileName, MaestrosAWS.PUNTO_FRONTERA, batchSize, SortBy.BOUNDARY_POINT_CODE, 'asc', this._maestroService.searchParam);
                } else { */
                this._maestroService.exportToExcel(MaestrosAWS.PUNTO_FRONTERA, this.totalRegisters, SortBy.BOUNDARY_POINT_CODE, 'asc', this._maestroService.searchParam)
                    .subscribe((res) => {
                        const fileName = 'export_' + MaestrosAWS.PUNTO_FRONTERA + '.xlsx';
                        this.downloadFile(res, fileName);
                    })
                //}
                break;
            case KeysProcesos.PROCESS:
                this._procesosService
                    .getProcessResultFilter(
                        1,
                        10,
                        EndpointsProcesos.SortByInitDate,
                        EndpointsProcesos.ASC,
                        this._procesosService.searchParam,
                        true
                    )
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this.newDataBusiness.forEach((register: any) => {
                            register['launchName'] = register.launchId.name;
                            register['processName'] = register.processCode.name;
                            register['launchCreationUser'] = register.launchId.creationUser;
                        });
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + 'procesosResultados');
                    });
                break;
            case MaestrosAWS.LIQUIDACION_POTESTATIVA:
                this._maestroService
                    .getTablaLiquidacionPotestativa(
                        1,
                        10,
                        'date',
                        EndpointsProcesos.ASC,
                        this._maestroService.searchParam,
                        true
                    )
                    .subscribe((res: any) => {
                        if (res.data) {
                            res.data = res.data.sort((a: any, b: any) => {
                                if (a.date !== b.date) {
                                    return a.date.localeCompare(b.date);
                                }
                                if (a.hour !== b.hour) {
                                    return a.hour - b.hour;
                                }
                                return a.programUnitCode - b.programUnitCode;
                            });
                        }
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosAWS.LIQUIDACION_POTESTATIVA
                        );
                    });
                break;
            case KeysEnvios.ENVIOS_RESULTADOS:
                this._enviosService
                    .getTablaResultados(-1, 10, SortBy.SENDS_RESULTS, 'asc', this._enviosService.searchParam, true)
                    .subscribe((res) => {
                        this.newDataBusiness = this._formatData(res.data);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + KeysEnvios.ENVIOS_RESULTADOS
                        );
                    });
                break;
            case MaestrosCRM.COBERTURA_CONTRATO:
                this._maestroService.getContractState().subscribe((result) => {
                    this.statusResult = result;
                });
                this._maestroService
                    .getTablaCoberturaContrato(
                        -1,
                        10,
                        'contractCode.contractCode',
                        'asc',
                        this._maestroService.searchParam,
                        true
                    )
                    .subscribe((res) => {
                        this.newDataBusiness = res.data;
                        let contractCodes = res.data.map((f: any) => f.contractCode.contractCode);
                        const newResult: any = [];
                        newResult.push({ res: res.data, listStatus: this.statusResult.data });

                        newResult[0].res.forEach((element: any) => {
                            newResult[0].listStatus;
                            if (element.status == newResult[0].listStatus[parseInt(element.status) - 1].status) {
                                element.status = newResult[0].listStatus[parseInt(element.status) - 1].description;
                            }
                        });

                        newResult[0].res.forEach((element: any, index: any) => {
                            element.contractCode = contractCodes[index];
                        });
                        this.newDataBusiness = this._formatData(newResult[0].res);
                        this._excelService.exportToExcel(
                            this.newDataBusiness,
                            'export_' + MaestrosCRM.COBERTURA_CONTRATO
                        );
                    });
                break;
            case KeysInformes.REPORT_RESULTS:
                this._informesService
                    .getTablaInformesRes(-1, 10, SortByInformes.ID, 'asc', this._informesService.searchParam, true)
                    .subscribe((res) => {
                        res.data.forEach((status: any) => {
                            if (status.status === ReportStatus.SAVED) {
                                this._translateService.get('PAGE.REPORTS').subscribe((message) => {
                                    status.status = message.SAVED;
                                });
                            } else {
                                this._translateService.get('PAGE.REPORTS').subscribe((message) => {
                                    status.status = message.IN_PROGRESS;
                                });
                            }
                        });
                        this.newDataBusiness = this._formatData(res.data);
                        this.newDataBusiness.forEach((register: any) => {
                            register['description'] = register.reportType.description;
                        });
                        this._excelService.exportToExcel(this.newDataBusiness, 'export_' + KeysInformes.REPORT_RESULTS);
                    });
                break;
            default:
                console.log('No se ha encontrado la tabla');
                break;
        }
    }

    private downloadFile(fileUrl: string, fileName: string) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    private _formatData(values: any) {
        const isEnglish: boolean = this._headerTitleService.currentLang === 'en';
        const excludedPropertyKeys: string[] = [
            'creationUser',
            'updateUser',
            'rate',
            'code',
            'name',
            'codeRate',
            'description',
            'area',
            'codeRateTelef',
            'liquidation',
            'hour',
            'telephoneNum',
            'precedence',
            'origin',
            'programUnitCode',
            'shortDescription',
            'offerType',
            'participantCode',
            'eicCode',
            'regZone',
            'boundaryPointCode',
            'cups',
            'formulaOutEnergy',
            'formulaInEnergy',
            'claims',
            'objections',
            'zone',
            'zoneGroup',
            'fileName',
            'geoZone',
            'contractCode',
            'clientType',
            'atr',
            'codeRateAtr',
            'eqRentalPrice',
            'variable',
            'period',
            'instName',
            'distrCode',
            'street',
            'town',
            'municipality',
            'zipCode',
            'province',
            'country',
            'telephone',
            'fax',
            'email',
            'councilCif',
            'councilName',
            'agreedLosses',
            'idNumber',
            'clientName',
            'crmCode',
            'sapCode',
            'cnae',
            'cnaeCode',
            'contact',
            'negotiatingAccountDescription',
            'negotiatingAccountCode',
            'contract.contractCode',
            'contractCode.contractCode',
            'contractCode.status',
            'contractCode',
            'contractName',
            'hedgeCode',
            'atrProcess',
            'concept',
            'taxBase',
            'emsVariable',
            'variableType',
            'crmVariable',
            'closedCode',
            'closedTerm',
            'cupsMP',
            'type',
            'readMode',
            'functionMP',
            'address',
            'linkAddress',
            'ipAddress',
            'singularPoints',
            'cecoerActiveHub',
            'connectionIssue',
            'port',
            'accessKey',
            'telemetryPhone',
            'phoneStatus',
            'telTelemedida',
            'readsExtract',
            'typePropDevice',
            'deviceBrand',
            'file',
            'origin',
            'invoiceFiscalCode',
            'errors',
            'launchId.name',
            'processCode.name',
            'launchId.creationUser',
            'reportType.description',
            'pmType',
            'instName',
            'clientName',
            'negotiatingAccountDescription',
            'type',
        ];
        values.forEach((row: any[]) => {
            Object.keys(row).forEach((propertyKey: any) => {
                if (
                    !excludedPropertyKeys.some((key: string) => key === propertyKey) &&
                    !propertyKey.includes('formulaReact')
                ) {
                    let propertyValue: string =
                        row[propertyKey] || row[propertyKey] === 0 ? row[propertyKey].toString() : '';
                    if (
                        typeof propertyValue === 'string' &&
                        propertyValue.length > 5 &&
                        !/[a-zA-SU-Z@#$%^&*()_+{}\[\];<>?//]/.test(propertyValue) &&
                        (propertyValue.match(/-/g)?.length === 2 || propertyValue.match(/\//g)?.length === 2) &&
                        moment(propertyValue).isValid() &&
                        !isNaN(Date.parse(propertyValue))
                    ) {
                        // If it's a date
                        propertyValue = propertyValue.includes('/')
                            ? propertyValue.replaceAll('/', '-')
                            : propertyValue;
                        row[propertyKey] = moment(propertyValue).format(
                            propertyValue.includes('T')
                                ? `${isEnglish ? 'YYYY-MM-DD' : 'DD-MM-YYYY'} HH:mm:ss`
                                : `${isEnglish ? 'YYYY-MM-DD' : 'DD-MM-YYYY'}`
                        );
                    } else if (
                        // if it's a number
                        (!isNaN(Number.parseInt(propertyValue)) &&
                            !/[a-zA-Z@#$%^&*()_+{}\[\]:;<>?/]/.test(propertyValue)) ||
                        propertyValue === '0'
                    ) {
                        row[propertyKey] =
                            propertyValue &&
                                (propertyValue.split(',').length > 2 || propertyValue.split('.').length > 2)
                                ? row[propertyKey].toString()
                                : this._formatNumber(propertyValue, isEnglish);
                    }
                }
            });
        });
        return values;
    }

    private _formatNumber(value: string, isEnglish: boolean): string {
        const decimalSeparator: '.' | ',' = isEnglish ? '.' : ',';
        const thousandsSeparator: '.' | ',' = isEnglish ? ',' : '.';
        if (value.includes(thousandsSeparator)) {
            value = value.replace(thousandsSeparator, decimalSeparator);
        }
        if (value.includes(decimalSeparator)) {
            const valueParts: any[] = value.split(decimalSeparator);
            const decimalPart = valueParts[1] || '';
            const formattedInteger = valueParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
            return decimalPart ? `${formattedInteger}${decimalSeparator}${decimalPart}` : formattedInteger;
        } else {
            return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
        }
    }

    /* Función para dividir la descarga del excel en varias llamadas */
    /* private async exportToExcelAsync(
        totalRegisters: number,
        fileName: string,
        endpointType: string,
        batchSize: number,
        sortBy: string,
        sortDir: string,
        search: string
    ) {
        const backBatchRelation = 4; // Relación de tamaño entre el batch del back y el batch que se usa para dividir las descargas
        const totalBatches = Math.ceil(totalRegisters / batchSize);
        const recordBatch = batchSize * backBatchRelation;
        var records = recordBatch;
        for (let batchIndex = 0; batchIndex < totalBatches; batchIndex += backBatchRelation) {
            const response = await lastValueFrom(this._maestroService.exportToExcel(endpointType, records, sortBy, sortDir, batchIndex, search));
            const fileEnd = fileName + batchIndex + '.xlsx';
            this.downloadFile(response, fileEnd);
            records += recordBatch;
        }
    } */
}
