import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LoginService } from 'src/app/core/services/login.service';
import { GlobalService } from 'src/app/services/global.service';
import { HeaderTitleService } from '../../../services/header-title.service';
import { CuadroMandosService } from '../services/cuadro-mandos.service';
import {
    DashboardSections,
    DashboardPeriodCodes,
    DashboardBestMeasureOptions,
    DashboardBestCloseOptions,
} from 'src/app/shared/enums/dashboard.enum';
import { MatSelect } from '@angular/material/select';
import { MonthListEnum } from '../../../shared/enums/monthListEnum';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { firstValueFrom, interval } from 'rxjs';
import { MaestroService } from '../../maestro/services/maestro.service';

interface BestMeasureObject {
    cups: any[];
    closeDateBegin: any[];
    closeDateEnd: any[];
    var: any[];
}

interface SequentialDB {
    seq: any[];
    var: any[];
}

@Component({
    selector: 'app-cuadro-mandos',
    templateUrl: './cuadro-mandos-page.component.html',
    styleUrls: ['./cuadro-mandos-page.component.scss'],
})
export class CuadroMandosComponent implements OnInit, OnDestroy {
    @ViewChild('monthBulkSelection') matMonthBulkSelection: MatSelect;
    @ViewChild('yearBulkSelection') matYearBulkSelection: MatSelect;
    @ViewChild('originBulkMeasureSelection') originBulkMeasureSelection: MatSelect;
    @ViewChild('originBulkCloseSelection') originBulkCloseSelection: MatSelect;
    @ViewChild('matBestMeasureSelect') matBestMeasureSelect: MatSelect;
    @ViewChild('matBestCloseSelect') matBestCloseSelect: MatSelect;
    @ViewChild('cupFilterBlockGroup') cupFilterBlockGroup: ElementRef<HTMLTextAreaElement>;
    @ViewChild('cupFilterBulkGroup') cupFilterBulkGroup: ElementRef<HTMLTextAreaElement>;
    selectColumns: FormControl = new FormControl();

    @Output() pagination: EventEmitter<any> = new EventEmitter();

    readonly NULL_VALUE_SIGN = `-`;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    dataHeaders: any;
    allDataHeaders: any;
    dataHeadersForColumns: any;
    columns: any[];
    dataBusiness: any[];
    totalRegisters: number;
    totalPages: number;
    numPage: number;
    pageSize: number;
    pageSizeOptions: number[] = [5];
    isBulkBlockUnblockModalVisible: boolean;
    disabled: boolean;
    bulkSelection: boolean;
    ocultar: boolean = true;
    dataHeaders_valueSelect: any;
    element: any = false;
    userAllRoles: any;
    rolesAll: any;
    validRole: any = true;
    isBulkBestMeasureCloseModalVisible: boolean = false;
    globalDataHeaders: any;
    globalDataBusiness: any;
    globalDataBusinessPreloaded: any;
    chargeSummary: boolean = true;
    chargeReady: boolean = false;
    fixSpinner: boolean = false;
    seqList: any = [];
    cupListFilter: any = [];
    dateInitFilter: any = [];
    dateEndFilter: any = [];
    activeColumn: any = DashboardSections.TECHNICAL_INFO;
    lastYearsComparison: any;
    lastYearStatus: any;
    selfSupplyList: any;
    search: any;
    periodCode: any = [20, 1, 2, 3, 4, 5, 6];
    tabMejorMedidaCierre: boolean = false;
    bestMeasureBulk: string;
    bestCloseBulk: string;
    noData: boolean = false;
    checkingTrue: boolean = false;
    monthFilterBlockGroup: any;
    yearFilterBlockGroup: any;
    isBulkBlockUnblockDisabled: boolean = true;
    bulkSelectionSelected: boolean = true;
    monthBulkSelection: any;
    yearBulkSelection: any;
    isOriginBulkMeasureSelectionDisabled: boolean = false;
    isOriginBulkCloseSelectionDisabled: boolean = false;
    defaultBulkMeasure: any = undefined;
    defaultBulkClose: any = undefined;
    dataHeaderChange: boolean;
    month: any;
    year: any;
    spinnerActive: boolean = false;
    visibilityPagination: boolean = true;
    arrayEmpty: any = ['', '', '', '', '', '', ''];
    bestMeasuresList: any = [
        'AWS',
        'Manual',
        'Distribuidor F',
        'Distribuidor P',
        'Concentrador',
        'REE',
        'ATR',
        'Previsiones',
        'ACUM',
        'INMECLOS',
    ];
    bestClosesList: any = ['AWS', 'CCH', 'Concentrador', 'Distribuidor', 'Horario', 'Manual', 'ATR', 'Mixto'];
    selectedOriginBestMeasure: any[] = [];
    selectedOriginBestClose: any[] = [];
    DHResumenConPeriodos: any = [
        'manualInNetEnergy',
        'distPInNetEnergy',
        'distFInNetEnergy',
        'hubInNetEnergy',
        'reeInNetEnergy',
        'atrInNetEnergy',
        'bestForecastInNetEnergy',
        'awsInNetEnergy', 
        'acumInNetEnergy',
        'inmeclosInNetEnergy',
        'manualOutNetEnergy',
        'distPOutNetEnergy',
        'distFOutNetEnergy',
        'hubOutNetEnergy',
        'reeOutNetEnergy',
        'atrOutNetEnergy',
        'awsOutNetEnergy',
        'inmeclosOutNetEnergy',
        'closePeriod',
        'manualIncreEnergy',
        'quarterHourlyIncreEnergy',
        'atrIncreEnergy',
        'mixedIncreEnergy',
        'hourlyIncreEnergy',
        'hubIncreEnergy',
        'distributorIncreEnergy',
        'awsBestIncreEnergy',
        'atrPowerExcess',
        'bestPowerExcess',
        'forecastHours',
        'forecastEnergy',
    ];

    isPaginating: boolean = false;
    isLiquidationPage: boolean = false;
    isInDashboard: boolean = false;
    isCupsLimit: boolean = false;
    // Array de peticiones  que están siendo lanzadas cada 30 segundos para comprobar el estado de un proceso pendiente
    activeRequests: { cups: string; closeDateBegin: string; closeDateEnd: string }[] = [];
    userRoles: string[] = [];
    isCupsModalVisible: boolean = false;
    isCupsBlockUnblockDisabled: boolean = true;
    filteredDates: string = "";
    cupFilterCupsGroup: string = "";
    event: any;

    constructor(
        public readonly _headerTitleService: HeaderTitleService,
        private _globalService: GlobalService,
        private _cuadroMandosService: CuadroMandosService,
        private _loginService: LoginService,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _maestroService: MaestroService,
    ) {}

    ngOnInit(): void {
        this._checkUserAccess();
        this._headerTitleService.currentHeaderTranslationCode = 'DASHBOARD_TITLE';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
        this.isInDashboard = true;
        this.dataHeaders = [];
        this.allDataHeaders = [];
        
        let headersList = sessionStorage.getItem('headers');
        if (headersList) {
            this.allDataHeaders = JSON.parse(headersList).data;
            this.dataHeaders = this.allDataHeaders;
        } else {
            this._globalService.getHeaders().subscribe((headers) => {
                this.dataHeaders = [];
                this.allDataHeaders = [];
                this.allDataHeaders = headers.data;
                this.dataHeaders = this.allDataHeaders;
            });
        }
    }

    ngOnDestroy(): void {
        this.isInDashboard = false;
    }    

    /**
     * Resetea valores por defecto y carga todos los datos para la tabla de resultados.
     * Rellena por defecto el Resumen.
     */
    loadTable($event: any, cupsRefreshing?: string): void {
        this.event = $event;
        //TODO: add currentNumPage
        this.dataBusiness = [];
        this.globalDataHeaders = undefined;
        this.globalDataBusinessPreloaded = undefined;
        this.totalRegisters = 0;
        this.numPage = 1;
        this.dataBusiness = [];
        this.dataHeaders = [];
        this.search = !cupsRefreshing ? $event[2].toString() : cupsRefreshing;
        this.filteredDates = $event[2][4];
        // Si está activo, se irán haciendo llamadas para montar el tab "Resumen"
        this.chargeSummary = true;
        this.visibilityPagination = true;
        this.fixSpinner = false;
        this.noData = false;
        this.spinnerActive = true;
        // Primero se empieza con infoTecnica, que es la base para generar todos los datos.
        this.populateSelectedSection(DashboardSections.TECHNICAL_INFO);
    }

    /**
     * Función para hacer el modal visible cuando se pulsa
     */
    onClickOpenBulkBlockUnBlockModal(): void {
        this.isBulkBlockUnblockModalVisible = true;
    }

    /**
     * Función que resetea los valores del modal al cerrarlo
     */
    onClickCloseBulkBlockUnblockModal(): void {
        this.deleteBulkBlockUnblockModalValues();
        this.cupFilterBlockGroup.nativeElement.value = '';
        this.bulkSelection = false;
    }

    /**
     * Función auxiliar de onClickCloseBulkBlockUnblockModal
     */
    deleteBulkBlockUnblockModalValues(): void {
        this.isBulkBlockUnblockModalVisible = false;
        this.isBulkBlockUnblockDisabled = true;
        this.monthFilterBlockGroup = undefined;
        this.yearFilterBlockGroup = undefined;
    }

    /**
     * Función para hacer el modal visible cuando se pulsa
     */
    onClickOpenBulkBestMeasureCloseModal(): void {
        this.isBulkBestMeasureCloseModalVisible = true;
    }

    /**
     * Función que resetea los valores del modal al cerrarlo
     */
    onClickCloseSelectBestMeasureCloseModal(): void {
        this.onClickDeleteBestMeasureModalValues();
        this.bulkSelectionSelected = true;
        this.isBulkBestMeasureCloseModalVisible = false;
        this.bulkSelection = false;
    }

    /**
     * Función auxiliar de onClickCloseBulkBlockUnblockModal
     */
    onClickDeleteBestMeasureModalValues(): void {
        this.monthBulkSelection = undefined;
        this.yearBulkSelection = undefined;
        this.matMonthBulkSelection.value = undefined;
        this.matYearBulkSelection.value = undefined;
        this.originBulkMeasureSelection.value = undefined;
        this.originBulkCloseSelection.value = undefined;
        this.isOriginBulkMeasureSelectionDisabled = false;
        this.isOriginBulkCloseSelectionDisabled = false;
        this.cupFilterBulkGroup.nativeElement.value = '';
    }

    /**
     * Función para hacer el modal visible cuando se pulsa
     */
    onClickOpenCupsBlockUnBlockModal(): void {
        this.isCupsModalVisible = true;
    }

    /**
     * Función que resetea los valores del modal al cerrarlo
     */
    onClickCloseCupsModal(): void {
        this.cupFilterCupsGroup = '';
        this.isCupsModalVisible = false;
        this.isCupsBlockUnblockDisabled = true;
    }

    /**
     * Bucle de llamadas para cargar las tablas.
     * Por defecto, cuando se filtra, se carga por primera vez la tab "Resumen", que es la suma de todas las demás.
     * Se encadenan llamadas solo para el resumen.
     */
    protected populateSelectedSection(idTab: string, calledFromChild: boolean = false): void {
        //Cuando se hace click en uno de los tabs se llega aquí, y se procede en función del botón seleccionado.
        if (calledFromChild) {
            this.activeColumn = this._cuadroMandosService.activeColumn;
            if (idTab === DashboardSections.SETTLEMENT) {
                this.isLiquidationPage = true;
            } else {
                this.isLiquidationPage = false;
            }
        } else {
            this.activeColumn = idTab;
        }
        if (this.activeColumn === DashboardSections.TECHNICAL_INFO) {
            this._populateTechnicalInfoSection();
        } else if (this.activeColumn === DashboardSections.CHECK) {
            this._populateChecksInfoSection();
        } else if (this.activeColumn === DashboardSections.MEASURES) {
            this._populateMeasuresInfoSection();
        } else if (this.activeColumn === DashboardSections.CLOSES) {
            this._populateClosesInfoSection();
        } else if (this.activeColumn === DashboardSections.BEST_MEASURE_CLOSE) {
            this._populateBestMeasureAndCloseInfoSection();
        } else if (this.activeColumn === DashboardSections.SETTLEMENT) {
            this._populateSettlementInfoSection();
        } else if (this.activeColumn === DashboardSections.SUMMARY) {
            this._populateSummary();
        }
        this.globalDataBusinessPreloaded = this.globalDataBusiness;
        this._startRecursiveRequests();
        this.columns = [];
        this.dataHeaders.forEach((header: IColumnsTable) => {
            if (header.isVisible) {
                this.columns.push(header.id);
            }
        });
    }

    protected resetTable(): void {
        //Se resetean las variables al hacer click en botón "Borrar"
        this.numPage = 1;
        this.noData = false;
        this.fixSpinner = false;
        this.dataHeaders = undefined;
        this.chargeSummary = true;
        this.fixSpinner = false;
        this.tabMejorMedidaCierre = false;
        this.visibilityPagination = true;
    }

    protected onPageChangeUpdateRegistersAndPaginationOptions(event: any): void {
        this.numPage = event.pageIndex + 1; //Se aumenta nº de pág.
        this.chargeSummary = true;
        this.visibilityPagination = true;
        this.fixSpinner = false;
        this.spinnerActive = true;
        this.isPaginating = true;
        this.populateSelectedSection(DashboardSections.TECHNICAL_INFO, false);
    }

    protected onClickPopulateColumnHeaders(columnId: string | number, dataHeadersForColumns: any[]): void {
        const indexHeaderToChange: number = dataHeadersForColumns.findIndex((header: any) => header.id === columnId);
        if (indexHeaderToChange !== -1) {
            if (dataHeadersForColumns[indexHeaderToChange].isVisible === true) {
                dataHeadersForColumns[indexHeaderToChange].isVisible = false;
            } else {
                dataHeadersForColumns[indexHeaderToChange].isVisible = true;
            }
        }
        this.dataHeaders_valueSelect = dataHeadersForColumns;
        this.columns = [];
        dataHeadersForColumns.forEach((header: IColumnsTable) =>
            header.isVisible ? this.columns.push(header.id) : ''
        );
    }

    protected onClickShowColumnsSelector(): void {
        //Mostrar-ocultar Etiqueta Span "Columnas"
        this.element = !this.element;
        //Mostrar-ocultar select de columnas
        this.ocultar = !this.ocultar;
    }

    protected getTableDataHeaders(): IColumnsTable[] {
        if (this.dataHeadersForColumns !== this.dataHeaders) {
            this.dataHeadersForColumns = this.dataHeaders;
            this.dataHeaders_valueSelect = [];
            this.dataHeaderChange = true;
        }
        if (this.dataHeaderChange === true) {
            for (let i = 0; i < this.dataHeaders?.length; i++) {
                if (
                    (this.dataHeaders[i].isVisible === true || this.dataHeaders[i].isVisible == undefined) &&
                    this.dataHeaders[i].isVisible !== false
                ) {
                    this.dataHeaders_valueSelect.push(this.dataHeaders[i]);
                }
            }
            this.columns = [];
            for (let i = 0; i < this.dataHeaders_valueSelect.length; i++) {
                let columnId;
                columnId = this.dataHeaders_valueSelect[i].id;
                this.columns.push(columnId);
            }
        }
        this.dataHeaderChange = false;
        return this.dataHeaders;
    }

    protected getHeadersValue(): IColumnsTable[] {
        if (this.selectColumns.value) {
            let values: any[] = this.selectColumns.value.filter((option: IColumnsTable) => option.isVisible);
            this.selectColumns.setValue(values);
        }
        return this.dataHeaders_valueSelect.filter((header: IColumnsTable) => header.isVisible);
    }

    /**
     * Función que activa, o no, una bandera con la que se consulta si los cups que llegan en un elemento de tipo
     * textArea son válidos en cuanto al límite de estos. No puede haber más de 100 cups.
     * @param textArea Texto con los cups
     */
    protected checkCupsLength(textArea: any): void {
        const cups: any[] = textArea.value
            .replaceAll('\n', ',')
            .split(',')
            .filter((value: string) => value !== '');
        if (cups.length > 100) {
            this.isCupsLimit = true;
        } else {
            this.isCupsLimit = false;
        }
    }

    /**
     * Función encargada de retornar el estado del botón aceptar del modal de seleccion masiva.
     * @returns Si el botón del modal debe permanecer deshabilitado
     */
    protected isAcceptButtonDisabled(): boolean {
        return !(this.monthBulkSelection &&
            this.yearBulkSelection &&
            this.cupFilterBulkGroup.nativeElement.value !== '' &&
            !this.isCupsLimit &&
            ((this.originBulkMeasureSelection.value !== '' && this.originBulkMeasureSelection.value !== undefined) ||
                (this.originBulkCloseSelection.value !== '' && this.originBulkCloseSelection.value !== undefined)));
    }

    /**
     * Función encargada de retornar el estado del botón aceptar del modal de ejecución de cups.
     * @returns Si el botón del modal debe permanecer deshabilitado
     */
    protected isAcceptCupsButtonDisabled(): boolean {
        return !(this.cupFilterCupsGroup !== '' && !this.isCupsLimit);
    }

    protected disableMeasuresOrClosesSelectInModal(selectName: string): void {
        if (selectName === 'measure') {
            this.originBulkMeasureSelection.value = undefined;
            this.isOriginBulkMeasureSelectionDisabled = false;
            this.isOriginBulkCloseSelectionDisabled = false;
        } else if (selectName === 'close') {
            this.originBulkCloseSelection.value = undefined;
            this.isOriginBulkCloseSelectionDisabled = false;
            this.isOriginBulkMeasureSelectionDisabled = false;
        }
    }

    protected checkIfIsNumber(isNumber: any) {
        if (typeof isNumber === 'number') {
            const formatedNumber: any = this._formatNumber(isNumber.toString());
            return formatedNumber !== '0' && formatedNumber !== 0 ? formatedNumber : this.NULL_VALUE_SIGN;
        } else {
            return isNumber !== '0' && isNumber !== 0 ? isNumber : this.NULL_VALUE_SIGN;
        }
    }

    protected transformDate(date: string, largeFormat?: boolean) {
        if (date.includes('/')) {
            date = date.replaceAll('/', '-');
        }
        const datePipe = new DatePipe('en-US');
        let dates = new Date(date);
        let formatDate;
        if (isNaN(dates.getTime())) {
            formatDate = '-';
        } else {
            if (largeFormat) {
                formatDate = datePipe.transform(
                    dates,
                    `${this._headerTitleService.currentLang !== 'en' ? 'dd/MM/yyyy' : 'yyyy/MM/dd'} HH:mm:ss`
                );
            } else {
                formatDate = datePipe.transform(
                    dates,
                    `${this._headerTitleService.currentLang !== 'en' ? 'dd/MM/yyyy' : 'yyyy/MM/dd'}`
                );
            }
        }
        return formatDate;
    }

    protected setStatusClass(status: any = []): string {
        if (status === 'ok' || status === 'OK') {
            return 'verde';
        } else if (status === 'ko' || status === 'KO') {
            return 'red';
        } else {
            return 'black';
        }
    }

    protected onChangeSetSelectedOption(event: { id: any; value: any }): void {
        if (event.id === 'monthFilterBlockGroup') {
            this.monthFilterBlockGroup = event.value;
        }
        if (event.id === 'yearFilterBlockGroup') {
            this.yearFilterBlockGroup = event.value;
        }
        if (this.yearFilterBlockGroup && this.monthFilterBlockGroup) {
            this.isBulkBlockUnblockDisabled = false;
        }
        if (event.id === 'monthBulkSelection') {
            this.monthBulkSelection = event.value;
        }
        if (event.id === 'yearBulkSelection') {
            this.yearBulkSelection = event.value;
        }
        if (event.id === 'originBulkMeasureSelection') {
            this.bestMeasureBulk = event.value;
            this.isOriginBulkCloseSelectionDisabled = true;
        }
        if (event.id === 'originBulkCloseSelection') {
            this.bestCloseBulk = event.value;
            this.isOriginBulkMeasureSelectionDisabled = true;
        }
        if (
            this.monthBulkSelection &&
            this.yearBulkSelection &&
            (this.originBulkMeasureSelection || this.originBulkCloseSelection)
        ) {
            this.bulkSelectionSelected = false;
        }
    }

    protected onClickChangeBestMeasure(origin: any, cups: any): void {
        const indexRegister: number = this.globalDataBusiness.findIndex((register: any) => register.seq === cups.seq);
        const closeDateBeginBestMeasure =
            cups.closeDateBegin !== undefined
                ? cups.closeDateBegin
                : this.globalDataBusiness[indexRegister].closeDateBegin;
        const closeDateEndBestMeasure =
            cups.closeDateEnd !== undefined ? cups.closeDateEnd : this.globalDataBusiness[indexRegister].closeDateEnd;
        if (closeDateBeginBestMeasure !== undefined && closeDateEndBestMeasure !== undefined) {
            this.dataBusiness[indexRegister].executionMeasure = true;
            this.globalDataBusiness[indexRegister].executionMeasure = true;
            this.globalDataBusinessPreloaded[indexRegister].executionMeasure = true;
            const defaultMeasure = this.selectedOriginBestMeasure.filter((measure) => measure.seq === cups.seq);
            if (origin === undefined) {
                origin =
                    cups.origenBestMeasure !== undefined && cups.origenBestMeasure
                        ? cups.origenBestMeasure
                        : cups.awsOrigin;
            } else if (origin.includes('AWS')) {
                origin = cups.awsOrigin ? cups.awsOrigin : origin;
            }
            this._cuadroMandosService
                .updateBestMeasure(cups.cups, closeDateBeginBestMeasure, closeDateEndBestMeasure, origin)
                .subscribe({
                    next: (changeBestMeasureResponse: any) => {
                        if (
                            changeBestMeasureResponse &&
                            changeBestMeasureResponse.dashboardList &&
                            changeBestMeasureResponse.dashboardList.length > 0
                        ) {
                            if (changeBestMeasureResponse.dashboardList[0].status === 'KO') {
                                this._resetBestMeasureSelect(cups, defaultMeasure);
                                this._loadNotification('ERROR_CHANGING_BEST_MEASURE');
                            } else {
                                this._updateBestMeasureSelect(cups, origin);
                                this._startRecursiveRequest(cups, 'measure');
                                this._loadNotification('BEST_MEASURE_SUCCESSFULLY_CHANGED');
                            }
                        }
                    },
                    error: (error: any) => {
                        this._resetBestMeasureSelect(cups, defaultMeasure);
                        this._loadNotification('ERROR_CHANGING_BEST_MEASURE');
                    },
                });
        }
    }

    protected onClickChangeBestClose(origin: any, cups: any): void {
        const indexRegister: number = this.globalDataBusiness.findIndex((register: any) => register.seq === cups.seq);
        const closeDateBeginBestClose =
            cups.closeDateBegin !== undefined && cups.closeDateBegin !== null
                ? cups.closeDateBegin
                : this.globalDataBusiness[indexRegister].closeDateBegin;
        const closeDateEndBestClose =
            cups.closeDateEnd !== undefined && cups.closeDateEnd !== null
                ? cups.closeDateEnd
                : this.globalDataBusiness[indexRegister].closeDateEnd;
        if (closeDateBeginBestClose !== undefined && closeDateEndBestClose !== undefined) {
            this.dataBusiness[indexRegister].executionClose = true;
            this.globalDataBusiness[indexRegister].executionClose = true;
            this.globalDataBusinessPreloaded[indexRegister].executionClose = true;
            const defaultClose = this.selectedOriginBestClose.filter((close) => close.seq === cups.seq);
            if (origin === 'AWS' || origin === undefined) {
                origin =
                    cups.bestCloseOrigin !== undefined && cups.bestCloseOrigin ? cups.bestCloseOrigin : cups.bestOrigin;
            }
            this._cuadroMandosService
                .updateBestClose(cups.cups, closeDateBeginBestClose, closeDateEndBestClose, origin)
                .subscribe({
                    next: (changeBestCloseResponse: any) => {
                        if (
                            changeBestCloseResponse &&
                            changeBestCloseResponse.closeList &&
                            changeBestCloseResponse.closeList.length > 0
                        ) {
                            if (changeBestCloseResponse.closeList[0].status === 'KO') {
                                this._resetBestCloseSelect(cups, defaultClose);
                                this._loadNotification('ERROR_CHANGING_BEST_CLOSE');
                            } else {
                                this._startRecursiveRequest(cups, 'close');
                                this._loadNotification('BEST_CLOSE_SUCCESSFULLY_CHANGED');
                            }
                            cups.originBestClose = this.globalDataBusinessPreloaded.filter(
                                (register: any) => register.seq === changeBestCloseResponse.closeList[0].seq
                            )[0].originBestClose;
                        }
                    },
                    error: (error: any) => {
                        this._resetBestCloseSelect(cups, defaultClose);
                        this._loadNotification('ERROR_CHANGING_BEST_CLOSE');
                    },
                });
        }
    }

    /**
     * Función que ejecuta las operaciones del modal de bloqueo y desbloqueo masivo.
     * @param month Mes elegido en el modal.
     * @param year Año elegido en el modal.
     * @param action Confirmación de bloqueo o desbloqueo.
     * @param cupsList Lista de los cups proporcionada por el elemento html textArea asociado.
     */
    protected onClickUpdateBulkBlockUnblock(month: any, year: any, action: boolean, cupsList: any): void {
        const cups: string[] = cupsList.split('\n');
        for (let i = 0; i < this.dataBusiness.length; i++) {
            if (
                !this.dataBusiness[i].executionBlockUnblock ||
                this.dataBusiness[i].executionBlockUnblock === null ||
                this.dataBusiness[i].executionBlockUnblock === undefined
            ) {
                this.dataBusiness[i].executionBlockUnblock = false;
                this.globalDataBusiness[i].executionBlockUnblock = false;
                this.globalDataBusinessPreloaded[i].executionBlockUnblock = false;
            }
            if (cups.includes(this.dataBusiness[i].cups)) {
                this.dataBusiness[i].executionBlockUnblock = true;
                this.globalDataBusiness[i].executionBlockUnblock = true;
                this.globalDataBusinessPreloaded[i].executionBlockUnblock = true;
            }
        }
        cupsList = cupsList ? cupsList.replaceAll('\n', ',') : 'all';
        this.month = Number(MonthListEnum[month]) < 10 ? `0${MonthListEnum[month]}` : `${MonthListEnum[month]}`;
        this.deleteBulkBlockUnblockModalValues();
        this._cuadroMandosService.updateBlockFlagBulk(cupsList, year, this.month, action).subscribe({
            next: (bulkBlock: any) => {
                if (bulkBlock && bulkBlock.dashboardList) {
                    this._checkBulkBlockUnblockResults(bulkBlock.dashboardList, action);
                } else {
                    this._loadNotification(action ? 'CUPS_BLOCKING_EMPTY' : 'CUPS_UNBLOCKING_EMPTY');
                }
            },
            error: (error: any) => {
                this._loadNotification(action ? 'ERROR_BLOCKING_CUPS' : 'ERROR_UNBLOCKING_CUPS');
            },
            complete: () => {
                for (let i = 0; i < this.dataBusiness.length; i++) {
                    if (cups.includes(this.dataBusiness[i].cups)) {
                        this.dataBusiness[i].executionBlockUnblock = false;
                        this.globalDataBusiness[i].executionBlockUnblock = false;
                        this.globalDataBusinessPreloaded[i].executionBlockUnblock = false;
                    }
                }
            },
        });
    }

    /**
     * Función que ejecuta las operaciones del modal de selección masiva.
     * @param cupsList Lista de cups venida desde el elemento html textArea asociado.
     * @param month Mes elegido en el modal.
     * @param year Año elegido en el modal.
     * @param measureToChange Parámetro opcional medida.
     * @param closeToChange Parámetro opcional cierre.
     */
    protected onClickBulkUpdateBestMeasureOrClose(
        cupsList?: any,
        month?: any,
        year?: any,
        measureToChange?: any,
        closeToChange?: any
    ): void {
        cupsList = cupsList ? cupsList.replaceAll('\n', ',') : 'all';
        this.month = Number(MonthListEnum[month]) < 10 ? `0${MonthListEnum[month]}` : `${MonthListEnum[month]}`;
        let initDate = this._getFirstAndLastDayOfMonthInStringDateFormat(year, this.month).firstDay;
        let endDate = this._getFirstAndLastDayOfMonthInStringDateFormat(year, this.month).lastDay;
        this.onClickCloseSelectBestMeasureCloseModal();

        if (measureToChange && measureToChange !== '') {
            this._cuadroMandosService.updateBestMeasure(cupsList, initDate, endDate, this.bestMeasureBulk).subscribe({
                next: (updateBestMeasureBulkResponse) => {
                    if (updateBestMeasureBulkResponse && updateBestMeasureBulkResponse.dashboardList) {
                        this._checkBulkUpdateBestMeasureCloseResults(
                            updateBestMeasureBulkResponse.dashboardList,
                            'measure',
                            measureToChange
                        );
                        let isAnyValueAdded: boolean = false;
                        updateBestMeasureBulkResponse.dashboardList.forEach((resp: any) => {
                            if (!isAnyValueAdded && resp.status === 'OK') {
                                this._startRecursiveRequest(
                                    this.dataBusiness.filter((register: any) => register.seq === resp.seq)[0],
                                    'measure'
                                );
                                isAnyValueAdded = true;
                            }
                        });
                    } else {
                        this._loadNotification('CUPS_CHANGING_BEST_MEASURE_EMPTY');
                    }
                },
                error: (updateBestMeasureBulkError: any) => {
                    this._loadNotification('ERROR_BULK_CHANGING_BEST_MEASURE');
                },
                complete: () => {},
            });
        } else if (closeToChange && closeToChange !== '') {
            this._cuadroMandosService.updateBestClose(cupsList, initDate, endDate, this.bestCloseBulk).subscribe({
                next: (updateBestCloseBulkResponse) => {
                    if (updateBestCloseBulkResponse && updateBestCloseBulkResponse.closeList) {
                        this._checkBulkUpdateBestMeasureCloseResults(
                            updateBestCloseBulkResponse.closeList,
                            'close',
                            closeToChange
                        );
                    } else {
                        this._loadNotification('CUPS_CHANGING_BEST_CLOSE_EMPTY');
                    }
                },
                error: (updateBestCloseBulkError: any) => {
                    this._loadNotification('ERROR_BULK_CHANGING_BEST_CLOSE');
                },
                complete: () => {},
            });
        }
    }

    /**
     * Función que ejecuta la acción del modal de los cups.
     * @param cupsList La lista de cups proporcionada en el elemento html textArea.
     */
    protected async onClickExecuteCupsOrClose(
    ): Promise<void> {
        this._loadNotification('STARTING_EXECUTION');
        var cupsList = this.cupFilterCupsGroup ? this.cupFilterCupsGroup.split('\n') : ['all'];
        
        if (cupsList.length <= 100) {
            this.onClickCloseCupsModal();

            var dataBusinessLocal: any[] = [];
            this.dataBusiness.forEach((register:any)=>{
                if (cupsList.includes(register.cups)) {
                    dataBusinessLocal.push(register);
                }
            });

            try {
                dataBusinessLocal = await this._filterNotBlockedCups(dataBusinessLocal);
    
                if (dataBusinessLocal.length < cupsList.length) {
                    var badCups: string[] = [];
                    const cupsInData = dataBusinessLocal.map((register:any) => register.cups);
                    cupsList.filter((cups:string) => !cupsInData.includes(cups)).forEach((cups:string)=> badCups.push(cups));
                    this._loadNotification('NOT_VALID_CUPS',badCups.toString());
                }
                let formattedBody = this._populateBodyData(cupsList);
                (async () => { 
                    this._cuadroMandosService.updateBlockFlagCups(formattedBody).subscribe({
                        next: (sendedData: any) => {
                            const seqInData = dataBusinessLocal.map((register:any) => register.seq);
                            for (let i = 0; i < this.dataBusiness.length; i++) {
                                const register = this.dataBusiness[i];
                                if (seqInData.includes(register.seq)) {
                                    this.dataBusiness[i].executionCups = true;
                                    this.globalDataBusiness[i].executionCups = true;
                                    this.globalDataBusinessPreloaded[i].executionCups = true;
        
                                    let cups = register.cups;
                                    let closeDateBegin = register.closeDateBegin;
                                    let closeDateEnd = register.closeDateEnd;
                                    this._startRecursiveRequest({cups, closeDateBegin, closeDateEnd}, 'close');
                                }
                            }
                        },
                        error: (error: any) => {
                            console.log('en error');
                            this._loadNotification('ERROR_CUPS_EXECUTION');
                        },
                        complete: () => {},
                    });
                })();
                
            } catch (error) {
                console.log('en catch');
                this._loadNotification('ERROR_CUPS_EXECUTION');
            }
            

        } else {
            this._loadNotification('SO_MUCH_CUPS_TO_BLOCK');
        }

    }

    protected changeBlockFlag(event: any, emp: any, seqBlockFlag: boolean): void {
        if (
            emp.executionBlockUnblock !== null &&
            !emp.executionBlockUnblock &&
            !emp.executionMeasure &&
            !emp.executionClose &&
            !emp.executionCups
        ) {
            const indexRegister: number = this.dataBusiness.findIndex((register: any) => register.seq === emp.seq);
            this.dataBusiness[indexRegister].executionBlockUnblock = true;
            this.globalDataBusiness[indexRegister].executionBlockUnblock = true;
            this.globalDataBusinessPreloaded[indexRegister].executionBlockUnblock = true;
            event.preventDefault();
            this._cuadroMandosService.updateBlockFlag(emp.seq, seqBlockFlag).subscribe({
                next: (blockFlag: any) => {
                    if (blockFlag && blockFlag.dashboardList) {
                        blockFlag.dashboardList.forEach((responseCups: any) => {
                            this.globalDataBusiness.forEach((cups: any) => {
                                if (cups.seq === emp.seq) {
                                    if (responseCups.status === 'KO') {
                                        cups.blockedFlag = !seqBlockFlag;
                                        this._loadNotification(
                                            seqBlockFlag ? 'ERROR_BLOCKING_CUPS' : 'ERROR_UNBLOCKING_CUPS'
                                        );
                                    } else {
                                        cups.blockedFlag = seqBlockFlag;
                                        this._loadNotification(
                                            seqBlockFlag ? 'CUPS_BLOCKING_SUCCESSFUL' : 'CUPS_UNBLOCKING_SUCCESSFUL'
                                        );
                                    }
                                }
                            });
                        });
                    }
                },
                error: (error: any) => {
                    this.globalDataBusiness.forEach((cups: any) => {
                        if (cups.seq === emp.seq) {
                            cups.blockedFlag = !seqBlockFlag;
                        }
                    });
                    this._loadNotification(seqBlockFlag ? 'ERROR_BLOCKING_CUPS' : 'ERROR_UNBLOCKING_CUPS');
                },
                complete: () => {
                    this.dataBusiness[indexRegister].executionBlockUnblock = false;
                    this.globalDataBusiness[indexRegister].executionBlockUnblock = false;
                    this.globalDataBusinessPreloaded[indexRegister].executionBlockUnblock = false;
                },
            });
        }
    }

    protected launchReport($event: any): void {
        const reportFilters = {
            year: $event.year,
            month: $event.month,
            clientType: $event.clientType,
            cups: $event.cups,
            instalation: $event.instalation,
            accountId: $event.accountId,
            bestDefinitiveMeasureVsReeStatus: $event.bestDefinitiveMeasureVsReeStatus,
        };
        const dashboardReportBody = {
            reportCode: 'GM0009',
            name: 'reportingGmDashboard',
            filters: JSON.stringify(reportFilters),
        };
        this._cuadroMandosService.launchReport(dashboardReportBody).subscribe({
            next: (reportResponse: any) => {
                this._loadNotification('REPORT_LAUNCH_SUCCESSFUL');
            },
            error: (error: any) => {
                this._loadNotification('REPORT_LAUNCH_ERROR', error);
            },
        });
    }

    protected addTextAreaCups(texto: any): void {
        this.checkCupsLength(texto)
        if (!this.isCupsLimit) {
            this.cupFilterCupsGroup = texto.value;
        }
    }

    private _checkBulkBlockUnblockResults(bulkBlockList: any, action: boolean): void {
        let blockingCupsErrorCounter: string[] = [];
        bulkBlockList.forEach((responseCups: any) => {
            if (responseCups.status === 'KO') {
                blockingCupsErrorCounter.push(responseCups.cups);
            }
        });
        if (blockingCupsErrorCounter.length > 0) {
            if (blockingCupsErrorCounter.length <= 100) {
                let errorMessage = '';
                let errorLiteral = action ? 'PAGE.MODALS.ERROR_BLOCK_CUPS_LIST' : 'PAGE.MODALS.ERROR_UNBLOCK_CUPS_LIST';
                errorMessage = this._translateService.instant(errorLiteral);
                let blockingCupsErrorMessage: string = errorMessage + blockingCupsErrorCounter.join(', ');
                this._loadNotification(undefined, blockingCupsErrorMessage, 10000);
            } else {
                let errorMessage = '';
                let errorLiteral = action
                    ? 'PAGE.MODALS.ERROR_BLOCK_CUPS_QUANTITY'
                    : 'PAGE.MODALS.ERROR_UNBLOCK_CUPS_QUANTITY';
                errorMessage = this._translateService.instant(errorLiteral, {
                    cupsQuantity: blockingCupsErrorCounter.length,
                });
                this._loadNotification(errorLiteral, errorMessage);
            }
        } else {
            this._loadNotification(action ? 'CUPS_BLOCKING_SUCCESSFUL' : 'CUPS_UNBLOCKING_SUCCESSFUL');
        }

        this.globalDataBusiness.forEach((cups: any) => {
            bulkBlockList.forEach((blockCups: any) => {
                if (blockCups.seq === cups.seq) {
                    if (!blockingCupsErrorCounter.includes(cups.cups)) {
                        cups.blockedFlag = action;
                    }
                }
            });
        });
    }

    private _resetBestCloseSelect(cups: any, defaultClose: any[]): void {
        for (let i = 0; i < this.dataBusiness.length; i++) {
            if (this.dataBusiness[i].seq === cups.seq) {
                this.dataBusiness[i].executionClose = false;
                this.globalDataBusiness[i].closeDateBegin = this.dataBusiness[i].closeDateBegin;
                this.globalDataBusiness[i].closeDateEnd = this.dataBusiness[i].closeDateEnd;
            }
        }
    }

    private _updateBestMeasureSelect(cups: any, originBestMeasure: any): void {
        this.selectedOriginBestMeasure.forEach((measure) => {
            if (measure.seq === cups.seq) {
                measure.bestMeasure = originBestMeasure;
            }
        });
        this.dataBusiness.forEach((data) => {
            if (data.seq === cups.seq) {
                data.origenBestMeasure = originBestMeasure;
            }
        });
    }

    private _resetBestMeasureSelect(cups: any, defaultMeasure: any[]): void {
        this.dataBusiness.forEach((data) => {
            if (data.seq === cups.seq) {
                data.origenBestMeasure = defaultMeasure.length > 0 ? defaultMeasure[0].bestMeasure : data.awsOrigin;
                data.executionMeasure = false;
            }
        });
    }

    private _checkBulkUpdateBestMeasureCloseResults(
        bulkBestMeasureCloseList: any,
        updateType: string,
        action: any
    ): void {
        let updatingBestMeasureCloseErrorCounter: string[] = [];
        bulkBestMeasureCloseList.forEach((responseCups: any) => {
            if (responseCups.status === 'KO') {
                updatingBestMeasureCloseErrorCounter.push(responseCups.cups);
            }
        });
        if (updatingBestMeasureCloseErrorCounter.length > 0) {
            if (updatingBestMeasureCloseErrorCounter.length <= 100) {
                let errorMessage = '';
                let errorLiteral =
                    updateType === 'measure'
                        ? 'PAGE.MODALS.ERROR_UPDATE_BEST_MEASURE_CUPS_LIST'
                        : 'PAGE.MODALS.ERROR_UPDATE_BEST_CLOSE_CUPS_LIST';
                errorMessage = this._translateService.instant(errorLiteral);
                let updateCupsErrorMessage: string = errorMessage + updatingBestMeasureCloseErrorCounter.join(', ');
                this._loadNotification(undefined, updateCupsErrorMessage, 10000);
            } else {
                let errorMessage = '';
                let errorLiteral = action
                    ? 'PAGE.MODALS.ERROR_UPDATE_BEST_MEASURE_CUPS_QUANTITY'
                    : 'PAGE.MODALS.ERROR_UPDATE_BEST_CLOSE_QUANTITY';
                errorMessage = this._translateService.instant(errorLiteral, {
                    cupsQuantity: updatingBestMeasureCloseErrorCounter.length,
                });
                this._loadNotification(errorLiteral, errorMessage);
            }
        } else {
            this._loadNotification(
                updateType === 'measure'
                    ? 'BEST_BULK_MEASURE_SUCCESSFULLY_CHANGED'
                    : 'BEST_BULK_CLOSE_SUCCESSFULLY_CHANGED'
            );
        }
    }

    private _checkUserAccess(): void {
        this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
        this.userRoles = this.userRoles.map((element: any) => element.trim());
        if (
            this.userRoles.findIndex((role) => role === 'MEDIDA') !== -1 ||
            this.userRoles.findIndex((role) => role === 'ADMIN') !== -1
        ) {
            this.validRole = true;
        } else {
            this.validRole = false;
        }
    }

    private _populateTechnicalInfoSection(): void {
        this.tabMejorMedidaCierre = false;
        this.selfSupplyList = [];
        this.dataBusiness = [];
        this.columns = [];

        if (!this.chargeSummary) {
            this.spinnerActive = true;
            this.fixSpinner = false;
            this.visibilityPagination = true;
        }
        this.dataHeaders = this.allDataHeaders;
        this.dataHeaders.forEach((element: any) => {
            if (element.name === 'dashboard_infoTecnica') {
                this.dataHeaders = element.headers;
            }
        });
        this.dataHeaders_valueSelect = [];
        this.dataHeaders_valueSelect = this.dataHeaders;
        this.dataHeadersForColumns = [];
        this.dataHeadersForColumns = this.dataHeaders;

        for (let i = 0; i < this.dataHeaders.length; i++) {
            let headerLabel = this.dataHeaders[i].id;
            this.columns.push(headerLabel);
        }
        if (this.search.includes('cups=(;)')) {
            this.search = this.search.replaceAll('cups=(;)', '');
        }
        this._cuadroMandosService.getTechnicalInfo(this.search, 5, this.numPage).subscribe((dashboard_infoTecnica) => {
            this.totalPages = dashboard_infoTecnica.page.totalPages;
            this.totalRegisters = dashboard_infoTecnica.page.totalElements;
            this.seqList = [];
            this.cupListFilter = [];
            this.dateEndFilter = [];
            this.dateInitFilter = [];

            if (dashboard_infoTecnica && dashboard_infoTecnica.data && dashboard_infoTecnica.data.length > 0) {
                for (let i = 0; i < dashboard_infoTecnica.data.length; i++) {
                    this.seqList.push(dashboard_infoTecnica.data[i].seq);
                    this.selfSupplyList.push({
                        seq: dashboard_infoTecnica.data[i].seq,
                        selfSupply: dashboard_infoTecnica.data[i].selfsupplyType,
                    });
                    this.cupListFilter.push(dashboard_infoTecnica.data[i].cups);
                    this.dateInitFilter.push(dashboard_infoTecnica.data[i].closeDateBegin);
                    this.dateEndFilter.push(dashboard_infoTecnica.data[i].closeDateEnd);
                }
                this.dateInitFilter.sort();
                this.dateInitFilter = this.dateInitFilter[0];
                this.dateEndFilter.sort();
                this.dateEndFilter = this.dateEndFilter.pop();
                this._cuadroMandosService
                    .getDropdownBestClose(
                        this.cupListFilter.join(`,`),
                        DashboardPeriodCodes.PERIOD_CODE_20,
                        this.dateInitFilter,
                        this.dateEndFilter
                    )
                    .subscribe({
                        next: (bestCloseResponse: any) => {
                            this.dataBusiness = dashboard_infoTecnica.data;
                            if (bestCloseResponse && bestCloseResponse.bestOriginList) {
                                bestCloseResponse.bestOriginList.forEach((origin: any) => {
                                    for (let i = 0; i < this.dataBusiness.length; i++) {
                                        if (this.dataBusiness[i].cups === origin.cups) {
                                            this.dataBusiness[i].definitiveBestCloseOrigin = origin.bestSystemOrigin;
                                            this.dataBusiness[i].originBestClose = origin.bestSystemOrigin;
                                            this.dataBusiness[i].origenBestMeasure =
                                                this.dataBusiness[i].bestOrigin !== undefined
                                                    ? this.dataBusiness[i].bestOrigin
                                                    : this.dataBusiness[i].origenBestMeasure;
                                            this.dataBusiness[i].executionBlockUnblock = false;
                                            this.dataBusiness[i].isBestMeasuresListAvailable =
                                                this.dataBusiness[i].executionMeasure ||
                                                this.dataBusiness[i].executionClose ||
                                                this.dataBusiness[i].executionCups
                                                    ? false
                                                    : this.dataBusiness[i].isBestMeasuresListAvailable;
                                            this.dataBusiness[i].isBestClosesListAvailable =
                                                this.dataBusiness[i].executionMeasure ||
                                                this.dataBusiness[i].executionClose ||
                                                this.dataBusiness[i].executionCups
                                                    ? false
                                                    : this.dataBusiness[i].isBestClosesListAvailable;
                                            if (this.globalDataBusiness && this.globalDataBusiness[i]) {
                                                this.globalDataBusiness[i].executionBlockUnblock = false;
                                                this.globalDataBusiness[i].executionMeasure =
                                                    this.globalDataBusiness[i].executionMeasure !== undefined
                                                        ? this.globalDataBusiness[i].executionMeasure
                                                        : this.dataBusiness[i].executionMeasure;
                                                this.globalDataBusiness[i].executionClose =
                                                    this.globalDataBusiness[i].executionClose !== undefined
                                                        ? this.globalDataBusiness[i].executionClose
                                                        : this.dataBusiness[i].executionClose;
                                                this.globalDataBusiness[i].executionMeasure =
                                                    this.globalDataBusiness[i].executionMeasure !== undefined
                                                        ? this.globalDataBusiness[i].executionMeasure
                                                        : this.globalDataBusinessPreloaded[i].executionMeasure;
                                                this.globalDataBusiness[i].executionClose =
                                                    this.globalDataBusiness[i].executionClose !== undefined
                                                        ? this.globalDataBusiness[i].executionClose
                                                        : this.globalDataBusinessPreloaded[i].executionClose;
                                                this.globalDataBusiness[i].isBestMeasuresListAvailable =
                                                    this.globalDataBusiness[i].executionMeasure ||
                                                    this.globalDataBusiness[i].executionClose ||
                                                    this.globalDataBusiness[i].executionCups
                                                        ? false
                                                        : this.globalDataBusiness[i].isBestMeasuresListAvailable;
                                                this.globalDataBusiness[i].isBestClosesListAvailable =
                                                    this.globalDataBusiness[i].executionMeasure ||
                                                    this.globalDataBusiness[i].executionClose ||
                                                    this.globalDataBusiness[i].executionCups
                                                        ? false
                                                        : this.globalDataBusiness[i].isBestClosesListAvailable;
                                                this.globalDataBusiness[i].closeDateBegin =
                                                    this.dataBusiness[i].closeDateBegin !== undefined
                                                        ? this.dataBusiness[i].closeDateBegin
                                                        : this.globalDataBusiness[i].closeDateBegin;
                                                this.globalDataBusiness[i].closeDateEnd =
                                                    this.dataBusiness[i].closeDateEnd !== undefined
                                                        ? this.dataBusiness[i].closeDateEnd
                                                        : this.globalDataBusiness[i].closeDateEnd;
                                                this.globalDataBusiness[i].originBestClose = origin.bestSystemOrigin;
                                                this.dataBusiness[i].origenBestMeasure =
                                                    this.globalDataBusiness[i].bestOrigin !== undefined
                                                        ? this.globalDataBusiness[i].bestOrigin
                                                        : this.dataBusiness[i].origenBestMeasure;
                                                this.dataBusiness[i].definitiveBestCloseOrigin =
                                                    this.globalDataBusiness[i].definitiveBestCloseOrigin !== undefined
                                                        ? this.globalDataBusiness[i].definitiveBestCloseOrigin
                                                        : this.dataBusiness[i].definitiveBestCloseOrigin;
                                                this.globalDataBusiness[i].definitiveBestCloseOrigin =
                                                    this.dataBusiness[i].definitiveBestCloseOrigin !== undefined
                                                        ? this.dataBusiness[i].definitiveBestCloseOrigin
                                                        : this.globalDataBusiness[i].definitiveBestCloseOrigin;
                                            }
                                            if (
                                                this.globalDataBusinessPreloaded &&
                                                this.globalDataBusinessPreloaded[i]
                                            ) {
                                                this.globalDataBusinessPreloaded[i].executionBlockUnblock = false;
                                                if (
                                                    this.globalDataBusinessPreloaded[i].executionMeasure === undefined
                                                ) {
                                                    this.globalDataBusinessPreloaded[i].executionMeasure =
                                                        this.globalDataBusiness[i].executionMeasure !== undefined
                                                            ? this.globalDataBusiness[i].executionMeasure
                                                            : this.dataBusiness[i].executionMeasure;
                                                }
                                                if (this.globalDataBusinessPreloaded[i].executionClose === undefined) {
                                                    this.globalDataBusinessPreloaded[i].executionClose =
                                                        this.globalDataBusiness[i].executionClose !== undefined
                                                            ? this.globalDataBusiness[i].executionClose
                                                            : this.dataBusiness[i].executionClose;
                                                }
                                                this.globalDataBusinessPreloaded[i].executionMeasure =
                                                    this.globalDataBusinessPreloaded[i].executionMeasure !== undefined
                                                        ? this.globalDataBusinessPreloaded[i].executionMeasure
                                                        : this.dataBusiness[i].executionMeasure;
                                                this.globalDataBusinessPreloaded[i].executionClose =
                                                    this.globalDataBusinessPreloaded[i].executionClose !== undefined
                                                        ? this.globalDataBusinessPreloaded[i].executionClose
                                                        : this.dataBusiness[i].executionClose;
                                                this.globalDataBusinessPreloaded[i].executionMeasure =
                                                    this.globalDataBusinessPreloaded[i].executionMeasure !== undefined
                                                        ? this.globalDataBusinessPreloaded[i].executionMeasure
                                                        : this.globalDataBusiness[i].executionMeasure;
                                                this.globalDataBusinessPreloaded[i].executionClose =
                                                    this.globalDataBusinessPreloaded[i].executionClose !== undefined
                                                        ? this.globalDataBusinessPreloaded[i].executionClose
                                                        : this.globalDataBusiness[i].executionClose;
                                                this.globalDataBusinessPreloaded[i].isBestMeasuresListAvailable =
                                                    this.globalDataBusinessPreloaded[i].executionMeasure ||
                                                    this.globalDataBusinessPreloaded[i].executionClose ||
                                                    this.globalDataBusinessPreloaded[i].executionCups
                                                        ? false
                                                        : this.globalDataBusinessPreloaded[i].isBestMeasuresListAvailable;
                                                this.globalDataBusinessPreloaded[i].isBestClosesListAvailable =
                                                    this.globalDataBusinessPreloaded[i].executionMeasure ||
                                                    this.globalDataBusinessPreloaded[i].executionClose ||
                                                    this.globalDataBusinessPreloaded[i].executionCups
                                                        ? false
                                                        : this.globalDataBusinessPreloaded[i].isBestClosesListAvailable;
                                                this.globalDataBusinessPreloaded[i].closeDateBegin =
                                                    this.dataBusiness[i].closeDateBegin !== undefined
                                                        ? this.dataBusiness[i].closeDateBegin
                                                        : this.globalDataBusinessPreloaded[i].closeDateBegin;
                                                this.globalDataBusinessPreloaded[i].closeDateEnd =
                                                    this.dataBusiness[i].closeDateEnd !== undefined
                                                        ? this.dataBusiness[i].closeDateEnd
                                                        : this.globalDataBusinessPreloaded[i].closeDateEnd;
                                                this.dataBusiness[i].definitiveBestCloseOrigin =
                                                    this.globalDataBusinessPreloaded[i].definitiveBestCloseOrigin !==
                                                    undefined
                                                        ? this.globalDataBusinessPreloaded[i].definitiveBestCloseOrigin
                                                        : this.dataBusiness[i].definitiveBestCloseOrigin;
                                                this.globalDataBusinessPreloaded[i].definitiveBestCloseOrigin =
                                                    this.dataBusiness[i].definitiveBestCloseOrigin !== undefined
                                                        ? this.dataBusiness[i].definitiveBestCloseOrigin
                                                        : this.globalDataBusinessPreloaded[i].definitiveBestCloseOrigin;
                                                this.dataBusiness[i].origenBestMeasure =
                                                    this.dataBusiness[i].bestOrigin !== undefined
                                                        ? this.globalDataBusinessPreloaded[i].bestOrigin
                                                        : this.dataBusiness[i].origenBestMeasure;
                                                this.dataBusiness[i].awsOrigin =
                                                    this.globalDataBusinessPreloaded[i].awsOrigin;
                                                this.dataBusiness[i].originBestClose = origin.bestSystemOrigin;
                                            }
                                        }
                                    }
                                });
                                this._populateTechnicalInfoSpecificData();
                            }
                        },
                        error: (error: any) => {
                            this._loadNotification('ERROR_GETTING_BEST_CLOSE_CUPS');
                        },
                    });
            } else {
                this._populateTechnicalInfoSpecificData();
            }
        });
    }

    private _populateTechnicalInfoSpecificData(): void {
        this.globalDataBusiness = [];
        this.globalDataBusiness = this.dataBusiness;

        if (!this.noData) {
            if (this.dataBusiness.length < 1) {
                this.fixSpinner = true;
                this.noData = true;
                this.populateSelectedSection(DashboardSections.SUMMARY);
            } else {
                //Si se está cargando la pestaña "Resumen" se lanza las llamada para la siguiente tab, que sería "Checks"
                if (this.chargeSummary) {
                    this.noData = false;
                    this.populateSelectedSection(DashboardSections.CHECK);
                } else {
                    this.spinnerActive = false;
                    this.noData = false;
                    this.fixSpinner = true;
                    this.visibilityPagination = false;
                }
            }
        }
    }

    private _populateChecksInfoSection(): void {
        if (!this.chargeSummary) {
            this.spinnerActive = true;
            this.fixSpinner = false;
            this.visibilityPagination = true;
        }

        this.dataHeaders = this.allDataHeaders;
        this.dataHeaders.forEach((element: any) => {
            if (element.name === 'dashboard_checks') {
                this.dataHeaders = element.headers;
            }
        });

        this.columns = [];
        for (let i = 0; i < this.dataHeaders.length; i++) {
            let headerLabel = this.dataHeaders[i].id;
            this.columns.push(headerLabel);
        }

        this.dataHeaders_valueSelect = [];
        this.dataHeaders_valueSelect = this.dataHeaders;
        this.dataHeadersForColumns = [];
        this.dataHeadersForColumns = this.dataHeaders;

        if (!this.noData) {
            this.tabMejorMedidaCierre = false;
            this.seqList = this.seqList.toString();
            this._cuadroMandosService.getChecks(this.seqList).subscribe((dashboard_Checks) => {
                this.dataBusiness = [];
                this.dataBusiness = dashboard_Checks.listChecks;

                for (let i = 0; i < this.dataBusiness.length; i++) {
                    if (
                        this.dataBusiness[i].inNetEnergyTotalValue === null ||
                        this.dataBusiness[i].inNetEnergyTotalValue === undefined ||
                        this.dataBusiness[i].inNetEnergyTotalValue === 0
                    ) {
                        this.dataBusiness[i].inNetEnergyTotalValue =
                            this.dataBusiness[i].inNetEnergyTotalValue === 0 ? `0` : this.NULL_VALUE_SIGN;
                    }
                    // Se preparan los campos de la tabla que contienen dos campos (OK/KO + number)
                    this.dataBusiness[i].zerosValue = [
                        this.dataBusiness[i].zerosValue ? this.dataBusiness[i].zerosValue : this.NULL_VALUE_SIGN,
                        this.dataBusiness[i].zerosStatus,
                    ];
                    this.dataBusiness[i].gapsValue = [
                        this.dataBusiness[i].gapsValue ? this.dataBusiness[i].gapsValue : this.NULL_VALUE_SIGN,
                        this.dataBusiness[i].gapsStatus,
                    ];
                    this.dataBusiness[i].peaksValue = [
                        this.dataBusiness[i].peaksValue ? this.dataBusiness[i].peaksValue : this.NULL_VALUE_SIGN,
                        this.dataBusiness[i].peaksStatus,
                    ];
                    this.dataBusiness[i].inNetEnergyValue = [
                        this.dataBusiness[i].inNetEnergyTotalValue
                            ? this.dataBusiness[i].inNetEnergyTotalValue
                            : this.NULL_VALUE_SIGN,
                        this.dataBusiness[i].inNetEnergyTotalStatus,
                    ];
                    this.dataBusiness[i].vsLastMonthValue = [
                        this.dataBusiness[i].vsLastMonthValue
                            ? this.dataBusiness[i].vsLastMonthValue
                            : this.NULL_VALUE_SIGN,
                        this.dataBusiness[i].vsLastMonthStatus,
                    ];

                    // Los siguientes dos campos contienen 7 valores (1 por cada periodo)
                    // 1-Obtener lastyearComparison
                    this.lastYearsComparison = [];
                    for (let j = 0; j < this.dataBusiness[i].checksLastYear.length; j++) {
                        if (
                            this.dataBusiness[i].checksLastYear[j].lastYearComparison === null ||
                            this.dataBusiness[i].checksLastYear[j].lastYearComparison === undefined ||
                            this.dataBusiness[i].checksLastYear[j].lastYearComparison === 0
                        ) {
                            this.lastYearsComparison.push(this.NULL_VALUE_SIGN);
                        } else {
                            this.lastYearsComparison.push(this.dataBusiness[i].checksLastYear[j].lastYearComparison);
                        }
                    }
                    this.dataBusiness[i].lastYearComparison = this.lastYearsComparison;

                    // 2-Obtener lastYearStatus
                    this.lastYearStatus = [];
                    for (let x = 0; x < this.dataBusiness[i].checksLastYear.length; x++) {
                        this.lastYearStatus.push(this.dataBusiness[i].checksLastYear[x].lastYearStatus);
                    }
                    this.dataBusiness[i].lastYearStatus = this.lastYearStatus;
                    this.dataBusiness[i].periodCode = this.periodCode; //Se añaden los 7 periodos "PeriodCode"
                }

                this.dataBusiness.forEach((element) => {
                    for (let i = 0; i < this.globalDataBusiness.length; i++) {
                        if (element.seq === this.globalDataBusiness[i].seq) {
                            // En globalDataBusiness se van guardando los datos encadenados de todas las tablas
                            this.globalDataBusiness[i] = Object.assign(this.globalDataBusiness[i], element);
                            if (element.executionMeasure === undefined || element.executionClose === undefined) {
                                element.executionMeasure = this.globalDataBusiness[i].executionMeasure;
                                element.executionClose = this.globalDataBusiness[i].executionClose;
                            }
                        }
                    }
                });

                if (this.chargeSummary) {
                    //Si se está cargando resumen...
                    this.populateSelectedSection(DashboardSections.MEASURES); //...se sigue con la pestaña "Curvas"
                } else {
                    this.spinnerActive = false;
                    this.fixSpinner = true;
                    this.visibilityPagination = false;
                }
            });
        }
    }

    private _populateMeasuresInfoSection(): void {
        if (!this.chargeSummary) {
            this.spinnerActive = true;
            this.fixSpinner = false;
            this.visibilityPagination = true;
        }

        this.dataHeaders = this.allDataHeaders;
        this.dataHeaders.forEach((element: any) => {
            if (element.name === 'dashboard_curvas') {
                this.dataHeaders = element.headers;
            }
        });

        this.dataHeaders_valueSelect = [];
        this.dataHeaders_valueSelect = this.dataHeaders;
        this.dataHeadersForColumns = this.dataHeaders;

        if (!this.noData) {
            this.tabMejorMedidaCierre = false;
            this._cuadroMandosService.getCheckMeasures(this.seqList).subscribe((dashboard_Curvas) => {
                this.dataBusiness = [];
                this.dataBusiness = dashboard_Curvas.data;
                //Se añade origen de mejor curva
                this.dataBusiness.forEach((element) => {
                    element.origin = [this.bestMeasuresList];
                });
                //Se añade "Autoconsumo", que se guardó en "selfSupplyList" durante la carga de "InfoTecnica"
                this.dataBusiness.forEach((element) => {
                    for (let i = 0; i < this.selfSupplyList.length; i++) {
                        if (element.seq === this.selfSupplyList[i].seq) {
                            element.selfSupplyType = this.selfSupplyList[i].selfSupply;
                        }
                    }
                });

                //Aquí se empiezan a montar los datos para esta tab, cada celda de la tabla contiene 7 datos, uno por periodo.
                let temporalDB: SequentialDB[] = [];
                for (let i = 0; i < this.dataBusiness.length; i++) {
                    temporalDB.push({ seq: this.dataBusiness[i].seq, var: [] });
                }
                //variable con la lista de SEQs
                const seqListCurvas = [];
                //Filtrar lista de SEQs para eliminar duplicados
                for (let indice = 0; indice < temporalDB.length; indice++) {
                    const seqTemporal = temporalDB[indice];
                    let esDuplicado = false;
                    for (let i = 0; i < seqListCurvas.length; i++) {
                        if (seqListCurvas[i].seq === seqTemporal.seq) {
                            esDuplicado = true;
                            break;
                        }
                    }
                    if (!esDuplicado) {
                        seqListCurvas.push(seqTemporal);
                    }
                }
                //asigna al SEQ correspondiente una variable del DB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            seqListCurvas[i].var.push(this.dataBusiness[x].periodCode);
                        }
                    }
                }
                //Añade variable PERIODCODE al GlobalDB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].periodCode = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].periodCode = seqListCurvas[i].var;
                        }
                    }
                }
                //Asigna al SEQ correspondiente una variable del DB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].manualInNetEnergy === null ||
                                this.dataBusiness[x].manualInNetEnergy === undefined ||
                                this.dataBusiness[x].manualInNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].manualInNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].manualInNetEnergy);
                            }
                        }
                    }
                }
                //Añade variable manualInNetEnergy al GlobalDB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].manualInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].manualInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                //Añade variables availableBestMeasuresList y isBestMeasuresListAvailable
                for (let i = 0; i < this.globalDataBusiness.length; i++) {
                    if (
                        this.dataBusiness[i].seq === this.globalDataBusiness[i].seq &&
                        this.globalDataBusinessPreloaded &&
                        this.globalDataBusinessPreloaded.length &&
                        this.globalDataBusinessPreloaded[i] !== undefined
                    ) {
                        this.dataBusiness[i].availableBestMeasuresList =
                            this.globalDataBusinessPreloaded[i].availableBestMeasuresList;
                        this.dataBusiness[i].isBestMeasuresListAvailable =
                            !this.globalDataBusinessPreloaded[i].executionMeasure &&
                            !this.globalDataBusinessPreloaded[i].executionClose &&
                            !this.globalDataBusinessPreloaded[i].executionCups
                                ? this.globalDataBusinessPreloaded[i].isBestMeasuresListAvailable
                                : false;
                        this.globalDataBusiness[i].availableBestMeasuresList =
                            this.globalDataBusinessPreloaded[i].availableBestMeasuresList;
                        this.globalDataBusiness[i].isBestMeasuresListAvailable =
                            !this.globalDataBusinessPreloaded[i].executionMeasure &&
                            !this.globalDataBusinessPreloaded[i].executionMeasure &&
                            !this.globalDataBusinessPreloaded[i].executionCups
                                ? this.globalDataBusinessPreloaded[i].isBestMeasuresListAvailable
                                : false;
                    }
                }
                //asigna al SEQ correspondiente una variable del DB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].distPInNetEnergy === null ||
                                this.dataBusiness[x].distPInNetEnergy === undefined ||
                                this.dataBusiness[x].distPInNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].distPInNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].distPInNetEnergy);
                            }
                        }
                    }
                }
                //Añade variable distFInNetEnergy al GlobalDB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].distPInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].distPInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                //asigna al SEQ correspondiente una variable del DB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].distFInNetEnergy === null ||
                                this.dataBusiness[x].distFInNetEnergy === undefined ||
                                this.dataBusiness[x].distFInNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].distFInNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].distFInNetEnergy);
                            }
                        }
                    }
                }
                //Añade variable distFInNetEnergy al GlobalDB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].distFInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].distFInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                //asigna al SEQ correspondiente una variable del DB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].hubInNetEnergy === null ||
                                this.dataBusiness[x].hubInNetEnergy === undefined ||
                                this.dataBusiness[x].hubInNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].hubInNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].hubInNetEnergy);
                            }
                        }
                    }
                }
                //Añade variable distFInNetEnergy al GlobalDB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].hubInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].hubInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                //asigna al SEQ correspondiente una variable del DB
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].reeInNetEnergy === null ||
                                this.dataBusiness[x].reeInNetEnergy === undefined ||
                                this.dataBusiness[x].reeInNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].reeInNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].reeInNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].reeInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].reeInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].atrInNetEnergy === null ||
                                this.dataBusiness[x].atrInNetEnergy === undefined ||
                                this.dataBusiness[x].atrInNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].atrInNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].atrInNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].atrInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].atrInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].bestForecastInNetEnergy === null ||
                                this.dataBusiness[x].bestForecastInNetEnergy === undefined ||
                                this.dataBusiness[x].bestForecastInNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].bestForecastInNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].bestForecastInNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].bestForecastInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].bestForecastInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].awsInNetEnergy === null ||
                                this.dataBusiness[x].awsInNetEnergy === undefined ||
                                this.dataBusiness[x].awsInNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].awsInNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].awsInNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].awsInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].awsInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].acumInNetEnergy === null ||
                                this.dataBusiness[x].acumInNetEnergy === undefined ||
                                this.dataBusiness[x].acumInNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].acumInNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].acumInNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].acumInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].acumInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].inmeclosInNetEnergy === null ||
                                this.dataBusiness[x].inmeclosInNetEnergy === undefined ||
                                this.dataBusiness[x].inmeclosInNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].inmeclosInNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].inmeclosInNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].inmeclosInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].inmeclosInNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].manualOutNetEnergy === null ||
                                this.dataBusiness[x].manualOutNetEnergy === undefined ||
                                this.dataBusiness[x].manualOutNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].manualOutNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].manualOutNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].manualOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].manualOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].distPOutNetEnergy === null ||
                                this.dataBusiness[x].distPOutNetEnergy === undefined ||
                                this.dataBusiness[x].distPOutNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].distPOutNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].distPOutNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].distPOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].distPOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].distFOutNetEnergy === null ||
                                this.dataBusiness[x].distFOutNetEnergy === undefined ||
                                this.dataBusiness[x].distFOutNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].distFOutNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].distFOutNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].distFOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].distFOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].hubOutNetEnergy === null ||
                                this.dataBusiness[x].hubOutNetEnergy === undefined ||
                                this.dataBusiness[x].hubOutNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].hubOutNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].hubOutNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].hubOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].hubOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].reeOutNetEnergy === null ||
                                this.dataBusiness[x].reeOutNetEnergy === undefined ||
                                this.dataBusiness[x].reeOutNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].reeOutNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].reeOutNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].reeOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].reeOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].atrOutNetEnergy === null ||
                                this.dataBusiness[x].atrOutNetEnergy === undefined ||
                                this.dataBusiness[x].atrOutNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].atrOutNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].atrOutNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].atrOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].atrOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].awsOutNetEnergy === null ||
                                this.dataBusiness[x].awsOutNetEnergy === undefined ||
                                this.dataBusiness[x].awsOutNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].awsOutNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].awsOutNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].awsOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].awsOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].inmeclosOutNetEnergy === null ||
                                this.dataBusiness[x].inmeclosOutNetEnergy === undefined ||
                                this.dataBusiness[x].inmeclosOutNetEnergy === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].inmeclosOutNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].inmeclosOutNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].inmeclosOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].inmeclosOutNetEnergy = seqListCurvas[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    seqListCurvas[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].closePeriod === null ||
                                this.dataBusiness[x].closePeriod === undefined ||
                                this.dataBusiness[x].closePeriod === 0
                            ) {
                                seqListCurvas[i].var.push(
                                    this.dataBusiness[x].closePeriod === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCurvas[i].var.push(this.dataBusiness[x].closePeriod);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCurvas.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].closePeriod = seqListCurvas[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCurvas[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].closePeriod = seqListCurvas[i].var;
                        }
                    }
                }

                let dataBusinessFilter: any;
                dataBusinessFilter = [];
                for (let indice = 0; indice < this.dataBusiness.length; indice++) {
                    const seqTemporal = this.dataBusiness[indice];
                    let esDuplicado = false;
                    for (let i = 0; i < dataBusinessFilter.length; i++) {
                        if (dataBusinessFilter[i].seq === seqTemporal.seq) {
                            esDuplicado = true;
                            break;
                        }
                    }
                    if (!esDuplicado) {
                        dataBusinessFilter.push(seqTemporal);
                    }
                }
                this.dataBusiness = [];
                this.dataBusiness = dataBusinessFilter;
                for (let i = 0; i < this.dataBusiness.length; i++) {
                    this.dataBusiness[i].origenBestMeasure =
                        this.dataBusiness[i].bestOrigin !== undefined
                            ? this.dataBusiness[i].bestOrigin
                            : this.dataBusiness[i].origenBestMeasure;
                    this.dataBusiness[i].awsOrigin = this.dataBusiness[i].awsOrigin;
                    this.dataBusiness[i].definitiveBestMeasureOrigin = this.dataBusiness[i].bestOrigin;
                    if (this.globalDataBusiness && this.globalDataBusiness[i]) {
                        this.globalDataBusiness[i].closeDateBegin =
                            this.dataBusiness[i].closeDateBegin !== undefined
                                ? this.dataBusiness[i].closeDateBegin
                                : this.globalDataBusiness[i].closeDateBegin;
                        this.globalDataBusiness[i].closeDateEnd =
                            this.dataBusiness[i].closeDateEnd !== undefined
                                ? this.dataBusiness[i].closeDateEnd
                                : this.globalDataBusiness[i].closeDateEnd;
                        this.globalDataBusiness[i].origenBestMeasure =
                            this.dataBusiness[i].bestOrigin !== undefined
                                ? this.dataBusiness[i].bestOrigin
                                : this.globalDataBusiness[i].origenBestMeasure;
                        this.globalDataBusiness[i].periodCode = this.periodCode;
                        this.dataBusiness[i].origenBestMeasure =
                            this.globalDataBusiness[i].bestOrigin !== undefined
                                ? this.globalDataBusiness[i].bestOrigin
                                : this.dataBusiness[i].origenBestMeasure;
                        this.dataBusiness[i].isBestMeasuresListAvailable =
                            !this.globalDataBusiness[i].executionMeasure && 
                            !this.globalDataBusiness[i].executionClose &&
                            !this.globalDataBusiness[i].executionCups
                                ? this.globalDataBusiness[i].isBestMeasuresListAvailable
                                : false;
                        this.dataBusiness[i].availableBestMeasuresList =
                            this.globalDataBusiness[i].availableBestMeasuresList;
                        this.globalDataBusiness[i].awsOrigin = this.dataBusiness[i].awsOrigin;
                        this.dataBusiness[i].definitiveBestCloseOrigin =
                            this.globalDataBusiness[i].definitiveBestCloseOrigin;
                        this.globalDataBusiness[i].definitiveBestMeasureOrigin =
                            this.dataBusiness[i].definitiveBestMeasureOrigin;
                        if (
                            this.dataBusiness[i].executionMeasure === undefined ||
                            this.dataBusiness[i].executionClose === undefined
                        ) {
                            this.dataBusiness[i].executionMeasure = this.globalDataBusiness[i].executionMeasure;
                            this.dataBusiness[i].executionClose = this.globalDataBusiness[i].executionClose;
                        }
                    }
                    if (this.globalDataBusinessPreloaded && this.globalDataBusinessPreloaded[i]) {
                        this.globalDataBusinessPreloaded[i].closeDateBegin =
                            this.dataBusiness[i].closeDateBegin !== undefined
                                ? this.dataBusiness[i].closeDateBegin
                                : this.globalDataBusinessPreloaded[i].closeDateBegin;
                        this.globalDataBusinessPreloaded[i].closeDateEnd =
                            this.dataBusiness[i].closeDateEnd !== undefined
                                ? this.dataBusiness[i].closeDateEnd
                                : this.globalDataBusinessPreloaded[i].closeDateEnd;
                        this.dataBusiness[i].isBestMeasuresListAvailable =
                            this.globalDataBusinessPreloaded[i].executionMeasure ||
                            this.globalDataBusinessPreloaded[i].executionClose ||
                            this.globalDataBusinessPreloaded[i].executionCups
                                ? false
                                : this.globalDataBusinessPreloaded[i].isBestMeasuresListAvailable;
                        this.dataBusiness[i].availableBestMeasuresList =
                            this.globalDataBusinessPreloaded[i].availableBestMeasuresList;
                        this.dataBusiness[i].increEnergyMeasure =
                            this.globalDataBusinessPreloaded[i].increEnergyMeasure;
                        this.dataBusiness[i].definitiveBestCloseOrigin =
                            this.globalDataBusinessPreloaded[i].definitiveBestCloseOrigin;
                        this.dataBusiness[i].origenBestMeasure =
                            this.globalDataBusinessPreloaded[i].bestOrigin !== undefined
                                ? this.globalDataBusinessPreloaded[i].bestOrigin
                                : this.dataBusiness[i].origenBestMeasure;
                        if (
                            this.dataBusiness[i].executionMeasure === undefined ||
                            this.dataBusiness[i].executionClose === undefined
                        ) {
                            this.dataBusiness[i].executionMeasure =
                                this.globalDataBusinessPreloaded[i].executionMeasure;
                            this.dataBusiness[i].executionClose = this.globalDataBusinessPreloaded[i].executionClose;
                        }
                    }
                }
                if (this.chargeSummary) {
                    this.populateSelectedSection(DashboardSections.CLOSES);
                } else {
                    this.spinnerActive = false;
                    this.fixSpinner = true;
                    this.visibilityPagination = false;
                }
            });
        }
    }

    private _populateClosesInfoSection(): void {
        if (!this.chargeSummary) {
            this.spinnerActive = true;
            this.fixSpinner = false;
            this.visibilityPagination = true;
        }

        this.dataHeaders = this.allDataHeaders;
        this.dataHeaders.forEach((element: any) => {
            if (element.name === 'dashboard_cierres') {
                this.dataHeaders = element.headers;
            }
        });

        this.columns = [];
        for (let i = 0; i < this.dataHeaders.length; i++) {
            let headerLabel = this.dataHeaders[i].id;
            this.columns.push(headerLabel);
        }

        this.dataHeaders_valueSelect = [];
        this.dataHeaders_valueSelect = this.dataHeaders;
        this.dataHeadersForColumns = [];
        this.dataHeadersForColumns = this.dataHeaders;

        if (!this.noData) {
            this.tabMejorMedidaCierre = false;
            let searchForSeq;
            let search = this.seqList.toString();
            searchForSeq = search.replaceAll(',', ';');
            searchForSeq = 'seq=(' + searchForSeq + ')';

            this._cuadroMandosService.getCloses(this.seqList).subscribe((dashboard_Cierres) => {
                this.dataBusiness = [];
                this.dataBusiness = dashboard_Cierres.data;
                let temporalDB: any = [{ seq: [], var: [] }];

                for (let i = 0; i < this.dataBusiness.length; i++) {
                    temporalDB.push({ seq: this.dataBusiness[i].seq, var: [] });
                }
                this.dataBusiness.forEach((element) => {
                    for (let i = 0; i < this.selfSupplyList.length; i++) {
                        if (element.seq === this.selfSupplyList[i].seq) {
                            element.selfSupplyType = this.selfSupplyList[i].selfSupply;
                        }
                    }
                    element.definitiveBestCloseOrigin = element.bestOrigin;
                });
                //variable con la lista de SEQs
                const seqListCierres = [];
                //Filtrar lista de SEQs para eliminar duplicados
                for (let indice = 0; indice < temporalDB.length; indice++) {
                    const seqDB = temporalDB[indice];
                    let esDuplicado = false;
                    for (let i = 0; i < seqListCierres.length; i++) {
                        if (seqListCierres[i].seq === seqDB.seq) {
                            esDuplicado = true;
                            break;
                        }
                    }
                    if (!esDuplicado) {
                        seqListCierres.push(seqDB);
                    }
                }
                // MEJOR CIERRE: se saca la info de Cierres
                this._cuadroMandosService
                .getBestCloses(this.cupListFilter, this.dateInitFilter, this.dateEndFilter)
                    .subscribe((dashboard_getCuadroDeMandos_mejorCierre) => {
                        dashboard_getCuadroDeMandos_mejorCierre.data = this._refillEmptyDefinitiveBestClosePeriods(
                            dashboard_getCuadroDeMandos_mejorCierre.data,
                            this.cupListFilter,
                            this.dateInitFilter,
                            this.dateEndFilter
                        );
                        this.dataBusiness = dashboard_getCuadroDeMandos_mejorCierre.data;
                        if (this.dataBusiness.length > 0) {
                            let temporalDB: BestMeasureObject[] = [];
                            for (let i = 0; i < this.globalDataBusiness.length; i++) {
                                this.globalDataBusiness[i].increEnergyClose = [];
                                temporalDB[i] = {
                                    cups: this.globalDataBusiness[i].cups,
                                    closeDateBegin: this.globalDataBusiness[i].closeDateBegin,
                                    closeDateEnd: this.globalDataBusiness[i].closeDateEnd,
                                    var: [
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                    ],
                                };
                                for (let j = 0; j < dashboard_getCuadroDeMandos_mejorCierre.data.length; j++) {
                                    if (
                                        this.globalDataBusiness[i].cups ===
                                            dashboard_getCuadroDeMandos_mejorCierre.data[j].cups &&
                                        this.globalDataBusiness[i].closeDateBegin ===
                                            dashboard_getCuadroDeMandos_mejorCierre.data[j].closeDateBegin &&
                                        this.globalDataBusiness[i].closeDateEnd ===
                                            dashboard_getCuadroDeMandos_mejorCierre.data[j].closeDateEnd
                                    ) {
                                        const dashboardNotNum = dashboard_getCuadroDeMandos_mejorCierre.data[j].increEnergy == 0
                                                || dashboard_getCuadroDeMandos_mejorCierre.data[j].increEnergy == this.NULL_VALUE_SIGN;
                                        const roundedNum = Math.round(dashboard_getCuadroDeMandos_mejorCierre.data[j].increEnergy);
                                        if (dashboard_getCuadroDeMandos_mejorCierre.data[j].periodCode === 20) {
                                            temporalDB[i].var[0] = Math.round(this.dataBusiness[i].increEnergy);
                                            this.globalDataBusiness[i].increEnergyClose[0] = dashboardNotNum ? this.NULL_VALUE_SIGN : roundedNum;
                                        } else {
                                            const indexPeriodCode = dashboard_getCuadroDeMandos_mejorCierre.data[j].periodCode;
                                            this.globalDataBusiness[i].increEnergyClose[indexPeriodCode] = dashboardNotNum ? this.NULL_VALUE_SIGN : roundedNum;
                                            temporalDB[i].var[indexPeriodCode] = dashboardNotNum ? this.NULL_VALUE_SIGN : roundedNum;
                                        }
                                    }
                                }
                            }
                        }
                        //variable con la lista de SEQs
                        const mejorMedidaList = [];
                        //Filtrar lista de SEQs para eliminar duplicados
                        for (let indice = 0; indice < temporalDB.length; indice++) {
                            const seqDB = temporalDB[indice];
                            let esDuplicado = false;
                            for (let i = 0; i < mejorMedidaList.length; i++) {
                                if (
                                    mejorMedidaList[i].var[0] === seqDB.var[0] &&
                                    mejorMedidaList[i].var[1] === seqDB.var[1] &&
                                    mejorMedidaList[i].var[2] === seqDB.var[2] &&
                                    mejorMedidaList[i].var[3] === seqDB.var[3] &&
                                    mejorMedidaList[i].var[4] === seqDB.var[4] &&
                                    mejorMedidaList[i].var[5] === seqDB.var[5] &&
                                    mejorMedidaList[i].var[6] === seqDB.var[6]
                                ) {
                                    esDuplicado = true;
                                    break;
                                }
                            }
                            if (!esDuplicado) {
                                mejorMedidaList.push(seqDB);
                            }
                        }
                        for (let i = 0; i < this.globalDataBusiness.length; i++) {
                            if (
                                this.globalDataBusiness[i].increEnergyMeasure === null ||
                                this.globalDataBusiness[i].increEnergyMeasure === undefined ||
                                this.globalDataBusiness[i].increEnergyMeasure === 0
                            ) {
                                this.globalDataBusiness[i].increEnergyMeasure = [
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                ];
                            }
                        }
                        for (let i = 0; i < this.globalDataBusiness.length; i++) {
                            if (
                                this.globalDataBusiness[i].increEnergyClose === null ||
                                this.globalDataBusiness[i].increEnergyClose === undefined ||
                                this.globalDataBusiness[i].increEnergyClose === 0
                            ) {
                                this.globalDataBusiness[i].increEnergyClose = [
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                    this.NULL_VALUE_SIGN,
                                ];
                            }
                        }
                        for (let i = 0; i < this.globalDataBusiness.length; i++) {
                            this.globalDataBusiness[i].periodCode = this.periodCode;
                        }
                        this.dataBusiness = this.globalDataBusiness;
                        for (let i = 0; i < this.dataBusiness.length; i++) {
                            this.dataBusiness[i].isBestMeasuresListAvailable =
                                this.dataBusiness[i].executionMeasure || 
                                this.dataBusiness[i].executionClose ||
                                this.dataBusiness[i].executionCups
                                    ? false
                                    : this.dataBusiness[i].isBestMeasuresListAvailable;
                            this.dataBusiness[i].isBestClosesListAvailable =
                                this.dataBusiness[i].executionMeasure || 
                                this.dataBusiness[i].executionClose ||
                                this.dataBusiness[i].executionCups
                                    ? false
                                    : this.dataBusiness[i].isBestClosesListAvailable;
                        }
                        if (this.chargeSummary) {
                            this.populateSelectedSection(DashboardSections.SUMMARY);
                            this.chargeSummary = false;
                        } else {
                            this.fixSpinner = true;
                            this.visibilityPagination = false;
                            this.spinnerActive = false;
                        }
                    });
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].manualIncreEnergy === null ||
                                this.dataBusiness[x].manualIncreEnergy === undefined ||
                                this.dataBusiness[x].manualIncreEnergy === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].manualIncreEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].manualIncreEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].manualIncreEnergy = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].manualIncreEnergy = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].quarterHourlyIncreEnergy === null ||
                                this.dataBusiness[x].quarterHourlyIncreEnergy === undefined ||
                                this.dataBusiness[x].quarterHourlyIncreEnergy === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].quarterHourlyIncreEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].quarterHourlyIncreEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].quarterHourlyIncreEnergy = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].quarterHourlyIncreEnergy = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].atrIncreEnergy === null ||
                                this.dataBusiness[x].atrIncreEnergy === undefined ||
                                this.dataBusiness[x].atrIncreEnergy === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].atrIncreEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].atrIncreEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].atrIncreEnergy = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].atrIncreEnergy = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].mixedIncreEnergy === null ||
                                this.dataBusiness[x].mixedIncreEnergy === undefined ||
                                this.dataBusiness[x].mixedIncreEnergy === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].mixedIncreEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].mixedIncreEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].mixedIncreEnergy = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].mixedIncreEnergy = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].hourlyIncreEnergy === null ||
                                this.dataBusiness[x].hourlyIncreEnergy === undefined ||
                                this.dataBusiness[x].hourlyIncreEnergy === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].hourlyIncreEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].hourlyIncreEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].hourlyIncreEnergy = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].hourlyIncreEnergy = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].hubIncreEnergy === null ||
                                this.dataBusiness[x].hubIncreEnergy === undefined ||
                                this.dataBusiness[x].hubIncreEnergy === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].hubIncreEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].hubIncreEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].hubIncreEnergy = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].hubIncreEnergy = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].distributorIncreEnergy === null ||
                                this.dataBusiness[x].distributorIncreEnergy === undefined ||
                                this.dataBusiness[x].distributorIncreEnergy === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].distributorIncreEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].distributorIncreEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].distributorIncreEnergy = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].distributorIncreEnergy = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].awsBestIncreEnergy === null ||
                                this.dataBusiness[x].awsBestIncreEnergy === undefined ||
                                this.dataBusiness[x].awsBestIncreEnergy === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].awsBestIncreEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].awsBestIncreEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].awsBestIncreEnergy = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].awsBestIncreEnergy = seqListCierres[i].var;
                        }
                    }
                } 
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].atrPowerExcess === null ||
                                this.dataBusiness[x].atrPowerExcess === undefined ||
                                this.dataBusiness[x].atrPowerExcess === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].atrPowerExcess === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].atrPowerExcess);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].atrPowerExcess = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].atrPowerExcess = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].bestPowerExcess === null ||
                                this.dataBusiness[x].bestPowerExcess === undefined ||
                                this.dataBusiness[x].bestPowerExcess === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].bestPowerExcess === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].bestPowerExcess);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].bestPowerExcess = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].bestPowerExcess = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].forecastHours === null ||
                                this.dataBusiness[x].forecastHours === undefined ||
                                this.dataBusiness[x].forecastHours === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].forecastHours === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].forecastHours);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].forecastHours = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].forecastHours = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].forecastEnergy === null ||
                                this.dataBusiness[x].forecastEnergy === undefined ||
                                this.dataBusiness[x].forecastEnergy === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].forecastEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].forecastEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].forecastEnergy = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].forecastEnergy = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].inmeclosOutNetEnergy === null ||
                                this.dataBusiness[x].inmeclosOutNetEnergy === undefined ||
                                this.dataBusiness[x].inmeclosOutNetEnergy === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].inmeclosOutNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].inmeclosOutNetEnergy);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].inmeclosOutNetEnergy = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].inmeclosOutNetEnergy = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].closePeriod === null ||
                                this.dataBusiness[x].closePeriod === undefined ||
                                this.dataBusiness[x].closePeriod === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].closePeriod === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].closePeriod);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].closePeriod = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].closePeriod = seqListCierres[i].var;
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    seqListCierres[i].var = [];
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            if (
                                this.dataBusiness[x].periodCode === null ||
                                this.dataBusiness[x].periodCode === undefined ||
                                this.dataBusiness[x].periodCode === 0
                            ) {
                                seqListCierres[i].var.push(
                                    this.dataBusiness[x].periodCode === 0 ? `0` : this.NULL_VALUE_SIGN
                                );
                            } else {
                                seqListCierres[i].var.push(this.dataBusiness[x].periodCode);
                            }
                        }
                    }
                }
                for (let i = 0; i < seqListCierres.length; i++) {
                    for (let x = 0; x < this.globalDataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.globalDataBusiness[x].seq) {
                            this.globalDataBusiness[x].periodCode = seqListCierres[i].var;
                        }
                    }
                    for (let x = 0; x < this.dataBusiness.length; x++) {
                        if (seqListCierres[i].seq === this.dataBusiness[x].seq) {
                            this.dataBusiness[x].periodCode = seqListCierres[i].var;
                        }
                    }
                }

                this.dataBusiness.forEach((element: any) => {
                    element.periodCode = this.periodCode;
                });
                this.globalDataBusiness.forEach((element: any) => {
                    element.periodCode = this.periodCode;
                });

                let dataBusinessFilter: any;
                dataBusinessFilter = [];
                for (let indice = 0; indice < this.dataBusiness.length; indice++) {
                    const seqTemporal = this.dataBusiness[indice];
                    let esDuplicado = false;
                    for (let i = 0; i < dataBusinessFilter.length; i++) {
                        if (dataBusinessFilter[i].seq === seqTemporal.seq) {
                            esDuplicado = true;
                            break;
                        }
                    }
                    if (!esDuplicado) {
                        dataBusinessFilter.push(seqTemporal);
                    }
                }

                this.dataBusiness = [];
                this.dataBusiness = dataBusinessFilter;
                for (let i = 0; i < this.dataBusiness.length; i++) {
                    this.dataBusiness[i].originBestClose = this.globalDataBusiness[i].originBestClose
                        ? this.globalDataBusiness[i].originBestClose
                        : this.dataBusiness[i].originBestClose;
                    this.dataBusiness[i].definitiveBestCloseOrigin =
                        this.dataBusiness[i].bestOrigin !== undefined && this.dataBusiness[i].bestOrigin !== null
                            ? this.dataBusiness[i].bestOrigin
                            : this.dataBusiness[i].definitiveBestCloseOrigin;
                    if (this.globalDataBusiness && this.globalDataBusiness[i]) {
                        if (
                            this.dataBusiness[i].executionMeasure === undefined ||
                            this.dataBusiness[i].executionClose === undefined ||
                            this.dataBusiness[i].executionCups === undefined
                        ) {
                            this.dataBusiness[i].executionMeasure = this.globalDataBusiness[i].executionMeasure;
                            this.dataBusiness[i].executionClose = this.globalDataBusiness[i].executionClose;
                            this.dataBusiness[i].executionCups = this.globalDataBusiness[i].executionCups;
                        }
                        this.globalDataBusiness[i].definitiveBestCloseOrigin =
                            this.dataBusiness[i].definitiveBestCloseOrigin &&
                            this.dataBusiness[i].definitiveBestCloseOrigin !== undefined &&
                            this.dataBusiness[i].definitiveBestCloseOrigin !== null
                                ? this.dataBusiness[i].definitiveBestCloseOrigin
                                : this.globalDataBusiness[i].definitiveBestCloseOrigin;
                        this.dataBusiness[i].isBestClosesListAvailable =
                            this.globalDataBusiness[i].isBestClosesListAvailable;
                        this.dataBusiness[i].availableBestClosesList =
                            this.globalDataBusiness[i].availableBestClosesList;
                        this.dataBusiness[i].acumInNetEnergy = this.globalDataBusiness[i].acumInNetEnergy;
                        this.dataBusiness[i].inmeclosInNetEnergy = this.globalDataBusiness[i].inmeclosInNetEnergy;
                        this.dataBusiness[i].bestCloseOrigin = this.globalDataBusiness[i].bestCloseOrigin;
                        this.dataBusiness[i].acumInNetEnergy =
                            this.dataBusiness[i].acumInNetEnergy !== undefined
                                ? this.dataBusiness[i].acumInNetEnergy
                                : this.globalDataBusiness[i].acumInNetEnergy;
                        this.dataBusiness[i].inmeclosInNetEnergy =
                            this.dataBusiness[i].inmeclosInNetEnergy !== undefined
                                ? this.dataBusiness[i].inmeclosInNetEnergy
                                : this.globalDataBusiness[i].inmeclosInNetEnergy;
                    }
                    if (this.globalDataBusinessPreloaded && this.globalDataBusinessPreloaded[i]) {
                        this.globalDataBusinessPreloaded[i].definitiveBestCloseOrigin =
                            this.dataBusiness[i].definitiveBestCloseOrigin &&
                            this.dataBusiness[i].definitiveBestCloseOrigin !== undefined &&
                            this.dataBusiness[i].definitiveBestCloseOrigin !== null
                                ? this.dataBusiness[i].definitiveBestCloseOrigin
                                : this.globalDataBusinessPreloaded[i].definitiveBestCloseOrigin;
                        this.dataBusiness[i].isBestClosesListAvailable =
                            this.globalDataBusinessPreloaded[i].isBestClosesListAvailable;
                        this.dataBusiness[i].availableBestClosesList =
                            this.globalDataBusinessPreloaded[i].availableBestClosesList;
                        this.dataBusiness[i].increEnergyClose = this.globalDataBusinessPreloaded[i].increEnergyClose;
                        this.dataBusiness[i].bestCloseOrigin = this.globalDataBusinessPreloaded[i].bestCloseOrigin;
                        this.dataBusiness[i].acumInNetEnergy =
                            this.dataBusiness[i].acumInNetEnergy !== undefined
                                ? this.dataBusiness[i].acumInNetEnergy
                                : this.globalDataBusinessPreloaded[i].acumInNetEnergy;
                        this.dataBusiness[i].inmeclosInNetEnergy =
                            this.dataBusiness[i].inmeclosInNetEnergy !== undefined
                                ? this.dataBusiness[i].inmeclosInNetEnergy
                                : this.globalDataBusinessPreloaded[i].inmeclosInNetEnergy;
                    }
                    if (
                        this.dataBusiness[i].closeDateBegin === undefined ||
                        this.dataBusiness[i].closeDateEnd === undefined
                    ) {
                        this.dataBusiness[i].closeDateBegin =
                            this.globalDataBusiness[i].closeDateBegin !== undefined
                                ? this.globalDataBusiness[i].closeDateBegin
                                : this.dataBusiness[i].closeDateBegin;
                        this.dataBusiness[i].closeDateEnd =
                            this.globalDataBusiness[i].closeDateEnd !== undefined
                                ? this.globalDataBusiness[i].closeDateEnd
                                : this.dataBusiness[i].closeDateEnd;
                    }
                }
                if (this.chargeSummary) {
                    this.populateSelectedSection(DashboardSections.BEST_MEASURE_CLOSE);
                } else {
                    this.spinnerActive = false;
                    this.fixSpinner = true;
                    this.visibilityPagination = false;
                }
            });
        }
    }

    private _populateBestMeasureAndCloseInfoSection(): void {
        if (!this.chargeSummary) {
            this.spinnerActive = true;
            this.fixSpinner = false;
            this.visibilityPagination = true;
        }

        this.dataHeaders = this.allDataHeaders;
        this.dataHeaders.forEach((element: any) => {
            if (element.name === 'dashboard_mejorcierre_mejormedida') {
                this.dataHeaders = element.headers;
            }
        });

        this.columns = [];
        for (let i = 0; i < this.dataHeaders.length; i++) {
            let headerLabel = this.dataHeaders[i].id;
            this.columns.push(headerLabel);
        }

        this.dataHeaders_valueSelect = [];
        this.dataHeaders_valueSelect = this.dataHeaders;
        this.dataHeadersForColumns = [];
        this.dataHeadersForColumns = this.dataHeaders;

        if (!this.noData) {
            //Se cargan los datos desde aquí
            this.tabMejorMedidaCierre = true;
            this.dataBusiness = [];
            this.dataBusiness = this.globalDataBusiness;
            // variable con la lista de SEQs
            const temporalCupList = [];
            // Filtrar lista de SEQs para eliminar duplicados
            for (let indice = 0; indice < this.cupListFilter.length; indice++) {
                const seqDB = this.cupListFilter[indice];
                let esDuplicado = false;
                for (let i = 0; i < temporalCupList.length; i++) {
                    if (temporalCupList[i] === seqDB) {
                        esDuplicado = true;
                        break;
                    }
                }
                if (!esDuplicado) {
                    temporalCupList.push(seqDB);
                }
            }
            this.cupListFilter = temporalCupList;
            // MEJOR MEDIDA: Se sacan la info de medidas
            this._cuadroMandosService
                .getBestMeasures(this.cupListFilter, this.dateInitFilter, this.dateEndFilter)
                .subscribe((dashboard_getCuadroDeMandos_mejorMedida) => {
                    this.dataBusiness = dashboard_getCuadroDeMandos_mejorMedida.data;
                    let temporalDB: BestMeasureObject[] = [];
                    // se hace una lista con los cups de dataBusiness
                    for (let i = 0; i < this.dataBusiness.length; i++) {
                        temporalDB.push({
                            cups: this.dataBusiness[i].cups,
                            closeDateBegin: this.dataBusiness[i].closeDateBegin,
                            closeDateEnd: this.dataBusiness[i].closeDateEnd,
                            var: [],
                        });
                    }
                    // asigna al SEQ correspondiente una variable del DB
                    for (let i = 0; i < temporalDB.length; i++) {
                        temporalDB[i].var = [];
                        for (let x = 0; x < this.dataBusiness.length; x++) {
                            if (
                                temporalDB[i].cups === this.dataBusiness[x].cups &&
                                temporalDB[i].closeDateBegin === this.dataBusiness[x].closeDateBegin &&
                                temporalDB[i].closeDateEnd === this.dataBusiness[x].closeDateEnd
                            ) {
                                if (
                                    this.dataBusiness[x].inNetEnergy === null ||
                                    this.dataBusiness[x].inNetEnergy === undefined ||
                                    this.dataBusiness[x].inNetEnergy === 0
                                ) {
                                    if (temporalDB[i].var.length < 7) {
                                        temporalDB[i].var.push(
                                            this.dataBusiness[x].inNetEnergy === 0 ? `0` : this.NULL_VALUE_SIGN
                                        );
                                    }
                                } else {
                                    if (temporalDB[i].var.length < 7) {
                                        temporalDB[i].var.push(this.dataBusiness[x].inNetEnergy);
                                    }
                                }
                            }
                        }
                    }
                    // variable con la lista de SEQs
                    const mejorMedidaList = [];
                    // Filtrar lista de SEQs para eliminar duplicados
                    for (let indice = 0; indice < temporalDB.length; indice++) {
                        const seqDB = temporalDB[indice];
                        let esDuplicado = false;
                        for (let i = 0; i < mejorMedidaList.length; i++) {
                            if (
                                mejorMedidaList[i].var[0] === seqDB.var[0] &&
                                mejorMedidaList[i].var[1] === seqDB.var[1] &&
                                mejorMedidaList[i].var[2] === seqDB.var[2] &&
                                mejorMedidaList[i].var[3] === seqDB.var[3] &&
                                mejorMedidaList[i].var[4] === seqDB.var[4] &&
                                mejorMedidaList[i].var[5] === seqDB.var[5] &&
                                mejorMedidaList[i].var[6] === seqDB.var[6]
                            ) {
                                esDuplicado = true;
                                break;
                            }
                        }
                        if (!esDuplicado) {
                            mejorMedidaList.push(seqDB);
                        }
                    }

                    for (let i = 0; i < mejorMedidaList.length; i++) {
                        for (let x = 0; x < this.globalDataBusiness.length; x++) {
                            if (
                                mejorMedidaList[i].cups === this.globalDataBusiness[x].cups &&
                                mejorMedidaList[i].closeDateBegin === this.globalDataBusiness[x].closeDateBegin &&
                                mejorMedidaList[i].closeDateEnd === this.globalDataBusiness[x].closeDateEnd
                            ) {
                                this.globalDataBusiness[x].increEnergyMeasure = mejorMedidaList[i].var;
                            }
                        }
                    }
                    // MEJOR CIERRE: se saca la info de Cierres
                    this._cuadroMandosService
                        .getBestCloses(this.cupListFilter, this.dateInitFilter, this.dateEndFilter)
                        .subscribe((dashboard_getCuadroDeMandos_mejorCierre) => {
                            dashboard_getCuadroDeMandos_mejorCierre.data = this._refillEmptyDefinitiveBestClosePeriods(
                                dashboard_getCuadroDeMandos_mejorCierre.data,
                                this.cupListFilter,
                                this.dateInitFilter,
                                this.dateEndFilter
                            );
                            this.dataBusiness = dashboard_getCuadroDeMandos_mejorCierre.data;
                            if (this.dataBusiness.length > 0) {
                                let temporalDB: BestMeasureObject[] = [];
                                for (let i = 0; i < this.globalDataBusiness.length; i++) {
                                    this.globalDataBusiness[i].increEnergyClose = [];
                                    temporalDB[i] = {
                                        cups: this.globalDataBusiness[i].cups,
                                        closeDateBegin: this.globalDataBusiness[i].closeDateBegin,
                                        closeDateEnd: this.globalDataBusiness[i].closeDateEnd,
                                        var: [
                                            this.NULL_VALUE_SIGN,
                                            this.NULL_VALUE_SIGN,
                                            this.NULL_VALUE_SIGN,
                                            this.NULL_VALUE_SIGN,
                                            this.NULL_VALUE_SIGN,
                                            this.NULL_VALUE_SIGN,
                                            this.NULL_VALUE_SIGN,
                                        ],
                                    };
                                    for (let j = 0; j < dashboard_getCuadroDeMandos_mejorCierre.data.length; j++) {
                                        if (
                                            this.globalDataBusiness[i].cups ===
                                                dashboard_getCuadroDeMandos_mejorCierre.data[j].cups &&
                                            this.globalDataBusiness[i].closeDateBegin ===
                                                dashboard_getCuadroDeMandos_mejorCierre.data[j].closeDateBegin &&
                                            this.globalDataBusiness[i].closeDateEnd ===
                                                dashboard_getCuadroDeMandos_mejorCierre.data[j].closeDateEnd
                                        ) {
                                            const dashboardNotNum = dashboard_getCuadroDeMandos_mejorCierre.data[j].increEnergy == 0
                                                || dashboard_getCuadroDeMandos_mejorCierre.data[j].increEnergy == this.NULL_VALUE_SIGN;
                                            const roundedNum = Math.round(dashboard_getCuadroDeMandos_mejorCierre.data[j].increEnergy);
                                            if (dashboard_getCuadroDeMandos_mejorCierre.data[j].periodCode === 20) {
                                                temporalDB[i].var[0] = Math.round(this.dataBusiness[i].increEnergy);
                                                this.globalDataBusiness[i].increEnergyClose[0] = dashboardNotNum ? this.NULL_VALUE_SIGN : roundedNum;
                                            } else {
                                                const indexPeriodCode = dashboard_getCuadroDeMandos_mejorCierre.data[j].periodCode;
                                                this.globalDataBusiness[i].increEnergyClose[indexPeriodCode] = dashboardNotNum ? this.NULL_VALUE_SIGN : roundedNum;
                                                temporalDB[i].var[indexPeriodCode] = dashboardNotNum ? this.NULL_VALUE_SIGN : roundedNum;
                                            }
                                        }
                                    }
                                }
                            }
                            //variable con la lista de SEQs
                            const mejorMedidaList = [];
                            //Filtrar lista de SEQs para eliminar duplicados
                            for (let indice = 0; indice < temporalDB.length; indice++) {
                                const seqDB = temporalDB[indice];
                                let esDuplicado = false;
                                for (let i = 0; i < mejorMedidaList.length; i++) {
                                    if (
                                        mejorMedidaList[i].var[0] === seqDB.var[0] &&
                                        mejorMedidaList[i].var[1] === seqDB.var[1] &&
                                        mejorMedidaList[i].var[2] === seqDB.var[2] &&
                                        mejorMedidaList[i].var[3] === seqDB.var[3] &&
                                        mejorMedidaList[i].var[4] === seqDB.var[4] &&
                                        mejorMedidaList[i].var[5] === seqDB.var[5] &&
                                        mejorMedidaList[i].var[6] === seqDB.var[6]
                                    ) {
                                        esDuplicado = true;
                                        break;
                                    }
                                }
                                if (!esDuplicado) {
                                    mejorMedidaList.push(seqDB);
                                }
                            }
                            for (let i = 0; i < this.globalDataBusiness.length; i++) {
                                if (
                                    this.globalDataBusiness[i].increEnergyMeasure === null ||
                                    this.globalDataBusiness[i].increEnergyMeasure === undefined ||
                                    this.globalDataBusiness[i].increEnergyMeasure === 0
                                ) {
                                    this.globalDataBusiness[i].increEnergyMeasure = [
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                    ];
                                }
                            }
                            for (let i = 0; i < this.globalDataBusiness.length; i++) {
                                if (
                                    this.globalDataBusiness[i].increEnergyClose === null ||
                                    this.globalDataBusiness[i].increEnergyClose === undefined ||
                                    this.globalDataBusiness[i].increEnergyClose === 0
                                ) {
                                    this.globalDataBusiness[i].increEnergyClose = [
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                        this.NULL_VALUE_SIGN,
                                    ];
                                }
                            }
                            for (let i = 0; i < this.globalDataBusiness.length; i++) {
                                this.globalDataBusiness[i].periodCode = this.periodCode;
                            }
                            this.dataBusiness = this.globalDataBusiness;
                            for (let i = 0; i < this.dataBusiness.length; i++) {
                                this.dataBusiness[i].isBestMeasuresListAvailable =
                                    this.dataBusiness[i].executionMeasure || 
                                    this.dataBusiness[i].executionClose ||
                                    this.dataBusiness[i].executionCups
                                        ? false
                                        : this.dataBusiness[i].isBestMeasuresListAvailable;
                                this.dataBusiness[i].isBestClosesListAvailable =
                                    this.dataBusiness[i].executionMeasure || 
                                    this.dataBusiness[i].executionClose ||
                                    this.dataBusiness[i].executionCups
                                        ? false
                                        : this.dataBusiness[i].isBestClosesListAvailable;
                            }
                            if (this.chargeSummary) {
                                this.populateSelectedSection(DashboardSections.SUMMARY);
                                this.chargeSummary = false;
                            } else {
                                this.fixSpinner = true;
                                this.visibilityPagination = false;
                                this.spinnerActive = false;
                            }
                        });
                });
        }
    }

    private _populateSettlementInfoSection(): void {
        //Esta es la única tab que funciona independientemente, solo se pasa por ella al hacer click en su botón. NO se utiliza para cargar Resumen
        this.dataHeaders = this.allDataHeaders;
        this.dataHeaders.forEach((element: any) => {
            if (element.name === 'dashboard_liquidacion') {
                this.dataHeaders = element.headers;
            }
        });

        this.columns = [];
        for (let i = 0; i < this.dataHeaders.length; i++) {
            let headerLabel = this.dataHeaders[i].id;
            this.columns.push(headerLabel);
        }

        this.dataHeaders_valueSelect = [];
        this.dataHeaders_valueSelect = this.dataHeaders;
        this.dataHeadersForColumns = [];
        this.dataHeadersForColumns = this.dataHeaders;

        if (!this.noData) {
            this.spinnerActive = true;
            this.fixSpinner = false;
            this.visibilityPagination = true;

            this.tabMejorMedidaCierre = false;
            let cupsFilter = this.cupListFilter.toString();
            if (this.cupListFilter.length > 1) {
                cupsFilter = cupsFilter.replaceAll(',', ';');
            }

            this._cuadroMandosService
                .getSettlements(cupsFilter, this.dateInitFilter, this.dateEndFilter)
                .subscribe((dashboard_Liquidacion) => {
                    for (let i = 0; i < this.dataBusiness.length; i++) {
                        if (dashboard_Liquidacion.data[i]) {
                            if (this.dataBusiness[i].cups === dashboard_Liquidacion.data[i].settlementGeneric.cups) {
                                this.dataBusiness[i].status = dashboard_Liquidacion.data[i].settlementGeneric.status;
                                this.dataBusiness[i].measureOrigin =
                                    dashboard_Liquidacion.data[i].settlementGeneric.measureOrigin;
                            }
                        }
                        this.dataBusiness[i].periodCode = this.periodCode;
                    }
                    for (let i = 0; i < this.dataBusiness.length; i++) {
                        this.dataBusiness[i].periodCode = this.periodCode;
                    }
                    this._cuadroMandosService
                        .getSettlementAggregatedCurves(cupsFilter, this.dateInitFilter, this.dateEndFilter)
                        .subscribe((dashboard_LiquidacionAggregated) => {
                            let dataBusinessAggregatedCurve = [];
                            dataBusinessAggregatedCurve = dashboard_LiquidacionAggregated.data;
                            let temporalDB: any = [{ cups: [], closeDateBegin: [], closeDateEnd: [], var: [] }];
                            for (let i = 0; i < dashboard_LiquidacionAggregated.data.length; i++) {
                                temporalDB.push({
                                    cups: dashboard_LiquidacionAggregated.data[i].cups,
                                    closeDateBegin: dashboard_LiquidacionAggregated.data[i].closeDateBegin,
                                    closeDateEnd: dashboard_LiquidacionAggregated.data[i].closeDateEnd,
                                    var: [],
                                });
                            }
                            for (let i = 0; i < temporalDB.length; i++) {
                                //asigna al SEQ correspondiente una variable del DB
                                temporalDB[i].var = [];
                                for (let x = 0; x < dataBusinessAggregatedCurve.length; x++) {
                                    if (
                                        temporalDB[i].cups === dataBusinessAggregatedCurve[x].cups &&
                                        temporalDB[i].closeDateBegin ===
                                            dataBusinessAggregatedCurve[x].closeDateBegin &&
                                        temporalDB[i].closeDateEnd === dataBusinessAggregatedCurve[x].closeDateEnd
                                    ) {
                                        if (
                                            dataBusinessAggregatedCurve[x].inNetEnergy === null ||
                                            dataBusinessAggregatedCurve[x].inNetEnergy === undefined ||
                                            dataBusinessAggregatedCurve[x].inNetEnergy === 0
                                        ) {
                                            if (temporalDB[i].var.length < 7) {
                                                temporalDB[i].var.push(
                                                    dataBusinessAggregatedCurve[x].inNetEnergy === 0
                                                        ? `0`
                                                        : this.NULL_VALUE_SIGN
                                                );
                                            }
                                        } else {
                                            if (temporalDB[i].var.length < 7) {
                                                temporalDB[i].var.push(dataBusinessAggregatedCurve[x].inNetEnergy);
                                            }
                                        }
                                    }
                                }
                            }
                            let liquidacionAggregated = []; //variable con la lista de SEQs
                            for (let indice = 0; indice < temporalDB.length; indice++) {
                                //Filtrar lista de SEQs para eliminar duplicados
                                const seqDB = temporalDB[indice];
                                let esDuplicado = false;
                                for (let i = 0; i < liquidacionAggregated.length; i++) {
                                    if (
                                        liquidacionAggregated[i].var[0] === seqDB.var[0] &&
                                        liquidacionAggregated[i].var[1] === seqDB.var[1] &&
                                        liquidacionAggregated[i].var[2] === seqDB.var[2] &&
                                        liquidacionAggregated[i].var[3] === seqDB.var[3] &&
                                        liquidacionAggregated[i].var[4] === seqDB.var[4] &&
                                        liquidacionAggregated[i].var[5] === seqDB.var[5] &&
                                        liquidacionAggregated[i].var[6] === seqDB.var[6]
                                    ) {
                                        esDuplicado = true;
                                        break;
                                    }
                                }
                                if (!esDuplicado) {
                                    liquidacionAggregated.push(seqDB);
                                }
                            }
                            for (let i = 0; i < liquidacionAggregated.length; i++) {
                                for (let x = 0; x < this.dataBusiness.length; x++) {
                                    if (
                                        liquidacionAggregated[i].cups === this.globalDataBusiness[x].cups &&
                                        liquidacionAggregated[i].closeDateBegin ===
                                            this.globalDataBusiness[x].closeDateBegin &&
                                        liquidacionAggregated[i].closeDateEnd ===
                                            this.globalDataBusiness[x].closeDateEnd
                                    ) {
                                        this.dataBusiness[x].bestLiquidation = liquidacionAggregated[i].var;
                                    }
                                    if (!this.dataBusiness[x].bestLiquidation) {
                                        this.dataBusiness[x].bestLiquidation = ['-', '-', '-', '-', '-', '-', '-'];
                                    }
                                }
                            }
                        });
                    this.visibilityPagination = false;
                    this.fixSpinner = true;
                    this.spinnerActive = false;
                });
        }
    }

    private _populateSummary(): void {
        //"Resumen" es la suma de las llamadas de todas las tabs que se han ido realizando, no se hace ninguna otra adicional.
        this.dataHeaders = this.allDataHeaders;
        this.dataHeaders.forEach((element: any) => {
            if (element.name === 'dashboard_summary') {
                this.dataHeaders = element.headers;
            }
        });

        this.columns = [];
        for (let i = 0; i < this.dataHeaders.length; i++) {
            let headerLabel = this.dataHeaders[i].id;
            this.columns.push(headerLabel);
        }

        this.dataBusiness = [];
        if (this.noData) {
            this.dataBusiness = this.globalDataBusiness;
            this.spinnerActive = false;
        } else {
            if (this.chargeSummary) {
                //Este es el final del bucle de llamadas tras hacer click en "Filtrar"
                //Se pasa la info de "globalDataBusiness" (toda la info sacada de las diferentes tabs) a "dataBusiness".
                this.dataBusiness = this.globalDataBusiness;
                this.globalDataBusinessPreloaded = this.globalDataBusiness;
                this.chargeSummary = false;
                for (let i = 0; i < this.dataBusiness.length; i++) {
                    this.dataBusiness[i].periodCode = this.periodCode;
                }
                if (this.isPaginating) {
                    this.isPaginating = false;
                    this.populateSelectedSection(this._cuadroMandosService.activeColumn, true);
                }
            } else {
                //Aquí se llega cuando se navega a "Resumen" desde otra tab.
                //"globalDataBusinessPreloaded" contiene una copia de toda la tabla. Se iguala a dataBusiness cuando se hace click en la tab de "Resumen",
                //para que no se tengan que volver a hacer todas las llamadas
                this.dataBusiness = this.globalDataBusinessPreloaded;
                for (let i = 0; i < this.dataBusiness.length; i++) {
                    this.dataBusiness[i].periodCode = this.periodCode;
                }
            }
        }
        this.chargeReady = true;
        this.fixSpinner = true;
        this.spinnerActive = false;
        this.visibilityPagination = false;
    }

    private _getFirstAndLastDayOfMonthInStringDateFormat(
        year: number,
        month: number
    ): { firstDay: string; lastDay: string } {
        const firstMonthDay = moment([year, month - 1]).format('YYYY-MM-DD');
        const lastMonthDay = moment(firstMonthDay).endOf('month').format('YYYY-MM-DD');
        return { firstDay: firstMonthDay, lastDay: lastMonthDay };
    }

    private _loadNotification(literal?: string, additionalMessage?: string, timeout: number = 4000): void {
        this._translateService.get('PAGE.MODALS').subscribe((message) => {
            this._snackBar.open(
                `${literal && message[literal] ? message[literal] : ''} ${additionalMessage ? additionalMessage : ''}`,
                message['ACTION_CLOSE'],
                {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                }
            );
            setTimeout(() => {
                this._snackBar.dismiss();
            }, timeout);
        });
    }

    private _getBestMeasureCurvasList(dataRow: any): string[] {
        let bestMeasureSelectOptions: string[] = this.bestMeasuresList;
        bestMeasureSelectOptions = this._checkFieldArrayValues(
            dataRow.manualInNetEnergy,
            bestMeasureSelectOptions,
            DashboardBestMeasureOptions.MANUAL
        );
        bestMeasureSelectOptions = this._checkFieldArrayValues(
            dataRow.distFInNetEnergy,
            bestMeasureSelectOptions,
            DashboardBestMeasureOptions.F_DISTRIBUTOR
        );
        bestMeasureSelectOptions = this._checkFieldArrayValues(
            dataRow.distPInNetEnergy,
            bestMeasureSelectOptions,
            DashboardBestMeasureOptions.P_DISTRIBUTOR
        );
        bestMeasureSelectOptions = this._checkFieldArrayValues(
            dataRow.hubInNetEnergy,
            bestMeasureSelectOptions,
            DashboardBestMeasureOptions.HUB
        );
        bestMeasureSelectOptions = this._checkFieldArrayValues(
            dataRow.reeInNetEnergy,
            bestMeasureSelectOptions,
            DashboardBestMeasureOptions.REE
        );
        bestMeasureSelectOptions = this._checkFieldArrayValues(
            dataRow.atrInNetEnergy,
            bestMeasureSelectOptions,
            DashboardBestMeasureOptions.ATR
        );
        bestMeasureSelectOptions = this._checkFieldArrayValues(
            dataRow.bestForecastInNetEnergy,
            bestMeasureSelectOptions,
            DashboardBestMeasureOptions.FORECASTS
        );
        bestMeasureSelectOptions = this._checkFieldArrayValues(
            dataRow.acumInNetEnergy,
            bestMeasureSelectOptions,
            DashboardBestMeasureOptions.ACUM
        );
        bestMeasureSelectOptions = this._checkFieldArrayValues(
            dataRow.inmeclosInNetEnergy,
            bestMeasureSelectOptions,
            DashboardBestMeasureOptions.INMECLOS
        );
        bestMeasureSelectOptions = this._checkFieldArrayValues(
            dataRow.awsInNetEnergy,
            bestMeasureSelectOptions,
            DashboardBestMeasureOptions.AWS
        );

        if (
            bestMeasureSelectOptions.length === 0 ||
            (bestMeasureSelectOptions.length === 1 &&
                (bestMeasureSelectOptions[0] === null ||
                    bestMeasureSelectOptions[0] === undefined ||
                    bestMeasureSelectOptions[0] === '' ||
                    bestMeasureSelectOptions[0] === this.NULL_VALUE_SIGN))
        ) {
            return [dataRow.origenBestMeasure];
        }
        return bestMeasureSelectOptions;
    }

    private _getBestClosesList(dataRow: any): string[] {
        let bestCloseSelectOptions: string[] = this.bestClosesList;
        bestCloseSelectOptions = this._checkFieldArrayValues(
            dataRow.manualIncreEnergy,
            bestCloseSelectOptions,
            DashboardBestCloseOptions.MANUAL
        );
        bestCloseSelectOptions = this._checkFieldArrayValues(
            dataRow.distributorIncreEnergy,
            bestCloseSelectOptions,
            DashboardBestCloseOptions.DISTRIBUTOR
        );
        bestCloseSelectOptions = this._checkFieldArrayValues(
            dataRow.hubIncreEnergy,
            bestCloseSelectOptions,
            DashboardBestCloseOptions.HUB
        );
        bestCloseSelectOptions = this._checkFieldArrayValues(
            dataRow.atrIncreEnergy,
            bestCloseSelectOptions,
            DashboardBestCloseOptions.ATR
        );
        bestCloseSelectOptions = this._checkFieldArrayValues(
            dataRow.mixedIncreEnergy,
            bestCloseSelectOptions,
            DashboardBestCloseOptions.MIXED
        );
        bestCloseSelectOptions = this._checkFieldArrayValues(
            dataRow.hourlyIncreEnergy,
            bestCloseSelectOptions,
            DashboardBestCloseOptions.HOURLY
        );
        bestCloseSelectOptions = this._checkFieldArrayValues(
            dataRow.quarterHourlyIncreEnergy,
            bestCloseSelectOptions,
            DashboardBestCloseOptions.QUARTER_HOURLY
        );
        bestCloseSelectOptions = this._checkFieldArrayValues(
            dataRow.awsBestIncreEnergy,
            bestCloseSelectOptions,
            DashboardBestCloseOptions.AWS
        ); 

        if (
            bestCloseSelectOptions.length === 0 ||
            (bestCloseSelectOptions.length === 1 &&
                (bestCloseSelectOptions[0] === null ||
                    bestCloseSelectOptions[0] === undefined ||
                    bestCloseSelectOptions[0] === '' ||
                    bestCloseSelectOptions[0] === this.NULL_VALUE_SIGN))
        ) {
            return [];
        }
        return bestCloseSelectOptions;
    }

    private _checkFieldArrayValues(fieldValues: any, optionsSelect: string[], optionToFilter: string): string[] {
        if (fieldValues && fieldValues.length > 0) {
            let hasFieldArrayValues = fieldValues.some((value: any) => {
                return value !== this.NULL_VALUE_SIGN && value !== 0 && value !== null && value !== undefined;
            });
            if (!hasFieldArrayValues) {
                optionsSelect = optionsSelect.filter((option: string) => option !== optionToFilter);
            }
        }
        return optionsSelect;
    }

    private _isBestMeasureAvailable(data: any): boolean {
        return data.availableBestMeasuresList &&
            data.availableBestMeasuresList.length > 1 &&
            !data.executionMeasure &&
            !data.executionClose &&
            !data.executionCups
            ? true
            : false;
    }

    private _isBestCloseAvailable(data: any): boolean {
        return data.availableBestClosesList &&
            data.availableBestClosesList.length > 0 &&
            !data.executionMeasure &&
            !data.executionClose &&
            !data.executionCups
            ? true
            : false;
    }

    private _refillEmptyDefinitiveBestClosePeriods(data: any, cupsList: any, closeDateBegin: any, closeDateEnd: any) {
        let resp: any[] = [];
        // Si algún CUPS no trae todos los periodos se agregan vacíos los que falten
        data.forEach((register: any) => {
            if (register.periodCode === 20) {
                resp.push(register);
                const registerCopy = data.filter((registerCopy: any) => {
                    return (
                        registerCopy.cups === register.cups &&
                        registerCopy.closeDateBegin === register.closeDateBegin &&
                        registerCopy.closeDateEnd === register.closeDateEnd
                    );
                });
                for (let i = 1; i < 7; i++) {
                    if (!registerCopy.some((registerCopy: any) => registerCopy.periodCode === i)) {
                        resp.push({
                            cups: register.cups,
                            closeDateBegin: register.closeDateBegin,
                            closeDateEnd: register.closeDateEnd,
                            periodCode: i,
                            increEnergy: '-',
                        });
                    } else {
                        resp.push(
                            data.filter(
                                (registerCopy: any) =>
                                    registerCopy.cups === register.cups &&
                                    registerCopy.closeDateBegin === register.closeDateBegin &&
                                    registerCopy.closeDateEnd === register.closeDateEnd &&
                                    registerCopy.periodCode === i
                            )[0]
                        );
                    }
                }
            }
        });
        // Si algún CUPS no trae ningún periodo se agrega vacío completamente
        if (cupsList && cupsList.length > 0) {
            cupsList.forEach((cups: string) => {
                if (!resp.some((register: any) => register.cups === cups)) {
                    for (let i = 0; i < 7; i++) {
                        resp.push({
                            cups,
                            closeDateBegin,
                            closeDateEnd,
                            periodCode: i === 0 ? 20 : i,
                            increEnergy: '-',
                        });
                    }
                }
            });
        }
        return resp;
    }

    private _formatNumber(value: string): string {
        if (value !== null && !isNaN(Number.parseInt(value)) && !/[a-zA-Z@#$%^&*()_+{}\[\]:;<>?//-]/.test(value)) {
            value =
                value.includes('.') && this._headerTitleService.currentLang !== 'en'
                    ? value.replaceAll('.', ',')
                    : value;
            const decimalSeparator: '.' | ',' = this._headerTitleService.currentLang === 'en' ? '.' : ',';
            const thousandsSeparator: '.' | ',' = this._headerTitleService.currentLang === 'en' ? ',' : '.';
            if (value.includes(decimalSeparator)) {
                const valueParts: any[] = value.split(decimalSeparator);
                const decimalPart = valueParts[1] || '';
                const formattedInteger = valueParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
                return decimalPart ? `${formattedInteger}${decimalSeparator}${decimalPart}` : formattedInteger;
            } else {
                return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
            }
        } else {
            return '_';
        }
    }

    private _startRecursiveRequests(): void {
        this.dataBusiness.forEach((data) => {
            // Si hay algún proceso de ejecución y no está en el array de peticiones pendientes se inicia la llamada recursiva
            const {
                seq,
                cups,
                closeDateBegin,
                closeDateEnd,
                executionMeasure,
                executionClose,
                executionCups,
                origenBestMeasure,
                originBestClose,
            } = data;
            if (
                (executionMeasure || executionClose || executionCups) &&
                !this.activeRequests.some(
                    (register: { cups: string; closeDateBegin: string; closeDateEnd: string }) =>
                        register.cups === cups &&
                        register.closeDateBegin === closeDateBegin &&
                        register.closeDateEnd === closeDateEnd
                )
            ) {
                const executionType = executionMeasure ? 'measure' : (executionClose ? 'close' : 'cups');
                this._startRecursiveRequest({ cups, closeDateBegin, closeDateEnd }, executionType);
            }
            this.selectedOriginBestMeasure.push({ seq, bestMeasure: origenBestMeasure });
            this.selectedOriginBestClose.push({ seq, bestClose: originBestClose });
            data.availableBestMeasuresList = this._getBestMeasureCurvasList(data);
            data.isBestMeasuresListAvailable = this._isBestMeasureAvailable(data);
            data.availableBestClosesList = this._getBestClosesList(data);
            data.isBestClosesListAvailable = this._isBestCloseAvailable(data);
        });
    }

    private _startRecursiveRequest(register: any, executionType: string): void {
        // Encuentra el índice del registro que se va a modificar
        const { cups, closeDateBegin, closeDateEnd } = register;
        const indexRow: number = this.dataBusiness.findIndex(
            (element: any) =>
                cups === element.cups &&
                closeDateBegin === element.closeDateBegin &&
                closeDateEnd === element.closeDateEnd
        );
        if (indexRow !== -1 && closeDateBegin !== undefined && closeDateEnd !== undefined) {
            // Deshabilita la fila del registro que se va a modificar
            this._disableOrEnableRow(indexRow, false);
            // Guarda el registro en array de peticiones pendientes
            if (
                !this.activeRequests.some(
                    (register: { cups: string; closeDateBegin: string; closeDateEnd: string }) =>
                        register.cups === cups &&
                        register.closeDateBegin === closeDateBegin &&
                        register.closeDateEnd === closeDateEnd
                )
            ) {
                const indexRequest: number = this.activeRequests.push({ cups, closeDateBegin, closeDateEnd }) - 1;
                if (executionType == 'measure') {
                    this.dataBusiness[indexRow].executionMeasure = true;
                    this.globalDataBusiness[indexRow].executionMeasure = true;
                    this.globalDataBusinessPreloaded[indexRow].executionMeasure = true;
                } else if (executionType == 'close') {
                    this.dataBusiness[indexRow].executionClose = true;
                    this.globalDataBusiness[indexRow].executionClose = true;
                    this.globalDataBusinessPreloaded[indexRow].executionClose = true;
                } else if (executionType == 'cups') {
                    this.dataBusiness[indexRow].executionCups = true;
                    this.globalDataBusiness[indexRow].executionCups = true;
                    this.globalDataBusinessPreloaded[indexRow].executionCups = true;
                }
                const filters = `cups=(${cups}),closeDateBegin>=${closeDateBegin},closeDateEnd<=${closeDateEnd}`;
                // Intervalo de 30 segundos con límite de 20 repeticiones
                interval(30000).subscribe(() => {
                    let end = false;
                    if (!end && this.isInDashboard) {
                        this._cuadroMandosService
                            .checkRequestStatus(filters)
                            .subscribe((dashboard_infoTecnica: any) => {
                                const newRegister = dashboard_infoTecnica.data[0];
                                // Si la respuesta devuelve que el registro no está en ningún proceso se borra su CUPS del array de
                                // peticiones pendientes, rehabilita la fila y actualiza el registro
                                if (newRegister.executionMeasure === false 
                                    && newRegister.executionClose === false
                                    && newRegister.executionCups === false) {
                                    // TODO: agregar una llamada a un método que bloquee la navegación entre tabs mientras se cargan datos (para evitar fallos)
                                    this.activeRequests[indexRequest].cups = '';
                                    end = true;
                                    this._disableOrEnableRow(indexRow, true);
                                    this.loadTable(this.event, this.search);
                                }
                            });
                    }
                });
            }
        }
    }

    private _disableOrEnableRow(indexRow: number, action: boolean): void {
        if (this.dataBusiness[indexRow] && this.dataBusiness[indexRow].isBestClosesListAvailable === undefined) {
            this.dataBusiness[indexRow].isBestClosesListAvailable = action;
            this.dataBusiness[indexRow].isBestMeasuresListAvailable = action;
        }
        this.globalDataBusiness[indexRow].isBestClosesListAvailable = action;
        this.globalDataBusiness[indexRow].isBestMeasuresListAvailable = action;
        if (this.globalDataBusinessPreloaded && this.globalDataBusinessPreloaded[indexRow]) {
            this.globalDataBusinessPreloaded[indexRow].isBestClosesListAvailable = action;
            this.globalDataBusinessPreloaded[indexRow].isBestMeasuresListAvailable = action;
        }
    }

    private _populateBodyData(cupsList: string[]) {
        let splittedDates = this.filteredDates.split(',');
        let initialDate = splittedDates[0].substring(splittedDates[0].length-10,splittedDates[0].length);
        let endedDate = splittedDates[1].substring(splittedDates[1].length-10,splittedDates[1].length);

        let filtersBodyPost = {
            initDate: initialDate,
            endDate: endedDate,
            closeType: 'Dashboard',
            cups: cupsList,
            processCode: 'APR0001'
        };
        let programProcessBody: any = {
            processCode: 'APR0001',
            name: 'CalculateDashboard',
            initDate: null,
            endDate: null,
            filters: filtersBodyPost,
            cron: null,
        };
        programProcessBody.filters = JSON.stringify(programProcessBody.filters);
        return programProcessBody;
    }

    private async _filterNotBlockedCups(dataBusinessLocal: any[]) {
        var notBlockedCups: any[] = [];

        for (let i = 0; i < dataBusinessLocal.length; i++) {
            const register = dataBusinessLocal[i];
            const cups = register.cups;
            const initialDate = register.closeDateBegin;
            const endedDate = register.closeDateEnd;
            const filters = `cups=(${cups}),closeDateBegin>=${initialDate},closeDateEnd<=${endedDate}`;
            const response$:any = await firstValueFrom(this._cuadroMandosService.checkRequestStatus(filters));
            if (response$.data) {
                const newRegister = response$.data[0];
                if (newRegister.executionMeasure === false 
                    && newRegister.executionClose === false
                    && newRegister.executionCups === false) {
                    notBlockedCups.push(newRegister);
                }
            }
        }
        
        return notBlockedCups;
    }
}
