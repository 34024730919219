<h1>{{ label }}:</h1>
<div class="filter_variable_container">
    <div *ngIf="showDay" class="days_container">
        <span>{{ 'PAGE.FILTERS.SELECT.DAY' | translate }}</span>
        <mat-radio-group>
            <mat-radio-button
                *ngFor="let radio of radioButtons"
                [value]="radio"
                [checked]="radioDaySelected === radio ? radioDaySelected : undefined"
                (click)="onClickValueChange(radio, 'd')"
                color="warn"
                >{{ radio }}</mat-radio-button
            >
        </mat-radio-group>
        <mat-form-field appearance="standard" class="select">
            <mat-select [(ngModel)]="daySelectedNumber" [disabled]="!radioDaySelected">
                <mat-option *ngFor="let day of daysList" [value]="day" (click)="onClickValueChange(day, 'd')">
                    {{ day }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="months_container">
        <span>{{ 'PAGE.FILTERS.SELECT.MONTH' | translate }}</span>
        <mat-radio-group>
            <mat-radio-button
                *ngFor="let radio of radioButtons"
                [value]="radio"
                [checked]="radioMonthSelected === radio ? radioMonthSelected : undefined"
                (click)="onClickValueChange(radio, 'm')"
                color="warn"
                >{{ radio }}</mat-radio-button
            >
        </mat-radio-group>

        <mat-form-field appearance="standard" class="select">
            <mat-select [(ngModel)]="monthSelectedNumber" [disabled]="!radioMonthSelected">
                <mat-option *ngFor="let month of monthsList" [value]="month" (click)="onClickValueChange(month, 'm')">
                    {{ month }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="years_container">
        <span>{{ 'PAGE.FILTERS.SELECT.YEAR' | translate }}</span>
        <mat-radio-group>
            <mat-radio-button
                *ngFor="let radio of radioButtons"
                [value]="radio"
                [checked]="radioYearSelected === radio ? radioYearSelected : undefined"
                (click)="onClickValueChange(radio, 'y')"
                color="warn"
                >{{ radio }}</mat-radio-button
            >
        </mat-radio-group>

        <mat-form-field appearance="standard" class="select">
            <mat-select [(ngModel)]="yearSelectedNumber" [disabled]="!radioYearSelected">
                <mat-option *ngFor="let year of yearsList" [value]="year" (click)="onClickValueChange(year, 'y')">
                    {{ year }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div>
    <span>{{ labelMessage + ': ' + formattedDateString }}</span>
</div>
