import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Files } from 'src/app/shared/interfaces/cargas.interface';
import { StatusEnum } from 'src/app/shared/enums/cargas';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class CargasService {
    searchBy: boolean = false;
    posts: any[];

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    // Parametro de busqueda para los endpoint
    searchParam: string;

    // Variable para controlar si mostrar la tabla de conceptos de contratos con unas columnas u otras.
    showColumnsBy: string;

    constructor(private _http: HttpClient, private _snackBar: MatSnackBar,
        private translate: TranslateService) {}

    getCargas(): Observable<Files[]> {
        const url = `${environment.apiUrl}/file/v0?page=-1`;
        return this._http.get<any>(url).pipe(
            map((e) => {
                return e.data as Files[];
            })
        );
    }

    getFiles(): Observable<Files[]> {
        const url = `${environment.apiUrl}/file-process/v0?page=-1&sortBy=id&sortDir=asc`;
        return this._http.get<any>(url).pipe(
            map((e) => {
                return e.data as Files[];
            })
        );
    }

    createGetRequets(data: string[], getRequests: any) {
        data.forEach((url) => getRequests.push(this._http.get(url, { responseType: 'blob' })));
    }

    downloadFile(route: string, filename: string = 'descarga'): void {
        const route2 = `${environment.apiUrl}/file-process/v0/download?fileS3=${route}`;
        this._http.get(route2, { responseType: 'blob' as 'json' }).subscribe((response: any) => {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

            if (filename) downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);

            downloadLink.click();
        });
    }

    downloadAllFiles() {
        const apiUrl = 'http://localhost:8090';
        const url = `${apiUrl}/file-process/v0/0`;

        return this._http.get<any>(url);
    }

    /**
     * Conecta con el botón 'Solventar' para enviar petición de arreglo de un error
     * @param element
     */
    fixError(element: any) {
        // Tomamos las variables del elemento que irán en el body
        const elementDescription = element.description;
        const elementStatus = 'KO_SOLVED';
        const elementInitDate = element.initDate;
        const elementDateEnd = element.endDate;
        const elementId = element.id;
        const body = {
            description: elementDescription,
            status: elementStatus,
            initDate: elementInitDate,
            endDate: elementDateEnd,
        };

        const url = `${environment.apiUrl}/file-process/v0/${elementId}`;

        this._snackBar.open('Se ha enviado su petición de solvención correctamente', 'CERRAR', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });

        return this._http.put<any>(url, body);
    }

    getConceptsResults(): Observable<any> {
        const url = './assets/json/conceptos-cargas.json';
        return this._http.get<any>(url).pipe(map((e) => e.conceptosResults));
    }

    /**
     * Get Resultados
     * @returns
     */
    getTablaResultados(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        let url = '';
        if (environment.isMockActive) {
            url = './assets/json/tabla-resultados.json';
            return this._http.get<any[]>(url);
        } else {
            if (pageSize == 0) {
                url = `${environment.apiUrl}/file-process/v0?page=-1&sortBy=${sortBy}&sortDir=${sortDir}&search=${search}`;
            } else {
                url = `${environment.apiUrl}/file-process/v0?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&search=${search}`;
            }
            if (isExport) {
                url += '&isExcel=true';
            }
            return this._http.get<any[]>(url);
        }
    }

    getHeaders() {
        const url = `${environment.apiUrl}/header/v0?page=0&search=0`;
        return this._http.get<any[]>(url);
    }

    /**
     * Get Resultados
     * @returns
     */
    getResultados(page: number, pageSize: number, sortBy: string, sortDir: string): Observable<any> {
        let url = '';
        if (pageSize == 0) {
            url = `${environment.apiUrl}/file-process/v0?page=-1&sortBy=${sortBy}&sortDir=${sortDir}`;
        } else {
            url = `${environment.apiUrl}/file-process/v0?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`;
        }
        return this._http.get<any[]>(url);
    }

    getSelects(): Observable<any> {
        if (environment.isMockActive) {
            return of('isMockActive ContractState');
        } else {
            const url = `${environment.apiUrl}/file/v0?page=-1`;
            return this._http.get<any[]>(url);
        }
    }

    /**
     * Get Fees
     * @returns
     */
    getFeesTable(page: number, pageSize: number, sortBy: string, sortDir: string): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-tarifas.json';
            return this._http.get<any>(url);
        } else {
            const url = `${environment.apiUrl}/rate/v0?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`;
            return this._http.get<any[]>(url);
        }
    }

    getTablaPrecios() {
        const url = './assets/json/tabla-precios-regulados.json';
        return this._http.get<any>(url);
    }

    getTablaPreciosSort(sort: any) {
        if (sort.direction == 'asc') {
            const url = './assets/json/tabla-precios-regulados-sort-asc.json';
            return this._http.get<any>(url);
        } else {
            if (sort.direction == 'desc') {
                const url = './assets/json/tabla-precios-regulados-sort-desc.json';
                return this._http.get<any>(url);
            } else {
                const url = './assets/json/tabla-precios-regulados.json';
                return this._http.get<any>(url);
            }
        }
    }

    getTablaPreciosPaginationOne() {
        const url = './assets/json/tabla-precios-regulado-pagination-one.json';
        return this._http.get<any>(url);
    }

    getTablaPreciosPaginationTwo() {
        const url = './assets/json/tabla-precios-regulado-pagination-two.json';
        return this._http.get<any>(url);
    }

    getCargasMedidas(): Observable<any> {
        //Obtener Medidas
        const url = './assets/json/tabla_cargas_manual.json';
        return this._http.get<any>(url).pipe(map((e) => e.medidas));
    }

    getCargasPrevisiones(): Observable<any> {
        //Obtener previsiones
        const url = './assets/json/tabla_cargas_manual.json';
        return this._http.get<any>(url).pipe(map((e) => e.previsiones));
    }

    getSelectSistemas(): Observable<any> {
        //Obtener filtro "Sistemas" (Medidas/previsiones)
        const url = './assets/json/filtros.json';
        return this._http.get<any>(url).pipe(map((e) => e.filters));
    }

    /**
     * Función para mapear el status que viene de BBDD para el front
     * @param status
     * @returns status mapeado
     */
    mapCargasStatus(status: string): string {
        switch (status) {
            case 'ATR_CUPS_ERROR':
                status = StatusEnum.CUPS_NOT_EXISTS;
                break;
            case 'FILE_ALREADY_PROCESSED':
                status = StatusEnum.FILE_ALREADY_PROCESSED;
                break;
            case 'INIT_CATALOG':
            case 'INIT_PROCESS':
            case 'OK_CATALOG':
            case 'OK_CATALOG_WITH_ERRORS':
                status = StatusEnum.IN_PROCESS;
                break;
            case 'OK_PROCESS':
                status = StatusEnum.COMPLETED;
                break;
            case 'KO_CATALOG_SYSTEM':
            case 'KO_PROCESS_SYSTEM':
                status = StatusEnum.ERROR;
                break;
            case 'KO_SOLVED':
                status = StatusEnum.ERROR_SOLVED;
                break;
            case 'OK_PROCESS_WITH_ERRORS':
                status = StatusEnum.COMPLETED_WITH_ERRORS;
                break;
            case 'SCHEMA_ATR_INVALID':
                status = StatusEnum.SCHEMA_ATR_INVALID;
                break;
            case 'ATR_POWER_PERIOD_ERROR':
                status = StatusEnum.ATR_POWER_PERIOD_ERROR;
                break;
            case 'ATR_ENERGY_PERIOD_ERROR':
                status = StatusEnum.ATR_ENERGY_PERIOD_ERROR;
                break;
            case 'ATR_CONTRACT_CONCEPTS_ERROR':
                status = StatusEnum.ATR_CONTRACT_CONCEPTS_ERROR;
                break;
            case 'ATR_POWER_COMPARISON_ERROR':
                status = StatusEnum.ATR_POWER_COMPARISON_ERROR;
                break;
            case 'ATR_INVOICE_FISCAL_CODE_DUPLICATED_ERROR':
                status = StatusEnum.ATR_INVOICE_FISCAL_CODE_DUPLICATED_ERROR;
                break;
            default:
                break;
        }
        return status;
    }

    mapCargasDetailErrors(error:any):any {
        var errorMessage = '';
        if (error.length > 0) {
            const line = this.translate.instant('PAGE.LOADS.ROW_NUMBER');
            error.sort((a: any, b: any) => a.rowNum - b.rowNum);
            error.forEach((e:any) => {
                errorMessage += line + ' ' + e.rowNum + ': ' + e.description + '\n'
            });
        }
        return errorMessage;
    }
}
