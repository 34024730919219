export enum ContractTypes {
    PRECIO_FIJO = 'Precio fijo',
    PRECIO_INDEXADO = 'Precio indexado',
}

export enum FictitiousContractsFormFields {
    CODIGO_CONTRATO = 'Código contrato',
    DESCRIPCION = 'Descripción',
}

export enum GestionATR {
    YES = 'YES',
    NO = 'NO',
}

export enum AdjMecGas {
    SI = 'YES',
    NO = 'NO',
    SN = 'SN',
}
