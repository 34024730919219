import { Component, OnInit, OnChanges, Input } from '@angular/core';

@Component({
    selector: 'app-base-icon',
    templateUrl: './base-icon.component.html',
    styleUrls: ['./base-icon.component.scss'],
})
export class BaseIconComponent implements OnInit, OnChanges {
    @Input() iconStyle: 'filled' | 'outlined' | 'two-tone' | 'round' | 'sharp' = 'filled';
    @Input() iconName = '';
    @Input() color = '';
    @Input() size: number | undefined;

    get iconClass() {
        return `material-icons-${this.iconStyle}`;
    }

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(): void {
        this.iconName = this.iconName;
    }
}
