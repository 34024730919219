<div class="modal-title-close">
    <h1 mat-dialog-title>{{ 'PAGE.MODALS.TITLE11' | translate }}</h1>
    <button mat-button mat-dialog-close class="hide-access close" (click)="close(false)">close</button>
</div>
<div>
    <div class="container" *ngIf="boundaryPointsData?.length > 0">
        <table mat-table [dataSource]="boundaryPointsData" multiTemplateDataRows matSort>
            <div style="max-width: fit-content" [matColumnDef]="column.id" *ngFor="let column of this.dataHeaders">
                <!-- Header TH -->
                <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                    <div class="header-table d-flex">
                        {{ column.label }}
                    </div>
                </th>
                <!-- Element TD -->
                <td mat-cell *matCellDef="let element; let i = dataIndex">
                    <!-- Element TD Text -->
                    <div *ngIf="column.type === 'text' && column.id !== 'edit'">
                        <span>{{ element[column.id] }}</span>
                    </div>
                    <div class="editColumn" *ngIf="column.id === 'edit' && boundaryPointsPermissions?.edit">
                        <span (click)="edit(i)" class="icon material-symbols-outlined">edit</span>
                    </div>
                </td>
            </div>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9999">{{ 'PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA' | translate }}</td>
            </tr>
        </table>
    </div>
    <div class="container" *ngIf="boundaryPointsData?.length === 0">
        <h3>{{ 'PAGE.MODALS.NOT_MEASURE_POINTS' | translate }}</h3>
    </div>
</div>
