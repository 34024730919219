import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';

//PAGES
import { HomePageComponent } from './pages/home-page/home-page.component';

// COMPONENTS
import { LoginComponent } from './pages/login/login/login.component';
import { DetailsPageComponent } from './pages/maestro/components/tabla-maestro/details-page/details-page.component';
import { ErrorPageComponent } from './pages/error/components/error-page/error-page.component';
import { ManagementUserComponent } from './pages/user_management/components/management-user/management-user.component';
import { SelectProcesosComponent } from './pages/procesos/components/select-procesos/select-procesos.component';
import { MaestroCRMPageComponent } from './pages/maestro/maestro-crm-page/maestro-crm-page.component';
import { MaestroAWSPageComponent } from './pages/maestro/maestro-aws-page/maestro-aws-page.component';
import { SelectMaestroComponent } from './pages/maestro/components/select-maestro/select-maestro.component';
import { TablaMaestroComponent } from './pages/maestro/components/tabla-maestro/tabla-maestro.component';
import { AddModalComponent } from './pages/maestro/components/tabla-maestro/add-modal/add-modal.component';
import { EditModalComponent } from './pages/maestro/components/tabla-maestro/edit-modal/edit-modal.component';
import { EditModalComponentPrevisions } from './pages/previsiones/components/tabla-previsiones/edit-modal/edit-modal.component';
import { DeleteModalComponent } from './pages/maestro/components/tabla-maestro/delete-modal/delete-modal-component';
import { HomeCarouselComponent } from './shared/components/home-carousel/home-carousel.component';

// cargas
import { CargasResultadoComponent } from './pages/cargas/cargas-resultado/cargas-resultado.component';
import { SelectCargasComponent } from './pages/cargas/components/select-cargas/select-cargas.component';
import { SelectCargasResComponent } from './pages/cargas/components/select-cargas-res/select-cargas-res.component';
import { TablaCargasComponent } from './pages/cargas/components/tabla-cargas/tabla-cargas.component';
import { AddModalResultsComponent } from './pages/cargas/components/tabla-cargas/add-modal/add-modal.component';
import { EditModalResultsComponent } from './pages/cargas/components/tabla-cargas/edit-modal/edit-modal.component';
import { DeleteModalResultsComponent } from './pages/cargas/components/tabla-cargas/delete-modal/delete-modal-component';
import { ErrorModalComponent } from './pages/cargas/components/tabla-cargas/error-modal/error-modal.component';

// procesos
import { ProcesosLanzarComponent } from './pages/procesos/procesos-lanzar/procesos-lanzar.component';
import { ProcesosResultadosComponent } from './pages/procesos/procesos-resultados/procesos-resultados.component';
import { SelectProcesosResComponent } from './pages/procesos/components/select-procesos-res/select-procesos-res.component';
import { TablaProcesosResComponent } from './pages/procesos/components/tabla-procesos-res/tabla-procesos-res.component';
import { AddModalProcessResComponent } from './pages/procesos/components/tabla-procesos-res/add-modal-process-res/add-modal-process-res.component';
import { EditModalProcessResComponent } from './pages/procesos/components/tabla-procesos-res/edit-modal-process-res/edit-modal-process-res.component';
import { DeleteModalProcessResComponent } from './pages/procesos/components/tabla-procesos-res/delete-modal-process-res/delete-modal-process-res.component';

//MODULES
import { SharedModule } from './shared/modules/shared.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

//SERVICES
import { ExportService } from './services/export.service';

//TRANSLATE
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TableNoDataComponent } from './shared/components/table-no-data/table-no-data.component';
import { NumberFormatPipe } from './shared/pipes/number-format.pipe';

//SPINNER
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { SpinnerInterceptor } from './shared/interceptors/spinner.interceptor';

//MATERIAL
import { MaterialModule } from './shared/modules/material.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from './shared/material-configurations/custom-paginator';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

//AMPLIFY
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';

//HTTP_ERROR
import { HttpErrorComponent } from './shared/components/httpError/httperror.component';
import { httpErrorInterceptor } from './shared/interceptors/httperror.interceptor';
import { ProcesosProgramarComponent } from './pages/procesos/procesos-programar/procesos-programar.component';

// interceptors & auth-gaurd
import { HttpInterceptorProviders } from './shared/interceptors/interceptor-provider';
import { AuthGuard } from './core/guards/auth.guard';
import { SelectCuadroMandosComponent } from './pages/cuadro-mandos/components/select-cuadro-mandos/select-cuadro-mandos.component';
import { CuadroMandosComponent } from './pages/cuadro-mandos/cuadro-mandos-page/cuadro-mandos-page.component';
import { FiltroMonthComponent } from './shared/components/filtros/filtros_month/filtro-month/filtro-month.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ShowModalComponent } from './pages/maestro/components/tabla-maestro/show-modal/show-modal.component';
import { FiltroToggleComponent } from './shared/components/filtros/filtro-check-toggle/filtro-toggle/filtro-toggle.component';
import { BoundaryPointsModalComponent } from './pages/maestro/components/tabla-maestro/boundary-points-modal/boundary-points-modal.component';
import { AlertModalComponent } from './shared/components/alert-modal/alert-modal.component';
import { EnviosLanzarComponent } from './pages/envios/envios-lanzar/envios-lanzar.component';
import { SelectPrevisionesComponent } from './pages/previsiones/components/select-previsiones/select-previsiones.component';
import { TablaPrevisionesComponent } from './pages/previsiones/components/tabla-previsiones/tabla-previsiones.component';
import { PrevisionesAgregadoPageComponent } from './pages/previsiones/previsiones-agregado-page/previsiones-agregado-page.component';
import { MejorPrevisionPageComponent } from './pages/previsiones/mejor-prevision-page/mejor-prevision-page.component';
import { SelectMejorPrevisionComponent } from './pages/previsiones/components/select-mejor-prevision/select-mejor-prevision.component';

import { FiltroDateVariableComponent } from './shared/components/filtros/filtro-date-variable/filtro-date-variable.component';
import { FormsModule } from '@angular/forms';
import { CronEditorModule } from 'ngx-cron-editor';
import { MatCarouselModule } from 'material2-carousel';
import { CustomCronComponent } from './shared/components/custom-cron/custom-cron.component';
import { CustomCronTimePickerComponent } from './shared/components/custom-cron-time-picker/custom-cron-time-picker.component';
import { EnviosResultadoComponent } from './pages/envios/envios-resultado/envios-resultado.component';
import { FiltroDateRangeComponent } from './shared/components/filtros/filtro-date-range/filtro-date-range.component';
import { ContratosFicticiosComponent } from './pages/cargas/contratos-ficticios/contratos-ficticios.component';
import { ClaimDetailsPageComponent } from './pages/maestro/components/tabla-maestro/claim-details-page/claim-details-page.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SelectEjecucionInformesComponent } from './pages/informes/ejecucion/components/select-ejecucion-informes/select-ejecucion-informes.component';
import { EjecucionInformesPageComponent } from './pages/informes/ejecucion/ejecucion-informes-page/ejecucion-informes-page.component';
import { SelectInformesResultadoComponent } from './pages/informes/resultados/components/select-informes-resultado/select-informes-resultado.component';
import { TablaInformesComponent } from './pages/informes/resultados/components/tabla-informes-resultado/tabla-informes-resultado.component';
import { InformesResultadoPageComponent } from './pages/informes/resultados/informes-resultado-page/informes-resultado-page.component';
import { NotImplementedComponent } from './pages/not-implemented/not-implemented.component';
import { HealthCheckComponent } from './pages/health-check/health-check.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NoopAnimationsModule,
        MaterialModule,
        SharedModule,
        MatDialogModule,
        AmplifyAngularModule,
        NgxSpinnerModule,
        FormsModule,
        CronEditorModule,
        MatCarouselModule.forRoot(),
        // AmplifyAuthenticatorModule,
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    declarations: [
        AppComponent,
        HomePageComponent,
        SelectMaestroComponent,
        TablaMaestroComponent,
        HomeCarouselComponent,
        MaestroCRMPageComponent,
        MaestroAWSPageComponent,
        EditModalComponent,
        EditModalComponentPrevisions,
        DeleteModalComponent,
        AddModalComponent,
        TableNoDataComponent,
        NumberFormatPipe,
        SpinnerComponent,
        CargasResultadoComponent,
        SelectCargasResComponent,
        TablaCargasComponent,
        AddModalResultsComponent,
        DeleteModalResultsComponent,
        EditModalResultsComponent,
        ErrorModalComponent,
        ProcesosLanzarComponent,
        ProcesosResultadosComponent,
        SelectProcesosResComponent,
        TablaProcesosResComponent,
        AddModalProcessResComponent,
        EditModalProcessResComponent,
        DeleteModalProcessResComponent,
        SelectCargasComponent,
        ErrorPageComponent,
        LoginComponent,
        SelectProcesosComponent,
        DetailsPageComponent,
        ManagementUserComponent,
        HttpErrorComponent,
        ProcesosProgramarComponent,
        SelectCuadroMandosComponent,
        CuadroMandosComponent,
        FiltroMonthComponent,
        ShowModalComponent,
        FiltroToggleComponent,

        BoundaryPointsModalComponent,
        AlertModalComponent,
        EnviosLanzarComponent,
        FiltroDateVariableComponent,
        CustomCronComponent,
        CustomCronTimePickerComponent,
        SelectPrevisionesComponent,
        TablaPrevisionesComponent,
        PrevisionesAgregadoPageComponent,
        MejorPrevisionPageComponent,
        SelectMejorPrevisionComponent,
        EnviosResultadoComponent,
        FiltroDateRangeComponent,
        ContratosFicticiosComponent,
        ClaimDetailsPageComponent,
        SelectEjecucionInformesComponent,
        EjecucionInformesPageComponent,
        SelectInformesResultadoComponent,
        TablaInformesComponent,
        InformesResultadoPageComponent,
        NotImplementedComponent,
        HealthCheckComponent,
        // FiltroInputMultiComponent
    ],
    providers: [
        ExportService,
        DatePipe,
        AmplifyService,
        AuthGuard,
        HttpInterceptorProviders,
        {
            provide: MatDialogRef,
            useValue: {},
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: httpErrorInterceptor,
            multi: true,
        },
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlCro,
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'es-ES',
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
