<div class="modal-title-close">
    <h1 mat-dialog-title>{{ 'PAGE.MODALS.TITLE8' | translate }}</h1>
    <button mat-button mat-dialog-close class="hide-access close">close</button>
</div>
<div mat-dialog-content class="errorBlock">
    <table class="table-latitude">
        <thead>
            <th class="th1">{{ 'PAGE.LOADS.ROW_NUMBER' | translate }}</th>
            <th class="th2">{{ 'PAGE.LOADS.ERROR_MESSAGE' | translate }}</th>
        </thead>

        <tbody>
            <ng-container *ngFor="let elemento of data.value">
                <tr>
                    <th>{{ 'PAGE.LOADS.LINE' | translate }} {{ elemento['rowNum'] }}</th>
                    <td>{{ elemento['description'] }}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
