import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { FiltroSelectComponent } from 'src/app/shared/components/filtros/filtro-select/filtro-select.component';
import { MaestrosCRM } from 'src/app/shared/enums/maestros';
import { KeysPrevisiones } from 'src/app/shared/enums/previsiones';
import { Concepto } from 'src/app/shared/models/concepto.interface';
import { Filtro, FiltroSelect, ListaFiltros } from 'src/app/shared/models/filtros.interface';
import { PrevisionesService } from '../../services/previsiones.service';
import { MaestroService } from '../../../maestro/services/maestro.service';
import { LoginService } from '../../../../core/services/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-select-mejor-prevision',
    templateUrl: './select-mejor-prevision.component.html',
    styleUrls: ['./select-mejor-prevision.component.scss'],
})
export class SelectMejorPrevisionComponent implements OnInit {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    durationInSeconds = 5;
    @Output() optionSelectedId = new EventEmitter<string>();
    @Output() resetTable = new EventEmitter<any>();
    @Output() OptionFilters = new EventEmitter<any>();
    @Output() changeSelectReset = new EventEmitter<any>();
    @Output() filtersList = new EventEmitter<any>();
    @Input() tipoConcepto: string;
    subfilters: any[];
    displayedSubfilters: Filtro[] = [];
    selectSubfilter: FiltroSelect[];
    conceptosProcesos: Concepto[] = [];
    filters: ListaFiltros[] = [];
    disableSelect = new FormControl(false);
    subcriptions: Subscription[] = [];
    tablaSeleccionada: string | undefined;
    selectId: any;
    isDisabled: boolean = true;
    inputValues: { id: string; value: string }[] = [];
    resetSelectedOption: any;
    resetSubfilter: any;
    showinput: any;
    showSelect: boolean = true;
    intermedio: boolean = true;
    resetCups: boolean;
    @ViewChildren('selects') selects: QueryList<FiltroSelectComponent>;
    defaultSelect: string = '';
    toggleActive: boolean = false;
    specialFeeActivated?: boolean = undefined;
    disableSelectFirst: boolean = false;
    isInitialForecastsSelected = false;
    // booleano para controlar si filtrar los filtros por ejemplo por codigo o por descripcion
    searchBy: boolean = false;
    // booleano para saber y controlar si el select de Cuenta Negociadora en CONTRATOS está vacio para controlar el disabled del toggle.
    disableToggle: boolean = true;
    unitProgramList: any;
    unitProgramListNoFilter: any;
    electricSystemList: any;
    processConceptsList: any;
    cuentaNegociadoraActive: boolean = false;
    cuentaNegActive: boolean;
    eventvalue: any;
    rateList: any;

    //POST//
    filtersBodyPost: any;

    cups: any;
    processCodePOST: any;
    nameProcessPOST: any;
    defaultExecution: any;

    rate: any;
    programUnit: any;
    liquidationType: any;
    clientType: any;
    accountId: any;
    initDate: any;
    endDate: any;
    closeType: any;
    measureInitDate: any;
    measureEndDate: any;
    forecastInitDate: any;
    forecastEndDate: any;
    society: any;
    origin: any;
    validRole: any = true;
    userAllRoles: any;
    userRoles: string[] = [];

    constructor(
        private globalService: GlobalService,
        private translateService: TranslateService,
        private _snackBar: MatSnackBar,
        protected readonly _headerTitleService: HeaderTitleService,
        public previsionService: PrevisionesService,
        public maestroService: MaestroService,
        public _loginService: LoginService
    ) {}
    ngOnInit(): void {
        this.maestroService.validCups = undefined;
        this.getFilters(this.searchBy);
        this.resetCups = false;

        this.userAllRoles = this._loginService.getUserActiveRoles();
        this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
        this.userRoles = this.userRoles.map((element: any) => element.trim());
        if (
            this.userRoles.findIndex((role) => role === 'PREVISION') !== -1 ||
            this.userRoles.findIndex((role) => role === 'ADMIN') !== -1
        ) {
            this.validRole = true;
        } else {
            this.validRole = false;
        }
    }

    /**
     * Metodo para controlar el booleano de filtrar por
     * @param searchBy  Boolean para controlar si filtrar por una cosa u otra
     */
    getFiltersBy(searchBy: [boolean, string]) {
        let subfilter = this.subfilters?.find((filter: any) => filter.key === searchBy[1]);
        if (subfilter) {
            subfilter.toggleState = searchBy[0];
        }
    }

    ngOnDestroy(): void {
        this.subfilters = this.subfilters.map((filter: any) => {
            filter.selected = '';
            return filter;
        });
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.subcriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    getFilters(filterByBoolean: boolean) {
        this.subcriptions.push(
            this.globalService.getAllFilters(filterByBoolean).subscribe((filtros) => {
                filtros.data.forEach((filter: any) => {
                    if (filter['name'] === 'mejorPrevision') {
                        this.filters = filter;
                        this.subfilters = filter.filters;
                    }
                });
                this.obtainSubfilters();
            })
        );
    }

    obtainSubfilters() {
        this.toggleActive = false;
        this.resetTable.emit();
        this.inputValues = [];
        this.filtersList.emit({ subfilters: this.subfilters });
        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element) => {
                if (element.required) {
                    if (element.name.includes('*')) {
                        return;
                    } else {
                        element.name = element.name + ' *';
                    }
                }
            });
        }

        this.accountId = undefined;
        this.maestroService.validCups = undefined;
        this.isDisableButtonRequired();
    }

    obtainSelectSubfiltros() {
        this.subfilters
            ?.filter((f) => {
                f.type === 'Select';
            })
            .forEach((s) => s.url);
    }

    obtainIdSelect(selectedOption: any) {
        for (let i = 0; i < this.subfilters!.length; i++) {
            if (this.subfilters![i].selected != undefined || this.subfilters != null) {
                this.subfilters![i].selected = '';
            }
        }
        this.resetSubfilter = selectedOption;
        this.selectId = selectedOption.value.id;
        this.isDisableButtonRequired();
        this.showinput = undefined;
        this.isDisableButtonRequired();
    }

    isDisableButtonRequired() {
        var countSelected = 0;
        var countRequired = 0;
        if (this.subfilters !== undefined) {
            this.subfilters
                .filter((subfilter) => subfilter.key !== 'cups')
                .forEach((element) => {
                    if (element.required) {
                        countRequired++;
                        if (element.selected) {
                            countSelected++;
                        }
                    } else if (element.selected != '' && this.selectId === MaestrosCRM.CONTRATOS) {
                        countSelected++;
                    }
                });
        }
        if (countRequired == 0) {
            this.isDisabled = false;
        } else {
            if (countRequired === countSelected) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        }
        if (this.dateRangeSameMonth) {
            this.isDisabled = true;
        }
    }
    resetFilters() {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.subfilters = this.subfilters.map((filter: any) => {
            filter.selected = '';
            return filter;
        });
        this.showSelect = true;
        this.resetTable.emit(true);
        this.cuentaNegociadoraActive = false;
        this.toggleActive = false;
        this.unitProgramList = this.unitProgramListNoFilter; //Esto restablecer bien el filtro de unit Program al "Borrar"
        this.maestroService.validCups = undefined;
        this.rate = undefined;
        this.programUnit = undefined;
        this.liquidationType = undefined;
        this.clientType = undefined;
        this.accountId = undefined;
        this.initDate = undefined;
        this.endDate = undefined;
        this.closeType = undefined;
        this.measureInitDate = undefined;
        this.measureEndDate = undefined;
        this.forecastInitDate = undefined;
        this.forecastEndDate = undefined;
        this.society = undefined;
        this.filtersBodyPost = undefined;
        this.isDisabled = true;
        this.cups = undefined;
    }
    loadTable() {
        this.cups = this.maestroService.validCups;
        let bodyPrevisionesIniciales: any;
        let selectFilter: any = [this.selectId, this.subfilters];

        if (this.cups != undefined) {
            let splitedDates = this.subfilters[0].selected!.split('~');
            let splitedOrigin = this.subfilters[1].selected!.split('=');
            this.filtersBodyPost = { cups: this.cups };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            bodyPrevisionesIniciales = {
                initDate: splitedDates[0],
                endDate: splitedDates[1],
                cups: [this.cups],
                origin: splitedOrigin[1],
            };
            this.previsionService.launchPutPrevisionesIniciales(bodyPrevisionesIniciales).subscribe(
                (uF: any) => {
                    if (uF.status === 200) {
                        this.translateService.get('PAGE.MODALS').subscribe((message) => {
                            this._snackBar.open(message['FINISH_EXECUTION_PROCESS'], message['ACTION_CLOSE'], {
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                            });
                        });
                    }
                },
                (error: HttpErrorResponse) => {
                    if (error && error.status === 404) {
                        this.translateService.get('PAGE.FILTERS').subscribe((message) => {
                            this._snackBar.open(message['NO_DATA'], message['ACTION_CLOSE'], {
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                            });
                        });
                    }
                }
            );
            setTimeout(() => {
                this._snackBar.dismiss();
            }, 4000);
        }

        if (this.subfilters !== undefined) {
            this.optionSelectedId.emit(selectFilter);
        } else {
            this.optionSelectedId.emit(this.selectId);
        }
    }
    obtainOption(event: { id: string; value: string }) {
        if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }
    }

    checkConditions(filtro: string[] | undefined): boolean {
        if (this.showinput === false) {
            return true;
        }
        if (!filtro || !filtro.length) {
            return true;
        }
        let solution = false;
        filtro.forEach((cond) => {
            if (this.inputValues.some((inpVal) => inpVal.value === cond)) {
                solution = true;
            }
        });
        return solution;
    }
    dateRangeSameMonth: boolean = false;

    receiveDate($event: any, filtro: any, reset?: boolean) {
        if (this.subfilters !== undefined) {
            const filter = $event.replace('Meteológica', 'Meteologica');
            this.subfilters[filtro].selected = filter;
        }
        this.isDisableButtonRequired();
    }

    receiveDate2(filtro: any) {
        this.isDisableButtonRequired();
    }

    clearFilters() {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.disableToggle = true;
        this.showSelect = true;
        this.obtainSubfilters();
        this.obtainIdSelect(this.resetSubfilter);
        this.resetCups = true;
        this.isDisabled = true;
    }

    resetComplete($event: any) {
        this.isDisabled = true;
        this.resetCups = $event;
    }

    hideSelect() {
        this.isDisabled = true;
        this.showSelect = false;
    }

    reset() {
        if (this.subfilters != undefined) {
            this.subfilters.forEach(function (e) {
                delete e['selected'];
            });
        }
        this.isDisabled = true;
    }

    /**
     * Método para controlar que filtros de Selects van a tener barra de busqueda con la lógica de buscar a raiz de escribir 3 carácteres
     *
     * @param filterName Valor name que nos viene de los subfilters
     * @returns
     */

    restarValue(event: any, filtro: any, key: string, filtroType?: any) {
        if (this.subfilters !== undefined && event.newValue) {
            this.subfilters[filtro].selected = event.newValue;
        }
        this.intermedio = true;
    }

    byOpenSelect(event: any) {
        if (event.newValue === true) {
            if (event.key === KeysPrevisiones.ORIGEN) {
                let subfilter = this.subfilters?.find((f: any) => f.key === KeysPrevisiones.ORIGEN);
                if (subfilter) {
                    subfilter.data = [];
                    this.previsionService.getTablaPrelacion().subscribe((neAccounts: { data: any[] }) => {
                        neAccounts.data.forEach((element: any) => {
                            element.origin === 'Meteologica' ? (element.origin = 'Meteológica') : element.origin;
                            if (subfilter && subfilter.toggleState === true) {
                                subfilter.data.push({ name: element.origin });
                            } else if (subfilter) {
                                subfilter.data.push({ name: element.origin });
                            }
                        });
                    });
                }
            }
        }
    }

    activeToggle() {
        if (this.previsionService.validCups != undefined || this.cuentaNegActive) {
            return;
        }
        if (this.toggleActive) {
            this.toggleActive = false;
        } else {
            this.toggleActive = true;
        }
    }

    reactivateCUPs() {
        this.previsionService.validCups = undefined;
    }
}
