export enum TipoMaestro {
    AWS = 'aws',
    CRM = 'crm',
}

export enum MaestrosCRM {
    CONTRATOS = 'contratos',
    CONCEPTOS_CONTRATOS = 'conceptosContratos',
    CUPS = 'CUPS',
    SOCIEDAD = 'sociedad',
    CUENTA_NEGOCIADORA = 'cuentaNegociadora',
    GRUPO_FACTURACION = 'grupoFacturacion',
    PRECIOS_CONTRATOS = 'preciosContratos',
    CIERRES = 'cierres',
    PUNTO_MEDIDA = 'puntoMedida',
    CONSUMO_ANUAL = 'consumoAnual',
    ID_FECHA_CONTRATOS = '35',
    COBERTURA_CONTRATO = 'coberturasContrato',
    PRICES_CONTRACTS = 'preciosContratos',
    CONCEPTS_CONTRACTS = 'conceptosContratos',
    CLOSURES = 'cierres',
}

export enum MaestrosAWS {
    TARIFAS = 'tarifas',
    FESTIVOS = 'festivos',
    PRECIOS_REGULADOS = 'preciosRegulados',
    PRECIOS_INDEXADOS = 'preciosIndexados',
    PRECIOS_MENSUALES = 'preciosMensuales',
    PARAMETROS = 'parametros',
    DISTRIBUIDOR = 'distribuidor',
    PRELACION = 'prelacion',
    UNIDAD_PROGRAMACION = 'unidadProgramacion',
    PUNTO_FRONTERA = 'puntoFrontera',
    PERIODOS_TARIFARIOS = 'periodosTarifarios',
    K_ESTIMADA = 'kEstimada',
    PERFILES_REE = 'perfilesREE',
    COEFICIENTE_PERDIDAS = 'coeficientePerdidas',
    MEDIDAS = 'Medidas',
    CIERRES = 'Cierres',
    LIQUIDACION_POTESTATIVA = 'liquidacionPotestativa',
    CONTRATOS_FICTICIOS = 'contratosFicticios',
    VARIABLES_CONTRATOS_FICTICIOS = 'variablesContratosFicticios',
}

export enum FiltroSelectConceptoContrato {
    LIQUIDACION = 'Liquidacion',
}

export enum FiltrosContrato {
    DESCRIPCION = 'Descripción',
}

export enum TypicalEnumToUse {
    CODE = 'Código',
}

export enum PaginationIDs {
    CONTRATOS = 'contratos',
    SOCIEDAD = 'sociedad',
    DISTRIBUIDOR = 'distribuidor',
    TARIFA = 'tarifas',
    CUPS = 'CUPS',
    CLOSURE = 'cierres',
    CONTRACT_PRICES = 'preciosContratos',
    NEGOTIATING_ACCOUNT = 'cuentaNegociadora',
    CONSUMO_ANUAL = 'consumoAnual',
    PUNTO_MEDIDA = 'puntoMedida',
    UNIDAD_PROGRAMACION = 'unidadProgramacion',
    PERFILES_REE = 'perfilesREE',
    PRELACION = 'prelacion',
    PARAMETROS = 'parametros',
    PERIODOS_TARIFARIOS = 'periodosTarifarios',
    PRECIOS_REGULADOS = 'preciosRegulados',
    PRECIOS_INDEXADOS = 'preciosIndexados',
    PRECIOS_MENSUALES = 'preciosMensuales',
    PUNTO_FRONTERA = 'puntoFrontera',
    FESTIVOS = 'festivos',
    COBERTURA_CONTRATO = 'coberturasContrato',
    COEFICIENTE_PERDIDAS = 'coeficientePerdidas',
    LIQUIDACION_POTESTATIVA = 'liquidacionPotestativa',
    K_ESTIMADA = 'kEstimada',
    CONTRATOS_FICTICIOS = 'contratosFicticios',
    VARIABLES_CONTRATOS_FICTICIOS = 'variablesContratosFicticios',
}

export enum SortBy {
    CODE = 'code',
    CODE_RATE = 'codeRate',
    ID_NUMBER = 'idNumber',
    CUPS = 'cups',
    CONTRACT_CODE = 'contractCode',
    CONCEPT = 'concept',
    CRM_VARIABLE = 'crmVariable',
    CLOSED = 'closedCode',
    NEGOTIATING_ACCOUNT = 'negotiatingAccountCode',
    HISTORIC = 'crmHistoricConsumptionsId.cups',
    MEASURE_POINT = 'crmMeasurePointId.cups',
    UNIT_PROGRAM_CODE = 'programUnitCode',
    PERFILES_REE = 'date',
    PRELACION = 'precedence',
    PERIODOS_TARIFARIOS = 'id',
    PRECIOS_REGULADOS = 'rate',
    PRECIOS_INDEXADOS = 'date', // TODO: comprobar si es date
    PRECIOS_MENSUALES = 'year', // TODO: comprobar si es year
    BOUNDARY_POINT_CODE = 'boundaryPointCode',
    COBERTURA_CONTRATO = 'contractCode',
    COEFICIENTE_PERDIDAS = 'date,hour,code',
    K_ESTIMADA = 'date,hour',
    LIQUIDACION_POTESTATIVA = 'date',
    SENDS_RESULTS = 'id',
    CONTRATOS_FICTICIOS = 'contractCode',
    VARIABLES_CONTRATOS_FICTICIOS = 'contractCode',
    PERIOD = 'period',
    FESTIVE = 'festiveType,festiveDate,regionCode',
}

export enum KeysMaestro {
    CUENTA_NEGOCIADORA = 'negotiatingAccountCode',
    CONTRACT_CUENTA_NEGOCIADORA = 'contract.negotiatingAccountCode',
    SOCIEDADES = 'idNumber',
    CONTRACT_SOCIEDADES = 'contract.idNumber',
    ESTADO_CONTRATO = 'status',
    CODIGO_CONTRATO = 'contractCode',
    CONTRACT_CODIGO_CONTRATO = 'contract.contractCode',
    NEGOCIO = 'clientType',
    TIPO_LIQUIDACION = 'concepts.liqType',
    DESCRIPTION = 'contractName',
    CUPS = 'cups',
    CRM_VARIABLE = 'crmVariable',
    CUENTA_NEGOCIADORA_GET = 'negotiatingAccountCode',
    CUENTA_NEGOCIADORA_DESCRIPCION = 'negotiatingAccountDescription',
    CUPS_CONSUMO_ANUAL = 'CrmHistoricConsumptionsId.cups',
    YEAR_CONSUMO_ANUAL = 'CrmHistoricConsumptionsId.year',

    TIPO_FESTIVO = 'festiveType',
    FECHA_FESTIVO = 'festiveDate',
    AMBITO_FESTIVO = 'regionCode',
    DESCRIPCION_FESTIVO = 'festiveDescription',

    ACTIVE_C_CECOER = 'cecoerActiveHub',
    PORT = 'port',
    SINGULAR_POINTS = 'singularPoints',
    COMMERCIAL_REQUEST = 'commercialRequest',
    CONNECTION_ISSUE = 'connectionIssue',

    PRECEDENCE_AREA = 'area',
    PERFILES_REE_ORIGEN = 'origin',
    CODIGO_TARIFAS = 'rate',
    PRECIOS_REGULADOS = 'code',
    PRECIOS_INDEXADOS = 'code', // TODO: comprobar si es code
    PRECIOS_MENSUALES = 'code', // TODO: comprobar si es code

    BOUNDARY_POINT_CUPS = 'boundaryPointCode',
    MEASURE_POINT_CUPS = 'crmMeasurePointId.cups',
    HISTORIC_CONSUMPTIONS = 'crmHistoricConsumptionsId.cups',
    BOUNDARY_DESCRIPTION = 'description',
    TIPO_TERMINO = 'code',
    CODIGO_PRECIOS = 'code',

    ACCOUNTID_CUENTA_NEGOCIADORA = 'accountId',

    LIQUIDACION_POTESTATIVA = 'programUnit',
    CONTRATOS_FICTICIOS = 'contractCode',
    VARIABLES_CONTRATOS_FICTICIOS = 'variable',
    COEFICIENTE_PERDIDAS = 'rate',
    CONTRACT = 'contract',
}

export enum KeysCierres {
    CLOSED_STATE = 'closedState',
}

export enum PuntoFronteraBooleans {
    BOOLEAN_Q1 = 'booleanFormulaReactEnergyQ1',
    BOOLEAN_Q2 = 'booleanFormulaReactEnergyQ2',
    BOOLEAN_Q3 = 'booleanFormulaReactEnergyQ3',
    BOOLEAN_Q4 = 'booleanFormulaReactEnergyQ4',

    BOOLEAN_IN_FORMULA = 'booleanFormulaInEnergy',
    BOOLEAN_OUT_FORMULA = 'booleanFormulaOutEnergy',
}

export enum PuntoFronteraTypeModal {
    SET_PRECEDENCES = 'set_precedences',
    MANAGE_PRECEDENCES = 'manage_precedences',
}

export enum ZoneTarifarios {
    RESTO = 'Resto',
}

export enum PerfilesREE {
    KEY_ORIGIN = 'origin',
    KEY_NAME = 'name',
    SELECTED_REE = 'REE',
    SELECTED_PROPIO = 'Propio',
    ID_17 = '17',
    ID_18 = '18',
    ID_19 = '19',
    ID_987 = '987',
    INICIAL = 'INICIAL',
    FINAL = 'FINAL',
}
