import { Component, OnInit } from '@angular/core';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
    constructor(private _headerTitleService: HeaderTitleService) {}

    ngOnInit(): void {
        this._headerTitleService.showBack = false;
        this._headerTitleService.currentHeaderTranslationCode = 'TITLE';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
    }
}
