import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TablaMaestroComponent } from '../tabla-maestro.component';
import { MaestroService } from '../../../services/maestro.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { ExportService } from '../../../../../services/export.service';
import { IColumnsTable } from '../../../../../shared/models/columns-table.model';
import {
    ContractDetailsEnum,
    ContractInstallationsDetailsEnum,
    HeadersContractDetails,
    TabDetails,
} from 'src/app/shared/enums/detailsTables';
import { ContractDetails } from '../../../../../shared/enums/idsConcepts';
import { SortBy } from 'src/app/shared/enums/maestros';
import { ClosedStatus, liqTypeEnum } from 'src/app/shared/enums/contractEnums';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/core/services/login.service';
import { DatePipe } from '@angular/common';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-details-page',
    templateUrl: './details-page.component.html',
    styleUrls: ['./details-page.component.scss'],
})
export class DetailsPageComponent implements OnInit, AfterViewInit {
    pageSize: number;
    totalPages: number;
    numPage: number;
    pageIndex: any;
    sortActive: any;
    sortDir: any;

    pageEvent: PageEvent;

    dataHeadersValuesSelected: IColumnsTable[][] = [[], [], []];
    dataHeaders: IColumnsTable[][] = [[], [], []];
    dataHeaders2: IColumnsTable[][] = [[], [], []];
    contractDetailsValues = Object.entries(ContractDetailsEnum);

    message: string = '';
    action: string = '';
    config: any;

    hidden: boolean = true;
    element: boolean = false;
    dataHeaderChange = true;

    codContrato: string;
    codigoCup: string;

    detailsFromBack: any[];
    installationsFromBack: any[];
    pricesFromBack: any;
    closuresFromBack: any;
    contractConceptsFromBack: any;
    translateString: string | undefined;

    @ViewChild(MatTable) table: MatTable<any>;
    @ViewChild('paginatorPrices') paginatorPrices: MatPaginator;
    paginatorPricesDataSource: MatTableDataSource<any>;
    totalRegistersPrices: number;
    pageIndexPrices: number;
    pageSizePrices: number;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    @ViewChild('paginatorContractConcepts') paginatorContractConcepts: MatPaginator;
    paginatorContractConceptsDataSource: MatTableDataSource<any>;
    totalRegistersContractConcepts: number;
    pageIndexContractConcepts: number;
    pageSizeContractConcepts: number;

    @ViewChild('paginatorClosures') paginatorClosures: MatPaginator;
    paginatorClosuresDataSource: MatTableDataSource<any>;
    totalRegistersClosures: number;
    pageIndexClosures: number;
    pageSizeClosures: number;

    columns: any[] = [[], [], []];
    headers: any;

    get TabDetails() {
        return TabDetails;
    }

    constructor(
        public DialogAdd: MatDialogRef<TablaMaestroComponent>,
        public readonly _headerTitleService: HeaderTitleService,
        private _maestroService: MaestroService,
        private _excelService: ExportService,
        private _translateService: TranslateService,
        private _loginService: LoginService
    ) {}

    ngOnInit(): void {
        this.headers = this._loginService.getHeaderSession();
        if (this.headers !== null) {
            this.headers = JSON.parse(this.headers);
        }
        this.message = this._translateService.instant('PAGE.MODALS.LOADING_MESSAGE_EXCEL');
        this.action = this._translateService.instant('PAGE.MODALS.CLOSE');
        this.codContrato = this._maestroService.contractCode;
        // Nos traemos de back los detalles del producto que queremos chequear.
        if (this.codContrato) {
            this._maestroService.getContractDetails(this.codContrato).subscribe((data: any) => {
                this.detailsFromBack = data.data;
                // Buscamos el código cup y lo guardamos
                this.detailsFromBack.forEach((element) => {
                    if (element.contractCode === this.codContrato) {
                        this.codigoCup = element.cups;
                    }
                });
                // Quitamos los conceptos
                delete this.detailsFromBack[0]['concepts'];

                // Nos traemos las instalaciones del back según el CUP
                this._maestroService.getInstallations(this.codigoCup).subscribe((data: any) => {
                    this.installationsFromBack = data.data;
                    // Quitamos las instalaciones
                    delete this.installationsFromBack[0]['boundaryPoints'];
                    delete this.installationsFromBack[0]['historic'];
                });
            });

            // Nos traemos los precios del back según el código de contrato
            this._maestroService
                .getPrices(1, 5, SortBy.CRM_VARIABLE, 'asc', this._maestroService.contractCode)
                .subscribe((res: any) => {
                    if (res !== null && res !== undefined) {
                        const headers = this.headers.data.filter(
                            (header: any) => header.name === HeadersContractDetails.PRICES
                        );
                        this.pricesFromBack = res.data;
                        this.dataHeadersValuesSelected[TabDetails.TAB_PRICES] = headers[0].headers;
                        this.dataHeaders[TabDetails.TAB_PRICES] = headers[0].headers;
                        this.totalRegistersPrices = res.page.totalElements;
                        this.transformHeaders(
                            [this.pricesFromBack, this.dataHeaders[TabDetails.TAB_PRICES]],
                            TabDetails.TAB_PRICES
                        );
                    }
                });
            // Nos traemos los conceptos de contrato del back según el código de contrato

            this._maestroService
                .getContractConcepts(1, 5, SortBy.CONCEPT, 'asc', this._maestroService.contractCode)
                .subscribe((res: any) => {
                    if (res !== null && res !== undefined) {
                        const headers = this.headers.data.filter(
                            (header: any) => header.name === HeadersContractDetails.CONTRACT_CONCEPTS
                        );
                        this.contractConceptsFromBack = res.data;
                        this.dataHeadersValuesSelected[TabDetails.TAB_CONTRACT_CONCEPTS] = headers[0].headers;
                        this.dataHeaders[TabDetails.TAB_CONTRACT_CONCEPTS] = headers[0].headers;
                        this.totalRegistersContractConcepts = res.page.totalElements;
                        this.transformHeaders(
                            [this.contractConceptsFromBack, this.dataHeaders[TabDetails.TAB_CONTRACT_CONCEPTS]],
                            TabDetails.TAB_CONTRACT_CONCEPTS
                        );
                    }
                });
            // Nos traemos los cierres del back según el código de contrato
            this._maestroService
                .getClosures(1, 5, SortBy.CLOSED, 'asc', this._maestroService.contractCode)
                .subscribe((res: any) => {
                    if (res !== null && res !== undefined) {
                        const headers = this.headers.data.filter(
                            (header: any) => header.name === HeadersContractDetails.CLOSURES
                        );
                        this.closuresFromBack = res.data;
                        this.dataHeadersValuesSelected[TabDetails.TAB_CLOSURES] = headers[0].headers;
                        this.dataHeaders[TabDetails.TAB_CLOSURES] = headers[0].headers;
                        this.totalRegistersClosures = res.page.totalElements;
                        this.transformHeaders(
                            [this.closuresFromBack, this.dataHeaders[TabDetails.TAB_CLOSURES]],
                            TabDetails.TAB_CLOSURES
                        );
                    }
                });
        }
    }

    ngAfterViewInit(): void {
        this.paginatorPricesDataSource = new MatTableDataSource(this.pricesFromBack);
        this.paginatorPricesDataSource.paginator = this.paginatorPrices;

        this.paginatorContractConceptsDataSource = new MatTableDataSource(this.contractConceptsFromBack?.data);
        this.paginatorContractConceptsDataSource.paginator = this.paginatorContractConcepts;

        this.paginatorClosuresDataSource = new MatTableDataSource(this.closuresFromBack?.data);
        this.paginatorClosuresDataSource.paginator = this.paginatorClosures;
    }

    openModalExcel(dataToExport: string) {
        if (dataToExport === ContractDetails.PRICES) {
            this._excelService.openDialog(this.pricesFromBack, ContractDetails.PRICES, this.totalRegistersPrices);
        } else if (dataToExport === ContractDetails.CONTRACT_CONCEPTS) {
            this._excelService.openDialog(
                this.contractConceptsFromBack,
                ContractDetails.CONTRACT_CONCEPTS,
                this.totalRegistersContractConcepts
            );
        } else if (dataToExport === ContractDetails.CLOSURES) {
            this._excelService.openDialog(this.closuresFromBack, ContractDetails.CLOSURES, this.totalRegistersClosures);
        }
    }

    checkvalue(dataHeaders2: any, tab: TabDetails) {
        this.dataHeadersValuesSelected[tab] = dataHeaders2;
        this.columns[tab] = [];
        for (let i = 0; i < dataHeaders2.length; i++) {
            let columnId;
            columnId = dataHeaders2[i].id;
            this.columns[tab].push(columnId);
        }
    }

    hideClick() {
        if (this.element) {
            //Mostrar-ocultar Etiqueta Span "Columnas"
            this.element = false;
        } else {
            this.element = true;
        }
        if (!this.hidden) {
            //Mostrar-ocultar select de columnas
            this.hidden = true;
        } else {
            this.hidden = false;
        }
    }

    filterDataHeader(tab: TabDetails) {
        if (this.dataHeaders2[tab] != this.dataHeaders[tab]) {
            this.dataHeaders2[tab] = this.dataHeaders[tab];
            this.dataHeadersValuesSelected[tab] = [];
            this.dataHeaderChange = true;
        }
        if (this.dataHeaderChange === true) {
            for (let i = 0; i < this.dataHeaders[tab].length; i++) {
                if (this.dataHeaders[tab][i].isVisible === true! || this.dataHeaders[tab][i].isVisible == undefined) {
                    this.dataHeadersValuesSelected[tab].push(this.dataHeaders[tab][i]);
                }
            }
            this.columns[tab] = [];
            for (let i = 0; i < this.dataHeadersValuesSelected[tab].length; i++) {
                let columnId;
                columnId = this.dataHeadersValuesSelected[tab][i].id;
                this.columns[tab].push(columnId);
            }
        }

        this.dataHeaderChange = false;

        return this.dataHeaders[tab];
    }

    transformHeaders(data: any, tab: TabDetails) {
        this.columns[tab] = data[1].map((col: any) => col.id);
    }

    pageChangePrices(event: any) {
        this.pagination(event, TabDetails.TAB_PRICES);
    }

    pageChangeContractConcepts(event: any) {
        this.pagination(event, TabDetails.TAB_CONTRACT_CONCEPTS);
    }

    pageChangeClosures(event: any) {
        this.pagination(event, TabDetails.TAB_CLOSURES);
    }

    pagination(event: any, tab: TabDetails) {
        event.pageIndex += 1;
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        //Filtrar por ...
        if (!this.sortActive) {
            switch (tab) {
                case TabDetails.TAB_PRICES:
                    this.sortActive = SortBy.CRM_VARIABLE;
                    break;
                case TabDetails.TAB_CONTRACT_CONCEPTS:
                    this.sortActive = SortBy.CONCEPT;
                    break;
                case TabDetails.TAB_CLOSURES:
                    this.sortActive = SortBy.CLOSED;
                    break;
                default:
                    break;
            }
        }

        //Ordenar por ASC o DESC
        if (!this.sortDir) {
            this.sortDir = 'asc';
        }
        event.pageIndex = event.pageIndex;
        switch (tab) {
            case TabDetails.TAB_PRICES:
                this._maestroService
                    .getPrices(event.pageIndex, event.pageSize, this.sortActive, this.sortDir, this.codContrato)
                    .subscribe((res: any) => {
                        this.pricesFromBack = res.data;
                        this.totalRegistersPrices = res.page.totalElements;
                        this.pageIndex = res.page.number;
                        this.pageSize = res.page.size;
                        this.pageSizePrices = res.page.size;
                        this.totalPages = res.page.totalPages;
                        this.sortActive = undefined;
                    });
                break;
            case TabDetails.TAB_CONTRACT_CONCEPTS:
                this._maestroService
                    .getContractConcepts(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this.codContrato
                    )
                    .subscribe((res: any) => {
                        this.contractConceptsFromBack = res.data;
                        this.totalRegistersContractConcepts = res.page.totalElements;
                        this.pageIndex = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                        this.sortActive = undefined;
                    });
                break;
            case TabDetails.TAB_CLOSURES:
                this._maestroService
                    .getClosures(event.pageIndex, event.pageSize, this.sortActive, this.sortDir, this.codContrato)
                    .subscribe((res: any) => {
                        this.closuresFromBack = res.data;
                        this.totalRegistersClosures = res.page.totalElements;
                        this.pageIndex = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                        this.sortActive = undefined;
                    });
                break;
            default:
                break;
        }
    }

    getLabelContractDetails(key: any): string | undefined {
        const keys = this.contractDetailsValues.filter((res) => res[0] === key);
        const translateKey = 'PAGE.MODALS.CONTRACT_DETAILS.' + keys[0][1];
        this._translateService.get(translateKey).subscribe((str: any) => {
            this.translateString = str;
        });
        return this.translateString;
    }

    getLabelInstallations(key: any): string | undefined {
        if (key !== 'historic') {
            let installationsValues = Object.entries(ContractInstallationsDetailsEnum);
            let keys = installationsValues.filter((res) => res[0] === key);
            let translateKey = 'PAGE.MODALS.INSTALL_DETAILS.' + keys[0][1];
            this._translateService.get(translateKey).subscribe((str: any) => {
                this.translateString = str;
            });
            return this.translateString;
        }
        return undefined;
    }

    getValue(item: any): string | number | undefined {
        const booleanValues = [
            'adjMec',
            'atrProcess',
            'calculateInitialFc',
            'lessOneYearContract',
            'rebillC5',
            'eintensiveType',
            'indLiqEstimated',
        ];
        let value = item.value;
        if (
            item.key === 'initDate' ||
            item.key === 'finalDate' ||
            item.key === 'finalEffectiveDate' ||
            item.key === 'initEffectiveDate' ||
            item.key === 'initDateAtr' ||
            item.key === 'signatureDate'
        ) {
            const dateFormat: any = this._transformDate(item.value, false);
            return dateFormat;
        }
        if (item.key === 'creationDate' || item.key === 'updateDate') {
            const dateFormat: any = this._transformDate(item.value, true);
            return dateFormat;
        }
        if (item.key === 'status') {
            switch (value) {
                case 1:
                    return ClosedStatus.PRE_CONTRATO;
                case 2:
                    return ClosedStatus.PENDING;
                case 3:
                    return ClosedStatus.FORMALIZED;
                case 4:
                    return ClosedStatus.CANCELED_REJECTED;
                case 5:
                    return ClosedStatus.COMPLETED;
                default:
                    return '';
            }
        }
        if (value !== null && value !== undefined && booleanValues.some((elem) => elem === item.key)) {
            return this._maestroService.setBooleanValue(value);
        }
        if (value === null || value === undefined || value === '' || item === null) {
            return this._translateService.instant('PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA');
        } else if (typeof value === 'object' && Array.isArray(value)) {
            return '';
        } else {
            return value;
        }
    }

    checkAndReturnColumns(element: any, columnId: string) {
        if (columnId.indexOf('.') > -1) {
            let columnIdSplitted = columnId.split('.');
            return element[columnIdSplitted[0]][columnIdSplitted[1]];
        }
        if (columnId === 'liqType') {
            return this.setLiquidationType(element);
        }
        if (
            columnId === 'taxBase' ||
            columnId === 'exenPercentage' ||
            columnId === 'valueP1' ||
            columnId === 'valueP2' ||
            columnId === 'valueP3' ||
            columnId === 'valueP4' ||
            columnId === 'valueP5' ||
            columnId === 'valueP6' ||
            columnId === 'eqRentalPrice' ||
            columnId === 'lectureMensualPrice' ||
            columnId === 'price' ||
            columnId === 'period' ||
            columnId === 'closedPriced' ||
            columnId === 'closedMwh' ||
            columnId === 'priceP1' ||
            columnId === 'priceP2' ||
            columnId === 'priceP3' ||
            columnId === 'priceP4' ||
            columnId === 'priceP5' ||
            columnId === 'priceP6' ||
            columnId === 'realMeasure' ||
            columnId === 'costComercialMargin' ||
            columnId === 'costAgentMargin'
        ) {
            return this.checkIfIsNumber(element[columnId]);
        }
        if (columnId === 'rentMeter') {
            this._translateService.get('PAGE.MASTER.MASTER_CRM.RENT_METER').subscribe((message) => {
                if (element[columnId] === '1') {
                    element[columnId] = message.APPLY;
                } else if (element[columnId] === '3') {
                    element[columnId] = message.NOT_APPLY;
                }
                return element[columnId];
            });
        } else if (columnId === 'exenType' && element[columnId] !== null) {
            return element[columnId];
        }
        if (columnId === 'closedPercentage') {
            return this.formatNumber(+element[columnId]);
        }
        if (
            columnId === 'beginDate' ||
            columnId === 'endDate' ||
            columnId === 'initDate' ||
            columnId === 'finalDate' ||
            columnId === 'closedDate'
        ) {
            return this._transformDate(element[columnId], false);
        }
        if (columnId === 'creationDate' || columnId === 'updateDate') {
            return this._transformDate(element[columnId], true);
        }
        if (columnId === 'adjustMec') {
            let formatString;
            this._translateService.get('PAGE.CONTRATOS_FICTICIOS').subscribe((str) => {
                switch (element[columnId]) {
                    case 2:
                        formatString = str.SN;
                        break;
                    case 1:
                        formatString = str.SI;
                        break;
                    case 0:
                        formatString = str.NO;
                        break;
                }
            });
            return formatString;
        }
        return element[columnId];
    }

    private setLiquidationType(element: any): string {
        if (element?.liqType === '1') {
            return liqTypeEnum.MEDIA_HORARIA;
        } else if (element?.liqType === '2') {
            return liqTypeEnum.FACTURA_ATR;
        }
        return '';
    }
    checkIfIsNumber(isNumber: any) {
        if (typeof isNumber === 'number') {
            const formatedNumber: any = this.formatNumber(isNumber);
            return formatedNumber;
        } else {
            return isNumber;
        }
    }
    formatNumber(numValue: any) {
        if (numValue !== null) {
            let formatNumValue = numValue.toLocaleString('es-ES');
            if (numValue % 1 !== 0) {
                let decimals = numValue.toString().split('.')[1];
                formatNumValue === '0' ? (formatNumValue = '0,0') : formatNumValue;
                let position = formatNumValue.indexOf(',');
                let format = formatNumValue.substring(0, position + 1) + decimals;
                return format;
            } else {
                return formatNumValue;
            }
        } else {
            return '_';
        }
    }
    _transformDate(date: any, largeFormat: boolean) {
        const datePipe = new DatePipe('en-US');
        let newDate = new Date(date);
        let formatDate;
        if (isNaN(newDate.getTime()) || date === null) {
            formatDate = '-';
        } else {
            formatDate = datePipe.transform(
                newDate,
                `${this._headerTitleService.currentLang === 'en' ? 'yyyy/MM/dd' : 'dd/MM/yyyy'} ${
                    largeFormat ? 'HH:mm:ss' : ''
                }`
            );
        }
        return formatDate;
    }
}
