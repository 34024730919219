import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.scss']
})
export class HomeCarouselComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  slides = [
    { 'image': 'assets/img/homecarousel/energia.jpg', 'title': 'Título A', 'subtitle': 'Subtitulo A', 'text':'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent rutrum libero est. Nulla mauris nulla, rutrum in nulla sit amet, interdum varius lorem. Donec sit amet lacinia neque, et porta orci. Ut libero lacus, vehicula vitae dui quis, sodales ullamcorper orci. Suspendisse maximus eleifend aliquam.'},
    { 'image': 'assets/img/homecarousel/energia2.jpg', 'title': 'Título B', 'subtitle': 'Subtitulo B', 'text':'Etiam interdum scelerisque mauris id egestas. Nullam eget fermentum magna. Donec tempus molestie pharetra. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean congue ornare semper. Aliquam tristique libero vel magna dictum venenatis. Quisque mauris tellus.'},
    { 'image': 'assets/img/homecarousel/energia3.jpg', 'title': 'Título C', 'subtitle': 'Subtitulo C', 'text':'Quisque mauris tellus, condimentum ut hendrerit id, aliquet et ante. Aenean id ullamcorper ligula. Nunc ultricies ac dui at tristique. Fusce ac vulputate dui. Vestibulum sed erat ligula. Maecenas mattis rutrum mollis. Praesent blandit ligula augue, vitae convallis lectus ullamcorper non.'}
  ];

}
