import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TablaCargasComponent } from '../tabla-cargas.component';

@Component({
    selector: 'delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalResultsComponent {
    constructor(public DialogDelete: MatDialogRef<TablaCargasComponent>) {}
    close(resp: boolean) {
        this.DialogDelete.close(resp);
    }
}
