import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-no-data',
  templateUrl: './table-no-data.component.html',
  styleUrls: ['./table-no-data.component.scss']
})
export class TableNoDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
