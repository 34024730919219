<section class="cron-editor-main">
    <mat-tab-group
        class="cron-editor-container"
        (selectedIndexChange)="onTabFocus($event)"
        [(selectedIndex)]="selectedTabIndex"
        [backgroundColor]="backgroundColor"
        color="warn"
    >
        <!-- Daily -->
        <mat-tab
            class="cron-editor-tab"
            label="{{ 'PAGE.CRON.DAILY_EVERY_DAY' | translate }}"
            *ngIf="!options.hideDailyTab"
        >
            <div class="cron-editor-tab-content" [formGroup]="dailyForm">
                <mat-radio-group class="cron-editor-radio-group" color="warn">
                    <mat-radio-button
                        color="warn"
                        class="cron-editor-radio-button"
                        value="everyDays"
                        [ngClass]="state.formRadioClass"
                        (change)="onValueChanged(dailyForm.value.everyWeekDay, 'daily', 0, true)"
                        [checked]="this.selectedRadioButton === 0"
                    >
                        <span class="cron-form-label">{{ ('PAGE.CRON.DAILY_EVERY_FRAGMENT' | translate) + ' ' }}</span>
                        <mat-form-field formGroupName="everyWeekDay">
                            <mat-label>{{ 'PAGE.CRON.DAILY_DAY_FRAGMENT' | translate }}</mat-label>
                            <mat-select formControlName="days">
                                <mat-option
                                    *ngFor="let monthDay of selectOptions.monthDays"
                                    [value]="monthDay"
                                    (click)="onValueChanged(dailyForm.value.everyWeekDay, 'daily', 0)"
                                >
                                    {{ monthDay }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        {{ 'PAGE.CRON.ALL_CONFIG_AT_DAILY_TEXT_FRAGMENT' | translate }}
                        <app-custom-cron-time-picker
                            formGroupName="everyWeekDay"
                            [use24HourTime]="options.use24HourTime"
                            (onTimeChange)="onValueChanged($event, 'daily', 0)"
                        >
                        </app-custom-cron-time-picker>
                    </mat-radio-button>
                    <mat-radio-button
                        color="warn"
                        name="subTab"
                        class="cron-editor-radio-button"
                        value="everyWeekDay"
                        [ngClass]="state.formRadioClass"
                        (change)="onValueChanged(dailyForm.value.everyDays, 'daily', 1, true)"
                        [checked]="this.selectedRadioButton === 1"
                    >
                        <span>{{ ('PAGE.CRON.ALL_CONFIG_AT_DAILY_TEXT_FRAGMENT' | translate) + ' ' }}</span>
                        <app-custom-cron-time-picker
                            formGroupName="everyDays"
                            [use24HourTime]="options.use24HourTime"
                            (onTimeChange)="onValueChanged($event, 'daily', 1)"
                        >
                        </app-custom-cron-time-picker>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </mat-tab>
        <!-- Weekly -->
        <mat-tab
            class="cron-editor-tab"
            label="{{ 'PAGE.CRON.WEEKLY_EVERY_WEEK' | translate }}"
            *ngIf="!options.hideWeeklyTab"
        >
            <div class="cron-editor-tab-content">
                <div [formGroup]="weeklyForm">
                    <span class="cron-form-label">{{ ('PAGE.CRON.WEEKLY_ALL_DAYS' | translate) + ' ' }}</span>
                    <mat-checkbox
                        color="warn"
                        class="checkbox-margin"
                        formControlName="MON"
                        (change)="
                            onValueChanged(
                                { weeklyDayForm: weeklyForm.value, weeklyTimeForm: weeklyTimeForm.value },
                                'weekly',
                                0
                            )
                        "
                    >
                        {{ 'PAGE.DAYS.MON' | translate }}
                    </mat-checkbox>
                    <mat-checkbox
                        color="warn"
                        class="checkbox-margin"
                        formControlName="TUE"
                        (change)="
                            onValueChanged(
                                { weeklyDayForm: weeklyForm.value, weeklyTimeForm: weeklyTimeForm.value },
                                'weekly',
                                0
                            )
                        "
                    >
                        {{ 'PAGE.DAYS.TUE' | translate }}
                    </mat-checkbox>
                    <mat-checkbox
                        color="warn"
                        class="checkbox-margin"
                        formControlName="WED"
                        (change)="
                            onValueChanged(
                                { weeklyDayForm: weeklyForm.value, weeklyTimeForm: weeklyTimeForm.value },
                                'weekly',
                                0
                            )
                        "
                    >
                        {{ 'PAGE.DAYS.WED' | translate }}
                    </mat-checkbox>
                    <mat-checkbox
                        color="warn"
                        class="checkbox-margin"
                        formControlName="THU"
                        (change)="
                            onValueChanged(
                                { weeklyDayForm: weeklyForm.value, weeklyTimeForm: weeklyTimeForm.value },
                                'weekly',
                                0
                            )
                        "
                    >
                        {{ 'PAGE.DAYS.THU' | translate }}
                    </mat-checkbox>
                    <mat-checkbox
                        color="warn"
                        class="checkbox-margin"
                        formControlName="FRI"
                        (change)="
                            onValueChanged(
                                { weeklyDayForm: weeklyForm.value, weeklyTimeForm: weeklyTimeForm.value },
                                'weekly',
                                0
                            )
                        "
                    >
                        {{ 'PAGE.DAYS.FRI' | translate }}
                    </mat-checkbox>
                    <mat-checkbox
                        color="warn"
                        class="checkbox-margin"
                        formControlName="SAT"
                        (change)="
                            onValueChanged(
                                { weeklyDayForm: weeklyForm.value, weeklyTimeForm: weeklyTimeForm.value },
                                'weekly',
                                0
                            )
                        "
                    >
                        {{ 'PAGE.DAYS.SAT' | translate }}
                    </mat-checkbox>
                    <mat-checkbox
                        color="warn"
                        class="checkbox-margin"
                        formControlName="SUN"
                        (change)="
                            onValueChanged(
                                { weeklyDayForm: weeklyForm.value, weeklyTimeForm: weeklyTimeForm.value },
                                'weekly',
                                0
                            )
                        "
                    >
                        {{ 'PAGE.DAYS.SUN' | translate }}
                    </mat-checkbox>
                </div>
                <span class="cron-form-label">
                    {{ ('PAGE.CRON.ALL_CONFIG_AT_HOUR_TEXT_FRAGMENT' | translate) + ' ' }}
                </span>
                <app-custom-cron-time-picker
                    [formGroup]="weeklyTimeForm"
                    [use24HourTime]="options.use24HourTime"
                    (onTimeChange)="
                        onValueChanged(
                            { weeklyDayForm: weeklyForm.value, weeklyTimeForm: weeklyTimeForm.value },
                            'weekly',
                            0
                        )
                    "
                >
                </app-custom-cron-time-picker>
            </div>
        </mat-tab>
        <!-- Monthly -->
        <mat-tab
            class="cron-editor-tab"
            label="{{ 'PAGE.CRON.MONTHLY_PERIOD' | translate }}"
            *ngIf="!options.hideMonthlyTab"
        >
            <div class="cron-editor-tab-content" [formGroup]="monthlyForm">
                <mat-radio-group class="cron-editor-radio-group">
                    <mat-radio-button
                        color="warn"
                        name="monthly-radio"
                        class="cron-editor-radio-button"
                        value="specificDay"
                        [ngClass]="state.formRadioClass"
                        (change)="onValueChanged(monthlyForm.value.specificDay, 'monthly', 0, true)"
                        [checked]="this.selectedRadioButton === 0"
                    >
                        <!-- Specific day -->
                        <span formGroupName="specificDay">
                            {{ 'PAGE.CRON.ALL_CONFIG_AT_MONTH_TEXT_FRAGMENT' | translate }}
                            <ng-container *ngIf="options.cronFlavor === 'quartz'">
                                <mat-form-field>
                                    <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_DAY_TEXT_FRAGMENT' | translate }}</mat-label>
                                    <mat-select class="month-days" formControlName="day">
                                        <mat-option
                                            *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts"
                                            [value]="monthDaysWithLast"
                                            (click)="onValueChanged(monthlyForm.value.specificDay, 'monthly', 0)"
                                        >
                                            {{ monthDayDisplay(monthDaysWithLast) | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="options.cronFlavor === 'standard'">
                                <mat-form-field>
                                    <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_DAY_TEXT_FRAGMENT' | translate }}</mat-label>
                                    <mat-select class="month-days" formControlName="day">
                                        <mat-option
                                            *ngFor="let monthDaysWithOutLast of selectOptions.monthDaysWithOutLasts"
                                            [value]="monthDaysWithOutLast"
                                            (click)="onValueChanged(monthlyForm.value.specificDay, 'monthly', 0)"
                                        >
                                            {{ monthDayDisplay(monthDaysWithOutLast) | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            {{ 'PAGE.CRON.ALL_CONFIG_OF_EVERY_TEXT_FRAGMENT' | translate }}
                            <mat-form-field>
                                <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_MONTH_TEXT_FRAGMENT' | translate }}</mat-label>
                                <mat-select
                                    class="months-small"
                                    formControlName="months"
                                    [ngClass]="options.formSelectClass"
                                >
                                    <mat-option
                                        *ngFor="let month of selectOptions.months"
                                        [value]="month"
                                        (click)="onValueChanged(monthlyForm.value.specificDay, 'monthly', 0)"
                                    >
                                        {{ month }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            {{ 'PAGE.CRON.ALL_CONFIG_AT_HOUR_TEXT_FRAGMENT' | translate }}
                            <app-custom-cron-time-picker
                                [disabled]="disabled"
                                [formGroup]="getFormGroup(monthlyForm.controls.specificDay)"
                                [use24HourTime]="options.use24HourTime"
                                (onTimeChange)="onValueChanged($event, 'monthly', 0)"
                            >
                            </app-custom-cron-time-picker>
                        </span>
                    </mat-radio-button>
                    <mat-radio-button
                        color="warn"
                        name="monthly-radio"
                        class="cron-editor-radio-button"
                        value="specificWeekDay"
                        [ngClass]="state.formRadioClass"
                        (change)="onValueChanged(monthlyForm.value.specificWeekDay, 'monthly', 1, true)"
                        [checked]="this.selectedRadioButton === 1"
                    >
                        <!-- Specific Week day -->
                        <span formGroupName="specificWeekDay">
                            {{ 'PAGE.CRON.ALL_CONFIG_AT_WEEK_TEXT_FRAGMENT' | translate }}
                            <mat-form-field>
                                <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_WEEK_TEXT_FRAGMENT' | translate }}</mat-label>
                                <mat-select
                                    class="day-order-in-month"
                                    formControlName="monthWeek"
                                    [ngClass]="options.formSelectClass"
                                >
                                    <mat-option
                                        *ngFor="let monthWeek of selectOptions.monthWeeks"
                                        [value]="monthWeek"
                                        (click)="onValueChanged(monthlyForm.value.specificWeekDay, 'monthly', 1)"
                                    >
                                        {{ monthWeekDisplay(monthWeek) | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_DAY_TEXT_FRAGMENT' | translate }}</mat-label>
                                <mat-select class="week-days" formControlName="day" [ngClass]="options.formSelectClass">
                                    <mat-option
                                        *ngFor="let day of selectOptions.days"
                                        [value]="day"
                                        (click)="onValueChanged(monthlyForm.value.specificWeekDay, 'monthly', 1)"
                                    >
                                        {{ dayDisplay(day) | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            {{ 'PAGE.CRON.ALL_CONFIG_OF_EVERY_TEXT_FRAGMENT' | translate }}
                            <mat-form-field>
                                <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_MONTH_TEXT_FRAGMENT' | translate }}</mat-label>
                                <mat-select class="months-small" formControlName="months">
                                    <mat-option
                                        *ngFor="let month of selectOptions.months"
                                        [value]="month"
                                        (click)="onValueChanged(monthlyForm.value.specificWeekDay, 'monthly', 1)"
                                    >
                                        {{ month }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            {{ 'PAGE.CRON.ALL_CONFIG_AT_HOUR_TEXT_FRAGMENT' | translate }}
                            <app-custom-cron-time-picker
                                [formGroup]="getFormGroup(monthlyForm.controls.specificWeekDay)"
                                [use24HourTime]="options.use24HourTime"
                                (onTimeChange)="onValueChanged($event, 'monthly', 1)"
                            >
                            </app-custom-cron-time-picker>
                        </span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </mat-tab>
        <!-- Yearly -->
        <mat-tab
            class="cron-editor-tab"
            label="{{ 'PAGE.CRON.YEARLY_PERIOD' | translate }}"
            *ngIf="!options.hideYearlyTab"
        >
            <div class="cron-editor-tab-content" [formGroup]="yearlyForm">
                <mat-radio-group class="cron-editor-radio-group">
                    <mat-radio-button
                        color="warn"
                        name="yearly-radio"
                        class="cron-editor-radio-button"
                        value="specificMonthDay"
                        [ngClass]="state.formRadioClass"
                        (change)="onValueChanged(yearlyForm.value.specificMonthDay, 'yearly', 0, true)"
                        [checked]="this.selectedRadioButton === 0"
                    >
                        {{ 'PAGE.CRON.ALL_CONFIG_AT_MONTH_TEXT_FRAGMENT' | translate }}
                        <mat-form-field formGroupName="specificMonthDay" *ngIf="options.cronFlavor === 'quartz'">
                            <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_DAY_TEXT_FRAGMENT' | translate }}</mat-label>
                            <mat-select class="month-days" formControlName="day">
                                <mat-option
                                    *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts"
                                    [value]="monthDaysWithLast"
                                    (click)="onValueChanged(yearlyForm.value.specificMonthDay, 'yearly', 0)"
                                >
                                    {{ monthDayDisplay(monthDaysWithLast) | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field formGroupName="specificMonthDay" *ngIf="options.cronFlavor === 'standard'">
                            <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_DAY_TEXT_FRAGMENT' | translate }}</mat-label>
                            <mat-select class="month-days" formControlName="day">
                                <mat-option
                                    *ngFor="let monthDaysWithOutLast of selectOptions.monthDaysWithOutLasts"
                                    [value]="monthDaysWithOutLast"
                                    (click)="onValueChanged(yearlyForm.value.specificMonthDay, 'yearly', 0)"
                                >
                                    {{ monthDayDisplay(monthDaysWithOutLast) | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        {{ 'PAGE.CRON.ALL_CONFIG_OF_TEXT_FRAGMENT' | translate }}
                        <mat-form-field formGroupName="specificMonthDay">
                            <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_MONTH_TEXT_FRAGMENT' | translate }}</mat-label>
                            <mat-select class="months" formControlName="month">
                                <mat-option
                                    *ngFor="let month of selectOptions.months"
                                    [value]="month"
                                    (click)="onValueChanged(yearlyForm.value.specificMonthDay, 'yearly', 0)"
                                >
                                    {{ monthDisplay(month) | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        {{ 'PAGE.CRON.ALL_CONFIG_AT_DAILY_TEXT_FRAGMENT' | translate }}
                        <app-custom-cron-time-picker
                            [disabled]="disabled"
                            [formGroup]="getFormGroup(yearlyForm.controls.specificMonthDay)"
                            [use24HourTime]="options.use24HourTime"
                            (onTimeChange)="onValueChanged($event, 'yearly', 0)"
                        >
                        </app-custom-cron-time-picker>
                    </mat-radio-button>
                    <mat-radio-button
                        color="warn"
                        name="yearly-radio"
                        class="cron-editor-radio-button"
                        value="specificMonthWeek"
                        [ngClass]="state.formRadioClass"
                        (change)="onValueChanged(yearlyForm.value.specificMonthWeek, 'yearly', 1, true)"
                        [checked]="this.selectedRadioButton === 1"
                    >
                        {{ 'PAGE.CRON.ALL_CONFIG_AT_WEEK_TEXT_FRAGMENT' | translate }}
                        <mat-form-field formGroupName="specificMonthWeek">
                            <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_WEEK_TEXT_FRAGMENT' | translate }}</mat-label>
                            <mat-select class="day-order-in-month" formControlName="monthWeek">
                                <mat-option
                                    *ngFor="let monthWeek of selectOptions.monthWeeks"
                                    [value]="monthWeek"
                                    (click)="onValueChanged(yearlyForm.value.specificMonthWeek, 'yearly', 1)"
                                >
                                    {{ monthWeekDisplay(monthWeek) | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field formGroupName="specificMonthWeek">
                            <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_DAY_TEXT_FRAGMENT' | translate }}</mat-label>
                            <mat-select class="week-days" formControlName="day">
                                <mat-option
                                    *ngFor="let day of selectOptions.days"
                                    [value]="day"
                                    (click)="onValueChanged(yearlyForm.value.specificMonthWeek, 'yearly', 1)"
                                >
                                    {{ dayDisplay(day) | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        {{ 'PAGE.CRON.ALL_CONFIG_OF_TEXT_FRAGMENT' | translate }}
                        <mat-form-field formGroupName="specificMonthWeek">
                            <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_MONTH_TEXT_FRAGMENT' | translate }}</mat-label>
                            <mat-select class="months" formControlName="month">
                                <mat-option
                                    *ngFor="let month of selectOptions.months"
                                    [value]="month"
                                    (click)="onValueChanged(yearlyForm.value.specificMonthWeek, 'yearly', 1)"
                                >
                                    {{ monthDisplay(month) | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        {{ 'PAGE.CRON.ALL_CONFIG_AT_HOUR_TEXT_FRAGMENT' | translate }}
                        <app-custom-cron-time-picker
                            [disabled]="disabled"
                            [formGroup]="getFormGroup(yearlyForm.controls.specificMonthWeek)"
                            [use24HourTime]="options.use24HourTime"
                            (onTimeChange)="onValueChanged($event, 'yearly', 1)"
                        >
                        </app-custom-cron-time-picker>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </mat-tab>
        <!-- Advanced -->
        <mat-tab
            class="cron-editor-tab"
            label="{{ 'PAGE.CRON.ADVANCED_PERIOD' | translate }}"
            *ngIf="!options.hideAdvancedTab"
        >
            <div class="cron-editor-tab-content" [formGroup]="advancedForm">
                <mat-form-field>
                    <mat-label>{{ 'PAGE.CRON.EXPRESSION' | translate }}</mat-label>
                    <input
                        id="advanced-input"
                        matInput
                        type="text"
                        class="advanced-cron-editor-input"
                        formControlName="expression"
                    />
                </mat-form-field>
            </div>
        </mat-tab>
        <!-- Unique -->
        <mat-tab class="cron-editor-tab" label="{{ 'PAGE.CRON.UNIQUE_PERIOD' | translate }}">
            <div class="cron-editor-tab-content" [formGroup]="uniqueForm">
                {{ 'PAGE.CRON.ALL_CONFIG_AT_MONTH_TEXT_FRAGMENT' | translate }}
                <mat-form-field *ngIf="options.cronFlavor === 'quartz'">
                    <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_DAY_TEXT_FRAGMENT' | translate }}</mat-label>
                    <mat-select class="month-days" formControlName="day">
                        <mat-option
                            *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts"
                            [value]="monthDaysWithLast"
                            (click)="onValueChanged(uniqueForm.value, 'unique', 0)"
                        >
                            {{ monthDayDisplay(monthDaysWithLast.toString()) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="options.cronFlavor === 'standard'">
                    <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_DAY_TEXT_FRAGMENT' | translate }}</mat-label>
                    <mat-select class="month-days" formControlName="day">
                        <mat-option
                            *ngFor="let monthDaysWithOutLast of selectOptions.monthDays"
                            [value]="monthDaysWithOutLast"
                            (click)="onValueChanged(uniqueForm.value, 'unique', 0)"
                        >
                            {{ monthDayDisplay(monthDaysWithOutLast.toString()) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                {{ 'PAGE.CRON.ALL_CONFIG_OF_TEXT_FRAGMENT' | translate }}
                <mat-form-field>
                    <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_MONTH_TEXT_FRAGMENT' | translate }}</mat-label>
                    <mat-select class="months" formControlName="month">
                        <mat-option
                            *ngFor="let month of selectOptions.months"
                            [value]="month"
                            (click)="onValueChanged(uniqueForm.value, 'unique', 0)"
                        >
                            {{ monthDisplay(month) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                {{ 'PAGE.CRON.ALL_CONFIG_OF_TEXT_FRAGMENT' | translate }}
                <mat-form-field>
                    <mat-label>{{ 'PAGE.CRON.ALL_CONFIG_YEAR_TEXT_FRAGMENT' | translate }}</mat-label>
                    <mat-select class="months" formControlName="year">
                        <mat-option
                            *ngFor="let year of selectOptionsYears"
                            [value]="year"
                            (click)="onValueChanged(uniqueForm.value, 'unique', 0)"
                        >
                            {{ year }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                {{ 'PAGE.CRON.ALL_CONFIG_AT_DAILY_TEXT_FRAGMENT' | translate }}
                <app-custom-cron-time-picker
                    [disabled]="disabled"
                    [formGroup]="getFormGroup(uniqueForm)"
                    [use24HourTime]="options.use24HourTime"
                    (onTimeChange)="onValueChanged($event, 'unique', 0)"
                >
                </app-custom-cron-time-picker>
            </div>
        </mat-tab>
    </mat-tab-group>
</section>
