import { Roles } from '../enums/roles.enum';
import { ItemMenu } from '../interfaces/itemMenu.interface';

export const MedidasPage: ItemMenu = {
    id: '5',
    name: 'MEDIDA',
    title: 'Medida',
    route: 'medida',
    icon: 'electric_meter',
    subItems: [
        {
            id: '5.1',
            name: 'CONTROL_PANEL',
            title: 'Cuadro de mandos',
            route: 'medida/cuadro-mandos',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.MEDIDA],
        },
    ],
    roles: [Roles.ADMIN, Roles.MEDIDA],
};
