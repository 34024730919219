import { ConceptsLabel, MonthsTranslate } from 'src/app/shared/enums/procesos';
import * as moment from 'moment';

const DEFAULT_CRON_EXPRESSION = `0 0 1/1 * ? *`;

export class ProcesosProgramarHelper {
    static getSubfiltersFromProcess(processCodeName: string): string[] {
        let arrayFilters: string[] = [];
        switch (processCodeName) {
            case ConceptsLabel.PREVISIONES_PROYECTADAS:
                arrayFilters = [
                    'electricSystem',
                    'programUnit',
                    'rate',
                    'negotiatingAccount',
                    'liquidationType',
                    'clientType',
                    'cups',
                ];
                break;
            case ConceptsLabel.CIERRE_VIRTUAL:
                arrayFilters = ['society', 'closeType', 'negotiatingAccount', 'clientType', 'cups'];
                break;
            case ConceptsLabel.PREVISIONES_INICIALES:
                arrayFilters = [
                    'electricSystem',
                    'programUnit',
                    'rate',
                    'negotiatingAccount',
                    'liquidationType',
                    'clientType',
                    'cups',
                    'defaultExecution',
                ];
                break;
            case ConceptsLabel.MEDIDAS_OBJECIONES:
                arrayFilters = [
                    'electricSystem',
                    'programUnit',
                    'rate',
                    'negotiatingAccount',
                    'liquidationType',
                    'clientType',
                    'cups',
                ];
                break;
            case ConceptsLabel.AGREGADO_PREVISIONES:
                arrayFilters = [
                    'electricSystem',
                    'programUnit',
                    'rate',
                    'negotiatingAccount',
                    'liquidationType',
                    'clientType',
                    'cups',
                ];
                break;
            case ConceptsLabel.ENVIO_METEOLOGICA:
                arrayFilters = [
                    'electricSystem',
                    'programUnit',
                    'rate',
                    'negotiatingAccount',
                    'liquidationType',
                    'clientType',
                    'cups',
                ];
                break;
            case ConceptsLabel.MEDIDAS_CUARTO_HORARIO:
                arrayFilters = [
                    'electricSystem',
                    'programUnit',
                    'rate',
                    'negotiatingAccount',
                    'liquidationType',
                    'clientType',
                    'cups',
                ];
                break;
            case ConceptsLabel.LIQUIDACION_POTESTATIVA:
                arrayFilters = [
                    'electricSystem',
                    'programUnit',
                    'rate',
                    'negotiatingAccount',
                    'liquidationType',
                    'clientType',
                    'cups',
                ];
                break;
            case ConceptsLabel.ANALISIS_RESULTADOS:
                arrayFilters = [
                    'electricSystem',
                    'programUnit',
                    'rate',
                    'negotiatingAccount',
                    'liquidationType',
                    'clientType',
                    'cups',
                ];
                break;
            case ConceptsLabel.DASHBOARD:
                arrayFilters = [
                    'electricSystem',
                    'programUnit',
                    'rate',
                    'negotiatingAccount',
                    'liquidationType',
                    'clientType',
                    'cups',
                ];
                break;
        }
        return arrayFilters;
    }

    // ON DATE CHECK
    static checkDates(
        initDateFilter: string | null,
        endDateFilter: string | null,
        initDateRange: string | null,
        endDateRange: string | null
    ): boolean {
        if ((!initDateFilter || !endDateFilter) && (!initDateRange || !endDateRange)) {
            return true;
        }
        return false;
    }

    // ON PROJECTED DATE CHECK
    static checkProjectedDates(
        projectedInitDateFilter: string | undefined,
        projectedEndDateFilter: string | undefined
    ): boolean {
        if (!projectedInitDateFilter || !projectedEndDateFilter) {
            return true;
        }
        return false;
    }

    // ON CLOSE TYPE CHECK
    static checkCloseType(subfilters: any): boolean {
        if (subfilters?.find((f: any) => f.key === 'closeType') !== undefined) {
            if (subfilters.find((f: any) => f.key === 'closeType')?.selected) {
                if (subfilters.find((f: any) => f.key === 'closeType')?.selected === '') {
                    return true;
                }
                return false;
            }
        }
        return true;
    }

    static checkResultsFilter(selectProcesosComponent: any): boolean {
        const filterResultsSelectedFiltered = selectProcesosComponent?.subfilters?.find((results: any) => {
            return results.key === 'resultados' && results.required === true;
        });
        return filterResultsSelectedFiltered?.selected === undefined || filterResultsSelectedFiltered?.selected === '';
    }

    static checkDefaultExecution(): boolean {
        return false;
    }

    static checkMonthAndYear(monthYearDate: string): boolean {
        return !monthYearDate;
    }

    static checkDateFormat(date: any) {
        return !isNaN(Date.parse(date));
    }

    static validateCronExpression(cronValue: any) {
        const cronFormValues = cronValue.split(' ');
        if (cronFormValues.length === 4) {
            cronFormValues[2] = '?';
            cronFormValues.push('?', '*');
        } else if (cronFormValues.length === 5 && cronFormValues[cronFormValues.length - 1] !== '*') {
            cronFormValues[2] = '?';
            cronFormValues.push('*');
        } else if (cronFormValues.length === 5) {
            cronFormValues.splice(cronFormValues.length - 1, 0, '?');
        }

        if (cronFormValues[2] === '*' && cronFormValues[cronFormValues.length - 1] === '*') {
            cronFormValues[cronFormValues.length - 1] = '?';
        }

        if (cronFormValues[2] === '?' && cronFormValues[cronFormValues.length - 2] === '?') {
            cronFormValues[2] = '*';
        }

        if (cronFormValues[cronFormValues.length - 1] === '?') {
            cronFormValues[cronFormValues.length - 1] = '*';
        }
        return cronFormValues.join(' ');
    }

    // ON SAVE PROGRAM
    static convertCronLocalTimeToUtc(
        cronExpression: string,
        bodyInitDate: Date,
        dateFormat: string,
        activeTab: number
    ) {
        moment.locale('es-ES');
        moment.weekdays(true);
        const offset: number = 2;
        if (!cronExpression) {
            cronExpression = DEFAULT_CRON_EXPRESSION;
        }
        const cronParts: string[] = cronExpression.split(' ');
        let hour: number = parseInt(cronParts[1], 10);
        hour -= offset;
        const initDate = moment(bodyInitDate).format(dateFormat);
        switch (activeTab) {
            case 0 /* DIA */:
                //TODO: consultar el por qué de esto
                if (hour < 0) {
                    hour += 24;
                    if (cronParts[4] !== '*') {
                        // cronParts[4] = 'SUN-THU';
                    }
                } else if (hour >= 24) {
                    hour -= 24;
                    if (cronParts[4] !== '*') {
                        // cronParts[4] = 'TUE-SAT';
                    }
                }
                break;
            case 1 /* SEMANA */:
                if (hour < 0) {
                    hour += 24;
                    if (cronParts[4] && cronParts[4] !== '*') {
                        let weekDays = cronParts[4].split(',');
                        cronParts[4] = this.addOrSubtractOneWeekDay(weekDays, false);
                    }
                } else if (hour >= 24) {
                    hour -= 24;
                    if (cronParts[4] && cronParts[4] !== '*') {
                        let weekDays = cronParts[4].split(',');
                        cronParts[4] = this.addOrSubtractOneWeekDay(weekDays, true);
                    }
                }
                break;
            case 2 /* MES */:
                if (hour < 0) {
                    hour += 24;
                    if (cronParts[4].includes('#')) {
                        cronParts[4] = this.setDayAndWeek(false, initDate, cronParts[4]);
                    } else {
                        if (cronParts[2] && !cronParts[2].includes('/') && cronParts[2] !== '*') {
                            let currentDate = moment(initDate).set('date', parseInt(cronParts[2], 10));
                            currentDate = currentDate.subtract(1, 'd');
                            cronParts[2] = currentDate.date().toString();
                        }
                        if (cronParts[4].includes('#')) {
                            let weekDay = cronParts[4].split('#');
                            cronParts[4] = this.addOrSubtractOneWeekDay([weekDay[0]], false) + '#' + weekDay[1];
                        }
                    }
                } else if (hour >= 24) {
                    hour -= 24;
                    if (cronParts[4].includes('#')) {
                        cronParts[4] = this.setDayAndWeek(true, initDate, cronParts[4]);
                    } else {
                        if (cronParts[2] && !cronParts[2]?.includes('/') && cronParts[2] !== '*') {
                            let currentDate = moment(initDate).set('date', parseInt(cronParts[2], 10));
                            currentDate = currentDate.add(1, 'd');
                            cronParts[2] = currentDate.date().toString();
                        }
                        if (cronParts[4].includes('#')) {
                            let weekDay = cronParts[4].split('#');
                            cronParts[4] = this.addOrSubtractOneWeekDay([weekDay[0]], true) + '#' + weekDay[1];
                        }
                    }
                }
                break;
            case 3 /* AÑO */:
                if (hour < 0) {
                    hour += 24;
                    if (cronParts[4].includes('#')) {
                        const currentDate = moment(initDate);
                        currentDate.set('month', parseInt(cronParts[3], 10) - 1);
                        cronParts[3] = (currentDate.month() + 1).toString();
                        cronParts[4] = this.setDayAndWeek(false, initDate, cronParts[4]);
                    } else {
                        let currentDate = moment(initDate).set('date', parseInt(cronParts[2], 10));
                        currentDate = currentDate.set('month', parseInt(cronParts[3], 10) - 1);
                        currentDate = currentDate.subtract(1, 'd');
                        if (cronParts[2] && !cronParts[2].includes('/') && cronParts[2] !== '*') {
                            cronParts[2] = currentDate.date().toString();
                        }
                        if (cronParts[3] && !cronParts[3].includes('/') && cronParts[3] !== '*') {
                            cronParts[3] = (currentDate.month() + 1).toString();
                        }
                    }
                } else if (hour >= 24) {
                    hour -= 24;
                    if (cronParts[4].includes('#')) {
                        const currentDate = moment(initDate);
                        currentDate.set('month', parseInt(cronParts[3], 10) - 1);
                        cronParts[3] = (currentDate.month() + 1).toString();
                        cronParts[4] = this.setDayAndWeek(true, initDate, cronParts[4]);
                    } else {
                        let currentDate = moment(initDate).set('date', parseInt(cronParts[2], 10));
                        currentDate = currentDate.set('month', parseInt(cronParts[3], 10) - 1);
                        currentDate = currentDate.add(1, 'd');
                        if (cronParts[2] && !cronParts[2].includes('/') && cronParts[2] !== '*') {
                            cronParts[2] = currentDate.date().toString();
                        }
                        if (cronParts[3] && !cronParts[3].includes('/') && cronParts[3] !== '*') {
                            cronParts[3] = (currentDate.month() + 1).toString();
                        }
                    }
                }
                break;
            case 4 /* UNICO */:
                if (hour < 0) {
                    hour += 24;
                    let currentDate = moment(initDate).set('date', parseInt(cronParts[2], 10));
                    currentDate = currentDate.set('month', parseInt(cronParts[3], 10) - 1);
                    currentDate = currentDate.set('year', parseInt(cronParts[5], 10));
                    currentDate = currentDate.subtract(1, 'd');
                    cronParts[2] = currentDate.date().toString();
                    cronParts[3] = currentDate.month() === 0 ? '12' : currentDate.month().toString();
                    cronParts[5] = currentDate.year().toString();
                } else if (hour >= 24) {
                    hour -= 24;
                    let currentDate = moment(initDate).set('date', parseInt(cronParts[2], 10));
                    currentDate = currentDate.set('month', parseInt(cronParts[3], 10) - 1);
                    currentDate = currentDate.set('year', parseInt(cronParts[5], 10));
                    currentDate = currentDate.add(1, 'd');
                    cronParts[2] = currentDate.date().toString();
                    cronParts[3] = currentDate.month() === 0 ? '12' : currentDate.month().toString();
                    cronParts[5] = currentDate.year().toString();
                }
                break;
            default:
                return cronExpression;
        }
        cronParts[1] = hour.toString();
        return cronParts.join(' ');
    }

    static convertCronToUTC(cron: string, isPostRequest: boolean = false): any {
        const offset: number = (new Date().getTimezoneOffset() / 60) * (isPostRequest ? 1 : -1);
        let cronArray = cron.split(' ');
        let hours = Number(cronArray[1]);
        let days = Number(cronArray[2].substring(cronArray[2].indexOf('/') + 1)) || 0;

        hours = hours + offset;
        if (hours > 23) {
            hours = hours - 23;
            days++;
        }
        if (hours < 0) {
            hours = hours + 23;
            days--;
        }
        cronArray[1] = hours.toString();
        cronArray[2] = (cronArray[2].indexOf('/') !== -1) ? cronArray[2].slice(0, cronArray[2].indexOf('/') + 1) + days.toString() : cronArray[2];
        return cronArray.join(' ');
    }

    static getWeekDay(day: string) {
        const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const index = days.indexOf(day);
        return index - 1;
    }

    static getWeekDayByNumber(day: number) {
        const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        return days[day];
    }

    static setDayAndWeek(add: boolean, initDate: any, cronPart4: string) {
        let currentDate = moment(initDate);
        let weekDay = cronPart4.split('#');

        let selectedWeek = moment(initDate).startOf('month').week() + (parseInt(weekDay[1], 10) - 1);
        let selectedDay = this.getWeekDay(weekDay[0]);

        currentDate.week(selectedWeek);
        currentDate.weekday(selectedDay);

        add ? currentDate.add(1, 'd') : currentDate.subtract(1, 'd');

        const day = this.getWeekDayByNumber(currentDate.day());
        const week = currentDate.week() - currentDate.startOf('month').week() + 1;

        return `${day}#${week}`;
    }

    static addOrSubtractOneWeekDay(weekDays: string[], add: boolean) {
        let result: string[] = [];
        let days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

        weekDays.forEach((elem) => {
            let index = add ? days.indexOf(elem) + 1 : days.indexOf(elem) - 1;
            if (index < 0) {
                index = 6;
            } else if (index > 6) {
                index = 0;
            }
            result.push(days[index]);
        });
        return result.join(',');
    }

    static parseStaticOrVariableDate(date: string, dateFormat: string) {
        const isExactDate = ProcesosProgramarHelper.checkDateFormat(date);
        let formattedDate = null;

        if (isExactDate) {
            const dateToFormat = new Date(date);
            formattedDate = moment(dateToFormat).format(dateFormat);
        } else {
            const daySplit = this.formatVariableDate('d', date);
            const monthSplit = this.formatVariableDate('m', daySplit[1]);
            const yearSplit = this.formatVariableDate('y', monthSplit[1]);

            const dateToFormat = new Date();
            dateToFormat.setDate(dateToFormat.getDate() + Number(daySplit[0]));
            dateToFormat.setMonth(dateToFormat.getMonth() + Number(monthSplit[0]));
            dateToFormat.setFullYear(dateToFormat.getFullYear() + Number(yearSplit[0]));
            formattedDate = moment(dateToFormat).format(dateFormat);
        }
        return formattedDate;
    }

    static formatVariableDate(dateInfo: string, dateText: string) {
        const array = dateText.split(dateInfo);
        if (array.length > 1) {
            return array;
        }
        return ['+0', array[0]];
    }

    static convertMonthNumberToName(month: number): string {
        return MonthsTranslate[month];
    }
}
