import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-not-implemented',
    templateUrl: './not-implemented.component.html',
    styleUrls: ['./not-implemented.component.scss'],
})
export class NotImplementedComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit() {}

    onClickReturnToHome(): void {
        this.router.navigate(['home']);
    }
}
