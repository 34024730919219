import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InformesService } from '../../../services/informes.service';
import { GlobalService } from 'src/app/services/global.service';
import { FiltroSelectComponent } from 'src/app/shared/components/filtros/filtro-select/filtro-select.component';
import { Filtro, FiltroSelect, ListaFiltros } from 'src/app/shared/models/filtros.interface';
import { SistemasInformesEnum } from 'src/app/shared/enums/sistemasInformes.enum';
import { MatOption } from '@angular/material/core';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-select-informes-resultado',
    templateUrl: './select-informes-resultado.component.html',
    styleUrls: ['./select-informes-resultado.component.scss'],
})
export class SelectInformesResultadoComponent implements OnInit {
    @Output() optionSelectedId = new EventEmitter<string>();
    @Output() resetTable = new EventEmitter<string>();
    @Output() changeSelectReset = new EventEmitter<any>();
    @Output() filtersList = new EventEmitter<any>();

    @Input() tipoConcepto: string;

    @ViewChildren('selects') selects: QueryList<FiltroSelectComponent>;
    @ViewChild('matReportSelect') matReportSelect: MatSelect;

    // booleano para controlar si filtrar los filtros por ejemplo por codigo o por descripcion
    searchBy: boolean = this._informesServices.searchBy;
    subfilters: Filtro[];
    displayedSubfilters: Filtro[] = [];
    selectSubfilter: FiltroSelect[];
    filters: ListaFiltros[] = [];
    disableSelect = new FormControl(false);
    subcriptions: Subscription[] = [];
    tablaSeleccionada: string | undefined;
    selectId: any;
    isDisabled: boolean = true;
    inputValues: { id: string; value: string }[] = [];
    resetSelectedOption: any;
    resetSubfilter: any;
    showinput: any;
    showSelect: boolean = true;
    isUsersListLoaded: boolean = false;
    intermedio: boolean = true;
    resetCups: boolean;
    userID: any;
    unitPrograms = new FormControl('');
    electrictSystemSelected: boolean = false;
    toggleActive: boolean = false;
    yearValue: string;
    defaultSelect: string = '';
    specialFeeActivated?: boolean = undefined;
    disableSelectFirst: boolean = false;
    unitProgramListNoFilter: any;
    unitProgramList: any;
    programUnit: any;
    selectedInitialForecasts: any;
    cuentaNegociadoraActive: boolean;
    eventvalue: string;
    cuentaNegActive: boolean;
    users: any;
    systemList: any[] = [];
    reportsList: any[] = [];
    filteredReportsList: any[] = [];
    electricSystemSelected: any;

    constructor(
        protected readonly _headerTitleService: HeaderTitleService,
        private _informesServices: InformesService,
        private globalService: GlobalService
    ) {}

    ngOnInit(): void {
        this.systemList = [
            {
                id: 1,
                name: SistemasInformesEnum.MEDIDAS,
                translation: 'MEASURES',
            },
            {
                id: 2,
                name: SistemasInformesEnum.PREVISIONES,
                translation: 'FORECAST',
            },
            {
                id: 3,
                name: SistemasInformesEnum.CONCILIACION,
                translation: 'CONCILIATION',
            },
            {
                id: 4,
                name: SistemasInformesEnum.DATOS_MAESTROS,
                translation: 'MASTER_DATA',
            },
        ];
        this.getFilters(this._informesServices.searchBy);
        this.getSelectInformes();
        this.subfilters = this.subfilters?.map((fil: any) => {
            fil.selected = '';
            return fil;
        });
    }

    /**
     * Metodo para controlar el booleano de filtrar por
     * @param searchBy  Boolean para controlar si filtrar por una cosa u otra
     */
    getFiltersBy(searchBy: [boolean, string]) {
        let subfilter = this.subfilters?.find((filter: any) => filter.key === searchBy[1]);
        if (subfilter) {
            subfilter.toggleState = searchBy[0];
        }
    }

    private getSelectInformes() {
        this.reportsList = [];
        this._informesServices.getSelectReports().subscribe((reportsResponse: any) => {
            reportsResponse.data.forEach((file: any) => {
                file.name = file.name.charAt(0).toUpperCase() + file.name.slice(1).toLowerCase();
                this.reportsList.push(file);
                this.filteredReportsList = this.reportsList;
            });
        });
    }

    getFilters(filterByBoolean: boolean) {
        this.subcriptions.push(
            this.globalService.getAllFilters(filterByBoolean).subscribe((filtros: { data: any[] }) => {
                filtros.data.forEach((filter: any) => {
                    if (filter['name'] === 'informes_resultados') {
                        this.filters = filter;
                        this.subfilters = filter.filters;
                    }
                });
                this.obtainSubfilters();
            })
        );
    }

    onClickFilterReportTypes(selectedSystem: string) {
        if (selectedSystem === 'reset') {
            this.filteredReportsList = this.reportsList;
        } else if (selectedSystem === SistemasInformesEnum.MEDIDAS) {
            this.filteredReportsList = this.reportsList.filter(
                (report) => report.area === SistemasInformesEnum.MEDIDAS
            );
        } else if (selectedSystem === SistemasInformesEnum.PREVISIONES) {
            this.filteredReportsList = this.reportsList.filter(
                (report) => report.area === SistemasInformesEnum.PREVISIONES
            );
        } else if (selectedSystem === SistemasInformesEnum.CONCILIACION) {
            this.filteredReportsList = this.reportsList.filter(
                (report) => report.area === SistemasInformesEnum.CONCILIACION
            );
        } else if (selectedSystem === SistemasInformesEnum.DATOS_MAESTROS) {
            this.filteredReportsList = this.reportsList.filter(
                (report) => report.area === SistemasInformesEnum.DATOS_MAESTROS
            );
        }
        this.reset('reportType.reportCode');
        this.matReportSelect.options.forEach((data: MatOption) => data.deselect());
        this.isDisableButtonRequired();
    }

    checkValueSelects(selectedOption: any) {
        this._informesServices.showColumnsBy = '';
    }

    obtainSubfilters() {
        this.resetTable.emit();
        this.inputValues = [];
        this.filtersList.emit({ subfilters: this.subfilters });

        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element) => {
                if (element.required) {
                    if (element.name.includes('*')) {
                        return;
                    } else {
                        element.name = element.name + ' *';
                    }
                }
            });
        }
    }

    obtainSelectSubfiltros() {
        this.subfilters?.filter((f) => f.type === 'Select').forEach((s) => s.url);
    }

    isDisableButtonRequired() {
        let countRequired = 0;
        let countSelected = 0;
        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element) => {
                if (element.required) {
                    countRequired++;
                    if (element.selected) {
                        countSelected++;
                    }
                }
            });
        }
        if (countRequired === 0) {
            this.isDisabled = false;
        } else {
            if (countRequired === countSelected) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        }
    }

    loadTable() {
        let selectFilter: any = [this.filters, this.subfilters, this.programUnit];
        if (this.subfilters !== undefined) {
            this.optionSelectedId.emit(selectFilter);
        } else {
            this.optionSelectedId.emit(this.selectId);
        }
    }

    obtainOption(event: { id: string; value: string } | any) {
        if (!event.id && !event.programUnitCode) {
            const id = event;
            event = { id, event };
            this.inputValues.push(event);
        } else if (event.programUnitCode) {
            this.inputValues.push(event);
        } else {
            if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
                this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
            } else {
                this.inputValues.push(event);
                this.isDisableButtonRequired();
            }
        }
    }

    byOpenSelect(event: any) {
        if ((!this.isUsersListLoaded && event.key === 'creationUser') || event.key === 'user') {
            let subfilter = this.subfilters?.find((f: any) => f.key === event.key);
            if (subfilter) {
                subfilter.data = [];
            }
            this._informesServices.getUsers().subscribe((status: any) => {
                status.data.forEach((element: any) => {
                    subfilter!.data.push({
                        name: element.name,
                        value: element.username,
                        surnames: element.surnames,
                    });
                });
                this.isUsersListLoaded = true;
            });
        }
    }

    checkConditions(filtro: string[] | undefined): boolean {
        if (this.showinput === false) {
            return true;
        }
        if (!filtro || !filtro.length) {
            return true;
        }
        let solution = false;
        filtro.forEach((cond) => {
            if (this.inputValues.some((inpVal) => inpVal.value === cond)) {
                solution = true;
            }
        });
        return solution;
    }

    receiveDate($event: any, filtro: any, reset?: boolean) {
        if (this.subfilters !== undefined) {
            this.subfilters[filtro].selected = $event;
        }
        this.isDisableButtonRequired();
    }

    checkReportTypeAsSelected($event: any, filtro: any) {
        if (this.subfilters !== undefined) {
            this.subfilters[filtro].selected = $event.reportCode;
        }
        this.isDisableButtonRequired();
    }

    restartValue(event: any, filtro: any, key: string, filtroType?: any) {
        this.formatSelectedValue(event, filtro, key, filtroType);
        if (typeof event === 'string') {
            let splittedValues: string[] = event.split('=');
            if (splittedValues[0] === 'Usuario' || splittedValues[0] === 'User') {
                this.subfilters[filtro].selected = splittedValues[1];
            }
        } else if (this.subfilters !== undefined && event.newValue) {
            this.subfilters[filtro].selected = event.newValue;
        }
    }

    /**
     *
     * @param event Dato seleccionado
     * @param filtro El número del select de filtros
     * @param key Key del filtro
     */
    formatSelectedValue(event: any, filtro: any, key: string, filtroType: string) {
        if (filtroType !== 'Date' && this.subfilters) {
            let filtroFormateado = event.split('=');
            this.subfilters[filtro].selected = filtroFormateado[1];
        }
    }

    clearFilters() {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.showSelect = true;
        this.getFilters(this._informesServices.searchBy);
        this.obtainSubfilters();
        this.yearValue = '';
        this.isDisabled = true;
        this.filteredReportsList = this.reportsList;
        this.reset();
    }

    hideSelect() {
        this.showSelect = false;
    }

    filterUnitProgram(electricSystemSelected: any) {
        let unitProgramFilter: any = [];

        this.unitProgramListNoFilter.forEach((unitProgram: any) => {
            if (unitProgram.electricSystem === electricSystemSelected) {
                unitProgramFilter.push(unitProgram);
            }
        });
        this.unitProgramList = [];
        this.programUnit = [];
        this.unitProgramList = unitProgramFilter;

        // Rellenamos todos por defecto
        this.unitProgramList = this.unitProgramList.map((uP: any) => uP.programUnitCode);
        this.unitPrograms = new FormControl(this.unitProgramList);
        this.programUnit = this.unitProgramList;
    }

    obtainUnitsPrograms(programUnit: any) {
        this.programUnit = programUnit.value;
    }

    obtainSelectedOptionElectrictSystem(eSystem: any) {
        if (eSystem.value) {
            this.electrictSystemSelected = true;
        } else {
            this.electrictSystemSelected = false;
        }
    }

    obtainOption3(event: { id: string; value: string }, valueCuentNeg: any) {
        if (this.selectedInitialForecasts) {
            if (event.value) {
                //Aquí esta el valor del select de cuenta negociadora
                this.cuentaNegociadoraActive = true;
                this.eventvalue = event.value;
            }
            this.cuentaNegActive = true;
            if (valueCuentNeg) {
                this.cuentaNegociadoraActive = true;
            }
        }
        if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }
    }

    receiveDate2(filtro: any) {
        this.isDisableButtonRequired();
    }

    activeToggleDeleteRegisters() {
        if (this.toggleActive) {
            this.toggleActive = false;
        } else {
            this.toggleActive = true;
        }
    }

    reset(filter?: any) {
        if (this.subfilters != undefined) {
            if (!filter) {
                this.subfilters.forEach((subfilter) => {
                    subfilter.selected = '';
                });
            } else {
                this.subfilters.forEach((subfilter) => {
                    if (subfilter.key && subfilter.key === filter) {
                        subfilter.selected = '';
                    }
                });
            }
        }
    }

    ngOnDestroy(): void {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.subcriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }
}
