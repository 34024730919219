import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MaestroService } from 'src/app/pages/maestro/services/maestro.service';
import { FiltroSelect } from 'src/app/shared/models/filtros.interface';

@Component({
    selector: 'app-filtro-year',
    templateUrl: './filtro-year.component.html',
    styleUrls: ['./filtro-year.component.scss'],
})
export class FiltroYearComponent implements OnInit {
    @Input() filtroUrl: FiltroSelect[]; // lo que te manda la pagina
    @Input() title: string;
    @Input() id: string;
    @Input() value: any;
    @Output() valueChange = new EventEmitter<{ id: string; value: string }>();
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() selectSelected: EventEmitter<any> = new EventEmitter();

    @Output() defaultValue = new EventEmitter<{ id: string; value: string }>();

    year: any | any[];
    optionsYear: any[] = [];

    constructor() {}

    ngOnInit(): void {
        let actualDate = new Date();
        this.year = actualDate.getFullYear();
        let menosYear = this.year - 6;
        let masYear = this.year + 2;
        while (menosYear <= masYear) {
            this.optionsYear.push(menosYear);
            menosYear++;
        }

        this.loadYears();

        this.defaultValue.emit(this.optionsYear[0]);
    }

    emitValue(valor: any) {
        this.select.emit(valor);
        this.valueChange.emit({ id: this.id, value: valor });
    }

    emitValueNew($event: any) {
        this.selectSelected.emit({ newValue: $event.value.name, name: this.id });
    }

    loadYears() {
        let actualDate = new Date();
        this.year = actualDate.getFullYear();
        let menosYear = this.year - 6;
        let masYear = this.year + 2;
        this.optionsYear = [];
        while (menosYear <= masYear) {
            this.optionsYear.push(menosYear);
            menosYear++;
        }
    }
}
