import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { LoginService } from 'src/app/core/services/login.service';
import { HeaderTitleService } from '../../../services/header-title.service';
import { EnviosService } from '../envios.service';
import { TipoEnvios } from '../../../shared/enums/envios';
import { Filtro, FiltroSelect } from 'src/app/shared/models/filtros.interface';
import { FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
    selector: 'app-envios-lanzar',
    templateUrl: './envios-lanzar.component.html',
    styleUrls: ['./envios-lanzar.component.scss'],
})
export class EnviosLanzarComponent implements OnInit, OnDestroy {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    sendTypeList: any = [TipoEnvios.DAILY_FORECAST, TipoEnvios.WEEKLY_FORECAST, TipoEnvios.OPTIONAL_SETTLEMENT];
    sendTypes = TipoEnvios;
    unitsForm: FormControl = new FormControl();
    subcriptions: Subscription[] = [];
    reset$: Subject<void> = new Subject<void>();
    userRoles: string[] = [];
    userAllRoles: any;
    validRole: any = true;
    dataBusiness: any = false;
    subfilters: Filtro[] | undefined | any = [];
    unitProgramsFormControl: any;

    selectedValue: string;
    errorMessageTranslationCode: string;
    dateInitSelected: any;
    dateEndSelected: any;
    electricSystemSelected: string = '';
    unitProgramSelected: any[] = [];
    optionValues: string[] = ['', ''];
    electricSystemList: any[] = [];
    unitProgramListCopy: any[] = [];
    unitProgramList: any[] = [];
    isDateFilterIncorrect: boolean = false;
    isPeninsularSystem: boolean = false;

    constructor(
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _enviosService: EnviosService,
        private _loginService: LoginService,
        private _globalService: GlobalService,
        private _headerTitleService: HeaderTitleService
    ) {
        this._headerTitleService.currentHeaderTranslationCode = 'SENDS_LAUNCH_TITLE';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
    }

    ngOnInit(): void {
        this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
        this.userRoles = this.userRoles.map((element: any) => element.trim());
        if (
            this.userRoles.findIndex((role) => role === 'PREVISION') !== -1 ||
            this.userRoles.findIndex((role) => role === 'ADMIN') !== -1
        ) {
            this.validRole = true;
        } else {
            this.validRole = false;
        }
        this.subfilters[0] = [];
        this.subfilters[1] = [];
        this._getUnitProgramSelect();

        // Relleno los filters
        let respFilters: any = [];
        this.subfilters[0] = [];
        this.subfilters[1] = [];
        respFilters = this._loginService.getFiltersTotal();
        respFilters = JSON.parse(respFilters);
        this.subcriptions.push(
            this._globalService.getAllFilters(true).subscribe((filtros: { data: any[] }) => {
                filtros.data.forEach((filter: any) => {
                    if (filter['name'] === 'envios') {
                        this.subfilters = filter.filters;
                        this._getUnitProgramSelect();
                    }
                });
            })
        );
    }

    ngOnDestroy(): void {
        this.subcriptions.forEach((sub: any) => {
            sub.unsubscribe();
        });
    }

    protected dataChanged($event: any, isConcept?: boolean): void {
        if (isConcept) {
            this.selectedValue = $event;
            this._getUnitProgramSelect();
            this.clearFilters(false, true);
        }
        this.isButtonDisabled();
    }

    protected isButtonDisabled(): boolean {
        if (
            this.selectedValue === '' ||
            this.selectedValue === undefined ||
            this.dateInitSelected === '' ||
            this.dateInitSelected === undefined ||
            this.electricSystemSelected === '' ||
            this.electricSystemSelected === undefined ||
            this.unitProgramSelected.length === 0 ||
            this.isDateFilterIncorrect === true ||
            this.unitProgramSelected === undefined ||
            this._isDateSelectedIncorrect()
        ) {
            return true;
        }
        return false;
    }

    protected getOptionSelected($event: any, isSystemFilter: boolean = false): void {
        if (isSystemFilter) {
            if ($event === 'Península') {
                this.isPeninsularSystem = true;
            } else {
                this.isPeninsularSystem = false;
            }
            this._enviosService.setIfPeninsularSystem(this.isPeninsularSystem);
            this.isButtonDisabled();
        } else {
            this.unitProgramSelected = $event;
        }
        this.isButtonDisabled();
    }

    protected clearFilters(clearDates: boolean = true, isConceptChange: boolean = false): void {
        if (clearDates) {
            this.dateInitSelected = undefined;
            this.dateEndSelected = undefined;
        }
        this.selectedValue = '';
        this.errorMessageTranslationCode = '';
        this.electricSystemSelected = '';
        this.unitProgramSelected = [];
        this.unitProgramsFormControl = [];
        this.unitsForm.reset();
        this.reset$.next();

        for (let i = 0; i < this.optionValues.length; i++) {
            this.optionValues[i] = '';
        }
        if (!isConceptChange) {
            this._getUnitProgramSelect();
        }
        this._preselectAllUnitPrograms(true);
        this.isButtonDisabled();
    }

    protected dateComplete($event: any): void {
        this.dateInitSelected = $event.split('~')[0];
        this.dateEndSelected = $event.split('~')[1];
    }

    protected sendFile(): void {
        let programUnitCode: any = '';
        this.unitProgramSelected.forEach((unitProgram) => {
            if (unitProgram.name) {
                programUnitCode += unitProgram.name + ',';
            } else {
                programUnitCode += unitProgram + ',';
            }
        });
        if (programUnitCode.slice(-1) === ',') {
            programUnitCode = programUnitCode.slice(0, -1);
        }

        let searchType = '';
        if (this.selectedValue === TipoEnvios.OPTIONAL_SETTLEMENT) {
            searchType = 'optionalSettlement';
        } else if (this.selectedValue === TipoEnvios.DAILY_FORECAST) {
            searchType = 'dailyForecast';
        } else if (this.selectedValue === TipoEnvios.WEEKLY_FORECAST) {
            searchType = 'weeklyForecast';
        } else {
            searchType = this.selectedValue;
        }

        this._enviosService
            .postFile({
                programUnitCode,
                type: searchType,
                initDate: this.dateInitSelected,
                endDate: this.dateEndSelected,
            })
            .subscribe((data) => {
                this._translateService.get('PAGE.SENDINGS').subscribe((message) => {
                    this._snackBar.open(
                        message[`${data && data.warningList === null ? 'SENDING_SUCCESSFUL' : 'SENDING_ERROR'}`],
                        message['ACTION_CLOSE'],
                        {
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                        }
                    );
                    setTimeout(() => {
                        this._snackBar.dismiss();
                    }, 6000);
                });
            });
    }

    protected isDateIncorrect($event: any): void {
        this.isDateFilterIncorrect = $event;
    }

    protected isMultiple(index: number): boolean {
        if (index === 1) {
            return true;
        }
        return false;
    }

    private _getUnitProgramSelect(): void {
        this._enviosService.getUnidadPrograma(this.selectedValue).subscribe((UP) => {
            this.unitProgramList = UP.data;
            this.electricSystemList = [];
            UP.data.forEach((unitProgram: any) => {
                if (this.selectedValue !== TipoEnvios.WEEKLY_FORECAST || unitProgram.sendSystem !== 'PENINSULA') {
                    this.electricSystemList.push(unitProgram.sendSystem);
                }
            });

            // Quita los sistemas eléctricos repetidos:
            let electricSystemFilter: any = Array.from(new Set(this.electricSystemList));
            electricSystemFilter = electricSystemFilter.map(function (word: string) {
                word === 'PENINSULA'
                    ? (word = 'Península')
                    : (word =
                          word.charAt(0).toUpperCase() +
                          word
                              .slice(1)
                              .toLowerCase()
                              .replace(/-(.)/g, (match) => '-' + match.charAt(1).toUpperCase()));
                return word;
            });
            this.electricSystemList = electricSystemFilter;

            // Rellena la lista de sistemas eléctricos:
            let copy: FiltroSelect[] = [];

            for (let i = 0; i < this.electricSystemList.length; i++) {
                copy[i] = { name: '', value: '', code: '' };
                copy[i].name = this.electricSystemList[i];
                copy[i].value = this.electricSystemList[i];
                copy[i].code = this.electricSystemList[i];
            }

            this.electricSystemList = copy;
            this.subfilters[0].data = this.electricSystemList;

            // Rellena la lista de unidades de programa:
            this.unitProgramListCopy = [];

            for (let i = 0; i < this.electricSystemList.length; i++) {
                for (let j = 0; j < UP.data.length; j++) {
                    UP.data[j].sendSystem === 'PENINSULA'
                        ? (UP.data[j].sendSystem = 'Península')
                        : (UP.data[j].sendSystem =
                              UP.data[j].sendSystem.charAt(0).toUpperCase() +
                              UP.data[j].sendSystem
                                  .slice(1)
                                  .toLowerCase()
                                  .replace(/-(.)/g, (match: any) => '-' + match.charAt(1).toUpperCase()));
                    if (this.electricSystemList[i].name === UP.data[j].sendSystem) {
                        let unitProgramCopy: any = {
                            name: UP.data[j].programUnitCode,
                            value: UP.data[j].programUnitCode,
                            code: this.electricSystemList[i].name,
                        };

                        this.unitProgramListCopy.push(unitProgramCopy);
                    }
                }
            }
            this.unitProgramList = this.unitProgramListCopy;
            this.subfilters[1].data = this.unitProgramList;
            this._preselectAllUnitPrograms(true);
            this.isButtonDisabled();
        });
    }

    protected filter(opcion: any, index: number): void {
        if (index === 0) {
            this.electricSystemSelected = opcion.name;
            let subfiltersCopy: any[] = [];

            for (let i = 0; i < this.unitProgramListCopy.length; i++) {
                if (this.unitProgramListCopy[i].code === opcion.name) {
                    // Controla que no haya unidades de programa repetidas:
                    if (
                        !this.subfilters[1].data.some((x: any) => {
                            x.code == this.unitProgramListCopy[i].name;
                        })
                    ) {
                        subfiltersCopy.push(this.unitProgramListCopy[i]);
                        this.subfilters[1].data = subfiltersCopy;
                    }
                }
            }
            for (let i = 0; i < this.unitProgramListCopy.length; i++) {
                this.unitProgramsFormControl.push(this.unitProgramListCopy[i]);
            }
            this.unitProgramSelected = this.unitProgramsFormControl;
            this.unitsForm.setValue([]);
            this._preselectAllUnitPrograms(true);
            this.unitProgramList = this.unitProgramListCopy;
            this.subfilters[1].data = this.unitProgramList;
        } else if (index === 1) {
            let subfiltersCopy: any[] = [];
            let exist = false;

            for (let i = 0; i < this.unitProgramList.length; i++) {
                if (this.unitProgramList[i].name === opcion.name) {
                    exist = true;
                }
            }

            if (!exist) {
                this.unitProgramList.push(opcion);
            } else {
                for (let i = 0; i < this.unitProgramList.length; i++) {
                    if (this.unitProgramList[i].name !== opcion.name) subfiltersCopy.push(this.unitProgramList[i]);
                }
                this.unitProgramList = subfiltersCopy;
            }
        }
        this.isButtonDisabled();
    }

    private _isDateSelectedIncorrect(): boolean {
        const dateInit = new Date(this.dateInitSelected);
        const dateEnd = new Date(this.dateEndSelected);
        this.errorMessageTranslationCode = '';
        switch (this.selectedValue) {
            case TipoEnvios.DAILY_FORECAST:
                // Comprobar si la diferencia es de máximo 30 días
                if (Math.abs((dateEnd.getTime() - dateInit.getTime()) / (1000 * 60 * 60 * 24)) > 30) {
                    this.errorMessageTranslationCode = '30_DAYS_LIMIT';
                    return true;
                }
                return false;
            case TipoEnvios.WEEKLY_FORECAST:
                // Comprobar si dateInit es un sábado y dateEnd es un viernes
                if (dateInit.getDay() !== 6 || dateEnd.getDay() !== 5) {
                    this.errorMessageTranslationCode = 'FROM_SAT_TO_FRI';
                    return true;
                }
                return false;
            case TipoEnvios.OPTIONAL_SETTLEMENT:
                // Comprobar si las fechas están dentro del mismo mes
                if (dateInit.getMonth() !== dateEnd.getMonth()) {
                    this.errorMessageTranslationCode = 'IN_A_SINGLE_MONTH';
                    return true;
                }
                return false;
            default:
                return true;
        }
    }

    private _preselectAllUnitPrograms(clear?: boolean): void {
        this.unitProgramSelected = [];
        this.unitProgramsFormControl = [];
        this.unitsForm.reset();
        let arrayUnitPrograms: any[] = [];

        arrayUnitPrograms = this.subfilters[1].data;
        for (let i = 0; i < arrayUnitPrograms.length; i++) {
            this.unitProgramsFormControl.push(arrayUnitPrograms[i]);
        }
    }
}
