export interface SistemaCargasOption {
    id: number;
    name: string;
    translation: string;
}

export class Files {
    id: string;
    area: string;
    description: string;
    file: string;
    fileTemplate: string;
    origin: string;
    name?: string;
    size?: number;
    status?: string;
    selected?: boolean;
    uploaded?: boolean;
    uploadError?: boolean;
    content?: File;
}

export class FilesToUpload {
    origin: string;
    file: string;
    names: string[];
}

export class FileName {
    name?: string;
    description?: string;
    status?: string;
}
