<div class="dialog">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
        <div class="box_textarea">
            <div *ngIf="!modeRead">
                <textarea
                    matInput
                    cdkTextareaAutosize
                    #box
                    #autosize="cdkTextareaAutosize"
                    value="{{ value }}"
                    [(ngModel)]="value"
                ></textarea>
            </div>
            <div *ngIf="modeRead">
                <div *ngFor="let cup of data.newCups; let i = index" class="cups">
                    <span class="box_cups_input"
                        >{{ cup }}
                        <mat-icon class="close" (click)="remove(i)">close</mat-icon>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="close()" *ngIf="modeRead">{{ 'PAGE.MODALS.CLOSE' | translate }}</button>
        <button mat-flat-button color="warn" (click)="save()" *ngIf="modeRead">
            {{ 'PAGE.MODALS.SAVE' | translate }}
        </button>
        <button mat-button (click)="close()" *ngIf="!modeRead">{{ 'PAGE.MODALS.CANCEL' | translate }}</button>
        <button
            mat-flat-button
            color="warn"
            (click)="checkCups(value); emitValue(value); emitValueNew(value)"
            *ngIf="!modeRead"
        >
            {{ 'PAGE.MODALS.VALIDATE' | translate | uppercase }}
        </button>
    </div>
</div>
