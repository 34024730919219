<div class="modal">
    <div class="modal-title-text-title">
        <div class="modal-title-close">
            <h1 mat-dialog-title>{{ 'PAGE.MODALS.TITLE1' | translate }}</h1>
            <button mat-button mat-dialog-close class="hide-access close">close</button>
        </div>
        <div mat-dialog-content>
            {{ 'PAGE.MODALS.TEXT_TITLE_1' | translate }}
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="secondary" (click)="close()">
            {{ 'PAGE.MODALS.CANCEL' | translate }}
        </button>
        <button mat-button mat-flat-button color="warn" class="primary" cdkFocusInitial (click)="exportExcel()">
            {{ 'PAGE.MODALS.ACCEPT' | translate }}
        </button>
    </div>
</div>
