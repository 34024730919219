import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PrevisionesService {
    searchBy: boolean = false;
    selectId: string;
    year: number | number[];

    validCups: any; // Pagination
    pageIndex: number;
    pageSize: number;
    sortDir: string;
    sortActive: string; // Parametro de busqueda para los endpoint
    searchParam: string; // Variable para controlar si mostrar la tabla de conceptos de contratos con unas columnas u otras.
    showColumnsBy: string;

    constructor(private _http: HttpClient) {}

    launchPutPrevisionesIniciales(body: any) {
        const url = `${environment.apiUrl}/forecast/v0`;
        return this._http.put<any>(url, body, { observe: 'response' });
    }

    getTablaPrelacion(): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-prelacion.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting('area', 1, 'search=area=Previsiones', 'asc', 'precedence/common', 'area', 10);
        }
    }

    getUnidadPrograma() {
        const url = `${environment.apiUrl}/unitProgram/v0?page=0&sortDir=asc`;
        return this._http.get<any>(url);
    }

    getTablaPrevisionesAgregado(page: number, sortBy: string, sortDir: string, search: string): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/previsiones-agregado.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, search, sortDir, 'aggregatedUnit', 'programUnitCode');
        }
    }

    updateAggregatedUnit(body: any) {
        //ACTUALIZAR FESTIVOS
        const url = `${environment.apiUrl}/aggregatedUnit/v0`;
        return this._http.put<any>(url, body);
    }

    checkSorting(
        sortBy: string,
        page: number,
        search: string,
        sortDir: string,
        endpoint: string,
        sortType: string,
        pageSize?: number,
        sort = 'asc'
    ) {
        let url: string = `${environment.apiUrl}/${endpoint}/v0?page=${page}&pageSize=${pageSize}`;

        if (pageSize === undefined && sortBy === undefined) {
            url = `${environment.apiUrl}/${endpoint}/v0?page=${page}&sortBy=${sortType}&sortDir=${sort}&search=${search}`;
        } else {
            url += `&sortBy=${sortBy}&sortDir=${sortDir}&search=${search}`;
        }
        return this._http.get<any[]>(url);
    }
}
