import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-filtro-toggle',
    templateUrl: './filtro-toggle.component.html',
    styleUrls: ['./filtro-toggle.component.scss'],
})
export class FiltroToggleComponent implements OnInit {
    @Input() title: string;
    @Input() disableToggle: boolean;
    @Output() activeToggle: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
    activeDefaultSelec() {
        this.activeToggle.emit();
    }
}
