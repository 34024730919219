import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Filtro, FiltroSelect } from 'src/app/shared/models/filtros.interface';

@Component({
    selector: 'app-filtro-multiselect',
    templateUrl: './filtro-multiselect.component.html',
    styleUrls: ['./filtro-multiselect.component.scss'],
})
export class FiltroMultiselectComponent implements OnInit {
    formControl = new FormControl('');
    value: string[] = [];

    @Input() filtroUrl: FiltroSelect[]; // lo que te manda la pagina
    @Input() title: string;
    @Input() key: string;
    @Output() valueChange = new EventEmitter<{ key: string; value: string[] }>();
    @Output() select: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    /**
     * En esta función actualizamos el valor de la variable interna 'value' con los ids que llegan desde el evento.
     * También emitimos de vuelta el argumento 'valueChange' y 'select' actualizados.
     * @param event - any (Formato { key: string; value: string[] })
     */
    emitValue(event: any) {
        const selectedValues = event.value;
        var valores: string[] = [];
        selectedValues.forEach((item: { id: string; name: string }) => {
            if (item.id == undefined || item.id == '') {
                valores.push(item.name);
            } else {
                valores.push(item.id);
            }
        });

        this.value = valores;

        this.select.emit(valores);
        this.valueChange.emit({ key: this.key, value: valores });
    }
}
