import { Roles } from '../enums/roles.enum';
import { ItemMenu } from '../interfaces/itemMenu.interface';

export const ProcesosPage: ItemMenu = {
    id: '3',
    name: 'PROCESOS',
    title: 'Datos procesos',
    route: 'procesos',
    icon: 'account_tree',
    subItems: [
        {
            id: '3.1',
            name: 'LAUNCH',
            title: 'Lanzamiento de procesos',
            route: 'procesos/lanzar',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
        },
        {
            id: '3.2',
            name: 'RESULTS',
            title: 'Resultados de procesos',
            route: 'procesos/resultados',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.CONSULTA, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
        },
        {
            id: '3.3',
            name: 'PROGRAM',
            title: 'Programación de procesos',
            route: 'procesos/programar',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
        },
    ],
    roles: [Roles.ADMIN, Roles.CONSULTA, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
};

export interface MainFilterProgramar {
    id: string;
    key: string;
    url?: string;
    data: MainFilterData[];
    name: string;
    type: string;
    editableSelect?: boolean;
    required: boolean;
}

export interface MainFilterData {
    id: string;
    name: string;
    filters?: any;
}
