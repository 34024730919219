import { Component, OnInit } from '@angular/core';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { SortingObject } from 'src/app/shared/models/files.interface';
import { ProcesosPage } from 'src/app/shared/models/procesos-page.data';
import { PaginationObject } from 'src/app/shared/models/paginationObject.interface';
import { ProcesosService } from '../services/procesos.service';
import { MaestroService } from '../../maestro/services/maestro.service';
import { HeaderTitleService } from '../../../services/header-title.service';

@Component({
    selector: 'app-procesos-lanzar',
    templateUrl: './procesos-lanzar.component.html',
    styleUrls: ['./procesos-lanzar.component.scss'],
})
export class ProcesosLanzarComponent implements OnInit {
    dataBusiness: any;
    dataHeaders: IColumnsTable[];
    dataPagination: PaginationObject;
    dataSorting: SortingObject;
    columns: any;
    totalRegisters: number;
    permissions: any;
    headerEdit: IColumnsTable;
    headerDelete: IColumnsTable;
    // nombre de la tabla seleccionada en el concepto
    filtersTable: any[];
    filters: any;
    idFilters: any;
    enableFilterConcept: boolean;

    constructor(
        private _procesosService: ProcesosService,
        private _maestroService: MaestroService,
        private _headerTitleService: HeaderTitleService
    ) {}

    ngOnInit(): void {
        this._headerTitleService.currentHeaderTranslationCode = 'PROCESS_LAUNCH';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
        this._maestroService.selectId = 'procesosLanzar';
        this.enableFilterConcept = true;
    }

    transformHeaders() {
        this.columns = this.dataHeaders.map((col) => col.id);
    }

    clearTable() {
        this.dataBusiness = undefined;
    }

    receiveIdSelect($event: any) {
        let filter: boolean = false;
        for (const element of $event[1]) {
            if (element.selected) {
                filter = true;
            }
        }
        this.filtersTable = $event;
    }

    sort($event: any) {
        this._procesosService.getTablaPreciosSort($event).subscribe((res) => {
            this.dataBusiness = res.preciosRegulados;
            this.dataHeaders = res.headers;
            this.totalRegisters = res.totalRegisters;
            this.transformHeaders();
        });
    }

    pagination($event: any) {
        if ($event.pageSize === 5) {
            if ($event.pageIndex === 0) {
                this._procesosService.getTablaPreciosPaginationOne().subscribe((res) => {
                    this.dataBusiness = res.preciosRegulados;
                    this.dataHeaders = res.headers;
                    this.totalRegisters = res.totalRegisters;
                    this.transformHeaders();
                });
            } else {
                if ($event.pageIndex === 1) {
                    this._procesosService.getTablaPreciosPaginationTwo().subscribe((res) => {
                        this.dataBusiness = res.preciosRegulados;
                        this.dataHeaders = res.headers;
                        this.totalRegisters = res.totalRegisters;
                        this.transformHeaders();
                    });
                }
            }
        } else {
            this._procesosService.getTablaPrecios().subscribe((res) => {
                this.dataBusiness = res.preciosRegulados;
                this.dataHeaders = res.headers;
                this.totalRegisters = res.totalRegisters;
                this.transformHeaders();
            });
        }
    }

    chargeFiltersInTable($event: any) {
        this.filters = $event;
    }
}
