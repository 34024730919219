import { Component, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { Users } from 'src/app/shared/interfaces/user.interface';
import { UserManagService } from '../../services/user-manag.service';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/core/services/login.service';
import { HeaderTitleService } from '../../../../services/header-title.service';

@Component({
    selector: 'app-management-user',
    templateUrl: './management-user.component.html',
    styleUrls: ['./management-user.component.scss'],
})
export class ManagementUserComponent implements OnInit {
    displayedColumns2 = ['Username', 'Name', 'Apellidos', 'Roles', 'edit', 'delete'];
    search: string = '';
    usersList: any; //Aqui se almacena TODA la lista de usuarios
    usersListFilters: any; //Aquí se almacenan los usuarios filtrados tras el "search"
    selectRoles: any[];
    userAllRoles: any;
    rolesAll: any[];
    rolesAllName: any[];
    value: string = '';
    nameNewUsername: string = '';
    nameNewUser: string = '';
    surnameNewUser: string = '';
    rolesNewUser: any[];
    rolesNewUser2: any[];
    newUser: Users;
    userEdit: Users;
    openModal: boolean;
    openModalDelete: boolean = false;
    inputUsernames: any;
    inputNames: any;
    inputSurnames: any;
    roleSelect: any;
    isAdmin: boolean;
    @Output() disabled: boolean;
    disabledInsert: boolean = false;
    editMode: boolean = false;
    userToDelete: Users;
    headerList: any;

    constructor(
        private globalService: GlobalService,
        public userManagService: UserManagService,
        private _loginService: LoginService,
        private _headerTitleService: HeaderTitleService
    ) {}

    ngOnInit(): void {
        this._headerTitleService.currentHeaderTranslationCode = 'USERS_MANAGEMENT';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);

        this.getHeadersUsersMock();
        if (environment.isMockActive) {
            this.getUsersMock();
        } else {
            this.getUsers();
            this.setRoles();
        }

        let roles = this._loginService.getUserActiveRoles();
        if (roles?.includes('ADMIN')) {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }
    }

    setRoles() {
        this.userAllRoles = this._loginService.getRolesTotal();
        this.rolesAll = JSON.parse(this.userAllRoles);
    }

    editUser(editUser: Users) {
        this.userAllRoles = this._loginService.getRolesTotal();
        this.userAllRoles = JSON.parse(this.userAllRoles);
        this.openModal = true;
        this.disabled = true;
        this.editMode = true;
        this.userEdit = editUser;
        this.inputUsernames = editUser.username;
        this.inputNames = editUser.name;
        this.inputSurnames = editUser.surnames;
        this.roleSelect = editUser.roles;
        this.rolesNewUser = this.rolesNewUser2;
    }

    removeAccents = (str: any) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    searchFilter(filter: any) {
        filter = filter.toLowerCase();
        filter = this.removeAccents(filter);
        if (filter === '') {
            //Si viene vacio deja la lista al completo
            this.usersListFilters = this.usersList;
            return;
        }
        let cont = 0;
        this.usersListFilters = [];

        for (let i = 0; i < this.usersList.length; i++) {
            if (this.removeAccents(this.usersList[i].username).toLowerCase().includes(filter)) {
                this.usersListFilters.push(this.usersList[i]);
                cont++;
            } else {
                if ((cont = 0)) {
                    this.usersListFilters = [];
                }
            }
        }
    }

    getUsersMock() {
        //Obtenemos user de Mock
        this.globalService.getUsersMock().subscribe((usersMock) => {
            this.usersList = [];
            this.usersList = usersMock;
            this.usersListFilters = usersMock;
        });
    }

    getHeadersUsersMock() {
        //Obtenemos user de Mock
        this.globalService.getHeadersUsersMock().subscribe((usersHeadersMock) => {
            this.headerList = [];
            this.headerList = usersHeadersMock;
        });
    }

    addUser() {
        this.editMode = false;
        this.openModal = true;
        this.disabledInsert = true;
        this.nameNewUser = '';
        this.surnameNewUser = '';
        this.rolesNewUser = [];
        this.inputNames = '';
        this.inputSurnames = '';
        this.roleSelect = '';
        // this.inputRoles = [];
        this.inputUsernames = '';
    }

    emitValueUserName($event: any) {
        this.nameNewUsername = $event.newValue;
    }
    emitValueRoles($event: any) {
        this.rolesNewUser = $event;
    }

    emitValueName($event: any) {
        this.nameNewUser = $event.newValue;
    }

    emitValueSurname($event: any) {
        this.surnameNewUser = $event.newValue;
    }

    //BACK

    getUsers() {
        //Obtener todos los usuarios del Back
        this.globalService.getUsers().subscribe((users) => {
            this.usersList = [];
            this.usersList = users.data;
            this.usersListFilters = users.data;
        });
    }

    deleteUser() {
        if (environment.isMockActive) {
            for (let i = 0; i < this.usersList.length; i++) {
                if (this.usersList[i] === this.userToDelete) {
                    this.usersList.splice(i, 1);
                }
            }
        } else {
            this.globalService.deleteUsers(this.userToDelete.username).subscribe((users) => {
                this.getUsers();
            });
            this.getUsers();
        }
        this.usersListFilters = [];
        this.usersListFilters = [...this.usersListFilters, ...this.usersList];
        this.openModalDelete = false;
        this.userToDelete != undefined;
    }

    addNewUser() {
        this.disabled = false;
        this.openModal = false;
        if (environment.isMockActive) {
        } else {
            if (this.editMode) {
                var userEditActive;
                if (this.nameNewUsername === '' || this.nameNewUsername === undefined) {
                    this.nameNewUsername = this.inputUsernames;
                }
                if (this.rolesNewUser === this.rolesNewUser2) {
                    this.rolesNewUser = this.userEdit.roles;
                }

                if (this.nameNewUser === '' || this.nameNewUser === undefined) {
                    this.nameNewUser = this.inputNames;
                }
                if (this.surnameNewUser === '' || this.surnameNewUser === undefined) {
                    this.surnameNewUser = this.inputSurnames;
                }

                this.userEdit.name = this.nameNewUser.trim();
                this.userEdit.surnames = this.surnameNewUser.trim();
                this.userEdit.roles = this.rolesNewUser;

                this._loginService.setUserActiveRoles(this.userEdit.roles);

                this.globalService.updateUsers(this.nameNewUsername, this.userEdit).subscribe((users) => {
                    this.editMode = false;
                });
            } else {
                if (
                    this.nameNewUser === '' ||
                    this.nameNewUser === undefined ||
                    this.nameNewUsername === '' ||
                    this.nameNewUsername === undefined ||
                    this.surnameNewUser === '' ||
                    this.surnameNewUser === undefined ||
                    this.rolesNewUser === undefined
                ) {
                    return;
                }

                this.newUser = {
                    username: this.nameNewUsername.trim(),
                    name: this.nameNewUser.trim(),
                    surnames: this.surnameNewUser.trim(),
                    roles: this.rolesNewUser,
                };
                this.globalService.addUsers(this.nameNewUsername, this.newUser).subscribe((users) => {
                    this.editMode = false;
                    this.getUsers();
                });
            }
            this.nameNewUser = '';
            this.surnameNewUser = '';
            this.rolesNewUser = [];
            this.nameNewUsername = '';
            this.inputUsernames = '';
        }
    }

    //MODAL
    manageModalDelete(deleteUser: Users) {
        this.openModalDelete = true;
        this.userToDelete = deleteUser;
    }

    closeModal() {
        this.openModal = false;
        this.disabled = false;
        this.editMode = false;
        this.openModalDelete = false;
    }
}
