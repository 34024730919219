<div *ngIf="isAdmin">
    <mat-form-field>
        <div style="display: flex; flex-direction: row">
            <input
                matInput
                placeholder="{{ 'PAGE.FILTERS.SEARCH_BY_NAME' | translate }}"
                #search
                id="search"
                name="search"
                (keyup)="searchFilter(search.value)"
            />
            <mat-icon>search</mat-icon>
        </div>
    </mat-form-field>

    <div class="container">
        <app-spinner></app-spinner>
        <mat-table [dataSource]="usersListFilters">
            <ng-container matColumnDef="Username">
                <mat-header-cell *matHeaderCellDef> {{ 'PAGE.USERS.USERNAME' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.username }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Name">
                <mat-header-cell *matHeaderCellDef> {{ 'PAGE.USERS.NAME' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Apellidos">
                <mat-header-cell *matHeaderCellDef> {{ 'PAGE.USERS.SURNAME' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.surnames }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Roles">
                <mat-header-cell *matHeaderCellDef> {{ 'PAGE.USERS.ROLES' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.roles }} </mat-cell>
            </ng-container>
            <tbody>
                <div>
                    <ng-container matColumnDef="edit">
                        <mat-header-cell *matHeaderCellDef> {{ 'PAGE.ACTIONS.EDIT' | translate }} </mat-header-cell>
                        <mat-cell class="cursor_pointer" (click)="editUser(element)" *matCellDef="let element">
                            <span class="icon material-symbols-outlined icon_color">edit</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <mat-header-cell *matHeaderCellDef> {{ 'PAGE.ACTIONS.DELETE' | translate }} </mat-header-cell>
                        <mat-cell class="cursor_pointer" (click)="manageModalDelete(element)" *matCellDef="let element">
                            <span class="icon material-symbols-outlined icon_color">delete</span>
                        </mat-cell>
                    </ng-container>
                </div>
            </tbody>
            <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns2"></mat-row>
        </mat-table>
        <div>
            <button class="mat" mat-button type="button" (click)="addUser()">
                {{ 'PAGE.USERS.CREATE_USER' | translate }}
            </button>
        </div>
    </div>

    <div *ngIf="openModal" class="cdk-overlay-container">
        <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
        <div class="modal_box cdk-overlay-container">
            <div
                style="max-width: 80vw; position: static; padding: 24px"
                class="cdk-overlay-pane"
                class="mat-dialog-container ng-tns-c122-25 ng-trigger ng-trigger-dialogContainer ng-star-inserted"
            >
                <div style="display: flex; justify-content: space-between">
                    <h1 *ngIf="!editMode" mat-dialog-title>{{ 'PAGE.MODALS.TITLE6' | translate }}</h1>
                    <h1 *ngIf="editMode" mat-dialog-title>{{ 'PAGE.MODALS.TITLE7' | translate }}</h1>

                    <button class="hide-access close" (click)="closeModal()" mat-button>close</button>
                </div>
                <div mat-dialog-content class="items_modal_box">
                    <div class="width_input_box">
                        <app-filtro-input
                            matInput
                            placeholder="Username"
                            [disabled]="disabled"
                            #inputUsername
                            id="inputUsername"
                            name="Username"
                            [value]="inputUsernames"
                            (select)="emitValueUserName($event)"
                        ></app-filtro-input>
                    </div>

                    <div class="width_input_box">
                        <app-filtro-input
                            matInput
                            placeholder="Nombre"
                            #inputName
                            id="inputName"
                            name="Nombre"
                            [value]="inputNames"
                            (select)="emitValueName($event)"
                        ></app-filtro-input>
                    </div>

                    <div class="width_input_box">
                        <app-filtro-input
                            matInput
                            placeholder="Apellidos"
                            #inputSurname
                            id="inputSurname"
                            name="Apellidos"
                            [value]="inputSurnames"
                            (select)="emitValueSurname($event)"
                        ></app-filtro-input>
                    </div>

                    <mat-form-field class="select width_input_box">
                        <mat-select
                            multiple
                            placeholder="Roles"
                            (valueChange)="emitValueRoles(optionRol.value)"
                            #optionRol
                            name="optionRol"
                            id="optionRol"
                            id="optionRol"
                            name="optionRol"
                            [value]="roleSelect"
                        >
                            <mat-option *ngFor="let rol of rolesAll" [value]="rol">
                                {{ rol }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="display_center">
                    <button mat-button (click)="closeModal()" class="secondary">
                        {{ 'PAGE.MODALS.DECLINE' | translate }}
                    </button>
                    <button
                        mat-button
                        mat-flat-button
                        color="warn"
                        class="primary"
                        cdkFocusInitial
                        (click)="addNewUser()"
                    >
                        {{ 'PAGE.MODALS.ACCEPT' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="openModalDelete" class="cdk-overlay-container">
        <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
        <div
            class="cdk-overlay-container"
            style="justify-content: center; align-items: center; display: flex; pointer-events: auto"
        >
            <div
                style="max-width: 80vw; position: static; padding: 24px"
                class="cdk-overlay-pane"
                class="mat-dialog-container ng-tns-c122-25 ng-trigger ng-trigger-dialogContainer ng-star-inserted"
            >
                <div class="display_space_between">
                    <h1 class="margin_title_box" mat-dialog-title>{{ 'PAGE.MODALS.TITLE5' | translate }}</h1>
                </div>
                <div mat-dialog-content>
                    {{ 'PAGE.MODALS.TEXT_TITLE_4' | translate }}
                </div>
                <div class="buttonsRow">
                    <button mat-button (click)="closeModal()" class="secondary">
                        {{ 'PAGE.MODALS.DECLINE' | translate }}
                    </button>
                    <button
                        mat-button
                        mat-flat-button
                        color="warn"
                        class="primary"
                        cdkFocusInitial
                        (click)="deleteUser()"
                    >
                        {{ 'PAGE.MODALS.ACCEPT' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isAdmin">
    <h3 class="invalidRol">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
</div>
