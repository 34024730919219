import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltroSelect } from 'src/app/shared/models/filtros.interface';

@Component({
    selector: 'app-filtro-month',
    templateUrl: './filtro-month.component.html',
    styleUrls: ['./filtro-month.component.scss'],
})
export class FiltroMonthComponent implements OnInit {
    @Input() filtroUrl: FiltroSelect[]; // lo que te manda la pagina
    @Input() title: string;
    @Input() id: string;
    @Input() value: any;
    @Output() valueChange = new EventEmitter<{ id: string; value: string }>();
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() selectSelected: EventEmitter<any> = new EventEmitter();
    optionsMonth = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ];

    constructor() {}

    ngOnInit(): void {}

    emitValue(valor: any) {
        valor = valor.value;
        this.select.emit(valor);
        this.valueChange.emit({ id: this.id, value: valor });
    }

    emitValueNew($event: any) {
        this.selectSelected.emit({ newValue: $event.value.name, name: this.id });
    }
}
