export enum EndpointsProcesos {
    SortByInitDate = 'initDate',
    ASC = 'asc',
    DESC = 'desc',
}

export enum Concepts {
    PREVISIONES_INICIALES = 'PR0002',
    PREVISIONES_PROYECTADAS = 'PR0003',
    CIERRE_VIRTUAL = 'PR0004',
    MEDIDAS_OBJECIONES = 'PR0005',
    MEDIDAS_HORARIAS_CUARTOS_HORARIAS = 'PR0006',
    CALCULO_AGREGADO_PREVISIONES = 'PR0007',
    ENVIO_METEOROLOGICA = 'PR0008',
    ANALISIS_RESULTADOS = 'PR0009',
    LIQUIDACION_POTESTATIVA = 'PR0011',
}

export enum KeysProcesos {
    PROCESS = 'procesos',
}

export enum Mode {
    EDIT = 'EDIT',
    CREATE = 'CREATE',
}

export enum MonthsTranslate {
    JAN = 1,
    FEB,
    MAR,
    APR,
    MAY,
    JUN,
    JUL,
    AUG,
    SEPT,
    OCT,
    NOV,
    DEC,
}

export enum ConceptsLabel {
    PREVISIONES_PROYECTADAS = 'Previsiones proyectadas',
    PREVISIONES_INICIALES = 'Previsiones iniciales',
    CIERRE_VIRTUAL = 'Cierre virtual',
    MEDIDAS_OBJECIONES = 'Calculo objeciones potenciales',
    MEDIDAS_CUARTO_HORARIO = 'Medidas horarias desde cuarto horarias',
    AGREGADO_PREVISIONES = 'Cálculo agregado de previsiones',
    ENVIO_METEOLOGICA = 'Envío a meteológica',
    ANALISIS_RESULTADOS = 'Cálculo informe análisis de resultados',
    DASHBOARD = 'Calculo dashboard',
    LIQUIDACION_POTESTATIVA = 'Liquidación potestativa',
}

export enum SistemasProcesosEnum {
    MEDIDAS = 'Medidas',
    CIERRES = 'Cierres',
    PREVISIONES = 'Previsiones',
    PRECIOS = 'Precios',
    CONCILIACION = 'Conciliacion',
}

export enum Status {
    IN_PROGRESS = 'IN_PROGRESS',
    OK = 'OK',
}
