import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    availableLanguages: string[] = ['es', 'pt', 'en'];
    browserLanguage: string;
    defaultLanguage = 'es';
    preferredLanguage: string | null;

    get languages(): string[] {
        return this.availableLanguages;
    }

    get languageBrowser(): string {
        return this.browserLanguage;
    }

    constructor(private readonly _translateService: TranslateService) {
        this.preferredLanguage = this.getPreferredLanguage();
        this._translateService.setDefaultLang(this.preferredLanguage ? this.preferredLanguage : this.defaultLanguage);
    }

    public changeLanguage(lang: string): void {
        this.defaultLanguage = lang;
        this._translateService.use(lang);
        this.setPreferredLanguage(lang);
        this.preferredLanguage = this.getPreferredLanguage();
    }

    public setPreferredLanguage(language: string): void {
        localStorage.setItem('userPreferredLanguage', language);
    }

    public getPreferredLanguage(): string | null {
        return localStorage.getItem('userPreferredLanguage');
    }
}
