<div *ngIf="validRole">
    <div class="container" style="justify-content: flex-start">
        <div class="subfilter" *ngFor="let filtro of subfilters; let i = index">
            <app-filtro-date
                [isDisabled]="toggleActive"
                (select)="restarValue($event, i, filtro.key, filtro.type)"
                (select)="receiveDate($event, i)"
                *ngIf="filtro.type === 'Date' && showSelect"
                [name]="filtro.name"
            >
            </app-filtro-date>

            <app-filtro-select
                [disableSelect]="
                    toggleActive ||
                    cuentaNegociadoraActive ||
                    (previsionService.validCups != undefined && isInitialForecastsSelected)
                "
                (select)="restarValue($event, i, filtro.key, filtro.type)"
                #selects
                *ngIf="
                    filtro.type === 'Select' &&
                    showSelect &&
                    filtro.name != 'Unidad de Programa' &&
                    filtro.name != 'Sistema eléctrico' &&
                    filtro.name != 'Cuenta Negociadora' &&
                    filtro.name != 'Tarifas'
                "
                [toggleLabelLeft]="filtro.isToggle?.leftLabel"
                [toggleLabelRight]="filtro.isToggle?.rightLabel"
                [charactersControl]="filtro.isSearchable?.specialSearch"
                [searchByToggle]="filtro.isToggle?.active"
                [isSearchable]="filtro.isSearchable?.active"
                [filtroUrl]="filtro.data"
                [title]="filtro.name"
                [key]="filtro.key"
                [id]="filtro.id"
                (valueChange)="obtainOption($event)"
                (searchBy)="getFiltersBy($event)"
                (openSelect)="byOpenSelect($event)"
                (openSelect)="receiveDate2($event)"
                (select)="receiveDate($event, i)"
            >
            </app-filtro-select>

            <app-filtro-cups
                [disabledCUPS]="toggleActive"
                (select)="restarValue($event, i, filtro.key, filtro.type)"
                *ngIf="filtro.type === 'cups' && showSelect"
                [filtroUrl]="filtro.data"
                [name]="filtro.name"
                (resetComplete)="resetComplete($event)"
                (reactivateCUPs)="reactivateCUPs()"
                [reset]="resetCups"
            >
            </app-filtro-cups>
        </div>
    </div>
</div>
<div *ngIf="!validRole">
    <h3 class="invalidRol">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
</div>
<div class="buttonsFilter">
    <div class="buttonsActionsDisplay"></div>
    <div class="buttonsFilterNoMargin">
        <button class="delete" (click)="resetFilters()" (mouseup)="hideSelect()" mat-button>
            {{ 'PAGE.ACTIONS.DELETE' | translate }} <app-base-icon iconName="filter_alt_off" class=""></app-base-icon>
        </button>

        <button
            class="search"
            [disabled]="this.maestroService.validCups === undefined || isDisabled === true"
            (click)="loadTable()"
            mat-flat-button
            color="warn"
        >
            {{ 'PAGE.ACTIONS.EXECUTION' | translate | uppercase }}
            <app-base-icon iconName="settings" class=""></app-base-icon>
        </button>
    </div>
</div>
