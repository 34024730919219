import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service';
import { HeaderTitleService } from '../../../services/header-title.service';
import { PrevisionesPage } from 'src/app/shared/models/previsiones-page.data';

@Component({
    selector: 'app-mejor-prevision-page',
    templateUrl: './mejor-prevision-page.component.html',
    styleUrls: ['./mejor-prevision-page.component.scss'],
})
export class MejorPrevisionPageComponent implements OnInit {
    dataBusiness: any;
    dataHeaders: any;
    columns: any;
    permissions: any;
    filtersTable: any[]; // nombre de la tabla seleccionada en el concepto
    filters: any;
    idFilters: any;
    openModalExcel: boolean;

    dataHeaders_valueSelect: any;
    ocultar: boolean = true;
    element: any = false;
    newDataBusiness: any;

    systemSelect: string;

    headers: any[];

    search: any = [];

    message: string = '';
    action: string = '';
    config: any;

    pageSizeOptions: number[] = [10, 25, 50, 100];
    pageIndex: any;
    sortActive: any;
    sortDir: any;
    totalRegisters: number;

    pageSize: number;
    totalPages: number;
    numPage: number;

    constructor(private _headerTitleService: HeaderTitleService, private _translateService: TranslateService) {}

    checkvalue(asd: any) {
        this.columns = [];
        for (let i = 0; i < asd.length; i++) {
            this.columns.push(asd[i].id);
        }
    }

    ngOnInit(): void {
        this._headerTitleService.currentHeaderTranslationCode = 'BEST_FORECAST';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
        this.message = this._translateService.instant('PAGE.MODALS.LOADING_MESSAGE_EXCEL');
        this.action = this._translateService.instant('PAGE.MODALS.CLOSE');
    }

    botonClick() {
        if (this.element) {
            //Mostrar-ocultar Etiqueta Span "Columnas"
            this.element = false;
        } else {
            this.element = true;
        }
        if (!this.ocultar) {
            //Mostrar-ocultar select de columnas
            this.ocultar = true;
        } else {
            this.ocultar = false;
        }
    }

    transformHeaders() {
        /*     this.columns = this.dataHeaders.map((col) => col.id);
         */
    }

    buildTable(res: any, idHeaders: number): void {
        this.dataBusiness = res[0].data;
        this.dataHeaders = res[1].data[idHeaders].headers;
        this.totalRegisters = res[0].page.totalElements;
        this.numPage = res[0].page.number;
        this.pageSize = res[0].page.size;
        this.totalPages = res[0].page.totalPages;

        this.transformHeaders();
    }

    receiveIdFilters($event: any) {
        this.getInfoTablaByFilterSort($event);
    }
    saveSearch: any;
    receiveIdSelect($event: any) {
        let selectedUser;
        let selectedDates;
        let selectedInitDate;
        let selectedEndDate;
        let selectedStatus;
        let selectedProcess;
        for (let i = 0; i < $event[1].length; i++) {
            //Sacar Usuario
            if ($event[1][i].key === 'user' && $event[1][i].selected !== undefined) {
                selectedUser = 'creationUser=' + $event[1][i].selected;
                this.search.push(selectedUser);
            }
        }

        // Filtro Medidas o Previsiones
        if ($event[2]) {
            this.search.push(`area=${$event[2]}`);
        }

        for (let i = 0; i < $event[1].length; i++) {
            //Sacar tipo de proceso
            if ($event[1][i].key === 'type' && $event[1][i].selected !== undefined) {
                selectedProcess = 'name=' + $event[1][i].selected;
                this.search.push(selectedProcess);
            }
        }

        for (let i = 0; i < $event[1].length; i++) {
            //Sacar fechas
            if ($event[1][i].key === 'initDate' && $event[1][i].selected !== undefined) {
                selectedDates = $event[1][i].selected.split('~');
                selectedInitDate = 'initDate>=' + selectedDates[0];
                selectedEndDate = 'endDate<=' + selectedDates[1];
                this.search.push(selectedInitDate);
                this.search.push(selectedEndDate);
            }
        }

        for (let i = 0; i < $event[1].length; i++) {
            //Sacar estado
            if ($event[1][i].key === 'status' && $event[1][i].selected !== undefined) {
                selectedStatus = $event[1][i].selected;
                if (selectedStatus === 'Error') {
                    selectedStatus = 'status=ERROR';
                    this.search.push(selectedStatus);
                }
                if (selectedStatus === 'En Curso') {
                    selectedStatus = 'status=IN_PROGRESS';
                    this.search.push(selectedStatus);
                }
                if (selectedStatus === 'OK') {
                    selectedStatus = 'status=OK';
                    this.search.push(selectedStatus);
                }
            }
        }
    }

    resetSearch(event: any) {
        if (event) {
            this.search = [];
            this.dataBusiness = [];
        }
    }

    clearTable($event: any) {
        this.dataBusiness = undefined;
    }
    /**
     * Cargamos la tabla
     */

    reset() {}

    getInfoTablaByFilterSort(s: any) {}

    chargeFiltersInTable($event: any) {
        this.filters = $event;
    }

    closeModal() {
        this.openModalExcel = false;
    }

    checkAndReturnColumns(element: any, columnId: string) {
        if (columnId.indexOf('.') > -1) {
            let columnIdSplitted = columnId.split('.');
            if (element[columnIdSplitted[0]]) {
                return element[columnIdSplitted[0]][columnIdSplitted[1]];
            }
            return undefined;
        }
        return element[columnId];
    }
}
