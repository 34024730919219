<div class="content_multiselect">
    <mat-form-field class="input__select" appearance="standard" *ngIf="!isChecked">
        <mat-label class="name__input">CUPS</mat-label>
        <mat-select multiple #multi>
            <mat-option *ngFor="let item of filtroUrl" [value]="item">
                {{ item.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="input__select" appearance="standard" *ngIf="isChecked">
        <mat-label class="name__input">CUPS</mat-label>
        <input matInput value="" (keyup.enter)="saveSelect($event)" autocomplete="off" />
    </mat-form-field>
</div>
