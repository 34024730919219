<div class="container">
    <div class="div_button" *ngIf="checkUserRoles()">
        <label>{{ 'PAGE.DUMMY_CONTRACTS.CREATE_FC_TITLE' | translate }}</label>
        <button class="search" mat-flat-button color="warn" (click)="openModal()" [disabled]="!isFormValid()">
            {{ 'PAGE.DUMMY_CONTRACTS.CREATE_BUTTON' | translate }}
        </button>
    </div>
    <div class="form_container" *ngIf="checkUserRoles()">
        <div [formGroup]="formGroup">
            <div class="form_part1">
                <!-- Descripción-->
                <mat-form-field class="input" appearance="standard">
                    <mat-label class="description"
                        >{{ 'PAGE.DUMMY_CONTRACTS.FORM.DESCRIPTION' | translate }}
                    </mat-label>
                    <input formControlName="description" matInput />
                </mat-form-field>
                <!-- Fechas  -->
                <app-filtro-date
                    name="PAGE.DUMMY_CONTRACTS.DATE_LABEL"
                    (select)="onSelectedDateChange($event)"
                ></app-filtro-date>
                <!-- CUPS -->
                <mat-form-field class="input" appearance="standard">
                    <mat-label class="cups">{{ 'PAGE.DUMMY_CONTRACTS.FORM.A_CUPS' | translate }}</mat-label>
                    <input formControlName="cups" matInput />
                </mat-form-field>
                <!-- CNAE  -->
                <div class="cnae_div">
                    <mat-form-field class="input" appearance="standard">
                        <mat-label class="CNAE">{{ 'PAGE.DUMMY_CONTRACTS.FORM.CNAE' | translate }}</mat-label>
                        <input formControlName="cnae" matInput />
                    </mat-form-field>
                    <span class="text-danger" *ngIf="maxLengthError('cnae')">
                        {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR_MAXLENGTH' | translate }}
                    </span>
                </div>
                <!-- UNIDAD DE PROGRAMA -->
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.DUMMY_CONTRACTS.FORM.PROGRAM_UNIT' | translate }}</mat-label>
                    <mat-select formControlName="programUnitCode" (selectionChange)="obtainUnitProgram($event)">
                        <mat-option *ngFor="let opcion of unitProgramList" [value]="opcion">
                            {{ opcion }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- TARIFA  -->
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.DUMMY_CONTRACTS.FORM.RATE' | translate }}</mat-label>
                    <mat-select formControlName="rate">
                        <mat-option *ngFor="let opcion of rateList" [value]="opcion">
                            {{ opcion }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Tipo de Cliente  -->
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.DUMMY_CONTRACTS.FORM.CLIENT_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="clientType" (selectionChange)="obtainClientType($event)">
                        <mat-option *ngFor="let client of clientTypes" [value]="client">
                            {{ client }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- ATR  -->
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.DUMMY_CONTRACTS.FORM.ATR_MANAGE' | translate }}</mat-label>
                    <mat-select formControlName="atr" (selectionChange)="obtainATR($event)">
                        <mat-option *ngFor="let atr of atrList" [value]="atr">
                            {{ 'PAGE.DUMMY_CONTRACTS.' + atr | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Coste alquiler equipo  -->
                <mat-form-field class="input" appearance="standard">
                    <mat-label class="cost_rental_equipment">{{
                        'PAGE.DUMMY_CONTRACTS.FORM.COST_RENTAL_EQUIPMENT' | translate
                    }}</mat-label>
                    <input type="number" formControlName="eqRentalPrice" matInput />
                </mat-form-field>
                <mat-form-field appearance="standard" class="select">
                    <mat-label>{{ 'PAGE.DUMMY_CONTRACTS.FORM.ADJUSTMENT_MECHANISM' | translate }}</mat-label>
                    <mat-select formControlName="adjMec" (selectionChange)="obtainAdjMec($event)">
                        <mat-option *ngFor="let adj of adjMech" [value]="adj">
                            {{ 'PAGE.DUMMY_CONTRACTS.' + adj | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form_part2">
                <div class="consumption_div">
                    <!-- Consumo P1  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="consumptionP1">{{
                                'PAGE.DUMMY_CONTRACTS.FORM.P1' | translate
                            }}</mat-label>
                            <input type="number" formControlName="consumptionP1" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('consumptionP1')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                    <!-- Consumo P2  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="consumptionP2">{{
                                'PAGE.DUMMY_CONTRACTS.FORM.P2' | translate
                            }}</mat-label>
                            <input type="number" formControlName="consumptionP2" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('consumptionP2')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                    <!-- Consumo P3  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="consumptionP3">{{
                                'PAGE.DUMMY_CONTRACTS.FORM.P3' | translate
                            }}</mat-label>
                            <input type="number" formControlName="consumptionP3" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('consumptionP3')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                    <!-- Consumo P4  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="consumptionP4">{{
                                'PAGE.DUMMY_CONTRACTS.FORM.P4' | translate
                            }}</mat-label>
                            <input type="number" formControlName="consumptionP4" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('consumptionP4')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                    <!-- Consumo P5  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="consumptionP5">{{
                                'PAGE.DUMMY_CONTRACTS.FORM.P5' | translate
                            }}</mat-label>
                            <input type="number" formControlName="consumptionP5" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('consumptionP5')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                    <!-- Consumo P6  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="consumptionP6">{{
                                'PAGE.DUMMY_CONTRACTS.FORM.P6' | translate
                            }}</mat-label>
                            <input type="number" formControlName="consumptionP6" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('consumptionP6')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                </div>
                <div class="powers_div">
                    <!-- Potencia P1  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="powerP1">{{ 'PAGE.DUMMY_CONTRACTS.FORM.PW1' | translate }}</mat-label>
                            <input type="number" formControlName="Pw1" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('Pw1')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                    <!-- Potencia P2  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="powerP2">{{ 'PAGE.DUMMY_CONTRACTS.FORM.PW2' | translate }}</mat-label>
                            <input type="number" formControlName="Pw2" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('Pw2')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                    <!-- Potencia P3  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="powerP3">{{ 'PAGE.DUMMY_CONTRACTS.FORM.PW3' | translate }}</mat-label>
                            <input type="number" formControlName="Pw3" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('Pw3')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                    <!-- Potencia P4  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="powerP4">{{ 'PAGE.DUMMY_CONTRACTS.FORM.PW4' | translate }}</mat-label>
                            <input type="number" formControlName="Pw4" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('Pw4')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                    <!-- Potencia P5  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="powerP5">{{ 'PAGE.DUMMY_CONTRACTS.FORM.PW5' | translate }}</mat-label>
                            <input type="number" formControlName="Pw5" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('Pw5')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                    <!-- Potencia P6  -->
                    <div class="form_part2_div">
                        <mat-form-field class="input" appearance="standard">
                            <mat-label class="powerP6">{{ 'PAGE.DUMMY_CONTRACTS.FORM.PW6' | translate }}</mat-label>
                            <input type="number" formControlName="Pw6" matInput />
                        </mat-form-field>
                        <span class="text-danger" *ngIf="formHasErrors('Pw6')">
                            {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="label_form" *ngIf="checkUserRoles()">
        <label>{{ 'PAGE.DUMMY_CONTRACTS.CREATE_FC_VARIABLES' | translate }}</label>
    </div>
    <div class="form_variables_container">
        <div [formGroup]="formGroupVariables" *ngIf="checkUserRoles()">
            <div>
                <mat-tab-group>
                    <mat-tab label="Tab 1">
                        <!-- OMIP Variable -->
                        <div formArrayName="omip" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.omip.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label
                                        >{{ 'PAGE.DUMMY_CONTRACTS.FORM.OMIP_PRICE_P' + (i + 1) | translate }}
                                    </mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('omip', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                        <!-- RRTT_SSCC Variable -->
                        <div formArrayName="rrtt_sscc" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.rrtt_sscc.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label
                                        >{{ 'PAGE.DUMMY_CONTRACTS.FORM.RRTT_SSCC_PRICE_P' + (i + 1) | translate }}
                                    </mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('rrtt_sscc', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                        <!-- SCD Variable -->
                        <div formArrayName="scd" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.scd.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label>{{
                                        'PAGE.DUMMY_CONTRACTS.FORM.SCD_PRICE_P' + (i + 1) | translate
                                    }}</mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('scd', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                        <!-- MAE Variable -->
                        <div formArrayName="mae" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.mae.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label>{{
                                        'PAGE.DUMMY_CONTRACTS.FORM.MAE_PRICE_P' + (i + 1) | translate
                                    }}</mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('mae', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Tab 2">
                        <!-- MC Variable -->
                        <div formArrayName="mc" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.mc.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label>{{
                                        'PAGE.DUMMY_CONTRACTS.FORM.MC_PRICE_P' + (i + 1) | translate
                                    }}</mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('mc', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                        <!-- SI Variable -->
                        <div formArrayName="si" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.si.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label>{{
                                        'PAGE.DUMMY_CONTRACTS.FORM.SI_PRICE_P' + (i + 1) | translate
                                    }}</mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('si', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                        <!-- CFE Variable -->
                        <div formArrayName="cfe" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.cfe.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label>{{
                                        'PAGE.DUMMY_CONTRACTS.FORM.CFE_PRICE_P' + (i + 1) | translate
                                    }}</mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('cfe', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                        <!-- ROM Variable -->
                        <div formArrayName="rom" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.rom.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label>{{
                                        'PAGE.DUMMY_CONTRACTS.FORM.ROM_PRICE_P' + (i + 1) | translate
                                    }}</mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('rom', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Tab 3">
                        <!-- ROS Variable -->
                        <div formArrayName="ros" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.ros.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label>{{
                                        'PAGE.DUMMY_CONTRACTS.FORM.ROS_PRICE_P' + (i + 1) | translate
                                    }}</mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('ros', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                        <!-- PC Variable -->
                        <div formArrayName="pc" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.pc.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label>{{
                                        'PAGE.DUMMY_CONTRACTS.FORM.PC_PRICE_P' + (i + 1) | translate
                                    }}</mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('pc', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                        <!-- GDOS Variable -->
                        <div formArrayName="gdos" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.gdos.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label
                                        >{{ 'PAGE.DUMMY_CONTRACTS.FORM.GDOS_PRICE_P' + (i + 1) | translate }}
                                    </mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('gdos', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                        <!-- FNSSE Variable -->
                        <div formArrayName="fnsse" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.fnsse.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label
                                        >{{ 'PAGE.DUMMY_CONTRACTS.FORM.FNSSE_PRICE_P' + (i + 1) | translate }}
                                    </mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('fnsse', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Tab 4">
                        <!-- PM Variable -->
                        <div formArrayName="pm" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.pm.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label>{{
                                        'PAGE.DUMMY_CONTRACTS.FORM.PM_PRICE_P' + (i + 1) | translate
                                    }}</mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('pm', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                        <!-- CBOS Variable -->
                        <div formArrayName="cbos" class="formControlArray_div">
                            <div
                                class="formControl_div"
                                *ngFor="let control of formGroupVariables.controls.cbos.controls; let i = index"
                            >
                                <mat-form-field class="input" appearance="standard">
                                    <mat-label
                                        >{{ 'PAGE.DUMMY_CONTRACTS.FORM.CBOS_PRICE_P' + (i + 1) | translate }}
                                    </mat-label>
                                    <input type="number" [formControl]="control" matInput />
                                </mat-form-field>
                                <span class="text-danger" *ngIf="formArrayHasErrors('cbos', i)">
                                    {{ 'PAGE.DUMMY_CONTRACTS.FORM.ERROR' | translate }}
                                </span>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div *ngIf="!checkUserRoles()">
            <h3 class="invalidRole">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
        </div>
    </div>
</div>
