<div class="container" *ngIf="dataBusiness">
    <div class="row row-column">
        <div class="table-header">
            <div class="table-header-info">
                <span
                    >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                    {{ totalRegisters | numberFormat : _headerTitleService.currentLang }}</span
                >
            </div>
            <div class="table-header-actions display_flex actions-heigh">
                <button mat-button (click)="openModalExcel()">
                    <span class="text">{{ 'PAGE.ACTIONS.EXCEL_EXPORT' | translate }}</span>
                    <span class="icon material-symbols-outlined">save_as </span>
                </button>
                <button mat-button color="primary" *ngIf="permissions.add">
                    <span (click)="add()" class="text">{{ 'PAGE.ACTIONS.ADD_REGISTER' | translate }}</span>
                    <span class="icon material-symbols-outlined"> add_circle </span>
                </button>
                <button class="buttonColumns" [ngClass]="ocultar === true ? 'mat' : 'mat_none'">
                    <!-- INICIO>>> Botón mostrar/ocultar columnas -->
                    <mat-form-field [hidden]="ocultar">
                        <mat-select
                            #selectColumns
                            name="selectColumns"
                            id="selectColumns"
                            [value]="dataHeaders_valueSelect"
                            multiple
                        >
                            <mat-option
                                enableCheckAll="true"
                                [matColumnDef]="column.id"
                                [value]="column"
                                (click)="checkvalue(selectColumns.value)"
                                *ngFor="let column of filterDataHeader()"
                                >{{ column.label }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                    <div class="divColumns" (click)="botonClick()">
                        <span class="text spanColumn" *ngIf="!element">{{ 'PAGE.ACTIONS.COLUMNS' | translate }}</span>
                        <app-base-icon class="padding_button" iconName="view_column"></app-base-icon>
                    </div>
                </button>
                <!-- FIN>>> Botón mostrar/ocultar columnas -->
            </div>
        </div>

        <div
            class="table-container"
            [ngClass]="{
                'acciones--1': permissions.edit == true || permissions.delete == true,
                'acciones--2': permissions.edit == true && permissions.delete
            }"
        >
            <!-- Table -->
            <table
                mat-table
                [dataSource]="dataBusiness"
                multiTemplateDataRows
                matSort
                (matSortChange)="sortData($event)"
            >
                <ng-container
                    style="max-width: fit-content"
                    [matColumnDef]="column.id"
                    *ngFor="let column of dataHeaders"
                >
                    <!-- Header TH -->
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                        <div class="header-table d-flex">
                            {{ column.label }}
                        </div>
                    </th>
                    <!-- Element TD -->
                    <td mat-cell *matCellDef="let element">
                        <!-- Element TD Text -->
                        <div *ngIf="column.type === 'text'">
                            <div *ngIf="column.id === 'initDateHeader'">
                                <span>{{ showTimeInit(element) }}</span>
                            </div>

                            <div *ngIf="column.id === 'endDateHeader'">
                                <span>{{ showTimeEnd(element) }}</span>
                            </div>

                            <div *ngIf="column.id === 'timeIni'">{{ horaIni[element['id']] }}</div>

                            <div *ngIf="column.id === 'timeFin'">{{ horaFin[element['id']] }}</div>

                            {{ element[column.id] }}
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
            </table>

            <mat-paginator
                (page)="pageChange($event)"
                [length]="10"
                [pageSize]="10"
                [pageSizeOptions]="[10, 25, 50, 100]"
                aria-label="Select page"
            >
            </mat-paginator>

            <table
                class="tableAccions"
                *ngIf="permissions.edit || permissions.delete"
                [ngClass]="{
                    'acciones--1': permissions.edit == true || (permissions.delete == true) == true,
                    'acciones--2': permissions.edit == true && permissions.delete
                }"
            >
                <!-- <table class="tableAccions " *ngIf="permissions.edit || permissions.delete || permissions.download" [ngClass]="{'acciones--1': permissions.edit == true || permissions.delete == true || permissions.download, 'acciones--2': (permissions.edit == true && permissions.delete) || (permissions.edit == true && permissions.download) || (permissions.delete == true && permissions.download), 'acciones--3': permissions.edit == true && permissions.delete && permissions.download}">  -->
                <thead *ngIf="permissions.edit || permissions.delete">
                    <th *ngIf="permissions.edit">
                        <div class="header-table d-flex">{{ 'PAGE.ACTIONS.EDIT' | translate }}</div>
                    </th>
                    <th *ngIf="permissions.delete">
                        <div class="header-table d-flex">{{ 'PAGE.ACTIONS.DELETE' | translate }}</div>
                    </th>
                </thead>
                <tbody role="rowgroup" *ngIf="permissions.edit || permissions.delete">
                    <!-- Element TD -->
                    <tr *ngFor="let column of dataBusiness; let i = index">
                        <td *ngIf="permissions.edit">
                            <span (click)="edit(i)" class="icon material-symbols-outlined">edit</span>
                        </td>
                        <td *ngIf="permissions.delete">
                            <span (click)="delete(i)" class="icon material-symbols-outlined">delete</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="container containerNoData" *ngIf="!dataBusiness">
    <div class="row flex_column">
        <h3>{{ 'PAGE.ACTIONS.NO_DATA' | translate }}</h3>
        <app-spinner></app-spinner>
    </div>
    <app-table-no-data></app-table-no-data>
</div>
