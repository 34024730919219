import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { SortingObject } from 'src/app/shared/models/files.interface';
import { PaginationObject } from 'src/app/shared/models/paginationObject.interface';
import { combineLatest } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoginService } from 'src/app/core/services/login.service';
import { PrevisionesService } from '../services/previsiones.service';
import { HeaderTitleService } from '../../../services/header-title.service';
import * as moment from 'moment';
@Component({
    selector: 'app-previsiones-agregado-page',
    templateUrl: './previsiones-agregado-page.component.html',
    styleUrls: ['./previsiones-agregado-page.component.scss'],
})
export class PrevisionesAgregadoPageComponent implements OnInit {
    dataBusiness: any;
    dataHeaders: IColumnsTable[];
    dataPagination: PaginationObject;
    dataSorting: SortingObject;
    columns: any;
    permissions: any;
    filters: any;
    idFilters: any;
    filtersTable: any[];
    idToCompare: string | [];

    totalRegisters: number;
    pageSize: number;
    totalPages: number;
    numPage: number;
    pageIndex: any;
    sortActive: any;
    sortDir: any;

    clients: any;
    sortIdFilter: any;
    sortFilter: any;
    sortDirection: any;
    sortParameter: any;
    sortId: any;
    headers: any;
    newTable: string[];
    tablaPivotada: [];
    unitProgramListNoFilter: [any];
    unitProgramList: [any];
    electricSystemList: any;
    constructor(
        private _previsionServices: PrevisionesService,
        private globalService: GlobalService,
        private _loginService: LoginService,
        private _headerTitleService: HeaderTitleService
    ) {}

    ngOnInit(): void {
        this._headerTitleService.currentHeaderTranslationCode = 'FORECAST_AGGREGATE';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
    }

    transformHeaders() {
        this.columns = this.dataHeaders.map((col) => col.id);
    }

    /**
     * Método para formatear el parámetro de busqueda
     * @param idFiltersEvent id del filtro y subfiltros
     * @returns
     */
    formatSearch(idFiltersEvent: any): string {
        let params: string = '';
        let accountsFiltered: boolean = false;
        let addParamFlag: boolean = false;
        if (idFiltersEvent[0]) {
            idFiltersEvent[1].forEach((filter: any, index: number) => {
                accountsFiltered = false;
                addParamFlag = false;

                if (filter.selected && filter.type === 'Date' && !addParamFlag) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    let splitedDates = idFiltersEvent[1][0].selected.split('~');
                    params = params.concat(`date>=${splitedDates[0]},date<=${splitedDates[1]}`);
                    addParamFlag = true;
                } else {
                    if (filter.selected && filter.type !== 'Date' && !addParamFlag) {
                        if (index !== 0 && params !== '') {
                            params = params.concat(',');
                        }
                        params = params.concat(`${filter.key}=${filter.selected}`);
                        addParamFlag = true;
                    }
                }
            });

            if (idFiltersEvent[2]) {
                params = params.concat(',');
                params = params.concat(`programUnitCode=;(${idFiltersEvent[2]})`);
                params = params.split(';')[0] + params.split(';')[1].replace(/,/g, ';');
                addParamFlag = true;
            }
        }
        return params;
    }

    private getInfoTabla(idFilters: any, filter: boolean, sortParameter?: string) {
        // agregadoPrevisiones
        this.permissions = this.globalService.transformPermissions(idFilters[0].name);
        this.idFilters = idFilters;
        this.headers = this._loginService.getHeaderSession();
        this._previsionServices.searchParam = this.formatSearch(idFilters);
        if (this.headers != null) {
            combineLatest([
                this._previsionServices.getTablaPrevisionesAgregado(
                    -1,
                    this.sortParameter,
                    this.sortDirection,
                    this.formatSearch(idFilters)
                ),
            ]).subscribe((res) => {
                const registrosAgrupados: any[] = [];
                res[0].data.forEach((registro: any) => {
                    if (
                        !registrosAgrupados.some((registroAgrupado) =>
                            registroAgrupado.find(
                                (reg: any) =>
                                    reg.programUnitCode === registro.programUnitCode && reg.date === registro.date
                            )
                        )
                    ) {
                        registrosAgrupados.push(
                            res[0].data.filter(
                                (filtrado: any) =>
                                    filtrado.programUnitCode === registro.programUnitCode &&
                                    filtrado.date === registro.date
                            )
                        );
                    }
                });
                const cabeceraNueva = new Set();
                for (let i = 0; i < registrosAgrupados.length; i++) {
                    registrosAgrupados.sort((a: { date: any }, b: { date: any }) => {
                        return a.date - b.date;
                    });
                }

                const tablaFiltrado = registrosAgrupados.map((registroAgrupado) => {
                    const registroUnificado = { ...registroAgrupado[0] };
                    registroAgrupado.forEach((registro: any) => {
                        cabeceraNueva.add(registro.hour);
                        registroUnificado[registro.hour] = {
                            energy: registro.energy,
                            type: registro.type,
                            hour: registro.hour,
                        };
                    });
                    return registroUnificado;
                });

                // Ordenamos el array tablaFiltrado por fechas
                const sortedArray = tablaFiltrado.sort(
                    (a, b) => moment(a.date, 'YYYY-MM-DD').unix() - moment(b.date, 'YYYY-MM-DD').unix()
                );
                this.buildTable(tablaFiltrado, JSON.parse(this.headers), 'previsiones_agregado');
            });
        }

        this.sortParameter = undefined;
    }

    clearTable($event: any) {
        this.dataBusiness = undefined;
        this.sortActive = undefined;
    }
    getInfoTablaByFilterSort(s: any) {}

    receiveIdFilters($event: any) {
        this.getInfoTablaByFilterSort($event);
    }

    receiveIdSelect(event: any) {
        let filter: boolean = false;
        for (let index = 0; index < event[1].length; index++) {
            if (event[1][index].selected) {
                filter = true;
            }
        }
        this.filtersTable = event;
        this.getInfoTabla(event, filter);

        // TOdo cambiar las peticiones aqui
    }

    reset() {}

    sort(event: any, id: string) {
        this.sortDir = event.direction;
        this.sortActive = event.active;
        this.sortDirection = event.sort.direction;
        this.sortParameter = event.sort.active;

        this.getInfoTabla(this.idFilters, this.sortFilter, this.sortParameter);
    }

    buildTable(res: any[], header: any, nameHeaders: string): void {
        if (environment.isMockActive) {
            this.dataBusiness = res[0].data;
            header.data.forEach((element: any) => {
                if (element.name === nameHeaders) {
                    this.dataHeaders = element.headers;
                }
            });
            this.dataHeaders = res[1].data[nameHeaders].headers;
            this.totalRegisters = res[1].totalRegisters;
        } else {
            this.dataBusiness = res;
            header.data.forEach((element: any) => {
                if (element.name === nameHeaders) {
                    this.dataHeaders = element.headers;
                }
            });
            this.totalRegisters = res.length;
        }
        this.transformHeaders();
    }
}
