export const Days: { [key: string]: string } = {
    SUN: 'PAGE.DAYS.SUN',
    MON: 'PAGE.DAYS.MON',
    TUE: 'PAGE.DAYS.TUE',
    WED: 'PAGE.DAYS.WED',
    THU: 'PAGE.DAYS.THU',
    FRI: 'PAGE.DAYS.FRI',
    SAT: 'PAGE.DAYS.SAT',
};
export const DaysCode: { [key: string]: string } = {
    DAY_7: 'SUN',
    DAY_1: 'MON',
    DAY_2: 'TUE',
    DAY_3: 'WED',
    DAY_4: 'THU',
    DAY_5: 'FRI',
    DAY_6: 'SAT',
};

export const MonthWeeks: { [key: string]: string } = {
    '#1': 'PAGE.ORDINALS.FIRST',
    '#2': 'PAGE.ORDINALS.SECOND',
    '#3': 'PAGE.ORDINALS.THIRD',
    '#4': 'PAGE.ORDINALS.FOURTH',
    '#5': 'PAGE.ORDINALS.FIFTH',
    'L': 'PAGE.ORDINALS.LAST',
};

export const Months: { [key: number]: string } = {
    1: 'PAGE.MONTHS.JAN',
    2: 'PAGE.MONTHS.FEB',
    3: 'PAGE.MONTHS.MAR',
    4: 'PAGE.MONTHS.APR',
    5: 'PAGE.MONTHS.MAY',
    6: 'PAGE.MONTHS.JUN',
    7: 'PAGE.MONTHS.JUL',
    8: 'PAGE.MONTHS.AUG',
    9: 'PAGE.MONTHS.SEP',
    10: 'PAGE.MONTHS.OCT',
    11: 'PAGE.MONTHS.NOV',
    12: 'PAGE.MONTHS.DEC',
};

export const MonthCardinals: { [key: string]: string } = {
    1: 'PAGE.ORDINAL_DAYS.FIRST',
    2: 'PAGE.ORDINAL_DAYS.SECOND',
    3: 'PAGE.ORDINAL_DAYS.THIRD',
    4: 'PAGE.ORDINAL_DAYS.FOURTH',
    5: 'PAGE.ORDINAL_DAYS.FIFTH',
    6: 'PAGE.ORDINAL_DAYS.SIXTH',
    7: 'PAGE.ORDINAL_DAYS.SEVENTH',
    8: 'PAGE.ORDINAL_DAYS.EIGHTH',
    9: 'PAGE.ORDINAL_DAYS.NINTH',
    10: 'PAGE.ORDINAL_DAYS.TENTH',
    11: 'PAGE.ORDINAL_DAYS.ELEVENTH',
    12: 'PAGE.ORDINAL_DAYS.TWELFTH',
    13: 'PAGE.ORDINAL_DAYS.THIRTEENTH',
    14: 'PAGE.ORDINAL_DAYS.FOURTEENTH',
    15: 'PAGE.ORDINAL_DAYS.FIFTEENTH',
    16: 'PAGE.ORDINAL_DAYS.SISTEENTH',
    17: 'PAGE.ORDINAL_DAYS.SEVENTEENTH',
    18: 'PAGE.ORDINAL_DAYS.EIGHTEENTH',
    19: 'PAGE.ORDINAL_DAYS.NINETEENTH',
    20: 'PAGE.ORDINAL_DAYS.TWENTIETH',
    21: 'PAGE.ORDINAL_DAYS.TWENTY_FIRST',
    22: 'PAGE.ORDINAL_DAYS.TWENTY_SECOND',
    23: 'PAGE.ORDINAL_DAYS.TWENTY_THIRD',
    24: 'PAGE.ORDINAL_DAYS.TWENTY_FOURTH',
    25: 'PAGE.ORDINAL_DAYS.TWENTY_FIFTH',
    26: 'PAGE.ORDINAL_DAYS.TWENTY_SIXTH',
    27: 'PAGE.ORDINAL_DAYS.TWENTY_SEVENTH',
    28: 'PAGE.ORDINAL_DAYS.TWENTY_EIGHTH',
    29: 'PAGE.ORDINAL_DAYS.TWENTY_NINTH',
    30: 'PAGE.ORDINAL_DAYS.THIRTIETH',
    31: 'PAGE.ORDINAL_DAYS.THIRTY_FIRST',
};
