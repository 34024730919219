import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { TipoEnvios } from '../../shared/enums/envios';

@Injectable({
    providedIn: 'root',
})
export class EnviosService {
    private auth_token = this._loginService.getTokenSession();
    private isPeninsularSystem = new Subject<boolean>();

    private headersFiles = new HttpHeaders({
        Accept: '*/*',
        Authorization: `Bearer ${this.auth_token}`,
    });

    private HttpUploadOptions = { headers: this.headersFiles };
    searchParam: any;

    constructor(private _http: HttpClient, private _loginService: LoginService) {}

    getTablaResultados(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search?: string,
        isExport: boolean = false
    ): Observable<any> {
        this.searchParam = search;
        let url = `${environment.apiUrl}/send/v0?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&search=${search}`;
        if (isExport) {
            url += '&isExcel=true';
        }
        return this._http.get<any[]>(url);
    }

    postFile(body: any) {
        if (body.type === TipoEnvios.OPTIONAL_SETTLEMENT) {
            body.type = 'optionalSettlement';
        }
        if (body.type === TipoEnvios.FORECAST) {
            body.type = 'forecast';
        }

        const url = `${environment.apiUrl}/send/v0`;
        return this._http.post<any>(url, body, this.HttpUploadOptions);
    }

    getUsers() {
        const url = `${environment.apiUrl}/user/v0?page=0`;
        return this._http.get<any[]>(url);
    }

    getUnidadPrograma(concept?: string) {
        let url = `${environment.apiUrl}/unitProgram/v0?page=0&sortDir=asc`;
        if (concept === TipoEnvios.OPTIONAL_SETTLEMENT || concept === 'OPTIONAL_SETTLEMENT') {
            url += '&search=optionalSettlement=true';
        }
        return this._http.get<any>(url);
    }

    downloadFile(route: string) {
        const ruta = `${environment.apiUrl}/send/v0/download?fileS3=${route}`;

        this._http.get(ruta, { responseType: 'blob' as 'json', observe: 'response' }).subscribe((response: any) => {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response.body);

            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

            downloadLink.setAttribute('download', response.url.slice(response.url.lastIndexOf('/') + 1));

            document.body.appendChild(downloadLink);
            downloadLink.click();
        });
    }

    getIfPeninsularSystem(): Observable<boolean> {
        return this.isPeninsularSystem.asObservable();
    }

    setIfPeninsularSystem(value: boolean): void {
        this.isPeninsularSystem.next(value);
    }
}
