import { Component, OnInit } from '@angular/core';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-ejecucion-informes-page',
    templateUrl: './ejecucion-informes-page.component.html',
    styleUrls: ['./ejecucion-informes-page.component.scss'],
})
export class EjecucionInformesPageComponent implements OnInit {
    isPageEnabled: boolean = true;

    constructor(private _headerTitleService: HeaderTitleService) {}

    ngOnInit(): void {
        this._headerTitleService.currentHeaderTranslationCode = 'REPORTS_EXECUTION';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
    }
}
