import { Component } from '@angular/core';
import { Auth } from 'aws-amplify';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
    returnHome() {
        Auth.signOut();
    }
}
