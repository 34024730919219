import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewChecked } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProcesosService } from 'src/app/pages/procesos/services/procesos.service';
import { HeaderTitleService } from 'src/app/services/header-title.service';

const DATE_FORMAT_SPANISH = `DD-MM-YYYY`;
const DATE_FORMAT_ENGLISH = `YYYY-MM-DD`;

@Component({
    selector: 'app-filtro-date-variable',
    templateUrl: './filtro-date-variable.component.html',
    styleUrls: ['./filtro-date-variable.component.scss'],
})
export class FiltroDateVariableComponent implements OnInit, AfterViewChecked, OnDestroy {
    @Input() showDay: boolean = true;
    @Input() isRequired: boolean = false;
    @Input() label: string;
    @Input() resetDate$: Observable<void>;
    @Input() changeDate$: Observable<void>;
    @Output() onChangeSelectedVariableDate = new EventEmitter<string>();

    protected readonly radioButtons: string[] = ['+', '-'];
    protected readonly yearsList: number[] = Array.from({ length: 11 }, (_v, i) => i++);
    protected readonly monthsList: number[] = Array.from({ length: 13 }, (_v, i) => i++);
    protected readonly daysList: number[] = Array.from({ length: 32 }, (_v, i) => i++);

    private resetDateSubscription: Subscription;
    private changeDateSubscription: Subscription;

    // Filter variables
    daySelectedNumber: number | undefined;
    monthSelectedNumber: number | undefined;
    yearSelectedNumber: number | undefined;
    daySelected: string | undefined;
    monthSelected: string | undefined;
    yearSelected: string | undefined;
    radioDaySelected: string | undefined;
    radioMonthSelected: string | undefined;
    radioYearSelected: string | undefined;
    variableDateJSON: {
        day: { operator: string | undefined; value: number | undefined };
        month: { operator: string | undefined; value: number | undefined };
        year: { operator: string | undefined; value: number | undefined };
    };

    currentLang: string;
    labelMessage: string; // ex. 'Fecha inicio medidas'
    labelTranslationKey: string; // ex. 'MEASURE_INIT_DATE'
    formattedDateString: string; // ex. '08-04-2023'
    variableDateString: string; // ex. '+14d-11m+7y'

    constructor(
        private readonly _procesosService: ProcesosService,
        private readonly _translateService: TranslateService,
        private readonly _headerTitleService: HeaderTitleService
    ) {}

    ngOnInit(): void {
        this.currentLang = this._headerTitleService.currentLang;
        this._setLabels();
        this._getVariableDateFromService();
        this._loadData();
        this._subscribeToChanges();
    }

    ngAfterViewChecked(): void {
        if (this.currentLang !== this._headerTitleService.currentLang) {
            this.currentLang = this._headerTitleService.currentLang;
            this._updateLabelsFromLanguageChange();
            this._updateFormattedDateToShow();
        }
    }

    ngOnDestroy(): void {
        this.resetDateSubscription.unsubscribe();
        this.changeDateSubscription.unsubscribe();
    }

    // Modifies label and labelMessage to add or remove asterisks
    private _setLabels(): void {
        this.label = `${this.label.replaceAll('*', '').replaceAll('  ', '').replaceAll(':', '').trim()}${
            this.isRequired ? ' *' : ''
        }`;
        this.labelMessage = this.label.replaceAll('*', '').trim();
    }

    private _getVariableDateFromService(): void {
        this._translateService.get('PAGE.FILTERS').subscribe((message: any) => {
            // Gets the date string in variable format from processes service
            switch (this.labelMessage) {
                case message['INIT_DATE_REDUCED']:
                    this.labelTranslationKey = 'INIT_DATE_REDUCED';
                    this.variableDateString = this._procesosService.selectedProcess?.initDate;
                    break;
                case message['END_DATE_REDUCED']:
                    this.labelTranslationKey = 'END_DATE_REDUCED';
                    this.variableDateString = this._procesosService.selectedProcess?.endDate;
                    break;
                case message['MEASURE_INIT_DATE']:
                    this.labelTranslationKey = 'MEASURE_INIT_DATE';
                    this.variableDateString = this._procesosService.selectedProcess?.measureInitDate;
                    break;
                case message['MEASURE_END_DATE']:
                    this.labelTranslationKey = 'MEASURE_END_DATE';
                    this.variableDateString = this._procesosService.selectedProcess?.measureEndDate;
                    break;
                case message['FORECAST_INIT_DATE']:
                    this.labelTranslationKey = 'FORECAST_INIT_DATE';
                    this.variableDateString = this._procesosService.selectedProcess?.forecastInitDate;
                    break;
                case message['FORECAST_END_DATE']:
                    this.labelTranslationKey = 'FORECAST_END_DATE';
                    this.variableDateString = this._procesosService.selectedProcess?.forecastEndDate;
                    break;
                case message['PROJECTED_INIT_DATE']:
                    this.labelTranslationKey = 'PROJECTED_INIT_DATE';
                    this.variableDateString = this._procesosService.selectedProcess?.projectedInitDate;
                    break;
                case message['PROJECTED_END_DATE']:
                    this.labelTranslationKey = 'PROJECTED_END_DATE';
                    this.variableDateString = this._procesosService.selectedProcess?.projectedEndDate;
                    break;
                case message['MONTH']:
                    this.labelTranslationKey = 'MONTH';
                    this.variableDateString = this._procesosService.selectedProcess?.date;
                    break;
                default:
                    break;
            }
            this.variableDateString = this.variableDateString ? this.variableDateString : '';
        });
    }

    private _loadData(): void {
        this.onChangeSelectedVariableDate.emit(this.variableDateString);
        this._formatVariableDateStringToJSON();
        this._updateSelectedValuesFromDateInJSON();
        this._updateFormattedDateToShow();
    }

    private _formatVariableDateStringToJSON(): void {
        // Gets monthsEndIndex and yearsEndIndex based on where the operator (+ or -) is found
        let monthsStartIndex: number = 0;
        let yearsStartIndex: number = 0;
        const daysEndIndex: number = this.variableDateString.indexOf('d');
        const monthsEndIndex: number = this.variableDateString.indexOf('m') - 1;
        const yearsEndIndex: number = this.variableDateString.indexOf('y') - 1;
        if (this.variableDateString.includes('m')) {
            monthsStartIndex = this.radioButtons.some(
                (op: string) => op === this.variableDateString.charAt(monthsEndIndex - 1)
            )
                ? monthsEndIndex - 1
                : monthsEndIndex - 2;
        }
        if (this.variableDateString.includes('y')) {
            yearsStartIndex = this.radioButtons.some(
                (op: string) => op === this.variableDateString.charAt(yearsEndIndex - 1)
            )
                ? yearsEndIndex - 1
                : yearsEndIndex - 2;
        }
        // Builds variableDateJSON with the five indexes variables defined before
        this.variableDateJSON = {
            day: { operator: undefined, value: undefined },
            month: { operator: undefined, value: undefined },
            year: { operator: undefined, value: undefined },
        };
        if (this.variableDateString.includes('d')) {
            this.variableDateJSON.day = {
                operator: this.variableDateString.includes('d') ? this.variableDateString.charAt(0) : undefined,
                value: this.variableDateString.includes('d')
                    ? Number.parseInt(this.variableDateString.slice(1, daysEndIndex))
                    : undefined,
            };
        }
        if (this.variableDateString.includes('m')) {
            this.variableDateJSON.month = {
                operator: this.variableDateString.includes('m')
                    ? this.variableDateString.charAt(monthsStartIndex)
                    : undefined,
                value: this.variableDateString.includes('m')
                    ? Number.parseInt(this.variableDateString.slice(monthsStartIndex + 1, monthsEndIndex + 1))
                    : undefined,
            };
        }
        if (this.variableDateString.includes('y')) {
            this.variableDateJSON.year = {
                operator: this.variableDateString.includes('y')
                    ? this.variableDateString.charAt(yearsStartIndex)
                    : undefined,
                value: this.variableDateString.includes('y')
                    ? Number.parseInt(this.variableDateString.slice(yearsStartIndex + 1, yearsEndIndex + 1))
                    : undefined,
            };
        }
    }

    private _updateSelectedValuesFromDateInJSON(): void {
        if (this.variableDateJSON) {
            this.radioDaySelected = this.variableDateJSON.day?.operator
                ? this.variableDateJSON.day.operator
                : this.radioDaySelected;
            this.daySelected = this.variableDateJSON.day?.value?.toString()
                ? this.variableDateJSON.day.value.toString()
                : this.daySelected;
            this.daySelectedNumber =
                this.daySelected && !isNaN(Number.parseInt(this.daySelected))
                    ? Number.parseInt(this.daySelected)
                    : this.daySelectedNumber;
            this.radioMonthSelected = this.variableDateJSON.month?.operator
                ? this.variableDateJSON.month.operator
                : this.radioMonthSelected;
            this.monthSelected = this.variableDateJSON.month?.value?.toString()
                ? this.variableDateJSON.month.value.toString()
                : this.monthSelected;
            this.monthSelectedNumber =
                this.monthSelected && !isNaN(Number.parseInt(this.monthSelected))
                    ? Number.parseInt(this.monthSelected)
                    : this.monthSelectedNumber;
            this.radioYearSelected = this.variableDateJSON.year?.operator
                ? this.variableDateJSON.year.operator
                : this.radioYearSelected;
            this.yearSelected = this.variableDateJSON.year?.value?.toString()
                ? this.variableDateJSON.year.value.toString()
                : this.yearSelected;
            this.yearSelectedNumber =
                this.yearSelected && !isNaN(Number.parseInt(this.yearSelected))
                    ? Number.parseInt(this.yearSelected)
                    : this.yearSelectedNumber;
        }
    }

    // Adds or subtracts time to date and formats it to be displayed
    private _updateFormattedDateToShow(): void {
        let date: Date = new Date();
        // To control cases when day of the month is above 28 to avoid adding/subtracting months/years errors
        const dayOfMonthCopy: number = date.getDate();
        dayOfMonthCopy > 28 ? date.setDate(28) : '';
        // Updates years
        if (this.variableDateJSON?.year?.value) {
            this.variableDateJSON?.year?.operator === '-'
                ? date.setFullYear(date.getFullYear() - this.variableDateJSON?.year?.value)
                : date.setFullYear(date.getFullYear() + this.variableDateJSON?.year?.value);
        }
        // Updates months
        if (this.variableDateJSON?.month?.value) {
            this.variableDateJSON?.month?.operator === '-'
                ? date.setMonth(date.getMonth() - this.variableDateJSON?.month?.value)
                : date.setMonth(date.getMonth() + this.variableDateJSON?.month?.value);
        }
        // Restores day of month value once years and months has been updated
        dayOfMonthCopy > 28 ? date.setDate(28) : '';
        // Updates days
        if (this.variableDateJSON?.day?.value) {
            this.variableDateJSON?.day?.operator === '-'
                ? date.setDate(date.getDate() - this.variableDateJSON?.day?.value)
                : date.setDate(date.getDate() + this.variableDateJSON?.day?.value);
        }
        // Formats date to show
        this.formattedDateString = moment(date).format(
            this.currentLang === 'en' ? DATE_FORMAT_ENGLISH : DATE_FORMAT_SPANISH
        );
        // Removes day from date to show when it's month-year only
        if (!this.showDay) {
            this.formattedDateString = this.formattedDateString.slice(
                this.currentLang === 'en' ? 0 : 3,
                this.currentLang === 'en' ? 7 : this.formattedDateString.length
            );
        }
    }

    private _updateLabelsFromLanguageChange(): void {
        this._translateService.get(`PAGE.FILTERS.${this.labelTranslationKey}`).subscribe((message: any) => {
            if (message) {
                this.label = `${message.replaceAll('*', '').replaceAll('  ', '').replaceAll(':', '').trim()}${
                    this.isRequired ? ' *' : ''
                }`;
                this.labelMessage = this.label.replaceAll('*', '').trim();
            }
        });
    }

    private _subscribeToChanges(): void {
        if (this.changeDate$) {
            this.changeDateSubscription = this.changeDate$.subscribe(() => {
                this._resetData();
            });
        }
        if (this.resetDate$) {
            this.resetDateSubscription = this.resetDate$.subscribe(() => {
                this._resetData();
            });
        }
    }

    private _resetData(): void {
        this.radioDaySelected = undefined;
        this.radioMonthSelected = undefined;
        this.radioYearSelected = undefined;
        this.daySelected = undefined;
        this.monthSelected = undefined;
        this.yearSelected = undefined;
        this.daySelectedNumber = undefined;
        this.monthSelectedNumber = undefined;
        this.yearSelectedNumber = undefined;
        this.labelMessage = '';
        this.variableDateString = '';
        this.formattedDateString = '';
        this.variableDateJSON = {
            day: { operator: undefined, value: undefined },
            month: { operator: undefined, value: undefined },
            year: { operator: undefined, value: undefined },
        };
        this._setLabels();
        this._getVariableDateFromService();
        this._loadData();
    }

    // Executes when filter is manually modified by the user
    // Sets the new value to its corresponding filter's variable, then updates variableDateString
    // with the new data and calls loadData()
    protected onClickValueChange(value: string | number, form: 'd' | 'm' | 'y'): void {
        value = value.toString();
        const isOperator: boolean = value === '-' || value === '+';
        switch (form) {
            case 'd':
                if (isOperator) {
                    this.radioDaySelected = value;
                } else {
                    this.daySelected = value;
                    this.daySelectedNumber = !isNaN(Number.parseInt(this.daySelected))
                        ? Number.parseInt(this.daySelected)
                        : this.daySelectedNumber;
                }
                break;
            case 'm':
                if (isOperator) {
                    this.radioMonthSelected = value;
                } else {
                    this.monthSelected = value;
                    this.monthSelectedNumber = !isNaN(Number.parseInt(this.monthSelected))
                        ? Number.parseInt(this.monthSelected)
                        : this.monthSelectedNumber;
                }
                break;
            case 'y':
                if (isOperator) {
                    this.radioYearSelected = value;
                } else {
                    this.yearSelected = value;
                    this.yearSelectedNumber = !isNaN(Number.parseInt(this.yearSelected))
                        ? Number.parseInt(this.yearSelected)
                        : this.yearSelectedNumber;
                }
                break;
            default:
                break;
        }
        // Builds the date string in variable format with the dates filter variables
        this.variableDateString = `${
            this.radioDaySelected && this.daySelected && this.daySelected !== '0'
                ? this.radioDaySelected + this.daySelected + 'd'
                : ''
        }${
            this.radioMonthSelected && this.monthSelected && this.monthSelected !== '0'
                ? this.radioMonthSelected + this.monthSelected + 'm'
                : ''
        }${
            this.radioYearSelected && this.yearSelected && this.yearSelected !== '0'
                ? this.radioYearSelected + this.yearSelected + 'y'
                : ''
        }`;
        this._loadData();
    }
}
