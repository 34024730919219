<div class="container" *ngIf="!isOnlyQueryRole">
    <!-- SECCIÓN DE FILTROS Y BOTONES -->
    <div class="main_container">
        <div class="selects_container">
            <div class="concept_filter">
                <!-- FILTRO DE CONCEPTOS -->
                <mat-form-field appearance="standard">
                    <mat-label class="concept">{{ 'PAGE.ACTIONS.CONCEPT' | translate }}</mat-label>
                    <mat-select
                        [formControl]="conceptsControl"
                        (selectionChange)="onChangeUpdateConcept($event)"
                        #matSelect
                        [matTooltip]="matSelect.triggerValue"
                    >
                        <mat-option *ngFor="let concept of filteredProcessConceptsList" [value]="concept">
                            {{ concept.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- FILTRO DE PROGRAMACIONES -->
            <div class="main_filter_container" *ngIf="matSelect.value && showSelectProgram">
                <app-filtro-select
                    #matSelectMain
                    *ngIf="mainFilter.type === 'Select'"
                    [filtroUrl]="mainFilter.data"
                    [title]="mainFilter.name | translate"
                    [key]="mainFilter.key"
                    [id]="mainFilter.id"
                    (valueChangeProcesosProgramar)="onSelectLoadProcessScheduleInfo($event)"
                    [disableSelect]="!matSelect.value"
                    (select)="onClickSelectScheduleOption($event)"
                    (openSelect)="onClickLoadOptions($event)"
                    [isSearchable]="true"
                    (deleteEvent)="deleteProcess($event)"
                >
                </app-filtro-select>
            </div>
            <!-- CREAR PROGRAMACIÓN -->
            <ng-container *ngIf="showInputProgram">
                <ng-container *ngIf="showCreateProgram">
                    <mat-form-field class="input_create_program">
                        <mat-label *ngIf="showCreateLabel">{{ 'PAGE.ACTIONS.NEW_TITLE' | translate }}</mat-label>
                        <mat-label *ngIf="!showCreateLabel">{{
                            'PAGE.FILTERS.SELECT.CREATE_PROGRAM' | translate
                        }}</mat-label>
                        <input
                            matInput
                            placeholder=""
                            [(ngModel)]="selectedProgram"
                            (change)="onChangeUpdateSelectedSchedule(inputCreate.value)"
                            #inputCreate
                        />
                    </mat-form-field>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedProgram && selectedProgram !== ''">
                <span class="span_label_program_selected">{{ 'PAGE.ACTIONS.PROGRAM_SELECTED' | translate }}</span
                ><span class="span_program_selected">{{ selectedProgram }}</span>
            </ng-container>
        </div>
        <!-- BOTONES DE PROGRAMACIONES -->
        <div class="buttons_container">
            <button
                *ngIf="showButtonProgram"
                mat-button
                mat-flat-button
                color="warn"
                class="primary"
                (click)="onClickCreateNewProcessSchedule()"
            >
                {{ 'PAGE.ACTIONS.NEW' | translate }}
            </button>
            <button
                *ngIf="showInputProgram"
                mat-button
                mat-flat-button
                color="warn"
                class="primary"
                (click)="onClickCancelCreateOrEditProcess()"
            >
                {{ 'PAGE.MODALS.CANCEL' | translate }}
            </button>
            <button
                *ngIf="mainFilter.data && selectedProgram !== '' && selectedProgram !== undefined && showInputProgram"
                mat-button
                mat-flat-button
                color="warn"
                class="primary"
                (click)="onClickOpenModal()"
                [disabled]="isDisabled"
            >
                {{ 'PAGE.ACTIONS.PROGRAM' | translate }}
            </button>
        </div>
    </div>
    <!-- SECCIÓN DE MOSTRADO DE FILTROS GENERALES -->
    <div class="schedule_info_container" *ngIf="!showCreateLabel && showInputProgram">
        <span class="span_schedule_title" translate> {{ 'PAGE.CRON.SCHEDULE_TITLE' | translate }} </span>
        <div class="schedule_info_sub_container">
            <ng-container *ngFor="let item of infoFilters">
                <div class="info_div">
                    <div class="infoLabel">{{ item.infoArray.label }}</div>
                    <div class="infoValue">{{ item.infoArray.value }}</div>
                </div>
                <mat-divider class="divider_info" vertical></mat-divider>
            </ng-container>
        </div>
    </div>
    <!-- FILTROS Y FECHAS RELATIVAS -->
    <div class="filters_container" [hidden]="!showInputProgram">
        <span class="filters_container_title" *ngIf="!showCreateLabel">{{
            'PAGE.CRON.EDIT_SCHEDULE_FIELDS' | translate
        }}</span>
        <div class="dates_container">
            <div *ngIf="!showSelect && selectedConcept?.processCode === concepts.ANALISIS_RESULTADOS && showDateFilter">
                <!-- Fecha Variable Inicial -->
                <app-filtro-date-variable
                    [resetDate$]="resetDate$"
                    [changeDate$]="changeDate$"
                    [showDay]="false"
                    [isRequired]="true"
                    [label]="'PAGE.FILTERS.SELECT.MONTH' | translate"
                    (onChangeSelectedVariableDate)="setInitialDate($event)"
                >
                </app-filtro-date-variable>
            </div>
            <div *ngIf="!showSelect && selectedConcept?.processCode === concepts.MEDIDAS_OBJECIONES && showDateFilter">
                <!-- Fecha Variable Inicial -->
                <app-filtro-date-variable
                    [resetDate$]="resetDate$"
                    [changeDate$]="changeDate$"
                    [showDay]="false"
                    [isRequired]="true"
                    [label]="'PAGE.FILTERS.SELECT.MONTH' | translate"
                    (onChangeSelectedVariableDate)="setInitialDate($event)"
                >
                </app-filtro-date-variable>
            </div>
            <ng-container
                *ngIf="
                    showSelect ||
                    (selectedConcept?.processCode !== concepts.MEDIDAS_OBJECIONES &&
                        selectedConcept?.processCode !== concepts.ANALISIS_RESULTADOS)
                "
            >
                <div class="variable-date--position">
                    <!-- Fecha Variable Inicial -->
                    <ng-container *ngIf="selectedConcept?.processCode !== concepts.PREVISIONES_INICIALES">
                        <ng-container
                            *ngIf="showSelect && selectedConcept?.processCode !== concepts.CALCULO_AGREGADO_PREVISIONES"
                        >
                            <app-filtro-date
                                (select)="onSelectChangeDateValue($event)"
                                [isPreloaded]="
                                    initDateRange && initDateRange !== '' && endDateRange && endDateRange !== ''
                                        ? true
                                        : false
                                "
                                [preloadedInitialDate]="initDateRange"
                                [preloadedEndDate]="endDateRange"
                                [name]="'PAGE.FILTERS.DATE_RANGE_REQUIRED' | translate"
                            ></app-filtro-date>
                        </ng-container>

                        <ng-container *ngIf="selectedConcept?.processCode === concepts.PREVISIONES_PROYECTADAS">
                            <ng-container *ngIf="!showSelect && showDateFilter">
                                <app-filtro-date-variable
                                    [resetDate$]="resetDate$"
                                    [changeDate$]="changeInitDate$"
                                    [isRequired]="isVariableInitialDateRequired"
                                    [label]="'PAGE.FILTERS.SELECT.MEASURE_INIT_DATE' | translate"
                                    (onChangeSelectedVariableDate)="setInitialDate($event)"
                                >
                                </app-filtro-date-variable>
                            </ng-container>
                            <mat-divider class="divider_dates" vertical></mat-divider>
                            <!-- Fecha Variable Fin -->
                            <ng-container *ngIf="!showSelect && showDateFilter">
                                <app-filtro-date-variable
                                    [resetDate$]="resetDate$"
                                    [changeDate$]="changeEndDate$"
                                    [isRequired]="isVariableEndDateRequired"
                                    [label]="'PAGE.FILTERS.SELECT.MEASURE_END_DATE' | translate"
                                    (onChangeSelectedVariableDate)="setEndDate($event)"
                                >
                                </app-filtro-date-variable>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="selectedConcept?.processCode === concepts.CIERRE_VIRTUAL">
                            <ng-container *ngIf="!showSelect && showDateFilter">
                                <app-filtro-date-variable
                                    [resetDate$]="resetDate$"
                                    [changeDate$]="changeInitDate$"
                                    [isRequired]="isVariableInitialDateRequired"
                                    [label]="'PAGE.FILTERS.SELECT.INIT_DATE_REQUIRED' | translate"
                                    (onChangeSelectedVariableDate)="setInitialDate($event)"
                                >
                                </app-filtro-date-variable>
                            </ng-container>
                            <mat-divider class="divider_dates" vertical></mat-divider>
                            <!-- Fecha Variable Fin -->
                            <ng-container *ngIf="!showSelect && showDateFilter">
                                <app-filtro-date-variable
                                    [resetDate$]="resetDate$"
                                    [changeDate$]="changeEndDate$"
                                    [isRequired]="isVariableEndDateRequired"
                                    [label]="'PAGE.FILTERS.SELECT.END_DATE' | translate"
                                    (onChangeSelectedVariableDate)="setEndDate($event)"
                                >
                                </app-filtro-date-variable>
                            </ng-container>
                        </ng-container>

                        <ng-container
                            *ngIf="selectedConcept?.processCode === concepts.MEDIDAS_HORARIAS_CUARTOS_HORARIAS"
                        >
                            <ng-container *ngIf="!showSelect && showDateFilter">
                                <app-filtro-date-variable
                                    [resetDate$]="resetDate$"
                                    [changeDate$]="changeInitDate$"
                                    [isRequired]="isVariableInitialDateRequired"
                                    [label]="'PAGE.FILTERS.SELECT.INIT_DATE_REQUIRED' | translate"
                                    (onChangeSelectedVariableDate)="setInitialDate($event)"
                                >
                                </app-filtro-date-variable>
                            </ng-container>
                            <mat-divider class="divider_dates" vertical></mat-divider>
                            <!-- Fecha Variable Fin -->
                            <ng-container *ngIf="!showSelect && showDateFilter">
                                <app-filtro-date-variable
                                    [resetDate$]="resetDate$"
                                    [changeDate$]="changeEndDate$"
                                    [isRequired]="isVariableEndDateRequired"
                                    [label]="'PAGE.FILTERS.SELECT.END_DATE' | translate"
                                    (onChangeSelectedVariableDate)="setEndDate($event)"
                                >
                                </app-filtro-date-variable>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="selectedConcept?.processCode === concepts.LIQUIDACION_POTESTATIVA">
                            <ng-container *ngIf="!showSelect && showDateFilter">
                                <app-filtro-date-variable
                                    [resetDate$]="resetDate$"
                                    [changeDate$]="changeInitDate$"
                                    [isRequired]="isVariableInitialDateRequired"
                                    [label]="'PAGE.FILTERS.SELECT.INIT_DATE' | translate"
                                    (onChangeSelectedVariableDate)="setInitialDate($event)"
                                >
                                </app-filtro-date-variable>
                            </ng-container>
                            <mat-divider class="divider_dates" vertical></mat-divider>
                            <!-- Fecha Variable Fin -->
                            <ng-container *ngIf="!showSelect && showDateFilter">
                                <app-filtro-date-variable
                                    [resetDate$]="resetDate$"
                                    [changeDate$]="changeEndDate$"
                                    [isRequired]="isVariableEndDateRequired"
                                    [label]="'PAGE.FILTERS.SELECT.END_DATE' | translate"
                                    (onChangeSelectedVariableDate)="setEndDate($event)"
                                >
                                </app-filtro-date-variable>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="selectedConcept?.processCode === concepts.ENVIO_METEOROLOGICA">
                            <ng-container *ngIf="!showSelect && showDateFilter">
                                <app-filtro-date-variable
                                    [resetDate$]="resetDate$"
                                    [changeDate$]="changeInitDate$"
                                    [isRequired]="isVariableInitialDateRequired"
                                    [label]="'PAGE.FILTERS.SELECT.INIT_DATE' | translate"
                                    (onChangeSelectedVariableDate)="setInitialDate($event)"
                                >
                                </app-filtro-date-variable>
                            </ng-container>
                            <mat-divider class="divider_dates" vertical></mat-divider>
                            <!-- Fecha Variable Fin -->
                            <ng-container *ngIf="!showSelect && showDateFilter">
                                <app-filtro-date-variable
                                    [resetDate$]="resetDate$"
                                    [changeDate$]="changeEndDate$"
                                    [isRequired]="isVariableEndDateRequired"
                                    [label]="'PAGE.FILTERS.SELECT.END_DATE' | translate"
                                    (onChangeSelectedVariableDate)="setEndDate($event)"
                                >
                                </app-filtro-date-variable>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="selectedConcept?.processCode === concepts.CALCULO_AGREGADO_PREVISIONES">
                            <app-filtro-date-variable
                                [resetDate$]="resetDate$"
                                [changeDate$]="changeInitDate$"
                                [isRequired]="isVariableInitialDateRequired"
                                [label]="'PAGE.FILTERS.SELECT.INIT_DATE' | translate"
                                (onChangeSelectedVariableDate)="setInitialDate($event)"
                            >
                            </app-filtro-date-variable>
                            <mat-divider class="divider_dates" vertical></mat-divider>
                            <app-filtro-date-variable
                                [resetDate$]="resetDate$"
                                [changeDate$]="changeEndDate$"
                                [isRequired]="isVariableEndDateRequired"
                                [label]="'PAGE.FILTERS.SELECT.END_DATE' | translate"
                                (onChangeSelectedVariableDate)="setEndDate($event)"
                            >
                            </app-filtro-date-variable>
                        </ng-container>
                    </ng-container>
                </div>
                <div
                    class="variable-date--position"
                    *ngIf="selectedConcept?.processCode === concepts.PREVISIONES_PROYECTADAS && showDateFilter"
                >
                    <!-- Fecha Init Variable Proyectada -->
                    <app-filtro-date-variable
                        [resetDate$]="resetDate$"
                        [changeDate$]="changeInitDateProjected$"
                        [isRequired]="true"
                        [label]="'PAGE.FILTERS.SELECT.FORECAST_INIT_DATE' | translate"
                        (onChangeSelectedVariableDate)="setProjectedInitDate($event)"
                    >
                    </app-filtro-date-variable>
                    <mat-divider class="divider_dates" vertical></mat-divider>
                    <!--   Fecha End Variable Proyectada -->
                    <app-filtro-date-variable
                        [resetDate$]="resetDate$"
                        [changeDate$]="changeEndDateProjected$"
                        [isRequired]="true"
                        [label]="'PAGE.FILTERS.SELECT.FORECAST_END_DATE' | translate"
                        (onChangeSelectedVariableDate)="setProjectedEndDate($event)"
                    >
                    </app-filtro-date-variable>
                </div>
            </ng-container>
        </div>
        <ng-container
            *ngIf="
                selectedConcept?.processCode === concepts.CIERRE_VIRTUAL ||
                selectedConcept?.processCode === concepts.MEDIDAS_HORARIAS_CUARTOS_HORARIAS ||
                selectedConcept?.processCode === concepts.MEDIDAS_OBJECIONES ||
                selectedConcept?.processCode === concepts.ANALISIS_RESULTADOS
            "
        >
            <div class="variable_or_fixed_date">
                <mat-label>{{ 'PAGE.FILTERS.SELECT.VARIABLE_DATE' | translate }}</mat-label>
                <mat-slide-toggle (toggleChange)="onToggleChangeSelectRangeDatesVisibility()" [(ngModel)]="showSelect">
                </mat-slide-toggle>
                <mat-label>{{ 'PAGE.FILTERS.SELECT.FIXED_DATE' | translate }}</mat-label>
            </div>
        </ng-container>
        <div class="container_subfilters">
            <div class="subfilter">
                <div *ngFor="let filtro of subfilters; let i = index">
                    <ng-container
                        *ngIf="
                            filtro.key === 'electricSystem' ||
                            filtro.key === 'unitProgram' ||
                            filtro.key === 'programUnit' ||
                            filtro.key === 'rate' ||
                            filtro.key === 'clientType' ||
                            filtro.key === 'closeType' ||
                            filtro.key === 'resultados' ||
                            filtro.key === 'results' ||
                            filtro.key === 'defaultExecution' ||
                            filtro.key === 'deleteModified' ||
                            filtro.key === 'liquidationType'
                        "
                    >
                        <!-- ELECTRIC SYSTEM -->
                        <mat-form-field
                            *ngIf="filtro.key === 'electricSystem'"
                            appearance="standard"
                            class="select"
                            name="electricSystemSelector"
                        >
                            <mat-label> {{ filtro.name }} </mat-label>
                            <mat-select
                                [formControl]="electricSystemForm"
                                (valueChange)="_getOption($event, filtro.key)"
                                placeholder="{{ filtro.name }}"
                            >
                                <mat-option *ngFor="let option of filtro.data" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- UNIT PROGRAMS -->
                        <mat-form-field
                            *ngIf="filtro.key === 'unitProgram' || filtro.key === 'programUnit'"
                            appearance="standard"
                            class="select"
                            name="unitProgramSelector"
                        >
                            <mat-label> {{ filtro.name }} </mat-label>
                            <mat-select
                                [formControl]="programUnitForm"
                                (valueChange)="_getOption($event, filtro.key)"
                                [multiple]="true"
                                placeholder="{{ filtro.name }}"
                            >
                                <mat-option *ngFor="let option of filtro.data" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- RATE -->
                        <mat-form-field
                            *ngIf="filtro.key === 'rate'"
                            appearance="standard"
                            class="select"
                            name="rateSelector"
                        >
                            <mat-label> {{ filtro.name }} </mat-label>
                            <mat-select
                                [formControl]="rateForm"
                                (valueChange)="_getOption($event, filtro.key)"
                                [multiple]="true"
                                placeholder="{{ filtro.name }}"
                            >
                                <mat-option *ngFor="let option of filtro.data" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- CLIENT TYPE -->
                        <mat-form-field
                            *ngIf="filtro.key === 'clientType'"
                            appearance="standard"
                            class="select"
                            name="clientTypeSelector"
                        >
                            <mat-label> {{ filtro.name }} </mat-label>
                            <mat-select
                                [formControl]="clientTypeForm"
                                (valueChange)="_getOption($event, filtro.key)"
                                placeholder="{{ filtro.name }}"
                            >
                                <mat-option *ngFor="let option of filtro.data" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- CLOSE TYPE -->
                        <mat-form-field
                            *ngIf="filtro.key === 'closeType'"
                            appearance="standard"
                            class="select"
                            name="closeTypeSelector"
                        >
                            <mat-label> {{ filtro.name }} </mat-label>
                            <mat-select
                                [formControl]="closeTypeForm"
                                (valueChange)="_getOption($event, filtro.key)"
                                placeholder="{{ filtro.name }}"
                            >
                                <mat-option *ngFor="let option of filtro.data" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- LIQUIDATION TYPE -->
                        <mat-form-field
                            *ngIf="filtro.key === 'liquidationType'"
                            appearance="standard"
                            class="select"
                            name="liquidationTypeSelector"
                        >
                            <mat-label> {{ filtro.name }} </mat-label>
                            <mat-select
                                [formControl]="liquidationTypeForm"
                                (valueChange)="_getOption($event, filtro.key)"
                                placeholder="{{ filtro.name }}"
                            >
                                <mat-option *ngFor="let option of filtro.data" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- RESULTS -->
                        <mat-form-field
                            *ngIf="filtro.key === 'resultados'"
                            appearance="standard"
                            class="select"
                            name="resultsSelector"
                        >
                            <mat-label> {{ filtro.name }} </mat-label>
                            <mat-select
                                [formControl]="resultsForm"
                                (valueChange)="_getOption($event, filtro.key)"
                                placeholder="{{ filtro.name }}"
                            >
                                <mat-option *ngFor="let option of filtro.data" [value]="option">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <ng-container *ngIf="filtro.type === 'toggle' && filtro.key === 'defaultExecution'">
                            <mat-slide-toggle [checked]="filtro.toggleState" (click)="onToggleChange(filtro.key)">
                            </mat-slide-toggle>
                            <mat-label>{{ 'PAGE.FILTERS.SELECT.DEFAULT_EXECUTION' | translate }}</mat-label>
                        </ng-container>
                        <div *ngIf="filtro.type === 'toggle' && filtro.key === 'deleteModified'">
                            <mat-slide-toggle [checked]="filtro.toggleState" (click)="onToggleChange(filtro.key)">
                            </mat-slide-toggle>
                            <mat-label>{{ 'PAGE.FILTERS.SELECT.DELETE_REGISTERS' | translate }}</mat-label>
                        </div>
                    </ng-container>
                    <app-filtro-select
                        *ngIf="
                            filtro.type === 'Select' &&
                            (filtro.key === 'accountId' ||
                                filtro.key === 'contract.idNumber' ||
                                filtro.key === 'society')
                        "
                        [toggleLabelLeft]="filtro.isToggle?.leftLabel"
                        [toggleLabelRight]="filtro.isToggle?.rightLabel"
                        [charactersControl]="filtro.isSearchable?.specialSearch"
                        [searchByToggle]="filtro.isToggle?.active"
                        [isSearchable]="filtro.isSearchable?.active"
                        [filtroUrl]="filtro.data"
                        [title]="filtro.name"
                        [key]="filtro.key"
                        [id]="filtro.id"
                        (valueChange)="onChangeSetOptionSelected($event, selects.value)"
                        (select)="onSelectRestartValue($event, i, filtro.key, filtro.type)"
                        (openSelect)="onClickLoadOptions($event)"
                        (specialSearch)="onKeyPressPerformSpecialSearch($event)"
                        [disableSelect]="
                            (maestroService.validCups !== undefined && isInitialForecastsSelected) ||
                            (maestroService.validCups !== undefined && previsionesProyectadasSelected)
                        "
                        #selects
                    >
                    </app-filtro-select>
                    <app-filtro-cups
                        *ngIf="filtro.type === 'cups'"
                        [filtroUrl]="filtro.data"
                        (select)="onSelectRestartValue($event, i, filtro.key, filtro.type)"
                        (resetComplete)="onCompleteResetCups($event)"
                        [name]="filtro.name"
                        [reset]="resetCups"
                        #filterCups
                    ></app-filtro-cups>
                </div>
            </div>
        </div>
    </div>
    <div class="cron_container" *ngIf="showInputProgram">
        <!-- PATRÓN DE REPETICIONES -->
        <div class="repeating_pattern">
            <span class="cron_repeating_pattern_title">{{ 'PAGE.CRON.REPEATING_PATTERN_TITLE' | translate }}</span>
            <app-custom-cron
                [reset$]="reset$"
                #cronEditorDemo
                color="warn"
                [formControl]="cronForm"
                [options]="cronOptions"
                (tabClick)="onClickSetActiveTab($event)"
                (onValueChange)="onCRONValueChange($event)"
                (isCRONIncorrect)="checkCRON($event)"
            ></app-custom-cron>
        </div>
        <!-- RANGO DE REPETICIONES -->
        <div class="range_repeats">
            <span class="cron_range_repeats_title">{{ 'PAGE.CRON.RANGE_REPEATS_TITLE' | translate }}</span>
            <div class="date_init_range_repeats">
                <span>{{ 'PAGE.CRON.DATE_INIT' | translate }}</span>
                <mat-form-field appearance="fill">
                    <input
                        #repRangeInitDateInputRef
                        matInput
                        (dateInput)="getInitDateValue($event)"
                        [matDatepicker]="picker"
                        [(ngModel)]="repRangeInitDate"
                    />
                    <mat-hint>YYYY/MM/DD</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="range_repeats_box">
                <mat-radio-group class="mat_radio_group" [(ngModel)]="radioButtonSelected">
                    <mat-radio-button *ngFor="let radio of radioButtons" [value]="radio" color="warn">
                        {{ radio }}
                        <ng-container *ngIf="radio === radioButtonsCron.option3">
                            <mat-form-field appearance="fill">
                                <input
                                    #repRangeEndDateInputRef
                                    matInput
                                    (dateInput)="getEndDateValue($event)"
                                    [matDatepicker]="picker"
                                    [(ngModel)]="repRangeEndDate"
                                />
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </ng-container>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isOnlyQueryRole">
    <h3 class="invalidRole">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
</div>
