import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { MaestroService } from 'src/app/pages/maestro/services/maestro.service';
import { GlobalService } from 'src/app/services/global.service';
import { FiltroSelectComponent } from 'src/app/shared/components/filtros/filtro-select/filtro-select.component';
import { KeysMaestro, MaestrosCRM } from 'src/app/shared/enums/maestros';
import { Concepto } from 'src/app/shared/models/concepto.interface';
import { ListaFiltros, Filtro, FiltroSelect } from 'src/app/shared/models/filtros.interface';
import { ProcesosService } from '../../services/procesos.service';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-select-procesos-res',
    templateUrl: './select-procesos-res.component.html',
    styleUrls: ['./select-procesos-res.component.scss'],
})
export class SelectProcesosResComponent implements OnInit {
    @Output() OptionSelectedId = new EventEmitter<string>();
    @Output() OptionFilters = new EventEmitter<any>();
    @Output() changeSelectReset = new EventEmitter<any>();
    @Output() filtersList = new EventEmitter<any>();

    @Output() resetSearch = new EventEmitter<any>();

    @Output() systemSelect = new EventEmitter<any>();
    @Input() tipoConcepto: string;
    subfilters: Filtro[];

    processConceptsList: any;

    displayedSubfilters: Filtro[] = [];
    selectSubfilter: FiltroSelect[];
    conceptosProcesosResultados: Concepto[] = [];
    filters: ListaFiltros[] = [];
    disableSelect = new UntypedFormControl(false);
    subcriptions: Subscription[] = [];
    tablaSeleccionada: string | undefined;
    selectId: any;
    isDisabled: boolean = true;
    inputValues: { id: string; value: string }[] = [];
    resetSelectedOption: any;
    resetSubfilter: any;
    showinput: any;
    showSelect = true;
    intermedio = true;
    conceptosProcesos: any;
    conceptFilterByRolMedida: any;
    conceptFilterByRolPrevisiones: any;
    dataBusinessMedidas: any[];
    dataBusinessPrevisiones: any[];
    dataBusinessSelectSistema: any[];
    cargas_rol: any[];
    nameSistems: any;
    dataSource: any[];
    archivos: any[];
    filesName: any[];
    filesName2: any[];
    disabled = false;
    userSystem = { username: 'SYSTEM', name: 'SYSTEM' };
    selectMedidas = false;
    selectPrevisiones = false;
    selectAdmin = false;
    rolAdmin: boolean | undefined = false;
    rolPrevision: boolean | undefined = false;
    rolMedida: boolean | undefined = false;
    rolConciliation: boolean | undefined = false;
    rolQuery: boolean | undefined = false;
    selectedSystem: any;
    userLog: any;
    userLogRoles: any;

    @ViewChildren('processType') processType: QueryList<FiltroSelectComponent>;

    @Output() resetTable = new EventEmitter<boolean>();

    selectedOption: any = { id: 'resultados', name: 'Resultados', filtrosCategory: 'procesos_resultados' };

    selected2 = this.conceptosProcesosResultados[0];

    // booleano para controlar si filtrar los filtros por ejemplo por codigo o por descripcion
    searchBy: boolean = false;
    // booleano para saber y controlar si el select de Cuenta Negociadora en CONTRATOS está vacio para controlar el disabled del toggle.
    disableToggle: boolean = true;

    usersList: any; //Aqui se almacena TODA la lista de usuarios
    processConceptsMedida: any;
    processConceptsPrevisiones: any;
    processConceptsConciliation: any;
    dateRequired: boolean = false;
    processRequired: boolean = false;

    disableProcessTypeSelect: boolean;

    constructor(
        protected readonly _headerTitleService: HeaderTitleService,
        private procesosService: ProcesosService,
        private globalService: GlobalService,
        private maestroService: MaestroService,
        private _loginService: LoginService
    ) {}

    ngOnInit(): void {
        this.getFilters(this.searchBy);
        this.getAllConcepts();
        this.getConceptos();
        this.globalService.getUsers().subscribe((users) => {
            this.rolAdmin = this._loginService.getUserActiveRoles()?.includes('ADMIN');
            this.rolPrevision = this._loginService.getUserActiveRoles()?.includes('PREVISION');
            this.rolMedida = this._loginService.getUserActiveRoles()?.includes('MEDIDA');
            this.rolConciliation = this._loginService.getUserActiveRoles()?.includes('CONCILIACION');
            this.rolQuery = this._loginService.getUserActiveRoles()?.includes('CONSULTA');
            this.usersList = [];
            this.usersList = users.data;
            this.usersList.push(this.userSystem);
        });
        this.procesosService.getProcessConcepts().subscribe((processConcepts) => {
            this.processConceptsList = [];
            this.conceptFilterByRolMedida = [];
            this.processConceptsPrevisiones = [];
            this.processConceptsMedida = [];
            this.processConceptsConciliation = [];

            processConcepts.data.forEach((concept: any) => {
                this.processConceptsList.push(concept);
                if (concept.area === 'Previsiones') {
                    concept.name = this.formatValues(concept.name);
                    this.processConceptsPrevisiones.push(concept);
                }
                if (concept.area === 'Medidas') {
                    concept.name = this.formatValues(concept.name);
                    this.processConceptsMedida.push(concept);
                }
                if (concept.area === 'Conciliacion') {
                    concept.name = this.formatValues(concept.name);
                    this.processConceptsConciliation.push(concept);
                }
            });
        });

        this.archivos = [];
        this.filesName = [];
        this.filesName2 = [];
        this.disableProcessTypeSelect = true;

        this.globalService.getFilters().subscribe((filtros) => {
            let filtr = filtros.data.findIndex((filter: any) => filter.name === 'procesos_resultados');
            this.subfilters = filtros.data[filtr].filters;
            for (let i = 0; i < this.subfilters.length; i++) {
                if (this.subfilters[i].required) {
                    this.subfilters[i].name = this.subfilters[i].name + ' *';
                }
            }
        });
        this.obtainSubfilters('');
    }

    ngOnDestroy(): void {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this._headerTitleService.measureInitDate = '';
        this._headerTitleService.measureEndDate = '';
        this.subcriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    formatValues(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }

    // importamos el servicio que llama al json
    getConceptos() {
        this.conceptFilterByRolMedida = [];

        if (this.tipoConcepto === 'procesos-resultados') {
            this.procesosService.getConceptsProcesosResultados().subscribe(
                (res) => {
                    this.conceptosProcesosResultados = res;
                },
                (error: any) => {
                    console.log(error);
                }
            );
        } else {
        }
    }
    getAllConcepts() {
        this.procesosService.getConceptsProcesosLanzar().subscribe((res) => {
            this.conceptosProcesos = res;
        });
    }

    getFilters(filterByBoolean: boolean) {
        this.subcriptions.push(this.globalService.getFilters().subscribe((filtros) => (this.filters = filtros.data)));
    }

    obtainSubfilters(selectedOption: any) {
        for (let i = 0; i < this.subfilters?.length; i++) {
            if (this.subfilters[i].required) {
                this.subfilters[i].name = this.subfilters[i].name + ' *';
            }
        }
    }

    obtainSelectSubfiltros() {
        this.subfilters
            ?.filter((f) => {
                f.type === 'Select';
            })
            .forEach((s) => s.url);
    }

    loadSelect(select: any) {
        //hay q recorrer el selec.urr y pusearlo dntro del sleect.hmtl
    }

    obtainIdSelect(selectedOption: any) {
        for (let i = 0; i < this.subfilters!.length; i++) {
            if (this.subfilters![i].selected != undefined || this.subfilters != null) {
                this.subfilters![i].selected = '';
            }
        }
        this.resetSubfilter = selectedOption;
        this.selectId = selectedOption.value.id;
        this.isDisableButtonRequired();
        this.showinput = undefined;
    }
    getFiltersBy(searchBy: [boolean, string]) {
        let subfilter = this.subfilters?.find((filter: any) => filter.key === searchBy[1]);
        if (subfilter) {
            subfilter.toggleState = searchBy[0];
        }
    }

    isDisableButtonRequired(): boolean {
        let countRequired = 0;
        let countSelected = 0;

        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element) => {
                if (element.required) {
                    countRequired++;
                    if (element.selected !== undefined && element.selected !== '') {
                        countSelected++;
                    }
                }
            });
        }
        if (countRequired == 0) {
            this.isDisabled = false;
        } else {
            if (countRequired === countSelected) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        }
        return this.isDisabled;
    }

    loadTable() {
        let selectFilter: any = [this.selectId, this.subfilters, this.selectedSystem];

        if (this.subfilters !== undefined) {
            this.OptionSelectedId.emit(selectFilter);
        } else {
            this.OptionSelectedId.emit(this.selectId);
        }
    }

    obtainOption(event: { id: string; value: string }) {
        if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }

        if (event.value.split('=')[0] === 'Sistema') {
            this.disableProcessTypeSelect = false;
        }
    }

    checkConditions(filtro: string[] | undefined): boolean {
        if (this.showinput === false) {
            return true;
        }
        if (!filtro || !filtro.length) {
            return true;
        }
        let solution = false;
        filtro.forEach((cond) => {
            if (this.inputValues.some((inpVal) => inpVal.value === cond)) {
                solution = true;
            }
        });
        return solution;
    }

    receiveDate($event: any, filtro: any, reset?: boolean) {
        if (this.subfilters !== undefined) {
            this.subfilters[filtro].selected = $event;
        }
        this.isDisableButtonRequired();
    }

    restarValue(event: any, filtro: any, key: string, filtroType?: any) {
        this.formatSelectedValue(event, filtro, key, filtroType);
        if (this.subfilters !== undefined && event.newValue) {
            this.subfilters[filtro].selected = event.newValue;

            if (this.selectId === MaestrosCRM.CONTRATOS) {
                this.isDisableButtonRequired();
            }
        } else if (!event.newValue && this.selectId === MaestrosCRM.CONTRATOS) {
            this.isDisableButtonRequired();
        }
        this.intermedio = true;

        if (this.subfilters[0].selected != '' && this.subfilters[0].selected != undefined) {
            this.dateRequired = true;
        }
        if (this.subfilters[3].selected != '' && this.subfilters[3].selected != undefined) {
            this.processRequired = true;
        }
        if (this.dateRequired && this.processRequired) {
            this.isDisabled = false;
        }
    }

    resetProcess(event: any) {
        this.disableProcessTypeSelect = false;

        let indexFiltroProcesos = this.subfilters.findIndex((filter) => filter.key === 'type');
        this.subfilters[indexFiltroProcesos].data = [];
        this.subfilters[indexFiltroProcesos].selected = '';

        this.systemSelected(event);
        this.isDisableButtonRequired();
    }

    hideSelect() {
        this.showSelect = false;
    }

    reset() {
        if (this.subfilters != undefined) {
            this.subfilters.forEach(function (e) {
                delete e['selected'];
            });
        }
    }

    systemSelected(valueSelectSistemas: string) {
        if (this.selectedSystem !== valueSelectSistemas) {
            this.selectedSystem = valueSelectSistemas;
            this.dataSource = [];
            this.processConceptsList = [];
            if (valueSelectSistemas === 'Medidas' && (this.rolAdmin || this.rolMedida || this.rolQuery)) {
                this.dataSource = this.processConceptsMedida;
                this.disableProcessTypeSelect = false;
            }
            if (valueSelectSistemas === 'Previsiones' && (this.rolAdmin || this.rolPrevision || this.rolQuery)) {
                this.dataSource = this.processConceptsPrevisiones;
                this.disableProcessTypeSelect = false;
            }
            if (valueSelectSistemas === 'Conciliacion' && (this.rolAdmin || this.rolConciliation || this.rolQuery)) {
                this.dataSource = this.processConceptsConciliation;
                this.disableProcessTypeSelect = false;
            }
            this.processConceptsList = this.dataSource;
            let indexFiltroProcesos = this.subfilters.findIndex((filter) => filter.key === 'type');
            this.subfilters[indexFiltroProcesos].selected = '';
            this.processType.first.resetSelect();
            this.processType.first.bankCtrl.reset();
            this.processType.last.resetSelect();
            this.processType.last.bankCtrl.reset();

            this.isDisableButtonRequired();
        }
    }

    /**
     * Este evento tiene 2 usos:
     * Si selectId === Contratos -> Método que comprueba los select de Tarifas y Zona para deshabilitar las opciones de Zona según hemos seleccionado la tarifa especial de 2.0TD o no.
     * Si selectId === Contratos -> Método que comprueba si hay algo seleccionado
     * @param selectedOption Valor seleccionado que viene emitido del select
     */
    checkValueSelects(selectedOption: any) {
        if (this.selectId === 'resultados') {
            this.getFilters(this.procesosService.searchBy);
        } else {
            this.procesosService.showColumnsBy = '';
        }
    }

    /**
     *
     * @param event Dato seleccionado
     * @param filtro El número del select de filtros
     * @param key Key del filtro
     */
    formatSelectedValue(event: any, filtro: any, key: string, filtroType: string) {
        if (filtroType === 'Date') {
            this.subfilters[filtro].selected = event.newValue;
        } else {
            let filtroFormateado = event.split('=');
            this.subfilters[filtro].selected = filtroFormateado[1];
        }
    }

    resetFilters() {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.showSelect = true;
        this.resetTable.emit(true);
        this.processRequired = false;
        this.dateRequired = false;
        this.isDisabled = true;

        // reset filters
        if (this.subfilters) {
            this.subfilters.forEach((d: any) => {
                d.selected = undefined;
            });
        }

        this.selectedSystem = undefined;
        this.processConceptsList = [];

        // rellenamos otra vez los filtros
        this.subfilters = [];
        this.globalService.getFilters().subscribe((filtros) => {
            let filtr = filtros.data.findIndex((filter: any) => filter.name === 'procesos_resultados');
            this.subfilters = filtros.data[filtr].filters;
            for (let i = 0; i < this.subfilters.length; i++) {
                if (this.subfilters[i].required) {
                    this.subfilters[i].name = this.subfilters[i].name + ' *';
                }
            }
        });

        this.disableProcessTypeSelect = true;
        this.processType.first.resetSelect();
        this.processType.first.bankCtrl.reset();
        this.processType.last.resetSelect();
        this.processType.last.bankCtrl.reset();
        this.resetSearch.emit(true);
    }

    byOpenSelect(event: any) {
        if (event.newValue === true) {
            if (event.key === KeysMaestro.CUENTA_NEGOCIADORA) {
                let subfilter = this.subfilters?.find(
                    (f: any) =>
                        f.key === KeysMaestro.CUENTA_NEGOCIADORA || f.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA
                );
                if (subfilter) {
                    subfilter.data = [];
                }
            } else if (event.key === KeysMaestro.SOCIEDADES) {
                let subfilter = this.subfilters?.find(
                    (f: any) => f.key === KeysMaestro.SOCIEDADES || f.key === KeysMaestro.CONTRACT_SOCIEDADES
                );
                if (subfilter) {
                    subfilter.data = [];
                }
            } else if (event.key === KeysMaestro.CODIGO_CONTRATO) {
                let subfilter = this.subfilters?.find(
                    (f: any) => f.key === KeysMaestro.CODIGO_CONTRATO || f.key === KeysMaestro.CONTRACT_CODIGO_CONTRATO
                );
                if (subfilter) {
                    subfilter.data = [];
                }
            } else if (event.key === KeysMaestro.CRM_VARIABLE) {
                let crmVariables: any = [];
                this.maestroService.getCrmVariables().subscribe((price: any) => {
                    price.data.forEach((element: any) => {
                        crmVariables.push({ name: element });
                    });

                    // Guardo en los subsfilters de codigo contrato lo de la petición
                    this.subfilters?.forEach((subfilter) => {
                        if (subfilter.key === KeysMaestro.CRM_VARIABLE) {
                            subfilter.data = crmVariables;
                        }
                    });
                });
            } else if (event.key === KeysMaestro.CUENTA_NEGOCIADORA_GET) {
                let subfilter = this.subfilters?.find((f: any) => f.key === KeysMaestro.CUENTA_NEGOCIADORA_GET);
                if (subfilter) {
                    subfilter.data = [];
                    this.maestroService.getNegotiatingAccounts().subscribe((neAccounts) => {
                        neAccounts.data.forEach((element: any) => {
                            if (subfilter) {
                                subfilter.data.push({ name: element.negotiatingAccountCode });
                            }
                        });
                    });
                }
            }
        }
    }

    specialSearch(event: any) {
        if (
            (event.key === KeysMaestro.SOCIEDADES || event.key === KeysMaestro.CONTRACT_SOCIEDADES) &&
            this.selectId !== MaestrosCRM.COBERTURA_CONTRATO
        ) {
            let subfilter = this.subfilters?.find(
                (f: any) => f.key === KeysMaestro.SOCIEDADES || f.key === KeysMaestro.CONTRACT_SOCIEDADES
            );
            this.maestroService
                .getClients({ search: event.search, mode: subfilter?.toggleState ? 'cif' : 'description' })
                .subscribe((client) => {
                    if (subfilter) {
                        subfilter.data = [];
                    }
                    client.data.forEach((element: any) => {
                        if (subfilter && subfilter.toggleState === true) {
                            subfilter.data.push({ name: element.idNumber });
                        } else if (subfilter) {
                            subfilter.data.push({ name: element.clientName });
                        }
                    });
                });
        } else if (
            event.key === KeysMaestro.CUENTA_NEGOCIADORA ||
            event.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA
        ) {
            let subfilter = this.subfilters?.find(
                (f: any) =>
                    f.key === KeysMaestro.CUENTA_NEGOCIADORA || f.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA
            );
            this.maestroService
                .getNegotiatingAccounts({
                    search: event.search,
                    mode: subfilter?.toggleState ? 'negotiatingAccountCode' : 'negotiatingAccountDescription',
                })
                .subscribe((neAccounts) => {
                    if (subfilter) {
                        subfilter.data = [];
                    }
                    neAccounts.data.forEach((element: any) => {
                        if (subfilter && subfilter.toggleState === true) {
                            subfilter.data.push({ name: element.negotiatingAccountCode });
                        } else if (subfilter) {
                            subfilter.data.push({ name: element.negotiatingAccountDescription });
                        }
                    });
                });
        } else if (event.key === KeysMaestro.CODIGO_CONTRATO || event.key === KeysMaestro.CONTRACT_CODIGO_CONTRATO) {
            let subfilter = this.subfilters?.find(
                (f: any) => f.key === KeysMaestro.CONTRACT_CODIGO_CONTRATO || f.key === KeysMaestro.CODIGO_CONTRATO
            );
            if (subfilter) {
                subfilter.data = [];
            }
            this.maestroService.getContractCode(event.search).subscribe((code) => {
                code.data.forEach((element: any) => {
                    if (subfilter) {
                        subfilter.data.push({ name: element.contractCode });
                    }
                });
            });
        }
    }
}
