<!-- ERROR MESSAGE -->
<ng-container *ngIf="isDateInvalid && ((initialDate && endDate) || uniqueDate)">
    <div class="invalidDateErrorMessage">
        {{ 'PAGE.FILTERS.IS_DATE_INCORRECT' | translate }}
    </div>
</ng-container>
<!-- DATE RANGE FILTER -->
<ng-container *ngIf="isDateRange">
    <mat-form-field appearance="standard" class="date">
        <mat-label>{{ name | translate }}{{ isRequired ? ' *' : '' }}</mat-label>
        <!-- DISPLAYED DATES -->
        <div class="display-flex">
            <input
                matInput
                placeholder="{{ 'PAGE.FILTERS.SELECT.INIT_DATE' | translate }}"
                [value]="getDateToDisplay(initialDate) | momentDateFormat : 'date'"
                (change)="onDateInput($event, 'initialDate', true)"
            />
            <ng-container *ngIf="initialDate && endDate">
                <span class="dates-separator">&mdash;</span>
            </ng-container>
            <input
                matInput
                placeholder="{{ 'PAGE.FILTERS.SELECT.END_DATE' | translate }}"
                [value]="getDateToDisplay(endDate) | momentDateFormat : 'date'"
                (change)="onDateInput($event, 'endDate', true)"
            />
        </div>
        <!-- DATE PICKER -->
        <div class="display-none">
            <mat-date-range-input [rangePicker]="picker" [formGroup]="formGroup">
                <input
                    matStartDate
                    formControlName="initialDate"
                    placeholder="{{ 'PAGE.FILTERS.SELECT.INIT_DATE' | translate }}"
                    (dateInput)="onDateInput($event, 'initialDate')"
                />
                <input
                    matEndDate
                    formControlName="endDate"
                    placeholder="{{ 'PAGE.FILTERS.SELECT.END_DATE' | translate }}"
                    (dateInput)="onDateInput($event, 'endDate')"
                />
            </mat-date-range-input>
        </div>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
</ng-container>
<!-- UNIQUE DATE FILTER -->
<ng-container *ngIf="!isDateRange">
    <mat-form-field appearance="standard">
        <mat-label>{{ name | translate }}{{ isRequired ? ' *' : '' }}</mat-label>
        <!-- DISPLAYED DATES -->
        <div class="display-flex">
            <input
                matInput
                placeholder="{{ 'PAGE.FILTERS.SELECT.DATE' | translate }}"
                [value]="getDateToDisplay(uniqueDate) | momentDateFormat : 'date'"
                [disabled]="isDisabled"
                (dateInput)="onDateInput($event, 'uniqueDate', true)"
            />
        </div>
        <!-- DATE PICKER -->
        <input
            matInput
            class="display-none"
            placeholder="{{ 'PAGE.FILTERS.SELECT.DATE' | translate }}"
            [matDatepicker]="picker"
            [disabled]="isDisabled"
            [value]="uniqueDate"
            (dateInput)="onDateInput($event, 'uniqueDate')"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</ng-container>
