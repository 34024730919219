<app-select-procesos-res
    (OptionSelectedId)="receiveIdSelect($event)"
    (OptionFilters)="receiveIdFilters($event)"
    (resetSearch)="resetSearch($event)"
    (systemSelect)="(systemSelect)"
>
</app-select-procesos-res>
<div class="container">
    <app-spinner></app-spinner>
    <app-http-error></app-http-error>
    <div class="row row-column" *ngIf="dataHeaders">
        <div class="table-header">
            <div class="table-header-info">
                <span
                    >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                    {{ totalRegisters | numberFormat : _headerTitleService.currentLang }}</span
                >
            </div>
            <div class="display_flex">
                <button mat-button style="margin-left: 15px">
                    <span class="text" (click)="openModalExcelExport()">{{
                        'PAGE.ACTIONS.EXCEL_EXPORT' | translate
                    }}</span>
                    <span class="icon material-symbols-outlined">save_as </span>
                </button>
                <button class="buttonColumns borderRadius" [ngClass]="ocultar === true ? 'mat' : 'mat_none'">
                    <mat-form-field [hidden]="ocultar">
                        <mat-select
                            #selectColumns
                            name="selectColumns"
                            id="selectColumns"
                            [value]="dataHeaders_valueSelect"
                            multiple
                        >
                            <mat-option
                                #selectColumns2
                                name="selectColumns2"
                                id="selectColumns2"
                                enableCheckAll="true"
                                [matColumnDef]="column.label"
                                [value]="column"
                                (click)="checkvalue(selectColumns.value)"
                                *ngFor="let column of dataHeaders_valueSelect"
                                >{{ column.label }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                    <div class="divColumns" (click)="botonClick()">
                        <span class="text spanColumn" *ngIf="!element">{{ 'PAGE.ACTIONS.COLUMNS' | translate }}</span>
                        <app-base-icon class="padding_button" iconName="view_column"></app-base-icon>
                    </div>
                </button>
            </div>
        </div>
        <!--    status-{{dataBusiness[0].status}}
        launch-{{dataBusiness[0].launchId.name}}
        launch-{{dataBusiness[0].processCode.name}} -->

        <div class="table-container-main">
            <div class="table-container">
                <table mat-table [dataSource]="dataBusiness" multiTemplateDataRows matSort>
                    <ng-container
                        style="max-width: fit-content"
                        [matColumnDef]="column.id"
                        *ngFor="let column of dataHeaders; let i = index"
                    >
                        <!-- Header TH -->
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                            <div class="header-table d-flex">
                                {{ column.label }}
                            </div>
                        </th>
                        <!-- Element TD -->
                        <td mat-cell *matCellDef="let element">
                            <!-- Element TD Text -->
                            <div>
                                <!-- Campos formula con Tooltip en Punto Frontera : -->
                                <div class="tipoFormula">
                                    <span [matTooltip]="checkAndReturnColumns(element, column.id)">
                                        {{ checkAndReturnColumns(element, column.id) }}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9999">{{ 'PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA' | translate }}</td>
                    </tr>
                </table>
            </div>
            <mat-paginator
                [length]="totalRegisters"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChange($event)"
                aria-label="Select page"
            >
            </mat-paginator>
        </div>
    </div>
    <div class="container containerNoData" *ngIf="!dataHeaders">
        <div class="row flex_column">
            <app-spinner></app-spinner>
            <app-http-error></app-http-error>
            <h3>{{ 'PAGE.ACTIONS.NO_DATA' | translate }}</h3>
        </div>
        <app-table-no-data></app-table-no-data>
    </div>
</div>

<!-- MODAL EXCELL -->

<div *ngIf="openModalExcel" class="cdk-overlay-container">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
    <div class="modal_box cdk-overlay-container">
        <div
            style="max-width: 80vw; position: static; padding: 24px; height: initial"
            class="cdk-overlay-pane"
            class="mat-dialog-container ng-tns-c122-25 ng-trigger ng-trigger-dialogContainer ng-star-inserted"
        >
            <div class="modal-title-close">
                <h1 mat-dialog-title>{{ 'PAGE.MODALS.TITLE1' | translate }}</h1>
                <button mat-button mat-dialog-close class="hide-access close" (click)="closeModal()">close</button>
            </div>
            <div mat-dialog-content class="div_buttons">{{ 'PAGE.MODALS.TEXT_TITLE_1' | translate }}</div>
            <div mat-dialog-actions>
                <button mat-button mat-dialog-close class="secondary" (click)="closeModal()">
                    {{ 'PAGE.MODALS.NO' | translate }}
                </button>
                <button mat-button mat-flat-button color="warn" (clicl)="openModal()" class="primary" cdkFocusInitial>
                    {{ 'PAGE.MODALS.YES' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
