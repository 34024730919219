export enum DashboardSections {
    SUMMARY = 'summary',
    TECHNICAL_INFO = 'technicalInfo',
    CHECK = 'check',
    MEASURES = 'measures',
    CLOSES = 'closes',
    BEST_MEASURE_CLOSE = 'bestMeasureClose',
    SETTLEMENT = 'settlement',
}

export enum DashboardPeriodCodes {
    PERIOD_CODE_20 = 20,
    PERIOD_CODE_1 = 1,
    PERIOD_CODE_2 = 2,
    PERIOD_CODE_3 = 3,
    PERIOD_CODE_4 = 4,
    PERIOD_CODE_5 = 5,
    PERIOD_CODE_6 = 6,
}

export enum DashboardFilterIds {
    MONTH_FILTER_ID = '105',
    YEAR_FILTER_ID = '106',
    NEGOTIATING_ACCOUNT_ID = '124',
    INSTALLATION_ID = '112',
    CLIENT_TYPE_ID = '133',
    MEASURE_DISCREPANCY_ID = '111',
}

export enum DashboardBestMeasureOptions {
    MANUAL = 'Manual',
    F_DISTRIBUTOR = 'Distribuidor F',
    P_DISTRIBUTOR = 'Distribuidor P',
    HUB = 'Concentrador',
    REE = 'REE',
    ATR = 'ATR',
    FORECASTS = 'Previsiones',
    ACUM = 'ACUM',
    INMECLOS = 'INMECLOS',
    AWS = 'AWS',
}

export enum DashboardBestCloseOptions {
    MANUAL = 'Manual',
    DISTRIBUTOR = 'Distribuidor',
    HUB = 'Concentrador',
    ATR = 'ATR',
    MIXED = 'Mixto',
    HOURLY = 'Horario',
    QUARTER_HOURLY = 'CCH',
    AWS = 'AWS',
}
