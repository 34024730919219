import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl implements OnDestroy {
    unsubscribe: Subject<void> = new Subject<void>();
    zeroOfLabel: string = '';
    ofLabel: string = '';
    totalLabel: string = '';

    constructor(private _translateService: TranslateService, private _headerTitleService: HeaderTitleService) {
        super();
        this._translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.getAndInitTranslations();
        });

        this.getAndInitTranslations();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getAndInitTranslations() {
        this._translateService
            .get('PAGE.ACTIONS.PAGINATOR')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((message: any) => {
                this.itemsPerPageLabel = message['ITEMS_PER_PAGE'];
                this.nextPageLabel = message['NEXT_PAGE'];
                this.previousPageLabel = message['PREVIOUS_PAGE'];
                this.zeroOfLabel = message['0_OF'];
                this.ofLabel = message['OF'];
                this.totalLabel = message['TOTAL'];
                this.changes.next();
            });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        const languagePrefix: string = this._headerTitleService.currentLang === 'en' ? 'en-US' : 'de-DE';
        if (length === 0 || pageSize === 0) {
            return this.zeroOfLabel + `${Number(length).toLocaleString(languagePrefix)}`;
        }
        const startIndex = page * pageSize;
        length = Math.max(length, 0);
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${Number(startIndex + 1).toLocaleString(languagePrefix)} - ${Number(endIndex).toLocaleString(
            languagePrefix
        )} ${this.ofLabel} ${Number(length).toLocaleString(languagePrefix)} ${this.totalLabel}`;
    };
}
