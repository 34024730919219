export enum SelectEnviado {
    DIARIO = 'Mercado Diario',
    INTRADIARIO = 'Mercado Intradiario',
    PRECIOS_REGULADOS = 'preciosRegulados',
    PARAMETROS = 'parámetros',
    NO_ENVIADO = 'No',
}

export enum KeysPrevisiones {
    TIPO = 'type',
    ORIGEN = 'origin',
    PREVISIONES_AGREGADO = 'previsiones_agregado',
}
