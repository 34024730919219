import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TablaProcesosResComponent } from '../tabla-procesos-res.component';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-edit-modal-process-res',
    templateUrl: './edit-modal-process-res.component.html',
    styleUrls: ['./edit-modal-process-res.component.scss'],
})
export class EditModalProcessResComponent {
    copyData: any;
    constructor(
        public DialogEdit: MatDialogRef<TablaProcesosResComponent>,
        protected readonly _headerTitleService: HeaderTitleService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.copyData = JSON.parse(JSON.stringify(data));
    }
    close(resp: boolean) {
        this.data = this.copyData;
        const newData = { resp: resp, data: this.data };
        this.DialogEdit.close(newData);
    }
    valueChange($event: any) {
        this.copyData.value[$event.name] = $event.newValue;
    }
}
