<mat-form-field appearance="standard" class="select">
    <mat-label>{{ title }}</mat-label>
    <mat-select
        (selectionChange)="emitValue($event)"
        (selectionChange)="emitValueNew($event)"
        [(value)]="value"
        [placeholder]="value"
    >
        <mat-option *ngFor="let month of optionsMonth" [value]="month">{{ month }}</mat-option>
    </mat-select>
</mat-form-field>
