<mat-carousel
    timings="250ms ease-in"
    [autoplay]="true"
    [interval]="5000"
    color="warn"
    maxWidth="auto"
    [proportion]="35"
    [slides]="3"
    [routerLink]="[]"
    [loop]="true"
    [hideArrows]="false"
    [hideIndicators]="true"
    [useKeyboard]="true"
    [useMouseWheel]="false"
    orientation="ltr"
>
    <mat-carousel-slide matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide.image" overlayColor="none" [hideOverlay]="false">
        <div class="text-content">
            <h1 class="text-title">{{ 'PAGE.HOME.CAROUSEL.SLIDE_' + (i + 1) + '.TITLE' | translate }}</h1>
            <p class="text-subtitle">{{ 'PAGE.HOME.CAROUSEL.SLIDE_' + (i + 1) + '.SUBTITLE' | translate }}</p>
            <p class="text-paragraph">{{ 'PAGE.HOME.CAROUSEL.SLIDE_' + (i + 1) + '.TEXT' | translate }}</p>
        </div>
    </mat-carousel-slide>
</mat-carousel>

<!-- TO DO - INTRODUCIR VARIABLE DEL NÚMERO DEL SLIDE EN EL IDENTIFICADOR SLIDE_{%i} PARA CAMBIAR CONTENIDO -->
