export enum TabDetails {
    TAB_PRICES = 0,
    TAB_CONTRACT_CONCEPTS = 1,
    TAB_CLOSURES = 2,
}

export enum ContractDetailsEnum {
    adjMec = 'ADJMEC',
    atrProcess = 'ATRPROCESS',
    contractCode = 'CONTRACT_CODE',
    contractName = 'CONTRACT_NAME',
    contractCodeSigned = 'CONTRACT_CODE_SIGNED',
    contractCodeRemit = 'CONTRACT_CODE_REMIT',
    initDate = 'INIT_DATE',
    finalDate = 'FINAL_DATE',
    initEffectiveDate = 'INIT_EFFECTIVE_DATE',
    finalEffectiveDate = 'FINAL_EFFECTIVE_DATE',
    cups = 'CUPS',
    tension = 'TENSION',
    status = 'STATUS',
    eintensiveType = 'EINTENSIVE_TYPE',
    pmType = 'PM_TYPE',
    selfsupplyType = 'SELF_SUPPLY_TYPE',
    periodNum = 'PERIODNUM',
    clientType = 'CLIENT_TYPE',
    signatureDate = 'SIGNATURE_DATE',
    negotiatingAccountCode = 'NEGOTIATING_ACCOUNT_CODE',
    negotiatingAccountDescription = 'NEGOTIATING_ACCOUNT_DESCRIPTION',
    rebillC5 = 'REBILL_C5',
    hourlyMatrix = 'HOURLY_MATRIX',
    issuerCompany = 'ISSUER_COMPANY',
    idNumber = 'ID_NUMBER',
    lessOneYearContract = 'LESS_ONE_YEAR_CONTRACT',
    rate = 'RATE',
    indLiqEstimated = 'IND_LIQ_ESTIMATED',
    updateDate = 'UPDATE_DATE',
    updateUser = 'UPDATE_USER',
    creationDate = 'CREATION_DATE',
    creationUser = 'CREATION_USER',
    instalationType = 'INSTALATION_TYPE',
    calculateInitialFc = 'CALCULATE_INITIAL_FC',
    contractType = 'CONTRACT_TYPE',
    enterprise = 'ENTERPRISE',
    concepts = 'CONCEPTS',
}

export enum ContractInstallationsDetailsEnum {
    cups = 'CUPS',
    instName = 'INST_NAME',
    instCode = 'INST_CODE',
    instType = 'INST_TYPE',
    programUnitCode = 'PROGRAM_UNIT_CODE',
    atrContractCode = 'ATR_CONTRACT_CODE',
    distrCode = 'DISTR_CODE',
    street = 'STREET',
    town = 'TOWN',
    municipality = 'MUNICIPALITY',
    zipCode = 'ZIP_CODE',
    province = 'PROVINCE',
    country = 'COUNTRY',
    telephone = 'TELEPHONE',
    fax = 'FAX',
    email = 'EMAIL',
    agreedLosses = 'AGREED_LOSSES',
    councilName = 'COUNCIL_NAME',
    councilCif = 'COUNCIL_CIF',
    initDateAtr = 'INIT_DATE_ATR',
    endDateAtr = 'END_DATE_ATR',
    creationUser = 'CREATION_USER',
    creationDate = 'CREATION_DATE',
    updateUser = 'UPDATE_USER',
    updateDate = 'UPDATE_DATE',
    historic = 'HISTORIC',
    boundaryPoints = 'BOUNDARY_POINTS',
}

export enum ContractPrices {
    contract = 'Contrato',
    creationDate = 'Fecha de creación',
    creationUser = 'Usuario de creación',
    crmVariable = 'Variable CRM',
    emsVariable = 'Variable EMS',
    variableType = 'Tipo de variable',
    initDate = 'Fecha Inicio',
    endDate = 'Fecha Fin',
    period = '',
    price = 'Precio',
    updateDate = 'Fecha de última actualización',
    updateUser = 'Fecha de Actualización de usuario',
}

export enum HeadersContractDetails {
    PRICES = 'maestro_detalles_desglose_precios',
    CONTRACT_CONCEPTS = 'maestro_detalles_conceptos_contrato',
    CLOSURES = 'maestro_detalles_cierres',
}
