import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ErrorHttpService {
    errorAppears$ = new Subject<boolean>();

    show(): void {
        this.errorAppears$.next(true);
    }
    hide(): void {
        this.errorAppears$.next(false);
    }
}
