import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CuadroMandosService {
    activeColumn: string;

    constructor(private _http: HttpClient) {}

    getInstallations() {
        if (environment.isMockActive) {
            const url = './assets/json/instalaciones.json';
            return this._http.get<any[]>(url);
        } else {
            const url = `${environment.apiUrl}/cups/v0?page=-1&sortBy=cups&sortDir=asc`;
            return this._http.get<any>(url);
        }
    }

    getTechnicalInfo(search?: any, pageSize?: any, numPage?: any) {
        if (pageSize === undefined) {
            pageSize = 1;
        }
        if (numPage === undefined) {
            numPage = 1;
        }
        let url = `${environment.apiUrl}/dashboard/v0?page=${numPage}&pageSize=${pageSize}&sortBy=cups&sortDir=asc&search=`;

        if (search === 'pagination') {
            url = `${environment.apiUrl}/dashboard/v0?page=-1&pageSize=&sortBy=cups&sortDir=asc&search=`;
            return this._http.get<any>(url);
        }

        if (search.includes('cups=()')) {
            search = search.replace('cups=()', '');
        }

        if (search != undefined) {
            url = `${environment.apiUrl}/dashboard/v0/search?page=${numPage}&pageSize=${pageSize}&sortBy=cups&sortDir=asc`;
            return this._http.post<any[]>(url, { search: search });
        } else {
            return this._http.post<any[]>(url, { search: '' });
        }
    }

    getCloses(seqList: any, search?: any) {
        let url = `${environment.apiUrl}/close/v0/${seqList}`;
        if (search != undefined) {
            url = `${environment.apiUrl}/close/v0?page=-1&pageSize=&sortBy=cups&sortDir=asc&search==${search}`;
        }
        return this._http.get<any>(url);
    }

    getCheckMeasures(seqList: any) {
        let url = `${environment.apiUrl}/measure/v0/${seqList}`;
        return this._http.get<any>(url);
    }

    getChecks(seqList: any) {
        let url = `${environment.apiUrl}/dashboard-checks/v0/${seqList}`;
        return this._http.get<any>(url);
    }

    getSettlements(cup: any, initDate: any, finalDate: any) {
        let url = `${environment.apiUrl}/settlement/v0/?page=-1&pageSize=&sortBy=cups&sortDir=asc&search=cups=(${cup}),initDate>=${initDate},finalDate<=${finalDate}`;

        return this._http.get<any>(url);
    }

    getSettlementAggregatedCurves(cup: any, closeDateBegin: any, closeDateEnd: any) {
        let url = `${environment.apiUrl}/settlement/v0/curve-aggregated?page=-1&pageSize=&sortBy=cups&sortDir=asc&search=cups=(${cup}),closeDateBegin>=${closeDateBegin},closeDateEnd<=${closeDateEnd}`;
        return this._http.get<any>(url);
    }

    getBestMeasures(cups: any, closeDateBegin: any, closeDateEnd: any) {
        let url = `${environment.apiUrl}/best-measure-period/v0/${cups}/${closeDateBegin}/${closeDateEnd}?page=-1&sortBy=cups&sortDir=asc`;
        return this._http.get<any>(url);
    }

    getBestCloses(cup?: any, closeDateBegin?: any, closeDateEnd?: any) {
        let url = `${environment.apiUrl}/definitiveBestClose/v0/${cup}/${closeDateBegin}/${closeDateEnd}?page=-1&sortBy=cups&sortDir=asc`;
        return this._http.get<any>(url);
    }

    getDropdownBestClose(cups: any, periodCode: any, closeDateBegin: any, closeDateEnd: any) {
        let url = `${environment.apiUrl}/bestClose/v0/${cups}/${periodCode}/${closeDateBegin}/${closeDateEnd}`;
        return this._http.get<any>(url);
    }

    updateBlockFlag(seq: any, bloquedFlag: any, body?: any) {
        const url = `${environment.apiUrl}/dashboard/v0/${seq}/${bloquedFlag}`;
        return this._http.put<any>(url, body);
    }

    updateBlockFlagBulk(cup: any, year: any, month: any, blockFlag: any) {
        if (cup === ' ') {
            cup = ':cups';
            const url = `${environment.apiUrl}/dashboard/v0/${cup}/${year}/${month}/${blockFlag}`;
            return this._http.put<any>(url, blockFlag);
        } else {
            const url = `${environment.apiUrl}/dashboard/v0/${cup}/${year}/${month}/${blockFlag}`;
            return this._http.put<any>(url, blockFlag);
        }
    }

    updateBestMeasure(cup: any, initDate: any, endDate: any, origin: any) {
        if (cup === ' ') {
            cup = ':cups';
            const url = `${environment.apiUrl}/best-measure/v0/${cup}/${initDate}/${endDate}/${origin}`;
            return this._http.put<any>(url, origin);
        } else {
            const url = `${environment.apiUrl}/best-measure/v0/${cup}/${initDate}/${endDate}/${origin}`;
            return this._http.put<any>(url, origin);
        }
    }

    updateBestClose(cup: any, initDate: any, endDate: any, origin: any) {
        const url = `${environment.apiUrl}/bestClose/v0/${cup}/${initDate}/${endDate}/${origin}`;
        return this._http.put<any>(url, origin);
    }

    /**
     * Función que realiza una llamada a un endpoint de process para ejecutar el cálculo asociado al mismo.
     * @param cup Lista de cups para el cálculo
     * @param initDate Fecha inicial de la que parte el cálculo
     * @param endDate Fecha final que observa el cálculo
     * @returns Objeto de respuesta html desde el servidor con los datos en las propiedades
     */
    updateBlockFlagCups(body: any) {
        const url = `${environment.apiUrl}/process/v0/launch`;
        return this._http.post<any>(url, body);
    }

    getSelectInstallations(code?: string) {
        if (environment.isMockActive) {
            const url = './assets/json/instalaciones.json';
            return this._http.get<any[]>(url);
        }
        const url = `${environment.apiUrl}/cups/v0?page=-1&search=instNamelike(${code})`;
        return this._http.get<any>(url);
    }

    launchReport(body: any) {
        const url = `${environment.apiUrl}/reporting/v0?`;
        return this._http.post<any>(url, body);
    }

    // MOCK SERVICES
    getMockDashboard() {
        const url = './assets/json/cuadro_mando.json';
        return this._http.get<any>(url).pipe(map((e) => e));
    }

    getMockTechnicalInfo() {
        const url = './assets/json/cuadro_mando_info_tecnica.json';
        return this._http.get<any>(url).pipe(map((e) => e));
    }

    getMockChecks() {
        const url = './assets/json/cuadro_mando_check.json';
        return this._http.get<any>(url).pipe(map((e) => e));
    }

    getMockCurves() {
        const url = './assets/json/cuadro_mando_curvas.json';
        return this._http.get<any>(url).pipe(map((e) => e));
    }

    getMockCloses() {
        const url = './assets/json/cuadro_mando_cierres.json';
        return this._http.get<any>(url).pipe(map((e) => e));
    }

    getMockPreferences() {
        const url = './assets/json/cuadro_mando_prelaciones.json';
        return this._http.get<any>(url).pipe(map((e) => e));
    }

    getMockSettlements() {
        const url = './assets/json/cuadro_mando_liquidacion.json';
        return this._http.get<any>(url).pipe(map((e) => e));
    }

    checkRequestStatus(filters: string) {
        return this._http.post<any[]>(
            `${environment.apiUrl}/dashboard/v0/search?page=1&pageSize=1&sortBy=cups&sortDir=asc`,
            { search: filters }
        );
    }
}
