export enum TipoEnvios {
    FORECAST = 'Previsiones',
    DAILY_FORECAST = 'Previsiones diarias',
    WEEKLY_FORECAST = 'Previsiones mensuales',
    OPTIONAL_SETTLEMENT = 'Liquidación Potestativa',
    DAILY_FORECAST_CODE = 'DAILY_FORECAST',
    WEEKLY_FORECAST_CODE = 'WEEKLY_FORECAST',
    OPTIONAL_SETTLEMENT_CODE = 'OPTIONAL_SETTLEMENT',
}

export enum EnviosRES {
    RESULTS = 'resultados',
}

export enum StatusEnum {
    KO = 'KO',
    IN_PROGRESS = 'En curso',
    PENDING = 'Pendiente',
    OK = 'Ok',
}

export enum PaginationIDs {
    RESULTS = 'resultados',
}

export enum SortBy {
    DATE = 'initDate',
    STATE = 'status',
    ID = 'id',
}

export enum KeysEnvios {
    ENVIOS_RESULTADOS = 'envios_resultados',
}
