<div class="container" *ngIf="dataBusiness">
    <app-spinner></app-spinner>
    <app-http-error></app-http-error>
    <div class="row row-column">
        <div class="table-header">
            <div class="table-header-info">
                <span
                    >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                    {{ totalRegisters | numberFormat : _headerTitleService.currentLang }}</span
                >
            </div>
            <div class="table-header-actions display_flex actions-heigh">
                <button mat-button (click)="openModalExcel()">
                    <span class="text">{{ 'PAGE.ACTIONS.EXCEL_EXPORT' | translate }}</span>
                    <span class="icon material-symbols-outlined">save_as </span>
                </button>
                <button
                    (click)="add()"
                    mat-button
                    color="primary"
                    *ngIf="
                        permissions?.add &&
                        permissions?.id != 'puntoMedida' &&
                        permissions?.id !== 'preciosMensuales' &&
                        permissions?.id !== 'preciosIndexados'
                    "
                >
                    <span class="text">{{ 'PAGE.ACTIONS.ADD_REGISTER' | translate }}</span>
                    <span class="icon material-symbols-outlined"> add_circle </span>
                </button>
                <button
                    (click)="showModal(PuntoFronteraTypeModal.SET_PRECEDENCES)"
                    mat-button
                    color="primary"
                    *ngIf="idFilters[0] === 'puntoFrontera' && permissions?.edit"
                >
                    <span class="text">{{ 'PAGE.ACTIONS.SET_PRECEDENCES' | translate }}</span>
                    <span class="icon material-symbols-outlined"> group </span>
                </button>
                <button class="buttonColumns" [ngClass]="ocultar === true ? 'mat' : 'mat_none'">
                    <!-- INICIO>>> Botón mostrar/ocultar columnas -->
                    <mat-form-field [hidden]="ocultar">
                        <mat-select
                            #selectColumns
                            name="selectColumns"
                            id="selectColumns"
                            [value]="dataHeaders_valueSelect"
                            multiple
                        >
                            <mat-option
                                enableCheckAll="true"
                                [matColumnDef]="column.id"
                                [value]="column"
                                (click)="checkvalue(selectColumns.value)"
                                *ngFor="let column of filterDataHeader()"
                                >{{ column.label }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                    <div class="divColumns" (click)="botonClick()">
                        <span class="text spanColumn" *ngIf="!element">{{ 'PAGE.ACTIONS.COLUMNS' | translate }}</span>
                        <app-base-icon class="padding_button" iconName="view_column"></app-base-icon>
                    </div>
                </button>
                <!-- FIN>>> Botón mostrar/ocultar columnas -->
            </div>
        </div>
        <div class="table-container-main">
            <div
                class="table-container"
                [ngClass]="{
                    'acciones--1': permissions?.edit === true || permissions?.delete == true,
                    'acciones--2': permissions?.edit == true && permissions?.delete,
                    'puntoFrontera': this.filtersTable[0] === MaestrosAWS.PUNTO_FRONTERA,
                    'contratos': this.filtersTable[0] === MaestrosCRM.CONTRATOS
                }"
            >
                <!-- Table -->
                <table
                    mat-table
                    [dataSource]="dataBusiness"
                    multiTemplateDataRows
                    matSort
                    (matSortChange)="sortData($event)"
                >
                    <ng-container
                        style="max-width: fit-content"
                        [matColumnDef]="column.id"
                        *ngFor="let column of dataHeaders; let i = index"
                    >
                        <!-- Header TH -->
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                            <div class="header-table d-flex">
                                {{ column.label }}
                            </div>
                        </th>
                        <!-- Element TD -->
                        <td mat-cell *matCellDef="let element">
                            <!-- Element TD Text -->
                            <div *ngIf="column.type === 'text'">
                                <span *ngIf="shouldDisplayColumn(column.id, element)">
                                    {{ checkAndReturnColumns(element, column.id) }}
                                    <ng-container
                                        *ngIf="
                                            idFilters[0] === MaestrosAWS.PRECIOS_REGULADOS &&
                                            element[column.id] &&
                                            ['valueP1', 'valueP2', 'valueP3', 'valueP4', 'valueP5', 'valueP6'].includes(
                                                column.id
                                            )
                                        "
                                    >
                                        <span class="units">{{ getUnits(element, column.id) }}</span>
                                    </ng-container>
                                </span>
                                <div class="tipoVariable" *ngIf="column.id === 'cecoerActiveHub'">
                                    <span class="material-symbols-outlined">{{
                                        checkBooleanValue(element, column.id)
                                    }}</span>
                                </div>
                                <div class="tipoVariable" *ngIf="column.id === 'variableType'">
                                    <span class="material-symbols-outlined">{{
                                        checkVarType(element[column.id])
                                    }}</span>
                                </div>
                                <!-- MOSTRAR AUTONOMIAS CORRECTAMENTE : -->
                                <span *ngIf="column.id === 'regionCode'">{{ checkRegion(element[column.id]) }}</span>
                                <span
                                    *ngIf="
                                        column.id === 'code' &&
                                        this.maestroService.selectId === MaestrosAWS.PRECIOS_REGULADOS
                                    "
                                    >{{ checkCodePrice(element[column.id]) }}</span
                                >
                                <span
                                    *ngIf="
                                        column.id === 'name' &&
                                        this.maestroService.selectId === MaestrosAWS.PERFILES_REE
                                    "
                                    >{{ returnColumnReeProfileName(element[column.id]) }}</span
                                >
                                <!-- Estado Cierres : -->
                                <div class="tipoVariable" *ngIf="column.id === 'contract.status'">
                                    <span class="material-symbols-outlined">{{
                                        checkState(checkAndReturnColumns(element, column.id), element)
                                    }}</span>
                                </div>
                                <!--Fechas-->
                                <div *ngIf="column.id === 'month'">
                                    {{ transformMonthNumberToText(element[column.id]) }}
                                </div>
                                <div *ngIf="column.id === 'dateInit'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'dateEnd'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'creationDate'">
                                    {{ transformDate(element[column.id], true) }}
                                </div>
                                <div *ngIf="column.id === 'updateDate'">
                                    {{ transformDate(element[column.id], true) }}
                                </div>
                                <div *ngIf="column.id === 'festiveDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'festiveType'">
                                    {{ getFestiveTypeMapped(element[column.id]) }}
                                </div>
                                <div *ngIf="column.id === 'initialDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'endDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'date'">{{ transformDate(element[column.id], false) }}</div>
                                <div *ngIf="column.id === 'validityDateInit'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'validityDateEnd'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'initValidityDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'finalValidityDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'initDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'initDateAtr'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'endDateAtr'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'hedgeDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'finalDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'initEffectiveDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'finalEffectiveDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'beginDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'closedDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'vigorDate'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'regDateMP'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>
                                <div *ngIf="column.id === 'commercialRequest'">
                                    {{ transformDate(element[column.id], false) }}
                                </div>

                                <!-- Campos formula con Tooltip en Punto Frontera : -->
                                <div
                                    class="tipoFormula"
                                    *ngIf="
                                        column.id === 'formulaInEnergy' ||
                                        column.id === 'formulaOutEnergy' ||
                                        column.id === 'formulaReactEnergyQ1' ||
                                        column.id === 'formulaReactEnergyQ2' ||
                                        column.id === 'formulaReactEnergyQ3' ||
                                        column.id === 'formulaReactEnergyQ4'
                                    "
                                >
                                    <span
                                        [matTooltip]="element[column.id]"
                                        matTooltipShowDelay="300"
                                        class="material-symbols-outlined"
                                        >{{ element[column.id] }}</span
                                    >
                                </div>
                                <!-- Modal de reclamaciones en PUNTO FRONTERA: -->
                                <ng-container *ngIf="element.claims === true && column.id === 'claims'">
                                    <span (click)="seeClaims(element)" class="icon material-symbols-outlined eyeIcon"
                                        >visibility</span
                                    >
                                </ng-container>

                                <!-- Modal de objeciones en PUNTO FRONTERA: -->
                                <ng-container *ngIf="element.objections === true && column.id === 'objections'">
                                    <span
                                        (click)="seeObjections(element)"
                                        class="icon material-symbols-outlined eyeIcon"
                                        >visibility</span
                                    >
                                </ng-container>

                                <!-- Estado Coberturas Contratos : -->
                                <div class="tipoVariable" *ngIf="column.id === 'contractCode.status'">
                                    <span class="material-symbols-outlined">{{
                                        checkStateCoberturas(checkAndReturnColumns(element, column.id), element)
                                    }}</span>
                                </div>

                                <!-- liquidationType: -->
                                <div *ngIf="isLiquidationType(element, column.id)">
                                    <span>{{ getLiquidationLabel(element) }}</span>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9999">{{ 'PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA' | translate }}</td>
                    </tr>
                </table>
            </div>

            <mat-paginator
                [length]="totalRegisters"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChange($event)"
                [pageIndex]="numPage - 1"
                aria-label="Select page"
            >
            </mat-paginator>

            <table
                class="tableAccions"
                *ngIf="
                    (permissions?.edit || permissions?.delete) &&
                    permissions?.id !== 'preciosMensuales' &&
                    permissions?.id !== 'preciosIndexados'
                "
                [ngClass]="{
                    'acciones--1': permissions?.edit == true || permissions?.delete == true,
                    'acciones--2': permissions?.edit == true && permissions?.delete
                }"
            >
                <thead *ngIf="permissions?.edit || permissions?.delete">
                    <th *ngIf="permissions?.edit">
                        <div [ngClass]="permissions?.id != 'puntoMedida' ? 'header-table d-flex' : 'header-table'">
                            {{ 'PAGE.ACTIONS.EDIT' | translate }}
                        </div>
                    </th>
                    <th *ngIf="permissions?.delete === true && permissions?.id !== 'puntoMedida'">
                        <div class="header-table d-flex">{{ 'PAGE.ACTIONS.DELETE' | translate }}</div>
                    </th>
                </thead>
                <tbody role="rowgroup" *ngIf="permissions?.edit || permissions?.delete">
                    <!-- Element TD -->
                    <tr *ngFor="let column of dataBusiness; let i = index">
                        <td class="verDetalle" *ngIf="permissions?.edit && column.festiveType !== 'Electricos'">
                            <span (click)="edit(i)" class="icon material-symbols-outlined">edit</span>
                        </td>
                        <td
                            class="verDetalle"
                            *ngIf="
                                permissions?.delete === true &&
                                column.festiveType !== 'Electricos' &&
                                permissions?.id !== 'puntoMedida'
                            "
                        >
                            <span (click)="delete(i)" class="icon material-symbols-outlined">delete</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- TABLA ACTIONS PARA PUNTO FRONTERA-->
            <table
                class="tableAccions"
                *ngIf="this.filtersTable[0] === MaestrosAWS.PUNTO_FRONTERA"
                [ngClass]="{
                    'acciones--1': permissions?.edit == true || permissions?.delete == true,
                    'acciones--2': permissions?.edit == true && permissions?.delete
                }"
            >
                <thead *ngIf="permissions?.edit || permissions?.delete || permissions?.view">
                    <th *ngIf="permissions?.edit">
                        <div class="header-table d-flex">{{ 'PAGE.ACTIONS.EDIT' | translate }}</div>
                    </th>
                    <th *ngIf="permissions?.edit">
                        <div class="header-table d-flex">{{ 'PAGE.ACTIONS.MANAGE_PRECEDENCES' | translate }}</div>
                    </th>
                    <th *ngIf="permissions?.view">
                        <div class="header-table d-flex">{{ 'PAGE.ACTIONS.BOUNDARY_POINTS' | translate }}</div>
                    </th>
                </thead>
                <tbody role="rowgroup" *ngIf="permissions?.edit || permissions?.delete || permissions?.view">
                    <!-- Element TD -->
                    <tr *ngFor="let column of dataBusiness; let i = index">
                        <td class="verDetalle" *ngIf="permissions?.edit">
                            <span (click)="edit(i)" class="icon material-symbols-outlined">edit</span>
                        </td>
                        <td class="verDetalle" *ngIf="permissions?.edit">
                            <span
                                (click)="showModal(PuntoFronteraTypeModal.MANAGE_PRECEDENCES, i)"
                                class="icon material-symbols-outlined"
                                >group</span
                            >
                        </td>
                        <td class="verDetalle" *ngIf="permissions?.view">
                            <span (click)="openBoundaryPointsModal(i)" class="icon material-symbols-outlined"
                                >circle</span
                            >
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="tableMaster" *ngIf="this.filtersTable[0] === MaestrosCRM.CONTRATOS">
                <thead>
                    <th>
                        <div class="header-table d-flex">{{ 'PAGE.ACTIONS.DETAILS' | translate }}</div>
                    </th>
                </thead>
                <tbody role="rowgroup">
                    <tr *ngFor="let column of dataBusiness; let i = index">
                        <td>
                            <span (click)="seeDetails(element, column)" class="icon material-symbols-outlined"
                                >visibility</span
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="container containerNoData" *ngIf="!dataBusiness">
    <div class="row flex_column">
        <app-spinner></app-spinner>
        <app-http-error></app-http-error>
        <h3>{{ 'PAGE.ACTIONS.NO_DATA' | translate }}</h3>
    </div>
    <app-table-no-data></app-table-no-data>
</div>
