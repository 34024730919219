import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-filtro-date',
    templateUrl: './filtro-date.component.html',
    styleUrls: ['./filtro-date.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    ],
})
export class FiltroDateComponent implements OnInit {
    @Input() reset$: Subject<void>;
    @Input() preloadedUniqueDate: any = '';
    @Input() preloadedEndDate: any = '';
    @Input() preloadedInitialDate: any = '';
    @Input() id: string = '';
    @Input() name: string = '';
    @Input() page: string = '';
    @Input() isDateRange: boolean = true;
    @Input() isRequired: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() isPreloaded: boolean = false;
    @Output() date: EventEmitter<any> = new EventEmitter();
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() invalidDate: EventEmitter<any> = new EventEmitter();

    isDateInvalid: boolean;
    uniqueDate: Date | undefined;
    initialDate: Date | undefined;
    endDate: Date | undefined;
    limitDate: Date;
    formGroup = new FormGroup({
        initialDate: new FormControl<Date | undefined>(undefined),
        endDate: new FormControl<Date | undefined>(undefined),
    });

    ngOnInit(): void {
        // If the unique date filter has a preloaded value (mostly in modals)
        if (!this.isDateRange && this.preloadedUniqueDate) {
            if (this.preloadedUniqueDate instanceof Date) {
                this.uniqueDate = new Date(this.preloadedUniqueDate);
            } else if (typeof this.preloadedUniqueDate === 'string') {
                this.uniqueDate = this._formatStringToDate(this.preloadedUniqueDate);
            }
        }
        // If the date range filter has a preloaded value (from process programming page)
        if (this.isDateRange && this.preloadedInitialDate && this.preloadedEndDate) {
            if (
                this.preloadedInitialDate instanceof Date &&
                this.preloadedEndDate instanceof Date &&
                this.preloadedInitialDate <= this.preloadedEndDate
            ) {
                this.initialDate = this.preloadedInitialDate;
                this.endDate = this.preloadedEndDate;
            } else if (typeof this.preloadedInitialDate === 'string' && typeof this.preloadedEndDate === 'string') {
                this.initialDate = this._formatStringToDate(this.preloadedInitialDate);
                this.endDate = this._formatStringToDate(this.preloadedEndDate);
            }
            this._setFormValues();
        }
        this.reset$?.subscribe(() => {
            this.formGroup.reset();
            this.uniqueDate = undefined;
            this.initialDate = undefined;
            this.endDate = undefined;
        });
    }

    // Gets excecuted every time filter value is changed (manually or using the date-picker)
    protected onDateInput(newValue: any, controlName: string, isManualInsertion: boolean = false): void {
        if (isManualInsertion && newValue?.target?.value) {
            newValue =
                /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$|^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])$/.test(
                    newValue.target.value
                )
                    ? this._formatStringToDate(newValue.target.value)
                    : undefined;
        } else {
            newValue = newValue.value;
        }
        if (newValue && newValue._d) {
            newValue = newValue._d;
        }
        if (newValue && newValue instanceof Date && !isNaN(newValue.getTime())) {
            const dateSelected: Date = newValue;
            switch (controlName) {
                case 'initialDate':
                    this.initialDate = new Date(dateSelected);
                    this.endDate = isManualInsertion ? this.endDate : undefined;
                    if (this.endDate && this.initialDate <= this.endDate) {
                        this.invalidDate.emit(false);
                        this.isDateInvalid = false;
                        this._emitDates();
                        this._setFormValues();
                    } else {
                        this.invalidDate.emit(true);
                        this.isDateInvalid = true;
                    }
                    break;
                case 'endDate':
                    this.endDate = new Date(dateSelected);
                    this._setFormValues();

                    // Emits dates if they are correct, else emits invalid date
                    if (this.initialDate && this.endDate && this.initialDate <= this.endDate) {
                        if (this.page === 'forecastsAggregation') {
                            this.limitDate = new Date(this.initialDate);
                            this.limitDate.setDate(this.initialDate.getDate() + 7);
                            if (this.endDate > this.limitDate) {
                                this.invalidDate.emit(true);
                                this.isDateInvalid = true;
                            } else {
                                this._emitDates();
                            }
                        } else {
                            this._emitDates();
                        }
                    } else {
                        this.invalidDate.emit(true);
                        this.isDateInvalid = true;
                    }
                    break;
                case 'uniqueDate':
                    this.uniqueDate = new Date(dateSelected);
                    this.select.emit({ newValue: this._getFormattedDataToSend(), name: this.id });
                    this.invalidDate.emit(false);
                    this.isDateInvalid = false;
                    break;
                default:
                    break;
            }
        } else {
            this.invalidDate.emit(true);
            this.isDateInvalid = true;
        }
    }

    // Sets date to display it with two digits in month and day fields
    protected getDateToDisplay(date: Date | undefined): Date | undefined {
        const dateCopy: string | undefined = date?.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        return dateCopy ? new Date(dateCopy) : undefined;
    }

    // Gets a string type date and returns it in Date type
    private _formatStringToDate(date: string): Date {
        const values: number[] = date.split(/-|\//).map((e: string) => Number.parseInt(e));
        return new Date(values[values[0] > 31 ? 0 : 2], values[1] - 1, values[values[0] > 31 ? 2 : 0]);
    }

    // Returns date range value as a string with format 'YYYY-MM-DD~YYYY-MM-DD' or unique date as a string with format 'YYYY-MM-DD'
    private _getFormattedDataToSend(): string {
        if (this.isDateRange && this.initialDate && this.endDate) {
            // Formats initialDate to YYYY-MM-DD
            let month: number | string = this.initialDate.getMonth() + 1;
            let day: number | string = this.initialDate.getDate();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            // Saves initialDate
            const formattedInitialDate: string = `${this.initialDate.getFullYear()}-${month}-${day}`;

            // Formats endDate to YYYY-MM-DD
            month = this.endDate.getMonth() + 1;
            day = this.endDate.getDate();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            // Returns in format YYYY-MM-DD
            return `${formattedInitialDate}~${this.endDate.getFullYear()}-${month}-${day}`;
        } else if (!this.isDateRange && this.uniqueDate) {
            let month: number | string = this.uniqueDate.getMonth() + 1;
            let day: number | string = this.uniqueDate.getDate();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            return `${this.uniqueDate.getFullYear()}-${month}-${day}`;
        } else {
            return '';
        }
    }

    private _setFormValues(): void {
        this.formGroup = new FormGroup({
            initialDate: new FormControl<Date | undefined>(this.initialDate),
            endDate: new FormControl<Date | undefined>(this.endDate),
        });
    }

    private _emitDates(): void {
        this.date.emit(this._getFormattedDataToSend());
        this.select.emit(this._getFormattedDataToSend());
        this.invalidDate.emit(false);
        this.isDateInvalid = false;
    }
}
