<div class="container" style="justify-content: flex-start">
    <div class="subfilter" *ngFor="let filtro of subfilters; let i = index">
        <app-filtro-date
            (select)="restarValue($event, i, filtro.key, filtro.type)"
            (select)="receiveDate($event, i)"
            *ngIf="filtro.type === 'Date' && showSelect"
            [name]="filtro.name"
        ></app-filtro-date>

        <app-filtro-select
            (select)="restarValue($event, i, filtro.key, filtro.type)"
            #selects
            *ngIf="
                filtro.name !== 'Tipo de proceso *' &&
                filtro.name !== 'Tipo de proceso' &&
                filtro.type === 'Select' &&
                showSelect &&
                filtro.name !== 'Usuario' &&
                filtro.name !== 'Sistema'
            "
            [toggleLabelLeft]="filtro.isToggle?.leftLabel"
            [toggleLabelRight]="filtro.isToggle?.rightLabel"
            [charactersControl]="filtro.isSearchable?.specialSearch"
            [searchByToggle]="filtro.isToggle?.active"
            [isSearchable]="filtro.isSearchable?.active"
            [filtroUrl]="filtro.data"
            [title]="filtro.name"
            [key]="filtro.key"
            [id]="filtro.id"
            (valueChange)="obtainOption($event)"
            (searchBy)="getFiltersBy($event)"
            (openSelect)="byOpenSelect($event)"
            (specialSearch)="specialSearch($event)"
        ></app-filtro-select>

        <app-filtro-select
            (select)="restarValue($event, i, filtro.key, filtro.type)"
            *ngIf="
                filtro.name !== 'Tipo de proceso *' &&
                filtro.name !== 'Tipo de proceso' &&
                filtro.type === 'Select' &&
                showSelect &&
                filtro.name === 'Usuario'
            "
            [toggleLabelLeft]="filtro.isToggle?.leftLabel"
            [toggleLabelRight]="filtro.isToggle?.rightLabel"
            [charactersControl]="filtro.isSearchable?.specialSearch"
            [searchByToggle]="filtro.isToggle?.active"
            [isSearchable]="filtro.isSearchable?.active"
            [filtroUrl]="usersList"
            [title]="filtro.name"
            [key]="filtro.key"
            [id]="filtro.id"
            (valueChange)="obtainOption($event)"
        ></app-filtro-select>

        <app-filtro-select
            #processType
            [disableSelect]="disableProcessTypeSelect"
            (select)="restarValue($event, i, filtro.key, filtro.type)"
            *ngIf="filtro.type === 'Select' && showSelect && filtro.name === 'Tipo de proceso *'"
            [toggleLabelLeft]="filtro.isToggle?.leftLabel"
            [toggleLabelRight]="filtro.isToggle?.rightLabel"
            [charactersControl]="filtro.isSearchable?.specialSearch"
            [searchByToggle]="filtro.isToggle?.active"
            [isSearchable]="filtro.isSearchable?.active"
            [filtroUrl]="processConceptsList"
            [title]="filtro.name"
            [key]="filtro.key"
            [id]="filtro.id"
            (valueChange)="obtainOption($event)"
        ></app-filtro-select>

        <div *ngIf="filtro.name === 'Sistema'">
            <mat-form-field appearance="standard" class="select">
                <mat-label>{{ ('PAGE.FILTERS.SYSTEM' | translate) + ' *' }}</mat-label>
                <mat-select *ngIf="showSelect">
                    <mat-option
                        [value]="'Medida'"
                        (click)="systemSelected('Medidas')"
                        [disabled]="!rolMedida && !rolAdmin && !rolQuery"
                        id="Medida"
                        name="Medida"
                    >
                        {{ 'PAGE.LOADS.MEASURES' | translate }}
                    </mat-option>
                    <mat-option
                        [value]="'Previsiones'"
                        (click)="systemSelected('Previsiones')"
                        [disabled]="!rolPrevision && !rolAdmin && !rolQuery"
                        id="Previsiones"
                        name="Previsiones"
                    >
                        {{ 'PAGE.LOADS.FORECAST' | translate }}
                    </mat-option>
                    <mat-option
                        [value]="'Conciliacion'"
                        (click)="systemSelected('Conciliacion')"
                        [disabled]="!rolConciliation && !rolAdmin && !rolQuery"
                        id="Conciliacion"
                        name="Conciliación"
                    >
                        {{ 'PAGE.LOADS.CONCILIATION' | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <app-filtro-input
            (select)="restarValue($event, i, filtro.key, filtro.type)"
            *ngIf="filtro.type === 'Input' && showSelect"
            [name]="filtro.name"
        >
        </app-filtro-input>
    </div>

    <div class="buttonsFilter">
        <div class="buttonsActionsDisplay"></div>
        <div class="buttonsFilterNoMargin">
            <button class="delete" (click)="resetFilters()" mat-button>
                {{ 'PAGE.ACTIONS.DELETE' | translate }}
                <app-base-icon iconName="filter_alt_off" class=""></app-base-icon>
            </button>
            <button
                class="search"
                [disabled]="isDisableButtonRequired()"
                (click)="loadTable()"
                mat-flat-button
                color="warn"
            >
                {{ 'PAGE.ACTIONS.FILTER' | translate | uppercase }}<app-base-icon iconName="search"> </app-base-icon>
            </button>
        </div>
    </div>
</div>
