import { Roles } from '../enums/roles.enum';
import { ItemMenu } from '../interfaces/itemMenu.interface';

export const InformesPage: ItemMenu = {
    id: '4',
    name: 'INFORMES',
    title: 'Datos Informes',
    route: 'informes',
    icon: 'summarize',
    subItems: [
        {
            id: '4.1',
            name: 'GENERATE',
            title: 'Generar informes',
            route: 'informes/generar',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.CONSULTA, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
        },
        {
            id: '4.2',
            name: 'QUERY',
            title: 'Consulta de informes',
            route: 'informes/consultar',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.CONSULTA, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
        },
    ],
    roles: [Roles.ADMIN, Roles.CONSULTA, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
};
