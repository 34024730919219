import { Roles } from '../enums/roles.enum';
import { ItemMenu } from '../interfaces/itemMenu.interface';

export const CargasPage: ItemMenu = {
    id: '2',
    name: 'CARGAS',
    title: 'Datos de Cargas',
    route: 'cargas',
    icon: 'bolt',
    subItems: [
        {
            id: '2.1',
            name: 'MANUAL',
            title: 'Cargas manuales',
            route: 'cargas/manual',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
        },
        {
            id: '2.2',
            name: 'RESULTS',
            title: 'Resultados de cargas',
            route: 'cargas/resultados',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.CONSULTA, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
        },
        {
            id: '2.3',
            name: 'FICTITIOUS_CONTRACTS',
            title: 'Contractos ficticios',
            route: 'cargas/contratos-ficticios',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.CONCILIACION],
        },
    ],
    roles: [Roles.ADMIN, Roles.CONSULTA, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
};
