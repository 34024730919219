import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaestroService } from 'src/app/pages/maestro/services/maestro.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-open-dialog',
    templateUrl: './open-dialog.component.html',
    styleUrls: ['./open-dialog.component.scss'],
})
export class OpenDialogComponent implements OnInit {
    @Input() value: string = '';
    @Input() id: string = '';
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() selectSelected: EventEmitter<any> = new EventEmitter();
    subcriptions: Subscription[] = [];
    cups: any;
    count: number;
    title: string;
    modeRead: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private maestroService: MaestroService
    ) {
        if (data.mode === 'read') {
            this.modeRead = true;
        }
    }

    ngOnInit(): void {
        this.count = 0;
    }

    ngOnDestroy(): void {
        this.subcriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    onNoClick(): void {
        if (this.modeRead) {
            this.data.newCups = this.data.newCups.toString();
            this.data.newCups = this.data.newCups.replace(',', ';');
            this.subcriptions.push(
                this.maestroService.checkCupsMock(this.data.newCups).subscribe((cupsV) => {
                    this.dialogRef.close({ event: 'save', data: cupsV });
                })
            );
        } else {
            this.dialogRef.close({ event: 'close', data: [] });
        }
    }

    checkCups(value: string) {
        while (value.indexOf('\n') !== -1) {
            value = value.replace('\n', ';');
        }
        while (value.indexOf(' ') !== -1) {
            value = value.replace(' ', '');
        }
        this.cups = value;
        this.subcriptions.push(
            this.maestroService.checkCups(this.cups).subscribe((cupsV) => {
                this.dialogRef.close({ event: 'save', data: cupsV });
            })
        );
    }
    save() {
        this.data.newCups = this.data.newCups.toString();
        this.data.newCups = this.data.newCups.replace(',', ';');
        this.subcriptions.push(
            this.maestroService.checkCupsMock(this.data.newCups).subscribe((cupsV) => {
                this.data.newCups = cupsV.split(';');
                this.dialogRef.close({ event: 'save', data: cupsV });
            })
        );
    }
    close() {
        this.dialogRef.close({ event: 'close', data: this.data });
    }
    remove(index: any) {
        this.data.newCups.splice(index, 1);
        let cupsFormated = this.data.newCups.toString();
        cupsFormated = cupsFormated.replace(/,/g, ';');
        this.maestroService.checkCupsMock(cupsFormated).subscribe((cupsV) => {
            this.maestroService.validCups = cupsFormated;
            cupsFormated = cupsV.split(';');
        });
    }

    emitValue(event: any) {
        this.select.emit(event);
    }

    emitValueNew(event: any) {
        this.selectSelected.emit({ newValue: event });
    }
}
