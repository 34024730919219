<app-select-cargas-res
    (OptionSelectedId)="receiveIdSelect($event)"
    [tipoConcepto]="'cargas-resultados'"
    (resetTable)="clearTable($event)"
    (OptionFilters)="receiveIdFilters($event)"
    (changeSelectReset)="reset()"
    (filtersList)="chargeFiltersInTable($event)"
>
</app-select-cargas-res>
<app-tabla-cargas
    [dataBusiness]="dataBusiness"
    [filtersTable]="filtersTable"
    [dataHeaders]="dataHeaders"
    [columns]="columns"
    [totalPages]="totalPages"
    [pageSize]="pageSize"
    [numPage]="numPage"
    [totalRegisters]="totalRegisters"
    [permissions]="permissions"
    [filters]="filters"
    [idFilters]="idFilters"
    [resetTableSubject]="resetTableSubject"
    (pagination)="pagination($event)"
    (sort)="sort($event, idFilters)"
>
</app-tabla-cargas>
