import { Roles } from '../enums/roles.enum';
import { ItemMenu } from '../interfaces/itemMenu.interface';

export const MaestroPage: ItemMenu = {
    id: '1',
    name: 'MAESTRO',
    title: 'Datos maestros',
    route: 'maestros',
    icon: 'dataset',
    subItems: [
        {
            id: '1.1',
            name: 'AWS_DATA',
            title: 'Datos maestros AWS',
            route: 'maestros/aws',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.CONSULTA, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
        },
        {
            id: '1.2',
            name: 'CRM_DATA',
            title: 'Datos maestros CRM',
            route: 'maestros/crm',
            icon: '',
            subItems: [],
            roles: [Roles.ADMIN, Roles.CONSULTA, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
        },
    ],
    roles: [Roles.ADMIN, Roles.CONSULTA, Roles.CONCILIACION, Roles.MEDIDA, Roles.PREVISION],
};
