<div class="modal-title-close">
    <h1 mat-dialog-title>{{ 'PAGE.MODALS.TITLE2' | translate }}</h1>
    <button mat-button mat-dialog-close class="hide-access close" (click)="close(false)">close</button>
</div>
<div mat-dialog-content>
    <ng-container *ngIf="!isFestivos && !isPreciosRegulados">
        <div *ngFor="let filtro of copyData.header; let i = index">
            <app-filtro-date
                *ngIf="filtro.filter === 'date'"
                [name]="filtro.label"
                [id]="filtro.id"
                [isDateRange]="false"
                (select)="valueChange($event)"
            ></app-filtro-date>
            <app-filtro-select
                *ngIf="filtro.filter === 'select'"
                (selectSelected)="valueChange($event)"
                [filtroUrl]="filtro.options || filtro.data"
                [title]="filtro.label"
                [id]="filtro.id"
            ></app-filtro-select>
            <app-filtro-input
                *ngIf="filtro.filter == 'text'"
                [name]="filtro.label"
                [id]="filtro.id"
                (select)="valueChange($event)"
            ></app-filtro-input>
        </div>
    </ng-container>
    <ng-container *ngIf="isPreciosRegulados">
        <div *ngFor="let filtro of copyData.header; let i = index">
            <app-filtro-date
                *ngIf="filtro.filter === 'date'"
                [name]="filtro.label"
                [id]="filtro.id"
                [isRequired]="true"
                [isDateRange]="false"
                (select)="valueChange($event)"
            ></app-filtro-date>
            <app-filtro-select
                *ngIf="filtro.filter === 'select'"
                (selectSelected)="valueChange($event)"
                [filtroUrl]="filtro.options || filtro.data"
                [title]="filtro.label"
                [id]="filtro.id"
                [required]="true"
            ></app-filtro-select>
            <app-filtro-input
                (keypress)="
                    (filtro.label === 'P1' ||
                    filtro.label === 'P2' ||
                    filtro.label === 'P3' ||
                    filtro.label === 'P4' ||
                    filtro.label === 'P5' ||
                    filtro.label === 'P6'
                        ? ''
                        : $event.charCode >= 48 && $event.charCode < 58)
                "
                type="number"
                *ngIf="filtro.filter == 'text'"
                [name]="filtro.label"
                [id]="filtro.id"
                (select)="valueChange($event)"
                [required]="filtro.label === 'P1' || filtro.label === 'P2' ? true : false"
            ></app-filtro-input>
        </div>
    </ng-container>
    <ng-container *ngIf="isFestivos">
        <div *ngFor="let filtro of copyDataMapped; let i = index">
            <app-filtro-date
                *ngIf="filtro.filter?.toLowerCase() === 'date'"
                [name]="filtro.label"
                [id]="filtro.id"
                [preloadedUniqueDate]="filtro.value"
                [isDateRange]="false"
                (select)="valueChange($event)"
            ></app-filtro-date>
            <app-filtro-select
                *ngIf="filtro.id === 'festiveType'"
                (selectSelected)="valueChange($event)"
                [filtroUrl]="filtro.data"
                [title]="filtro.label"
                [id]="filtro.id"
                [value]="filtro.value"
            ></app-filtro-select>
            <app-filtro-input
                *ngIf="filtro.filter?.toLowerCase() === 'text'"
                [name]="filtro.label"
                [id]="filtro.id"
                [value]="filtro.value"
                (select)="valueChange($event)"
            ></app-filtro-input>
            <app-filtro-select
                *ngIf="filtro.filter?.toLowerCase() === 'select' && filtro?.id !== 'festiveType' && isAutonomic"
                (selectSelected)="valueChange($event)"
                [filtroUrl]="filtro.data"
                [title]="filtro.label"
                [id]="filtro.id"
                [value]="filtro.value"
            ></app-filtro-select>
        </div>
    </ng-container>
</div>
<div *ngIf="isIncorrectDate && (!isFestivos || isPreciosRegulados)" class="incorrectDate">
    <h3 class="incorrectDateH3">
        {{ 'PAGE.MODALS.THE_END_DATE_MUST_BE_AFTER_THE_START_DATE' | translate }} ({{ dateInit }})
    </h3>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close class="secondary" (click)="close(false)">
        {{ 'PAGE.MODALS.CANCEL' | translate }}
    </button>
    <button
        mat-button
        mat-dialog-close
        mat-flat-button
        color="warn"
        class="primary"
        (click)="close(true)"
        [disabled]="emptyRequired"
        cdkFocusInitial
    >
        {{ 'PAGE.MODALS.ACCEPT' | translate }}
    </button>
</div>
