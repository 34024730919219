import {
    AfterContentChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MainFilterProgramar, ProcesosPage } from 'src/app/shared/models/procesos-page.data';
import { ProcesosService } from '../services/procesos.service';
import { MaestroService } from '../../maestro/services/maestro.service';
import { SelectProcesosComponent } from '../components/select-procesos/select-procesos.component';
import { FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CronOptions } from 'ngx-cron-editor';
import { RadioButtonsCron } from 'src/app/shared/enums/cron';
import { FiltroSelectComponent } from '../../../shared/components/filtros/filtro-select/filtro-select.component';
import { CustomCronComponent } from '../../../shared/components/custom-cron/custom-cron.component';
import * as moment from 'moment';
import { AlertModalComponent } from '../../../shared/components/alert-modal/alert-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Concepts, Mode, SistemasProcesosEnum } from 'src/app/shared/enums/procesos';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { InfoSchedule } from 'src/app/shared/models/procesos-programar';
import { HeaderTitleService } from '../../../services/header-title.service';
import { SistemaProcesosOption } from 'src/app/shared/interfaces/procesos.interface';
import { LoginService } from 'src/app/core/services/login.service';
import { Filtro, FiltroSelect, ListaFiltros } from 'src/app/shared/models/filtros.interface';
import { GlobalService } from 'src/app/services/global.service';
import { KeysMaestro } from 'src/app/shared/enums/maestros';
import { FiltroCupsComponent } from 'src/app/shared/components/filtros/filtro-cups/filtro-cups.component';
import { ProcesosProgramarHelper } from './utils/procesos-programar.utils';
import {
    ContractClientType,
    closeTypeCode,
    closeTypeId,
    closeTypeName,
    liqTypeEnum,
} from 'src/app/shared/enums/contractEnums';

const DEFAULT_CRON_EXPRESSION = `0 0 1/1 * ? *`;
const DATE_FORMAT_SPANISH = `DD-MM-YYYY`;
const DATE_FORMAT_ENGLISH = `YYYY-MM-DD`;
const NOTIFICATION_SHOWING_TIME = 4000;

@Component({
    selector: 'app-procesos-programar',
    templateUrl: './procesos-programar.component.html',
    styleUrls: ['./procesos-programar.component.scss'],
})
export class ProcesosProgramarComponent implements OnInit, AfterContentChecked {
    @Input() placeholderLabel: any = '';

    @Output() resetTable = new EventEmitter<any>();

    @ViewChild(SelectProcesosComponent) selectProcesosComponent: SelectProcesosComponent;
    @ViewChild(SelectProcesosComponent) matSelectMain: SelectProcesosComponent;
    @ViewChild(FiltroSelectComponent) selectFiltroSelect: FiltroSelectComponent;
    @ViewChild(CustomCronComponent) customCronComponent: CustomCronComponent;
    @ViewChild('repRangeInitDateInputRef') repRangeInitDateInputRef!: ElementRef<any>;
    @ViewChild('repRangeEndDateInputRef') repRangeEndDateInputRef!: ElementRef<any>;

    @ViewChildren('selects') selects: QueryList<FiltroSelectComponent>;
    @ViewChildren('filterCups') filterCups: QueryList<FiltroCupsComponent>;

    filteredBanks: ReplaySubject<FiltroSelect[]> = new ReplaySubject<FiltroSelect[]>(1);
    bankSearchCtrl: UntypedFormControl = new UntypedFormControl();
    formGroup = new UntypedFormGroup({});
    subfilters: Filtro[] | undefined;
    subcriptions: Subscription[] = [];
    conceptsControl = new FormControl('');
    reset$: Subject<void> = new Subject<void>();
    resetDate$: Subject<void> = new Subject<void>();
    changeDate$: Subject<void> = new Subject<void>();
    changeInitDate$: Subject<void> = new Subject<void>();
    changeEndDate$: Subject<void> = new Subject<void>();
    changeInitDateProjected$: Subject<void> = new Subject<void>();
    changeEndDateProjected$: Subject<void> = new Subject<void>();
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    filters: ListaFiltros[] = [];
    idFilters: any;
    infoFilters: InfoSchedule[] = [];
    selectedConcept: any;
    selectedConceptForFilters: any;
    selectedProgram: string | string[] = '';
    selectedProgramId: number;
    bodyToSend: any;
    mainFilter: MainFilterProgramar = {
        id: 'mainFilter',
        key: 'mainFilter',
        url: '',
        data: [],
        name: 'PAGE.FILTERS.SELECT.PROGRAM',
        type: 'Select',
        editableSelect: true,
        required: true,
    };
    // Dates de los filtros del body
    initDateFilter: string | null;
    endDateFilter: string | null;
    projectedInitDateFilter?: string;
    projectedEndDateFilter?: string;
    // Dates del body
    initDateBody: any;
    endDateBody: any;
    // Variable dates
    isVariableInitialDateRequired: boolean = false;
    isVariableEndDateRequired: boolean = false;

    cronForm = new FormControl();
    cronOptions: CronOptions;

    radioButtonSelected: any;
    radioButtons: RadioButtonsCron[] = [RadioButtonsCron.option1, RadioButtonsCron.option3];

    //Mostrar/ocultar el select de programación
    showSelectProgram: boolean = false;
    //Mostrar/ocultar el botón de programar
    showButtonProgram: boolean = false;
    //Mostrar/ocultar el input de programación
    showInputProgram: boolean = false;
    // Mostrar la label del Input
    showCreateLabel: boolean = false;
    // Mostrar la label del Input
    showDateFilter: boolean = false;
    // Booleano para diferenciar el crear del editar programacion
    isCreateMode: boolean = false;

    refilledFiltersToSend?: any;

    processConceptsList: any;
    filteredProcessConceptsList: any;
    userRoles: string[] = [];
    selectSystems: SistemaProcesosOption[] = [];
    isDisabled: boolean = true;
    tab: number;
    isOnlyQueryRole: boolean = false;
    selectSeleccionado: any;
    showSelect: boolean = false;
    showCreateProgram: boolean = false;
    virtualClosureList: any;
    formBuilder: FormBuilder;
    virtualClosureSelected: any;
    processCodeName: any;
    clientTypeList: any;
    idNumberList: any;
    charactersControl: boolean = false;
    subfilter: any;
    isInitialForecastsSelected = false;
    previsionesProyectadasSelected = false;
    cuentaNegociadoraActive: boolean = false;
    cuentaNegActive: boolean;
    eventvalue: any;
    inputValues: { id: string; value: string }[] = [];
    yearSelected: boolean = false;
    monthSelected: boolean = false;
    selectedYear: string | number;
    selectedMonth: string | number;
    selectId: any;
    intermedio: boolean = true;
    processCodePOST: any;
    nameProcessPOST: any;
    cups: any;
    filtersBodyPost: any;
    accountId: any;
    closeType: any;
    resetCups: boolean;
    society: any;
    clientType: any;
    negotiatingAccount: any;
    selectedSociety: any;
    selectedCups: any;
    selectedBusinessType: any;
    businessTypeList: any;
    endDateRange: string | undefined = '';
    initDateRange: string | undefined = '';
    repRangeInitDate: string = '';
    repRangeEndDate: string = '';
    initDateRangeCopy: string = '';
    endDateRangeCopy: string = '';
    monthYearDate: string = '';
    filtersName: string = '';
    electricSystemList: any = [''];
    unitProgramList: any;
    unitProgramListCopy: any;
    unitProgramsFormControl: any;
    optionValues: string[] = ['', ''];
    programUnitForm: FormControl;
    electricSystemForm: FormControl;
    closeTypeForm: FormControl;
    rateForm: FormControl;
    clientTypeForm: FormControl;
    liquidationTypeForm: FormControl;
    resultsForm: FormControl;
    allProgramUnits: any[] = [];
    defaultExecution: 'True' | 'False' = 'False';
    deleteModified: 'True' | 'False' = 'False';
    currentCRON: string = DEFAULT_CRON_EXPRESSION;
    isCRONIncorrect: boolean;

    get radioButtonsCron() {
        return RadioButtonsCron;
    }

    get concepts() {
        return Concepts;
    }

    constructor(
        public maestroService: MaestroService,
        protected readonly _headerTitleService: HeaderTitleService,
        private readonly _procesosService: ProcesosService,
        private readonly _translateService: TranslateService,
        private readonly _snackBar: MatSnackBar,
        private readonly _loginService: LoginService,
        private readonly _globalService: GlobalService,
        private readonly _matDialog: MatDialog
    ) {
        this.programUnitForm = new FormControl([]);
        this.electricSystemForm = new FormControl([]);
        this.closeTypeForm = new FormControl([]);
        this.rateForm = new FormControl([]);
        this.clientTypeForm = new FormControl([]);
        this.liquidationTypeForm = new FormControl([]);
        this.resultsForm = new FormControl([]);
        this.selects = new QueryList<FiltroSelectComponent>();
    }

    ngOnInit(): void {
        this.isOnlyQueryRole = false;
        if (ProcesosPage.subItems.length === 1) {
            this._headerTitleService.currentHeaderTranslationCode = 'PROCESS_LAUNCH';
        } else {
            this._headerTitleService.currentHeaderTranslationCode = 'PROCESS_PROGRAMMING';
        }
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);

        this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
        this.userRoles = this.userRoles.map((element: any) => element.trim());
        if (this.userRoles.length === 1 && this.userRoles[0] === 'CONSULTA') {
            this.isOnlyQueryRole = true;
        }

        this.selectSystems = [
            {
                id: 1,
                name: SistemasProcesosEnum.MEDIDAS,
                translation: 'MEASURES',
            },
            {
                id: 2,
                name: SistemasProcesosEnum.CIERRES,
                translation: 'CLOUSURES',
            },
            {
                id: 3,
                name: SistemasProcesosEnum.PREVISIONES,
                translation: 'FORECAST',
            },
        ];
        this._populateSystemsByUserRoles();

        // Nos traemos todos los conceptos de Procesos
        this._procesosService.getProcessConcepts().subscribe((processConcepts) => {
            const processes: any = [];
            processConcepts.data.forEach((process: any) => {
                const firstChar = process.processCode.substring(0, 1);
                if (firstChar !== 'A') {
                    process.name = process.name.charAt(0).toUpperCase() + process.name.slice(1).toLowerCase();
                    processes.push(process);
                }
            });
            this.processConceptsList = processes;
            this._populateConceptsByUserRoles();
        });

        // SelectId
        this.maestroService.selectId = 'procesosProgramar';

        // Cron Options
        this.cronForm = new FormControl(DEFAULT_CRON_EXPRESSION);
        this.cronOptions = {
            defaultTime: '00:00:00',
            hideMinutesTab: false,
            hideHourlyTab: false,
            hideDailyTab: false,
            hideWeeklyTab: false,
            hideMonthlyTab: false,
            hideYearlyTab: false,
            hideAdvancedTab: true,
            hideSpecificWeekDayTab: false,
            hideSpecificMonthWeekTab: false,
            use24HourTime: true,
            hideSeconds: true,
            //standard or quartz
            cronFlavor: 'standard',
        };

        this._getFilters();

        this.formGroup.addControl(
            'electricSystem',
            new UntypedFormGroup({
                value: new UntypedFormControl(undefined),
            })
        );
        this.formGroup.addControl(
            'unitProgram',
            new UntypedFormGroup({
                value: new UntypedFormControl(undefined),
            })
        );
        this.formGroup.addControl(
            'rate',
            new UntypedFormGroup({
                value: new UntypedFormControl(undefined),
            })
        );
        this.formGroup.addControl(
            'liquidationType',
            new UntypedFormGroup({
                value: new UntypedFormControl(undefined),
            })
        );
        this.formGroup.addControl(
            'resultados',
            new UntypedFormGroup({
                value: new UntypedFormControl(undefined),
            })
        );
        this.formGroup.addControl(
            'virtualClosure',
            new UntypedFormGroup({
                value: new UntypedFormControl(undefined),
            })
        );
        this.formGroup.addControl(
            'clientType',
            new UntypedFormGroup({
                value: new UntypedFormControl(undefined),
            })
        );
        this.formGroup.addControl(
            'accountId',
            new UntypedFormGroup({
                value: new UntypedFormControl(undefined),
            })
        );
        this.formGroup.addControl(
            'idNumber',
            new UntypedFormGroup({
                value: new UntypedFormControl(undefined),
            })
        );
    }

    ngAfterContentChecked(): void {
        if (this.repRangeInitDateInputRef) {
            this.repRangeInitDateInputRef.nativeElement.value = this.repRangeInitDate;
        }
        if (this.repRangeEndDateInputRef) {
            this.repRangeEndDateInputRef.nativeElement.value = this.repRangeEndDate;
        }
    }

    onChangeUpdateConcept($event: any) {
        this._resetData();
        this._procesosService.dates = '';
        this.showSelect = ProcesosProgramarHelper.checkDateFormat(
            this.refilledFiltersToSend?.processCalculate?.initDate
        );
        this.showSelect = ProcesosProgramarHelper.checkDateFormat(
            this.refilledFiltersToSend?.processCalculate?.endDate
        );
        this.selectedConcept = $event.value;
        this.selectedConceptForFilters = this.selectedConcept;
        this.selectFiltroSelect?.bankCtrl?.reset();
        this.showSelectProgram = true;
        this.showButtonProgram = true;
        this.showInputProgram = false;
        this._setVariableDateFilterAsRequired();
    }

    onClickLoadOptions(event: any) {
        if (event.newValue) {
            if (event.key === KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA) {
                const subfilter = this.subfilters?.find(
                    (f: any) =>
                        f.key === KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA ||
                        f.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA
                );
                if (subfilter) {
                    subfilter.data = [];
                }
            } else if (event.key === KeysMaestro.SOCIEDADES) {
                const subfilter = this.subfilters?.find(
                    (f: any) => f.key === KeysMaestro.SOCIEDADES || f.key === KeysMaestro.CONTRACT_SOCIEDADES
                );
                if (subfilter) {
                    subfilter.data = [];
                }
            } else if (event.key === KeysMaestro.CUENTA_NEGOCIADORA_GET) {
                const subfilter = this.subfilters?.find((f: any) => f.key === KeysMaestro.CUENTA_NEGOCIADORA_GET);
                if (subfilter) {
                    subfilter.data = [];
                    this.maestroService.getNegotiatingAccounts().subscribe((neAccounts) => {
                        neAccounts.data.forEach((element: any) => {
                            subfilter.data.push({
                                name: element.negotiatingAccountCode,
                            });
                        });
                    });
                }
            }
            this.mainFilter.data = [];
            this._procesosService
                .getAllLaunchedProcess(this.selectedConcept.processCode)
                .subscribe((processes: any) => {
                    if (processes) {
                        processes.data.forEach((element: any) => {
                            if (element.name !== null && element.cron) {
                                element.cron = ProcesosProgramarHelper.convertCronToUTC(element.cron);
                                this.mainFilter.data.push({ ...element });
                            }
                        });
                    }
                });
        }
        if (
            event.key !== KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA &&
            this.selectFiltroSelect.filtroUrl &&
            this.selectFiltroSelect.filtroUrl.length > 0
        ) {
            this._setSubfilters(
                this.selectFiltroSelect.filtroUrl.find((filter: any) => filter.launchId === this.selectedProgramId)
            );
        }
    }

    onClickSelectScheduleOption(event: any) {
        this.selectSeleccionado = {};
        if (this.refilledFiltersToSend && this.refilledFiltersToSend.processCalculate) {
            this.refilledFiltersToSend.processCalculate = {};
        }
        this.initDateFilter = '';
        this.endDateFilter = '';
        this.initDateRange = '';
        this.endDateRange = '';
        this.selectedProgram = event.split('=')[1];
        this.selectedConceptForFilters = this.selectedConcept;
        if (
            typeof this.selectedProgram === 'string' &&
            this.mainFilter.data.some((filter: any) => filter.name === this.selectedProgram)
        ) {
            this.showDateFilter = true;
        } else if (!this.showCreateProgram) {
            this.showDateFilter = false;
        }
        this.resetDate$.next();
        this._checkFilterButtonStatus();
    }

    onSelectLoadProcessScheduleInfo(event: any) {
        if (event && event.launchId && this.mainFilter?.data?.length > 0) {
            this._procesosService.selectedProcess = JSON.parse(
                this.mainFilter.data.find((process: any) => process.launchId === event.launchId)?.filters
            );
            if (this._procesosService.selectedProcess?.processCalculate) {
                this._procesosService.selectedProcess = JSON.parse(
                    this._procesosService.selectedProcess.processCalculate
                );
            }
        } else {
            this._procesosService.selectedProcess = undefined;
        }
        this.accountId = '';
        this.subfilters?.forEach((filter: any) => {
            if (filter.key === 'accountId') {
                filter.data = undefined;
            }
            filter.selected = undefined;
        });
        if (this.filters !== undefined) {
            const dato = this.filters.findIndex((filter: any) => filter.name === this.filtersName);
            if (this.filters[dato]) {
                this.filters[dato].filters.forEach((filter: any) => {
                    if (filter.key === 'accountId') {
                        filter.data = undefined;
                    }
                    filter.selected = undefined;
                });
            }
        }

        const filtersJSON = JSON.parse(event?.filters);
        if (event?.filters) {
            this.resetDate$.next();
            this.initDateFilter = '';
            this.endDateFilter = '';
            this.formGroup.controls.clientType.get('value')!.setValue(null);
            let filtersParsed;
            if (filtersJSON.processCalculate) {
                filtersParsed = JSON.parse(filtersJSON.processCalculate);
            } else {
                filtersParsed = typeof filtersJSON === 'string' ? JSON.parse(filtersJSON) : filtersJSON;
            }
            if (filtersParsed.dates && filtersParsed.dates !== '') {
                this.changeDate$.next(filtersParsed.dates);
            } else {
                filtersParsed.dates = '';
                this.changeDate$.next(filtersParsed.dates);
            }
            if (filtersParsed.initDateFilter && filtersParsed.initDateFilter !== '') {
                this.initDateFilter = filtersParsed.initDateFilter;
            }
            if (filtersParsed.initDate && filtersParsed.initDate !== '') {
                this.changeInitDate$.next(filtersParsed.initDate);
            } else {
                filtersParsed.initDate = '';
                this.changeInitDate$.next(filtersParsed.initDate);
            }
            if (filtersParsed.endDate && filtersParsed.endDate !== '') {
                this.changeEndDate$.next(filtersParsed.endDate);
            } else {
                filtersParsed.endDate = '';
                this.changeEndDate$.next(filtersParsed.endDate);
            }
            if (filtersParsed.measureInitDate && filtersParsed.measureInitDate !== '') {
                this.changeInitDate$.next(filtersParsed.measureInitDate);
            } else if (!filtersParsed.initDate || filtersParsed.initDate === '') {
                filtersParsed.measureInitDate = '';
                this.changeInitDate$.next(filtersParsed.measureInitDate);
            }
            if (filtersParsed.measureEndDate && filtersParsed.measureEndDate !== '') {
                this.changeEndDate$.next(filtersParsed.measureEndDate);
            } else if (!filtersParsed.endDate || filtersParsed.endDate === '') {
                filtersParsed.measureEndDate = '';
                this.changeEndDate$.next(filtersParsed.measureEndDate);
            }
            if (filtersParsed.forecastInitDate && filtersParsed.forecastInitDate !== '') {
                this.changeInitDateProjected$.next(filtersParsed.forecastInitDate);
            } else {
                filtersParsed.forecastInitDate = '';
                this.changeInitDateProjected$.next(filtersParsed.forecastInitDate);
            }
            if (filtersParsed.forecastEndDate && filtersParsed.forecastEndDate !== '') {
                this.changeEndDateProjected$.next(filtersParsed.forecastEndDate);
            } else {
                filtersParsed.forecastEndDate = '';
                this.changeEndDateProjected$.next(filtersParsed.forecastEndDate);
            }
        } else {
            this.resetDate$.next();
        }
        this.monthYearDate = '';
        if (filtersJSON) {
            this.monthYearDate = filtersJSON.processCalculate
                ? JSON.parse(filtersJSON.processCalculate).date
                : filtersJSON.date;
        }
        this._populateFilters(event);
        this.showInputProgram = true;
        this.showCreateProgram = false;
        this.showSelectProgram = true;
        this.showButtonProgram = true;
        // Ocultamos el label de crear y mostramos el otro
        this.showCreateLabel = false;
        // Programación seleccionada
        this.selectedProgram = event.name;
        this.selectedProgramId = event.launchId;
        this._getSubfilters(event);
        this._checkFilterButtonStatus();
    }

    onChangeUpdateSelectedSchedule(event: any) {
        this.selectedProgram = event;
    }

    onClickCreateNewProcessSchedule() {
        this.cronForm.reset();
        this.showDateFilter = true;
        this.cronForm = new FormControl(DEFAULT_CRON_EXPRESSION);
        this.currentCRON = DEFAULT_CRON_EXPRESSION;
        this.isCreateMode = true;
        // Booleanos para controlar si mostrar el input de texto o el select
        this.showInputProgram = true;
        this.showSelectProgram = false;
        this.showButtonProgram = false;
        this.showCreateProgram = true;
        // Mostramos la label del Input
        this.showCreateLabel = true;
        // Reiniciamos el select de programaciones
        this.selectFiltroSelect?.bankCtrl?.reset();
        // Reseteamos el valor de la programación
        this.selectedProgram = '';
        this.initDateRange = '';
        this.endDateRange = '';
        this.projectedInitDateFilter = '';
        this.projectedEndDateFilter = '';
        this.initDateFilter = '';
        this.endDateFilter = '';
        this.repRangeInitDate = '';
        this.repRangeEndDate = '';
        this.closeType = '';
        this.virtualClosureSelected = '';
        this._procesosService.dates = '';
        this.subfilters = [];
        this.infoFilters = [];
        this.cronForm.reset();
        this.showSelect = false;
        this.radioButtonSelected = undefined;
        this.monthYearDate = '';
        this.repRangeInitDate = '';
        this.repRangeEndDate = '';
        this.initDateRangeCopy = '';
        this.endDateRangeCopy = '';
        this.projectedInitDateFilter = '';
        this.projectedEndDateFilter = '';
        this.isVariableInitialDateRequired = false;
        this.isDisabled = true;
        this.infoFilters = [];
        this.mainFilter.data = [];
        this._procesosService.dates = '';
        this.initDateFilter = '';
        this.endDateFilter = '';
        this.initDateRange = undefined;
        this.endDateRange = undefined;
        this.projectedInitDateFilter = undefined;
        this.projectedEndDateFilter = undefined;
        this.accountId = '';
        this.negotiatingAccount = '';
        this.clientType = '';
        this.cups = '';
        this.formGroup.controls.accountId.get('value')!.setValue(null);
        this.formGroup.controls.clientType.get('value')!.setValue(null);
        this.formGroup.controls.electricSystem.get('value')!.setValue(null);
        this.formGroup.controls.idNumber.get('value')!.setValue(null);
        this.formGroup.controls.liquidationType.get('value')!.setValue(null);
        this.formGroup.controls.rate.get('value')!.setValue(null);
        this.formGroup.controls.resultados.get('value')!.setValue(null);
        this.formGroup.controls.unitProgram.get('value')!.setValue(null);
        this.formGroup.controls.virtualClosure.get('value')!.setValue(null);
        this.bodyToSend = [];
        this.defaultExecution = 'False';
        this.deleteModified = 'False';
        this.filtersBodyPost = '';
        this.maestroService.validCups = '';
        this.inputValues = [];

        this.filters[this.filters.findIndex((filter: any) => filter.name === this.filtersName)]?.filters?.forEach(
            (filter: any) => {
                filter.selected = undefined;
            }
        );
        this.resetDate$.next();
        this._getSubfilters(this.conceptsControl);
        this.subfilters = this.subfilters.map((fil: any) => {
            fil.selected = undefined;
            if (
                fil.key === 'accountId' ||
                fil.key === 'contract.idNumber' ||
                fil.key === 'cups' ||
                fil.key === 'society'
            ) {
                fil.data = undefined;
            }
            return fil;
        });
        if (
            this.filterCups.first &&
            this.filterCups.first.listCups &&
            this.filterCups.first.newCups &&
            this.filterCups.first.lengthCups
        ) {
            this.filterCups.first.listCups = [''];
            this.filterCups.first.newCups = '';
            this.maestroService.validCups = [''];
            this.filterCups.first.lengthCups = 0;
        }
        this.infoFilters = [];
        this.mainFilter.data = [];
        this.refilledFiltersToSend = [];
        this.selectSeleccionado = [];
        this.selectedCups = [];
        this.selectedSociety = '';
        this._procesosService.selectedProcess = undefined;
        this.resetDate$.next();
        this.unitProgramsFormControl = [];
        if (this.programUnitForm) {
            this.programUnitForm.reset();
        }
        if (this.rateForm) {
            this.rateForm.reset();
        }
        if (this.electricSystemForm) {
            this.electricSystemForm.reset();
        }
        if (this.closeTypeForm) {
            this.closeTypeForm.reset();
        }
        if (this.clientTypeForm) {
            this.clientTypeForm.reset();
        }
        if (this.liquidationTypeForm) {
            this.liquidationTypeForm.reset();
        }
        if (this.resultsForm) {
            this.resultsForm.reset();
        }
        this.reset$.next();
    }

    onClickCancelCreateOrEditProcess() {
        const copyData = this.mainFilter.data;
        this._procesosService.dates = '';
        this.initDateFilter = '';
        this.endDateFilter = '';
        this.initDateRange = '';
        this.endDateRange = '';
        this.projectedInitDateFilter = '';
        this.projectedEndDateFilter = '';
        this.isDisabled = true;
        this.isCreateMode = false;
        this.showInputProgram = false;
        this.showSelectProgram = true;
        this.showButtonProgram = true;
        this.selectedConceptForFilters = this.selectedConcept;
        this.selectFiltroSelect?.bankCtrl?.reset();
        this.showInputProgram = false;
        this.selectedProgram = '';
        this.mainFilter.data = [];
        this.mainFilter.data = copyData;
        this.conceptsControl.reset();
        this.subfilters = [];
        this.infoFilters = [];
        this._resetData();
    }

    onClickSetActiveTab(evt: number) {
        this.tab = evt;
    }

    onClickOpenModal() {
        const dialogRef = this._matDialog.open(AlertModalComponent, {
            data: { title: 'PAGE.MODALS.TITLE15', text: 'PAGE.MODALS.TEXT_TITLE_6' },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._buildScheduleBody();
            }
        });
    }

    protected deleteProcess(processId: any): void {
        // Confirmation modal
        const dialogRef = this._matDialog.open(AlertModalComponent, {
            data: { title: 'PAGE.MODALS.TITLE17', text: 'PAGE.MODALS.TEXT_TITLE_8' },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this._procesosService.deleteProcess(processId).subscribe((resp: any) => {
                    //TODO: cuando esté Back preparado habrá que controlar casos de errores desde aquí y actualizar la lista con otro get
                    if (resp && resp.status && resp.status === 'OK') {
                        this._loadNotification(false);
                    } else {
                        this._loadNotification(true, 'NOT_DELETED_SCHEDULE_PROCESS');
                    }
                });
            }
        });
    }

    onSelectChangeDateValue($event: any) {
        this.initDateRange = $event.split('~')[0];
        this.endDateRange = $event.split('~')[1];
        this._checkFilterButtonStatus();
    }

    onCompleteResetCups($event: any) {
        this.resetCups = $event;
    }

    onToggleChangeSelectRangeDatesVisibility(): void {
        if (!this.showSelect) {
            this.showSelect = true;
        } else {
            this.showSelect = false;
        }
        this.initDateRange = '';
        this.endDateRange = '';
        this.initDateFilter = '';
        this.endDateFilter = '';
        this.monthYearDate = '';
        this._checkFilterButtonStatus();
    }

    onClickSelectOption(option: any, name: string) {
        let subfilter = this.subfilters?.find((f: any) => f.key === name);
        if (subfilter) {
            subfilter.selected = option.id ? option.id : option.name;
            this.closeType = this.subfilters?.find((f: any) => f.key === 'closeType')?.selected;
            this.clientType = this.subfilters?.find((f: any) => f.key === 'clientType')?.selected;
            this._checkFilterButtonStatus();
            if (name === 'electricSystem') {
                this.unitProgramList = this.unitProgramListCopy.filter(
                    (filter: any) => filter.sendSystem === option.name
                );
            }
        }
    }

    onChangeSetOptionSelected(event: { id: string; value: string }, valueCuentNeg: any) {
        if (this.isInitialForecastsSelected || this.previsionesProyectadasSelected) {
            if (event.value) {
                //Aquí esta el valor del select de cuenta negociadora
                this.cuentaNegociadoraActive = true;
                this.eventvalue = event.value;
            }
            this.cuentaNegActive = true;
            if (valueCuentNeg) {
                this.cuentaNegociadoraActive = true;
            }
        }
        if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }
    }

    onSelectRestartValue(event: any, filtro: any, key: string, filtroType?: any) {
        const eventValue = event.newValue || event.value;

        if (filtroType === 'SelectMonth' || filtroType === 'SelectYear') {
            if (this.subfilters !== undefined && eventValue) {
                if (filtroType === 'SelectMonth') {
                    this.monthSelected = true;
                    this.selectedMonth = eventValue;
                } else if (filtroType === 'SelectYear') {
                    this.yearSelected = true;
                    this.selectedYear = eventValue;
                }
                this.subfilters[filtro].selected = eventValue;
            }
        } else {
            this._formatSelectedValue(event, filtro, key, filtroType);
            if (this.subfilters !== undefined && eventValue) {
                this.subfilters[filtro].selected = eventValue;
            }
            this.intermedio = true;
        }
        this.resetTable.emit();
    }

    onKeyPressPerformSpecialSearch(event: any) {
        if (event.key === KeysMaestro.SOCIEDADES || event.key === KeysMaestro.CONTRACT_SOCIEDADES) {
            let subfilter = this.subfilters?.find(
                (f: any) => f.key === KeysMaestro.SOCIEDADES || f.key === KeysMaestro.CONTRACT_SOCIEDADES
            );
            this.maestroService
                .getClients({
                    search: event.search,
                    mode: subfilter?.toggleState ? 'cif' : 'description',
                })
                .subscribe((client) => {
                    if (subfilter) {
                        subfilter.data = [];
                    }
                    client.data.forEach((element: any) => {
                        if (subfilter && subfilter.toggleState === true) {
                            subfilter.data.push({ name: element.idNumber });
                        } else if (subfilter) {
                            subfilter.data.push({ name: element.clientName });
                        }
                    });
                });
        } else if (event.key === KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA) {
            let subfilter = this.subfilters?.find((f: any) => f.key === KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA);
            this.maestroService
                .getNegotiatingAccounts({
                    search: event.search,
                    mode: subfilter?.toggleState ? 'negotiatingAccountCode' : 'negotiatingAccountDescription',
                })
                .subscribe((neAccounts) => {
                    if (subfilter) {
                        subfilter.data = [];
                    }
                    neAccounts.data.forEach((element: any) => {
                        if (subfilter && subfilter.toggleState === true) {
                            subfilter.data.push({
                                name: element.negotiatingAccountCode,
                            });
                        } else if (subfilter) {
                            subfilter.data.push({
                                name: element.negotiatingAccountDescription,
                            });
                        }
                    });
                });
        }
    }

    setInitialDate($event: any): void {
        if ($event !== undefined && typeof $event === 'object') {
            $event = $event.format(DATE_FORMAT_SPANISH);
        }
        if (
            this.selectedConcept?.processCode === Concepts.MEDIDAS_OBJECIONES ||
            this.selectedConcept?.processCode === Concepts.ANALISIS_RESULTADOS
        ) {
            this.monthYearDate = $event;
        } else {
            this.initDateFilter = $event;
            this.initDateRange = $event;
        }
        this._checkFilterButtonStatus();
    }

    setEndDate($event: any): void {
        if ($event !== undefined && typeof $event === 'object') {
            $event = $event.format(DATE_FORMAT_SPANISH);
        }
        this.endDateFilter = $event;
        this.endDateRange = $event;
        this._checkFilterButtonStatus();
    }

    setProjectedInitDate($event: any): void {
        if ($event !== undefined && typeof $event === 'object') {
            $event = $event.format(DATE_FORMAT_SPANISH);
        }
        this.projectedInitDateFilter = $event;
        this._checkFilterButtonStatus();
    }

    setProjectedEndDate($event: any): void {
        if ($event !== undefined && typeof $event === 'object') {
            $event = $event.format(DATE_FORMAT_SPANISH);
        }
        this.projectedEndDateFilter = $event;
        this._checkFilterButtonStatus();
    }

    getInitDateValue(event: any) {
        this.initDateBody = moment(event.value).format(DATE_FORMAT_ENGLISH);
        this.repRangeInitDate = this.initDateBody;
        if (this.repRangeInitDateInputRef?.nativeElement?.value) {
            this.repRangeInitDateInputRef.nativeElement.value = this.initDateBody;
        }
    }

    getEndDateValue(event: any) {
        this.endDateBody = moment(event.value).format(DATE_FORMAT_ENGLISH);
        this.repRangeEndDate = this.endDateBody;
        if (this.repRangeEndDateInputRef?.nativeElement?.value) {
            this.repRangeEndDateInputRef.nativeElement.value = this.endDateBody;
        }
    }

    private _buildScheduleBody() {
        this.closeType = this._getCloseTypeCode(this.closeType);
        if (this.initDateRange?.includes('undefined')) {
            this.initDateRange = this.initDateRange.replaceAll('undefined', '');
            this.initDateRange =
                this.initDateRange.charAt(this.initDateRange.length - 1) === '-'
                    ? this.initDateRange.substring(0, this.initDateRange.length - 1)
                    : this.initDateRange;
        }
        if (this.endDateRange?.includes('undefined')) {
            this.endDateRange = this.endDateRange.replaceAll('undefined', '');
            this.endDateRange =
                this.endDateRange.charAt(this.endDateRange.length - 1) === '-'
                    ? this.endDateRange.substring(0, this.endDateRange.length - 1)
                    : this.endDateRange;
        }
        // Cargamos los filtros
        this.bodyToSend = this._populateBodyData(this.selectedConceptForFilters.processCode);
        // Parseamos los filtros
        if (this.bodyToSend) {
            let filtersParsed = JSON.parse(this.bodyToSend?.filters);
            if (this.selectedConcept?.processCode === Concepts.PREVISIONES_PROYECTADAS) {
                if (this.initDateFilter) {
                    delete filtersParsed['initDate'];
                    filtersParsed.measureInitDate = this.initDateFilter;
                }
                if (this.endDateFilter) {
                    delete filtersParsed['endDate'];
                    filtersParsed.measureEndDate = this.endDateFilter;
                }
            } else if (
                this.selectedConcept?.processCode === Concepts.MEDIDAS_OBJECIONES ||
                this.selectedConcept?.processCode === Concepts.ANALISIS_RESULTADOS
            ) {
                if (filtersParsed.date) {
                    this.monthYearDate = filtersParsed.date;
                }
            } else {
                if (this.initDateFilter) {
                    filtersParsed.initDate =
                        this.initDateRange && this.initDateRange !== '' ? this.initDateRange : this.initDateFilter;
                    filtersParsed.initDate = ProcesosProgramarHelper.parseStaticOrVariableDate(filtersParsed.initDate, DATE_FORMAT_ENGLISH);
                }
                if (this.endDateFilter) {
                    filtersParsed.endDate =
                        this.endDateRange && this.endDateRange !== '' ? this.endDateRange : this.endDateFilter;
                    filtersParsed.endDate = ProcesosProgramarHelper.parseStaticOrVariableDate(filtersParsed.endDate, DATE_FORMAT_ENGLISH);
                }
            }
            if (this.selectedConcept?.processCode === Concepts.PREVISIONES_PROYECTADAS) {
                if (this.projectedInitDateFilter) {
                    filtersParsed.forecastInitDate = this.projectedInitDateFilter;
                }
                if (this.projectedEndDateFilter) {
                    filtersParsed.forecastEndDate = this.projectedEndDateFilter;
                }
            }
            if (filtersParsed.initDate === '') {
                if (this.initDateBody) {
                    filtersParsed.initDate =
                        this.initDateRange && this.initDateRange !== '' ? this.initDateRange : filtersParsed.initDate;
                    filtersParsed.initDate = ProcesosProgramarHelper.parseStaticOrVariableDate(filtersParsed.initDate, DATE_FORMAT_ENGLISH);
                } else {
                    filtersParsed.initDate =
                        this.initDateRange && this.initDateRange !== ''
                            ? this.initDateRange
                            : moment(Date.now()).format(DATE_FORMAT_ENGLISH);
                }
            }
            if (filtersParsed.endDate === '') {
                if (this.endDateBody) {
                    filtersParsed.endDate =
                        this.endDateRange && this.endDateRange !== '' ? this.endDateRange : filtersParsed.endDate;
                    filtersParsed.endDate = ProcesosProgramarHelper.parseStaticOrVariableDate(filtersParsed.endDate, DATE_FORMAT_ENGLISH);
                } else {
                    filtersParsed.endDate =
                        this.endDateRange && this.endDateRange !== ''
                            ? this.endDateRange
                            : moment(Date.now()).format(DATE_FORMAT_ENGLISH);
                }
            }
            this.subfilters?.forEach((fil: any) => {
                if (
                    fil.selected !== undefined &&
                    (fil.key === 'unitProgram' ||
                        fil.key === 'programUnit' ||
                        fil.key === 'rate' ||
                        fil.key === 'clientType' ||
                        fil.key === 'resultados' ||
                        fil.key === 'results' ||
                        fil.key === 'closeType' ||
                        fil.key === 'cups' ||
                        fil.key === 'liquidationType')
                ) {
                    if (fil.key !== 'resultados' && fil.key !== 'results') {
                        filtersParsed[fil.key] = fil.selected;
                    } else {
                        filtersParsed[fil.key] = fil.selected.id;
                    }
                } else if (fil.key === 'defaultExecution') {
                    filtersParsed.defaultExecution = this.defaultExecution;
                } else if (fil.key === 'deleteModified') {
                    filtersParsed.deleteModified = this.deleteModified;
                }
            });
            if (filtersParsed.unitProgram && filtersParsed.unitProgram.length === 0) {
                delete filtersParsed.unitProgram;
            }
            if (filtersParsed.programUnit && filtersParsed.programUnit.length === 0) {
                delete filtersParsed.programUnit;
            }
            if (filtersParsed.closeType) {
                if (filtersParsed.closeType === closeTypeName.CCH_NAME) {
                    filtersParsed.closeType = closeTypeCode.CCH_CODE;
                } else if (filtersParsed.closeType === closeTypeName.CH_NAME) {
                    filtersParsed.closeType = closeTypeCode.CCH_CODE;
                } else if (filtersParsed.closeType === closeTypeName.ATR_NAME) {
                    filtersParsed.closeType = closeTypeCode.ATR_CODE;
                } else if (filtersParsed.closeType === closeTypeName.MIXTO_NAME) {
                    filtersParsed.closeType = closeTypeCode.MIXTO_CODE;
                }
            }
            if (filtersParsed.cups) {
                filtersParsed.cups =
                    typeof filtersParsed.cups === 'string' ? filtersParsed.cups.split(';') : filtersParsed.cups;
                filtersParsed.cups = filtersParsed.cups.filter(
                    (cups: any) => cups && cups.length > 0 && cups !== 'undefined'
                );
                if (filtersParsed.cups.length === 0) {
                    delete filtersParsed.cups;
                }
            }
            if (filtersParsed.clientType) {
                filtersParsed.clientType =
                    filtersParsed.clientType === ContractClientType.GRANDES_CUENTAS ? [1, 2, 3, 4, 5] : [6];
            }
            if (filtersParsed.liquidationType) {
                filtersParsed.liquidationType =
                    filtersParsed.liquidationType === liqTypeEnum.MEDIA_HORARIA ||
                    filtersParsed.liquidationType === liqTypeEnum.MEDIA_HORARIA_CODE
                        ? liqTypeEnum.MEDIA_HORARIA_STRING
                        : liqTypeEnum.FACTURA_ATR_STRING;
            }
            if (
                this.selectedConcept?.processCode === Concepts.MEDIDAS_OBJECIONES ||
                this.selectedConcept?.processCode === Concepts.ANALISIS_RESULTADOS
            ) {
                if (filtersParsed.resultados) {
                    filtersParsed.results = filtersParsed.resultados;
                    delete filtersParsed['resultados'];
                }
            }
            if (
                this.selectedConcept?.processCode === Concepts.MEDIDAS_OBJECIONES ||
                this.selectedConcept?.processCode === Concepts.ANALISIS_RESULTADOS
            ) {
                if (this.monthYearDate) {
                    if (this.monthYearDate.includes('m')) {
                        filtersParsed.month = this.monthYearDate.substring(0, this.monthYearDate.indexOf('m') + 1);
                    }
                    if (this.monthYearDate.includes('y')) {
                        filtersParsed.year = this.monthYearDate.substring(
                            this.monthYearDate.indexOf('y') - 2,
                            this.monthYearDate.length
                        );
                    }
                }
            }
            debugger;
            this.bodyToSend.filters = JSON.stringify(filtersParsed).replaceAll('"', '"');
            // Operaciones con CRON
            let cron = DEFAULT_CRON_EXPRESSION;
            if (this.currentCRON !== DEFAULT_CRON_EXPRESSION) {
                const cronHour: string = ProcesosProgramarHelper.convertCronToUTC(this.currentCRON, true).split(' ')[1];
                cron = ProcesosProgramarHelper.convertCronLocalTimeToUtc(
                    this.currentCRON,
                    this.initDateBody,
                    DATE_FORMAT_ENGLISH,
                    this.tab
                );
                let splittedCron: string[] = cron.split(' ');
                splittedCron[1] = cronHour;
                cron = splittedCron.join(' ');
            }
            this.bodyToSend.cron = ProcesosProgramarHelper.validateCronExpression(cron);
            // Crear o editar la programación
            if (this.isCreateMode && this.selectedProgram) {
                this.bodyToSend.name = this.selectedProgram;
                this._createOrUpdateSchedule(Mode.CREATE, this.bodyToSend);
                // Editar programación
            } else if (!this.isCreateMode && this.selectedProgram) {
                this._createOrUpdateSchedule(Mode.EDIT, this.bodyToSend, this.selectedProgramId);
            }
            this._checkFilterButtonStatus();
        }
    }

    private _populateBodyData(code?: string) {
        if (code) {
            this.processCodePOST = code;
        }
        let programProcessBody: any = {
            processCode: this.processCodePOST,
            name: this.nameProcessPOST,
            initDate: '',
            endDate: '',
            filters: this.filtersBodyPost,
            cron: '',
        };
        this.cups = this.maestroService.validCups;

        //PREVISIONES INICIALES
        if (this.processCodePOST === 'PR0002') {
            //CASO 1, DEFAULT SELECTION ACTIVO
            if (this.defaultExecution === 'True') {
                this.filtersBodyPost = {
                    defaultExecution: this.defaultExecution,
                };
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                programProcessBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
            }
            //CASO 2, CUPS
            if (this.cups !== undefined) {
                this.filtersBodyPost = {};
                if (this.cups && this.cups !== '') {
                    this.filtersBodyPost.cups = this._procesosService.convertCupsListStringToArray(this.cups);
                }
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                programProcessBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
            }
            //CASO 3, CUENTA NEGOCIADORA
            if (this.accountId !== undefined) {
                this.filtersBodyPost = { accountId: this.accountId };
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                programProcessBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
            }
            //CASO 4, RESTO DE FILTROS
            if (this.defaultExecution === 'False' && this.cups === undefined && this.accountId === undefined) {
                this.filtersBodyPost = {};
                if (this.cups && this.cups !== '') {
                    this.filtersBodyPost.cups = this._procesosService.convertCupsListStringToArray(this.cups);
                }
                this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
                programProcessBody = {
                    processCode: this.processCodePOST,
                    name: this.nameProcessPOST,
                    initDate: '',
                    endDate: '',
                    filters: this.filtersBodyPost,
                    cron: '',
                };
            }
        }
        // PREVISIONES PROYECTADAS
        if (this.processCodePOST === 'PR0003') {
            this.filtersBodyPost = {
                unitProgram: this.formGroup.controls.unitProgram.get('value')?.value?.map((unit: any) => unit.name),
                rate: this.formGroup.controls.rate.get('value')?.value?.map((unit: any) => unit.name),
                liquidationType:
                    this.formGroup.controls.liquidationType.get('value')?.value?.name === liqTypeEnum.MEDIA_HORARIA
                        ? liqTypeEnum.MEDIA_HORARIA_STRING
                        : liqTypeEnum.FACTURA_ATR_STRING,
                clientType: this.clientType,
                accountId: this.accountId,
            };

            if (this.cups && this.cups !== '') {
                this.filtersBodyPost.cups = this._procesosService.convertCupsListStringToArray(this.cups);
            }
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        // CIERRE VIRTUAL
        if (this.processCodePOST === 'PR0004') {
            this.filtersBodyPost = {
                initDate: '',
                endDate: '',
                accountId: this.accountId,
                closeType: String(this.closeType).trim(),
                society: this.society,
                clientType: this.clientType,
            };
            if (this.cups && this.cups !== '') {
                this.filtersBodyPost.cups = this._procesosService.convertCupsListStringToArray(this.cups);
            }
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: this.repRangeInitDate,
                endDate: this.repRangeEndDate,
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        // CALCULO OBJECIONES
        if (this.processCodePOST === 'PR0005') {
            this.filtersBodyPost = {
                month: this.selectedMonth,
                year: this.selectedYear,
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        // Curva horaria a partir de cuarto-horaria
        if (this.processCodePOST === 'PR0006') {
            this.filtersBodyPost = {
                initDate: '',
                endDate: '',
            };
            if (this.cups && this.cups !== '') {
                this.filtersBodyPost.cups = this._procesosService.convertCupsListStringToArray(this.cups);
            }
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        // Agregado Previsiones
        if (this.processCodePOST === 'PR0007') {
            this.filtersBodyPost = {
                initDate: '',
                endDate: '',
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        // Envio Meteológica
        if (this.processCodePOST === 'PR0008') {
            this.filtersBodyPost = {
                initDate: '',
                endDate: '',
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        // Informe Analisis Resultados
        if (this.processCodePOST === 'PR0009') {
            this.filtersBodyPost = {
                month: this.selectedMonth,
                year: this.selectedYear,
                results: this.subfilters![2].selected,
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        // Liquidación potestativa
        if (this.processCodePOST === 'PR0011') {
            this.filtersBodyPost = {
                initDate: '',
                endDate: '',
            };
            this.filtersBodyPost = JSON.stringify(this.filtersBodyPost).replaceAll('"', '"');
            programProcessBody = {
                processCode: this.processCodePOST,
                name: this.nameProcessPOST,
                initDate: '',
                endDate: '',
                filters: this.filtersBodyPost,
                cron: '',
            };
        }
        programProcessBody.filters = JSON.parse(programProcessBody.filters);
        programProcessBody.filters.initDate =
            this.initDateRange && this.initDateRange !== '' ? this.initDateRange : this.initDateFilter;
        programProcessBody.filters.endDate =
            this.endDateRange && this.endDateRange !== '' ? this.endDateRange : this.endDateFilter;
        this.filtersBodyPost = programProcessBody.filters;
        if (!this.filtersBodyPost.initDate || this.filtersBodyPost.initDate === '') {
            delete this.filtersBodyPost['initDate'];
        }
        if (!this.filtersBodyPost.endDate || this.filtersBodyPost.endDate === '') {
            delete this.filtersBodyPost['endDate'];
        }
        if (!this.filtersBodyPost.cups || this.filtersBodyPost.cups === '') {
            delete this.filtersBodyPost['cups'];
        }
        if (!this.filtersBodyPost.accountId || this.filtersBodyPost.accountId === '') {
            delete this.filtersBodyPost['accountId'];
        }
        if (!this.filtersBodyPost.clientType || this.filtersBodyPost.clientType === '') {
            delete this.filtersBodyPost['clientType'];
        }
        if (
            !this.filtersBodyPost.liquidationType ||
            this.filtersBodyPost.liquidationType === '' ||
            !this.formGroup.controls.liquidationType.get('value')?.value
        ) {
            delete this.filtersBodyPost['liquidationType'];
        }
        if (
            !this.filtersBodyPost.rate ||
            this.filtersBodyPost.rate === '' ||
            !this.formGroup.controls.rate.get('value')
        ) {
            delete this.filtersBodyPost['rate'];
        }
        if (
            !this.filtersBodyPost.resultados ||
            this.filtersBodyPost.resultados === '' ||
            !this.formGroup.controls.resultados.get('value')
        ) {
            delete this.filtersBodyPost['resultados'];
        }
        this.filtersBodyPost = JSON.stringify(this.filtersBodyPost);
        programProcessBody = {
            processCode: this.processCodePOST,
            name: this.nameProcessPOST ? this.nameProcessPOST : this.processCodeName,
            initDate: this.initDateBody,
            endDate: this.endDateBody,
            filters: this.filtersBodyPost,
            cron: '',
        };
        if (programProcessBody.initDate === '') {
            delete programProcessBody['initDate'];
        }
        if (programProcessBody.endDate === '') {
            delete programProcessBody['endDate'];
        }

        let dataJSON: any = JSON.parse(programProcessBody.filters);
        if (
            !this.filtersBodyPost.liquidationType ||
            this.filtersBodyPost.liquidationType === '' ||
            !this.formGroup.controls.liquidationType.get('value')?.value
        ) {
            delete this.filtersBodyPost['liquidationType'];
        } else {
            dataJSON.unitProgram = this.formGroup.controls.unitProgram
                .get('value')
                ?.value?.map((unit: any) => unit.name);
            dataJSON.rate = this.formGroup.controls.rate.get('value')?.value?.map((unit: any) => unit.name);
            dataJSON.liquidationType =
                this.formGroup.controls.liquidationType.get('value')?.value?.name === liqTypeEnum.MEDIA_HORARIA
                    ? liqTypeEnum.MEDIA_HORARIA_STRING
                    : liqTypeEnum.FACTURA_ATR_STRING;
        }
        programProcessBody.filters = JSON.stringify(dataJSON);
        return programProcessBody;
    }

    private _createOrUpdateSchedule(mode: Mode, body: any, programId?: number) {
        if (mode === Mode.EDIT && programId) {
            this._procesosService.putProcesosProgramar(programId, body).subscribe(
                (_data) => {
                    this.conceptsControl.reset();
                    this._resetData();
                    this._loadNotification(false);
                },
                (_error) => {
                    this._loadNotification(true);
                }
            );
        } else if (mode === Mode.CREATE) {
            this._procesosService.postProcesosProgramar(this.bodyToSend).subscribe(
                (_data) => {
                    this.conceptsControl.reset();
                    this._resetData();
                    this._loadNotification(false);
                },
                (_error) => {
                    this._loadNotification(true);
                }
            );
        }
        return '';
    }

    private _getFilters() {
        this._globalService.getFilters().subscribe((filtros) => {
            this.filters = filtros.data;
        });
    }

    private _getSubfilters(process: any) {
        this.processCodeName = '';
        if (this.isCreateMode || process.value !== undefined) {
            this.processCodeName = process.value.name;
        } else if (!this.isCreateMode && process.processCode.name !== undefined) {
            this.processCodeName =
                process.processCode.name.charAt(0).toUpperCase() + process.processCode.name.slice(1).toLowerCase();
        }
        let selectedOption = this.processConceptsList?.find((p: any) => p.name === this.processCodeName);
        this.subfilters = this.filters.find((fil: any) => fil?.name === selectedOption?.nameStepFunction)?.filters;
        if (this.subfilters !== undefined) {
            const filterIndex: number = this.subfilters.findIndex((filter: any) => filter.key === 'clientType');
            this.clientTypeList = this.subfilters![filterIndex].data;
        }

        if (this.subfilters === undefined) {
            let nameFilters: string = 'procesos_';
            switch (this.selectedConcept.processCode) {
                case 'PR0003':
                    nameFilters += 'previsionesProyectadas';
                    break;
                case 'PR0002':
                    nameFilters += 'previsionesIniciales';
                    break;
                case 'PR0004':
                    nameFilters += 'cierreVirtual';
                    break;
                case 'PR0005':
                    nameFilters += 'medidasObjeciones';
                    break;
                case 'PR0006':
                    nameFilters += 'medidasHorariosCuartoHorario';
                    break;
                case 'PR0007':
                    nameFilters += 'agregadoPrevisiones';
                    break;
                case 'PR0008':
                    nameFilters += 'envioMeteologica';
                    break;
                case 'PR0009':
                    nameFilters += 'analisisResultados';
                    break;
                case 'PR0011':
                    nameFilters += 'liquidacionPotestativa';
                    break;
                default:
                    break;
            }
            this.filtersName = nameFilters;
            this.subfilters =
                this.filters[this.filters.findIndex((filter: any) => filter.name === nameFilters)].filters;
        }

        let arrayFilters = ProcesosProgramarHelper.getSubfiltersFromProcess(this.processCodeName);
        if (arrayFilters && arrayFilters.length > 0) {
            arrayFilters.forEach((element: any) => {
                switch (element) {
                    case 'electricSystem':
                        this._getUnitProgramSelect();
                        break;
                    case 'society':
                    case 'contract.idNumber':
                        this.selectedSociety = this.infoFilters.find(
                            (fil: any) => fil.infoArray.label === 'Sociedades: '
                        );
                        if (
                            this.selectedSociety !== undefined &&
                            this.selects.last !== undefined &&
                            this.selects.last.bankCtrl !== undefined &&
                            this.subfilters !== undefined
                        ) {
                            let filterAccountId = this.subfilters?.find((f: any) => f.key === 'contract.idNumber');
                            if (filterAccountId) {
                                filterAccountId.data = [{ name: this.selectedSociety.infoArray.value }];
                                this.society = this.selectedSociety.infoArray.value;
                            }
                            this.selects.last.bankCtrl.setValue({
                                name: this.selectedSociety.infoArray.value,
                            });
                            this.selects.last.bankSearchCtrl.setValue([this.selectedSociety.infoArray.value]);
                        }
                        break;
                    case 'closeType':
                        this.virtualClosureList = this.subfilters?.find((fil: any) => fil.key === 'closeType')!.data;
                        if (
                            this.virtualClosureList &&
                            this.virtualClosureList.length > 0 &&
                            this.virtualClosureList[0].name === 'Cuarto-horario'
                        ) {
                            this.virtualClosureList[0].name = this.virtualClosureList[0].name.replace('-', ' ');
                        }
                        let subfilterCloseTypeInfoAray = this.infoFilters.find(
                            (fil: any) => fil.infoArray.label === 'Tipo de Cierre: '
                        );
                        this.virtualClosureSelected = this.virtualClosureList?.find(
                            (fil: any) => fil?.name === subfilterCloseTypeInfoAray?.infoArray.value
                        );
                        if (this.virtualClosureSelected !== undefined) {
                            this.formGroup.controls.virtualClosure.get('value')!.setValue(this.virtualClosureSelected);
                            let selectedCloseType = this.subfilters?.find((f: any) => f.key === 'closeType');
                            if (selectedCloseType !== undefined) {
                                selectedCloseType.selected = this.virtualClosureSelected.id;
                            }
                        }
                        break;
                    case 'clientType':
                        this.clientTypeList = this.subfilters?.find((fil: any) => fil.key === 'clientType')?.data;
                        if (this.infoFilters?.some((filter: any) => filter.infoArray.label === 'Tipo de Negocio: ')) {
                            this.selectedBusinessType = this.clientTypeList.find(
                                (fil: any) =>
                                    fil.name ===
                                    this.infoFilters[
                                        this.infoFilters.findIndex(
                                            (filter: any) => filter.infoArray.label === 'Tipo de Negocio: '
                                        )
                                    ].infoArray.value
                            );
                        }
                        if (this.selectedBusinessType !== undefined) {
                            this.formGroup.controls.clientType.get('value')!.setValue(this.selectedBusinessType);
                            let selected = this.subfilters?.find((f: any) => f.key === 'clientType');
                            if (selected !== undefined) {
                                selected.selected = this.selectedBusinessType.id;
                            }
                            this.selectedBusinessType = undefined;
                        }
                        break;
                    case 'negotiatingAccount':
                        this.negotiatingAccount = this.infoFilters.find(
                            (fil: any) => fil.infoArray.label === 'Cuenta negociadora: '
                        );
                        if (
                            this.negotiatingAccount !== undefined &&
                            this.selects.first !== undefined &&
                            this.selects.first.bankCtrl !== undefined &&
                            this.subfilters !== undefined
                        ) {
                            let filterAccountId = this.subfilters?.find((f: any) => f.key === 'accountId');
                            if (filterAccountId) {
                                filterAccountId.data = [{ name: this.negotiatingAccount.infoArray.value }];
                                this.accountId = this.negotiatingAccount.infoArray.value;
                            }
                            this.selects.first.bankCtrl.setValue({
                                name: this.negotiatingAccount.infoArray.value,
                            });
                            this.selects.first.bankSearchCtrl.setValue([this.negotiatingAccount.infoArray.value]);
                        }
                        break;
                    case 'defaultExecution':
                        const indexFilter: number | undefined = this.subfilters?.findIndex(
                            (filter: any) => filter.key === 'defaultExecution'
                        );
                        if (indexFilter && this.subfilters) {
                            const indexSavedProgram: number | undefined = this.mainFilter.data.findIndex(
                                (savedProgram: any) => savedProgram.launchId === this.selectedProgramId
                            );
                            if (indexSavedProgram !== undefined) {
                                if (
                                    this.mainFilter &&
                                    this.mainFilter.data &&
                                    this.mainFilter.data.length > 0 &&
                                    this.mainFilter.data[indexSavedProgram].filters
                                ) {
                                    this.subfilters[indexFilter].toggleState =
                                        JSON.parse(
                                            JSON.parse(this.mainFilter.data[indexSavedProgram].filters).processCalculate
                                        ).defaultExecution === 'True';
                                    this.subfilters[indexFilter].selected = this.subfilters[indexFilter].toggleState;
                                    this.defaultExecution = this.subfilters[indexFilter].toggleState ? 'True' : 'False';
                                }
                            }
                        }
                        break;
                    case 'deleteModified':
                        const index: number | undefined = this.subfilters?.findIndex(
                            (filter: any) => filter.key === 'deleteModified'
                        );
                        if (index && this.subfilters) {
                            const savedProgramIndex: number | undefined = this.mainFilter.data.findIndex(
                                (savedProgram: any) => savedProgram.launchId === this.selectedProgramId
                            );
                            if (savedProgramIndex !== undefined) {
                                if (
                                    this.mainFilter &&
                                    this.mainFilter.data &&
                                    this.mainFilter.data.length > 0 &&
                                    this.mainFilter.data[savedProgramIndex].filters
                                ) {
                                    this.subfilters[index].toggleState =
                                        JSON.parse(
                                            JSON.parse(this.mainFilter.data[savedProgramIndex].filters).processCalculate
                                        ).deleteModified === 'True';
                                    this.subfilters[index].selected = this.subfilters[index].toggleState
                                        ? 'True'
                                        : 'False';
                                    this.deleteModified = this.subfilters[index].toggleState ? 'True' : 'False';
                                }
                            }
                        }
                        break;
                }
            });
        }
        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element) => {
                if (element.required) {
                    if (!element.name.includes('*')) {
                        element.name = element.name + ' *';
                    }
                }
            });
        }
        this._checkFilterButtonStatus();
    }

    protected _getOption(event: any, filterId: string) {
        if (this.subfilters) {
            const indexFilter: number = this.subfilters!.findIndex((fil: any) => fil.key === filterId);
            if (indexFilter !== -1) {
                switch (filterId) {
                    case 'electricSystem':
                        this.subfilters = this.subfilters?.map((fil: any) => {
                            if (fil.key === 'programUnit' || fil.key === 'unitProgram') {
                                if (this.allProgramUnits.length === 0) {
                                    this.allProgramUnits = fil.data;
                                }
                                fil.data = this.allProgramUnits.filter(
                                    (programUnit: any) => programUnit.sendSystem === event
                                );
                                fil.selected = fil.selected?.filter((programUnit: any) =>
                                    fil.data.some((pU: any) => pU.name === programUnit)
                                );
                                this.programUnitForm = new FormControl(fil.selected);
                            }
                            return fil;
                        });
                        break;

                    case 'unitProgram':
                    case 'programUnit':
                        this.subfilters[indexFilter].selected = event;
                        break;
                    case 'rate':
                        this.subfilters[indexFilter].selected = event;
                        break;
                    case 'clientType':
                        this.subfilters[indexFilter].selected = event;
                        break;
                    case 'closeType':
                        this.subfilters[indexFilter].selected = event;
                        break;
                    case 'resultados':
                    case 'results':
                        this.subfilters[indexFilter].selected = event;
                        break;
                    case 'liquidationType':
                        this.subfilters[indexFilter].selected =
                            event === liqTypeEnum.MEDIA_HORARIA
                                ? liqTypeEnum.MEDIA_HORARIA_CODE
                                : liqTypeEnum.FACTURA_ATR_CODE;
                        break;
                    default:
                        break;
                }
            }
        }
        this._checkFilterButtonStatus();
    }

    private _setSubfilters(process: any) {
        if (process && process.filters) {
            process.filters = typeof process.filters === 'string' ? JSON.parse(process.filters) : process.filters;
            process.filters =
                process.filters.processCalculate && typeof process.filters.processCalculate === 'string'
                    ? JSON.parse(process.filters.processCalculate)
                    : process.filters;
            process.filters =
                !process.filters.processCalculate && typeof process.filters === 'string'
                    ? JSON.parse(process.filters)
                    : process.filters;
            process = process.filters;
            if (process) {
                if (this.subfilters === undefined) {
                    this._getSubfilters(process);
                }

                this.rateForm.reset();
                this.clientTypeForm.reset();
                this.liquidationTypeForm.reset();
                this.resultsForm.reset();
                this.electricSystemForm.reset();
                this.closeTypeForm.reset();
                this.programUnitForm.reset();

                this.subfilters = this.subfilters?.map((fil: any) => {
                    fil.selected = undefined;
                    return fil;
                });

                if (!process.cups) {
                    this.selectSeleccionado.cups = [];
                    this.refilledFiltersToSend.processCalculate = '';
                    this.maestroService.validCups = [];
                    this.onCompleteResetCups(true);
                } else if (
                    this.filterCups.first &&
                    this.filterCups.first.listCups &&
                    this.filterCups.first.newCups &&
                    this.filterCups.first.lengthCups
                ) {
                    this.filterCups.first.listCups = this.selectedCups?.infoArray?.value[0].split(',');
                    this.filterCups.first.newCups = this.selectedCups?.infoArray?.value.toString();
                    this.maestroService.validCups = this.selectedCups?.infoArray?.value.toString();
                    this.filterCups.first.lengthCups = this.selectedCups?.infoArray?.value.length;
                }
                this.subfilters = this.subfilters!.map((filter: any) => {
                    filter.selected = undefined;
                    if (filter.key === 'contract.idNumber') {
                        filter.key = 'society';
                    }
                    if (
                        (process[filter.key] && process[filter.key].length !== 0) ||
                        (filter.key === 'resultados' && process.results)
                    ) {
                        const filterKey: string = filter.key !== 'contract.idNumber' ? filter.key : 'society';
                        switch (filterKey) {
                            case 'rate':
                                filter.data = filter.data.map((rate: any) => {
                                    return { id: rate.name, name: rate.name, value: rate.name };
                                });
                                filter.selected = typeof process.rate === 'string' ? [process.rate] : process.rate;
                                this.rateForm = new FormControl(filter.selected);
                                break;
                            case 'clientType':
                                filter.data = filter.data.map((client: any) => {
                                    let clientTypeParsed: string = client.id;
                                    if (client.id.includes(';')) {
                                        clientTypeParsed = '';
                                        client.id
                                            .slice(1, client.id.length - 1)
                                            .split(';')
                                            .forEach((cT: string) => {
                                                clientTypeParsed += `${cT},`;
                                            });
                                    }
                                    return {
                                        id: clientTypeParsed,
                                        name: client.name,
                                        value: client.id,
                                    };
                                });
                                filter.selected = filter.data.find(
                                    (fil: any) => fil.id === process.clientType || `(${fil.id})` === process.clientType
                                )?.name;
                                this.clientTypeForm = new FormControl(filter.selected);
                                break;
                            case 'liquidationType':
                                filter.data = filter.data.map((liquidation: any) => {
                                    return { id: liquidation.id, name: liquidation.name, value: liquidation.id };
                                });
                                filter.selected =
                                    process[filter.key] === 'ATR' ? liqTypeEnum.FACTURA_ATR : liqTypeEnum.MEDIA_HORARIA;
                                this.liquidationTypeForm = new FormControl(filter.selected);
                                break;
                            case 'electricSystem':
                                filter.data = filter.data.map((system: any) => {
                                    return { id: system.id, name: system.name, value: system.name };
                                });
                                filter.selected = process.electricSystem;
                                this.electricSystemForm = new FormControl(filter.selected);
                                break;
                            case 'closeType':
                                process.closeType = process.closeType.trim();
                                filter.data = filter.data.map((closeType: any) => {
                                    return { id: closeType.id.trim(), name: closeType.name, value: process.closeType };
                                });
                                if (
                                    process.closeType === closeTypeCode.CCH_CODE ||
                                    process.closeType === closeTypeId.CCH_ID
                                ) {
                                    filter.selected = closeTypeName.CCH_NAME;
                                } else if (
                                    process.closeType === closeTypeCode.CH_CODE ||
                                    process.closeType === closeTypeId.CH_ID
                                ) {
                                    filter.selected = closeTypeName.CH_NAME;
                                } else if (
                                    process.closeType === closeTypeCode.ATR_CODE ||
                                    process.closeType === closeTypeId.ATR_ID
                                ) {
                                    filter.selected = closeTypeName.ATR_NAME;
                                } else if (
                                    process.closeType === closeTypeCode.MIXTO_CODE ||
                                    process.closeType === closeTypeId.MIXTO_ID
                                ) {
                                    filter.selected = closeTypeName.MIXTO_NAME;
                                }
                                this.closeTypeForm = new FormControl(filter.selected);
                                break;
                            case 'unitProgram':
                            case 'programUnit':
                                filter.data.forEach((unit: any) => {
                                    unit = { id: unit.name, name: unit.name, value: unit.id };
                                });
                                filter.selected =
                                    typeof process[filterKey] === 'string' ? [process[filterKey]] : process[filterKey];
                                this.programUnitForm = new FormControl(filter.selected);
                                break;
                            case 'cups':
                                if (this.infoFilters) {
                                    this.selectedCups = this.infoFilters.find(
                                        (fil: any) => fil.infoArray.label === 'CUPS: '
                                    );
                                }
                                if (this.filterCups.first !== undefined) {
                                    if (this.selectedCups?.infoArray !== undefined) {
                                        this.filterCups.first.listCups = this.selectedCups.infoArray.value[0].includes(
                                            ','
                                        )
                                            ? this.selectedCups.infoArray.value[0].split(',')
                                            : this.selectedCups.infoArray.value;
                                        this.filterCups.first.newCups = this.selectedCups.infoArray.value.toString();
                                        this.maestroService.validCups = this.selectedCups.infoArray.value.toString();
                                        this.filterCups.first.lengthCups = this.selectedCups.infoArray.value.length;
                                    }
                                }
                                filter.data = process.cups;
                                filter.selected = process.cups;
                                break;
                            case 'accountId':
                                this.negotiatingAccount = this.infoFilters.find(
                                    (fil: any) => fil.infoArray.label === 'Cuenta negociadora: '
                                );
                                if (
                                    this.negotiatingAccount !== undefined &&
                                    this.selects.first !== undefined &&
                                    this.selects.first.bankCtrl !== undefined &&
                                    this.subfilters !== undefined
                                ) {
                                    let filterAccountId = this.subfilters?.find((f: any) => f.key === 'accountId');
                                    if (filterAccountId) {
                                        filterAccountId.data = [{ name: this.negotiatingAccount.infoArray.value }];
                                        this.accountId = this.negotiatingAccount.infoArray.value;
                                    }
                                    this.selects.first.bankCtrl.setValue({
                                        name: this.negotiatingAccount.infoArray.value,
                                    });
                                    this.selects.first.bankSearchCtrl.setValue([
                                        this.negotiatingAccount.infoArray.value,
                                    ]);
                                }
                                break;
                            case 'society':
                                this.selectedSociety = this.infoFilters.find(
                                    (fil: any) => fil.infoArray.label === 'Sociedades: '
                                );
                                if (
                                    this.selectedSociety !== undefined &&
                                    this.selects.last !== undefined &&
                                    this.selects.last.bankCtrl !== undefined &&
                                    this.subfilters !== undefined
                                ) {
                                    let filterAccountId = this.subfilters?.find(
                                        (f: any) => f.key === 'contract.idNumber' || f.key === 'society'
                                    );
                                    if (filterAccountId) {
                                        filterAccountId.data = [{ name: this.selectedSociety.infoArray.value }];
                                        this.society = this.selectedSociety.infoArray.value;
                                    }
                                    this.selects.last.bankCtrl.setValue({
                                        name: this.selectedSociety.infoArray.value,
                                    });
                                    this.selects.last.bankSearchCtrl.setValue([this.selectedSociety.infoArray.value]);
                                }
                                break;
                            case 'resultados':
                                filter.data = filter.data.map((result: any) => {
                                    return { id: result.id, name: result.name, value: result.name };
                                });
                                filter.selected = process.results;
                                this.resultsForm = new FormControl(
                                    filter.data[filter.data.findIndex((result: any) => result.id === process.results)]
                                );
                                break;
                            case 'defaultExecution':
                                filter.selected = process.defaultExecution;
                                filter.toggleState = process.defaultExecution === 'True';
                                this.defaultExecution = process.defaultExecution;
                                break;
                            default:
                                break;
                        }
                    }
                    return filter;
                });
            }
        }
    }

    protected onCRONValueChange($event: any) {
        this.currentCRON = $event;
    }

    private _populateSystemsByUserRoles(): void {
        if (!this.userRoles.includes('ADMIN')) {
            if (!this.userRoles.includes('MEDIDA')) {
                this.selectSystems = this.selectSystems.filter(
                    (system) =>
                        system.name !== SistemasProcesosEnum.MEDIDAS && system.name !== SistemasProcesosEnum.CIERRES
                );
            }
            if (!this.userRoles.includes('PREVISION')) {
                this.selectSystems = this.selectSystems.filter(
                    (system) => system.name !== SistemasProcesosEnum.PREVISIONES
                );
            }
            if (!this.userRoles.includes('CONCILIACION')) {
                this.selectSystems = this.selectSystems.filter(
                    (system) => system.name !== SistemasProcesosEnum.CONCILIACION
                );
            }
        }
    }

    private _populateConceptsByUserRoles(): void {
        this.filteredProcessConceptsList = this.processConceptsList;
        if (!this.userRoles.includes('ADMIN')) {
            if (!this.userRoles.includes('MEDIDA')) {
                this.filteredProcessConceptsList = this.filteredProcessConceptsList.filter(
                    (concept: any) =>
                        concept.area !== SistemasProcesosEnum.MEDIDAS && concept.area !== SistemasProcesosEnum.CIERRES
                );
            }
            if (!this.userRoles.includes('PREVISION')) {
                this.filteredProcessConceptsList = this.filteredProcessConceptsList.filter(
                    (concept: any) => concept.area !== SistemasProcesosEnum.PREVISIONES
                );
            }
            if (!this.userRoles.includes('CONCILIACION')) {
                this.filteredProcessConceptsList = this.filteredProcessConceptsList.filter(
                    (concept: any) => concept.area !== SistemasProcesosEnum.CONCILIACION
                );
            }
        }
    }

    private _setVariableDateFilterAsRequired(): void {
        if (this.selectedConcept?.processCode !== Concepts.PREVISIONES_INICIALES) {
            this.isVariableInitialDateRequired = true;
            this.isVariableEndDateRequired = true;
        } else {
            this.isVariableInitialDateRequired = false;
            this.isVariableEndDateRequired = false;
        }
    }

    private _populateFilters(event: any) {
        this.infoFilters = [];
        this.refilledFiltersToSend = JSON.parse(event.filters);
        let processCalculateFilters: any;
        try {
            this._translateService.get('PAGE.FILTERS').subscribe((message: any) => {
                processCalculateFilters =
                    this.refilledFiltersToSend?.processCalculate !== undefined
                        ? JSON.parse(this.refilledFiltersToSend.processCalculate)
                        : this.refilledFiltersToSend;
                this._procesosService.selectSelectedPrograming = processCalculateFilters;
                this.selectSeleccionado = processCalculateFilters;
                // Rellenamos también la información a mostrar
                if (this.refilledFiltersToSend?.programUnit || processCalculateFilters?.programUnit) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['PROGRAM_UNIT'] + ': ',
                            value: this.refilledFiltersToSend?.programUnit || processCalculateFilters?.programUnit,
                        },
                    });
                }
                if (this.refilledFiltersToSend?.rate || processCalculateFilters?.rate) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['RATE'] + ': ',
                            value: this.refilledFiltersToSend?.rate || processCalculateFilters?.rate,
                        },
                    });
                }
                if (this.refilledFiltersToSend?.liquidationType || processCalculateFilters?.liquidationType) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['LIQUIDATION_TYPE'] + ': ',
                            value:
                                this.refilledFiltersToSend?.liquidationType || processCalculateFilters?.liquidationType,
                        },
                    });
                }
                const measureInitDate =
                    this.refilledFiltersToSend?.measureInitDate || processCalculateFilters?.measureInitDate;
                // Fechas Measure
                if (this.refilledFiltersToSend?.measureInitDate || processCalculateFilters?.measureInitDate) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['INIT_DATE'] + ': ',
                            value: ProcesosProgramarHelper.parseStaticOrVariableDate(
                                measureInitDate,
                                DATE_FORMAT_SPANISH
                            ),
                            valueDate: measureInitDate,
                        },
                    });
                }
                const measureEndDate =
                    this.refilledFiltersToSend?.measureEndDate || processCalculateFilters?.measureEndDate;
                if (this.refilledFiltersToSend?.measureEndDate || processCalculateFilters?.measureEndDate) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['END_DATE'] + ': ',
                            value: ProcesosProgramarHelper.parseStaticOrVariableDate(
                                measureEndDate,
                                DATE_FORMAT_SPANISH
                            ),
                            valueDate: measureEndDate,
                        },
                    });
                }
                const projectedInitDate =
                    this.refilledFiltersToSend?.forecastInitDate || processCalculateFilters?.forecastInitDate;
                // Fechas Proyectadas
                if (this.refilledFiltersToSend?.forecastInitDate || processCalculateFilters?.forecastInitDate) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['PROJECTED_INIT_DATE'] + ': ',
                            value: ProcesosProgramarHelper.parseStaticOrVariableDate(
                                projectedInitDate,
                                DATE_FORMAT_SPANISH
                            ),
                            valueDate: projectedInitDate,
                        },
                    });
                }
                const projectedEndDate =
                    this.refilledFiltersToSend?.forecastEndDate || processCalculateFilters?.forecastEndDate;
                if (this.refilledFiltersToSend?.forecastEndDate || processCalculateFilters?.forecastEndDate) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['PROJECTED_END_DATE'] + ': ',
                            value: ProcesosProgramarHelper.parseStaticOrVariableDate(
                                projectedEndDate,
                                DATE_FORMAT_SPANISH
                            ),
                            valueDate: projectedEndDate,
                        },
                    });
                }
                if (processCalculateFilters && processCalculateFilters.initDate !== null) {
                    if (ProcesosProgramarHelper.checkDateFormat(processCalculateFilters.initDate)) {
                        // si es una fecha fija
                        this.showSelect = true;
                    } else {
                        // si es una fecha variable
                        this.showSelect = false;
                    }
                    // Fechas normales
                    const normalInitDate = processCalculateFilters?.initDate;
                    if (normalInitDate) {
                        this.infoFilters.push({
                            infoArray: {
                                label: message['INIT_DATE'] + ': ',
                                value: ProcesosProgramarHelper.parseStaticOrVariableDate(
                                    normalInitDate,
                                    DATE_FORMAT_SPANISH
                                ),
                                valueDate: normalInitDate,
                            },
                        });
                        this.showSelect = ProcesosProgramarHelper.checkDateFormat(normalInitDate);
                    }
                }
                const normalEndDate = processCalculateFilters?.endDate;
                if (normalEndDate) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['END_DATE'] + ': ',
                            value: ProcesosProgramarHelper.parseStaticOrVariableDate(
                                normalEndDate,
                                DATE_FORMAT_SPANISH
                            ),
                            valueDate: normalEndDate,
                        },
                    });
                    this.showSelect = ProcesosProgramarHelper.checkDateFormat(normalEndDate);
                }
                // Tipo de Cierre
                if (this.refilledFiltersToSend?.closeType || processCalculateFilters?.closeType) {
                    let closeType = this.refilledFiltersToSend?.closeType
                        ? this.refilledFiltersToSend?.closeType?.trim()
                        : processCalculateFilters?.closeType?.trim();
                    switch (closeType) {
                        case closeTypeId.CCH_ID:
                        case closeTypeCode.CCH_CODE:
                            closeType = closeTypeName.CCH_NAME;
                            break;
                        case closeTypeId.CH_ID:
                        case closeTypeCode.CH_CODE:
                            closeType = closeTypeName.CH_NAME;
                            break;
                        case closeTypeId.ATR_ID:
                        case closeTypeCode.ATR_CODE:
                            closeType = closeTypeName.ATR_NAME;
                            break;
                        case closeTypeId.MIXTO_ID:
                        case closeTypeCode.MIXTO_CODE:
                            closeType = closeTypeName.MIXTO_NAME;
                            break;
                        default:
                            break;
                    }
                    this.closeType = processCalculateFilters?.closeType;
                    this.infoFilters.push({
                        infoArray: {
                            label: message['CLOSE_TYPE'] + ': ',
                            value: closeType ? closeType : this.closeType,
                        },
                    });
                }
                // CUPS
                if (this.refilledFiltersToSend?.cups || processCalculateFilters?.cups) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['CUPS'] + ': ',
                            value: this.refilledFiltersToSend?.cups || processCalculateFilters?.cups,
                        },
                    });
                }
                // Cuenta Negociadora
                if (this.refilledFiltersToSend?.accountId || processCalculateFilters?.accountId) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['NEGOTIATING_ACCOUNT'] + ': ',
                            value: this.refilledFiltersToSend?.accountId || processCalculateFilters?.accountId,
                        },
                    });
                }
                // Sociedad
                if (this.refilledFiltersToSend?.society || processCalculateFilters?.society) {
                    // idNumber
                    this.infoFilters.push({
                        infoArray: {
                            label: message['SOCIETIES'] + ': ',
                            value: this.refilledFiltersToSend?.society || processCalculateFilters?.society,
                        },
                    });
                }
                // clientType
                if (this.refilledFiltersToSend?.clientType || processCalculateFilters?.clientType) {
                    // idNumber
                    let clientType = '';
                    if (processCalculateFilters?.clientType === '6') {
                        clientType = 'Pymes';
                    } else {
                        clientType = 'Grandes cuentas';
                    }
                    this.infoFilters.push({
                        infoArray: {
                            label: message['BUSINESS_TYPE'] + ': ',
                            value: clientType,
                        },
                    });
                }
                // Ejecución por defecto
                if (this.refilledFiltersToSend?.defaultExecution || processCalculateFilters?.defaultExecution) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['DEFAULT_EXECUTION'] + ': ',
                            value:
                                this.refilledFiltersToSend?.defaultExecution ||
                                processCalculateFilters?.defaultExecution,
                        },
                    });
                }
                // Eliminar registros
                if (this.refilledFiltersToSend?.deleteModified || processCalculateFilters?.deleteModified) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['DELETE_REGISTERS'] + ': ',
                            value:
                                this.refilledFiltersToSend?.deleteModified || processCalculateFilters?.deleteModified,
                        },
                    });
                }
                // Año
                if (this.refilledFiltersToSend?.year || processCalculateFilters?.year) {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['YEAR'] + ': ',
                            value: this.refilledFiltersToSend?.year || processCalculateFilters?.year,
                        },
                    });
                }
                // Mes
                if (this.refilledFiltersToSend?.month || processCalculateFilters?.month) {
                    this._translateService.get('PAGE.MONTHS').subscribe((message) => {
                        this.infoFilters.push({
                            infoArray: {
                                label: message['MONTH'] + ': ',
                                value: message[
                                    ProcesosProgramarHelper.convertMonthNumberToName(
                                        this.refilledFiltersToSend?.month || processCalculateFilters?.month
                                    )
                                ],
                            },
                        });
                    });
                }
                // CRON
                if (event.cron && event.cron !== '') {
                    this.infoFilters.push({ infoArray: { label: message['CRON'] + ': ', value: event.cron } });
                    message['PROGRAM_UNIT'] + ': ', (this.cronForm = new FormControl(event.cron));
                }
                // Fecha Inicio Repeticiones
                if (event.initDate && event.initDate !== '') {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['REPETITION_INIT_DATE'] + ': ',
                            value: moment(event.initDate).format(DATE_FORMAT_SPANISH),
                        },
                    });
                }
                // Fecha Fin Repeticiones
                if (event.endDate && event.endDate !== '') {
                    this.infoFilters.push({
                        infoArray: {
                            label: message['REPETITION_END_DATE'] + ': ',
                            value: moment(event.endDate).format(DATE_FORMAT_SPANISH),
                        },
                    });
                }
                this._procesosService.dates = this.infoFilters.filter((data: any) => {
                    return (
                        data.infoArray.label !== message['INIT_DATE'] &&
                        data.infoArray.label !== message['END_DATE'] &&
                        data.infoArray.label !== message['PROJECTED_INIT_DATE'] &&
                        data.infoArray.label !== message['PROJECTED_END_DATE']
                    );
                });
                this._procesosService.originalData = this.infoFilters.filter((data: any) => {
                    return (
                        data.infoArray.label !== message['INIT_DATE'] &&
                        data.infoArray.label !== message['END_DATE'] &&
                        data.infoArray.label !== message['PROJECTED_INIT_DATE'] &&
                        data.infoArray.label !== message['PROJECTED_END_DATE']
                    );
                });
                this._procesosService.launchId = event.launchId;
                // Guarda la fecha de inicio del rango de repeticiones
                if (event.initDate !== null && event.initDate !== undefined && event.initDate !== '') {
                    this.repRangeInitDate = event.initDate;
                } else {
                    this.repRangeInitDate = '';
                }
                if (this.repRangeInitDateInputRef?.nativeElement) {
                    this.repRangeInitDateInputRef.nativeElement.value = event.initDate;
                }
                // Guarda la fecha de fin del rango de repeticiones
                // Marca como checked el radio button que corresponda del rango de repeticiones
                if (event.endDate !== null && event.endDate !== undefined && event.endDate !== '') {
                    this.repRangeEndDate = event.endDate;
                    this.radioButtonSelected = RadioButtonsCron.option3;
                } else {
                    this.repRangeEndDate = '';
                    this.radioButtonSelected = RadioButtonsCron.option1;
                }
                if (this.repRangeEndDateInputRef?.nativeElement) {
                    this.repRangeEndDateInputRef.nativeElement.value = '';
                }
                // Cambio de formato a YYYY-MM-DD
                this.initDateRange = this.infoFilters.filter(
                    (subfilter: any) => subfilter.infoArray.label === message['INIT_DATE'] + ': '
                )[0]?.infoArray.valueDate;
                if (this.initDateRange && this.initDateRange.length > 0) {
                    this.initDateRange = `${this.initDateRange.split('-')[0]}-${this.initDateRange.split('-')[1]}-${
                        this.initDateRange.split('-')[2]
                    }`;
                }
                this.endDateRange = this.infoFilters.filter(
                    (subfilter: any) => subfilter.infoArray.label === message['END_DATE'] + ': '
                )[0]?.infoArray.valueDate;
                if (this.endDateRange && this.endDateRange.length > 0) {
                    this.endDateRange = `${this.endDateRange?.split('-')[0]}-${this.endDateRange?.split('-')[1]}-${
                        this.endDateRange?.split('-')[2]
                    }`;
                }
                if (this.initDateRange?.includes('undefined')) {
                    this.initDateRange = this.initDateRange.replaceAll('undefined', '');
                    this.initDateRange =
                        this.initDateRange.charAt(this.initDateRange.length - 1) === '-'
                            ? this.initDateRange.substring(0, this.initDateRange.length - 1)
                            : this.initDateRange;
                }
                if (this.endDateRange?.includes('undefined')) {
                    while (this.endDateRange.includes('undefined')) {
                        this.endDateRange = this.endDateRange.replaceAll('-undefined', '');
                    }
                }
            });
        } catch (e) {
            this.showSelect = false;
            this.formGroup.reset();
        }
    }

    private _formatSelectedValue(event: any, filtro: any, key: string, filtroType: string) {
        if (filtroType !== 'Date' && this.subfilters) {
            if (key === KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA || key === KeysMaestro.CONTRACT_SOCIEDADES) {
                let filtroFormateado = event.split('=');
                this.subfilters[filtro].selected = filtroFormateado[1];
                if (key === KeysMaestro.ACCOUNTID_CUENTA_NEGOCIADORA) {
                    this.accountId = this.subfilters[filtro].selected;
                } else if (key === KeysMaestro.CONTRACT_SOCIEDADES) {
                    this.society = this.subfilters[filtro].selected;
                }
            } else if (key === KeysMaestro.CUPS) {
                this.subfilters[filtro].selected = this.maestroService.validCups;
                this.cups = this.subfilters[filtro].selected;
            }
        }
    }

    private _checkFilterButtonStatus(): void {
        if (this.conceptsControl.value !== null) {
            const { processCode }: any = this.conceptsControl.value;
            switch (processCode) {
                case Concepts.PREVISIONES_INICIALES:
                    this.isDisabled = ProcesosProgramarHelper.checkDefaultExecution();
                    break;
                case Concepts.PREVISIONES_PROYECTADAS:
                    this.isDisabled =
                        ProcesosProgramarHelper.checkDates(
                            this.initDateFilter,
                            this.endDateFilter,
                            this.initDateRange!,
                            this.endDateRange!
                        ) ||
                        ProcesosProgramarHelper.checkProjectedDates(
                            this.projectedInitDateFilter,
                            this.projectedEndDateFilter
                        );
                    break;
                case Concepts.CIERRE_VIRTUAL:
                    this.isDisabled =
                        ProcesosProgramarHelper.checkDates(
                            this.initDateFilter,
                            this.endDateFilter,
                            this.initDateRange!,
                            this.endDateRange!
                        ) || ProcesosProgramarHelper.checkCloseType(this.subfilters);
                    break;
                case Concepts.MEDIDAS_OBJECIONES:
                    this.showButtonProgram = true;
                    this.isDisabled = ProcesosProgramarHelper.checkMonthAndYear(this.monthYearDate?.toString());
                    if (
                        !this.initDateFilter &&
                        (!this.initDateRange! || !this.endDateRange) &&
                        (!this.monthYearDate || this.monthYearDate === '')
                    ) {
                        this.isDisabled = true;
                    } else {
                        this.isDisabled = false;
                    }
                    break;
                case Concepts.MEDIDAS_HORARIAS_CUARTOS_HORARIAS:
                    this.isDisabled = ProcesosProgramarHelper.checkDates(
                        this.initDateFilter,
                        this.endDateFilter,
                        this.initDateRange!,
                        this.endDateRange!
                    );
                    break;
                case Concepts.CALCULO_AGREGADO_PREVISIONES:
                    this.isDisabled = ProcesosProgramarHelper.checkDates(
                        this.initDateFilter,
                        this.endDateFilter,
                        this.initDateRange!,
                        this.endDateRange!
                    );
                    break;
                case Concepts.ENVIO_METEOROLOGICA:
                    this.isDisabled = ProcesosProgramarHelper.checkDates(
                        this.initDateFilter,
                        this.endDateFilter,
                        this.initDateRange!,
                        this.endDateRange!
                    );
                    break;
                case Concepts.ANALISIS_RESULTADOS:
                    this.showButtonProgram = true;
                    let isResultsFilterRefilled: boolean = false;
                    const resultsFilterIndex: number | undefined = this.subfilters?.findIndex(
                        (f: any) => f.key === 'resultados' || f.key === 'results'
                    );
                    if (resultsFilterIndex && resultsFilterIndex !== -1) {
                        isResultsFilterRefilled =
                            this.subfilters![resultsFilterIndex].selected !== '' &&
                            this.subfilters![resultsFilterIndex].selected !== null &&
                            this.subfilters![resultsFilterIndex].selected !== undefined;
                    }
                    if (
                        (!this.initDateFilter &&
                            (!this.initDateRange! || !this.endDateRange) &&
                            (!this.monthYearDate || this.monthYearDate === '')) ||
                        !isResultsFilterRefilled
                    ) {
                        this.isDisabled = true;
                    } else {
                        this.isDisabled = false;
                    }
                    break;
                case Concepts.LIQUIDACION_POTESTATIVA:
                    this.isDisabled = ProcesosProgramarHelper.checkDates(
                        this.initDateFilter,
                        this.endDateFilter,
                        this.initDateRange!,
                        this.endDateRange!
                    );
                    break;
            }
        }
        if (!this.isDisabled && this.isCRONIncorrect) {
            this.isDisabled = true;
        }
        if (!this.isDisabled) {
            this.isDisabled = this.mainFilter.data.some(
                (e: any) => e.name === this.selectedProgram && e.creationUser === 'SYSTEM'
            );
        }
    }

    private _resetData() {
        const copyData = this.mainFilter.data;
        this.reset$.next();
        this.cronForm.reset();
        this.cronForm = new FormControl(DEFAULT_CRON_EXPRESSION);
        this._procesosService.dates = '';
        this.selectFiltroSelect?.bankCtrl?.reset();
        this.selectedProgram = '';
        this.isCreateMode = false;
        this.showCreateLabel = false;
        this.showInputProgram = false;
        this.showSelectProgram = false;
        this.showButtonProgram = false;
        this.showSelect = false;
        this.radioButtonSelected = undefined;
        this.monthYearDate = '';
        this.initDateRange = '';
        this.endDateRange = '';
        this.repRangeInitDate = '';
        this.repRangeEndDate = '';
        this.initDateRangeCopy = '';
        this.endDateRangeCopy = '';
        this.initDateFilter = '';
        this.endDateFilter = '';
        this.projectedInitDateFilter = '';
        this.projectedEndDateFilter = '';
        this.isVariableInitialDateRequired = false;
        this.isDisabled = true;
        this.subfilters = [];
        this.infoFilters = [];
        this.mainFilter.data = [];
        this.mainFilter.data = copyData;
        this.resetDate$.next();
        this.unitProgramsFormControl = [];
        if (this.programUnitForm) {
            this.programUnitForm.reset();
        }
        if (this.rateForm) {
            this.rateForm.reset();
        }
        if (this.electricSystemForm) {
            this.electricSystemForm.reset();
        }
        if (this.closeTypeForm) {
            this.closeTypeForm.reset();
        }
        if (this.clientTypeForm) {
            this.clientTypeForm.reset();
        }
        if (this.liquidationTypeForm) {
            this.liquidationTypeForm.reset();
        }
        if (this.resultsForm) {
            this.resultsForm.reset();
        }
    }

    private _loadNotification(hasError: boolean, errorCode?: string) {
        this._translateService.get('PAGE.MODALS').subscribe((message: any) => {
            this._snackBar.open(
                hasError
                    ? message[`${errorCode ? errorCode : 'NOT_FINISH_SCHEDULE_PROCESS'}`]
                    : message['FINISH_SCHEDULE_PROCESS'],
                message['ACTION_CLOSE'],
                {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                }
            );
            setTimeout(() => {
                this._snackBar.dismiss();
            }, NOTIFICATION_SHOWING_TIME);
        });
    }

    private _getCloseTypeCode(name: string): string {
        switch (name ? name.trim() : '') {
            case 'cuarto-horario':
                return 'CCH';
            case 'horario':
                return 'CH';
            case 'factura_ATR':
                return 'ATR';
            case 'mixto':
                return 'Mixto';
            default:
                return '';
        }
    }

    private _getUnitProgramSelect() {
        this._procesosService.getUnidadPrograma().subscribe((resp: any) => {
            this.unitProgramList = resp.data;
            this.electricSystemList = [];

            for (let i = 0; i < this.unitProgramList.length; i++) {
                this.electricSystemList.push(this.unitProgramList[i].sendSystem);
            }

            // Quita los sistemas eléctricos repetidos:
            let electricSystemFilter: any = Array.from(new Set(this.electricSystemList));
            electricSystemFilter = electricSystemFilter.map(function (word: string) {
                word === 'PENINSULA'
                    ? (word = 'Península')
                    : (word =
                          word.charAt(0).toUpperCase() +
                          word
                              .slice(1)
                              .toLowerCase()
                              .replace(/-(.)/g, (match) => '-' + match.charAt(1).toUpperCase()));
                return word;
            });
            this.electricSystemList = electricSystemFilter;
            let copy: FiltroSelect[] = [];

            // Rellena la lista de sistemas eléctricos:
            for (let i = 0; i < this.electricSystemList.length; i++) {
                copy[i] = { name: '', value: '', code: '' };
                copy[i].name = this.electricSystemList[i];
                copy[i].value = this.electricSystemList[i];
                copy[i].code = this.electricSystemList[i];
            }

            this.electricSystemList = copy;
            const indexFilter: number = this.subfilters!.findIndex((filter: any) => filter.key === 'electricSystem');
            if (indexFilter !== -1) {
                this.subfilters![indexFilter].data = copy;
            }

            // Rellena la lista de unidades de programa:
            this.unitProgramListCopy = [];

            for (let i = 0; i < this.electricSystemList.length; i++) {
                for (let j = 0; j < this.unitProgramList.length; j++) {
                    this.unitProgramList[j].sendSystem === 'PENINSULA'
                        ? (this.unitProgramList[j].sendSystem = 'Península')
                        : (this.unitProgramList[j].sendSystem =
                              this.unitProgramList[j].sendSystem.charAt(0).toUpperCase() +
                              this.unitProgramList[j].sendSystem
                                  .slice(1)
                                  .toLowerCase()
                                  .replace(/-(.)/g, (match: any) => '-' + match.charAt(1).toUpperCase()));
                    if (this.electricSystemList[i].name === this.unitProgramList[j].sendSystem) {
                        let unitProgramCopy: any = {
                            name: this.unitProgramList[j].programUnitCode,
                            value: this.unitProgramList[j].programUnitCode,
                            code: this.electricSystemList[i].name,
                            sendSystem: this.unitProgramList[j].sendSystem,
                        };
                        this.unitProgramListCopy.push(unitProgramCopy);
                    }
                }
            }
            this.unitProgramList = this.unitProgramListCopy;
            const filterIndex: number = this.subfilters!.findIndex(
                (filter: any) => filter.key === 'programUnit' || filter.key === 'unitProgram'
            );
            if (filterIndex !== -1) {
                this.subfilters![filterIndex].data = this.unitProgramList;
            }
        });
    }

    protected onToggleChange(key: string) {
        if (key === 'defaultExecution') {
            this.defaultExecution = this.defaultExecution === 'False' ? 'True' : 'False';
        } else if (key === 'deleteModified') {
            this.deleteModified = this.deleteModified === 'False' ? 'True' : 'False';
        }
    }

    protected checkCRON($event: any) {
        this.isCRONIncorrect = $event;
        this._checkFilterButtonStatus();
    }
}
