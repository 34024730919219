import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from '../components/header/header.component';
import { SidebarMenuComponent } from '../components/sidebar-menu/sidebar-menu.component';
import { SidebarMenuItemComponent } from '../components/sidebar-menu-item/sidebar-menu-item.component';
import { BaseIconComponent } from '../components/base-icon/base-icon.component';
import { FiltroDateComponent } from '../components/filtros/filtro-date/filtro-date.component';
import { FiltroInputComponent } from '../components/filtros/filtro-input/filtro-input.component';
import { FiltroMultiselectComponent } from '../components/filtros/filtro-multiselect/filtro-multiselect.component';
import { FiltroSelectComponent } from '../components/filtros/filtro-select/filtro-select.component';
import { DownloadExcelComponent } from '../components/download-excel/download-excel.component';
import { FiltroInputMultiComponent } from '../components/filtros/filtro-input-multi/filtro-input-multi.component';
import { FiltroTextareaComponent } from '../components/filtros/filtro-textarea/filtro-textarea.component';
import { OpenDialogComponent } from '../components/open-dialog/open-dialog.component';
import { FiltroCupsComponent } from '../components/filtros/filtro-cups/filtro-cups.component';
import { FiltroYearComponent } from '../components/filtros/filtro-year/filtro-year.component';
import { MomentDateFormatPipe } from '../pipes/moment-date-format.pipe';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TranslateModule, MaterialModule],
    declarations: [
        HeaderComponent,
        SidebarMenuComponent,
        SidebarMenuItemComponent,
        BaseIconComponent,
        FiltroDateComponent,
        FiltroSelectComponent,
        FiltroInputComponent,
        FiltroMultiselectComponent,
        DownloadExcelComponent,
        FiltroInputMultiComponent,
        FiltroTextareaComponent,
        FiltroCupsComponent,
        OpenDialogComponent,
        FiltroYearComponent,
        MomentDateFormatPipe,
    ],
    exports: [
        HeaderComponent,
        SidebarMenuComponent,
        SidebarMenuItemComponent,
        BaseIconComponent,
        FiltroDateComponent,
        FiltroSelectComponent,
        FiltroInputComponent,
        FiltroMultiselectComponent,
        DownloadExcelComponent,
        FiltroInputMultiComponent,
        TranslateModule,
        FiltroTextareaComponent,
        ReactiveFormsModule,
        FiltroCupsComponent,
        OpenDialogComponent,
        FiltroYearComponent,
        MomentDateFormatPipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
