<div class="container">
    <div class="subfilter" *ngFor="let filtro of subfilters; let i = index">
        <app-filtro-date
            *ngIf="filtro.type === 'Date' && showSelect"
            [name]="filtro.name"
            (select)="receiveDate($event, i)"
        ></app-filtro-date>
        <!-- Filtro SISTEMAS -->
        <ng-container
            *ngIf="
                filtro.type === 'Select' &&
                filtro.key === 'area' &&
                checkConditions(filtro.displayConditions) &&
                showSelect
            "
        >
            <mat-form-field appearance="standard" class="select">
                <mat-label>{{ 'PAGE.LOADS.SYSTEMS' | translate }}</mat-label>
                <mat-select
                    placeholder="{{ 'PAGE.LOADS.SYSTEMS_PLACEHOLDER' | translate }}"
                    [value]="systemList.length === 1 ? systemList[0] : ''"
                >
                    <ng-container *ngIf="systemList.length > 1 ? systemList[0] : ''">
                        <mat-option [value]="" (click)="onClickFilterReportTypes('reset')" id="0">
                            {{ 'PAGE.LOADS.ALL' | translate }}
                        </mat-option>
                    </ng-container>
                    <ng-container *ngFor="let system of systemList">
                        <mat-option [value]="system" (click)="onClickFilterReportTypes(system.name)" id="system.id">
                            {{ 'PAGE.LOADS.' + system.translation | translate }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <!-- Filtro INFORMES -->
        <ng-container
            *ngIf="
                filtro.type === 'Select' &&
                filtro.key === 'reportType.reportCode' &&
                checkConditions(filtro.displayConditions) &&
                showSelect
            "
        >
            <mat-form-field appearance="standard" class="select">
                <mat-label>{{ filtro.name }}</mat-label>
                <mat-select #matReportSelect>
                    <ng-container *ngFor="let report of filteredReportsList">
                        <mat-option
                            [value]="report"
                            (click)="checkReportTypeAsSelected(report, i)"
                            id="reportType.reportCode"
                        >
                            {{ report.name }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <app-filtro-select
            #selects
            *ngIf="
                filtro.type === 'Select' &&
                filtro.key !== 'area' &&
                filtro.key !== 'reportType.reportCode' &&
                checkConditions(filtro.displayConditions) &&
                showSelect
            "
            [toggleLabelLeft]="filtro.isToggle?.leftLabel"
            [toggleLabelRight]="filtro.isToggle?.rightLabel"
            [charactersControl]="filtro.isSearchable?.specialSearch"
            [searchByToggle]="filtro.isToggle?.active"
            [isSearchable]="filtro.isSearchable?.active"
            [filtroUrl]="filtro.data"
            [title]="filtro.name"
            [key]="filtro.key"
            [id]="filtro.id"
            [specialFeeActivated]="specialFeeActivated"
            (searchBy)="getFiltersBy($event)"
            (valueChange)="obtainOption($event)"
            (select)="restartValue($event, i, filtro.key, filtro.type)"
            (selectSelected)="checkValueSelects($event)"
            (openSelect)="byOpenSelect($event)"
        ></app-filtro-select>
    </div>
    <hr />
</div>
<div class="buttonsFilter">
    <button class="delete" (click)="clearFilters()" (mouseup)="hideSelect()" mat-button>
        {{ 'PAGE.ACTIONS.DELETE' | translate }} <app-base-icon iconName="filter_alt_off" class=""></app-base-icon>
    </button>
    <button class="search" mat-flat-button color="warn" (click)="loadTable()" [disabled]="isDisabled">
        {{ 'PAGE.ACTIONS.FILTER' | translate | uppercase }}<app-base-icon iconName="search"> </app-base-icon>
    </button>
</div>
