import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Distributors } from '../../../shared/models/distributors';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class InformesService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    searchBy: boolean = false;
    selectId: string;
    year: number | number[];

    validCups: any;

    // Pagination
    pageIndex: number;
    pageSize: number;
    sortDir: string;
    sortActive: string;

    // Parametro de busqueda para los endpoint
    searchParam: string;
    showColumnsBy: string;
    selectedClient: any;
    constructor(private _http: HttpClient, private _translateService: TranslateService, private _snackBar: MatSnackBar) {}

    launchInformesPostPrevisiones(body: any) {
        const url = `${environment.apiUrl}/reporting/v0`;
        return this._http.post<any>(url, body);
    }

    getTablaInformesRes(page: number, pageSize: number, sortBy: string, sortDir: string, search: string, isExport: boolean = false): Observable<any> {
        this.searchParam = search;
        return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'reporting', 'id', undefined, isExport);
    }

    getUsers() {
        const url = `${environment.apiUrl}/user/v0?page=0`;
        return this._http.get<any[]>(url);
    }

    getSelectReports(): Observable<any> {
        const url = `${environment.apiUrl}/reporting/type/v0?page=-1&sortBy=reportCode&sortDir=asc`;
        return this._http.get<Distributors[]>(url);
    }

    checkCups(cups: any): Observable<any> {
        if (typeof cups !== 'string') {
            cups.toString();
        }
        if (environment.isMockActive) {
            return of('ES0021000000000964CH;ES0021000000002611TD;ES0021000000006325TC;ES0021000000014012JW;ES0021000000017167NY');
        } else {
            let cups$: Observable<string> = this.checkValidCups(cups).pipe(map((response: any) => response?.data?.map((cups: any) => cups.cups).join(';')));
            cups$.subscribe((cupsString: string) => {
                this.validCups = cupsString;
            });
            return cups$;
        }
    }

    checkCupsMock(cups: string): Observable<string> {
        if (environment.isMockActive) {
            return of('ES0021000000000964CH;ES0021000000002611TD;ES0021000000006325TC;ES0021000000017167NY');
        } else {
            return of(cups);
        }
    }

    checkValidCups(cups: string): Observable<any> {
        if (cups && cups.length > 0) {
            const url = `${environment.apiUrl}/cups/v0?page=-1&search=cups=(${cups})`;
            return this._http.get<any[]>(url);
        }
        return of([]);
    }

    checkSorting(
        sortBy: string,
        page: number,
        pageSize: number,
        search: string,
        sortDir: string,
        endpoint: string,
        sortType: string,
        sort = 'asc',
        isExport: boolean = false
    ) {
        let url: string = `${environment.apiUrl}/${endpoint}/v0?page=${page}&pageSize=${pageSize}`;

        if (endpoint === 'cups/boundary-point') url = `${environment.apiUrl}/${endpoint}?page=${page}&pageSize=${pageSize}`;

        if (sortBy === undefined) url += `&sortBy=${sortType}&sortDir=${sort}&search=${search}`;
        else url += `&sortBy=${sortBy}&sortDir=${sortDir}&search=${search}`;

        if (isExport) {
            url += '&isExcel=true';
        }

        return this._http.get<any[]>(url);
    }

    getUnitProgram() {
        const url = `${environment.apiUrl}/unitProgram/v0?page=0&sortDir=asc`;
        return this._http.get<any>(url);
    }

    getFeesCode(): Observable<any> {
        const url = `${environment.apiUrl}/rate/v0?page=-1&sortBy=codeRate&sortDir=asc`;
        return this._http.get<Distributors[]>(url);
    }

    getConceptsReports(): Observable<any> {
        const url = `${environment.apiUrl}/reporting/type/v0?page=-1`;
        return this._http.get<any[]>(url);
    }

    /**
     * Hace una llamada al endpoint para obtener las cuentas negociadoras según el string de entrada y el modo de búsqueda
     * @param specialSearch 
     * @returns 
     */
    getNegotiatingAccounts(specialSearch?: { search: string; mode: 'negotiatingAccountCode' | 'negotiatingAccountDescription' }): Observable<any> {
        let url: string;
        if (specialSearch) {
            url = `${environment.apiUrl}/negotiating-account/v0?page=0&pageSize=10&sortBy=negotiatingAccountCode&sortDir=asc&search=${
                specialSearch.mode === 'negotiatingAccountCode' ? 'negotiatingAccountCode' : 'negotiatingAccountDescription'
            }like(${specialSearch.search})`;
        } else {
            url = `${environment.apiUrl}/negotiating-account/v0?page=0&pageSize=10&sortBy=negotiatingAccountCode&sortDir=asc`;
        }
        return this._http.get<any[]>(url);
    }

    /**
     * Hace una llamada al endpoint para obtener las sociedades según el string de entrada y el modo de búsqueda
     * @param specialSearch 
     * @returns 
     */
    getClients(specialSearch?: { search: string; mode: 'description' | 'cif' }): Observable<any> {
        let url: string;
        if (specialSearch) {
            url = `${environment.apiUrl}/client/v0?page=-1&sortBy=idNumber&sortDir=asc&search=${
                specialSearch.mode === 'cif' ? 'idNumberlike' : 'clientNamelike'
            }(${specialSearch.search})`;
        } else {
            url = `${environment.apiUrl}/client/v0?page=-1`;
        }
        return this._http.get<any[]>(url);
    }

    /**
     * Hace una llamada al endpoint para obtener los ayuntamientos según el string de entrada y el modo de búsqueda
     * @param specialSearch 
     * @returns 
     */
    getCouncils(specialSearch?: { search: string; mode: 'councilDescription' | 'councilCif' }): Observable<any> {
        let url: string;
        if (specialSearch) {
            url = `${environment.apiUrl}/cups/council/v0?page=-1&sortBy=cif&sortDir=asc&search=${
                specialSearch.mode === 'councilCif' ? 'councilCodelike' : 'councilNamelike'
            }(${specialSearch.search})`;
        } else {
            url = `${environment.apiUrl}/cups/council/v0?page=-1`;
        }
        return this._http.get<any[]>(url);
    }

    /**
     * Hace una llamada al endpoint para obtener las distribuidoras según el string de entrada y el modo de búsqueda
     * @param specialSearch 
     * @returns 
     */
    getDistributors(specialSearch?: { search: string; mode: 'distributorDescription' | 'distributorCode' }): Observable<any> {
        let url: string;
        if (specialSearch) {
            url = `${environment.apiUrl}/distributor/v0?page=-1&sortBy=code&sortDir=asc&search=${
                specialSearch.mode === 'distributorCode' ? 'codelike' : 'descriptionlike'
            }(${specialSearch.search})`;
        } else {
            url = `${environment.apiUrl}/distributor/v0?page=-1`;
        }
        return this._http.get<any[]>(url);
    }

    getSelectAccionaSociety(): Observable<any> {
        const url = `${environment.apiUrl}/contract/society/v0?page=-1`;
        return this._http.get<Distributors[]>(url);
    }

    getSelfConsumption(): Observable<any> {
        const url = `${environment.apiUrl}/selfsupply/v0?page=-1`;
        return this._http.get<any[]>(url);
    }

    // TODO: pendiente del back para testearlo
    downloadReport(report: any) {
        if (report.urlS3) {
            const url = `${environment.apiUrl}/reporting/v0/download?fileS3=${report.urlS3}`;
            this._http.get(url, { responseType: 'blob' as 'json', observe: 'response' }).subscribe((response: any) => {
                let dataType = response.type;
                let binaryData = [];
                binaryData.push(response.body);

                let downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

                downloadLink.setAttribute('download', response.url.slice(response.url.lastIndexOf('/') + 1));
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });
        } else {
            this._translateService.get('PAGE.SNACK_BAR').subscribe((message: any) => {
                this._snackBar.open(message['ERROR'], message['ACTION']['CLOSE'], {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                setTimeout(() => {
                    this._snackBar.dismiss();
                }, 6000);
            });
        }
    }

    deleteReport(id: number) {
        const url = `${environment.apiUrl}/reporting/v0/${id}`;
        return this._http.delete<any>(url);
    }
}
