import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CronGenComponent } from 'ngx-cron-editor';
import { Days, DaysCode, Months, MonthWeeks, MonthCardinals } from './custom-cron.enum';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-custom-cron',
    templateUrl: './custom-cron.component.html',
    styleUrls: ['./custom-cron.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CustomCronComponent,
            multi: true,
        },
    ],
})
export class CustomCronComponent extends CronGenComponent implements OnInit, OnChanges {
    @Input() reset$: Subject<void>;
    @Input() formControl?: any;
    @Output() tabClick: EventEmitter<number> = new EventEmitter();
    @Output() onValueChange: EventEmitter<string> = new EventEmitter();
    @Output() isCRONIncorrect: EventEmitter<boolean> = new EventEmitter();

    isOnReset: boolean = false;
    selectedTabIndex: number = 0;
    selectedRadioButton: number = -1;
    selectedYear: string = '';
    cronValue: string = '';
    symbols: string = '/-,MONTUEWEDTHUFRISATSUNJANFEBMARAPRMAYJUNJULAUGSEPOCTNOVDEC';
    selectOptionsYears = this.generateYears();
    weeklyTimeForm: FormGroup;
    uniqueForm = this.formBuilder.group({
        day: this.formBuilder.control(1),
        month: this.formBuilder.control(1),
        year: this.formBuilder.control(1),
        hours: this.formBuilder.control(1),
        minutes: this.formBuilder.control(1),
    });

    constructor(private formBuilder: FormBuilder) {
        super(formBuilder);
        this.weeklyTimeForm = this.formBuilder.group({
            hours: this.formBuilder.control(0),
            minutes: this.formBuilder.control(0),
        });
    }
    ngOnChanges(): void {
        this._getTabPreselected();
        this._setCRONValues();
    }

    async ngOnInit() {
        super.ngOnInit();
        this.activeTab = 'daily';
        this.selectedTabIndex = 0;
        this.selectedRadioButton = -1;
        this.reset$.subscribe(() => {
            this.minutesForm?.reset();
            this.hourlyForm?.reset();
            this.advancedForm?.reset();
            this.cronForm?.reset();

            this._resetForms();
            this._setCRONValues('0 0 1/1 * ? *', true);
            this._checkIfDisableProgramButton();
        });
        this.tabClick.emit(this._getTabPreselected());
        this.onValueChange.emit(this.formControl.value);
        this._setCRONValues();
        this._checkIfDisableProgramButton();
    }

    getFormGroup(control: AbstractControl) {
        return control as FormGroup;
    }

    onTabFocus(evt: number) {
        this.selectedTabIndex = evt;

        this.isCRONIncorrect.emit(false);
        switch (evt) {
            case 0:
                this.activeTab = 'daily';
                this.selectedRadioButton = -1;
                this.isCRONIncorrect.emit(true);
                break;
            case 1:
                this.activeTab = 'weekly';
                break;
            case 2:
                this.activeTab = 'monthly';
                this.selectedRadioButton = -1;
                this.isCRONIncorrect.emit(true);
                break;
            case 3:
                this.activeTab = 'yearly';
                this.selectedRadioButton = -1;
                this.isCRONIncorrect.emit(true);
                break;
            case 4:
                this.activeTab = 'unique';
                break;
            default:
                this.selectedTabIndex = 0;
                this.activeTab = 'daily';
                break;
        }
        this.tabClick.emit(evt);
        this._setCRONValues();
        if (this.isOnReset) {
            this.minutesForm?.reset();
            this.hourlyForm?.reset();
            this.activeTab = 'daily';
            this.selectedTabIndex = 0;
            this.selectedRadioButton = -1;
            this._resetForms();
            this.isCRONIncorrect.emit(true);
            this.isOnReset = false;
        }
        this.selectedRadioButton = -1;
        this._checkIfDisableProgramButton();
    }

    public monthWeekDisplay(monthWeekNumber: string): string {
        return MonthWeeks[monthWeekNumber];
    }

    public dayDisplay(day: string): string {
        return Days[day];
    }

    public monthDisplay(month: number): string {
        return Months[month];
    }

    public monthDayDisplay(month: string): string {
        return MonthCardinals[month];
    }

    protected onValueChanged(
        value: any,
        tab: 'daily' | 'weekly' | 'monthly' | 'yearly' | 'unique',
        radioIndex: 0 | 1,
        isRadio?: boolean
    ) {
        if (isRadio) {
            this.selectedRadioButton = radioIndex;
        }
        this.cronValue = this._formatCRON(value, tab);
        this._checkIfDisableProgramButton();
        this.onValueChange.emit(this.cronValue);
    }

    private _resetForms(): void {
        this.dailyForm = this.formBuilder.group({
            everyDays: this.formBuilder.group({
                days: this.formBuilder.control(0),
                hours: this.formBuilder.control(0),
                minutes: this.formBuilder.control(0),
                seconds: this.formBuilder.control(0),
                hourType: this.formBuilder.control('AM'),
            }),
            everyWeekDay: this.formBuilder.group({
                days: this.formBuilder.control(1),
                hours: this.formBuilder.control(0),
                minutes: this.formBuilder.control(0),
                seconds: this.formBuilder.control(0),
                hourType: this.formBuilder.control('AM'),
            }),
        });
        this.weeklyForm = this.formBuilder.group({
            MON: this.formBuilder.control(false),
            TUE: this.formBuilder.control(false),
            WED: this.formBuilder.control(false),
            THU: this.formBuilder.control(false),
            FRI: this.formBuilder.control(false),
            SAT: this.formBuilder.control(false),
            SUN: this.formBuilder.control(false),
        });
        this.weeklyTimeForm = this.formBuilder.group({
            hours: this.formBuilder.control(0),
            minutes: this.formBuilder.control(0),
        });
        this.monthlyForm = this.formBuilder.group({
            specificDay: this.formBuilder.group({
                day: this.formBuilder.control('1'),
                months: this.formBuilder.control(1),
                hours: this.formBuilder.control(0),
                minutes: this.formBuilder.control(0),
            }),
            specificWeekDay: this.formBuilder.group({
                day: this.formBuilder.control(DaysCode['DAY_1']),
                monthWeek: this.formBuilder.control('#1'),
                months: this.formBuilder.control(1),
                hours: this.formBuilder.control(0),
                minutes: this.formBuilder.control(0),
            }),
        });
        this.yearlyForm = this.formBuilder.group({
            specificMonthDay: this.formBuilder.group({
                day: this.formBuilder.control('1'),
                month: this.formBuilder.control(1),
                hours: this.formBuilder.control(0),
                minutes: this.formBuilder.control(0),
            }),
            specificMonthWeek: this.formBuilder.group({
                day: this.formBuilder.control(DaysCode['DAY_1']),
                monthWeek: this.formBuilder.control('#1'),
                month: this.formBuilder.control(1),
                hours: this.formBuilder.control(0),
                minutes: this.formBuilder.control(0),
            }),
        });
        this.uniqueForm = this.formBuilder.group({
            day: this.formBuilder.control(1),
            month: this.formBuilder.control(1),
            year: this.formBuilder.control(new Date().getFullYear()),
            hours: this.formBuilder.control(0),
            minutes: this.formBuilder.control(0),
        });
    }

    private _checkIfDisableProgramButton() {
        if (
            (this.activeTab === 'daily' || this.activeTab === 'monthly' || this.activeTab === 'yearly') &&
            this.selectedRadioButton === -1
        ) {
            this.isCRONIncorrect.emit(true);
        } else if (this.activeTab === 'weekly') {
            this._checkWeekDaySelected();
        } else {
            this.isCRONIncorrect.emit(false);
        }
    }

    private _checkWeekDaySelected() {
        if (
            this.weeklyForm.value.MON ||
            this.weeklyForm.value.TUE ||
            this.weeklyForm.value.WED ||
            this.weeklyForm.value.THU ||
            this.weeklyForm.value.FRI ||
            this.weeklyForm.value.SAT ||
            this.weeklyForm.value.SUN
        ) {
            this.isCRONIncorrect.emit(false);
        } else {
            this.isCRONIncorrect.emit(true);
        }
    }

    private _formatCRON(value: any, tab: 'daily' | 'weekly' | 'monthly' | 'yearly' | 'unique'): string {
        let newCRON: string = '';
        switch (tab) {
            // DAILY
            case 'daily':
                if (this.selectedRadioButton === 0) {
                    newCRON = `${value.minutes} ${value.hours} 1/${value.days} * ? *`;
                } else {
                    newCRON = `${value.minutes} ${value.hours} 1/1 * ? *`;
                }
                break;
            // WEEKLY
            case 'weekly':
                let weekDays: string = '';
                const weekDaysStringsArray: string[] = Object.values(Object.keys(value.weeklyDayForm));
                const weekDaysValuesArray: boolean[] = Object.values(value.weeklyDayForm);
                weekDaysStringsArray.forEach((day: string, index: number) => {
                    weekDays += weekDaysValuesArray[index] ? `${day},` : '';
                });
                weekDays = weekDays.slice(0, weekDays.length - 1);
                newCRON = `${value.weeklyTimeForm.minutes} ${value.weeklyTimeForm.hours} ? * ${weekDays} *`;
                break;
            // MONTHLY
            case 'monthly':
                if (this.selectedRadioButton === 0) {
                    newCRON = `${value.minutes} ${value.hours} ${value.day} ${value.months} ? *`;
                } else {
                    newCRON = `${value.minutes} ${value.hours} ${value.day} ${value.months} ${value.monthWeek} *`;
                }
                break;
            // YEARLY
            case 'yearly':
                if (this.selectedRadioButton === 0) {
                    newCRON = `${value.minutes} ${value.hours} ${value.day} ${value.month} ? *`;
                } else {
                    newCRON = `${value.minutes} ${value.hours} ${value.day} ${value.month} ${value.monthWeek} *`;
                }
                break;
            // UNIQUE
            case 'unique':
                newCRON = `${value.minutes} ${value.hours} ${value.day} ${value.month} ? ${value.year}`;
                break;

            default:
                break;
        }
        return newCRON;
    }

    private generateYears() {
        const actualYear = new Date().getFullYear();
        return Array.from({ length: 11 }, (element, index) => actualYear + index);
    }

    private _getTabPreselected() {
        const splittedValues: any[] = this.formControl?.value?.split(' ');
        if (splittedValues && splittedValues.length > 5) {
            const monthDayValue: string = splittedValues[2];
            const weekDayValue: string = splittedValues[4];
            if (monthDayValue.includes('/')) {
                // DAILY
                this.selectedTabIndex = 0;
                this.activeTab = 'daily';
            } else if (monthDayValue === '?') {
                // WEEKLY
                this.selectedTabIndex = 1;
                this.activeTab = 'weekly';
            } else if (weekDayValue === '?') {
                // MONTHLY
                this.selectedTabIndex = 2;
                this.activeTab = 'monthly';
            } else if (!splittedValues.some((value: string) => this.symbols.includes(value))) {
                // UNIQUE
                this.selectedTabIndex = 4;
                this.activeTab = 'unique';
            } else {
                // YEARLY
                this.selectedTabIndex = 3;
                this.activeTab = 'yearly';
            }
        }
        return this.selectedTabIndex;
    }

    private _getWeekDay(value: string): string {
        let weekDay: string = '';
        if (isNaN(Number.parseInt(value[0]))) {
            switch (value[0]) {
                case 'MON':
                    weekDay = '2';
                    break;
                case 'TUE':
                    weekDay = '3';
                    break;
                case 'WED':
                    weekDay = '4';
                    break;
                case 'THU':
                    weekDay = '5';
                    break;
                case 'FRI':
                    weekDay = '6';
                    break;
                case 'SAT':
                    weekDay = '7';
                    break;
                case 'SUN':
                    weekDay = '1';
                    break;

                default:
                    break;
            }
        } else {
            weekDay = value[0];
        }
        return weekDay;
    }

    private _getWeekNumber(value: string): string {
        let weekNumber: string = '';
        if (!isNaN(Number.parseInt(value[value.length - 1]))) {
            weekNumber = `#${value[value.length - 1]}`;
        } else {
            weekNumber = 'L';
        }
        return weekNumber;
    }

    private _setCRONValues(value?: string, onReset?: boolean) {
        if (this.formControl && this.formControl?.value && !value) {
            value = this.formControl.value;
        } else if (!value) {
            value = '0 0 1/1 * ? *';
        }
        // Get values
        const splittedValues: any[] = value ? value.split(' ') : this.formControl?.value?.split(' ');
        if (splittedValues && splittedValues.length > 5) {
            const minutesValue: string = splittedValues[0];
            const hoursValue: string = splittedValues[1];
            const monthDayValue: string = splittedValues[2];
            const monthValue: string = splittedValues[3];
            const weekDayValue: string = splittedValues[4];
            const yearValue: string = splittedValues[5];

            let weekDay: string = '';
            let weekNumber: string = '';
            if (weekDayValue.length > 2 && weekDayValue.includes('#')) {
                weekDay = this._getWeekDay(weekDayValue);
                weekNumber = this._getWeekNumber(weekDayValue);
            }

            const dateString: string = new Date(
                Number.parseInt(yearValue),
                Number.parseInt(monthValue),
                Number.parseInt(monthDayValue)
            )
                .getDate()
                .toString();

            // Refill forms fields
            if (this.selectedTabIndex === 0 && this.activeTab === 'daily') {
                // DAILY
                this.dailyForm = this.formBuilder.group({
                    everyDays: this.formBuilder.group({
                        days: this.formBuilder.control(Number.parseInt(monthDayValue)),
                        hours: this.formBuilder.control(Number.parseInt(hoursValue)),
                        minutes: this.formBuilder.control(Number.parseInt(minutesValue)),
                        seconds: this.formBuilder.control(0),
                        hourType: this.formBuilder.control('AM'),
                    }),
                    everyWeekDay: this.formBuilder.group({
                        days: this.formBuilder.control(1),
                        hours: this.formBuilder.control(Number.parseInt(hoursValue)),
                        minutes: this.formBuilder.control(Number.parseInt(minutesValue)),
                        seconds: this.formBuilder.control(0),
                        hourType: this.formBuilder.control('AM'),
                    }),
                });
                if (monthDayValue !== '1/1') {
                    this.selectedRadioButton = 0;
                } else {
                    this.selectedRadioButton = 1;
                }
            } else if (this.selectedTabIndex === 1 && this.activeTab === 'weekly') {
                // WEEKLY
                if (weekDayValue === '*') {
                    this.weeklyForm = this.formBuilder.group({
                        MON: this.formBuilder.control(true),
                        TUE: this.formBuilder.control(true),
                        WED: this.formBuilder.control(true),
                        THU: this.formBuilder.control(true),
                        FRI: this.formBuilder.control(true),
                        SAT: this.formBuilder.control(true),
                        SUN: this.formBuilder.control(true),
                    });
                } else if (weekDayValue.includes('-')) {
                    let initialDay: number = -1;
                    let endDay: number = -1;
                    this.selectOptions.days.forEach((day: string, index: number) => {
                        if (initialDay !== -1 && weekDayValue.includes(day)) {
                            endDay = index;
                        } else if (initialDay === -1 && weekDayValue.includes(day)) {
                            initialDay = index;
                        }
                    });
                    if (initialDay !== -1 && endDay !== -1) {
                        let weekDaysValueFormatted: string = '';
                        for (let i = initialDay; i <= endDay; i++) {
                            weekDaysValueFormatted += this.selectOptions.days[i];
                        }
                        this.weeklyForm = this.formBuilder.group({
                            MON: this.formBuilder.control(weekDaysValueFormatted.includes('MON')),
                            TUE: this.formBuilder.control(weekDaysValueFormatted.includes('TUE')),
                            WED: this.formBuilder.control(weekDaysValueFormatted.includes('WED')),
                            THU: this.formBuilder.control(weekDaysValueFormatted.includes('THU')),
                            FRI: this.formBuilder.control(weekDaysValueFormatted.includes('FRI')),
                            SAT: this.formBuilder.control(weekDaysValueFormatted.includes('SAT')),
                            SUN: this.formBuilder.control(weekDaysValueFormatted.includes('SUN')),
                        });
                    }
                } else {
                    this.weeklyForm = this.formBuilder.group({
                        MON: this.formBuilder.control(weekDayValue.includes('MON')),
                        TUE: this.formBuilder.control(weekDayValue.includes('TUE')),
                        WED: this.formBuilder.control(weekDayValue.includes('WED')),
                        THU: this.formBuilder.control(weekDayValue.includes('THU')),
                        FRI: this.formBuilder.control(weekDayValue.includes('FRI')),
                        SAT: this.formBuilder.control(weekDayValue.includes('SAT')),
                        SUN: this.formBuilder.control(weekDayValue.includes('SUN')),
                    });
                }
                this.weeklyTimeForm = this.formBuilder.group({
                    hours: this.formBuilder.control(Number.parseInt(hoursValue)),
                    minutes: this.formBuilder.control(Number.parseInt(minutesValue)),
                });
            } else if (this.selectedTabIndex === 2 && this.activeTab === 'monthly') {
                // MONTHLY
                if (!weekDayValue.includes('#') && !weekDayValue.includes('L')) {
                    this.selectedRadioButton = 0;
                } else {
                    this.selectedRadioButton = 1;
                }
                this.monthlyForm = this.formBuilder.group({
                    specificDay: this.formBuilder.group({
                        day: this.formBuilder.control(monthDayValue),
                        months: this.formBuilder.control(Number.parseInt(monthValue)),
                        hours: this.formBuilder.control(Number.parseInt(hoursValue)),
                        minutes: this.formBuilder.control(Number.parseInt(minutesValue)),
                    }),
                    specificWeekDay: this.formBuilder.group({
                        day: this.formBuilder.control(DaysCode[`DAY_${weekDay !== '' ? weekDay : dateString}`]),
                        monthWeek: this.formBuilder.control(
                            weekNumber !== ''
                                ? weekNumber
                                : `#${Math.trunc((Number.parseInt(monthDayValue) - 1) / 7 + 1).toString()}`
                        ),
                        months: this.formBuilder.control(Number.parseInt(monthValue)),
                        hours: this.formBuilder.control(Number.parseInt(hoursValue)),
                        minutes: this.formBuilder.control(Number.parseInt(minutesValue)),
                    }),
                });
            } else if (this.selectedTabIndex === 3 && this.activeTab === 'yearly') {
                // YEARLY
                this.yearlyForm = this.formBuilder.group({
                    specificMonthDay: this.formBuilder.group({
                        day: this.formBuilder.control(monthDayValue),
                        month: this.formBuilder.control(Number.parseInt(monthValue)),
                        year: this.formBuilder.control(yearValue),
                        hours: this.formBuilder.control(Number.parseInt(hoursValue)),
                        minutes: this.formBuilder.control(Number.parseInt(minutesValue)),
                    }),
                    specificMonthWeek: this.formBuilder.group({
                        day: this.formBuilder.control(DaysCode[`DAY_${dateString}`]),
                        monthWeek: this.formBuilder.control(
                            isNaN(Number.parseInt(monthDayValue))
                                ? 'L'
                                : `#${Math.trunc((Number.parseInt(monthDayValue) - 1) / 7 + 1).toString()}`
                        ),
                        month: this.formBuilder.control(Number.parseInt(monthValue)),
                        hours: this.formBuilder.control(Number.parseInt(hoursValue)),
                        minutes: this.formBuilder.control(Number.parseInt(minutesValue)),
                    }),
                });
                if (!weekDayValue.includes('#') || !weekDayValue.includes('L')) {
                    this.selectedRadioButton = 0;
                } else {
                    this.selectedRadioButton = 1;
                }
            } else if (this.selectedTabIndex === 4 && this.activeTab === 'unique') {
                // UNIQUE
                this.uniqueForm = this.formBuilder.group({
                    day: this.formBuilder.control(Number.parseInt(monthDayValue)),
                    month: this.formBuilder.control(Number.parseInt(monthValue)),
                    year: this.formBuilder.control(Number.parseInt(yearValue)),
                    hours: this.formBuilder.control(Number.parseInt(hoursValue)),
                    minutes: this.formBuilder.control(Number.parseInt(minutesValue)),
                });
            }
        }
        if (onReset) {
            this.selectedTabIndex = 0;
            this.selectedRadioButton = -1;
            this.activeTab = 'daily';
            this.isOnReset = true;
        }
        this._checkIfDisableProgramButton();
    }
}
