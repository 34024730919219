import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserManagService {
    constructor(private _http: HttpClient) {}

    getSelectSistemas(): Observable<any> {
        //Obtener filtro "Sistemas" (Medidas/previsiones)
        const url = './assets/json/filtros.json';
        return this._http.get<any>(url).pipe(map((e) => e.filters));
    }
}
