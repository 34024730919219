<mat-form-field appearance="standard" class="select">
    <mat-label>{{ title }}</mat-label>
    <mat-select
        #singleSelect
        (openedChange)="emitOpenSelect($event)"
        [formControl]="bankCtrl"
        (disableOptionCentering)="(true)"
        [disabled]="disableSelect"
        (selectionChange)="emitValue($event); emitValueNew($event)"
        [placeholder]="placeholder"
        [compareWith]="compareFn"
        [required]="required"
    >
        <mat-option *ngIf="isSearchable">
            <ngx-mat-select-search
                [formControl]="bankSearchCtrl"
                [placeholderLabel]="placeholderLabel"
                noEntriesFoundLabel="{{ 'PAGE.FILTERS.SELECT.NO_DATA' | translate }}"
            >
            </ngx-mat-select-search>
        </mat-option>
        <mat-option
            *ngFor="let option of filteredBanks | async"
            [value]="option"
            [disabled]="checkDisabled(option.name)"
        >
            <ng-container
                *ngIf="
                    !option ||
                        !option.launchId ||
                        !option.processCode ||
                        title !== ('PAGE.FILTERS.SELECT.PROGRAM' | translate);
                    else withDeleteOption
                "
            >
                {{
                    option.name && option.surnames
                        ? option.name + ' ' + option.surnames
                        : option.name === 'Peninsula-Islas'
                        ? 'Península-Islas'
                        : option.name
                }}
            </ng-container>
            <ng-template #withDeleteOption>
                <div class="deleteProcess">
                    {{ option.name }}
                    <span
                        class="icon material-symbols-outlined icon_color close"
                        (click)="emitDeleteProcess(option.launchId!)"
                        >{{ isProcessFilterOnFocus ? 'delete' : '' }}
                    </span>
                </div>
            </ng-template>
        </mat-option>
    </mat-select>
</mat-form-field>
<ng-container>
    <div *ngIf="searchByToggle" class="toggle__section">
        <mat-label>{{ toggleLabelLeft }}</mat-label>
        <mat-slide-toggle (toggleChange)="filterBy()" (click)="emitValueToggle($event)" [disabled]="disableToggle">
        </mat-slide-toggle>
        <mat-label>{{ toggleLabelRight }}</mat-label>
    </div>

    <div *ngIf="editableSelect" class="toggle__section">
        <app-filtro-input></app-filtro-input>
    </div>
</ng-container>
