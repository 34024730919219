import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { ExportService } from '../../../services/export.service';
import { GlobalService } from '../../../services/global.service';
import { HeaderTitleService } from '../../../services/header-title.service';
import { KeysEnvios, SortBy, TipoEnvios } from '../../../shared/enums/envios';
import { IColumnsTable } from '../../../shared/models/columns-table.model';
import { FiltroSelect } from '../../../shared/models/filtros.interface';
import { EnviosService } from '../envios.service';

@Component({
    selector: 'app-envios-resultado',
    templateUrl: './envios-resultado.component.html',
    styleUrls: ['./envios-resultado.component.scss'],
})
export class EnviosResultadoComponent implements OnInit, OnDestroy {
    @ViewChild('filtersSelect') filtersSelect: MatSelect;

    isDisabled: boolean = true;
    resetDate: boolean = false;
    ocultar: boolean = true;
    elemento: boolean = false;
    validRole: any = true;
    isFirstTime: boolean = true;

    totalRegisters: number;
    numPage: number;
    totalPages: number;

    reset$: Subject<void> = new Subject<void>();
    subcriptions: Subscription[] = [];
    selectedValue: string;
    statusSelected: string = '';
    electricSystemSelected: string | undefined = '';
    unitProgramSelected: any[] = [];
    userSelected: string = '';
    searchParam: string = '';

    dateInitSelected: any;
    dateEndSelected: any;
    singleDateSelected: string = '';

    optionValues: string[] = ['', ''];
    userAllRoles: any;

    sendTypeList: any = ['DAILY_FORECAST', 'WEEKLY_FORECAST', 'OPTIONAL_SETTLEMENT'];
    sendTypes = TipoEnvios;
    electricSystemList: any[] = [];
    unitProgramListCopy: any[] = [];
    unitProgramList: any[] = [];
    subfilters: any[] = [];
    unitProgramsFormControl: any;
    myselectedUnits: any;
    unitsForm: FormControl = new FormControl([]);

    pageIndex: number;
    pageSize: number;
    sortDir: string;
    sortActive: SortBy;
    sort: Sort;
    pageSizeOptions: number[] = [10, 25, 50, 100];

    dataBusiness: any;
    dataHeaders: any;
    dataHeaders2: IColumnsTable[] = [];
    dataHeadersValueSelect: IColumnsTable[] = [];
    dataHeaderChange: boolean = true;
    columns: any[];
    userRoles: string[] = [];
    transtalionCodeSelectedType: string;

    constructor(
        public readonly _headerTitleService: HeaderTitleService,
        private readonly _exportService: ExportService,
        private readonly _enviosService: EnviosService,
        private readonly _globalService: GlobalService,
        private readonly _loginService: LoginService
    ) {
        this._headerTitleService.currentHeaderTranslationCode = 'SENDS_RESULTS_TITLE';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
    }

    ngOnInit(): void {
        this.userAllRoles = this._loginService.getUserActiveRoles();

        this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
        this.userRoles = this.userRoles.map((element: any) => element.trim());
        if (
            this.userRoles.findIndex((role) => role === 'PREVISION') !== -1 ||
            this.userRoles.findIndex((role) => role === 'ADMIN') !== -1
        ) {
            this.validRole = true;
        } else {
            this.validRole = false;
        }

        // Rellena los filters
        let respFilters: any = [];
        this.subfilters[0] = [];
        this.subfilters[1] = [];
        this.subfilters[2] = [];
        this.subfilters[3] = [];

        respFilters = this._loginService.getFiltersTotal();
        respFilters = JSON.parse(respFilters);

        this.subcriptions.push(
            this._globalService.getAllFilters(true).subscribe((filtros: { data: any[] }) => {
                filtros.data.forEach((filter: any) => {
                    if (filter['name'] === KeysEnvios.ENVIOS_RESULTADOS) {
                        this.subfilters = filter.filters;
                        this.getUsers();
                        this.getUnitProgramSelect();
                    }
                });
            })
        );
    }

    ngOnDestroy(): void {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.subcriptions.forEach((sub: any) => {
            sub.unsubscribe();
        });
    }

    dataChanged($event: any, concept: boolean = false) {
        if (concept) {
            this.electricSystemSelected = '';
            this.selectedValue = $event;
            this.getUnitProgramSelect();
            this.filtersSelect.options.forEach((data: MatOption) => data.deselect());
            this.preselectAllUnitPrograms(true);
        }
        this.isButtonDisabled();
    }

    singleDataChanged($event: any) {
        this.singleDateSelected = $event.newValue;
    }

    isButtonDisabled(): boolean {
        if (this.selectedValue === '' || this.selectedValue === undefined) {
            return true;
        } else {
            return false;
        }
    }

    getOption(event: any, index: number) {
        switch (index) {
            case 0:
                this.electricSystemSelected = event;
                break;
            case 1:
                if (this.electricSystemSelected !== 'Ceuta-Melilla') {
                    if (index === 1) {
                        this.unitProgramSelected = event;
                    } else {
                        this.unitProgramSelected = [];
                    }
                } else {
                    this.unitProgramsFormControl = [];
                    this.myselectedUnits = [];
                    let arrayUnitPrograms: any[] = [];

                    arrayUnitPrograms = this.unitProgramSelected;

                    // Para que estén todas las opciones de CEUTA_MELILLA seleccionadas por defecto:
                    for (let i = 0; i < arrayUnitPrograms.length; i++) {
                        this.unitProgramsFormControl.push(arrayUnitPrograms[i]);

                        this.myselectedUnits.push(arrayUnitPrograms[i].value);
                    }
                    this.unitProgramSelected = this.unitProgramsFormControl;
                    this.unitsForm = new FormControl(this.myselectedUnits);
                }
                break;
            case 2:
                this.statusSelected = event;
                break;
            case 3:
                this.userSelected = event;
                break;
        }
        this.isButtonDisabled();
    }

    clearFilters() {
        this.reset$.next();
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.selectedValue = '';
        this.dateInitSelected = '';
        this.dateEndSelected = '';
        this.userSelected = '';
        this.statusSelected = '';
        this.singleDateSelected = '';
        this.unitProgramSelected = [];
        this.electricSystemSelected = '';
        this.unitProgramsFormControl = [];
        this.unitsForm.reset();
        this.dataBusiness = undefined;

        for (let i = 0; i < this.optionValues.length; i++) {
            this.optionValues[i] = '';
        }

        this.preselectAllUnitPrograms(true);
        this.isButtonDisabled();
    }

    dateComplete($event: any) {
        this.dateInitSelected = $event.split('~')[0];
        this.dateEndSelected = $event.split('~')[1];
    }

    singleDateComplete($event: any) {
        this.singleDateSelected = $event;
    }

    //>>>>>>>>>>>>> 2 FUNCIONES PARA MOSTRAR/OCULTAR COLUMNAS
    checkvalue(dataHeaders: any) {
        this.dataHeadersValueSelect = dataHeaders;
        this.columns = [];

        for (let i = 0; i < dataHeaders.length; i++) {
            let columnId = dataHeaders[i].id;
            this.columns.push(columnId);
        }
    }

    botonClick() {
        if (this.elemento) {
            this.elemento = false;
        } else {
            this.elemento = true;
        }

        if (!this.ocultar) {
            this.ocultar = true;
        } else {
            this.ocultar = false;
        }
    }

    filterDataHeader() {
        if (this.dataHeaders2 != this.dataHeaders) {
            this.dataHeaders2 = this.dataHeaders;
            this.dataHeadersValueSelect = [];
            this.dataHeaderChange = true;
        }

        if (this.dataHeaderChange === true) {
            for (let i = 0; i < this.dataHeaders?.length; i++) {
                if (this.dataHeaders[i].isVisible === true! || this.dataHeaders[i].isVisible == undefined) {
                    this.dataHeadersValueSelect.push(this.dataHeaders[i]);
                }
            }
            this.columns = [];

            for (let i = 0; i < this.dataHeadersValueSelect.length; i++) {
                let columnId = this.dataHeadersValueSelect[i].id;
                this.columns.push(columnId);
            }
        }
        this.dataHeaderChange = false;
        return this.dataHeaders;
    }

    openModalExcel() {
        this._exportService.openDialog(
            this.dataBusiness,
            [KeysEnvios.ENVIOS_RESULTADOS, this.subfilters],
            this.totalRegisters
        );
    }

    pagination(event: any) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        // Filtrar por ...
        if (!this.sortActive) {
            this.sortActive = SortBy.ID;
        }

        // Ordenar por ASC o DESC
        if (!this.sortDir) {
            this.sortDir = 'asc';
        }

        this._enviosService
            .getTablaResultados(event.pageIndex + 1, event.pageSize, this.sortActive, this.sortDir, this.searchParam)
            .subscribe((res: any) => {
                this.dataBusiness = res.data;
                this.totalRegisters = res.page.totalElements;
                this.numPage = res.page.number;
                this.pageSize = res.page.size;
                this.totalPages = res.page.totalPages;

                // Para incluir columna sendSystem que está en otra tabla:
                this.addSendSystems();
            });
    }

    addSendSystems(): void {
        this.dataBusiness.forEach((element: any) => {
            const [firstUnitProgramCode] = element.programUnitCode.split(',');
            const unitProgram = this.unitProgramListCopy.find((e: any) => e.name === firstUnitProgramCode);
            if (unitProgram) {
                element.sendSystem = unitProgram.sendSystem;
            }
        });
    }

    isMultiple(index: number): boolean {
        if (index === 1) {
            return true;
        }
        return false;
    }

    getDataTable(event: any) {
        let search = '';
        if (this.selectedValue === TipoEnvios.OPTIONAL_SETTLEMENT_CODE) {
            this.transtalionCodeSelectedType = this.selectedValue;
            search += 'type=optionalSettlement,';
        } else if (this.selectedValue === TipoEnvios.DAILY_FORECAST_CODE) {
            this.transtalionCodeSelectedType = this.selectedValue;
            search += 'type=dailyForecast,';
        } else if (this.selectedValue === TipoEnvios.WEEKLY_FORECAST_CODE) {
            this.transtalionCodeSelectedType = this.selectedValue;
            search += 'type=weeklyForecast,';
        }

        if (this.dateInitSelected !== undefined && this.dateInitSelected !== '') {
            search += 'initDate>=' + this.dateInitSelected + ',';
            search += 'endDate<=' + this.dateEndSelected + ',';
        }

        if (this.singleDateSelected !== undefined && this.singleDateSelected !== '') {
            search += 'sendDate=' + this.singleDateSelected + ',';
        }

        if (this.unitProgramSelected !== undefined && this.unitProgramSelected.length !== 0) {
            let aux: any = '';
            for (let i = 0; i < this.unitProgramSelected.length; i++) {
                if (this.unitProgramSelected[i].name) {
                    aux += this.unitProgramSelected[i].name + ';';
                } else {
                    aux += this.unitProgramSelected[i] + ';';
                }
            }

            if (aux.slice(-1) === ';') {
                aux = aux.slice(0, -1);
            }
            search += 'programUnitCode=(' + aux + '),';
        }

        if (this.statusSelected !== undefined && this.statusSelected !== '') {
            search += 'status=' + this.statusSelected + ',';
        }

        if (this.userSelected !== undefined && this.userSelected !== '') {
            search += 'creationUser=' + this.userSelected;
        }

        if (search.slice(-1) === ',') {
            search = search.slice(0, -1);
        }

        this.searchParam = search;

        this._enviosService.getTablaResultados(1, 10, 'id', 'asc', search).subscribe((res: any) => {
            this.dataBusiness = res.data;
            this.totalRegisters = res.page.totalElements;
            this.numPage = res.page.number;
            this.pageSize = res.page.size;
            this.totalPages = res.page.totalPages;

            // Para incluir columna sendSystem que está en otra tabla:
            this.addSendSystems();
        });

        let respHeaders: any = [];
        respHeaders = this._loginService.getHeaderSession();
        respHeaders = JSON.parse(respHeaders);

        // Relleno los headers
        for (let i = 0; i < respHeaders.data.length; i++) {
            if (respHeaders.data[i].name == 'envios_resultados') {
                this.dataHeaders = respHeaders.data[i].headers;
            }
        }
        this.isButtonDisabled();
    }

    async getUsers() {
        const response$ = await firstValueFrom(this._globalService.getUsers());
        const idSubfilter = this.subfilters.findIndex((filter: any) => filter.name === 'Usuario');
        this.subfilters[idSubfilter].data = [];

        this.subfilters[idSubfilter].data = response$.data.map((register: any) => {
            return { name: register.name, value: register.username, surnames: register.surnames };
        });
    }

    async getUnitProgramSelect() {
        const { data: unitPrograms } = await firstValueFrom(this._enviosService.getUnidadPrograma(this.selectedValue));
        this.unitProgramList = unitPrograms;
        this.electricSystemList = [];

        for (let i = 0; i < unitPrograms.length; i++) {
            this.electricSystemList.push(unitPrograms[i].sendSystem);
        }

        // Quita los sistemas eléctricos repetidos:
        let electricSystemFilter: any = Array.from(new Set(this.electricSystemList));
        electricSystemFilter = electricSystemFilter.map(function (word: string) {
            word === 'PENINSULA'
                ? (word = 'Península')
                : (word =
                      word.charAt(0).toUpperCase() +
                      word
                          .slice(1)
                          .toLowerCase()
                          .replace(/-(.)/g, (match) => '-' + match.charAt(1).toUpperCase()));
            return word;
        });
        this.electricSystemList = electricSystemFilter;
        let copy: FiltroSelect[] = [];

        // Rellena la lista de sistemas eléctricos:
        for (let i = 0; i < this.electricSystemList.length; i++) {
            copy[i] = { name: '', value: '', code: '' };
            copy[i].name = this.electricSystemList[i];
            copy[i].value = this.electricSystemList[i];
            copy[i].code = this.electricSystemList[i];
        }

        this.electricSystemList = copy;
        this.subfilters[0].data = this.electricSystemList;

        // Rellena la lista de unidades de programa:
        this.unitProgramListCopy = [];

        for (let i = 0; i < this.electricSystemList.length; i++) {
            for (let j = 0; j < unitPrograms.length; j++) {
                unitPrograms[j].sendSystem === 'PENINSULA'
                    ? (unitPrograms[j].sendSystem = 'Península')
                    : (unitPrograms[j].sendSystem =
                          unitPrograms[j].sendSystem.charAt(0).toUpperCase() +
                          unitPrograms[j].sendSystem
                              .slice(1)
                              .toLowerCase()
                              .replace(/-(.)/g, (match: any) => '-' + match.charAt(1).toUpperCase()));
                if (this.electricSystemList[i].name === unitPrograms[j].sendSystem) {
                    let unitProgramCopy: any = {
                        name: unitPrograms[j].programUnitCode,
                        value: unitPrograms[j].programUnitCode,
                        code: this.electricSystemList[i].name,
                        sendSystem: unitPrograms[j].sendSystem,
                    };
                    this.unitProgramListCopy.push(unitProgramCopy);
                }
            }
        }

        this.unitProgramList = this.unitProgramListCopy;
        this.subfilters[1].data = this.unitProgramList;

        if (this.isFirstTime) {
            this.isFirstTime = false;
            this.preselectAllUnitPrograms();
        }
        if (this.selectedValue === TipoEnvios.OPTIONAL_SETTLEMENT) {
            this.preselectAllUnitPrograms(true);
        } else {
            this.preselectAllUnitPrograms();
        }
    }

    // Para rellenar el select de unidades de programa (dependiendo de la opción elegida en sistemas eléctricos):
    filter(opcion: any, index: number) {
        if (index === 0) {
            let subfiltersCopy: any[] = [];

            for (let i = 0; i < this.unitProgramListCopy.length; i++) {
                if (this.unitProgramListCopy[i].code === opcion.name) {
                    // Controla que no haya unidades de programa repetidas:
                    if (
                        !this.subfilters[1].data.some((x: any) => {
                            x.code == this.unitProgramListCopy[i].name;
                        })
                    ) {
                        subfiltersCopy.push(this.unitProgramListCopy[i]);
                        this.subfilters[1].data = subfiltersCopy;
                    }
                }
            }
            this.preselectAllUnitPrograms();
        }
    }

    preselectAllUnitPrograms(clear?: boolean) {
        this.unitProgramSelected = [];
        this.unitProgramsFormControl = [];
        this.myselectedUnits = [];
        let arrayUnitPrograms: any[] = [];

        if (clear) {
            arrayUnitPrograms = this.unitProgramList;
        } else {
            arrayUnitPrograms = this.subfilters[1].data;
        }

        // Para que estén todas las opciones seleccionadas por defecto:
        for (let i = 0; i < arrayUnitPrograms.length; i++) {
            this.unitProgramsFormControl.push(arrayUnitPrograms[i]);

            this.myselectedUnits.push(arrayUnitPrograms[i].value);
        }
        this.unitProgramSelected = this.unitProgramsFormControl;

        this.unitsForm = new FormControl(this.myselectedUnits);
    }

    downloadFile(element: any, fileType: any) {
        if (fileType === 'fileSend') {
            this._enviosService.downloadFile('s3://' + element.fileSend);
        } else if (fileType === 'fileAnswer') {
            this._enviosService.downloadFile('s3://' + element.fileAnswer);
        }
    }
    transformDate(date: string, largeFormat: boolean) {
        const datePipe = new DatePipe('en-US');
        let dates = new Date(date);
        let formatDate;
        if (isNaN(dates.getTime()) || date === null) {
            formatDate = '-';
        } else {
            if (largeFormat) {
                formatDate = datePipe.transform(
                    dates,
                    `${this._headerTitleService.currentLang !== 'en' ? 'dd/MM/yyyy' : 'yyyy/MM/dd'} HH:mm:ss`
                );
            } else {
                formatDate = datePipe.transform(
                    dates,
                    `${this._headerTitleService.currentLang !== 'en' ? 'dd/MM/yyyy' : 'yyyy/MM/dd'}`
                );
            }
        }
        return formatDate;
    }
}
