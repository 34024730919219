import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ExportService } from 'src/app/services/export.service';
import { DownloadExcelComponent } from 'src/app/shared/components/download-excel/download-excel.component';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { OriginEnum, StatusEnum } from 'src/app/shared/enums/cargas';
import { Tabla } from 'src/app/shared/models/files.interface';
import { Permisos } from 'src/app/shared/models/permisos.interface';
import { CargasService } from '../../services/cargas.service';
import { AddModalResultsComponent } from './add-modal/add-modal.component';
import { DeleteModalResultsComponent } from './delete-modal/delete-modal-component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { EditModalResultsComponent } from './edit-modal/edit-modal.component';
import { HttpHeaders } from '@angular/common/http';
import { DownloadModel } from 'src/app/services/download.service';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { CargasServiceService } from '../../services/cargas-service.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/core/services/login.service';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import { DatePipe } from '@angular/common';
import { HeaderTitleService } from 'src/app/services/header-title.service';

const JSZipUtils = require('jszip-utils');

export interface DialogData {
    value: any;
}

export interface DownloadArray {
    id: number;
    name: string;
    fileS3: string;
}

@Component({
    selector: 'app-tabla-cargas',
    templateUrl: './tabla-cargas.component.html',
    styleUrls: ['./tabla-cargas.component.scss'],
})
export class TablaCargasComponent implements OnInit, AfterViewInit {
    @ViewChild(MatTable) table: MatTable<any>;
    @ViewChild('paginator') paginator: MatPaginator;

    @Output() sort: EventEmitter<any> = new EventEmitter();
    @Output() pagination: EventEmitter<any> = new EventEmitter();

    @Input() dataBusiness: any[];
    @Input() dataHeaders: IColumnsTable[] = [];
    @Input() dataHeaders2: IColumnsTable[] = [];
    @Input() dataHeaders_valueSelect: IColumnsTable[] = [];
    @Input() permissions?: Permisos;
    @Input() filtersTable: any[];
    @Input() idFilters: any;
    @Input() columns: any[];
    @Input() filters: any;
    @Input() totalRegisters: number;
    @Input() totalPages: number;
    @Input() numPage: number;
    @Input() pageSize: number;
    @Input() resetTableSubject: Subject<boolean> = new Subject<boolean>();

    private auth_token = this._loginService.getTokenSession();
    private headers = new HttpHeaders({ Authorization: `Bearer ${this.auth_token}` });
    private requestOptions = { headers: this.headers };

    pageSizeOptions: number[] = [5, 10, 25, 100];
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    dataSource: any;
    paginatorDataSource: MatTableDataSource<any>;
    displayedColumns: any;
    infotabla: string[];
    dataShow: Tabla[] = [];
    message: string = '';
    action: string = '';
    config: any;
    element = false;
    ocultar = true;
    dataHeaderChange = true;
    isDisabled: boolean = false;
    nameMultipleDown: any;
    downloadArray: DownloadArray[] = [];
    downloadAll: boolean = true;
    files: DownloadModel[] = [];
    fechaInit: any[] = [];
    fechaEnd: any[] = [];
    hora: any[] = [];
    formattedStatus: string[] = [];
    formattedOrigin: string[] = [];
    dataAll: DownloadArray[] = [];
    isEnglishSelected: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DownloadExcelComponent>,
        public dialog: MatDialog,
        public DeleteModalComponent: MatDialog,
        public EditModalComponent: MatDialog,
        public AddModalComponent: MatDialog,
        public ErrorModalComponent: MatDialog,
        public cargasServiceService: CargasServiceService,
        public readonly _headerTitleService: HeaderTitleService,
        private excelService: ExportService,
        private _snackBar: MatSnackBar,
        private exportService: ExportService,
        private cargasService: CargasService,
        private _loginService: LoginService,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<any>(this.dataBusiness);
        this.message = this._translateService.instant('PAGE.MODALS.LOADING_MESSAGE_EXCEL');
        this.action = this._translateService.instant('PAGE.MODALS.CLOSE');
        this.resetTableSubject.subscribe((response) => {
            if (response) {
                this.uncheckSelectedDocuments();
            }
        });
    }

    ngAfterViewInit(): void {
        this.paginatorDataSource = new MatTableDataSource(this.dataBusiness);
        this.paginatorDataSource.paginator = this.paginator;
    }

    pageChange($event: any) {
        this.pagination.emit($event);
    }

    sortData(sort: Sort) {
        this.sort.emit({ sort: sort, id: this.idFilters[0] });
    }

    exportExcel(): void {
        this.excelService.exportToExcel(this.dataBusiness, 'export');
    }

    openModalExcel() {
        this.exportService.openDialog(this.dataBusiness, this.idFilters, this.totalRegisters);
    }

    openModalErrors(error: any) {
        error.sort((a: any, b: any) => a.rowNum - b.rowNum);
        const dialogRef = this.ErrorModalComponent.open(ErrorModalComponent, {
            data: { value: error },
        });
    }

    delete(pos: any) {
        const dialogRef = this.DeleteModalComponent.open(DeleteModalResultsComponent);
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let idTable = this.dataBusiness[pos].id;
                this.dataBusiness.splice(pos, 1);
                this.table.renderRows();
            }
        });
    }

    edit(pos: any) {
        const dialogRef = this.EditModalComponent.open(EditModalResultsComponent, {
            data: { header: this.dataHeaders_valueSelect, value: this.dataBusiness[pos], filter: this.filters },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result.resp) {
                this.dataBusiness[pos] = result.data.value;
                this.table.renderRows();
            }
        });
    }

    add() {
        const dialogRef = this.AddModalComponent.open(AddModalResultsComponent, {
            data: { header: this.dataHeaders_valueSelect, value: this.dataBusiness, filter: this.filters },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result.resp) {
                this.dataBusiness.push(result.newValue);
                this.table.renderRows();
            }
        });
    }

    // Download Single
    downloadButton(element: any) {
        this.cargasService.downloadFile(element.fileS3, element.name);
    }

    checkDownloadList(file: DownloadArray) {
        const fileSelected: number = this.downloadArray.findIndex((x) => x.id === file.id);
        if (fileSelected === -1) {
            return false;
        } else {
            return true;
        }
    }

    // Checkbox list
    // Método para guardar los elementos de la lista a ser descargados
    downloadList(element: any) {
        // Dentro del array de objetos "downloadArray" se guardará el id/fila seleccionados en la web ("pos"), a ser descargados
        // la variable "index" es la posición en la que está guardado dicho id ("pos") dentro de "downloadArray"
        const index: number = this.downloadArray.findIndex((x) => x.id === element['id']);

        // Si index es "-1" significa que no ha encontrado "pos" en "downloadArray", así que lo añade
        if (index == -1) {
            this.downloadArray.push({
                id: element['id'],
                name: element['name'],
                fileS3: `${environment.apiUrl}/file-process/v0/download?fileS3=${element['fileS3']}`,
            });
        } else if (index !== -1) {
            // Si "index" es positivo, el "pos" ya estaba en la lista (ergo, se está de-seleccionando), así que se elimina de "downloadArray"
            this.downloadArray.splice(index, 1);
        }
    }

    // Variable that saves all the data of the /file-process/ endpoint
    // Used for downloading the entire set of files (i.e. downloading without checking any box in Descarga)
    saveData(data: any) {
        data.forEach((element: any) => {
            if (element['errors'].length <= 0) {
                this.dataAll.push({
                    id: element['id'],
                    name: element['name'],
                    fileS3: `${environment.apiUrl}/file-process/v0/download?fileS3=${element['fileS3']}`,
                });
            }
        });
    }

    // MÉTODO PARA DESCARGAR EN ZIP (descargas múltiples)
    downloadZip() {
        let count = 0;
        const zip = new JSZip();

        //<<<<<<<<<<<<<<<<< CODIGO INÚTIL, A BORRAR, PERO LOCALIZADO AQUÍ PARA PRUEBAS DE AUTENTIFICACIÓN EN DESCARGAS (detalles abajo)
        // Éste es el código localizado en jszip-utils, el cual ha tenido problemas de importación antes
        // es con ésta función con la que se comprimen las descargas en un ZIP para poder descargar éste más adelante
        // el problema es que ésta función no admite autorización de normal (tal como viene especificado en su carpeta de node_modules)
        // he intentado pillar su index.js, copiarlo aquí para sobreescribirlo, e inculcarle el auth, pero parece que no funciona.
        JSZipUtils.getBinaryContent = (path: any, options: any, auth: any) => {
            let promise, resolve: any, reject: any;
            let callback: any;
            auth = this.requestOptions;

            if (!options) {
                options = {};
            }
            // backward compatible callback
            if (typeof options === 'function') {
                callback = options;
                options = {};
            } else if (typeof options.callback === 'function') {
                // callback inside options object
                callback = options.callback;
            }

            if (!callback && typeof Promise !== 'undefined') {
                promise = new Promise(function (_resolve, _reject) {
                    resolve = _resolve;
                    reject = _reject;
                });
            } else {
                resolve = function (data: any) {
                    callback(null, data);
                };
                reject = function (err: any) {
                    callback(err, null);
                };
            }
            /*
             * Here is the tricky part : getting the data.
             * In firefox/chrome/opera/... setting the mimeType to 'text/plain; charset=x-user-defined'
             * is enough, the result is in the standard xhr.responseText.
             * cf https://developer.mozilla.org/En/XMLHttpRequest/Using_XMLHttpRequest#Receiving_binary_data_in_older_browsers
             * In IE <= 9, we must use (the IE only) attribute responseBody
             * (for binary data, its content is different from responseText).
             * In IE 10, the 'charset=x-user-defined' trick doesn't work, only the
             * responseType will work :
             * http://msdn.microsoft.com/en-us/library/ie/hh673569%28v=vs.85%29.aspx#Binary_Object_upload_and_download
             *
             * I'd like to use jQuery to avoid this XHR madness, but it doesn't support
             * the responseType attribute : http://bugs.jquery.com/ticket/11461
             */
            try {
                let xhr = JSZipUtils.createXHR();
                xhr.open('GET', path, true);
                // recent browsers
                if ('responseType' in xhr) {
                    xhr.responseType = 'arraybuffer';
                }
                xhr.withCredentialds = true;
                if (auth) {
                    xhr.setRequestHeader('Authorization', 'Basic ' + auth);
                }
                // older browser
                if (xhr.overrideMimeType) {
                    xhr.overrideMimeType('text/plain; charset=x-user-defined');
                }
                xhr.onreadystatechange = function (event: any) {
                    // use `xhr` and not `this`... thanks IE
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200 || xhr.status === 0) {
                            try {
                                resolve(JSZipUtils._getBinaryFromXHR(xhr));
                            } catch (err: any) {
                                reject(new Error(err));
                            }
                        } else {
                            reject(new Error('Ajax error for ' + path + ' : ' + this.status + ' ' + this.statusText));
                        }
                    }
                };
                if (options.progress) {
                    xhr.onprogress = function (e: any) {
                        options.progress({
                            path: path,
                            originalEvent: e,
                            percent: (e.loaded / e.total) * 100,
                            loaded: e.loaded,
                            total: e.total,
                        });
                    };
                }
                xhr.send();
            } catch (e: any) {
                reject(new Error(e), null);
            }
            // returns a promise or undefined depending on whether a callback was
            // provided
            return promise;
        };
        //<<<<<<<<<<<<<<<<< FIN DE CODIGO INÚTIL, A BORRAR, PERO LOCALIZADO AQUÍ PARA PRUEBAR DE AUTENTIFICACIÓN EN DESCARGAS (detalles abajo)
        let arrWay = this.downloadArray;
        this.downloadArray.forEach((element) => {
            const filename = element['fileS3'].split('/')[element['fileS3'].split('/').length - 1];
            let token = this.requestOptions;
            JSZipUtils.getBinaryContent(element['fileS3'], function (err: Error, data: any, _token2: any = token) {
                if (err) {
                    throw err;
                }

                zip.file(filename, data, { binary: true });
                count++;

                if (count === arrWay.length) {
                    zip.generateAsync({ type: 'blob' }).then((content) => {
                        const objectUrl: string = URL.createObjectURL(content);
                        const link: any = document.createElement('a');

                        link.download = 'Acciona_Cargas.zip';
                        link.href = objectUrl;
                        link.click();
                    });
                }
            });
        });
    }

    // Método para el botón "Sovlentar Errores"
    fix(pos: any) {
        this.cargasService.fixError(pos).subscribe((data) => {
            pos.isRunning = !pos.isRunning;
        });
    }

    protected getFormatedDate(date: string): string {
        let formatDate: string | null;
        if (date !== null) {
            const datePipe = new DatePipe('en-US');
            formatDate = datePipe.transform(
                date,
                `${this._headerTitleService.currentLang === 'en' ? 'yyyy/MM/dd HH:mm:ss' : 'dd/MM/yyyy HH:mm:ss'}`
            );
            formatDate = formatDate ? formatDate : '-';
        } else {
            formatDate = '-';
        }
        return formatDate;
    }

    protected mapFileOrigin(pos: any): string {
        switch (pos.origin) {
            case 'ATR':
                this.formattedOrigin[pos.id] = OriginEnum.ATR;
                break;
            case 'DISTRIBUTOR':
                this.formattedOrigin[pos.id] = OriginEnum.DISTRIBUTOR;
                break;
            case 'ESIOS':
                this.formattedOrigin[pos.id] = OriginEnum.ESIOS;
                break;
            case 'HUB':
                this.formattedOrigin[pos.id] = OriginEnum.HUB;
                break;
            case 'MANLOAD':
                this.formattedOrigin[pos.id] = OriginEnum.MANLOAD;
                break;
            case 'MANUAL':
                this.formattedOrigin[pos.id] = OriginEnum.MANUAL;
                break;
            case 'METEOLOGICA':
                this.formattedOrigin[pos.id] = OriginEnum.METEOLOGICA;
                break;
            case 'REE':
                this.formattedOrigin[pos.id] = OriginEnum.REE;
                break;
            default:
                this.formattedOrigin[pos.id] = pos.origin;
                break;
        }
        return this.formattedOrigin[pos.id];
    }

    mapFileStatus(pos: any): string {
        switch (pos.status) {
            case 'ATR_CUPS_ERROR':
                this.formattedStatus[pos.id] = StatusEnum.CUPS_NOT_EXISTS;
                break;
            case 'FILE_ALREADY_PROCESSED':
                this.formattedStatus[pos.id] = StatusEnum.FILE_ALREADY_PROCESSED;
                break;
            case 'INIT_CATALOG':
            case 'INIT_PROCESS':
            case 'OK_CATALOG':
            case 'OK_CATALOG_WITH_ERRORS':
                this.formattedStatus[pos.id] = StatusEnum.IN_PROCESS;
                break;
            case 'OK_PROCESS':
                this.formattedStatus[pos.id] = StatusEnum.COMPLETED;
                break;
            case 'KO_CATALOG_SYSTEM':
            case 'KO_PROCESS_SYSTEM':
                this.formattedStatus[pos.id] = StatusEnum.ERROR;
                break;
            case 'KO_SOLVED':
                this.formattedStatus[pos.id] = StatusEnum.ERROR_SOLVED;
                break;
            case 'OK_PROCESS_WITH_ERRORS':
                this.formattedStatus[pos.id] = StatusEnum.COMPLETED_WITH_ERRORS;
                break;
            case 'SCHEMA_ATR_INVALID':
                this.formattedStatus[pos.id] = StatusEnum.SCHEMA_ATR_INVALID;
                break;
            case 'ATR_POWER_PERIOD_ERROR':
                this.formattedStatus[pos.id] = StatusEnum.ATR_POWER_PERIOD_ERROR;
                break;
            case 'ATR_ENERGY_PERIOD_ERROR':
                this.formattedStatus[pos.id] = StatusEnum.ATR_ENERGY_PERIOD_ERROR;
                break;
            case 'ATR_CONTRACT_CONCEPTS_ERROR':
                this.formattedStatus[pos.id] = StatusEnum.ATR_CONTRACT_CONCEPTS_ERROR;
                break;
            case 'ATR_POWER_COMPARISON_ERROR':
                this.formattedStatus[pos.id] = StatusEnum.ATR_POWER_COMPARISON_ERROR;
                break;
            case 'ATR_INVOICE_FISCAL_CODE_DUPLICATED_ERROR':
                this.formattedStatus[pos.id] = StatusEnum.ATR_INVOICE_FISCAL_CODE_DUPLICATED_ERROR;
                break;
            default:
                break;
        }
        return this.formattedStatus[pos.id];
    }

    //>>>>>>>>>>>>>FUNCIONES PARA MOSTRAR/OCULTAR COLUMNAS
    checkvalue(dataHeaders2: any) {
        this.dataHeaders_valueSelect = dataHeaders2;
        this.columns = [];
        dataHeaders2.forEach((dataHeader: any) => {
            let columnId;
            columnId = dataHeader.id;
            this.columns.push(columnId);
        });
    }

    botonClick() {
        this.element = !this.element;
        this.ocultar = !this.ocultar;
    }

    filterDataHeader() {
        if (this.dataHeaders2 != this.dataHeaders) {
            this.dataHeaders2 = this.dataHeaders;
            this.dataHeaders_valueSelect = [];
            this.dataHeaderChange = true;
        }
        if (this.dataHeaderChange === true) {
            this.dataHeaders.forEach((dataHeader: any) => {
                if (dataHeader.isVisible === true! || dataHeader.isVisible == undefined) {
                    this.dataHeaders_valueSelect.push(dataHeader);
                }
            });
            this.columns = [];
            this.dataHeaders_valueSelect.forEach((dataHeaderValueSelect: any) => {
                let columnId;
                columnId = dataHeaderValueSelect.id;
                this.columns.push(columnId);
            });
        }
        this.dataHeaderChange = false;
        return this.dataHeaders;
    }
    //<<<<<<<<<<<<FUNCIONES PARA MOSTRAR/OCULTAR COLUMNAS

    getMultipleDownloadURLs() {
        if (this.downloadArray.length === 0) {
            return;
        }

        let multipleDownloadURLs: any;
        multipleDownloadURLs = [];
        this.nameMultipleDown = [];
        let nameFilesToDownload: any;
        let zip = new JSZip();
        let cont = 0;
        this.downloadArray.forEach((download) => {
            multipleDownloadURLs.push(download.fileS3); // Se guardan las URL en array
            this.nameMultipleDown.push(download.name); // Se guardan nombres de archivos en array
        });

        multipleDownloadURLs.forEach((downloadUrl: any, i: number) => {
            this.cargasServiceService.downloadFileMultiple(downloadUrl).subscribe((data: any) => {
                data = new Blob([data], {
                    type: 'application/zip',
                });
                cont++;
                nameFilesToDownload = this.nameMultipleDown[i];
                nameFilesToDownload = nameFilesToDownload.toString();
                zip.file(nameFilesToDownload, data);

                if (cont === multipleDownloadURLs.length) {
                    this._translateService.get('PAGE.MODALS').subscribe((message) => {
                        this._snackBar.open(message['DOWNLOAD_IN_PROCESS'], message['ACTION_CLOSE'], {
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                        });
                    });
                    zip.generateAsync({ type: 'blob' }).then((data) => {
                        FileSaver.saveAs(data, 'resultados_cargas.zip');
                    });
                }

                setTimeout(() => {
                    this._snackBar.dismiss();
                }, 4000);
            });
        });
    }

    uncheckSelectedDocuments() {
        this.downloadArray = [];
    }

    private _addZeroToValuesLessThanTen(value: number): string {
        return value < 10 ? `0${value}` : value.toString();
    }
}
