import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormControl, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { Concepto } from 'src/app/shared/models/concepto.interface';
import { ListaFiltros, Filtro, FiltroSelect } from 'src/app/shared/models/filtros.interface';
import { MaestroService } from '../../services/maestro.service';
import { FiltroSelectComponent } from '../../../../shared/components/filtros/filtro-select/filtro-select.component';
import {
    TipoMaestro,
    MaestrosAWS,
    MaestrosCRM,
    FiltroSelectConceptoContrato,
    KeysMaestro,
    PerfilesREE,
} from '../../../../shared/enums/maestros';
import { ContractClientType, liqTypeEnum } from 'src/app/shared/enums/contractEnums';
import { ProcesosService } from 'src/app/pages/procesos/services/procesos.service';
import { LoginService } from 'src/app/core/services/login.service';
import { Months } from 'src/app/shared/components/custom-cron/custom-cron.enum';
import { ErrorHttpService } from '../../../../services/error-http.service';
import { TranslateService } from '@ngx-translate/core';
import { ClosedStatus } from '../../../../shared/enums/contractEnums';
import { HeaderTitleService } from 'src/app/services/header-title.service';
@Component({
    selector: 'app-select-maestro',
    templateUrl: './select-maestro.component.html',
    styleUrls: ['./select-maestro.component.scss'],
})
export class SelectMaestroComponent implements OnInit, OnDestroy {
    @Output() optionSelectedId = new EventEmitter<string>();
    @Output() resetTable = new EventEmitter<string>();
    @Output() OptionFilters = new EventEmitter<any>();
    @Output() changeSelectReset = new EventEmitter<any>();
    @Output() filtersList = new EventEmitter<any>();
    @Output() chargeRegions = new EventEmitter<any>();

    @Input() tipoConcepto: string;
    subfilters: Filtro[] | undefined | any;
    displayedSubfilters: Filtro[] = [];
    selectSubfilter: FiltroSelect[];
    selectCopy: FiltroSelectComponent;
    conceptosMaestro: Concepto[] = [];
    filters: ListaFiltros[] = [];
    disableSelect = new UntypedFormControl(false);
    subcriptions: Subscription[] = [];
    tablaSeleccionada: string | undefined;
    selectId: any;
    isDisabled: boolean = true;
    inputValues: { id: string; value: string }[] = [];
    resetSelectedOption: any;
    resetSubfilter: any;
    showinput: any;
    showSelect: boolean = true;
    selectedName: string;
    intermedio: boolean = true;
    resetCups: boolean;
    isFirstTime: boolean = true;
    userID: any;

    yearValue: string;

    formGroup = new FormGroup({
        month: new FormControl(undefined),
    });

    months = Array.from({ length: 12 }, (v, k) => k + 1);

    @ViewChildren('selects') selects: QueryList<FiltroSelectComponent>;
    defaultSelect: string = '';

    specialFeeActivated?: boolean = undefined;
    disableSelectFirst: boolean = false;

    // booleano para controlar si filtrar los filtros por ejemplo por codigo o por descripcion
    searchBy: boolean = this.maestroService.searchBy;
    electricSystemSelected: any;
    unitProgramListNoFilter: any;
    unitProgramList: any[];
    programUnit: any;
    selectedInitialForecasts: any;
    cuentaNegociadoraActive: boolean;
    eventvalue: string;
    cuentaNegActive: boolean;
    electricSystemList: any;
    userRoles: string[] = [];
    readonly maestrosCRMEnum = MaestrosCRM;
    isDateInvalid: boolean = false;

    unitsForm: FormControl = new FormControl();
    contractStatuses: any[] = [];

    constructor(
        private maestroService: MaestroService,
        private globalService: GlobalService,
        private procesosService: ProcesosService,
        private _loginService: LoginService,
        private _errorHttpServ: ErrorHttpService,
        private _translateService: TranslateService,
        protected readonly _headerTitleService: HeaderTitleService
    ) {}

    ngOnInit(): void {
        this.getFilters(this.maestroService.searchBy);
        this.getConceptos();
        this.resetCups = false;
    }

    ngOnChange(): void {
        this.resetCups = false;
    }

    /**
     * Método para controlar el booleano de la variable de búsqueda donde se guardan todos los filtros seleccionados
     * @param searchBy  Boolean para controlar por qué parámetros filtrar
     */
    getFiltersBy(searchBy: [boolean, string]) {
        let subfilter = this.subfilters?.find((filter: any) => filter.key === searchBy[1]);
        if (subfilter) {
            subfilter.toggleState = searchBy[0];
        }
    }

    ngOnDestroy(): void {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.subcriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    /**
     * Método para obtener los distintos conceptos posibles para generar los informes
     */
    getConceptos() {
        if (this.tipoConcepto === TipoMaestro.AWS) {
            this.maestroService.getConceptsAWS().subscribe((res) => {
                this.conceptosMaestro = res;
            });
        } else {
            this.maestroService.getConceptsCRM().subscribe((res) => {
                this.conceptosMaestro = res;
            });
        }
    }

    monthDisplay(month: number): string {
        return Months[month];
    }

    /**
     * Método para gestionar los datos locales de los filtros usado normalmente al abrir un elemento html select
     * @param event Variable que trae la información sobre el filtro implicado en la operación
     */
    byOpenSelect(event: any) {
        if (event.newValue === true) {
            if (event.key === KeysMaestro.CUENTA_NEGOCIADORA || event.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA) {
                let subfilter = this.subfilters?.find(
                    (f: any) =>
                        f.key === KeysMaestro.CUENTA_NEGOCIADORA || f.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA
                );
                if (subfilter) {
                    subfilter.data = [];
                }
            } else if (event.key === KeysMaestro.ESTADO_CONTRATO) {
                let contractState: any = [];
                let description: string = '';
                this.maestroService.getContractState().subscribe((status) => {
                    status.data.forEach((element: any) => {
                        switch (element.description) {
                            case 'Pre-Contrato':
                                description = ClosedStatus.PRE_CONTRATO;
                                break;
                            case 'Pendiente Datos':
                                description = ClosedStatus.PENDING;
                                break;
                            case 'Cancelado-Rechazado':
                                description = ClosedStatus.CANCELED_REJECTED;
                                break;
                            default:
                                description = element.description;
                                break;
                        }
                        contractState.push({ name: description, value: element.status });
                    });

                    // Guardo en los subsfilters de estado contrato lo de la petición
                    this.subfilters?.forEach((subfilter: any) => {
                        if (subfilter.key === KeysMaestro.ESTADO_CONTRATO) {
                            subfilter.data = contractState;
                        }
                    });
                });
            } else if (this.selectId === MaestrosCRM.COBERTURA_CONTRATO && event.key === 'contractCode.contractCode') {
                let subfilter = this.subfilters?.find((f: any) => f.key === 'contractCode.contractCode');
                if (subfilter) {
                    subfilter.data = [];
                    subfilter.selected = '';
                }
            } else if (
                (this.selectId === MaestrosAWS.LIQUIDACION_POTESTATIVA ||
                    this.selectId === MaestrosAWS.CONTRATOS_FICTICIOS) &&
                event.key === 'programUnitt'
            ) {
                let subfilter = this.subfilters?.find((f: any) => f.key === 'programUnitt');

                if (subfilter) {
                    subfilter.data = [];

                    this.getUnitProgram();
                }
            } else if (
                event.key === KeysMaestro.CODIGO_CONTRATO ||
                event.key === KeysMaestro.CONTRACT_CODIGO_CONTRATO
            ) {
                let subfilter = this.subfilters?.find(
                    (f: any) => f.key === KeysMaestro.CODIGO_CONTRATO || f.key === KeysMaestro.CONTRACT_CODIGO_CONTRATO
                );
                if (subfilter) {
                    subfilter.data = [];
                }
            } else if (event.key === KeysMaestro.CRM_VARIABLE) {
                let crmVariables: any = [];
                this.maestroService.getCrmVariables().subscribe((price: any) => {
                    price.data.forEach((element: any) => {
                        crmVariables.push({ name: element });
                    });

                    // Guardo en los subsfilters de codigo contrato lo de la petición
                    this.subfilters?.forEach((subfilter: any) => {
                        if (subfilter.key === KeysMaestro.CRM_VARIABLE) {
                            subfilter.data = crmVariables;
                        }
                    });
                });
            } else if (event.key === KeysMaestro.VARIABLES_CONTRATOS_FICTICIOS) {
                let variables: any = [];
                this.maestroService.getCrmVariablesNew().subscribe((resp: any) => {
                    resp.data.forEach((element: any) => {
                        variables.push({ name: element.variable });
                    });
                    this.subfilters?.forEach((subfilter: any) => {
                        if (subfilter.key === KeysMaestro.VARIABLES_CONTRATOS_FICTICIOS) {
                            subfilter.data = variables;
                        }
                    });
                });
            } else if (
                (event.key === KeysMaestro.SOCIEDADES || event.key === KeysMaestro.CONTRACT_SOCIEDADES) &&
                this.selectId !== MaestrosCRM.COBERTURA_CONTRATO
            ) {
                let subfilter = this.subfilters?.find(
                    (f: any) => f.key === KeysMaestro.SOCIEDADES || f.key === KeysMaestro.CONTRACT_SOCIEDADES
                );
            } else if (
                event.key === KeysMaestro.SOCIEDADES ||
                event.key === KeysMaestro.CONTRACT_SOCIEDADES ||
                event.key === 'contract.idNumber' ||
                event.key === 'contractCode.idNumber'
            ) {
                let subfilter = this.subfilters?.find(
                    (f: any) =>
                        f.key === KeysMaestro.SOCIEDADES ||
                        f.key === KeysMaestro.CONTRACT_SOCIEDADES ||
                        f.key === 'contract.idNumber' ||
                        f.key === 'contractCode.idNumber'
                );
                if (subfilter) {
                    subfilter.data = [];
                    subfilter.selected = '';
                }
            } else if (event.key === KeysMaestro.CUENTA_NEGOCIADORA_GET) {
                let subfilter = this.subfilters?.find((f: any) => f.key === KeysMaestro.CUENTA_NEGOCIADORA_GET);
                if (subfilter) {
                    subfilter.data = [];
                    this.maestroService.getNegotiatingAccounts().subscribe((neAccounts) => {
                        neAccounts.data.forEach((element: any) => {
                            if (subfilter) {
                                subfilter.data.push({ name: element.negotiatingAccountCode });
                            }
                        });
                    });
                }
            } else if (event.key === 'instName') {
                let subfilter = this.subfilters?.find((f: any) => f.key === 'instName');
                if (subfilter) {
                    subfilter.data = [];
                }
            } else if (event.key === KeysMaestro.CODIGO_TARIFAS) {
                let subfilter = this.subfilters?.find((f: any) => f.key === KeysMaestro.CODIGO_TARIFAS);

                if (subfilter) {
                    subfilter.data = [];
                    this.maestroService.getFeesCode().subscribe((codFee) => {
                        codFee.data.forEach((element: any) => {
                            if (this.perfilesREEactive) {
                                if (element.codeRate === '2.0TD') {
                                    subfilter!.data.push({ name: element.codeRate });
                                }
                                if (element.codeRate === '3.0TD') {
                                    subfilter!.data.push({ name: element.codeRate });
                                }
                                if (element.codeRate === '3.0TDVE') {
                                    subfilter!.data.push({ name: element.codeRate });
                                }
                                return;
                            }
                            if (subfilter) {
                                subfilter.data.push({ name: element.codeRate });
                            }
                        });
                    });
                }
            } else if (
                event.key === KeysMaestro.TIPO_TERMINO &&
                this.selectId !== MaestrosAWS.PRECIOS_INDEXADOS &&
                this.selectId !== MaestrosAWS.PRECIOS_MENSUALES &&
                this.selectId !== MaestrosAWS.COEFICIENTE_PERDIDAS &&
                this.selectId !== MaestrosAWS.K_ESTIMADA
            ) {
                let subfilter = this.subfilters?.find((f: any) => f.key === KeysMaestro.TIPO_TERMINO);
                if (subfilter) {
                    subfilter.data = [];
                    this.maestroService.getTipoTerminoCode().subscribe((TermCode) => {
                        TermCode.data.forEach((element: any) => {
                            if (subfilter) {
                                // subfilter.data.push({ name: element.rate });
                                element.rate = element.rate.replace('Cargos', 'cargos');
                                subfilter.data.push({
                                    code: element.code,
                                    name: element.rate,
                                });
                            }
                        });
                    });
                }
            } else if (
                event.key === KeysMaestro.CODIGO_PRECIOS &&
                (this.selectId === MaestrosAWS.PRECIOS_INDEXADOS || this.selectId === MaestrosAWS.PRECIOS_MENSUALES)
            ) {
                let subfilter = this.subfilters?.find((f: any) => f.key === KeysMaestro.CODIGO_PRECIOS);

                if (subfilter) {
                    subfilter.data = [];

                    this.maestroService.getPreciosCode().subscribe((code) => {
                        code.data.forEach((element: any) => {
                            if (subfilter) {
                                if (this.selectId === MaestrosAWS.PRECIOS_INDEXADOS && element.type === 'Hourly') {
                                    subfilter.data.push({
                                        code: element.priceCode,
                                        name: element.priceCode,
                                        description: element.description,
                                        type: element.type,
                                    });
                                } else if (
                                    this.selectId === MaestrosAWS.PRECIOS_MENSUALES &&
                                    element.type === 'Monthly'
                                ) {
                                    subfilter.data.push({
                                        code: element.priceCode,
                                        name: element.priceCode,
                                        description: element.description,
                                        type: element.type,
                                    });
                                }
                            }
                        });
                    });
                }
            } else if (event.key === KeysMaestro.PRECIOS_INDEXADOS && this.selectId === MaestrosAWS.PRECIOS_INDEXADOS) {
                let liquidations: any = [];

                this.maestroService.getLiquidations().subscribe((resp: any) => {
                    resp.data.forEach((element: any) => {
                        liquidations.push({ name: element.origin });
                    });

                    liquidations.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name));
                    // Guardo en los subsfilters
                    this.subfilters?.forEach((subfilter: any) => {
                        if (subfilter.key === KeysMaestro.PRECIOS_INDEXADOS) {
                            subfilter.data = liquidations;
                        }
                    });
                });
            }
        }
    }

    specialSearch(event: any) {
        if (
            event.key === KeysMaestro.SOCIEDADES ||
            event.key === KeysMaestro.CONTRACT_SOCIEDADES ||
            event.key === 'contract.idNumber' ||
            event.key === 'contractCode.idNumber'
        ) {
            let subfilter = this.subfilters?.find(
                (f: any) =>
                    f.key === KeysMaestro.SOCIEDADES ||
                    f.key === KeysMaestro.CONTRACT_SOCIEDADES ||
                    f.key === 'contract.idNumber' ||
                    f.key === 'contractCode.idNumber'
            );
            this.maestroService
                .getClients({ search: event.search, mode: subfilter.toggleState ? 'cif' : 'description' })
                .subscribe((client) => {
                    subfilter.data = [];
                    client.data.forEach((element: any) => {
                        if (subfilter && subfilter.toggleState === true) {
                            subfilter.toggleStatus = true;
                            subfilter.data.push({ name: element.idNumber, value: element.idNumber });
                        } else if (subfilter) {
                            subfilter.toggleStatus = false;
                            subfilter.data.push({ name: element.clientName, value: element.idNumber });
                        }
                    });
                });
        } else if (
            event.key === KeysMaestro.CUENTA_NEGOCIADORA ||
            event.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA
        ) {
            let subfilter = this.subfilters?.find(
                (f: any) =>
                    f.key === KeysMaestro.CUENTA_NEGOCIADORA || f.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA
            );
            this.maestroService
                .getNegotiatingAccounts({
                    search: event.search,
                    mode: subfilter.toggleState ? 'negotiatingAccountCode' : 'negotiatingAccountDescription',
                })
                .subscribe((neAccounts) => {
                    subfilter.data = [];
                    neAccounts.data.forEach((element: any) => {
                        if (subfilter && subfilter.toggleState === true) {
                            subfilter.toggleStatus = true;
                            subfilter.data.push({
                                name: element.negotiatingAccountCode,
                                value: element.negotiatingAccountCode,
                            });
                        } else if (subfilter) {
                            subfilter.toggleStatus = false;
                            subfilter.data.push({
                                name: element.negotiatingAccountDescription,
                                value: element.negotiatingAccountCode,
                            });
                        }
                    });
                });
        } else if (
            event.key === KeysMaestro.CODIGO_CONTRATO ||
            event.key === KeysMaestro.CONTRACT_CODIGO_CONTRATO ||
            event.key === 'contractCode.contractCode'
        ) {
            let subfilter = this.subfilters?.find(
                (f: any) =>
                    f.key === KeysMaestro.CONTRACT_CODIGO_CONTRATO ||
                    f.key === KeysMaestro.CODIGO_CONTRATO ||
                    f.key === 'contractCode.contractCode'
            );
            subfilter.data = [];
            this.maestroService.getContractCode(event.search).subscribe((code) => {
                code.data.forEach((element: any) => {
                    subfilter.data.push({ name: element.contractCode });
                });
            });
        } else if (event.key === 'instName') {
            let subfilter = this.subfilters?.find((f: any) => f.key === 'instName');
            subfilter.data = [];
            this.maestroService.getCupsDescription(event.search).subscribe((cupsDesc) => {
                cupsDesc.data.forEach((element: any) => {
                    if (subfilter) {
                        subfilter.data.push({ name: element.instName });
                    }
                });
            });
        }
    }

    /**
     * Mátodo para obtener todos los filtros existentes en la base de datos
     * @param filterByBoolean Bandera para filtrar por booleanos
     */
    getFilters(filterByBoolean: boolean) {
        this.subcriptions.push(
            this.globalService.getAllFilters(filterByBoolean).subscribe((filtros) => (this.filters = filtros.data))
        );
    }

    /**
     * Este evento tiene 2 usos:
     * Si selectId === Contratos -> Método que comprueba los select de Tarifas y Zona para deshabilitar las opciones de Zona según hemos seleccionado la tarifa especial de 2.0TD o no.
     * Si selectId === Contratos -> Método que comprueba si hay algo seleccionado
     * @param selectedOption Valor seleccionado que viene emitido del select
     */
    checkValueSelects(selectedOption: any) {
        if (
            this.selectId === MaestrosCRM.CONTRATOS ||
            this.selectId === MaestrosCRM.CONCEPTOS_CONTRATOS ||
            this.selectId === MaestrosCRM.CIERRES ||
            this.selectId === MaestrosCRM.PRECIOS_CONTRATOS ||
            this.selectId === MaestrosCRM.COBERTURA_CONTRATO
        ) {
            // IF para controlar si el selector que estamos comprobando está en la parte de CONTRATOS, CONCEPTOS CONTRATO O CIERRES.
            this.getFilters(this.maestroService.searchBy);
        } else if (
            this.selectId === MaestrosCRM.CONCEPTOS_CONTRATOS &&
            selectedOption.newValue === FiltroSelectConceptoContrato.LIQUIDACION
        ) {
            // If para controlar en Conceptos de Contrato si mostrar las columnas de la tabla según el campo Liquidación o no.
            this.maestroService.showColumnsBy = FiltroSelectConceptoContrato.LIQUIDACION;
        } else if (this.selectId === MaestrosAWS.PERFILES_REE) {
            // IF para controlar si el selector que estamos comprobando está en la parte de PERIODOS TARIFARIOS.
            this.checkTarifasREE(selectedOption);
        } else if (this.selectId === MaestrosAWS.COEFICIENTE_PERDIDAS) {
            // IF para controlar si el selector que estamos comprobando está en la parte de PERIODOS TARIFARIOS.
            this.maestroService.showColumnsBy = selectedOption;
        } else if (this.selectId === MaestrosCRM.SOCIEDAD) {
            this.maestroService.selectedClient = selectedOption.eventValue;
        } else {
            this.maestroService.showColumnsBy = '';
        }

        if (selectedOption.newValue !== 'REE' && selectedOption.newValue !== 'Propio') {
            this.selectCopy = this.selects.last;
        }

        if (selectedOption.newValue === 'REE') {
            this.selects.last.bankCtrl.value.id = this.selectCopy.bankCtrl.value.id;
            this.selects.last.bankCtrl.value.name = this.selectCopy.bankCtrl.value.name;
        } else if (selectedOption.newValue === 'Propio') {
            this.selects.last.bankCtrl.value.id = this.selectCopy.bankCtrl.value.id;
            this.selects.last.bankCtrl.value.name = this.selectCopy.bankCtrl.value.name;
        }
    }

    resetFees(specialFee: boolean, index: number): void {
        this.specialFeeActivated = specialFee;
        this.selects.last.resetSelect();
        this.selects.last.bankCtrl.reset();
        this.subfilters[index].selected = '';
    }
    checkTarifasREE(selectedOption: any) {
        if (selectedOption.newValue === 'REE' && !this.specialFeeActivated) {
            this.specialFeeActivated = true;
        } else if (selectedOption.name === '17' && selectedOption.newValue !== 'REE') {
            this.specialFeeActivated = false;
        }
    }

    /**
     * Método para cargar en local el subfiltro seleccionado
     * @param selectedOption Datos del filtro seleccionado en pantalla
     */
    obtainSubfilters(selectedOption: any) {
        this._headerTitleService.currentInitDate = '';
        this._headerTitleService.currentEndDate = '';
        this.perfilesREEactive = false;
        this.resetTable.emit();
        this.resetSelectedOption = selectedOption;
        this.subfilters = this.filters.find((fil) => fil.name === selectedOption.filtrosCategory)?.filters;
        this.inputValues = [];
        this.filtersList.emit({ subfilters: this.subfilters, selectedOption });
        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element: any) => {
                if (element.type === 'Date' && element.id === MaestrosCRM.ID_FECHA_CONTRATOS && element.required) {
                    element.required = false;
                }

                if (element.required) {
                    if (element.name.includes('*')) {
                        return;
                    } else {
                        element.name = element.name + ' *';
                    }
                }
            });
        }
    }

    obtainSelectSubfiltros() {
        this.subfilters
            ?.filter((f: any) => {
                f.type === 'Select';
            })
            .forEach((s: any) => s.url);
    }

    userAllRoles: any;
    validRole: any = true;
    obtainIdSelect(selectedOption: any) {
        this.validRole = false;
        if (selectedOption.value.id === 'liquidacionPotestativa') {
            this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
            this.userRoles = this.userRoles.map((element: any) => element.trim());
            if (
                this.userRoles.findIndex((role) => role === 'PREVISION') !== -1 ||
                this.userRoles.findIndex((role) => role === 'ADMIN') !== -1
            ) {
                this.validRole = true;
            }
        } else {
            this.validRole = true;
        }

        if (
            selectedOption.value.id === MaestrosAWS.PRECIOS_INDEXADOS ||
            selectedOption.value.id === MaestrosAWS.COEFICIENTE_PERDIDAS ||
            selectedOption.value.id === MaestrosAWS.K_ESTIMADA
        ) {
            this.fillSelect('liquidation');
        }

        if (this.subfilters) {
            for (let i = 0; i < this.subfilters.length; i++) {
                if (this.subfilters![i].selected != undefined || this.subfilters != null) {
                    this.subfilters![i].selected = '';
                }
            }
        }
        this.resetSubfilter = selectedOption;
        this.selectId = selectedOption.value.id;
        this.maestroService.selectId = this.selectId;
        this.isDisableButtonRequired();
        this.showinput = undefined;
        this.maestroService.validCups = '';
        this._errorHttpServ.hide();
    }

    /**
     * Este método se usa para verificar si el botón de filtrar puede usarse o no; es decir, 
     * valida que se hayan cumplido los requisitos de los filtros
     */
    isDisableButtonRequired() {
        var countRequired = 0;
        var countSelected = 0;
        if (this.subfilters !== undefined) {
            this.subfilters.forEach((element: any) => {
                if (element.required) {
                    countRequired++;
                    if (element.selected) {
                        countSelected++;
                    }
                } else if (
                    element.selected !== '' &&
                    (this.selectId === MaestrosCRM.CONTRATOS || this.selectId === MaestrosAWS.PUNTO_FRONTERA)
                ) {
                    countSelected++;
                }
            });
        }
        if (countRequired === 0 && !this.isDateInvalid) {
            this.isDisabled = false;

            if (countSelected < 1 && this.selectId === MaestrosCRM.CONTRATOS) {
                this.isDisabled = true;
            } else if (countSelected >= 1 && this.selectId === MaestrosCRM.CONTRATOS) {
                this.isDisabled = false;
            }
        } else {
            if (countRequired === countSelected) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        }
    }

    /**
     * Carga los datos de la tabla al pulsar el botón filtrar
     */
    loadTable() {
        let selectFilter: any = [this.selectId, this.subfilters];
        if (this.subfilters !== undefined) {
            this.optionSelectedId.emit(selectFilter);
        } else {
            this.optionSelectedId.emit(this.selectId);
        }
    }

    obtainOption(event: any) {
        this.selectedName = '';

        if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }

        if (this.resetSelectedOption.id === 'periodosTarifarios' && event.value.split('=')[0] === 'Tarifas') {
            this.fillSelect('zone');
        }
    }

    checkConditions(filtro: string[] | undefined): boolean {
        if (this.showinput === false) {
            return true;
        }
        if (!filtro || !filtro.length) {
            return true;
        }
        let solution = false;
        filtro.forEach((cond) => {
            if (this.inputValues.some((inpVal) => inpVal.value === cond)) {
                solution = true;
            }
        });
        return solution;
    }
    perfilesREEactive: boolean = false;
    receiveDate($event: any, filtro: any, reset?: boolean) {
        if ($event === 'Origen *=Propio') {
            this.showinput = true;
        }
        if ($event === 'Origen *=REE') {
            this.showinput = false;
            this.checkConditions(undefined);
            this.perfilesREEactive = true;
        }
        if (this.subfilters !== undefined) {
            this.subfilters[filtro].selected = $event;
        }
        this.isDisableButtonRequired();
    }

    fillSelect(key: any) {
        if (key === 'liquidation') {
            let liquidations: any = [];

            this.maestroService.getLiquidations().subscribe((resp: any) => {
                resp.data.forEach((element: any) => {
                    if (element.area === 'Precios') liquidations.push({ name: element.origin });
                });
                liquidations.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name));
                // Guardo en los subsfilters
                this.subfilters?.forEach((subfilter: any) => {
                    if (
                        (subfilter.key === 'code' && this.selectId === MaestrosAWS.COEFICIENTE_PERDIDAS) ||
                        (subfilter.key === 'liquidation' && this.selectId === MaestrosAWS.PRECIOS_INDEXADOS) ||
                        (subfilter.key === 'code' && this.selectId === MaestrosAWS.K_ESTIMADA)
                    ) {
                        subfilter.data = liquidations;
                    }
                });
            });
        } else if (key === 'zone') {
            this.isFirstTime = false;

            this.subfilters.forEach((filtro: any) => {
                if (filtro.name === 'Zona' && filtro.key === 'zoneGroup') {
                    let copy = filtro.data;
                    filtro.data = [];
                    filtro.data = [...copy];
                }
            });
        }
    }

    restarValue(event: any, filtro: any, key: string, filtroType?: any) {
        if (this.selectId === MaestrosAWS.PRECIOS_MENSUALES && key === 'month') {
            this.subfilters!.forEach((keyPre: any) => {
                if (keyPre.key === 'month') {
                    keyPre.selected = event.value;
                    this.isDisableButtonRequired();
                }
            });
        }

        this.formatSelectedValue(event, filtro, key, filtroType);
        if (this.subfilters !== undefined && event.newValue) {
            this.subfilters[filtro].selected = event.newValue;
            if (this.selectId === MaestrosCRM.CONTRATOS || this.selectId === MaestrosAWS.PUNTO_FRONTERA) {
                this.isDisableButtonRequired();
            }
        } else if (!event.newValue && this.selectId === MaestrosCRM.CONTRATOS) {
            this.isDisableButtonRequired();
        } else if (
            !event.newValue &&
            this.subfilters![0].selected != '' &&
            this.subfilters![0].key == KeysMaestro.PRECEDENCE_AREA
        ) {
            this.isDisableButtonRequired();
        } else if (!event.newValue && this.selectId == MaestrosAWS.PRELACION) {
            this.subfilters!.forEach((keyPre: any) => {
                if (keyPre.selected != '' && keyPre.key == KeysMaestro.PRECEDENCE_AREA) {
                    this.isDisableButtonRequired();
                    this.intermedio = true;
                }
            });
        }

        // Buscamos si se ha seleccionado la key de Origen (perfiles REE), en cuyo caso, activamos el button Filtrar
        else if (!event.newValue && this.selectId == MaestrosAWS.PERFILES_REE) {
            this.perfilesREEactive = true;
            this.subfilters!.forEach((keyREE: any) => {
                if (keyREE.selected != '' && keyREE.key == KeysMaestro.PERFILES_REE_ORIGEN) {
                    this.isDisableButtonRequired();
                }
            });
            this.selectCopy = this.selects.last;
        }
        if (this.selectId === 'preciosMensuales' || this.selectId === MaestrosAWS.PUNTO_FRONTERA) {
            this.isDisableButtonRequired();
        }
    }

    /**
     * Método para formatear los datos de los filtros correctamente para el envío si es que es necesario
     * @param event Dato seleccionado
     * @param filtro El número del select de filtros
     * @param key Key del filtro
     */
    formatSelectedValue(event: any, filtro: any, key: string, filtroType: string) {
        if (
            this.selectId === MaestrosCRM.CONTRATOS ||
            this.selectId === MaestrosCRM.CONCEPTOS_CONTRATOS ||
            this.selectId === MaestrosCRM.PUNTO_MEDIDA ||
            this.selectId === MaestrosCRM.CONSUMO_ANUAL ||
            this.selectId === MaestrosCRM.CUPS ||
            MaestrosCRM.CIERRES ||
            MaestrosCRM.CUENTA_NEGOCIADORA ||
            MaestrosAWS.FESTIVOS ||
            MaestrosCRM.SOCIEDAD ||
            MaestrosCRM.PRECIOS_CONTRATOS
        ) {
            if (filtroType !== 'Date' && this.subfilters) {
                if (key === KeysMaestro.DESCRIPTION || key === KeysMaestro.BOUNDARY_DESCRIPTION) {
                    this.subfilters[filtro].selected = event.newValue;
                } else if (key === KeysMaestro.CUENTA_NEGOCIADORA_DESCRIPCION) {
                    this.subfilters[filtro].selected = event.newValue;
                } else if (
                    key === KeysMaestro.CUPS ||
                    key === KeysMaestro.BOUNDARY_POINT_CUPS ||
                    key === KeysMaestro.MEASURE_POINT_CUPS ||
                    key === KeysMaestro.HISTORIC_CONSUMPTIONS
                ) {
                    if (event.data) {
                        this.subfilters[filtro].selected += `;${event.data}`;
                    } else {
                        this.subfilters[filtro].selected = event;
                    }
                } else if (key === 'contractCode' && this.selectId === MaestrosCRM.CONTRATOS) {
                    this.subfilters[filtro].selected = event.newValue;
                } else if (key === 'contract.contractCode' && this.selectId === MaestrosCRM.CIERRES) {
                    this.subfilters[filtro].selected = event.newValue;
                } else if (
                    (key === 'contractCode.contractCode' ||
                        key === 'contract.contractCode' ||
                        key === 'contractCode' ||
                        key === 'clientName' ||
                        key === 'idNumber') &&
                    this.selectId !== MaestrosCRM.CONTRATOS &&
                    this.selectId !== MaestrosCRM.CIERRES
                ) {
                    let filtroformateado = event.newValue || event;
                    filtroformateado = event.newValue ? event.newValue : event.split('=');
                    if (Array.isArray(filtroformateado)) {
                        this.subfilters[filtro].selected = filtroformateado[1];
                    } else {
                        this.subfilters[filtro].selected = filtroformateado;
                    }
                } else {
                    let filtroFormateado = event.split('=');
                    if (key === KeysMaestro.CUPS_CONSUMO_ANUAL) {
                        this.subfilters[filtro].selected = filtroFormateado[1];
                    } else if (key === KeysMaestro.ESTADO_CONTRATO) {
                        switch (filtroFormateado[1]) {
                            case ClosedStatus.PRE_CONTRATO:
                                this.subfilters[filtro].selected = 1;
                                break;
                            case ClosedStatus.PENDING:
                                this.subfilters[filtro].selected = 2;
                                break;
                            case ClosedStatus.FORMALIZED:
                                this.subfilters[filtro].selected = 3;
                                break;
                            case ClosedStatus.CANCELED_REJECTED:
                                this.subfilters[filtro].selected = 4;
                                break;
                            case ClosedStatus.COMPLETED:
                                this.subfilters[filtro].selected = 5;
                                break;
                            default:
                                break;
                        }
                    } else if (key === KeysMaestro.NEGOCIO) {
                        switch (filtroFormateado[1]) {
                            case ContractClientType.GRANDES_CUENTAS:
                                this.subfilters[filtro].selected = ContractClientType.GRANDES_CUENTAS_STRING;
                                break;
                            case ContractClientType.PYMES:
                                this.subfilters[filtro].selected = ContractClientType.PYMES_STRING;
                                break;
                        }
                    } else if (key === KeysMaestro.TIPO_LIQUIDACION) {
                        switch (filtroFormateado[1]) {
                            case liqTypeEnum.MEDIA_HORARIA:
                                this.subfilters[filtro].selected = liqTypeEnum.MEDIA_HORARIA_STRING;
                                break;
                            case liqTypeEnum.FACTURA_ATR:
                                this.subfilters[filtro].selected = liqTypeEnum.FACTURA_ATR_STRING;
                                break;
                        }
                    } else {
                        this.subfilters[filtro].selected = filtroFormateado[1];
                        this.subfilters.forEach((element: any) => {
                            if (
                                element.id === PerfilesREE.ID_17 &&
                                element.key === PerfilesREE.KEY_ORIGIN &&
                                this.selectId === MaestrosAWS.PERFILES_REE
                            ) {
                                if (element.selected === PerfilesREE.SELECTED_PROPIO) {
                                    this.subfilters.forEach((subfilters: any) => {
                                        if (
                                            subfilters.id === PerfilesREE.ID_18 ||
                                            (subfilters.id === PerfilesREE.ID_19 &&
                                                subfilters.key === PerfilesREE.KEY_NAME)
                                        ) {
                                            subfilters.selected = '';
                                        }
                                    });
                                }
                                if (element.selected === PerfilesREE.SELECTED_REE) {
                                    this.subfilters.forEach((subfilters: any) => {
                                        if (
                                            subfilters.id === PerfilesREE.ID_987 &&
                                            subfilters.key === PerfilesREE.KEY_NAME
                                        ) {
                                            subfilters.selected = '';
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            }
        }
    }

    /**
     * Método para limpiar todos los filtros y resetearlos por defecto
     */
    clearFilters() {
        this.showSelect = true;
        this.obtainSubfilters(this.resetSelectedOption);
        this.obtainIdSelect(this.resetSubfilter);
        this.resetCups = true;
        this.yearValue = '';

        this.formGroup = new FormGroup({
            month: new FormControl(undefined),
        });

        this._errorHttpServ.hide();
        this.specialFeeActivated = false;
        this.isFirstTime = true;
    }

    resetComplete($event: any) {
        this.resetCups = $event;
    }

    hideSelect() {
        this.showSelect = false;
    }

    reset() {
        if (this.subfilters != undefined) {
            this.subfilters.forEach(function (e: any) {
                delete e['selected'];
            });
        }
    }

    filterUnitProgram(electricSystemSelected: any) {
        this.electricSystemSelected = electricSystemSelected;
        let unitProgramFilter = [];
        for (let i = 0; i < this.unitProgramListNoFilter.length; i++) {
            if (this.unitProgramListNoFilter[i].electricSystem === electricSystemSelected) {
                unitProgramFilter.push(this.unitProgramListNoFilter[i]);
            }
        }
        this.unitProgramList = [];
        this.unitProgramList = unitProgramFilter;
    }

    obtainUnitProgram(programUnit: any) {
        this.programUnit = programUnit.programUnitCode;
    }

    obtainOption3(event: { id: string; value: string }, valueCuentNeg: any) {
        if (this.selectedInitialForecasts) {
            if (event.value) {
                //Aquí esta el valor del select de cuenta negociadora
                this.cuentaNegociadoraActive = true;
                this.eventvalue = event.value;
            }
            this.cuentaNegActive = true;
            if (valueCuentNeg) {
                this.cuentaNegociadoraActive = true;
            }
        }
        if (this.inputValues.find((inpVal) => inpVal.id === event.id)) {
            this.inputValues.find((inpVal) => inpVal.id === event.id)!.value = event.value;
        } else {
            this.inputValues.push(event);
        }
    }

    receiveDate2(filtro: any) {
        this.isDisableButtonRequired();
    }

    unitProgram: any;
    unitProgramList2: any;

    getUnitProgram() {
        this.unitProgramList2 = [];

        this.procesosService.getUnidadPrograma(this.selectId).subscribe((UP) => {
            this.unitProgram = UP.data;

            for (let i = 0; i < this.unitProgram.length; i++) {
                this.unitProgramList2.push({ id: '', name: this.unitProgram[i].programUnitCode });
            }
        });
    }

    /**
     * Método para deshabilitar el botón de filtro si tenemos una fecha no válida
     * @param isInvalid Bandera con la información sobre si es inválida la fecha
     */
    protected isInvalidDate(isInvalid: boolean): void {
        this.isDateInvalid = isInvalid;
        this.isDisabled = isInvalid;
        this.isDisableButtonRequired();
    }

    placeholder$ = this._translateService.get('PAGE.MASTER.MASTER_CRM.MESSAGES.MESSAGE_PLACEHOLDER_SPECIAL_SELECTS');

    /** 
    * En esta función actualizamos los datos que se enviarán posteriormente de los subfiltros.
    * Concretamente se actualizan los datos relacionados con el multiselect de estado del contrato.
    *    @param opcion - any (Formato { key: string; value: string[] }) 
    */
    protected filterContract(opcion: any): void {
        debugger;
        this.subfilters?.forEach((subfilter: any) => {
            if (subfilter.key === KeysMaestro.ESTADO_CONTRATO) {
                const selectedValues = opcion.value;
                const formattedString = `(${selectedValues.join('; ')})`;
                subfilter.selected = formattedString;
            }
        });
    }
}
