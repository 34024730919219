import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-filtro-textarea',
    templateUrl: './filtro-textarea.component.html',
    styleUrls: ['./filtro-textarea.component.scss'],
})
export class FiltroTextareaComponent implements OnInit {
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    constructor(private _ngZone: NgZone) {}
    triggerResize() {
        // Wait for changes to be applied, then trigger textarea resize.
        this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
    }

    ngOnInit(): void {}
}
