export enum ContractStatusEnum {
    PRE_CONTRATO = 'Pre-Contract',
    PENDING = 'Pending Data',
    FORMALIZED = 'Formalized',
    CANCELED_REJECTED = 'Cancelled- Rejected',
    COMPLETED = 'Completed',
}

export enum ContractClientType {
    GRANDES_CUENTAS = 'Grandes cuentas',
    PYMES = 'Pymes',
    GRANDES_CUENTAS_STRING = '1,2,3,4,5',
    PYMES_STRING = '6',
}

export enum liqTypeEnum {
    MEDIA_HORARIA = 'Medida horaria',
    FACTURA_ATR = 'Factura ATR',
    MEDIA_HORARIA_STRING = '1',
    FACTURA_ATR_STRING = '2',
    MEDIA_HORARIA_CODE = 'MH',
    FACTURA_ATR_CODE = 'ATR',
}

export enum closeTypeName {
    ATR_NAME = 'Factura ATR',
    CH_NAME = 'Horario',
    CCH_NAME = 'Cuarto horario',
    MIXTO_NAME = 'Mixto',
}

export enum closeTypeId {
    ATR_ID = 'factura_ATR',
    CH_ID = 'horario',
    CCH_ID = 'cuarto-horario',
    MIXTO_ID = 'mixto',
}

export enum closeTypeCode {
    ATR_CODE = 'ATR',
    CH_CODE = 'CH',
    CCH_CODE = 'CCH',
    MIXTO_CODE = 'Mixto',
}

export enum ClosedStatus {
    PRE_CONTRATO = 'Pre-contrato',
    PENDING = 'Pendiente datos',
    FORMALIZED = 'Formalizado',
    CANCELED_REJECTED = 'Cancelado/rechazado',
    COMPLETED = 'Finalizado',
}

export enum CoberturasContratosStatus {
    PRE_CONTRATO = '1',
    PENDING = '2',
    FORMALIZED = '3',
    CANCELED_REJECTED = '4',
    COMPLETED = '5',
}

export enum ClosedStatusFilter {
    ACTIVE = 'Activo',
    NOT_ACTIVE = 'No activo',
}

export enum ClosedStatusFilterString {
    ACTIVE = '1',
    NOT_ACTIVE = '2',
}
