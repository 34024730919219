import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TablaProcesosResComponent } from '../tabla-procesos-res.component';

@Component({
    selector: 'app-delete-modal-process-res',
    templateUrl: './delete-modal-process-res.component.html',
    styleUrls: ['./delete-modal-process-res.component.scss'],
})
export class DeleteModalProcessResComponent {
    constructor(public DialogDelete: MatDialogRef<TablaProcesosResComponent>) {}
    close(resp: boolean) {
        this.DialogDelete.close(resp);
    }
}
