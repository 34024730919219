<mat-form-field appearance="standard" class="multiSelect">
    <mat-label>{{ title }}</mat-label>
    <mat-select 
        (selectionChange)="emitValue($event)"
        [formControl]="formControl" multiple>
        <mat-option *ngFor="let item of filtroUrl" [value]="item">
            {{ item.name }}
        </mat-option>
    </mat-select>
</mat-form-field>
