<div class="container" *ngIf="dataBusiness">
    <app-spinner></app-spinner>
    <app-http-error></app-http-error>
    <div class="row row-column">
        <div class="table-header">
            <div class="table-header-info">
                <span
                    >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                    {{ totalRegisters | numberFormat : _headerTitleService.currentLang }}</span
                >
            </div>
            <div class="table-header-actions display_flex actions-heigh">
                <button mat-button (click)="openModalExcel()">
                    <span class="text">{{ 'PAGE.ACTIONS.EXCEL_EXPORT' | translate }}</span>
                    <span class="icon material-symbols-outlined">save_as </span>
                </button>
                <button class="buttonColumns" [ngClass]="ocultar === true ? 'mat' : 'mat_none'">
                    <!-- INICIO>>> Botón mostrar/ocultar columnas -->
                    <mat-form-field [hidden]="ocultar">
                        <mat-select
                            #selectColumns
                            name="selectColumns"
                            id="selectColumns"
                            [value]="dataHeaders_valueSelect"
                            multiple
                        >
                            <mat-option
                                enableCheckAll="true"
                                [matColumnDef]="column.id"
                                [value]="column"
                                (click)="checkValue(selectColumns.value)"
                                *ngFor="let column of filterDataHeader()"
                                >{{ column.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="divColumns" (click)="botonClick()">
                        <span class="text spanColumn" *ngIf="!element">{{ 'PAGE.ACTIONS.COLUMNS' | translate }}</span>
                        <app-base-icon class="padding_button" iconName="view_column"></app-base-icon>
                    </div>
                </button>
                <!-- FIN>>> Botón mostrar/ocultar columnas -->
            </div>
        </div>
        <div class="table-container-main">
            <div
                class="table-container"
                [ngClass]="{
                    'acciones--1': permissions?.edit === true || permissions?.delete == true,
                    'acciones--2': permissions?.edit == true && permissions?.delete
                }"
            >
                <!-- Table -->
                <table
                    mat-table
                    [dataSource]="dataBusiness"
                    multiTemplateDataRows
                    matSort
                    (matSortChange)="sortData($event)"
                >
                    <ng-container [matColumnDef]="column.id" *ngFor="let column of dataHeaders; let i = index">
                        <!-- Header TH -->
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                            <div class="header-table d-flex">
                                {{ column.label }}
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="column.id === 'initDate'">
                                <span>{{ showTimeInit(element) }}</span>
                            </div>
                            <div *ngIf="column.id === 'endDate'">
                                <span>{{ showTimeEnd(element) }}</span>
                            </div>
                            <div *ngIf="column.id === 'status'">
                                <span>{{  getStatus(element) | translate }}</span>
                            </div>
                            <div *ngIf="column.id !== 'status' && column.id !== 'initDate' && column.id !== 'endDate'">
                                <span>
                                    {{ checkAndReturnColumns(element, column.id) }}
                                </span>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9999">{{ 'PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA' | translate }}</td>
                    </tr>
                </table>
            </div>
            <table class="tableAccions">
                <thead>
                    <th>
                        <div class="header-table d-flex headerCentered">{{ 'PAGE.ACTIONS.DOWNLOAD' | translate }}</div>
                    </th>
                    <th>
                        <div class="header-table d-flex headerCentered">{{ 'PAGE.ACTIONS.DELETE' | translate }}</div>
                    </th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let report of dataBusiness">
                        <tr>
                            <td (click)="downloadReport(report)" class="icon material-symbols-outlined">
                                <span>download</span>
                            </td>
                            <td (click)="deleteReport(report.id)" class="icon material-symbols-outlined">
                                <span>delete</span>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="!dataBusiness.length">
                        <tr>
                            <td><span></span></td>
                            <td><span></span></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <mat-paginator
            [length]="totalRegisters"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageChange($event)"
        >
        </mat-paginator>
    </div>
</div>
<div class="container containerNoData" *ngIf="!dataBusiness">
    <div class="row flex_column">
        <app-spinner></app-spinner>
        <app-http-error></app-http-error>
        <h3>{{ 'PAGE.ACTIONS.NO_DATA' | translate }}</h3>
    </div>
    <app-table-no-data></app-table-no-data>
</div>
