import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Users } from '../shared/interfaces/user.interface';
import { AutonomiesBackResponse } from '../shared/models/autonomias';
import { FilterBackResponse } from '../shared/models/filtros.interface';
import { environment } from '../../environments/environment';
import { LoginService } from 'src/app/core/services/login.service';
import { Permisos } from '../shared/models/permisos.interface';

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    private filtrosBack: FilterBackResponse | undefined = undefined;
    public permissionsBack: any[] = [];

    constructor(private _http: HttpClient, private _loginService: LoginService) {}

    getUserPermissions(id?: string): any {
        if (this.permissionsBack.length > 0) {
            return this.permissionsBack.filter(
                (permission: any) => permission?.idFront?.toLowerCase() === id?.toLowerCase()
            );
        } else {
            let userRoles: string[] = this._loginService.getUserActiveRoles()?.split(',') ?? ['CONSULTA'];
            userRoles = userRoles.map((element: any) => element.trim());
            let roles: any = {};
            this.getRoles().subscribe((rolesResponse: any) => {
                roles = rolesResponse.data.filter((role: any) => {
                    if (userRoles.includes(role.code)) {
                        return role;
                    }
                });
                roles.forEach((role: any) => {
                    role.permissions.forEach((permission: any) => {
                        if (
                            this.permissionsBack.length === 0 ||
                            this.permissionsBack.findIndex(
                                (permissionBack: any) => permissionBack.idFront === permission.idFront
                            ) === -1
                        ) {
                            this.permissionsBack.push(permission);
                        } else {
                            this.permissionsBack.forEach((backPermission: any) => {
                                if (backPermission.idFront === permission.idFront) {
                                    permission.operations.forEach((operation: string) => {
                                        if (!backPermission.operations.includes(operation)) {
                                            backPermission.operations.push(operation);
                                        }
                                    });
                                }
                            });
                        }
                    });
                });
            });
        }
    }

    getPermissionsMock(): Observable<AutonomiesBackResponse> {
        const url = './assets/json/permisos.json';
        return this._http.get<any>(url).pipe(
            map((res) => {
                this.permissionsBack = res;
                return res;
            })
        );
    }
    /**
     * Si ya hemos obtenido los filtros los devolvemos directamente,
     * en el caso contrario los obtenemos del back
     * @returns filtros de toda la app
     */
    getAllFilters(filterByBoolean: boolean): Observable<FilterBackResponse> {
        if (this.filtrosBack) {
            return of(this.filtrosBack);
        } else {
            if (environment.isMockActive) {
                return this.getFiltersMock(filterByBoolean);
            } else {
                return this.getFilters();
            }
        }
    }

    private getFiltersMock(filterByBoolean: boolean): Observable<FilterBackResponse> {
        let url = './assets/json/filtros.json';
        if (filterByBoolean) {
            url = './assets/json/filtros.json';
        } else {
            url = './assets/json/filtros.json';
        }
        return this._http.get<FilterBackResponse>(url).pipe(
            map((res: FilterBackResponse) => {
                this.filtrosBack = res;
                return res;
            })
        );
    }

    getFilters() {
        const url = `${environment.apiUrl}/filter/v0?page=0&search=0`;
        return this._http.get<FilterBackResponse>(url).pipe(
            map((res: FilterBackResponse) => {
                this.filtrosBack = res;
                this._loginService.setFiltersTotal(JSON.stringify(res.data));
                return res;
            })
        );
    }

    getFiltersSession(filterByBoolean: boolean): Observable<any> {
        if (this.filtrosBack) {
            return of(this.filtrosBack);
        } else {
            if (environment.isMockActive) {
                return this.getFiltersMock(filterByBoolean);
            } else {
                return this.getFiltersFromStorage();
            }
        }
    }

    getFiltersFromStorage() {
        return from(JSON.parse(sessionStorage.getItem('filters')!));
    }

    getFiltersBackByID(id: any) {
        const url = `${environment.apiUrl}/filter/v0/${id}`;
        return this._http.get<any>(url);
    }

    getUsers() {
        const url = `${environment.apiUrl}/user/v0?page=0`;
        return this._http.get<any>(url);
    }

    getUserByUsername(username: string) {
        const url = `${environment.apiUrl}/user/v0/${username}`;
        return this._http.get<any>(url);
    }

    deleteUsers(username: any) {
        const url = `${environment.apiUrl}/user/v0/${username}`;
        return this._http.delete<any>(url);
    }

    addUsers(username: any, user: Users) {
        //INSERTAR USUARIOS
        const url = `${environment.apiUrl}/user/v0`;
        return this._http.post<any>(url, user);
    }

    updateUsers(username: any, user: Users) {
        //INSERTAR USUARIOS
        const url = `${environment.apiUrl}/user/v0/${username}`;
        return this._http.put<any>(url, user);
    }

    getUsersMock(): Observable<any> {
        const url = './assets/json/users.json';
        return this._http.get<any>(url).pipe(map((e) => e.users));
    }

    getHeadersUsersMock(): Observable<any> {
        const url = './assets/json/users.json';
        return this._http.get<any>(url).pipe(map((e) => e.headers));
    }
    // 2   ROLES
    // 2.1 ROLES BACK

    getRoles() {
        const url = `${environment.apiUrl}/role/v0?page=-1`;
        return this._http.get<any>(url);
    }
    getRolesByUserRol(rol: string) {
        const url = `${environment.apiUrl}/role/v0/${rol}`;
        return this._http.get<any>(url);
    }

    // 2.1 ROLES MOCK

    getRolesMock(): Observable<any> {
        const url = './assets/json/roles.json';
        return this._http.get<any>(url).pipe(map((e) => e.roles));
    }

    getRolesMockByUserRol(_rol: string) {
        const url = './assets/json/roles.json';
        return this._http.get<any>(url).pipe(map((e) => e.roles));
    }

    getHeaders() {
        const url = `${environment.apiUrl}/header/v0?page=0&search=0`;
        return this._http.get<any>(url);
    }

    transformPermissions(id: string): Permisos {
        const permissionsAux = this.getUserPermissions(id);
        let permisos: Permisos;
        permisos = {
            id: permissionsAux[0].idFront,
            name: permissionsAux[0].idFront,
            edit: permissionsAux[0].operations.includes('PUT'),
            delete: permissionsAux[0].operations.includes('DELETE'),
            add: permissionsAux[0].operations.includes('POST'),
            view: permissionsAux[0].operations.includes('GET'),
            download: permissionsAux[0].operations.includes('GET'),
        };

        return permisos;
    }
}
