import { Component, OnInit } from '@angular/core';
import { ContractTypes, GestionATR, AdjMecGas } from 'src/app/shared/models/contratos-ficticios';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ContractClientType } from '../../../shared/enums/contractEnums';
import { ProcesosService } from '../../procesos/services/procesos.service';
import { MaestroService } from '../../maestro/services/maestro.service';
import { CargasServiceService } from '../services/cargas-service.service';
import { AlertModalComponent } from '../../../shared/components/alert-modal/alert-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from '../../../core/services/login.service';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-contratos-ficticios',
    templateUrl: './contratos-ficticios.component.html',
    styleUrls: ['./contratos-ficticios.component.scss'],
})
export class ContratosFicticiosComponent implements OnInit {
    contracts: string[] = [ContractTypes.PRECIO_FIJO, ContractTypes.PRECIO_INDEXADO];
    clientTypes: string[] = [ContractClientType.GRANDES_CUENTAS, ContractClientType.PYMES];
    adjMech: string[] = [AdjMecGas.SI, AdjMecGas.NO, AdjMecGas.SN];
    atrList: string[] = ['YES', 'NO'];
    validation: string = '^[0-9]+([.,][0-9]+)?$';

    // Valores a mandar
    clientType: any;
    atr?: string | number;
    adjMec?: string | number;
    rate: any;
    programUnit: any = [];

    electricSystemList: any;
    unitProgramList?: any = [''];
    unitProgramListData?: any = [];
    rateList?: any = [];

    // selectedContract?: string;
    electrictSystemSelected: boolean = false;

    // Boolean para el botón de crear
    isDisabled: boolean = true;

    formGroup = new FormGroup({
        description: new FormControl(undefined, Validators.required),
        dates: new FormControl(undefined, Validators.required),
        cups: new FormControl(undefined, Validators.required),
        cnae: new FormControl(undefined, [Validators.required, Validators.maxLength(5)]),
        clientType: new FormControl(undefined, Validators.required),
        atr: new FormControl(undefined, Validators.required),
        eqRentalPrice: new FormControl(undefined),
        adjMec: new FormControl(undefined, Validators.required),
        rate: new FormControl(undefined, Validators.required),
        programUnitCode: new FormControl(undefined, Validators.required),
        consumptionP1: new FormControl(
            undefined,
            Validators.compose([Validators.pattern(this.validation), Validators.required])
        ),
        consumptionP2: new FormControl(
            undefined,
            Validators.compose([Validators.pattern(this.validation), Validators.required])
        ),
        consumptionP3: new FormControl(
            undefined,
            Validators.compose([Validators.pattern(this.validation), Validators.required])
        ),
        consumptionP4: new FormControl(undefined, Validators.pattern(this.validation)),
        consumptionP5: new FormControl(undefined, Validators.pattern(this.validation)),
        consumptionP6: new FormControl(undefined, Validators.pattern(this.validation)),
        Pw1: new FormControl(undefined, Validators.compose([Validators.pattern(this.validation), Validators.required])),
        Pw2: new FormControl(undefined, Validators.compose([Validators.pattern(this.validation), Validators.required])),
        Pw3: new FormControl(undefined, Validators.pattern(this.validation)),
        Pw4: new FormControl(undefined, Validators.pattern(this.validation)),
        Pw5: new FormControl(undefined, Validators.pattern(this.validation)),
        Pw6: new FormControl(undefined, Validators.pattern(this.validation)),
    });

    numberOfControls: number = 6;

    formGroupVariables = new FormGroup({
        omip: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        rrtt_sscc: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        scd: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        mae: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        mc: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        si: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        cfe: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        rom: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        ros: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        pc: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        gdos: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        fnsse: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        pm: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
        cbos: new FormArray(
            Array.from(
                { length: this.numberOfControls },
                () => new FormControl(undefined, Validators.pattern(this.validation))
            )
        ),
    });
    isValidRole: boolean = true;
    userRoles: string[] = [];

    constructor(
        private _procesosService: ProcesosService,
        private _maestroService: MaestroService,
        private _cargasService: CargasServiceService,
        private _dialog: MatDialog,
        private _loginService: LoginService,
        protected readonly _headerTitleService: HeaderTitleService
    ) {}

    ngOnInit(): void {
        this._headerTitleService.currentHeaderTranslationCode = 'FICTITIOUS_CONTRACT_TITLE';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
        this.fillRates();
        this.fillUnitProgram();
        this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
        this.isValidRole = this.checkUserRoles();
    }

    checkUserRoles(): boolean {
        let isUserRoleValid = true;
        if (!this.userRoles.includes('ADMIN') && !this.userRoles.includes('CONCILIACION')) {
            isUserRoleValid = false;
        }
        return isUserRoleValid;
    }

    /**
     * Método para obtener el valor seleccionado y mapearlo.
     * @param event Párametro del valor seleccionado
     */
    obtainClientType(event: any) {
        if (event.value === ContractClientType.PYMES) {
            this.clientType = 6;
        } else if (event.value === ContractClientType.GRANDES_CUENTAS) {
            this.clientType = 5;
        }
    }

    /**
     * Método para obtener el valor de ATR
     * @param event
     */
    obtainATR(event: any) {
        if (event.value === GestionATR.YES) {
            this.atr = '1';
        } else if (event.value === GestionATR.NO) {
            this.atr = '0';
        }
    }

    obtainAdjMec(event: any) {
        switch (event.value) {
            case AdjMecGas.SN:
                this.adjMec = '2';
                break;
            case AdjMecGas.SI:
                this.adjMec = '1';
                break;
            case AdjMecGas.NO:
                this.adjMec = '0';
                break;
        }
    }

    /**
     * Obtener el valor del sistema eléctrico
     * @param eSystem Valor del sistema eléctrico
     */
    obtainSelectedOptionElectrictSystem(eSystem: any) {
        if (eSystem.value) {
            this.electrictSystemSelected = true;
        } else {
            this.electrictSystemSelected = false;
        }
    }

    /**
     * Abrir modal de confirmar creación
     */
    openModal() {
        const dialogRef = this._dialog.open(AlertModalComponent, {
            data: { title: 'PAGE.MODALS.TITLE16', text: 'PAGE.MODALS.TEXT_TITLE_7' },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.onSubmit();
            }
        });
    }

    /**
     * Método para rellenar el filtro de Tarifas
     */
    fillRates() {
        this._maestroService.getFeesCode().subscribe((rates) => {
            this.rateList = [];
            rates.data.forEach((rate: any) => {
                this.rateList.push(rate.codeRate);
            });
        });
    }

    /**
     * Método para rellenar unidad de programa
     */
    fillUnitProgram() {
        this.unitProgramList = [];

        this._procesosService.getUnidadPrograma().subscribe((unitP) => {
            unitP.data.forEach((element: any) => {
                this.unitProgramList.push(element.programUnitCode);
            });
        });
        this.programUnit = this.unitProgramList;
    }

    /**
     * Método para obtener la unidad de Programa
     * @param programUnit Valor seleccionado
     */
    obtainUnitProgram(programUnit: any) {
        this.programUnit = programUnit.value;
    }

    onSubmit() {
        const formValues: any = this.formGroup.value;
        let body: any = {};

        Object.keys(formValues).forEach((key) => {
            if (key === 'clientType') {
                body['clientType'] = this.clientType;
            } else if (key === 'atr') {
                body['atr'] = this.atr;
            } else if (key === 'adjMec') {
                body['adjMec'] = this.adjMec;
            } else if (formValues[key]) {
                body[key] = formValues[key];
            }
        });

        if (body && body.dates && typeof body.dates === 'string' && body.dates.includes('~')) {
            // Preparamos las fechas
            const splittedDates: string = body.dates.split('~');
            body = { ...body, dateInit: splittedDates[0], dateEnd: splittedDates[1] };
            delete body['dates'];

            // Preparamos también el formulario de variables
            const variables: any = this.formGroupVariables.value;

            // Preparamos el body final
            body = { ...body, variables: this.convertObjectToArray(variables) };

            // PETICIÓN POST
            this._cargasService.postDummyContract(body).subscribe((dummyContract) => {});
        }
    }

    /**
     * Método para controlar si es formulario es válido
     * @returns
     */
    isFormValid(): boolean {
        let valid = true;
        // FormGroup
        Object.keys(this.formGroup.controls).forEach((control) => {
            if (this.formGroup.get(control)!.status === 'INVALID') {
                valid = false;
            }
        });
        // FromGroup variables
        Object.keys(this.formGroupVariables.controls).forEach((control) => {
            if (this.formGroupVariables.get(control)!.status === 'INVALID') {
                valid = false;
            }
        });
        return valid;
    }

    /**
     * Método para mapear el array de variables
     * @param originalObject
     * @returns
     */
    convertObjectToArray(originalObject: any): any[] {
        const resultArray = Object.entries(originalObject)
            .map(([variable, values]) => {
                if ((values as any[]).some((value: any) => value)) {
                    const obj: any = {};
                    (values as any[]).forEach((element: any, index: number) => {
                        if (element) {
                            obj[`priceP${index + 1}`] = Number(element);
                        }
                    });
                    variable = variable.toUpperCase();
                    return { variable, ...obj };
                }
            })
            .filter((variable: any) => variable);
        return resultArray;
    }

    /**
     * Método para controlar los validadores de los formArray
     * @param name Nombre del control
     * @param index Posición
     * @returns
     */
    formArrayHasErrors(name: string, index: number): boolean {
        return (this.formGroupVariables.controls as any)[name].controls[index].hasError('pattern');
    }

    formHasErrors(name: string) {
        return (this.formGroup.controls as any)[name].hasError('pattern');
    }

    maxLengthError(name: string) {
        return this.formGroup.controls.cnae.hasError('maxlength');
    }

    protected onSelectedDateChange(value: string): void {
        const datesControl = this.formGroup.get('dates') as FormControl;
        datesControl.setValue(value);
    }
}
