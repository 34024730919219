import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { SortingObject } from 'src/app/shared/models/files.interface';
import { PaginationObject } from 'src/app/shared/models/paginationObject.interface';
import { MaestroService } from '../services/maestro.service';
import { KeysCierres, MaestrosCRM } from 'src/app/shared/enums/maestros';
import { combineLatest } from 'rxjs';
import { PaginationIDs, SortBy, KeysMaestro } from '../../../shared/enums/maestros';
import { LoginService } from 'src/app/core/services/login.service';
import { HeaderTitleService } from '../../../services/header-title.service';
import { ExportService } from 'src/app/services/export.service';
@Component({
    selector: 'app-maestro-crm-page',
    templateUrl: './maestro-crm-page.component.html',
    styleUrls: ['./maestro-crm-page.component.scss'],
})
export class MaestroCRMPageComponent implements OnInit {
    dataBusiness: any;
    dataHeaders: IColumnsTable[];
    dataPagination: PaginationObject;
    dataSorting: SortingObject;
    columns: any;
    permissions: any;
    filters: any;
    idFilters: any;
    filtersTable: any[];
    idToCompare: string | [];

    totalRegisters: number;
    pageSize: number;
    totalPages: number;
    numPage: number;
    pageIndex: any;
    sortActive: any;
    sortDir: any;

    negotiatingAccounts: any;
    clients: any;
    sortIdFilter: any;
    sortFilter: any;
    sortDirection: any;
    sortParameter: any;
    sortId: any;
    headers: any;
    constructor(
        private _maestroService: MaestroService,
        private globalService: GlobalService,
        private _loginService: LoginService,
        private _headerTitleService: HeaderTitleService,
        private _exportService: ExportService
    ) {}

    ngOnInit(): void {
        this._headerTitleService.currentHeaderTranslationCode = 'CRM_MASTER';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
    }

    transformHeaders() {
        this.columns = this.dataHeaders.map((col) => col.id);
    }

    /**
     * Método para formatear el parámetro de busqueda
     * @param idFiltersEvent id del filtro y subfiltros
     * @returns
     */
    formatSearch(idFiltersEvent: any): string {
        let params: string = '';
        let accountsFiltered: boolean = false;
        let addParamFlag: boolean = false;

        if (idFiltersEvent[0]) {
            if (this.idFilters[0] === MaestrosCRM.SOCIEDAD) {
                for (let i = 0; i < this.idFilters[1].length; i++) {
                    if (this.idFilters[1][i].selected !== '' && this.idFilters[1][i].selected !== undefined) {
                        if (this.idFilters[1][i].key === 'clientName') {
                            params = params.concat(`${this.idFilters[1][i].key}like(${this.idFilters[1][i].selected})`);
                        } else {
                            params = params.concat(
                                `${this.idFilters[1][i].key}=${this._maestroService.selectedClient.value},`
                            );
                        }
                    }
                }
            } else {
                idFiltersEvent[1].forEach((filter: any, index: number) => {
                    accountsFiltered = false;
                    addParamFlag = false;
                    if (
                        (filter.key === 'instName' ||
                            filter.key === KeysMaestro.CONTRACT_SOCIEDADES ||
                            filter.key === 'idNumber' ||
                            filter.key === 'clientName' ||
                            filter.key === 'contract.idNumber' ||
                            filter.key === 'contractCode.idNumber') &&
                        this.idFilters[0] !== MaestrosCRM.CONTRATOS &&
                        filter.selected &&
                        !addParamFlag
                    ) {
                        if (index !== 0 && params !== '') {
                            params = params.concat(',');
                        }
                        let code;
                        if (this.idFilters[0] === MaestrosCRM.CUPS && filter.selected !== '') {
                            code = filter.selected;
                        } else {
                            filter.data.forEach((element: any) => {
                                if (filter.selected === element.name) {
                                    code = element.value;
                                }
                            });
                        }
                        params = params.concat(`${filter.key}=${code}`);
                        addParamFlag = true;
                    }

                    // Filtro de CUPS
                    if (
                        filter.type === 'cups' &&
                        (filter.key === 'cups' ||
                            filter.key === 'crmHistoricConsumptionsId.cups' ||
                            filter.key === SortBy.MEASURE_POINT) &&
                        !addParamFlag
                    ) {
                        let cups: any[] = [];
                        filter.selected.split(';').forEach((cup: any) => {
                            if (cup !== undefined && cup !== '' && !cups.includes(cup)) {
                                cups.push(cup);
                            }
                        });
                        this._maestroService.validCups = cups.toString().replace(/,/g, ';');
                        if (this._maestroService.validCups !== undefined) {
                            if (index !== 0 && params !== '') {
                                params = params.concat(',');
                            }
                            if (this._maestroService.validCups !== '') {
                                params = params.concat(`${filter.key}=(${this._maestroService.validCups})`);
                                addParamFlag = true;
                            }
                        }
                    }

                    // Filtro Descripción de Cuenta Negociadora
                    if (
                        filter.selected &&
                        filter.key === 'negotiatingAccountDescription' &&
                        this.idFilters[0] === MaestrosCRM.CUENTA_NEGOCIADORA &&
                        !addParamFlag
                    ) {
                        if (index !== 0 && params !== '') {
                            params = params.concat(',');
                        }
                        params = params.concat(`${filter.key}like(${filter.selected})`);
                        addParamFlag = true;
                    }

                    // Filtro de CUENTA NEGOCIADORA pero entrando antes en CONTRACT
                    if (
                        (filter.key === 'negotiatingAccountCode' ||
                            filter.key === KeysMaestro.CONTRACT_CUENTA_NEGOCIADORA) &&
                        filter.toggleState !== true &&
                        filter.selected &&
                        !addParamFlag
                    ) {
                        if (index !== 0 && params !== '') {
                            params = params.concat(',');
                        }

                        let code;

                        filter.data.forEach((element: any) => {
                            if (filter.selected === element.name) {
                                code = element.value;
                            }
                        });

                        params = params.concat(`${filter.key}=${code}`);
                        addParamFlag = true;
                    }

                    // Filtro de SOCIEDADES pero entrando antes en CONTRACT
                    if (
                        (filter.key === 'idNumber' || filter.key === KeysMaestro.CONTRACT_SOCIEDADES) &&
                        (this.idFilters[0] === MaestrosCRM.CONTRATOS ||
                            this.idFilters[0] === MaestrosCRM.CIERRES ||
                            this.idFilters[0] === MaestrosCRM.CONCEPTOS_CONTRATOS ||
                            this.idFilters[0] === MaestrosCRM.PRECIOS_CONTRATOS) &&
                        filter.toggleState !== true &&
                        filter.selected &&
                        !addParamFlag
                    ) {
                        if (index !== 0 && params !== '') {
                            params = params.concat(',');
                        }

                        let code;

                        filter.data.forEach((element: any) => {
                            if (filter.selected === element.name) {
                                code = element.value;
                            }
                        });

                        params = params.concat(`${filter.key}=${code}`);

                        addParamFlag = true;
                    }

                    // Filtro Concepto de Conceptos Contrato con formateo de tildes.
                    if (filter.key === 'concept' && filter.selected && !addParamFlag) {
                        if (index !== 0 && params !== '') {
                            params = params.concat(',');
                        }
                        //TODO: Descomentar cuando quiten las tildes desde Back
                        // let conceptWithoutAccents = filter.selected.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
                        // params = params.concat(`${filter.key}like(${conceptWithoutAccents})`);
                        params = params.concat(`${filter.key}like(${filter.selected})`);
                        addParamFlag = true;
                    }

                    // Filtro de Estado en la sección de CIERRES
                    if (filter.key === KeysCierres.CLOSED_STATE && filter.selected && !addParamFlag) {
                        if (index !== 0 && params !== '') {
                            params = params.concat(',');
                        }
                        let value: string | undefined = filter.data.find(
                            (option: any) => option.name === filter.selected
                        )?.value;
                        if (value) {
                            params = params.concat(`${filter.key}=${value}`);
                            addParamFlag = true;
                        }
                    }

                    // Fechas concretamente para CONTRATOS
                    if (
                        filter.type === 'Date' &&
                        idFiltersEvent[0] === MaestrosCRM.CONTRATOS &&
                        filter.selected &&
                        !addParamFlag
                    ) {
                        let splitedDates = idFiltersEvent[1][0].selected.split('~');
                        params = params.concat(`initEffectiveDate=(${splitedDates[0]};${splitedDates.at(-1)})`);
                        addParamFlag = true;
                    }

                    // Fechas concretamente para PRECIOS CONTRATO y VARIABLES CONTRATOS FICTICIOS
                    if (
                        filter.type === 'Date' &&
                        idFiltersEvent[0] === MaestrosCRM.PRECIOS_CONTRATOS &&
                        filter.selected &&
                        !addParamFlag
                    ) {
                        let splitedDates = idFiltersEvent[1][0].selected.split('~');
                        params = params.concat(`initDate=(${splitedDates[0]};${splitedDates.at(-1)})`);
                        addParamFlag = true;
                    }

                    // Fechas para todos los filtros menos para CONTRATOS, CIERRES y CONCEPTOS CONTRATO
                    if (
                        filter.type === 'Date' &&
                        idFiltersEvent[0] !== MaestrosCRM.CONCEPTOS_CONTRATOS &&
                        idFiltersEvent[0] !== MaestrosCRM.COBERTURA_CONTRATO &&
                        idFiltersEvent[0] !== MaestrosCRM.COBERTURA_CONTRATO &&
                        filter.selected &&
                        !addParamFlag
                    ) {
                        let splitedDates = idFiltersEvent[1][0].selected.split('~');
                        params = params.concat(`initDate=(${splitedDates[0]};${splitedDates.at(-1)})`);
                        addParamFlag = true;
                    }

                    // Fechas concretamente para CONCEPTOS CONTRATO
                    if (
                        filter.type === 'Date' &&
                        idFiltersEvent[0] === MaestrosCRM.COBERTURA_CONTRATO &&
                        filter.selected &&
                        !addParamFlag
                    ) {
                        let splitedDates = idFiltersEvent[1][0].selected.split('~');
                        params = params.concat(`initDate=(${splitedDates[0]};${splitedDates.at(-1)})`);
                        addParamFlag = true;
                    }
                    if (
                        filter.type === 'Date' &&
                        idFiltersEvent[0] === MaestrosCRM.CONCEPTOS_CONTRATOS &&
                        filter.selected &&
                        !addParamFlag
                    ) {
                        let splitedDates = idFiltersEvent[1][0].selected.split('~');
                        params = params.concat(`beginDate=(${splitedDates[0]};${splitedDates.at(-1)})`);
                        addParamFlag = true;
                    }

                    if (
                        filter.selected &&
                        filter.type !== 'Date' &&
                        filter.key !== 'negotiatingAccountCode' &&
                        filter.toggleState !== false &&
                        !accountsFiltered &&
                        !addParamFlag
                    ) {
                        if (index !== 0 && params !== '') {
                            params = params.concat(',');
                        }
                        if (
                            filter.key === 'crmHistoricConsumptionsId.year' ||
                            filter.key === 'status'
                        ) {
                            params = params.concat(`${filter.key}=${filter.selected}`);
                        } else if (filter.key === 'clientType'){
                            const formatedFilter = `(${filter.selected.split(',').join(';')})`;
                            params = params.concat(`${filter.key}=${formatedFilter}`);
                        } else {
                            params = params.concat(`${filter.key}like(${filter.selected})`);
                        }
                        addParamFlag = true;
                    } else {
                        if (filter.selected && filter.type !== 'Date' && !addParamFlag) {
                            if (index !== 0 && params !== '') {
                                params = params.concat(',');
                            }
                            params = params.concat(`${filter.key}=${filter.selected}`);
                            addParamFlag = true;
                        }
                    }
                });
            }
        }
        return params;
    }

    private getInfoTabla(idFilters: any, filter: boolean, sortParameter?: string) {
        this.permissions = this.globalService.transformPermissions(idFilters[0]);
        this.idFilters = idFilters;
        this.headers = this._loginService.getHeaderSession();
        if (!this.sortDirection) {
            this.sortDirection = 'asc';
        }
        switch (idFilters[0]) {
            case MaestrosCRM.CUPS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaCUPS(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_cups'); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosCRM.SOCIEDAD:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaSociedad(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_sociedad'); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosCRM.CIERRES:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaCierres(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestros_cierres'); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosCRM.CUENTA_NEGOCIADORA:
                this._maestroService.searchParam = this.formatSearch(idFilters);

                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaCuentaNegociadora(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                        this.headers,
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_cuentaNegociadora'); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosCRM.GRUPO_FACTURACION:
                this._maestroService.getTablagrupoFact().subscribe((res) => {
                    this.dataBusiness = res.grupoFacturacion;
                    this.dataHeaders = res.headers;
                    this.totalRegisters = res.totalRegisters;
                    this.transformHeaders();
                });
                break;
            case MaestrosCRM.COBERTURA_CONTRATO:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaCoberturaContrato(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                        this.headers,
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_coberturasContrato'); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosCRM.PRECIOS_CONTRATOS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaPreciosContratos(
                            1,
                            10,
                            this.sortParameter ?? `${SortBy.CRM_VARIABLE},${SortBy.PERIOD}`,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_preciosContratos'); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosCRM.CONTRATOS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getContractsTable(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this._exportService.totalRegisters = res[0].page.totalElements;
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_contratos'); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosCRM.CONCEPTOS_CONTRATOS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getContractConceptsTable(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        if (res && res[0] && res[0].data) {
                            res[0].data.forEach((contract: any) => {
                                contract.liqType = this.liquidationConverter(contract.liqType);
                            });
                        }
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_conceptosContratos'); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosCRM.CONSUMO_ANUAL:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaConsumoAnual(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_consumoAnual'); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosCRM.PUNTO_MEDIDA:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaPuntoMedida(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_puntoMedida'); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            default:
                console.log('No se ha encontrado la tabla');
                break;
        }
        this.sortParameter = undefined;
    }

    receiveIdFilters($event: any) {
        this.getInfoTablaByFilterSort($event);
    }

    clearTable($event: any) {
        this.dataBusiness = undefined;
        this.sortActive = undefined;
    }

    receiveIdSelect(event: any) {
        let filter: boolean = false;
        for (let index = 0; index < event[1].length; index++) {
            if (event[1][index].selected) {
                filter = true;
            }
        }
        this.filtersTable = event;
        this.getInfoTabla(event, filter);

        // TOdo cambiar las peticiones aqui
    }

    reset() {}
    getInfoTablaByFilterSort(s: any) {}

    sort(event: any, id: string) {
        this.sortDir = event.direction;
        this.sortActive = event.active;
        this.sortDirection = event.sort.direction;
        this.sortParameter = event.sort.active;

        this.getInfoTabla(this.idFilters, this.sortFilter, this.sortParameter);
        switch (id) {
            case PaginationIDs.DISTRIBUIDOR:
                this._maestroService.getDistributorsTable(this.pageIndex, this.pageSize, event.active, event.direction);
                break;
            case PaginationIDs.TARIFA:
                this._maestroService.getFeesTable(
                    this.pageIndex,
                    this.pageSize,
                    event.active,
                    event.direction,
                    this._maestroService.searchParam
                );
                break;

            default:
                break;
        }
    }

    pagination(event: any) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        // Filtrar por ...
        if (!this.sortActive) {
            switch (this.idFilters[0]) {
                case PaginationIDs.DISTRIBUIDOR:
                    this.sortActive = SortBy.CODE;
                    break;
                case PaginationIDs.TARIFA:
                    this.sortActive = SortBy.CODE_RATE;
                    break;
                case PaginationIDs.SOCIEDAD:
                    this.sortActive = SortBy.ID_NUMBER;
                    break;
                case PaginationIDs.CUPS:
                    this.sortActive = SortBy.CUPS;
                    break;
                case PaginationIDs.CONTRATOS:
                    this.sortActive = SortBy.CONTRACT_CODE;
                    break;
                case PaginationIDs.CLOSURE:
                    this.sortActive = SortBy.CLOSED;
                    break;
                case PaginationIDs.CONTRACT_PRICES:
                    this.sortActive = `${SortBy.CRM_VARIABLE},${SortBy.PERIOD}`;
                    break;
                case PaginationIDs.CONSUMO_ANUAL:
                    this.sortActive = SortBy.HISTORIC;
                    break;
                case PaginationIDs.PUNTO_MEDIDA:
                    this.sortActive = SortBy.MEASURE_POINT;
                    break;
                case PaginationIDs.COBERTURA_CONTRATO:
                    this.sortActive = SortBy.COBERTURA_CONTRATO;
                    break;
                default:
                    break;
            }
        }

        // Ordenar por ASC o DESC
        if (!this.sortDir) {
            this.sortDir = 'asc';
        }
        event.pageIndex = event.pageIndex + 1;
        switch (this.idFilters[0]) {
            case PaginationIDs.SOCIEDAD:
                this._maestroService
                    .getTablaSociedad(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this._maestroService.searchParam
                    )
                    .subscribe((res: any) => {
                        this.dataBusiness = res.data;
                        this.totalRegisters = res.page.totalElements;
                        this.numPage = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                    });
                break;
            case PaginationIDs.CONTRATOS:
                this._maestroService
                    .getContractsTable(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this._maestroService.searchParam
                    )
                    .subscribe((res: any) => {
                        this.dataBusiness = res.data;
                        this.totalRegisters = res.page.totalElements;
                        this.numPage = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                    });
                break;
            case PaginationIDs.CUPS:
                this._maestroService
                    .getTablaCUPS(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this._maestroService.searchParam
                    )
                    .subscribe((res: any) => {
                        this.dataBusiness = res.data;
                        this.totalRegisters = res.page.totalElements;
                        this.numPage = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                    });
                break;
            case PaginationIDs.CLOSURE:
                this._maestroService
                    .getTablaCierres(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this._maestroService.searchParam
                    )
                    .subscribe((res: any) => {
                        this.dataBusiness = res.data;
                        this.totalRegisters = res.page.totalElements;
                        this.numPage = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                    });
                break;
            case PaginationIDs.CONTRACT_PRICES:
                this._maestroService
                    .getTablaPreciosContratos(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this._maestroService.searchParam
                    )
                    .subscribe((res: any) => {
                        this.dataBusiness = res.data;
                        this.totalRegisters = res.page.totalElements;
                        this.numPage = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                    });
                break;
            case PaginationIDs.NEGOTIATING_ACCOUNT:
                this._maestroService
                    .getTablaCuentaNegociadora(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this._maestroService.searchParam
                    )
                    .subscribe((res: any) => {
                        this.dataBusiness = res.data;
                        this.totalRegisters = res.page.totalElements;
                        this.numPage = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                    });
                break;
            case PaginationIDs.CONSUMO_ANUAL:
                this._maestroService
                    .getTablaConsumoAnual(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this._maestroService.searchParam
                    )
                    .subscribe((res: any) => {
                        this.dataBusiness = res.data;
                        this.totalRegisters = res.page.totalElements;
                        this.numPage = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                    });
                break;
            case PaginationIDs.PUNTO_MEDIDA:
                this._maestroService
                    .getTablaPuntoMedida(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this._maestroService.searchParam
                    )
                    .subscribe((res: any) => {
                        this.dataBusiness = res.data;
                        this.totalRegisters = res.page.totalElements;
                        this.numPage = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                    });
                break;
            case PaginationIDs.COBERTURA_CONTRATO:
                this._maestroService
                    .getTablaCoberturaContrato(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this._maestroService.searchParam
                    )
                    .subscribe((res: any) => {
                        this.dataBusiness = res.data;
                        this.totalRegisters = res.page.totalElements;
                        this.numPage = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                    });
                break;
            case MaestrosCRM.CONCEPTOS_CONTRATOS:
                this._maestroService
                    .getContractConceptsTable(
                        event.pageIndex,
                        event.pageSize,
                        this.sortActive,
                        this.sortDir,
                        this._maestroService.searchParam
                    )
                    .subscribe((res: any) => {
                        this.dataBusiness = res.data;
                        this.totalRegisters = res.page.totalElements;
                        this.numPage = res.page.number;
                        this.pageSize = res.page.size;
                        this.totalPages = res.page.totalPages;
                        if (res && res.data) {
                            res.data.forEach((contract: any) => {
                                contract.liqType = this.liquidationConverter(contract.liqType);
                            });
                        }
                    });
                break;
            default:
                break;
        }
    }

    liquidationConverter(liquidationType: number) {
        let convertedLiquidationType;
        switch (liquidationType) {
            case 1:
                convertedLiquidationType = 'Medida horaria';
                break;
            case 2:
                convertedLiquidationType = 'Factura ATR';
                break;
            default:
                convertedLiquidationType = liquidationType;
        }
        return convertedLiquidationType;
    }

    buildTable(res: any, header: any, nameHeaders: string): void {
        this.dataBusiness = res[0].data;
        header.data.forEach((element: any) => {
            if (element.name === nameHeaders) {
                this.dataHeaders = element.headers;
            }
        });
        this.totalRegisters = res[0].page.totalElements;
        this.numPage = res[0].page.number;
        this.pageSize = res[0].page.size;
        this.totalPages = res[0].page.totalPages;
        this.transformHeaders();
    }
}
