<!-- PANTALLA QUE SE MUESTRA SI USUARIO NO TIENE ROLES ADECUADOS -->
<div *ngIf="!validRole">
    <h1 class="errorMsg">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h1>
</div>
<div *ngIf="validRole">
    <app-select-cuadro-mandos
        (optionSelectedId)="loadTable($event)"
        (onButtonColumn)="populateSelectedSection($event, true)"
        (resetTable)="resetTable()"
        (launchReport)="launchReport($event)"
    ></app-select-cuadro-mandos>
    <div class="container">
        <div *ngIf="spinnerActive">
            <div class="content-spinner">
                <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        <div class="row row-column table-container" *ngIf="dataHeaders && fixSpinner">
            <div class="table-header">
                <div class="table-header-info">
                    <span
                        >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                        {{ totalRegisters | numberFormat : _headerTitleService.currentLang }}</span
                    >
                </div>
                <div class="display_flex">
                    <button class="buttonColumns mat margin-r" (click)="onClickOpenCupsBlockUnBlockModal()">
                        <span class="spanColumn">{{ 'PAGE.DASHBOARD.CUPS' | translate }}</span>
                        <app-base-icon class="padding_button" iconName="open_with"></app-base-icon>
                    </button>
                    <button class="buttonColumns mat margin-r" (click)="onClickOpenBulkBlockUnBlockModal()">
                        <span class="spanColumn">{{ 'PAGE.DASHBOARD.BLOCK' | translate }}</span>
                        <app-base-icon class="padding_button" iconName="block"></app-base-icon>
                    </button>
                    <button class="buttonColumns mat margin-r" (click)="onClickOpenBulkBestMeasureCloseModal()">
                        <span class="spanColumn">{{ 'PAGE.DASHBOARD.BULK_SELECTION' | translate }}</span>
                        <app-base-icon class="padding_button" iconName="add_to_photos"></app-base-icon>
                    </button>
                    <button class="buttonColumns" [ngClass]="ocultar === true ? 'mat' : 'mat_none'">
                        <!-- INICIO>>> Botón mostrar/ocultar columnas -->
                        <mat-form-field [hidden]="ocultar">
                            <mat-select
                                [formControl]="selectColumns"
                                name="selectColumns"
                                id="selectColumns"
                                [value]="getHeadersValue()"
                                multiple
                            >
                                <mat-option
                                    *ngFor="let column of getTableDataHeaders()"
                                    enableCheckAll="true"
                                    [matColumnDef]="column.id"
                                    [value]="column"
                                    (click)="onClickPopulateColumnHeaders(column.id, selectColumns.value)"
                                >
                                    {{ column.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="divColumns" (click)="onClickShowColumnsSelector()">
                            <span class="text spanColumn">{{ 'PAGE.ACTIONS.COLUMNS' | translate }}</span>
                            <app-base-icon class="padding_button" iconName="view_column"></app-base-icon>
                        </div>
                    </button>
                </div>
            </div>
            <div class="table-container-main table-container__no-padding">
                <div class="table-containertable-container__no-padding">
                    <table
                        mat-table
                        multiTemplateDataRows
                        [dataSource]="dataBusiness"
                        aria-describedby="Dashboard table"
                    >
                        <ng-container
                            style="max-width: fit-content"
                            [matColumnDef]="column?.id"
                            *ngFor="let column of dataHeaders"
                        >
                            <th
                                mat-header-cell
                                *matHeaderCellDef
                                style="padding: 0px 10px 0px 10px !important"
                                class="center_info_table_label"
                            >
                                {{ column?.label }}
                            </th>
                            <td
                                mat-cell
                                *matCellDef="let emp"
                                style="padding: 0 !important"
                                [ngClass]="
                                    emp.executionMeasure || 
                                    emp.executionClose || 
                                    emp.executionBlockUnblock ||
                                    emp.executionCups
                                        ? 'blockedRow'
                                        : ''
                                "
                            >
                                <div
                                    class="center_info_table"
                                    *ngIf="
                                        column?.id !== 'zerosValue' &&
                                        column?.id !== 'gapsValue' &&
                                        column?.id !== 'peaksValue' &&
                                        column?.id !== 'blockedFlag' &&
                                        column?.id !== 'origin' &&
                                        column?.id !== 'inNetEnergyValue' &&
                                        column?.id !== 'vsLastMonthValue' &&
                                        column?.id !== 'periodCode' &&
                                        column?.id !== 'lastYearComparison' &&
                                        column?.id !== 'lastYearStatus' &&
                                        column?.id !== 'origenBestMeasure' &&
                                        column?.id !== 'originBestClose' &&
                                        column?.id !== 'reeInNetEnergy' &&
                                        column?.id !== 'acumInNetEnergy4' &&
                                        column?.id !== 'bestLiquidation' &&
                                        column?.id !== 'atrProcess' &&
                                        column?.id !== 'increEnergyMeasure' &&
                                        column?.id !== 'increEnergyClose' &&
                                        column?.id !== 'closeDateBegin' &&
                                        column?.id !== 'definitiveCheck' &&
                                        column?.id !== 'powerExcessStatus' &&
                                        column?.id !== 'status' &&
                                        column?.id !== 'closeDateEnd' &&
                                        column?.id !== DHResumenConPeriodos[0] &&
                                        column?.id !== DHResumenConPeriodos[1] &&
                                        column?.id !== DHResumenConPeriodos[2] &&
                                        column?.id !== DHResumenConPeriodos[3] &&
                                        column?.id !== DHResumenConPeriodos[4] &&
                                        column?.id !== DHResumenConPeriodos[5] &&
                                        column?.id !== DHResumenConPeriodos[6] &&
                                        column?.id !== DHResumenConPeriodos[7] &&
                                        column?.id !== DHResumenConPeriodos[8] &&
                                        column?.id !== DHResumenConPeriodos[9] &&
                                        column?.id !== DHResumenConPeriodos[10] &&
                                        column?.id !== DHResumenConPeriodos[11] &&
                                        column?.id !== DHResumenConPeriodos[12] &&
                                        column?.id !== DHResumenConPeriodos[13] &&
                                        column?.id !== DHResumenConPeriodos[14] &&
                                        column?.id !== DHResumenConPeriodos[15] &&
                                        column?.id !== DHResumenConPeriodos[16] &&
                                        column?.id !== DHResumenConPeriodos[17] &&
                                        column?.id !== DHResumenConPeriodos[18] &&
                                        column?.id !== DHResumenConPeriodos[19] &&
                                        column?.id !== DHResumenConPeriodos[20] &&
                                        column?.id !== DHResumenConPeriodos[21] &&
                                        column?.id !== DHResumenConPeriodos[22] &&
                                        column?.id !== DHResumenConPeriodos[23] &&
                                        column?.id !== DHResumenConPeriodos[24] &&
                                        column?.id !== DHResumenConPeriodos[25] &&
                                        column?.id !== DHResumenConPeriodos[26] &&
                                        column?.id !== DHResumenConPeriodos[27] &&
                                        column?.id !== DHResumenConPeriodos[28] &&
                                        column?.id !== DHResumenConPeriodos[29] &&
                                        column?.id !== DHResumenConPeriodos[30]
                                    "
                                    [ngClass]="setStatusClass(emp[column?.id])!"
                                    [ngClass]="{
                                        'table-cell--padding':
                                            column?.id === 'cups' ||
                                            column?.id === 'negotiatingAccountDescription' ||
                                            column?.id === 'clientName' ||
                                            column?.id === 'instName' ||
                                            column?.id === 'rate' ||
                                            column?.id === 'rate' ||
                                            column?.id === 'pmType' ||
                                            column?.id === 'atrProcess' ||
                                            column?.id === 'closeDateBegin' ||
                                            column?.id === 'closeDateEnd'
                                    }"
                                >
                                    {{ emp[column?.id] }}
                                </div>
                                <div class="center_info_table" *ngIf="column?.id === 'atrProcess'">
                                    {{
                                        emp[column?.id] == 1
                                            ? ('PAGE.MODALS.YES' | translate)
                                            : ('PAGE.MODALS.NO' | translate)
                                    }}
                                </div>
                                <div
                                    class="center_info_table"
                                    *ngIf="column?.id === 'definitiveCheck' || column?.id === 'powerExcessStatus'"
                                    [ngClass]="emp[column?.id] === 'OK' ? 'green' : 'red'"
                                >
                                    {{ emp[column?.id] }}
                                </div>
                                <div class="center_info_table" *ngIf="column?.id === 'status'">
                                    {{
                                        emp[column?.id]
                                            ? emp[column?.id]
                                            : ('PAGE.DASHBOARD.SETTLEMENT_PENDING' | translate)
                                    }}
                                </div>
                                <ng-container *ngIf="emp[column?.id] && emp[column?.id] && emp[column?.id].length > 0">
                                    <!-- Ceros -->
                                    <div *ngIf="column?.id === 'zerosValue'" class="center_info_table">
                                        {{ checkIfIsNumber(emp[column?.id][0]) }}
                                    </div>
                                    <div
                                        class="green center_info_table"
                                        *ngIf="column?.id === 'zerosValue' && emp[column?.id][1] === 'OK'"
                                    >
                                        {{ emp[column?.id][1] }}
                                    </div>
                                    <div
                                        class="red center_info_table"
                                        *ngIf="column?.id === 'zerosValue' && emp[column?.id][1] === 'KO'"
                                    >
                                        {{ emp[column?.id][1] }}
                                    </div>
                                    <!-- Huecos -->
                                    <div *ngIf="column?.id === 'gapsValue'" class="center_info_table">
                                        {{ checkIfIsNumber(emp[column?.id][0]) }}
                                    </div>
                                    <div
                                        class="green center_info_table"
                                        *ngIf="column?.id === 'gapsValue' && emp[column?.id][1] === 'OK'"
                                    >
                                        {{ emp[column?.id][1] }}
                                    </div>
                                    <div
                                        class="red center_info_table"
                                        *ngIf="column?.id === 'gapsValue' && emp[column?.id][1] === 'KO'"
                                    >
                                        {{ emp[column?.id][1] }}
                                    </div>
                                    <!-- Picos -->
                                    <div *ngIf="column?.id === 'peaksValue'" class="center_info_table">
                                        {{ checkIfIsNumber(emp[column?.id][0]) }}
                                    </div>
                                    <div
                                        class="green center_info_table"
                                        *ngIf="column?.id === 'peaksValue' && emp[column?.id][1] === 'OK'"
                                    >
                                        {{ emp[column?.id][1] }}
                                    </div>
                                    <div
                                        class="red center_info_table"
                                        *ngIf="column?.id === 'peaksValue' && emp[column?.id][1] === 'KO'"
                                    >
                                        {{ emp[column?.id][1] }}
                                    </div>
                                    <!-- Total EA -->
                                    <div *ngIf="column?.id === 'inNetEnergyValue'" class="center_info_table">
                                        {{ checkIfIsNumber(emp[column?.id][0]) }}
                                    </div>
                                    <div
                                        class="green center_info_table"
                                        *ngIf="column?.id === 'inNetEnergyValue' && emp[column?.id][1] === 'OK'"
                                    >
                                        {{ emp[column?.id][1] }}
                                    </div>
                                    <div
                                        class="red center_info_table"
                                        *ngIf="column?.id === 'inNetEnergyValue' && emp[column?.id][1] === 'KO'"
                                    >
                                        {{ emp[column?.id][1] }}
                                    </div>
                                    <!-- Comparación mes anterior -->
                                    <div *ngIf="column?.id === 'vsLastMonthValue'" class="center_info_table">
                                        {{ checkIfIsNumber(emp[column?.id][0]) }}
                                    </div>
                                    <div
                                        class="green center_info_table"
                                        *ngIf="column?.id === 'vsLastMonthValue' && emp[column?.id][1] === 'OK'"
                                    >
                                        {{ emp[column?.id][1] }}
                                    </div>
                                    <div
                                        class="red center_info_table"
                                        *ngIf="column?.id === 'vsLastMonthValue' && emp[column?.id][1] === 'KO'"
                                    >
                                        {{ emp[column?.id][1] }}
                                    </div>
                                    <!-- Fechas -->
                                    <div *ngIf="column?.id === 'closeDateBegin'">
                                        {{ transformDate(emp[column?.id]) }}
                                    </div>
                                    <div *ngIf="column?.id === 'closeDateEnd'">
                                        {{ transformDate(emp[column?.id]) }}
                                    </div>
                                    <ng-container *ngIf="column?.id === 'bestLiquidation'">
                                        <div class="center_info_table">
                                            <div *ngFor="let element of arrayEmpty; let indice = index">
                                                <div [ngClass]="indice % 2 === 0 ? 'lightGray' : 'darkGray'">
                                                    {{ checkIfIsNumber(emp[column?.id][indice]) }}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <!-- Bloqueado -->
                                <div *ngIf="column?.id === 'blockedFlag'">
                                    <ng-container
                                        *ngIf="emp.executionBlockUnblock !== null && emp.executionBlockUnblock === true"
                                    >
                                        <div class="spinner_icon block_spinner_icon">
                                            <div class="lds-ring">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <mat-checkbox
                                        [checked]="emp[column?.id]"
                                        [disabled]="
                                            (emp.executionBlockUnblock !== null &&
                                                emp.executionBlockUnblock === true) ||
                                            emp.executionMeasure ||
                                            emp.executionClose ||
                                            emp.executionBlockUnblock ||
                                            emp.executionCups
                                        "
                                        (click)="changeBlockFlag($event, emp, !emp[column?.id])"
                                        class="reduce_width center_info_table display_center"
                                    ></mat-checkbox>
                                </div>
                                <!-- Selección mejor medida definitiva -->
                                <div
                                    *ngIf="column?.id === 'origenBestMeasure'"
                                    class="flex_column display_flex origin_selects_div"
                                >
                                    <ng-container *ngIf="isLiquidationPage && emp.measureOrigin">
                                        <div class="center_info_table">
                                            {{ emp.measureOrigin }}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!isLiquidationPage">
                                        <ng-container *ngIf="emp.executionMeasure === true">
                                            <div class="spinner_icon">
                                                <div class="lds-ring">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <mat-select
                                            class="form-control origin_selects"
                                            id="matBestMeasureSelect"
                                            #matBestMeasureSelect
                                            name="matBestMeasureSelect"
                                            placeholder="{{
                                                !emp.isBestMeasuresListAvailable
                                                    ? ('PAGE.DASHBOARD.BEST_MEASURE' | translate)
                                                    : emp.definitiveBestMeasureOrigin
                                            }}"
                                            [disabled]="
                                                !emp.isBestMeasuresListAvailable ||
                                                emp.executionMeasure ||
                                                emp.executionClose ||
                                                emp.executionBlockUnblock ||
                                                emp.executionCups
                                            "
                                            [ngClass]="emp.isBestMeasuresListAvailable ? 'placeholder--black' : ''"
                                        >
                                            <mat-option
                                                [value]="opt"
                                                class="form-control"
                                                *ngFor="let opt of emp.availableBestMeasuresList"
                                            >
                                                {{
                                                    opt.includes('AWS') &&
                                                    emp.awsOrigin !== '' &&
                                                    emp.awsOrigin !== undefined &&
                                                    emp.awsOrigin !== null
                                                        ? emp.awsOrigin
                                                        : opt
                                                }}
                                            </mat-option>
                                        </mat-select>
                                        <button
                                            class="buttonColumns mat margin-b margin-r-l margin-t"
                                            [ngClass]="
                                                !emp.isBestMeasuresListAvailable ||
                                                emp.executionMeasure ||
                                                emp.executionClose ||
                                                emp.executionBlockUnblock ||
                                                emp.executionCups
                                                    ? 'buttonDisabled'
                                                    : ''
                                            "
                                            mat-button
                                            (click)="onClickChangeBestMeasure(matBestMeasureSelect.value, emp)"
                                            [disabled]="
                                                !emp.isBestMeasuresListAvailable ||
                                                emp.executionMeasure ||
                                                emp.executionClose ||
                                                emp.executionBlockUnblock ||
                                                emp.executionCups
                                            "
                                        >
                                            {{ 'PAGE.MODALS.SAVE' | translate }}
                                        </button>
                                    </ng-container>
                                </div>
                                <!-- Selección mejor cierre definitivo -->
                                <div
                                    *ngIf="column?.id === 'originBestClose'"
                                    class="flex_column display_flex origin_selects_div"
                                >
                                    <ng-container *ngIf="emp.executionClose === true">
                                        <div class="spinner_icon">
                                            <div class="lds-ring">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <mat-select
                                        class="form-control origin_selects"
                                        id="matBestCloseSelect"
                                        #matBestCloseSelect
                                        name="matBestCloseSelect"
                                        placeholder="{{
                                            !emp.isBestClosesListAvailable
                                                ? ('PAGE.DASHBOARD.BEST_CLOSE' | translate)
                                                : emp.definitiveBestCloseOrigin
                                        }}"
                                        [(ngModel)]="
                                            emp['definitiveBestCloseOrigin'] !== undefined &&
                                            emp['definitiveBestCloseOrigin'] !== null
                                                ? emp['definitiveBestCloseOrigin']
                                                : emp.originBestClose
                                        "
                                        [disabled]="
                                            !emp.isBestClosesListAvailable ||
                                            emp.executionMeasure ||
                                            emp.executionClose ||
                                            emp.executionBlockUnblock ||
                                            emp.executionCups
                                        "
                                        [ngClass]="emp.isBestClosesListAvailable ? 'placeholder--black' : ''"
                                    >
                                        <ng-container *ngFor="let opt of emp.availableBestClosesList">
                                            <ng-container *ngIf="opt.includes('AWS')">
                                                <mat-option [value]="emp.originBestClose" class="form-control">
                                                    {{ emp.originBestClose }}
                                                </mat-option>
                                            </ng-container>
                                            <ng-container *ngIf="!opt.includes('AWS')">
                                                <mat-option [value]="opt" class="form-control">
                                                    {{ opt }}
                                                </mat-option>
                                            </ng-container>
                                        </ng-container>
                                    </mat-select>
                                    <button
                                        class="buttonColumns mat margin-b margin-r-l margin-t"
                                        [ngClass]="
                                            !emp.isBestClosesListAvailable ||
                                            emp.executionMeasure ||
                                            emp.executionClose ||
                                            emp.executionBlockUnblock ||
                                            emp.executionCups
                                                ? 'buttonDisabled'
                                                : ''
                                        "
                                        mat-button
                                        (click)="onClickChangeBestClose(matBestCloseSelect.value, emp)"
                                        [disabled]="
                                            !emp.isBestClosesListAvailable ||
                                            emp.executionMeasure ||
                                            emp.executionClose ||
                                            emp.executionBlockUnblock ||
                                            emp.executionCups
                                        "
                                    >
                                        {{ 'PAGE.MODALS.SAVE' | translate }}
                                    </button>
                                </div>
                                <!-- Datos de periodos -->
                                <div
                                    class="center_info_table"
                                    *ngIf="
                                        column?.id === 'manualInNetEnergy' ||
                                        column?.id === 'distFInNetEnergy' ||
                                        column?.id === 'distPInNetEnergy' ||
                                        column?.id === 'periodCode' ||
                                        column?.id === 'lastYearComparison' ||
                                        column?.id === 'reeInNetEnergy' ||
                                        column?.id === 'hubInNetEnergy' ||
                                        column?.id === 'atrInNetEnergy' ||
                                        column?.id === 'bestForecastInNetEnergy' ||
                                        column?.id === 'awsInNetEnergy' ||
                                        column?.id === 'acumInNetEnergy' ||
                                        column?.id === 'inmeclosInNetEnergy' ||
                                        column?.id === 'manualOutNetEnergy' ||
                                        column?.id === 'distPOutNetEnergy' ||
                                        column?.id === 'hubOutNetEnergy' ||
                                        column?.id === 'reeOutNetEnergy' ||
                                        column?.id === 'atrOutNetEnergy' ||
                                        column?.id === 'awsOutNetEnergy' ||
                                        column?.id === 'inmeclosOutNetEnergy' ||
                                        column?.id === 'closePeriod' ||
                                        column?.id === 'distFOutNetEnergy' ||
                                        column?.id === 'manualIncreEnergy' ||
                                        column?.id === 'quarterHourlyIncreEnergy' ||
                                        column?.id === 'atrIncreEnergy' ||
                                        column?.id === 'mixedIncreEnergy' ||
                                        column?.id === 'hourlyIncreEnergy' ||
                                        column?.id === 'hubIncreEnergy' ||
                                        column?.id === 'distributorIncreEnergy' ||
                                        column?.id === 'awsBestIncreEnergy' ||
                                        column?.id === 'atrPowerExcess' ||
                                        column?.id === 'forecastHours' ||
                                        column?.id === 'forecastEnergy' ||
                                        column?.id === 'hubIncreEnergy' ||
                                        column?.id === 'bestPowerExcess' ||
                                        column?.id === 'increEnergyMeasure' ||
                                        column?.id === 'increEnergyClose'
                                    "
                                >
                                    <div *ngFor="let element of arrayEmpty; let indice = index">
                                        <ng-container
                                            *ngIf="
                                                emp[column?.id] && emp[column?.id] !== null && emp[column?.id][indice]
                                            "
                                        >
                                            <div [ngClass]="indice % 2 === 0 ? 'lightGray' : 'darkGray'">
                                                {{ checkIfIsNumber(emp[column?.id][indice]) }}
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <!-- Indicador año anterior -->
                                <div
                                    *ngIf="
                                        column?.id === 'lastYearStatus' && emp[column?.id] && emp[column?.id].length > 0
                                    "
                                    class="center_info_table"
                                >
                                    <div *ngFor="let element of arrayEmpty; let indice = index">
                                        <div
                                            class="red lightGray"
                                            *ngIf="
                                                emp[column?.id] &&
                                                emp[column?.id].length > 0 &&
                                                emp[column?.id][indice] === 'KO' &&
                                                indice % 2 === 0
                                            "
                                        >
                                            {{ emp[column?.id][indice] }}
                                        </div>
                                        <div
                                            class="red darkGray"
                                            *ngIf="
                                                emp[column?.id] &&
                                                emp[column?.id].length > 0 &&
                                                emp[column?.id][indice] === 'KO' &&
                                                indice % 2 !== 0
                                            "
                                        >
                                            {{ emp[column?.id][indice] }}
                                        </div>
                                        <div
                                            class="green lightGray"
                                            *ngIf="
                                                emp[column?.id] &&
                                                emp[column?.id].length > 0 &&
                                                emp[column?.id][indice] === 'OK' &&
                                                indice % 2 === 0
                                            "
                                        >
                                            {{ emp[column?.id][indice] }}
                                        </div>
                                        <div
                                            class="green darkGray"
                                            *ngIf="
                                                emp[column?.id] &&
                                                emp[column?.id].length > 0 &&
                                                emp[column?.id][indice] === 'OK' &&
                                                indice % 2 !== 0
                                            "
                                        >
                                            {{ emp[column?.id][indice] }}
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let elementor of DHResumenConPeriodos; let indi = index">
                                    <div
                                        *ngIf="emp[column?.id] !== undefined && emp[column?.id] !== null"
                                        class="center_info_table"
                                    >
                                        <div
                                            *ngIf="
                                                column?.id === elementor &&
                                                column?.id != 'manualInNetEnergy' &&
                                                column?.id != 'distPInNetEnergy' &&
                                                column?.id != 'distFInNetEnergy' &&
                                                column?.id != 'hubInNetEnergy' &&
                                                column?.id != 'atrInNetEnergy' &&
                                                column?.id != 'bestForecastInNetEnergy' &&
                                                column?.id != 'awsInNetEnergy' &&
                                                column?.id != 'acumInNetEnergy' &&
                                                column?.id != 'inmeclosInNetEnergy' &&
                                                column?.id != 'manualOutNetEnergy' &&
                                                column?.id != 'distPOutNetEnergy' &&
                                                column?.id != 'hubOutNetEnergy' &&
                                                column?.id != 'reeOutNetEnergy' &&
                                                column?.id != 'atrOutNetEnergy' &&
                                                column?.id != 'awsOutNetEnergy' &&
                                                column?.id != 'inmeclosOutNetEnergy' &&
                                                column?.id != 'closePeriod' &&
                                                column?.id != 'distFOutNetEnergy' &&
                                                column?.id != 'manualIncreEnergy' &&
                                                column?.id != 'quarterHourlyIncreEnergy' &&
                                                column?.id != 'atrIncreEnergy' &&
                                                column?.id != 'mixedIncreEnergy' &&
                                                column?.id != 'hourlyIncreEnergy' &&
                                                column?.id != 'distributorIncreEnergy' &&
                                                column?.id != 'awsBestIncreEnergy' &&
                                                column?.id != 'atrPowerExcess' &&
                                                column?.id != 'forecastHours' &&
                                                column?.id != 'forecastEnergy' &&
                                                column?.id != 'hubIncreEnergy' &&
                                                column?.id != 'increEnergyMeasure' &&
                                                column?.id != 'increEnergyClose' &&
                                                column?.id != 'bestPowerExcess' &&
                                                column?.id != 'reeInNetEnergy'
                                            "
                                        >
                                            <div *ngFor="let element of arrayEmpty; let indice = index">
                                                {{
                                                    emp[column?.id][indice] !== 0 && emp[column?.id][indice] !== '0'
                                                        ? emp[column?.id][indice]
                                                        : NULL_VALUE_SIGN
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="columns"></tr>
                        <tr mat-row *matRowDef="let row; columns: columns"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="9999">
                                {{ 'PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA' | translate }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <mat-paginator
            [ngClass]="visibilityPagination === true ? 'visibilityOFF' : 'visibilityON'"
            [length]="totalRegisters"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="numPage - 1"
            (page)="onPageChangeUpdateRegistersAndPaginationOptions($event)"
            aria-label="Select page"
        >
        </mat-paginator>
        <div class="container containerNoData" *ngIf="!fixSpinner">
            <div class="row flex_column">
                <app-http-error></app-http-error>
                <h3>{{ 'PAGE.ACTIONS.NO_DATA' | translate }}</h3>
            </div>
            <app-table-no-data></app-table-no-data>
        </div>
    </div>
</div>
<!-- MODAL CUPS -->
<div *ngIf="isCupsModalVisible" class="cdk-overlay-container">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
    <div class="modal_box cdk-overlay-container">
        <div
            class="block_modal"
            class="cdk-overlay-pane"
            class="mat-dialog-container ng-tns-c122-25 ng-trigger ng-trigger-dialogContainer ng-star-inserted"
        >
            <div class="space_between">
                <h1 mat-dialog-title>{{ 'PAGE.DASHBOARD.CUPS_SELECTION' | translate }}</h1>
                <button class="hide-access close" (click)="onClickCloseCupsModal()" mat-button>
                    close
                </button>
            </div>
            <div class="box-dialog-block">
                <div class="dialog">
                    <div>
                        <textarea
                            class="box_textarea"
                            placeholder=" {{ 'PAGE.DASHBOARD.CUPS' | translate }} *"
                            (input)="addTextAreaCups($event.target)"
                        ></textarea>
                    </div>
                    <div class="cupsLimitExcedeed">
                        <div *ngIf="isCupsLimit" class="clarifyingMessage">
                            {{ 'PAGE.MODALS.CUPS_LIMIT_EXCEDEED' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="display_center">
                <div class="display_center">
                    <button mat-button (click)="onClickCloseCupsModal()" class="secondary margin-r">
                        {{ 'PAGE.MODALS.CANCEL' | translate }}
                    </button>
                    <button
                        mat-button
                        mat-flat-button
                        color="warn"
                        class="primary"
                        [disabled]="isAcceptCupsButtonDisabled()"
                        cdkFocusInitial
                        (click)="
                            onClickExecuteCupsOrClose()
                        "
                    >
                        {{ 'PAGE.MODALS.ACCEPT' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- MODAL BLOQUEAR -->
<div *ngIf="isBulkBlockUnblockModalVisible" class="cdk-overlay-container">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
    <div class="modal_box cdk-overlay-container">
        <div class="block_modal mat-dialog-container">
            <div class="mat-container--padding">
                <div class="space_between">
                    <h1 *ngIf="!bulkSelection" mat-dialog-title>{{ 'PAGE.DASHBOARD.BLOCK' | translate }}</h1>
                    <h1 *ngIf="bulkSelection" mat-dialog-title>{{ 'PAGE.DASHBOARD.BULK_SELECTION' | translate }}</h1>
                    <button class="hide-access close" (click)="onClickCloseBulkBlockUnblockModal()" mat-button>
                        close
                    </button>
                </div>
                <div class="box-dialog-block">
                    <div class="items_modal_box">
                        <app-filtro-month
                            name="monthFilterBlockGroup"
                            id="monthFilterBlockGroup"
                            #monthFilterBlockGroup
                            title="Mes"
                            (valueChange)="onChangeSetSelectedOption($event)"
                        >
                        </app-filtro-month>
                        <app-filtro-year
                            name="yearFilterBlockGroup"
                            id="yearFilterBlockGroup"
                            #yearFilterBlockGroup
                            title="Año"
                            (valueChange)="onChangeSetSelectedOption($event)"
                        >
                        </app-filtro-year>
                    </div>
                    <div class="dialog">
                        <div>
                            <div class="box_textarea">
                                <textarea
                                    #cupFilterBlockGroup
                                    #box
                                    #autosize="cdkTextareaAutosize"
                                    class="resetHeigth"
                                    name="cupFilterBlockGroup"
                                    id="cupFilterBlockGroup"
                                    placeholder=" {{ 'PAGE.DASHBOARD.CUPS' | translate }}"
                                    matInput
                                    cdkTextareaAutosize
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="display_center">
                    <button mat-button (click)="onClickCloseBulkBlockUnblockModal()" class="secondary margin-r">
                        {{ 'PAGE.MODALS.CANCEL' | translate }}
                    </button>
                    <button
                        [disabled]="isBulkBlockUnblockDisabled"
                        *ngIf="!bulkSelection"
                        mat-button
                        mat-flat-button
                        color="warn"
                        class="primary secondary margin-r"
                        cdkFocusInitial
                        (click)="
                            onClickUpdateBulkBlockUnblock(
                                monthFilterBlockGroup.value,
                                yearFilterBlockGroup.value,
                                true,
                                cupFilterBlockGroup.value
                            )
                        "
                    >
                        {{ 'PAGE.DASHBOARD.BLOCK' | translate }}
                    </button>
                    <button
                        [disabled]="isBulkBlockUnblockDisabled"
                        *ngIf="!bulkSelection"
                        mat-button
                        mat-flat-button
                        color="warn"
                        class="primary"
                        #but1
                        name="'but1'"
                        id="but1"
                        cdkFocusInitial
                        (click)="
                            onClickUpdateBulkBlockUnblock(
                                monthFilterBlockGroup.value,
                                yearFilterBlockGroup.value,
                                false,
                                cupFilterBlockGroup.value
                            )
                        "
                    >
                        {{ 'PAGE.DASHBOARD.UNBLOCK' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- MODAL SELECCIÓN MASIVA -->
<div *ngIf="isBulkBestMeasureCloseModalVisible" class="cdk-overlay-container">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
    <div class="modal_box cdk-overlay-container">
        <div
            class="block_modal"
            class="cdk-overlay-pane"
            class="mat-dialog-container ng-tns-c122-25 ng-trigger ng-trigger-dialogContainer ng-star-inserted"
        >
            <div class="space_between">
                <h1 mat-dialog-title>{{ 'PAGE.DASHBOARD.BULK_SELECTION' | translate }}</h1>
                <button class="hide-access close" (click)="onClickCloseSelectBestMeasureCloseModal()" mat-button>
                    close
                </button>
            </div>
            <div class="box-dialog-block">
                <div class="items_modal_box">
                    <app-filtro-month
                        (valueChange)="onChangeSetSelectedOption($event)"
                        name="monthBulkSelection"
                        id="monthBulkSelection"
                        #monthBulkSelection
                        title="Mes *"
                    >
                    </app-filtro-month>
                    <app-filtro-year
                        (valueChange)="onChangeSetSelectedOption($event)"
                        name="yearBulkSelection"
                        id="yearBulkSelection"
                        #yearBulkSelection
                        title="Año *"
                    >
                    </app-filtro-year>
                    <mat-form-field appearance="standard" class="select">
                        <mat-select
                            #originBulkMeasureSelection
                            class="form-control"
                            title="Mejor medida"
                            id="originBulkMeasureSelection"
                            name="originBulkMeasureSelection"
                            placeholder=" {{ 'PAGE.DASHBOARD.BEST_MEASURE' | translate }}"
                            [disabled]="isOriginBulkMeasureSelectionDisabled"
                            (valueChange)="
                                onChangeSetSelectedOption({ id: 'originBulkMeasureSelection', value: $event })
                            "
                        >
                            <mat-option
                                *ngIf="originBulkMeasureSelection.value"
                                (click)="disableMeasuresOrClosesSelectInModal('measure')"
                                class="deselectOption"
                            >
                                {{ 'PAGE.ACTIONS.UNCHECK' | translate }}
                            </mat-option>
                            <mat-option *ngFor="let measure of bestMeasuresList" class="form-control" [value]="measure">
                                {{ measure }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="select">
                        <mat-select
                            #originBulkCloseSelection
                            class="form-control"
                            title="Mejor cierre"
                            id="originBulkCloseSelection"
                            name="originBulkCloseSelection"
                            placeholder=" {{ 'PAGE.DASHBOARD.BEST_CLOSE' | translate }}"
                            [disabled]="isOriginBulkCloseSelectionDisabled"
                            (valueChange)="onChangeSetSelectedOption({ id: 'originBulkCloseSelection', value: $event })"
                        >
                            <mat-option
                                *ngIf="originBulkCloseSelection.value"
                                (click)="disableMeasuresOrClosesSelectInModal('close')"
                                class="deselectOption"
                            >
                                {{ 'PAGE.ACTIONS.UNCHECK' | translate }}
                            </mat-option>
                            <mat-option *ngFor="let measure of bestClosesList" class="form-control" [value]="measure">
                                {{ measure }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="dialog">
                    <div>
                        <div class="box_textarea">
                            <textarea
                                class="resetHeigth"
                                #cupFilterBulkGroup
                                #box
                                #autosize="cdkTextareaAutosize"
                                name="cupFilterBulkGroup"
                                id="cupFilterBulkGroup"
                                placeholder=" {{ 'PAGE.DASHBOARD.CUPS' | translate }} *"
                                matInput
                                cdkTextareaAutosize
                                (input)="checkCupsLength($event.target)"
                            ></textarea>
                        </div>
                    </div>
                    <div class="cupsLimitExcedeed">
                        <div *ngIf="isCupsLimit" class="clarifyingMessage">
                            {{ 'PAGE.MODALS.CUPS_LIMIT_EXCEDEED' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="position--absolute">
                <div
                    *ngIf="!isOriginBulkCloseSelectionDisabled && !isOriginBulkMeasureSelectionDisabled"
                    class="clarifyingMessageReportAnalysisResults"
                >
                    <p class="clarifyingMessage">
                        {{ 'PAGE.MODALS.MESSAGE_FILTERS_USER_MUST_SELECT_AT_LEAST_ONE' | translate }}
                    </p>
                </div>
            </div>
            <div class="display_center">
                <div class="display_center">
                    <button mat-button (click)="onClickDeleteBestMeasureModalValues()" class="delete margin-r">
                        {{ 'PAGE.ACTIONS.DELETE' | translate }}
                    </button>
                    <button mat-button (click)="onClickCloseSelectBestMeasureCloseModal()" class="secondary margin-r">
                        {{ 'PAGE.MODALS.CANCEL' | translate }}
                    </button>
                    <button
                        mat-button
                        mat-flat-button
                        color="warn"
                        class="primary"
                        [disabled]="isAcceptButtonDisabled()"
                        cdkFocusInitial
                        (click)="
                            onClickBulkUpdateBestMeasureOrClose(
                                cupFilterBulkGroup.value,
                                monthBulkSelection.value,
                                yearBulkSelection.value,
                                originBulkMeasureSelection.value,
                                originBulkCloseSelection.value
                            )
                        "
                    >
                        {{ 'PAGE.MODALS.ACCEPT' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
