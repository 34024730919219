import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, Hub } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';
/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Continue if request is local
        if (!request.url.match('http')) {
            return next.handle(request);
        }
        if (!Auth) {
            this.router.navigate(['/home']);
        } else {
            Auth.currentSession()
                .then((data) => {
                    // console.log(data)
                })
                .catch((err) => {
                    this.router.navigate(['/home']);
                });
        }
        return from(Auth.currentSession()).pipe(
            switchMap((auth: CognitoUserSession) => {
                const addToken = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${auth.getAccessToken().getJwtToken()}`,
                        //   Authorization: `${auth.getIdToken().getJwtToken()}`
                    },
                });
                return next.handle(addToken);
            })
        );
    }
}
