import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    OnDestroy,
    AfterViewInit,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { FiltroSelect } from 'src/app/shared/models/filtros.interface';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, take, debounce, debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-filtro-select',
    templateUrl: './filtro-select.component.html',
    styleUrls: ['./filtro-select.component.scss'],
})
export class FiltroSelectComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input() filtroUrl: FiltroSelect[]; // lo que te manda la pagina
    @Input() title: string;
    @Input() id: string;
    @Input() key: string;
    @Input() value: any;
    @Input() placeholder: any;
    @Input() placeholderLabel: any = '';
    @Input() theFirstTime: any;
    @Input() required: boolean;

    // Para controlar si quieres el Select con buscador o sin buscador:
    @Input() isSearchable?: boolean;
    // Para controlar si quieres que además de tener el Select con buscador, te busque a partir de 3 carácteres.
    @Input() charactersControl?: boolean;
    // Para controlar si quieres tener el toogle para poder buscar por las 2 opciones que permite:
    @Input() searchByToggle?: boolean;
    // Labels para el slide toggle
    @Input() toggleLabelLeft?: string;
    @Input() toggleLabelRight?: string;

    @Input() editableSelect?: boolean;

    @Output() searchBy: EventEmitter<[boolean, string]> = new EventEmitter<[boolean, string]>();
    resetBoolean: boolean = false;

    @Output() valueChange = new EventEmitter<{ id: string; value: string }>();
    @Output() valueChange2 = new EventEmitter<any>();
    @Output() valueChangeProcesosProgramar = new EventEmitter<any>();

    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() selectSelected: EventEmitter<any> = new EventEmitter();
    @Output() openSelect: EventEmitter<any> = new EventEmitter();
    @Output() specialSearch: EventEmitter<any> = new EventEmitter();
    @Output() deleteEvent: EventEmitter<any> = new EventEmitter();

    @Input() screen?: string = '';
    @Input() specialFeeActivated?: boolean;
    @Input() disableSelect: boolean;

    @Input() disableToggle: boolean;

    @Input() selectedNameChange: string | any;

    /** control for the selected bank */
    bankCtrl: UntypedFormControl = new UntypedFormControl();

    /** control for the MatSelect filter keyword */
    bankSearchCtrl: UntypedFormControl = new UntypedFormControl();

    /** list of banks filtered by search keyword */
    filteredBanks: ReplaySubject<FiltroSelect[]> = new ReplaySubject<FiltroSelect[]>(1);

    protected _onDestroy = new Subject<void>();

    @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
    isProcessFilterOnFocus: boolean = false;

    constructor() {}

    ngOnInit(): void {
        // Desde que se ejecuta por primera vez en el onInit, se queda a la escucha de lo que cambia en la searchBar del Select
        this.bankSearchCtrl.valueChanges.pipe(takeUntil(this._onDestroy), debounceTime(500)).subscribe(() => {
            this.filterBanks();
        });
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.filtroUrl) {
            // Establece la selección inicial
            // this.bankCtrl.setValue(this.filtroUrl);

            // Carga la lista inicial de opciones
            this.filteredBanks.next(this.filtroUrl);
        }
        if (simpleChanges.value) {
            this.bankCtrl.setValue(this.value);
        }
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /**
     * Establece el valor inicial después de que filteredBanks se cargan inicialmente
     */
    protected setInitialValue() {
        this.filteredBanks.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
            this.singleSelect.compareWith = (a: FiltroSelect, b: FiltroSelect) => a && b && a.name === b.name;
        });
    }

    emitDeleteProcess(processId: number): void {
        this.deleteEvent.emit(processId);
    }

    filterBanks() {
        if (!this.filtroUrl) {
            return;
        }
        // get the search keyword
        let search = this.bankSearchCtrl.value;
        if (!search) {
            this.filteredBanks.next(this.filtroUrl.slice());
            return;
        } else {
            search = typeof search === 'string' ? search.toLowerCase() : search[0].toLowerCase();
        }

        // IF para controlar con el booleano que viene del Input (charactersControl) si va a permitir buscar normal o a partir de 3 carácteres.
        if (this.charactersControl) {
            if (search.length >= 3) {
                this.controlSelectByNumCharacters(search);
            }
        } else {
            // filter the banks
            this.filteredBanks.next(this.filtroUrl.filter((bank) => bank.name.toLowerCase().indexOf(search) > -1));
        }
    }

    filterBy() {
        this.resetBoolean = !this.resetBoolean;
        this.searchBy.emit([this.resetBoolean, this.key]);
    }

    /**
     * Método para controlar las opciones del select según tenemos una Tarifa u otra en app-select-maestro.
     * @param name Valor de la opción del select
     * @returns
     */
    checkDisabled(name: string): boolean {
        if (
            this.id === '12' &&
            this.key === 'zoneGroup' &&
            this.title === 'Zona' &&
            !this.theFirstTime &&
            this.screen !== 'coeficientePerdidas'
        ) {
            if (this.specialFeeActivated) {
                if (name === 'Ceuta-Melilla' || name === 'Peninsula-Islas') {
                    return false;
                } else {
                    return true;
                }
            } else {
                if (name !== 'Ceuta-Melilla' && name !== 'Peninsula-Islas') {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    /**
     * Método para controlar
     * @param search
     */
    controlSelectByNumCharacters(search: any) {
        this.filteredBanks.next(this.filtroUrl.filter((bank) => bank.name.toLowerCase().indexOf(search) > -1));
        this.specialSearch.emit({ id: this.id, key: this.key, search });
    }

    /**
     * Este método le dice a Angular como tiene que comparar los valores del select y cual dejar por defecto.
     * @param obj1 El valor de una de las opciones.
     * @param obj2 El valor de la selección
     * @returns
     */
    compareFn(obj1: any, obj2: any) {
        return obj1 && obj2 ? obj1.name === obj2 : obj1 === obj2;
    }

    emitValue(event: any) {
        let valor = '';
        if (this.key === 'creationUser' || this.key === 'user') {
            valor = `${this.title}=${event.value.username ? event.value.username : event.value.value}`;
        } else if (event.value.name) {
            valor = `${this.title}=${
                this.key === 'accountId' && event.value.code ? event.value.code : event.value.name
            }`;
        } else {
            valor = `${this.title}=${event.value}`;
        }

        this.select.emit(valor);
        this.valueChange.emit({ id: this.id, value: valor });
        this.valueChangeProcesosProgramar?.emit(event.value);
    }

    emitValueToggle(event: any) {
        this.valueChange2.emit(event);
    }

    emitValueNew(event: any) {
        this.selectSelected.emit({
            newValue: event.value.value ?? event.value.name,
            name: this.id,
            eventValue: event.value,
        });
    }

    emitOpenSelect($event: any) {
        this.isProcessFilterOnFocus = !this.isProcessFilterOnFocus;
        this.openSelect.emit({ newValue: $event, id: this.id, key: this.key });
    }

    /**
     * Método para resetear el valor del select
     */
    resetSelect() {
        this.value = '';
    }

    emitValueChange($event: any) {
        this.valueChange.emit({ id: this.id, value: $event.name });
    }
}
