import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { environment } from '../../environments/environment';
import { DownloadExcelComponent } from '../shared/components/download-excel/download-excel.component';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable } from 'rxjs';
import { KeysMaestro } from '../shared/enums/maestros';
import { IColumnsTable } from '../shared/models/columns-table.model';
import { HeaderTitleService } from './header-title.service';

const CONTRACTS_LIMITED_EXPORTS_VIEWS = [
    'contratos',
    'conceptosContratos',
    'preciosContratos',
    'coberturasContrato',
    'cierres',
];
const EXCEL_EXT = '.xlsx';

// List of properties excluded of being typed nor formatted
const EXCLUDED_PROPERTIES_LIST = [
    'codeRate',
    'description',
    'codeRateAtr',
    'codeRateTelef',
    'festiveDescription',
    'festiveType',
    'regionCode',
    'code',
    'rate',
    'hour',
    'liquidation',
    'year',
    'area',
    'telephoneNum',
    'origin',
    'precedence',
    'programUnitCode',
    'shortDescription',
    'agentCode',
    'programUnitType',
    'offerType',
    'siosOfferType',
    'indRestriction',
    'indBreakdown',
    'indNomination',
    'electricSystem',
    'regulationZone',
    'electricSubsystemSeieCode',
    'eicCode',
    'optionalSettlement',
    'participantCode',
    'sendSystem',
    'boundaryPointCode',
    'claims',
    'objections',
    'zone',
    'name',
    'geoZone',
    'fileName',
    'contractCode',
    'cups',
    'cnae',
    'clientType',
    'atr',
    'variable',
    'period',
    'instName',
    'distrCode',
    'street',
    'town',
    'municipality',
    'zipCode',
    'province',
    'country',
    'telephone',
    'fax',
    'email',
    'agreedLosses',
    'councilName',
    'councilCif',
    'idNumber',
    'clientName',
    'crmCode',
    'sapCode',
    'cnaeCode',
    'contact',
    'negotiatingAccountCode',
    'negotiatingAccountDescription',
    'status',
    'hedgeCode',
    'fee',
    'taxesIndicator',
    'adjMec',
    'contractName',
    'atrProcess',
    'concept',
    'exenType',
    'cie',
    'liqType',
    'rentMeter',
    'contract.contractCode',
    'closedCode',
    'closedState',
    'closedTerm',
    'closedPercentage',
    'closedMwh',
    'clousureCost',
    'realMeasure',
    'adjustMec',
    'cupsMP',
    'type',
    'readMode',
    'functionMP',
    'address',
    'linkAddress',
    'ipAddress',
    'accessKey',
    'telemetryPhone',
    'phoneStatus',
    'rentEquip',
    'typeEquipMe',
    'typePropDevice',
    'readsExtract',
    'deviceType',
    'deviceBrand',
    'cecoerActiveHub',
    'singularPoints',
    'commercialRequest',
    'connectionIssue',
    'port',
    'file',
    'invoiceFiscalCode',
    'launchName',
    'processName',
    'launchCreationUser',
];

@Injectable({
    providedIn: 'root',
})
export class ExportService {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    totalRegisters: number = 0;
    // Microservice request limit of records
    static GENERAL_RECORDS_LIMIT = 10000;
    static CONTRACTS_RECORDS_LIMIT = 25000;
    constructor(
        public dialog: MatDialog,
        public _translateService: TranslateService,
        private _http: HttpClient,
        private _snackBar: MatSnackBar,
        private readonly _headerTitleService: HeaderTitleService
    ) {}

    // DESCARGA EXCEL
    async exportToExcel(json: any[], excelFileName: string): Promise<void> {
        // Gets headers row
        const response$ = await firstValueFrom(this.getExcelHeaders(excelFileName));
        if (response$.data && json && json.length > 0) {
            // Looks for extra properties which should not be included in excel file and deletes them
            let headers: any[] = response$.data[0].headers;
            let noExists: boolean;
            let order = Object.keys(json[0]);
            if (json.length === 0 && json[0] === undefined) {
                noExists = true;
            } else {
                if (excelFileName === 'export_periodosTarifarios') {
                    // Add accent to word Peninsula if it's necessary
                    json = json.map((e: any) => {
                        if (e.zone === 'Peninsula') {
                            return { ...e, zone: 'Península' };
                        }
                        return e;
                    });
                } else if (excelFileName !== 'my_exportcierres') {
                    order.forEach((propertyName: string) => {
                        noExists = true;
                        headers.forEach((header: any) => {
                            if (
                                header.id === KeysMaestro.CONTRACT_CODIGO_CONTRATO &&
                                propertyName === KeysMaestro.CONTRACT
                            ) {
                                propertyName = KeysMaestro.CONTRACT_CODIGO_CONTRATO;
                            }
                            if (propertyName === header.id) {
                                noExists = false;
                                return;
                            }
                        });
                        if (noExists) {
                            json.forEach((register: any) => {
                                delete register[propertyName];
                            });
                        }
                    });
                } else {
                    if (headers.some((header: IColumnsTable) => header.id === 'contract.contractCode')) {
                        headers.forEach((header: IColumnsTable) => {
                            if (header.id === 'contract.contractCode') {
                                header.id = 'contractCode';
                            }
                        });
                    }
                    if (Object.keys(json[0]).some((propertyName: any) => propertyName === 'contract')) {
                        json.forEach((register: any) => {
                            register.contractCode = register.contract.contractCode;
                            delete register['contract'];
                        });
                    }
                }
            }
            // Creates a new workbook and then adds the header row
            const wb = XLSX.utils.book_new();
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_aoa(ws, [headers.map((x: any) => x.label)]);

            // Sets columns widths dynamically depending on the text length and adding 10 points more
            ws!['!cols'] = [headers.map((x: any) => x.label)][0].map((a: any) => ({
                wch: Math.max(a.length) + 20,
            }));
            if (json && json.length > 0 && headers && headers.length > 0) {
                json = this._sortDataToExport(
                    json,
                    headers.map((objeto) => objeto.id)
                );
            }

            // Starting in the second row to avoid overriding and skipping headers
            let sortedData: any = json;
            XLSX.utils.sheet_add_json(ws, sortedData, { origin: 'A2', skipHeader: true });

            // Set cell types and formats
            sortedData.forEach((row: any, rowIndex: any) => {
                headers.forEach((header, colIndex) => {
                    const columnLetter = String.fromCharCode(65 + colIndex); // Transform index into letter (A, B, C, ...) (65 = 'A')
                    const cellAddress = columnLetter + (rowIndex + 2); // +2 to take into account header's row and 1-based of Excel
                    const cellValue = row[header.id];
                    // Checks if the property should be typed and formatted
                    if (!EXCLUDED_PROPERTIES_LIST.includes(header.id)) {
                        const { type, value, format } = this._getType(cellValue);
                        // Sets value and type of cells
                        ws[cellAddress] = { v: value, t: type };
                        // Sets format if it's necessary
                        if (format) {
                            ws[cellAddress].z = format;
                        }
                    } else {
                        ws[cellAddress] = { v: cellValue, t: cellValue ? 's' : 'z', z: '@' };
                    }
                });
            });

            // Writes Excel file
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(
                wb,
                excelFileName +
                    '_' +
                    new Date().getDate() +
                    '-' +
                    (new Date().getMonth() + 1) +
                    '-' +
                    new Date().getFullYear() +
                    EXCEL_EXT
            );

            // Closes Excel modal
            this._translateService.get('PAGE.MODALS').subscribe((message) => {
                this._snackBar.open(message['FINISH_DOWNLOAD_EXCEL'], message['ACTION_CLOSE'], {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                setTimeout(() => {
                    this._snackBar.dismiss();
                }, 4000);
            });
        } else {
            this.openSnackBar('NO_DATA_EXCEL');
        }
    }

    // Returns property's type (Date, number, string or stub), formatted value and format (optional)
    private _getType(value: any): { type: string; value: Date | number | string; format: string } {
        if (value || value === 0) {
            // If it's a date in format DD/MM/YYYY HH:MM:SS or DD/MM/YYYY
            if (
                /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-\d{4}( ([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9])?$/.test(
                    value
                )
            ) {
                const [datePart, timePart] = value.split(' ');
                const [day, month, year] = datePart.split('-');
                const [hours = 0, minutes = 0, seconds = 0] = timePart ? timePart.split(':') : [];
                const adjustedDate = new Date(year, month - 1, day, hours, minutes, seconds);
                adjustedDate.setMinutes(adjustedDate.getMinutes() - adjustedDate.getTimezoneOffset());
                return { type: 'd', value: adjustedDate, format: `dd-mm-yyyy${timePart ? ' hh:mm:ss' : ''}` };
            }
            // If it's a date in format YYYY/MM/DD HH:MM:SS or YYYY/MM/DD
            else if (
                /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])( ([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9])?$/.test(
                    value
                )
            ) {
                const [datePart, timePart] = value.split(' ');
                const [year, month, day] = datePart.split('-');
                const [hours = 0, minutes = 0, seconds = 0] = timePart ? timePart.split(':') : [];
                const adjustedDate = new Date(year, month - 1, day, hours, minutes, seconds);
                adjustedDate.setMinutes(adjustedDate.getMinutes() - adjustedDate.getTimezoneOffset());
                return { type: 'd', value: adjustedDate, format: `yyyy-mm-dd${timePart ? ' hh:mm:ss' : ''}` };
            } else {
                // If it's a number
                value = value.toString();
                if (!isNaN(value.replaceAll(',', '').replaceAll('.', ''))) {
                    value =
                        this._headerTitleService.currentLang === 'en'
                            ? Number(value.replaceAll(',', ''))
                            : Number(value.replaceAll('.', '').replace(',', '.'));

                    return {
                        type: 'n',
                        value: !isNaN(value) ? value : '-',
                        format: Number.isInteger(value) ? '#,##0;-#,##0' : '#,##0.########;-#,##0.########',
                    };
                }
            }
            // If it's a string
            return { type: 's', value, format: '@' };
        }
        return { type: 'z', value, format: '' };
    }

    private _sortDataToExport(data: any[], sort: string[]): any[] {
        let sortedData: any[] = [];
        let registerSorted: any = {};
        data.forEach((register: any) => {
            registerSorted = {};
            sort.forEach((header: string) => {
                registerSorted[header] = register[header] ? register[header] : undefined;
            });
            sortedData.push(registerSorted);
        });
        return sortedData;
    }

    // DIALOGO Y SNACKBAR
    openDialog(filtersTable: any[], idFilters: any, totalRegisters: number): void {
        const dialogRef = this.dialog.open(DownloadExcelComponent, {
            data: { filtersTable, idFilters, totalRegisters },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.openSnackBar('LOADING_MESSAGE_EXCEL');
            }
        });
        setTimeout(() => {
            this._snackBar.dismiss();
        }, 6000);
    }
    
    private openSnackBar(translationCode: string): void {
        this._translateService.get('PAGE.MODALS').subscribe((message: any) => {
            this._snackBar.open(message[translationCode], 'CERRAR', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        });
    }

    private getExcelHeaders(name: string): Observable<any> {
        return this._http.get<any>(`${environment.apiUrl}/header/v0?page=0&search=name=${name}`);
    }
}
