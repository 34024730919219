<div class="display_flex_main">
    <div class="display_flex">
        <div><h1 class="number_size">1</h1></div>
        <div class="steps_text_align">
            <h2 class="steps_text">{{ 'PAGE.EMPTY_TABLE.SELECT' | translate }}</h2>
            <h2 class="steps_text">{{ 'PAGE.EMPTY_TABLE.A_CONCEPT' | translate }}</h2>
        </div>
    </div>

    <div class="align_center">
        <img class="icon_arrow" src="../../assets/img/flecha.png" />
    </div>

    <div class="display_flex">
        <div><h1 class="number_size">2</h1></div>
        <div class="steps_text_align">
            <h2 class="steps_text">{{ 'PAGE.EMPTY_TABLE.SELECT_FILTERS' | translate }}</h2>
            <h2 class="steps_text">{{ 'PAGE.EMPTY_TABLE.OPTIONAL' | translate }}</h2>
        </div>
    </div>

    <div class="align_center">
        <img class="icon_arrow" src="../../assets/img/flecha.png" />
    </div>

    <div class="display_flex">
        <div><h1 class="number_size">3</h1></div>
        <div class="steps_text_align">
            <h2 class="steps_text">{{ 'PAGE.EMPTY_TABLE.CLICK' | translate }}</h2>
            <h2 class="steps_text">{{ 'PAGE.EMPTY_TABLE.FILTER' | translate }}</h2>
        </div>
    </div>
</div>
