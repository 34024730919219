import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { CountryService } from '../../../services/country.service';
import { HeaderTitleService } from '../../../services/header-title.service';
import { LanguageService } from '../../../services/language.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
    @Input() headerValue: string;

    showBack: boolean = false;
    currentLang: string = 'es';
    currentCountry: string = 'ES';
    userLogRoles: any;
    userName: any;
    isAdmin: boolean = false;
    userRoles: string[] = [];
    title = '';
    environment: string = this.getEnvironment();
    showEnvironment: boolean = this.environment.includes("PRE");

    headerValue$: Observable<string>;
    headerSubscription: Subscription;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        public _languageService: LanguageService,
        public _countryService: CountryService,
        public _translateService: TranslateService,
        private _loginService: LoginService,
        private _headerTitleService: HeaderTitleService,
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _adapter: DateAdapter<any>
    ) {}

    ngOnInit(): void {
        this.getLocalStorage();
        this.currentLang = this._languageService.preferredLanguage
            ? this._languageService.preferredLanguage
            : this._languageService.defaultLanguage;
        this.currentCountry = this._countryService.preferredCountry
            ? this._countryService.preferredCountry
            : this._countryService.defaultCountry;
        this._headerTitleService.currentLang = this.currentLang;

        this.headerSubscription = this._headerTitleService.headerValue.subscribe((value: any) => {
            if (value === 'PAGE.HEADER.TITLE' || value === 'TITLE') {
                this._translateService.get('PAGE.HEADER.TITLE').subscribe((message: string) => {
                    this.headerValue = message;
                    this._headerTitleService.updateHeader(message);
                    this.headerValue$ = this._headerTitleService.headerValue;
                });
            } else if (this.headerValue !== value) {
                this.headerValue = value;
                this.headerValue$ = this._headerTitleService.headerValue;
            }
        });

        this.userRoles = this._loginService.getUserActiveRoles()?.split(',') || ['CONSULTA'];
        this.userRoles = this.userRoles.map((element: any) => element.trim());
        if (this.userRoles.findIndex((role) => role === 'ADMIN') !== -1) {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }
        this._adapter.setLocale(`${this.currentLang === 'en' ? 'en-US' : 'es-ES'}`);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.showBack = this._router.url !== '/';
    }

    ngOnDestroy(): void {
        this.headerSubscription.unsubscribe();
    }

    goBack() {
        this._headerTitleService.showBack = false;
        this.showBack = false;
        this._router.navigate(['']);
    }

    changeLanguage(selection: string) {
        this._headerTitleService.currentLang = selection;
        this._languageService.changeLanguage(selection);
        this.currentLang = selection;
        this._translateService
            .get(this._headerTitleService.currentHeaderTranslationCode)
            .subscribe((message: string) => {
                this.headerValue = message;
                this._headerTitleService.updateHeader(message);
                this.headerValue$ = this._headerTitleService.headerValue;
            });
        this._adapter.setLocale(`${selection === 'en' ? 'en-US' : 'es-ES'}`);
    }

    changeCountry(selection: string) {
        this._countryService.changeCountry(selection);
        this.currentCountry = this._countryService.defaultCountry;
        this._openSnackBar(selection);
    }

    getLanguage() {
        return this.currentLang === this._translateService.currentLang;
    }

    getLocalStorage() {
        //Obtenemos el usuario logeado y sus roles almacenados en Storage
        this.userName = this._loginService.getUserNameSession();
    }

    logout() {
        this._loginService.removeUserSession();
        Auth.signOut();
    }

    protected get showBackButton() {
        return this._headerTitleService.showBack;
    }

    private _openSnackBar(selection: string): void {
        const message = this._translateService.instant('PAGE.HEADER.COUNTRIES.TEMPORAL.SNACK_MESSAGE', {
            country: selection,
        });
        this._snackBar.open(message, 'close', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['error-snackbar'],
        });
        setTimeout(() => {
            this._snackBar.dismiss();
        }, 4000);
    }

    private getEnvironment() {
        const url = environment.apiUrl;
        var res:string = "PROD.";
        if (url.includes("pre")) {
            res = "PRE-PROD.";
        }
        return res;
    }
}
