import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './services/global.service';
import { LanguageService } from './services/language.service';
import { CountryService } from './services/country.service';
import { Roles } from 'src/app/shared/interfaces/roles.interface';
import { Router } from '@angular/router';
import { LoginService } from './core/services/login.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HeaderTitleService } from './services/header-title.service';
import { Permisos } from './shared/models/permisos.interface';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked {
    readonly DEFAULT_LANGUAGE = 'es';
    readonly DEFAULT_COUNTRY = 'ES';

    pageTitle = '';
    showcomponent = true;
    loginCognitoActivo: boolean;
    username: string;
    permisionUsersActive: Roles[] = [];
    userLogRoles: any;
    permisos: Permisos[] = [];
    subcriptions: Subscription[] = [];
    header: HttpClient;
    currentHeaderValue: string;
    isLogged = true;

    constructor(
        private globalService: GlobalService,
        public languageService: LanguageService,
        public countryService: CountryService,
        private _loginService: LoginService,
        public _translateService: TranslateService,
        private router: Router,
        private _headerTitleService: HeaderTitleService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngAfterViewChecked(): void {
        this._headerTitleService.headerValue.subscribe((value) => {
            this.currentHeaderValue = value;
        });

        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        this._configureApplicationLanguage();
        this._configureApplicationCountry();

        setTimeout(() => {
            this.getUserData();
            this.getPermisos();
        }, 0);

        this.getHeaderFiltros();
        this.getFiltros();
    }

    private _configureApplicationLanguage(): void {
        const browserLang: string | undefined = this._translateService.getBrowserLang();
        if (this.languageService.preferredLanguage) {
            this._translateService.use(this.languageService.preferredLanguage);
            this.languageService.browserLanguage = this.languageService.preferredLanguage;
        } else if (browserLang && this.languageService.languages.includes(browserLang)) {
            this._translateService.use(browserLang);
            this.languageService.browserLanguage = browserLang;
            this.languageService.setPreferredLanguage(browserLang);
        } else {
            this._translateService.setDefaultLang(this.DEFAULT_LANGUAGE);
            this.languageService.browserLanguage = this.DEFAULT_LANGUAGE;
            this.languageService.setPreferredLanguage(this.DEFAULT_LANGUAGE);
        }
    }

    private _configureApplicationCountry(): void {
        if (!this.countryService.preferredCountry) {
            this.countryService.setPreferredCountry(this.DEFAULT_COUNTRY);
        }
    }

    getUserLog() {
        return this._loginService.isAuthenticated();
    }

    getUserData() {
        if (!this._loginService.getUserActive()) {
            this.isLogged = false;
            this.globalService.getUserByUsername(this._loginService.getUserNameSession()).subscribe({
                next: (userByUsername) => {
                    this.isLogged = true;
                    this._loginService.setUserActive(JSON.stringify(userByUsername));
                    // Este for es para arreglar el fallo de que como máximo almacenaba un rol
                    let roles = '';
                    for (let i = 0; i < userByUsername.roles.length; i++) {
                        roles += userByUsername.roles[i];
                        if (i !== userByUsername.roles.length - 1) {
                            roles += ',';
                        }
                    }
                    if (roles === '') {
                        roles = 'CONSULTA';
                    }
                    this._loginService.setUserActiveRoles(roles);
                    this.getRolesByUserRol(); //Pedimos los roles del usuario logueado
                    this.getPermisos();
                },
                error: (error) => {
                    this.isLogged = false;
                    this.router.navigate(['error']); //Si falla vamos a Page Error
                },
            });
        } else if (this._loginService.isAuthenticated() && this._loginService.getUserActive()) {
            this.isLogged = true;
            return;
        }
    }

    getRolesByUserRol() {
        this.globalService.getRoles().subscribe((rolesUser) => {
            this.permisionUsersActive = [];
            let roles = rolesUser.data.map((col: any) => col.code);
            this._loginService.setRolesTotal(JSON.stringify(roles));
        });
    }

    getPermisos() {
        this.globalService.getUserPermissions();
    }

    getFiltros() {
        if (this._loginService.getFiltersTotal()) {
            this._loginService.deleteSession('filters');
        }
    }

    getHeaderFiltros() {
        if (this._loginService.getHeaderSession()) {
            this._loginService.deleteSession('headers');
        }
        this.globalService.getHeaders().subscribe((res) => {
            this._loginService.setHeaderSession(JSON.stringify(res));
        });
    }
}
