import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
    transform(value: any, lang: string = 'en'): any {
        if (value) {
            return Number(value).toLocaleString(`${lang === 'en' ? 'en-US' : 'de-DE'}`);
        } else {
            return '0';
        }
    }
}
