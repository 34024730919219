<div class="modal-title-close">
    <h1 mat-dialog-title>{{ 'PAGE.MODALS.TITLE4' | translate }}</h1>
    <button mat-button mat-dialog-close class="hide-access close" (click)="close(false)">close</button>
</div>
<div mat-dialog-content>
    <ng-container>
        <ng-container *ngFor="let filtro of copyData.header; let i = index">
            <div
                *ngIf="
                    !inputIdNotShowedGeneral.includes(filtro.id) &&
                    !inputIdNotShowedaggregatedUnitToSend.includes(filtro.id)
                "
            >
                <app-filtro-date
                    *ngIf="filtro.filter === 'date'"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [preloadedUniqueDate]="copyData.value[filtro.id]"
                    [isDateRange]="false"
                    (select)="valueChange($event)"
                ></app-filtro-date>
                <app-filtro-select
                    *ngIf="filtro.filter === 'select'"
                    (selectSelected)="valueChange($event)"
                    [filtroUrl]="filtro.options"
                    [title]="filtro.label"
                    [id]="filtro.id"
                    [value]="copyData.value[filtro.id]"
                >
                </app-filtro-select>
                <app-filtro-input
                    *ngIf="filtro.filter == 'number'"
                    type="number"
                    [name]="filtro.label"
                    [id]="filtro.id"
                    [value]="copyData.value[filtro.id]"
                    (select)="valueChange($event)"
                ></app-filtro-input>
            </div>
        </ng-container>
    </ng-container>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close class="secondary" (click)="close(false)">
        {{ 'PAGE.MODALS.CANCEL' | translate }}
    </button>
    <button
        mat-button
        mat-dialog-close
        mat-flat-button
        color="warn"
        class="primary"
        (click)="close(true)"
        cdkFocusInitial
    >
        {{ 'PAGE.MODALS.ACCEPT' | translate }}
    </button>
</div>
