import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndpointsProcesos } from 'src/app/shared/enums/procesos';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../../core/services/login.service';

@Injectable({
    providedIn: 'root',
})
export class ProcesosService {
    constructor(private _http: HttpClient, private _loginService: LoginService) {}

    searchBy: boolean = false;

    // Pagination
    pageIndex: number;
    pageSize: number;
    sortDir: string;
    sortActive: string;

    // Parametro de busqueda para los endpoint
    searchParam: string;

    // Código de contrato para más tarde buscar los detalles de contrato
    contractCode: string;

    // Variable para controlar si mostrar la tabla de conceptos de contratos con unas columnas u otras.
    showColumnsBy: string;
    dates: any = '';
    originalData: any = '';
    selectedProcess: any;
    selectSelectedPrograming: any = '';
    launchId: any = '';
    getConceptsProcesosResultados(): Observable<any> {
        const url = './assets/json/conceptos-procesos.json';
        return this._http.get<any>(url).pipe(map((e) => e.conceptosProcesosResultados)); //TO DO: Añadir los otros conceptos de CRM tras demo
    }

    getTablaProcesosResultados(page: number, pageSize: number, sortBy: string, sortDir: string) {
        const url = `${environment.apiUrl}/process/v0?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`;
        return this._http.get<any[]>(url);
    }

    getConceptsProcesosLanzar(): Observable<any> {
        const url = './assets/json/conceptos-procesos.json';
        return this._http.get<any>(url).pipe(map((e) => e.conceptosProcesosLanzar)); //TO DO: Añadir los otros conceptos de CRM tras demo
    }

    getProcessConcepts() {
        const url = `${environment.apiUrl}/process/v0?page=0&sortBy=processCode&sortDir=asc`;
        return this._http.get<any>(url);
    }

    getHeaders() {
        const url = `${environment.apiUrl}/header/v0?page=0&search=0`;
        return this._http.get<any[]>(url);
    }

    getProcessResult() {
        const url = `${environment.apiUrl}/process/v0/execution?page=-1&sortBy=initDate&sortDir=asc`;
        return this._http.get<any>(url);
    }

    getProcessResultFilter(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ) {
        sortBy = EndpointsProcesos.SortByInitDate;
        sortDir = EndpointsProcesos.ASC;
        let url = `${environment.apiUrl}/process/v0/execution?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&search=${search}`;
        if (isExport) {
            url += '&isExcel=true';
        }
        return this._http.get<any>(url);
    }

    getTablaPreciosSort(sort: any) {
        if (sort.direction == 'asc') {
            const url = './assets/json/tabla-precios-regulados-sort-asc.json';
            return this._http.get<any>(url);
        } else {
            if (sort.direction == 'desc') {
                const url = './assets/json/tabla-precios-regulados-sort-desc.json';
                return this._http.get<any>(url);
            } else {
                const url = './assets/json/tabla-precios-regulados.json';
                return this._http.get<any>(url);
            }
        }
    }
    getTablaPreciosPaginationOne() {
        const url = './assets/json/tabla-precios-regulado-pagination-one.json';
        return this._http.get<any>(url);
    }
    getTablaPreciosPaginationTwo() {
        const url = './assets/json/tabla-precios-regulado-pagination-two.json';
        return this._http.get<any>(url);
    }
    getTablaPrecios() {
        const url = './assets/json/tabla-precios-regulados.json';
        return this._http.get<any>(url);
        // return this._http.post<any>(url,filters);
    }

    getCargasMedidas(): Observable<any> {
        //Obtener Medidas
        const url = './assets/json/tabla_cargas_manual.json';
        return this._http.get<any>(url).pipe(map((e) => e.medidas));
    }
    getCargasPrevisiones(): Observable<any> {
        //Obtener previsiones
        const url = './assets/json/tabla_cargas_manual.json';
        return this._http.get<any>(url).pipe(map((e) => e.previsiones));
    }

    getSelectSistemas(): Observable<any> {
        //Obtener filtro "Sistemas" (Medidas/previsiones)
        const url = './assets/json/filtros.json';
        return this._http.get<any>(url).pipe(map((e) => e.filters));
    }

    getProcesosResultadosMock() {
        const url = './assets/json/procesos_resultados.json';
        return this._http.get<any>(url).pipe(map((e) => e));
    }

    getUnidadPrograma(concept?: string) {
        let url = `${environment.apiUrl}/unitProgram/v0?page=0&sortDir=asc`;
        if (concept === 'liquidacionPotestativa') {
            url += '&search=optionalSettlement=true';
        }
        return this._http.get<any>(url);
    }

    postProcesosProgramar(body: any) {
        const url = `${environment.apiUrl}/process/v0/launch`;
        return this._http.post<any>(url, body);
    }

    putProcesosProgramar(launchId: number, body: any) {
        let launchIdParam = launchId;
        const url = `${environment.apiUrl}/process/v0/launch/${launchIdParam}`;
        return this._http.put<any>(url, body);
    }

    launchPostPrevisionesIniciales(body: any) {
        const url = `${environment.apiUrl}/process/v0/launch`;
        return this._http.post<any>(url, body);
    }

    getAllLaunchedProcess(code: string) {
        const url = `${environment.apiUrl}/process/v0/launch?page=-1&sortBy=launchId&search=processCode.processCode=${code},cron!=null&sortDir=asc`;
        return this._http.get<any>(url);
    }

    getNegotiatingAccounts(specialSearch?: { search: string | any }): Observable<any> {
        let url: string;
        if (specialSearch) {
            url = `${
                environment.apiUrl
            }/negotiating-account/v0?page=0&pageSize=10&sortBy=negotiatingAccountCode&sortDir=asc&search=${'accountId'}like(${
                specialSearch.search
            })`;
        } else {
            url = `${environment.apiUrl}/negotiating-account/v0?page=0&pageSize=10&sortBy=negotiatingAccountCode&sortDir=asc`;
        }
        return this._http.get<any[]>(url);
    }

    convertCupsListStringToArray(cups: any): string[] {
        let cupsArray: string[] = [];
        if (cups && cups !== '' && typeof cups === 'string') {
            let cupsList = cups.split(`;`);
            cupsList.forEach((cups: string) => cupsArray.push(cups));
        }
        return cupsArray;
    }

    deleteProcess(id: any) {
        return this._http.delete<any>(`${environment.apiUrl}/process/v0/launch/${id}`);
    }
}
