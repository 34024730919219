<div class="content_multiselect">
    <mat-label class="name__input">{{ name }}</mat-label>
    <div *ngIf="lengthCups > 2; else more">
        <div *ngFor="let cup of listCups; let i = index" class="cups">
            <ng-container *ngIf="i < 2">
                <span class="box_cups_input"
                    >{{ cup }}
                    <mat-icon class="close" (click)="remove(i)">close</mat-icon>
                </span>
            </ng-container>
        </div>
        <div class="content_multiselect__container-icon">
            <button mat-mini-fab color="warn" (click)="reviewList(); emitValue($event); emitValueNew($event)">
                <app-base-icon iconName="visibility"></app-base-icon>
            </button>
        </div>
    </div>
    <ng-template #more>
        <ng-container *ngIf="listCups && listCups.length > 0 && lengthCups > 0">
            <div *ngFor="let cup of listCups; let i = index" class="cups">
                <span class="box_cups_input"
                    >{{ cup }}
                    <mat-icon class="close" (click)="remove(i)">close</mat-icon>
                </span>
            </div>
        </ng-container>
    </ng-template>
    <button mat-mini-fab color="warn" [disabled]="disabledCUPS" (click)="addList()">
        <mat-icon>add</mat-icon>
    </button>
</div>
