import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { I18n, Auth, Hub } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { Translations } from './translations';
import { NgxSpinnerService } from 'ngx-spinner';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { GlobalService } from 'src/app/services/global.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    user: CognitoUserInterface | undefined;
    authUser = false;

    constructor(private router: Router, private zone: NgZone, private spinner: NgxSpinnerService, private _globalService: GlobalService) {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            if (event === 'cognitoHostedUI' || event === 'signedIn') {
                this.zone.run(() => this.router.navigate(['/home']));
            } else {
                this.spinner.hide();
            }
        });
    }

    ngOnInit(): void {
        Auth.currentAuthenticatedUser()
            .then((session) => {
                this._globalService.getUserByUsername('').subscribe((user: any) => {
                    if (user) {
                        this.router.navigate(['/home'], { replaceUrl: true });
                    } else {
                        this.router.navigate(['/login'], { replaceUrl: true });
                    }
                });
            })
            .catch((err) => {
                this.spinner.hide();
                let url =
                    `${environment.urlCognito}/oauth2/authorize?client_id=` +
                    environment.awsClientId +
                    '&response_type=token&scope=email+openid+phone+profile+seor/get&redirect_uri=' +
                    environment.awsRedirectURI;
                window.location.href = url;
            });
    }

    componentDidMount = () => {
        I18n.putVocabulariesForLanguage('en-US', {
            [Translations.SIGN_IN_TEXT]: 'LOGIN',
        });
    };
}
