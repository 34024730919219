import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TablaPrevisionesComponent } from '../tabla-previsiones.component';
import { KeysMaestro } from '../../../../../shared/enums/maestros';
import { AggregatedUnitToSend } from '../../../../../shared/interfaces/maestros-interface';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'edit-modal',
    templateUrl: './edit-modal.component.html',
    styleUrls: ['./edit-modal.component.scss'],
})
export class EditModalComponentPrevisions {
    copyData: any;
    copyDataMapped: any;

    isParametros: boolean = false;

    aggregatedUnitToSend: AggregatedUnitToSend;

    inputIdNotShowedaggregatedUnitToSend: string[] = ['date', 'programUnitCode'];
    inputIdNotShowedGeneral: string[] = [
        'creationDate',
        'updateUser',
        'creationUser',
        'updateDate',
        'lastModify',
        'modificationUser',
        'edit',
    ];
    aggregatedUnitPreChanges: any;
    nuevoEvento: any[] = [];
    energyPerHour: any[] = [];
    tmp: any = [];

    constructor(
        public DialogEdit: MatDialogRef<TablaPrevisionesComponent>,
        protected readonly _headerTitleService: HeaderTitleService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.copyData = JSON.parse(JSON.stringify(data));
        this.copyDataMapped = this.copyData.header.map((data: any) => {
            let value;
            const filterType = data.id.toLowerCase();
            if (filterType === 'date') {
                value = new Date(this.copyData.value[data.id]);
            } else if (filterType === 'select') {
                if (data.id === 'regionCode') {
                    value = data?.data?.find(
                        (d: any) => (d.value?.toString() as string)?.padStart(2, '0') === this.copyData?.value[data.id]
                    );
                } else {
                    value = data?.data?.find((d: any) => d.id === this.copyData.value[data.id]);
                }
            } else {
                value = this.copyData.value[data.id];
            }
            return { ...data, value };
        });
        this.aggregatedUnitToSend = {
            programUnitCode: this.copyData.value.programUnitCode,
            date: this.copyData.value.date,
            h1: this.copyData.value.hour_1,
            h2: this.copyData.value.hour_2,
            h3: this.copyData.value.hour_3,
            h4: this.copyData.value.hour_4,
            h5: this.copyData.value.hour_5,
            h6: this.copyData.value.hour_6,
            h7: this.copyData.value.hour_7,
            h8: this.copyData.value.hour_8,
            h9: this.copyData.value.hour_9,
            h10: this.copyData.value.hour_10,
            h11: this.copyData.value.hour_11,
            h12: this.copyData.value.hour_12,
            h13: this.copyData.value.hour_13,
            h14: this.copyData.value.hour_14,
            h15: this.copyData.value.hour_15,
            h16: this.copyData.value.hour_16,
            h17: this.copyData.value.hour_17,
            h18: this.copyData.value.hour_18,
            h19: this.copyData.value.hour_19,
            h20: this.copyData.value.hour_20,
            h21: this.copyData.value.hour_21,
            h22: this.copyData.value.hour_22,
            h23: this.copyData.value.hour_23,
            h24: this.copyData.value.hour_24,
            h25: this.copyData.value.hour_25,
            energyPerHour: [],
        };
        this.aggregatedUnitPreChanges = this.aggregatedUnitToSend;
    }

    get KeysMaestro() {
        return KeysMaestro;
    }

    close(resp: boolean) {
        let newData;
        for (let i = 0; i < this.nuevoEvento.length; i++) {
            const numero = this.nuevoEvento[i].name.split('_');
            this.tmp.push({
                name: Number(numero[1]),
                newValue: this.nuevoEvento[i].newValue,
            });
            this.tmp.sort((a: { name: any }, b: { name: any }) => {
                return a.name - b.name;
            });
        }

        if (this.tmp.length !== 1) {
            for (let i = 0; i < this.tmp.length; i++) {
                if (this.tmp[i + 1] !== undefined && this.tmp[i].name === this.tmp[i + 1].name) {
                    this.tmp.splice(i, 1);
                    i--;
                } else if (this.tmp[i + 1] === undefined) {
                    break;
                }
            }
        }
        newData = { resp: resp, data: this.aggregatedUnitToSend, datosCambiados: this.tmp };
        this.DialogEdit.close(newData);
    }

    valueChange(event: any) {
        this.nuevoEvento.push(event);

        let eventValue = event.newValue || event.eventValue || event.value;
        if (event.name !== undefined) {
            const newName = event.name.split('_')[1];
            const theLastName = 'h' + newName;
            const newValue = Number(eventValue);
            (this.aggregatedUnitToSend as any)[theLastName] = newValue;
        }
    }
}
