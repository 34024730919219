<div class="modal-title-close">
    <h1 mat-dialog-title>{{ 'PAGE.MODALS.TITLE3' | translate }}</h1>
    <button mat-button mat-dialog-close class="hide-access close" (click)="close(false)">close</button>
</div>
<div mat-dialog-content>
    {{ 'PAGE.MODALS.TEXT_TITLE_3' | translate }}
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close class="secondary" (click)="close(false)">
        {{ 'PAGE.MODALS.DECLINE' | translate }}
    </button>
    <button
        mat-button
        mat-dialog-close
        mat-flat-button
        color="warn"
        class="primary"
        (click)="close(true)"
        cdkFocusInitial
    >
        {{ 'PAGE.MODALS.ACCEPT' | translate }}
    </button>
</div>
