import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, of, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Distributors } from '../../../shared/models/distributors';
import { SortBy } from 'src/app/shared/enums/maestros';
import { LoginService } from '../../../core/services/login.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class MaestroService {
    searchBy: boolean = false;
    selectId: string;
    year: number | number[];
    validCups: any;
    // Pagination
    pageIndex: number;
    pageSize: number;
    sortDir: string;
    sortActive: string;
    // Parametro de busqueda para los endpoint
    searchParam: string;
    // Código de contrato para más tarde buscar los detalles de contrato
    contractCode: string;
    // Código de reclamación para más tarde buscar los detalles de la misma
    claimCode: string;
    // Código de objeción para más tarde buscar los detalles de la misma
    objectionCode: string;
    // Variable para controlar si mostrar la tabla de conceptos de contratos con unas columnas u otras.
    showColumnsBy: string;
    // Variable para tener guardados los CUPS de punto de frontera
    boundaryPoints: any = [];
    selectedClient: any;

    contractPagesEndpoints: string[] = [
        `contract`,
        `pricing-output`,
        `contract/concept`,
        `contract/hedge`,
        `contract/closing`,
    ];

    constructor(
        private _http: HttpClient,
        private _loginService: LoginService,
        private _translateService: TranslateService
    ) { }

    getConceptsAWS(): Observable<any> {
        const url = './assets/json/conceptos-maestro.json';
        return this._http.get<any>(url).pipe(map((e) => e.conceptosAWS)); // TODO: Añadir los otros conceptos de CRM tras demo
    }
    getConceptsCRM(): Observable<any> {
        const url = './assets/json/conceptos-maestro.json';
        return this._http.get<any>(url).pipe(map((e) => e.conceptosCRM)); // TODO: Añadir los otros conceptos de CRM tras demo
    }

    getTablaPrecios(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-precios-regulados-excel.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'price', 'rate', isExport);
        }
    }
    getTipoTerminoCode(): Observable<any> {
        const url = `${environment.apiUrl}/code-description/v0?page=-1&sortBy=code&sortDir=asc`;
        return this._http.get<Distributors[]>(url);
    }
    getCupsDescription(code: string): Observable<any> {
        const url = `${environment.apiUrl}/cups/v0?page=1&pageSize=10&search=instNamelike(${code})`;
        return this._http.get<any[]>(url);
    }
    getPreciosCode(): Observable<any> {
        const url = `${environment.apiUrl}/price-codes/v0?page=-1&sortBy=priceCode&sortDir=asc`;
        return this._http.get<Distributors[]>(url);
    }

    getTablaPreciosExcel() {
        const url = './assets/json/tabla-precios-regulados-excel.json';
        return this._http.get<any>(url);
    }

    getTablaPreciosPaginationOne() {
        const url = './assets/json/tabla-precios-regulado-pagination-one.json';
        return this._http.get<any>(url);
    }
    getTablaPreciosPaginationTwo() {
        const url = './assets/json/tabla-precios-regulado-pagination-two.json';
        return this._http.get<any>(url);
    }

    getTablaPreciosSort(sort: any) {
        if (sort.direction == 'asc') {
            const url = './assets/json/tabla-precios-regulados-sort-asc.json';
            return this._http.get<any>(url);
        } else {
            if (sort.direction == 'desc') {
                const url = './assets/json/tabla-precios-regulados-sort-desc.json';
                return this._http.get<any>(url);
            } else {
                const url = './assets/json/tabla-precios-regulados.json';
                return this._http.get<any>(url);
            }
        }
    }

    getTablaPreciosMockFilter() {
        const url = './assets/json/tabla-precios-regulados-filter.json';
        return this._http.get<any>(url);
    }

    getTablaPerfiles(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-perfilesREE.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'consumptionProfile', 'date', isExport);
        }
    }

    getTablaPreciosIndexados(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'hourly-price', 'date', isExport);
    }

    getTablaPreciosMensuales(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'monthly-price', 'year', isExport);
    }

    getTablaPrelacion(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-prelacion.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(
                sortBy,
                page,
                pageSize,
                search,
                sortDir,
                'precedence/common',
                'precedence',
                isExport
            );
        }
    }
    getTablaPeriodosTarifarios(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-periodos-tarifarios.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'fee-matrix', 'id', isExport);
        }
    }

    getTablaUnidadProgramacion(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-unidad-programacion.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(
                sortBy,
                page,
                pageSize,
                search,
                sortDir,
                'unitProgram',
                'programUnitCode',
                isExport
            );
        }
    }
    getTablakEstimada(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-k-estimada.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(
                sortBy,
                page,
                pageSize,
                search,
                sortDir,
                'estimatedK',
                SortBy.K_ESTIMADA,
                isExport
            );
        }
    }
    getTablaPuntoFrontera() {
        const url = './assets/json/tabla-punto-frontera.json';
        return this._http.get<any>(url);
    }
    getTablaCoeficientePerdidas(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-coeficiente-perdidas.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(
                sortBy,
                page,
                pageSize,
                search,
                sortDir,
                'loss-coefficient',
                'date,hour,code',
                isExport
            );
        }
    }
    getTablaLiquidacionPotestativa(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: any,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-coeficiente-perdidas.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'optional-settlement', 'date', isExport);
        }
    }

    // tablas CRM
    getTablagrupoFact() {
        const url = './assets/json/tabla-grupo-facturacion.json';
        return this._http.get<any>(url);
    }

    getTablaCoberturaContrato(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-cobertura-contratos.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(
                sortBy,
                page,
                pageSize,
                search,
                sortDir,
                'contract/hedge',
                'contractCode.contractCode',
                isExport
            );
        }
    }
    getTablaContratos() {
        const url = './assets/json/tabla-contratos.json';
        return this._http.get<any>(url);
    }

    /**
     * Endpoint para ver los detalles de los contratos
     * @param codContrato Código del contrato que queremos ver sus detalles
     * @returns
     */
    getContractDetails(codContrato: string) {
        if (environment.isMockActive) {
            const url = './assets/json/detalles-contratos.json';
            return this._http.get<any[]>(url);
        } else {
            const url = `${environment.apiUrl}/contract/v0?page=1&pageSize=1&sortBy=contractCode&sortDir=asc&search=contractCode=${codContrato}`;
            return this._http.get<any[]>(url);
        }
    }

    /**
     * Endpoint para ver los detalles de los reclamaciones
     * @param code Código del registro del que queremos ver los detalles de sus reclamaciones
     * @returns
     */
    getClaimDetails(code: string, page: number = 1, pageSize: number = 5): Observable<any> {
        const url = `${environment.apiUrl}/cups/claims/v0?page=${page}&pageSize=${pageSize}&sortBy=requestCode&sortDir=asc&search=cups=${code}`;
        return this._http.get<any[]>(url);
    }

    /**
     * Endpoint para ver los detalles de los objection
     * @param code Código del registro del que queremos ver los detalles de sus objection
     * @returns
     */
    getObjectionDetails(code: string, page: number = 1, pageSize: number = 5): Observable<any> {
        const url = `${environment.apiUrl}/cups/objection/v0?page=${page}&pageSize=${pageSize}&sortBy=cups&sortDir=asc&search=cups=${code}`;
        return this._http.get<any[]>(url);
    }

    /**
     * Endpoint para editar un valor de una objeción
     * @param id Id de la objeción que queremos editar
     * @param body Body que le pasamos con los nuevos cambios
     * @returns
     */
    setObjectionDetail(id: number, body: any): Observable<any> {
        const url = `${environment.apiUrl}/cups/objection/v0/${id}`;
        return this._http.put<any>(url, body);
    }

    /**
     * Get Parametros
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @returns
     * @returns
     */
    getTablaParametros(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-parametros.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'parameter', 'code', isExport);
        }
    }

    updateParametros(code: any, body: any) {
        const url = `${environment.apiUrl}/parameter/v0/${code}`;
        return this._http.put<any>(url, body);
    }

    deleteParametros(code: any) {
        const url = `${environment.apiUrl}/parameter/v0/${code}`;
        return this._http.delete<any>(url);
    }

    createParametros(body: any) {
        const url = `${environment.apiUrl}/parameter/v0`;
        return this._http.post<any>(url, body);
    }

    /**
     * Endpoint para ver los detalles de las instalaciones según el CUP
     * @param codCup Código cup del contrato para mostrar las instalaciones
     * @returns
     */
    getInstallations(codCup: string) {
        if (environment.isMockActive) {
            const url = './assets/json/instalaciones.json';
            return this._http.get<any[]>(url);
        } else {
            const url = `${environment.apiUrl}/cups/v0?page=-1&sortBy=cups&sortDir=asc&search=cups=${codCup}`;
            return this._http.get<any[]>(url);
        }
    }

    /**
     * Endpoint para ver el desglose de precios según el código del contrato elegido
     * @param codContrato codigo del contrato
     * @returns
     */
    getPrices(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        codContrato?: string,
        isExport: boolean = false
    ): Observable<any> {
        let url;
        if (environment.isMockActive) {
            url = './assets/json/tabla-desglose-precios.json';
        } else {
            url = `${environment.apiUrl}/pricing-output/v0?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&search=contract.contractCode=${codContrato}`;
            if (isExport) {
                url += '&isExcel=true';
            }
        }
        return this._http.get<any[]>(url);
    }

    /**
     * Endpoint para ver los conceptos del contrato según el código del contrato elegido
     * @param codContrato
     */
    getContractConcepts(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        codContrato?: string,
        isExport: boolean = false
    ): Observable<any> {
        let url;
        if (environment.isMockActive) {
            url = './assets/json/tabla-detalles-conceptos-contrato.json';
        } else {
            url = `${environment.apiUrl}/contract/concept/v0?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&search=contract.contractCode=${codContrato}`;
            if (isExport) {
                url += '&isExcel=true';
            }
        }
        return this._http.get<any[]>(url);
    }

    /**
     * Endpoint para ver los cierres según el código del contrato elegido
     * @param codContrato
     */
    getClosures(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        codContrato?: string,
        isExport: boolean = false
    ): Observable<any> {
        let url;
        if (environment.isMockActive) {
            url = './assets/json/tabla-cierres.json';
        } else {
            url = `${environment.apiUrl}/contract/closing/v0?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&search=contract.contractCode=${codContrato}`;
            if (isExport) {
                url += '&isExcel=true';
            }
        }
        return this._http.get<any[]>(url);
    }

    /**
     * Get CUPS
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @returns
     */
    getTablaCUPS(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-instalacion.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'cups', 'cups', isExport);
        }
    }

    /**
     * Get Consumo Anual
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @returns
     */
    getTablaConsumoAnual(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        let url;
        if (environment.isMockActive) {
            url = './assets/json/tabla-consumo-anual.json';
            return this._http.get<any[]>(url);
        } else {
            url = `${environment.apiUrl}/cups/historical-consumption/search?page=${page}&pageSize=${pageSize}&sortBy=`;
            if (sortBy === undefined) {
                url += `crmHistoricConsumptionsId.cups&sortDir=desc`;
            } else {
                url += `${sortBy}&sortDir=${sortDir}`;
            }
            if (isExport) {
                url += '&isExcel=true';
            }
            return this._http.post<any[]>(url, { search: search });
        }
    }

    /**
     * Get Contracts
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @returns
     */
    getContractsTable(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-contratos.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'contract', 'contractCode', isExport);
        }
    }
    getContractsTableBySociety(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-contratos.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'contract', 'idNumber', isExport);
        }
    }

    /**
     * Get Closures
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @param search parametro de busqueda
     * @returns
     */
    getTablaCierres(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-cierres.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(
                SortBy.CLOSED,
                page,
                pageSize,
                search,
                sortDir,
                'contract/closing',
                SortBy.CLOSED,
                isExport
            );
        }
    }

    /**
     * Get Contract Prices
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @param search parametro de busqueda
     * @returns
     */
    getTablaPreciosContratos(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-precios-contratos.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(
                sortBy,
                page,
                pageSize,
                search,
                sortDir,
                'pricing-output',
                SortBy.CRM_VARIABLE,
                isExport
            );
        }
    }

    getCrmVariables() {
        const url = `${environment.apiUrl}/pricing-output-variables/v0?page=-1`;
        return this._http.get<any[]>(url);
    }

    getCrmVariablesNew() {
        const url = `${environment.apiUrl}/contract/variables/v0?page=-1&sortBy=variable&sortDir=asc`;
        return this._http.get<any[]>(url);
    }

    getLiquidations() {
        const url = `${environment.apiUrl}/precedence/common/v0?page=-1`;
        return this._http.get<any[]>(url);
    }

    /**
     * Get Contract Concepts Table
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @param search parametro de busqueda
     * @returns
     */
    getContractConceptsTable(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-conceptos-contratos.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'contract/concept', 'concept', isExport);
        }
    }

    getContratosFicticiosTable(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        return this.checkSorting(
            sortBy,
            page,
            pageSize,
            search,
            sortDir,
            'contract/dummy',
            SortBy.CONTRATOS_FICTICIOS,
            isExport
        );
    }

    getVarContratosFicticiosTable(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-conceptos-contratos.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(
                sortBy,
                page,
                pageSize,
                search,
                sortDir,
                'contract/dummy-variables',
                SortBy.VARIABLES_CONTRATOS_FICTICIOS,
                isExport
            );
        }
    }

    /**
     * Get NegotiatingAccounts
     * @returns
     */
    getNegotiatingAccounts(specialSearch?: {
        search: string;
        mode: 'negotiatingAccountCode' | 'negotiatingAccountDescription';
    }): Observable<any> {
        let url: string;
        if (specialSearch) {
            url = `${environment.apiUrl
                }/negotiating-account/v0?page=0&pageSize=10&sortBy=negotiatingAccountCode&sortDir=asc&search=${specialSearch.mode === 'negotiatingAccountCode'
                    ? 'negotiatingAccountCode'
                    : 'negotiatingAccountDescription'
                }like(${specialSearch.search})`;
        } else {
            url = `${environment.apiUrl}/negotiating-account/v0?page=0&pageSize=10&sortBy=negotiatingAccountCode&sortDir=asc`;
        }
        return this._http.get<any[]>(url);
    }

    /**
     * Get Code NegotiatingAccount
     * @returns
     */
    getNegotiatingAccountDescription(description: string): Observable<any> {
        let url: string;
        url = `${environment.apiUrl}/negotiating-account/v0?page=0&sortBy=negotiatingAccountCode&sortDir=asc&search=negotiatingAccountDescription=${description}`;
        return this._http.get<any[]>(url);
    }

    /**
     * Get Clients
     * @returns
     */
    getClients(specialSearch?: { search: string; mode: 'description' | 'cif' }): Observable<any> {
        let url: string;
        if (specialSearch) {
            url = `${environment.apiUrl}/client/v0?page=-1&sortBy=idNumber&sortDir=asc&search=${specialSearch.mode === 'cif' ? 'idNumberlike' : 'clientNamelike'
                }(${specialSearch.search})`;
        } else {
            url = `${environment.apiUrl}/client/v0?page=-1`;
        }
        return this._http.get<any[]>(url);
    }

    /**
     * Get Contract Status
     * @returns
     */
    getContractState(): Observable<any> {
        if (environment.isMockActive) {
            return of('isMockActive ContractState');
        } else {
            const url = `${environment.apiUrl}/contract/status/v0?page=0`;
            return this._http.get<any[]>(url);
        }
    }

    /**
     * Get Contract Code
     * @returns
     */
    getContractCode(code?: string): Observable<any> {
        if (environment.isMockActive) {
            return of('isMockActive Contract Code');
        } else {
            let url;
            if (this.selectId !== 'variablesContratosFicticios') {
                url = `${environment.apiUrl}/contract/v0?page=0&search=contractCodelike(${code})`;
            } else {
                url = `${environment.apiUrl}/contract/dummy/v0?page=-1&sortBy=contractCode&sortDir=asc&search=contractCodelike(${code})`;
            }
            return this._http.get<any[]>(url);
        }
    }
    getContractCode1(): Observable<any> {
        if (environment.isMockActive) {
            return of('isMockActive Contract Code');
        } else {
            const url = `${environment.apiUrl}/contract/v0?page=1&pageSize=10`;
            return this._http.get<any[]>(url);
        }
    }

    getTablaPuntoMedida(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-punto-medida.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(
                sortBy,
                page,
                pageSize,
                search,
                sortDir,
                'measure-point',
                'crmMeasurePointId.cups',
                isExport
            );
        }
    }

    checkCups(cups: any): Observable<any> {
        if (typeof cups !== 'string') {
            cups.toString();
        }
        if (environment.isMockActive) {
            return of(
                'ES0021000000000964CH;ES0021000000002611TD;ES0021000000006325TC;ES0021000000014012JW;ES0021000000017167NY'
            );
        } else {
            let cups$: Observable<string> = this.checkValidCups(cups).pipe(
                map((response: any) => {
                    return response?.data?.map((cups: any) => cups.cups).join(';');
                })
            );
            this.validCups += `;${cups}`;
            return cups$;
        }
    }

    checkCupsMock(cups: string): Observable<any> {
        if (environment.isMockActive) {
            return of('ES0021000000000964CH;ES0021000000002611TD;ES0021000000006325TC;ES0021000000017167NY');
        } else {
            return of(cups);
        }
    }

    checkCupsMockDeleteAll(): Observable<string> {
        return of('');
    }

    checkValidCups(cups: any): Observable<any> {
        if (cups && cups.length > 0) {
            const url = `${environment.apiUrl}/cups/v0/search?page=-1`;
            return this._http.post<any[]>(url, { search: `cups=(${cups})` });
        }
        return of([]);
    }

    /**
     * Get Distributors
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @returns
     */
    getDistributorsTable(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        isExport: boolean = false
    ): Observable<any> {
        let url;
        if (environment.isMockActive) {
            url = './assets/json/tabla-distribuidor.json';
        } else {
            if (sortBy === undefined) {
                url = `${environment.apiUrl}/distributor/v0?page=${page}&pageSize=${pageSize}&sortBy=code&sortDir=asc`;
            } else {
                url = `${environment.apiUrl}/distributor/v0?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`;
            }
            if (isExport) {
                url += '&isExcel=true';
            }
        }
        return this._http.get<any[]>(url);
    }

    /**
     * Get Fees
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @param search buscar por..
     * @returns
     */
    getFeesTable(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-tarifas.json';
            return this._http.get<any>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'rate', 'codeRate', isExport);
        }
    }

    // Se usa getFeesCode para pintar todos los select que usen el codigo de Tarifas (ej: tarifarios, perfilesREE)

    getFeesCode(): Observable<any> {
        const url = `${environment.apiUrl}/rate/v0?page=-1&sortBy=codeRate&sortDir=asc`;
        return this._http.get<Distributors[]>(url);
    }
    getLossCoefficientCode(): Observable<any> {
        const url = `${environment.apiUrl}/rate/v0?page=-1&sortBy=codeRate&sortDir=asc`;
        return this._http.get<Distributors[]>(url);
    }
    /**
     * Get festivos
     * @param page
     * @param pageSize
     * @param sortBy
     * @param sortDir
     * @param search
     * @returns
     */
    getTablaFestivos(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        let url = `${environment.apiUrl}/holiday/v0?sortBy=${sortBy ? sortBy : SortBy.FESTIVE
            }&sortDir=${sortDir}&search=${search}&page=${page}&pageSize=${pageSize}`;
        if (isExport) {
            url += '&isExcel=true';
        }
        return this._http.get<any[]>(url);
    }

    /**
     * Actualizar festivos
     * @param festiveId ID del Festivo a editar
     * @param body Body que le pasamos con los nuevos cambios
     * @returns
     */
    updateFestivos(festiveId: any, body: any) {
        // ACTUALIZAR FESTIVOS
        const url = `${environment.apiUrl}/holiday/v0/${festiveId}`;
        return this._http.put<any>(url, body);
    }

    /**
     * Crear un nuevo Festivo
     * @param body Datos del nuevo festivo
     * @returns
     */
    createFestivo(body: any) {
        // INSERTAR FESTIVOS
        const url = `${environment.apiUrl}/holiday/v0`;
        return this._http.post<any>(url, body);
    }

    /**
     * @param festiveId ID del Festivo a borrar
     * @returns
     */
    deleteFestivo(festiveId: any) {
        const url = `${environment.apiUrl}/holiday/v0/${festiveId}`;
        return this._http.delete<any>(url);
    }

    /**
     * @param contractCode ID del contrato ficticio a borrar
     * @returns
     */
    deleteContratoFicticio(contractCode: any) {
        const url = `${environment.apiUrl}/contract/dummy/v0/${contractCode}`;
        return this._http.delete<any>(url);
    }

    /**
     * Actualizar Punto Medida
     * @param festiveId ID del CUPS MP
     * @param body Body que le pasamos con los nuevos cambios
     * @returns
     */
    updatePuntoMedida(cups: any, cupsMP: any, body: any) {
        // ACTUALIZAR PUNTO MEDIDA
        const url = `${environment.apiUrl}/measure-point/v0/${cups}/${cupsMP}`;
        return this._http.put<any>(url, body);
    }

    updatePreciosRegulados(precioReguladoId: any, body: any) {
        // ACTUALIZAR FESTIVOS
        const url = `${environment.apiUrl}/price/v0/${precioReguladoId}`;
        return this._http.put<any>(url, body);
    }

    deletePreciosRegulados(precioReguladoId: any) {
        const url = `${environment.apiUrl}/price/v0/${precioReguladoId}`;
        return this._http.delete<any>(url);
    }

    createPreciosRegulados(body: any) {
        // INSERTAR FESTIVOS
        const url = `${environment.apiUrl}/price/v0`;
        return this._http.post<any>(url, body);
    }
    /**
     * Te trae los clientes del BACK
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @param search buscar por..
     * @returns
     */
    getTablaSociedad(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-sociedad.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(sortBy, page, pageSize, search, sortDir, 'client', 'idNumber', isExport);
        }
    }

    /**
     * Get Cuentas Negociadoras
     * @param page número de página
     * @param pageSize tamaño de la página
     * @param sortBy filtrar por
     * @param sortDir ordenar en direccion ascendente o descendente
     * @param search buscar por..
     * @returns
     */
    getTablaCuentaNegociadora(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        if (environment.isMockActive) {
            const url = './assets/json/tabla-cuenta-negociadora.json';
            return this._http.get<any[]>(url);
        } else {
            return this.checkSorting(
                sortBy,
                page,
                pageSize,
                search,
                sortDir,
                'negotiating-account',
                'negotiatingAccountCode',
                isExport
            );
        }
    }

    getRegions(regionCode?: string) {
        if (!regionCode) {
            const url = `${environment.apiUrl}/region/v0?page=0&sortBy=regionCode&sortDir=asc`;
            return this._http.get<any[]>(url);
        } else {
            let regionCodeReturned: string = regionCode;
            const url = `${environment.apiUrl}/region/v0/${regionCodeReturned}`;
            return this._http.get<any[]>(url);
        }
    }

    checkZeros(regionCode: string): string {
        let code = Number(regionCode);
        if (!isNaN(code)) {
            return code.toString();
        }
        return '';
    }

    getBoundaryPoints(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDir: string,
        search: string,
        isExport: boolean = false
    ): Observable<any> {
        return this.checkSorting(
            sortBy,
            page,
            pageSize,
            search,
            sortDir,
            'cups/boundary-point',
            'boundaryPointCode',
            isExport,
            'asc'
        );
    }

    checkSorting(
        sortBy: string,
        page: number,
        pageSize: number,
        search: string,
        sortDir: string,
        endpoint: string,
        sortType: string,
        isExport: boolean = false,
        sort = 'asc'
    ) {
        let url: string = `${environment.apiUrl}/${endpoint}/v0?page=${endpoint === 'fee-matrix' ? -1 : page}${this.contractPagesEndpoints.includes(endpoint) && isExport ? `` : '&pageSize=' + pageSize
            }`;

        // Adapta la petición para usar POST (debido a que tantos CUPS no cabían en la URL)
        if (endpoint === 'cups/boundary-point' && this.selectId === 'puntoFrontera') {
            const url = `${environment.apiUrl}/cups/boundary-point/search?page=${page}&pageSize=${pageSize}&sortBy=${sortType}&sortDir=${sort}`;
            return this._adaptToPostRequest(url, search, ['descriptionlike', 'boundaryPointCode']);
        } else if (endpoint === 'cups' && this.selectId === 'CUPS') {
            const url = `${environment.apiUrl}/cups/v0/search?page=${page}&pageSize=${pageSize}&sortBy=${sortType}&sortDir=${sort}`;
            return this._adaptToPostRequest(url, search, ['instName', 'cups']);
        } else if (endpoint === 'contract' && this.selectId === 'contratos') {
            const url = `${environment.apiUrl}/contract/v0/search?page=${page}&pageSize=${pageSize}&sortBy=${sortType}&sortDir=${sort}`;
            return this._http.post<any[]>(url, { search: search });
        } else if (endpoint === 'measure-point' && this.selectId === 'puntoMedida') {
            const url = `${environment.apiUrl}/measure-point/v0/search?page=${page}&pageSize=${pageSize}&sortBy=${sortType}&sortDir=${sort}`;
            return this._http.post<any[]>(url, {
                search: this.validCups !== '' ? `crmMeasurePointId.cups=(${this.validCups})` : '',
            });
        }
        if (sortBy === undefined) {
            url += `&sortBy=${sortType}&sortDir=${sort}&search=${search}`;
        } else {
            url += `&sortBy=${sortBy}&sortDir=${sortDir}&search=${search}`;
        }
        if (isExport) {
            url += `&isExcel=true`;
        }
        return this._http.get<any[]>(url);
    }

    /*
    * Esta implementación exporta los registros en Excel desde los microservicios
    */
    exportToExcel(
        endpointType: string,
        totalRecords: number,
        sortBy: string,
        sortDir: string,
        search: string
    ): Observable<any> {
        let url: string;

        switch (endpointType) {
            case 'contratos':
                url = `${environment.apiUrl}/contract/v0/exportContractsToExcel?totalRecords=${totalRecords}&sortBy=${sortBy}&sortDir=${sortDir}`;
                break;
            case 'puntoFrontera':
                url = `${environment.apiUrl}/cups/boundary-point/exportBoundaryPointsToExcel?totalRecords=${totalRecords}&sortBy=${sortBy}&sortDir=${sortDir}`;
                break;
            default:
                throw new Error(`Unsupported endpoint type: ${endpointType}`);
        }

        const timeoutValue = 250000; // Tiempo de espera en milisegundos (250 segundos o 4 minutos)
        return this._http.post<ArrayBuffer>(url, { search }, {
            responseType: 'arraybuffer' as 'json',
        }).pipe(
            timeout(timeoutValue),
            map(arrayBuffer => this.convertToBlobUrl(arrayBuffer)),
            catchError(this.handleError)
        );
    }

    private convertToBlobUrl(arrayBuffer: ArrayBuffer): string {
        const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        return window.URL.createObjectURL(blob);
    }

    private handleError(error: any): Observable<never> {
        console.error('Error exporting to Excel:', error);
        return throwError(() => error);
    }

    private _adaptToPostRequest(url: string, search: string, filters: string[]) {
        let body = '';
        let property = '';

        filters.forEach((filter: string) => {
            if (search.includes(filter)) {
                const withEqual: boolean = filter !== 'descriptionlike';
                const indexBegin: number = withEqual ? 2 : 0;
                const indexEnd: number = withEqual ? -1 : 1;
                property = search.slice(
                    search.indexOf(filter) + filter.length + indexBegin,
                    search.indexOf(',', search.indexOf(filter)) + indexEnd
                );
                if (property.length > 4) {
                    property = `${filter}${withEqual ? '=' : ''}(${property})`;
                } else {
                    property = '';
                }
                body += `${property},`;
            }
        });
        return this._http.post<any[]>(url, { search: `${search}` });
    }

    /**
     * Endpoint para conseguir las precedencias según boundary point y area.
     * @param boundaryPoint Boundary Point a buscar en la petición
     * @param area Área de Medidas o Cierres
     * @returns
     */
    getPrecedencesShowModal(boundaryPoint: string, area: string): Observable<any> {
        const url = `${environment.apiUrl}/precedence/cups/v0?page=-1&sortBy=area&sortDir=asc&search=cups=${boundaryPoint},area=${area}`;
        return this._http.get<any[]>(url);
    }

    /**
     * Endpoint para conseguir las precedencias masivamente según boundary points y area.
     * @param boundaryPoint Boundary Point a buscar en la petición
     * @param area Área de Medidas o Cierres
     * @returns
     */
    getPrecedencesMassiveShowModal(boundaryPoints: string, area: string): Observable<any> {
        const url = `${environment.apiUrl}/precedence/cups/v0?page=-1&sortBy=area&sortDir=asc&search=cups=(${boundaryPoints}),area=${area}`;
        return this._http.get<any[]>(url);
    }

    /**
     * Endpoint para conseguir la estructura para crear una precedencia según Cierres o Medidas
     * @param area Área de medidas o cierres
     * @returns
     */
    getPrecedenceStructure(area: string): Observable<any> {
        const url = `${environment.apiUrl}/precedence/common/v0?page=-1&sortBy=area&search=area=${area}&sortDir=asc`;
        return this._http.get<any[]>(url);
    }

    /**
     * Editar Punto Frontera
     * @param body Body a mandar
     * @returns
     */
    updatePuntoFrontera(body: any, search?: string) {
        //ACTUALIZAR FESTIVOS
        let url = `${environment.apiUrl}/cups/boundary-point`;
        if (search) {
            url += search;
        }
        return this._http.put<any>(url, body);
    }

    /**
     * Editar una preferencia
     * @param body Body a mandar
     * @returns
     */
    updatePrecedence(body: any) {
        //ACTUALIZAR PRECEDENCIAS
        const url = `${environment.apiUrl}/precedence/cups/v0`;
        return this._http.put<any>(url, body);
    }

    /**
     * Crear una preferencia
     * @param body Body a mandar
     * @returns
     */
    createPrecedence(body: any) {
        //CREAR PRECEDENCIAS
        const url = `${environment.apiUrl}/precedence/cups/v0`;
        return this._http.post<any>(url, body);
    }

    searchBoundaryPointsPuntoFrontera(cups: string) {
        const url = `${environment.apiUrl}/measure-point/v0?page=-1&sortBy=crmMeasurePointId.cups&sortDir=asc&search=crmMeasurePointId.cups=${cups}`;
        return this._http.get<any>(url);
    }

    getUnidadPrograma() {
        const url = `${environment.apiUrl}/unitProgram/v0?page=0&sortDir=asc`;
        return this._http.get<any>(url);
    }

    changeReeProfileName(name: string) {
        let code = '';
        switch (name) {
            case 'Mineria':
                name = 'Minería';
                break;
            case 'Fabricas':
                name = 'Fábricas';
                break;
            case 'Construccion':
                name = 'Construcción';
                break;
            case 'Hosteleria':
                name = 'Hostelería';
                break;
            case 'Comunicacion':
                name = 'Comunicación';
                break;
            case 'Educacion':
                name = 'Educación';
                break;
        }
        code = name;
        return code;
    }

    setBooleanValue(value: boolean | number | string): string {
        return value === '0' || value === 0 || value === false
            ? this._translateService.instant('PAGE.MODALS.NO')
            : this._translateService.instant('PAGE.MODALS.YES');
    }

    getAllCupsToPrecedencesModal() {
        return this._http.get<any>(
            `${environment.apiUrl}/cups/boundary-point?page=1&pageSize=1&sortBy=boundaryPointCode&sortDir=asc&search=${this.searchParam}`
        );
    }
}
