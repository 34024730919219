<div class="container" style="justify-content: flex-start">
    <div class="row flex_column reposition">
        <app-spinner></app-spinner>
        <app-http-error class="errorBox"></app-http-error>
    </div>
    <div *ngIf="validRole" class="filter">
        <mat-form-field appearance="standard">
            <mat-label class="concept"> {{ 'PAGE.ACTIONS.CONCEPT' | translate }} * </mat-label>
            <mat-select (valueChange)="dataChanged($event, true)" [(ngModel)]="selectedValue">
                <mat-option *ngFor="let concepto of sendTypeList" [value]="concepto">
                    {{
                        concepto === sendTypes.DAILY_FORECAST
                            ? ('PAGE.SENDINGS.DAILY_FORECAST' | translate)
                            : concepto === sendTypes.WEEKLY_FORECAST
                            ? ('PAGE.SENDINGS.WEEKLY_FORECAST' | translate)
                            : ('PAGE.SENDINGS.OPTIONAL_SETTLEMENT' | translate)
                    }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <app-filtro-date
            [name]="'PAGE.FILTERS.DATE_RANGE_REQUIRED' | translate"
            [reset$]="reset$"
            (date)="dateComplete($event)"
            (select)="dataChanged($event)"
        ></app-filtro-date>
    </div>
    <div *ngIf="validRole" class="subfilter">
        <ng-container *ngFor="let filtro of subfilters; let i = index">
            <mat-form-field appearance="standard" class="select">
                <mat-label> {{ filtro.name }} </mat-label>

                <mat-select
                    *ngIf="i !== 1"
                    (valueChange)="getOptionSelected($event, true)"
                    [(ngModel)]="optionValues[i]"
                >
                    <mat-option *ngFor="let option of filtro.data" [value]="option.value" (click)="filter(option, i)">
                        {{ option.name }}
                    </mat-option>
                </mat-select>

                <mat-select
                    *ngIf="i === 1"
                    [formControl]="unitsForm"
                    (valueChange)="getOptionSelected($event)"
                    [multiple]="isMultiple(i)"
                >
                    <mat-option
                        *ngFor="let option of unitProgramsFormControl"
                        [value]="option.value"
                        (click)="filter(option, i)"
                    >
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </div>
    <ng-container *ngIf="errorMessageTranslationCode">
        <div class="clarifyingMessage">
            <h3 class="clarifyingMessageH3">
                {{ 'PAGE.FILTERS.SELECT.' + errorMessageTranslationCode | translate }}
            </h3>
        </div>
    </ng-container>
    <div *ngIf="!validRole">
        <h3 class="invalidRole">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
    </div>

    <div class="botones">
        <div class="buttonsFilter">
            <!-- BOTÓN BORRAR -->
            <button class="delete" (click)="clearFilters()" mat-button>
                {{ 'PAGE.ACTIONS.DELETE' | translate }}
                <app-base-icon iconName="filter_alt_off"></app-base-icon>
            </button>
            <!-- BOTÓN FILTRAR -->
            <button class="search" mat-flat-button color="warn" (click)="sendFile()" [disabled]="isButtonDisabled()">
                {{ 'PAGE.ACTIONS.EXECUTE_NOW' | translate | uppercase }}
                <span class="icon material-symbols-outlined"> cloud_upload </span>
            </button>
        </div>
    </div>
</div>
