import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { MaestroAWSPageComponent } from './pages/maestro/maestro-aws-page/maestro-aws-page.component';
import { MaestroCRMPageComponent } from './pages/maestro/maestro-crm-page/maestro-crm-page.component';
import { CargasResultadoComponent } from './pages/cargas/cargas-resultado/cargas-resultado.component';
import { ProcesosResultadosComponent } from './pages/procesos/procesos-resultados/procesos-resultados.component';
import { ProcesosLanzarComponent } from './pages/procesos/procesos-lanzar/procesos-lanzar.component';
import { LoginComponent } from './pages/login/login/login.component';
import { ErrorPageComponent } from './pages/error/components/error-page/error-page.component';
import { SelectCargasComponent } from './pages/cargas/components/select-cargas/select-cargas.component';
import { ManagementUserComponent } from './pages/user_management/components/management-user/management-user.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ProcesosProgramarComponent } from './pages/procesos/procesos-programar/procesos-programar.component';
import { CuadroMandosComponent } from './pages/cuadro-mandos/cuadro-mandos-page/cuadro-mandos-page.component';
import { EnviosLanzarComponent } from './pages/envios/envios-lanzar/envios-lanzar.component';
import { PrevisionesAgregadoPageComponent } from './pages/previsiones/previsiones-agregado-page/previsiones-agregado-page.component';
import { MejorPrevisionPageComponent } from './pages/previsiones/mejor-prevision-page/mejor-prevision-page.component';
import { EnviosResultadoComponent } from './pages/envios/envios-resultado/envios-resultado.component';
import { ContratosFicticiosComponent } from './pages/cargas/contratos-ficticios/contratos-ficticios.component';
import { EjecucionInformesPageComponent } from './pages/informes/ejecucion/ejecucion-informes-page/ejecucion-informes-page.component';
import { InformesResultadoPageComponent } from './pages/informes/resultados/informes-resultado-page/informes-resultado-page.component';
import { HealthCheckComponent } from './pages/health-check/health-check.component';

const routes: Routes = [
    {
        path: '',
        component: HomePageComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'home',
        component: HomePageComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'signout',
        component: LoginComponent,
    },
    {
        path: 'maestros',
        children: [
            {
                path: 'aws',
                component: MaestroAWSPageComponent,
            },
            {
                path: 'crm',
                component: MaestroCRMPageComponent,
            },
        ],
    },
    {
        path: 'cargas',
        children: [
            {
                path: 'manual',
                component: SelectCargasComponent,
            },
            {
                path: 'resultados',
                component: CargasResultadoComponent,
            },
            {
                path: 'contratos-ficticios',
                component: ContratosFicticiosComponent,
            },
        ],
    },
    {
        path: 'procesos',
        children: [
            {
                path: 'lanzar',
                component: ProcesosLanzarComponent,
            },
            {
                path: 'resultados',
                component: ProcesosResultadosComponent,
            },
            {
                path: 'programar',
                component: ProcesosProgramarComponent,
            },
        ],
    },
    {
        path: 'informes',
        children: [
            {
                path: 'generar',
                component: EjecucionInformesPageComponent,
            },
            {
                path: 'consultar',
                component: InformesResultadoPageComponent,
            },
        ],
    },
    {
        path: 'medida',
        children: [
            {
                path: 'cuadro-mandos',
                component: CuadroMandosComponent,
            },
        ],
    },
    {
        path: 'previsiones',
        children: [
            {
                path: 'agregado-previsiones',
                component: PrevisionesAgregadoPageComponent,
            },
            {
                path: 'mejor-prevision',
                component: MejorPrevisionPageComponent,
            },
        ],
    },
    {
        path: 'envios',
        children: [
            {
                path: 'lanzar',
                component: EnviosLanzarComponent,
            },
            {
                path: 'resultados',
                component: EnviosResultadoComponent,
            },
        ],
    },
    {
        path: 'user-management',
        component: ManagementUserComponent,
    },
    {
        path: 'error',
        component: ErrorPageComponent,
    },
    {
        path: 'healt-check',
        component: HealthCheckComponent,
    },
    {
        path: '404',
        component: ErrorPageComponent
    },
    {
        path: '**',
        redirectTo: ''
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), CommonModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
