import { Component, OnInit, HostListener } from '@angular/core';
import { MenuItems } from '../../models/menu-items.model';
import { ItemMenu } from '../../interfaces/itemMenu.interface';
import { LoginService } from '../../../core/services/login.service';
import { Roles } from '../../enums/roles.enum';
import * as _ from 'lodash';

@Component({
    selector: 'app-sidebar-menu',
    templateUrl: './sidebar-menu.component.html',
    styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
    events: string[] = [];
    opened: boolean = true;
    sideBarOpen: boolean = true;
    itemsMenu: ItemMenu[] = [];
    screenWidth = 1300;
    innerWidth: any;
    userRoles: string[] = [];
    fullItemsMenu: ItemMenu[] = [];

    constructor(private readonly _loginService: LoginService) {
        this.getScreenSize();
    }

    ngOnInit(): void {
        this.checkMenuVisibilityWithUserRoles();
    }

    toggleSidenav() {
        this.opened = !this.opened;
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize() {
        if (this.screenWidth < window.innerWidth) {
            this.opened = true;
        }
        if (this.screenWidth >= window.innerWidth) {
            this.opened = false;
        }
    }

    checkMenuVisibilityWithUserRoles(): void {
        const menuItemsUpdated: ItemMenu[] = [];
        this.fullItemsMenu = _.cloneDeep(MenuItems);
        this.itemsMenu = [];
        this.userRoles = this._loginService.getUserActiveRoles()?.split(',') ?? [Roles.CONSULTA];

        this.fullItemsMenu.forEach((menuItem: ItemMenu) => {
            let item: ItemMenu = {} as ItemMenu;
            const userForbiddenRolesToSeeMenu = this._searchForbiddenRoles(this.userRoles, menuItem.roles ?? []);
            const isUserAllowedToSeeMenu = this.userRoles.length !== userForbiddenRolesToSeeMenu.length;
            if (isUserAllowedToSeeMenu) {
                item = menuItem;
                const subItems: ItemMenu[] = [];
                menuItem.subItems.forEach((menuSubitem: ItemMenu) => {
                    const userForbiddenRolesToSeeSubmenu = this._searchForbiddenRoles(
                        this.userRoles,
                        menuSubitem.roles ?? []
                    );
                    const isUserAllowedToSeeSubmenu = this.userRoles.length !== userForbiddenRolesToSeeSubmenu.length;
                    if (isUserAllowedToSeeSubmenu) {
                        subItems.push(menuSubitem);
                    }
                });
                item.subItems = subItems;
                menuItemsUpdated.push(item);
            }
        });
        this.itemsMenu = menuItemsUpdated;
    }

    private _searchForbiddenRoles(userRoles: string[], itemRoles: string[]) {
        const userForbiddenRoles: string[] = [];
        if (userRoles && itemRoles) {
            userRoles.forEach((userRole: string) => {
                if (!itemRoles.includes(userRole.trim())) {
                    userForbiddenRoles.push(userRole.trim());
                }
            });
        }
        return userForbiddenRoles;
    }
}
