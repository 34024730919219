import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HeaderTitleService {
    private headerSubject = new BehaviorSubject<string>('');

    currentHeader: string = 'TITLE';
    currentHeaderTranslationCode: string = '';
    currentLang: string = '';

    currentInitDate: string = '';
    currentEndDate: string = '';
    measureInitDate: string = '';
    measureEndDate: string = '';
    headerValue = this.headerSubject.asObservable();
    showBack: boolean = false;

    constructor(private _translateService: TranslateService) {}

    async updateHeader(newValue: string) {
        this._translateService.use(this.currentLang);
        newValue = newValue.includes('PAGE.HEADER.') ? newValue : `PAGE.HEADER.${newValue}`;
        const response$ = await firstValueFrom(this._translateService.get(newValue));
        this.currentHeader = response$;
        this.headerSubject.next(this.currentHeader);
        this.showBack = this.currentHeaderTranslationCode !== 'TITLE';
    }
}
