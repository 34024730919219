import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
    ViewChild,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControlDirective,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
} from '@angular/forms';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-filtro-input',
    templateUrl: './filtro-input.component.html',
    styleUrls: ['./filtro-input.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: FiltroInputComponent, multi: true }],
})
export class FiltroInputComponent implements OnInit, ControlValueAccessor, OnChanges {
    @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;

    @Input() name: string = '';
    @Input() value: string | number = '';
    @Input() id: string = '';
    @Input() disabled: boolean;
    @Input() type: 'number' | 'input' = 'input';
    @Input() min: number;
    @Input() max: number;
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Input() required: boolean;

    @Input() formControl: UntypedFormControl;
    @Input() formControlName: string = '';
    private defaultControl = new UntypedFormControl();
    selectedLanguage: string;
    hasTypeChanged: boolean = false;

    get control() {
        if (this.hasTypeChanged) {
            this.value = this._changeToLocalFormat(this.defaultControl.value);
        }
        return this.formControl || this.controlContainer?.control?.get(this.formControlName) || this.defaultControl;
    }

    constructor(@Optional() private controlContainer: ControlContainer, private _languageService: LanguageService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.defaultControl.patchValue(this.value);
        }
    }

    ngOnInit(): void {
        this.selectedLanguage = this._languageService.getPreferredLanguage() || this._languageService.languageBrowser;
        if (this.selectedLanguage !== 'en') {
            this.selectedLanguage = 'de'; // El formato alemán es el que utilizamos
        }
        if (!this.hasTypeChanged && this.type === 'number' && this.selectedLanguage !== 'en') {
            this.type = 'input';
            this.hasTypeChanged = true;
        }
        if (this.hasTypeChanged) {
            const formattedValue = this.value
                ? this._changeToLocalFormat(Number.parseFloat(this.value.toString()))
                : '-';
            this.defaultControl.setValue(formattedValue);
            this.value = this.defaultControl.value === '-' ? null : this.defaultControl.value;
        }
    }

    emitValue($event: any) {
        if (this.hasTypeChanged && typeof $event.target.value === 'string') {
            this.value = this.value
                .toString()
                .replaceAll(',', '.')
                .replace(/[^0-9.,]/g, '');
            this.defaultControl.setValue(this._changeToLocalFormat(Number.parseFloat(this.value)));
            const valueParsed: number = Number.parseFloat(this.value.toString());
            this.select.emit({ newValue: !Number.isNaN(valueParsed) ? valueParsed : null, name: this.id });
        } else {
            this.select.emit({ newValue: $event.target.value, name: this.id });
        }
    }

    // Control Methods:
    registerOnTouched(fn: any): void {
        this.formControlDirective.valueAccessor!.registerOnTouched(fn);
    }

    registerOnChange(fn: any): void {
        this.formControlDirective.valueAccessor!.registerOnChange(fn);
    }

    writeValue(obj: any): void {
        this.formControlDirective.valueAccessor!.writeValue(obj);
    }

    setDisabledState(isDisabled: boolean): void {
        this.formControlDirective.valueAccessor!.setDisabledState!(isDisabled);
    }

    private _changeToLocalFormat(value: any) {
        return value
            .toLocaleString(this.selectedLanguage, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 9,
            })
            .toString();
    }
}
