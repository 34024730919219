import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { GlobalService } from 'src/app/services/global.service';
import { MaestroService } from '../../../services/maestro.service';
import { EditModalComponent } from '../edit-modal/edit-modal.component';
import { IColumnsTable } from '../../../../../shared/models/columns-table.model';
import {
    IClaims,
    IObjections,
    ICommunicationParameters,
    IClaimsAttachedDocument,
    IStep,
    IKeyValue,
    IResponseClaimsOrObjections,
} from '../../../../../shared/models/claimsObjections';
import { TranslateService } from '@ngx-translate/core';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-claim-details-page',
    templateUrl: './claim-details-page.component.html',
    styleUrls: ['./claim-details-page.component.scss'],
})
export class ClaimDetailsPageComponent implements OnInit {
    title: string;
    claimCode: string;
    objectionCode: string;
    isTabSystemVisible: boolean;
    isModalOpened: boolean;

    // HEADERS
    claims: string = 'Reclamaciones';
    objections: string = 'Objeciones';
    tabsTitles: any;
    columns: string[];
    editableCells: string[];
    displayedColumns: IColumnsTable[];
    headersUniqueFields: IColumnsTable[];
    headersCommunicationParams: IColumnsTable[];
    headersAttachedDocumentation: IColumnsTable[];
    headersSteps: IColumnsTable[];

    // DATA SOURCES
    modifiedDataSource: any[];
    // modifiedDataSource: IResponseClaimsOrObjections | IKeyValue[];
    originalDataSource: any;
    // originalDataSource: IClaims[] | IObjections[];
    dataSourceUniqueFields: any;
    // dataSourceUniqueFields: IUniqueFields | IKeyValue;
    dataSourceCommunicationParams: any[] = [];
    // dataSourceCommunicationParams: ICommunicationParameters[] = [];
    dataSourceAttachedDocumentation: any[] = [];
    // dataSourceAttachedDocumentation: IClaimsAttachedDocument[] = [];
    dataSourceSteps: any[] = [];
    // dataSourceSteps: IStep[] = [];
    tableTabsSource: { label: string; headers: IColumnsTable[]; data: any[] }[];

    // PAGINATION
    pageIndex: number;
    pageSize: number;
    pageSizeOptions: number[] = [5, 10, 25, 50];
    totalRegisters: number;

    constructor(
        public editModalComponent: MatDialog,
        public readonly _headerTitleService: HeaderTitleService,
        private _maestroService: MaestroService,
        private _globalService: GlobalService,
        private _translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.claimCode = this._maestroService.claimCode;
        this.objectionCode = this._maestroService.objectionCode;
        this.isTabSystemVisible = false;
        this.isModalOpened = false;
        // CLAIMS PAGE
        if (this.claimCode && this.claimCode !== '') {
            this.getRegisters(this.claims);
            this._translateService.get('PAGE.MASTER.MASTER_AWS.CLAIMS').subscribe((message: any) => {
                this.tabsTitles = message;
            });
        }
        // OBJECTIONS PAGE
        else if (this.objectionCode && this.objectionCode !== '') {
            this.getRegisters(this.objections);
        }
    }

    private getRegisters(page: string): void {
        if (page === this.claims) {
            this._maestroService.getClaimDetails(this.claimCode).subscribe((data: IResponseClaimsOrObjections) => {
                this.setData('maestro_puntoFrontera_reclamaciones', page, data);
            });
        } else if (page === this.objections) {
            this._maestroService
                .getObjectionDetails(this.objectionCode)
                .subscribe((data: IResponseClaimsOrObjections) => {
                    this.setData('maestro_puntoFrontera_objeciones', page, data);
                });
        }
    }

    // Sorts dataSource registers based on columns sort
    private sortRegisters(): void {
        let registerSorted: IKeyValue[] = [];
        let dataSorted: IKeyValue[][] = [];

        if (this.modifiedDataSource !== undefined && this.modifiedDataSource.length > 0) {
            this.modifiedDataSource.forEach((register: any) => {
                this.columns.forEach((column: string) => {
                    registerSorted.push({ id: column, value: register[column] });
                });
                registerSorted.push({ id: 'id', value: register['id'] });
                dataSorted.push(registerSorted);
                registerSorted = [];
            });
        }
        this.modifiedDataSource = dataSorted;
    }

    // Sets all the data for the specific page (claims or objections page)
    private setData(id: string, page: string, data: IResponseClaimsOrObjections): void {
        this.title = page;
        this._globalService.getHeaders().subscribe((resp: any) => {
            this.displayedColumns = resp.data.filter((x: any) => {
                return x.name === id;
            })[0]?.headers;

            // Deletes groups ids from the columns ids array
            this.columns = this.displayedColumns
                .map((col: IColumnsTable) => {
                    if (!col.group || !col.group.isTheHeader) {
                        return col.id;
                    }
                    return '';
                })
                .filter((col: string) => col !== undefined && col !== '' && col !== null);

            // Gets every editable cell
            this.editableCells = [];
            this.displayedColumns.forEach((col: IColumnsTable) => {
                if (col.type === 'input') {
                    this.editableCells.push(col.id);
                }
            });

            this.sortRegisters();

            if (page === this.claims) {
                this.setColumnsTabs(resp.data, id);
            }
        });
        this.modifiedDataSource = data.data;
        this.pageIndex = data.page.number - 1;
        this.pageSize = data.page.size;
        this.totalRegisters = data.page.totalElements;
        if (page === this.claims) {
            this.originalDataSource = this.modifiedDataSource;
        }
    }

    // Sets headers tabs in their own variables
    private setColumnsTabs(headers: any, id: string): void {
        this.headersUniqueFields = headers.filter((x: any) => x.name === `${id}_uniqueFields`)[0].headers;
        this.headersCommunicationParams = headers.filter((x: any) => x.name === `${id}_params`)[0].headers;
        this.headersAttachedDocumentation = headers.filter((x: any) => x.name === `${id}_doc`)[0].headers;
        this.headersSteps = headers.filter((x: any) => x.name === `${id}_steps`)[0].headers;
    }

    // Unifies all data sources tabs to use ngFor
    private getDataSourceTabs(): { label: string; headers: IColumnsTable[]; data: any[] }[] {
        return [
            {
                label: this.tabsTitles.COMMUNICATION_PARAMETERS,
                headers: this.headersCommunicationParams,
                data: this.dataSourceCommunicationParams,
            },
            {
                label: this.tabsTitles.ATTACHED_DOCUMENTATION,
                headers: this.headersAttachedDocumentation,
                data: this.dataSourceAttachedDocumentation,
            },
            { label: this.tabsTitles.STEPS, headers: this.headersSteps, data: this.dataSourceSteps },
        ];
    }

    // Sets all the data that will be used in the tab system, leaving out the properties that come from the back but are never used
    private setDataSourcesTabs(cups: string, requestCode: string): void {
        // Filters by cups and request code
        const register: IClaims | undefined = this.originalDataSource.find(
            (x: IClaims) => x.cups === cups && x.requestCode === requestCode
        );

        if (register) {
            // Sets only useful data of 'unique fields' tab
            const {
                cups,
                requestCode,
                type,
                subtype,
                status,
                resultDetail,
                acceptDate,
                denyDate,
                fraudAnomalyExpNumber,
                attackExpNumber,
                sinceDate,
                untilDate,
            } = register;
            this.dataSourceUniqueFields = {
                cups,
                requestCode,
                type,
                subtype,
                status,
                resultDetail,
                acceptDate,
                denyDate,
                fraudAnomalyExpNumber,
                attackExpNumber,
                sinceDate,
                untilDate,
            };
            // Sets only useful data of 'communication parameters' tab
            if (register.claimsCommunicationParameters) {
                this.dataSourceCommunicationParams = [];
                if (register.claimsCommunicationParameters.length) {
                    register.claimsCommunicationParameters.forEach((params: ICommunicationParameters) => {
                        const { measurePoint, linkAddress, lineNumber, accessKey, telphMeasure, ipAddress, linkPort } =
                            params;
                        const functionName = params.function; // Name 'function' is a reserved word
                        this.dataSourceCommunicationParams.push([
                            measurePoint,
                            functionName as 'function',
                            linkAddress,
                            lineNumber,
                            accessKey,
                            telphMeasure,
                            ipAddress,
                            linkPort,
                        ]);
                    });
                }
            }
            // Sets only useful data of 'attached document' tab
            if (register.claimsDocument) {
                this.dataSourceAttachedDocumentation = [];
                if (register.claimsDocument.length) {
                    register.claimsDocument.forEach((doc: IClaimsAttachedDocument) => {
                        const { documentType, urlAddress } = doc;
                        this.dataSourceAttachedDocumentation.push([documentType, urlAddress]);
                    });
                }
            }
            // Sets only useful data of 'steps' tab
            if (register.claimsStep) {
                this.dataSourceSteps = [];
                if (register.claimsStep.length) {
                    register.claimsStep.forEach((step: IStep) => {
                        const { steps, comments, modifDate } = step;
                        this.dataSourceSteps.push([steps, comments, modifDate]);
                    });
                }
            }
            // Unifies all dataSources to use ngFor
            this.tableTabsSource = this.getDataSourceTabs();
        }
    }

    // Only for claims page, sets tabs system as visible and calls a method to set their data sources.
    protected showTabs(register: IKeyValue[]): void {
        if (
            this.claimCode &&
            this.claimCode !== '' &&
            register.length > 1 &&
            typeof register[0].value === 'string' &&
            typeof register[1].value === 'string'
        ) {
            this.setDataSourcesTabs(register[0].value, register[1].value);
            this.isTabSystemVisible = true;
            window.scroll({ top: 500, left: 0, behavior: 'smooth' });
        }
    }

    // Gets a header label of unique fields tab by the key
    protected getLabelUniqueFieldsByKey(key: any): any {
        if (typeof key === 'string') {
            return this.headersUniqueFields.filter((header: IColumnsTable) => {
                return header.id === key;
            })[0]?.label;
        }
    }

    // Checks if value = null or undefined or '' to return a text, otherwise returns value.
    protected checkValue(value: any): any {
        if (
            value === null ||
            value === undefined ||
            value === '' ||
            (typeof value === 'object' && Array.isArray(value))
        ) {
            return this._translateService.instant('PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA');
        }
        return value;
    }

    // Checks if value = null or undefined or '' to return a class to apply a gray color.
    protected checkClass(value: any): string {
        return value === null || value === undefined || value === '' ? 'noData' : '';
    }

    // Opens edit modal and sets the new data
    protected editRow(registers: IKeyValue[]): void {
        // Gets only headers of editable cells
        let headersCopy: IColumnsTable[] = [];
        this.displayedColumns.forEach((element: IColumnsTable) => {
            if (this.editableCells.includes(element.id)) {
                headersCopy.push(element);
            }
        });

        // Gets only editable cells keyvalue pairs
        let registerCopy: any = {};
        registers.forEach((element: IKeyValue) => {
            if (this.editableCells.includes(element.id)) {
                registerCopy[element.id] = element.value;
            }
        });

        // Sets data to show on edit modal when is opened
        const dialogRef = this.editModalComponent.open(EditModalComponent, {
            data: {
                header: headersCopy,
                filter: 'text',
                value: registerCopy,
                name: 'puntoFrontera',
            },
        });
        // Saves edited cells in the database
        dialogRef.afterClosed().subscribe((result) => {
            if (result?.resp) {
                this.openModal(true);
                // Gets edited data to build the body which will be sent to the PUT request
                let body: any = {};
                Object.keys(result.data).forEach((element: string) => {
                    if (this.editableCells.includes(element)) {
                        body[element] = result.data[element];
                    }
                });
                // Once the PUT request has finished the task, gets objections registers again and refresh the table
                const idObjection = registers.filter((x: IKeyValue) => x.id === 'id')[0].value;
                if (idObjection && typeof idObjection === 'number') {
                    this._maestroService.setObjectionDetail(idObjection, body).subscribe((resp) => {
                        this.getRegisters(this.objections);
                        setTimeout(() => {
                            this.openModal(false);
                        }, 3000);
                    });
                }
            }
        });
    }

    // Closes edit modal from the 'X' button
    protected close(): void {
        this.editModalComponent.closeAll();
    }

    transformDate(date: any) {
        const datePipe = new DatePipe('en-US');
        let dates = new Date(date);
        let formatDate;
        if (isNaN(dates.getTime()) || date === null) {
            formatDate = '-';
        } else {
            formatDate = datePipe.transform(
                dates,
                this._headerTitleService.currentLang === 'en' ? 'YYYY-MM-DD' : 'DD-MM-YYYY'
            );
        }
        return formatDate;
    }

    // After a page change event, sets new pagination properties and calls setDataAfterPagination event
    protected pageChange($event: any): void {
        this.pageIndex = $event.pageIndex;
        this.pageSize = $event.pageSize;

        if (this.title === this.claims) {
            this._maestroService
                .getClaimDetails(this.claimCode, $event.pageIndex + 1, this.pageSize)
                .subscribe((data: IResponseClaimsOrObjections) => {
                    this.setDataAfterPagination(data);
                });
        } else if (this.title === this.objections) {
            this._maestroService
                .getObjectionDetails(this.objectionCode, $event.pageIndex + 1, this.pageSize)
                .subscribe((data: IResponseClaimsOrObjections) => {
                    this.setDataAfterPagination(data);
                });
        }
    }

    // Sets new dataSource after a page change event
    private setDataAfterPagination(data: IResponseClaimsOrObjections): void {
        this.modifiedDataSource = data.data;
        this.originalDataSource = this.modifiedDataSource;
        this.sortRegisters();
        this.totalRegisters = data.page.totalElements;
    }

    protected openModal(operation: boolean): void {
        this.isModalOpened = operation;
    }
}
