import 'zone.js/plugins/zone-error';

export const environment = {
    production: false,
    isMockActive: false,
    apiUrl: 'https://lb-internal.seor-dev.eu-west-1-aws.cloud-acc.net',
    urlCognito: 'https://dev-en-seor-seor.auth.eu-west-1.amazoncognito.com',
    awsClientId: 'vljn85o2tg5g4ut5cae5pgs2h',
    // awsRedirectURI: 'https://cdn-seor.seor-dev.eu-west-1-aws.cloud-acc.net',
    awsRedirectURI: 'https://dev-emis.acciona.com',
    awsconfig: {​​
        aws_project_region: 'eu-west-1',
        aws_cognito_region: 'eu-west-1',
        aws_user_pools_id: 'eu-west-1_ZFHApBceX',
        aws_user_pools_web_client_id: 'vljn85o2tg5g4ut5cae5pgs2h',
        oauth: {
            domain: 'dev-en-seor-seor.auth.eu-west-1.amazoncognito.com',
            response_type:'token',
            scope: ['email', 'profile', 'openid'],
            // redirectSignIn: 'https://cdn-seor.seor-dev.eu-west-1-aws.cloud-acc.net/home',
            // redirectSignOut: 'https://cdn-seor.seor-dev.eu-west-1-aws.cloud-acc.net/signout',
            redirectSignIn: 'https://dev-emis.acciona.com/home',
            redirectSignOut: 'https://dev-emis.acciona.com/signout',
            responseType: 'token'
        }
    }
};
