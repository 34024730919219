<div class="containerError">
    <img
        src="assets/img/acciona-transparent.png"
        alt="Logo Acciona"
        class="logo"
    />
    <p>{{ 'PAGE.PERMISSIONS.INVALID_USER_OR_PERMISSIONS' | translate }}</p>
    <button (click)="returnHome()" class="button_login">
        {{ 'PAGE.ACTIONS.RETURN_TO_LOGIN' | translate }}
    </button>
</div>
