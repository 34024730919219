<mat-tab-group dynamicHeight [backgroundColor]="'warn'">
    <mat-tab class="detalleContratosTab" label="{{ 'PAGE.MASTER.MASTER_CRM.CONTRACTS.CONTRACT_DETAILS' | translate }}">
        <div>
            <div class="detallesCabecera">
                <span> {{ 'PAGE.MASTER.MASTER_CRM.CONTRACTS.CONTRACT_DETAILS' | translate }} </span>
            </div>
            <div class="container">
                <ng-container *ngFor="let detalle of detailsFromBack; let i = index">
                    <ng-container *ngFor="let item of detalle | keyvalue">
                        <ng-container *ngIf="item.key === 'initDate' || item.key === 'initEffectiveDate'">
                            <ng-container
                                *ngTemplateOutlet="labelValuePair; context: { $implicit: item }"
                            ></ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let item of detalle | keyvalue">
                        <ng-container *ngIf="item.key === 'finalDate' || item.key === 'finalEffectiveDate'">
                            <ng-container
                                *ngTemplateOutlet="labelValuePair; context: { $implicit: item }"
                            ></ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let item of detalle | keyvalue">
                        <ng-container
                            *ngIf="
                                item.key !== 'initDate' &&
                                item.key !== 'initEffectiveDate' &&
                                item.key !== 'finalDate' &&
                                item.key !== 'finalEffectiveDate'
                            "
                        >
                            <ng-container
                                *ngTemplateOutlet="labelValuePair; context: { $implicit: item }"
                            ></ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #labelValuePair let-item>
                        <div class="detallesContainer">
                            <div class="detalleLabel">{{ getLabelContractDetails(item.key) }}</div>
                            <div class="detalleValue">{{ getValue(item) }}</div>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="{{ 'PAGE.MASTER.MASTER_CRM.CONTRACTS.INSTALLATION' | translate }}">
        <div>
            <div class="detallesCabecera">
                <span> {{ 'PAGE.MASTER.MASTER_CRM.CONTRACTS.INSTALLATION' | translate }} </span>
            </div>
            <div class="container" *ngFor="let instalacion of installationsFromBack; let i = index">
                <div class="detallesContainer" *ngFor="let item of instalacion | keyvalue">
                    <div class="detalleLabel">{{ getLabelInstallations(item.key) }}</div>
                    <div class="detalleValue">{{ getValue(item) }}</div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="{{ 'PAGE.MASTER.MASTER_CRM.CONTRACTS.PRICES' | translate }}">
        <div class="divDesglosePrecios">
            <div class="detallesCabecera">
                <span> {{ 'PAGE.MASTER.MASTER_CRM.CONTRACTS.PRICES' | translate }} </span>
            </div>
            <div class="divisor"></div>
            <div class="preciosContainer">
                <div class="tableContainer">
                    <div class="table-header-info">
                        <span
                            >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                            {{ totalRegistersPrices | numberFormat : _headerTitleService.currentLang }}</span
                        >
                    </div>
                    <div class="buttonsContainer">
                        <button mat-button (click)="openModalExcel('prices')">
                            <span class="text">{{ 'PAGE.ACTIONS.EXCEL_EXPORT' | translate }}</span>
                            <span class="icon material-symbols-outlined">save_as </span>
                        </button>
                        <button class="buttonColumns" [ngClass]="hidden === true ? 'mat' : 'mat_none'">
                            <!-- INICIO>>> Botón mostrar/ocultar columnas -->
                            <mat-form-field [hidden]="hidden">
                                <mat-select
                                    #selectColumns
                                    name="selectColumns"
                                    id="selectColumns"
                                    [value]="dataHeadersValuesSelected[TabDetails.TAB_PRICES]"
                                    multiple
                                >
                                    <mat-option
                                        enableCheckAll="true"
                                        [matColumnDef]="column.id"
                                        [value]="column"
                                        (click)="checkvalue(selectColumns.value, TabDetails.TAB_PRICES)"
                                        *ngFor="let column of filterDataHeader(TabDetails.TAB_PRICES)"
                                        >{{ column.label }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                            <div class="divColumns" (click)="hideClick()">
                                <span class="text spanColumn" *ngIf="!element">{{
                                    'PAGE.ACTIONS.COLUMNS' | translate
                                }}</span>
                                <app-base-icon class="padding_button" iconName="view_column"></app-base-icon>
                            </div>
                        </button>
                        <!-- FIN>>> Botón mostrar/ocultar columnas -->
                    </div>
                </div>
                <div class="fix-table">
                    <table mat-table [dataSource]="pricesFromBack" multiTemplateDataRows matSort>
                        <ng-container
                            style="max-width: fit-content"
                            [matColumnDef]="column.id"
                            *ngFor="let column of this.dataHeaders[TabDetails.TAB_PRICES]"
                        >
                            <!-- Header TH -->
                            <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                                <div class="header-table d-flex">
                                    {{ column.label }}
                                </div>
                            </th>
                            <!-- Element TD -->
                            <td mat-cell *matCellDef="let element">
                                <!-- Element TD Text -->
                                <div *ngIf="column.type === 'text'">
                                    <span>{{ checkAndReturnColumns(element, column.id) }}</span>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="columns[TabDetails.TAB_PRICES]"></tr>
                        <tr mat-row *matRowDef="let row; columns: columns[TabDetails.TAB_PRICES]"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="9999">
                                {{ 'PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA' | translate }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="divForPaginator">
            <mat-paginator
                [showFirstLastButtons]="true"
                [length]="totalRegistersPrices"
                [pageSize]="pageSizePrices"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChangePrices($event)"
                [pageIndex]="numPage"
                aria-label="Select page"
            >
            </mat-paginator>
        </div>
    </mat-tab>
    <mat-tab label="{{ 'PAGE.MASTER.MASTER_CRM.CONTRACTS.CONTRACT_CONCEPTS' | translate }}">
        <div class="divConceptosContrato">
            <div class="detallesCabecera">
                <span> {{ 'PAGE.MASTER.MASTER_CRM.CONTRACTS.CONTRACT_CONCEPTS' | translate }} </span>
            </div>
            <div class="divisor"></div>
            <div class="contractConceptsContainer">
                <div class="tableContainer">
                    <div class="table-header-info">
                        <span
                            >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                            {{ totalRegistersContractConcepts | numberFormat : _headerTitleService.currentLang }}</span
                        >
                    </div>
                    <div class="buttonsContainer">
                        <button mat-button (click)="openModalExcel('contractConcepts')">
                            <span class="text">{{ 'PAGE.ACTIONS.EXCEL_EXPORT' | translate }}</span>
                            <span class="icon material-symbols-outlined">save_as </span>
                        </button>
                        <button class="buttonColumns" [ngClass]="hidden === true ? 'mat' : 'mat_none'">
                            <!-- INICIO>>> Botón mostrar/ocultar columnas -->
                            <mat-form-field [hidden]="hidden">
                                <mat-select
                                    #selectColumnsContractConcepts
                                    name="selectColumnsContractConcepts"
                                    id="selectColumnsContractConcepts"
                                    [value]="dataHeadersValuesSelected[TabDetails.TAB_CONTRACT_CONCEPTS]"
                                    multiple
                                >
                                    <mat-option
                                        enableCheckAll="true"
                                        [matColumnDef]="column.id"
                                        [value]="column"
                                        (click)="
                                            checkvalue(
                                                selectColumnsContractConcepts.value,
                                                TabDetails.TAB_CONTRACT_CONCEPTS
                                            )
                                        "
                                        *ngFor="let column of filterDataHeader(TabDetails.TAB_CONTRACT_CONCEPTS)"
                                        >{{ column.label }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                            <div class="divColumns" (click)="hideClick()">
                                <span class="text spanColumn" *ngIf="!element">{{
                                    'PAGE.ACTIONS.COLUMNS' | translate
                                }}</span>
                                <app-base-icon class="padding_button" iconName="view_column"></app-base-icon>
                            </div>
                        </button>
                        <!-- FIN>>> Botón mostrar/ocultar columnas -->
                    </div>
                </div>
                <table mat-table [dataSource]="contractConceptsFromBack" multiTemplateDataRows matSort>
                    <ng-container
                        style="max-width: fit-content"
                        [matColumnDef]="column.id"
                        *ngFor="let column of this.dataHeaders[TabDetails.TAB_CONTRACT_CONCEPTS]"
                    >
                        <!-- Header TH -->
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                            <div class="header-table d-flex">
                                {{ column.label }}
                            </div>
                        </th>
                        <!-- Element TD -->
                        <td mat-cell *matCellDef="let element">
                            <!-- Element TD Text -->
                            <div *ngIf="column.type === 'text' && column.id">
                                <span>{{ checkAndReturnColumns(element, column.id) }}</span>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns[TabDetails.TAB_CONTRACT_CONCEPTS]"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns[TabDetails.TAB_CONTRACT_CONCEPTS]"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9999">
                            {{ 'PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA' | translate }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="divForPaginator">
            <mat-paginator
                [showFirstLastButtons]="true"
                [length]="totalRegistersContractConcepts"
                [pageSize]="pageSizeContractConcepts"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChangeContractConcepts($event)"
            >
            </mat-paginator>
        </div>
    </mat-tab>
    <mat-tab label="{{ 'PAGE.MASTER.MASTER_CRM.CONTRACTS.CLOSURES' | translate }}">
        <div class="divClosures">
            <div class="detallesCabecera">
                <span> {{ 'PAGE.MASTER.MASTER_CRM.CONTRACTS.CLOSURES' | translate }} </span>
            </div>
            <div class="divisor"></div>
            <div class="closuresContainer">
                <div class="tableContainer">
                    <div class="table-header-info">
                        <span
                            >{{ 'PAGE.ACTIONS.SHOWING_RESULTS' | translate }}
                            {{ totalRegistersClosures | numberFormat : _headerTitleService.currentLang }}</span
                        >
                    </div>
                    <div class="buttonsContainer">
                        <button mat-button (click)="openModalExcel('closures')">
                            <span class="text">{{ 'PAGE.ACTIONS.EXCEL_EXPORT' | translate }}</span>
                            <span class="icon material-symbols-outlined">save_as </span>
                        </button>
                        <button class="buttonColumns" [ngClass]="hidden === true ? 'mat' : 'mat_none'">
                            <!-- INICIO>>> Botón mostrar/ocultar columnas -->
                            <mat-form-field [hidden]="hidden">
                                <mat-select
                                    #selectColumnsClosures
                                    name="selectColumnsClosures"
                                    id="selectColumnsClosures"
                                    [value]="dataHeadersValuesSelected[TabDetails.TAB_CLOSURES]"
                                    multiple
                                >
                                    <mat-option
                                        enableCheckAll="true"
                                        [matColumnDef]="column.id"
                                        [value]="column"
                                        (click)="checkvalue(selectColumnsClosures.value, TabDetails.TAB_CLOSURES)"
                                        *ngFor="let column of filterDataHeader(TabDetails.TAB_CLOSURES)"
                                        >{{ column.label }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                            <div class="divColumns" (click)="hideClick()">
                                <span class="text spanColumn" *ngIf="!element">{{
                                    'PAGE.ACTIONS.COLUMNS' | translate
                                }}</span>
                                <app-base-icon class="padding_button" iconName="view_column"></app-base-icon>
                            </div>
                        </button>
                        <!-- FIN>>> Botón mostrar/ocultar columnas -->
                    </div>
                </div>
                <table mat-table [dataSource]="closuresFromBack" multiTemplateDataRows matSort>
                    <ng-container
                        style="max-width: fit-content"
                        [matColumnDef]="column.id"
                        *ngFor="let column of this.dataHeaders[TabDetails.TAB_CLOSURES]"
                    >
                        <!-- Header TH -->
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [class.noSort]="!column.sort">
                            <div class="header-table d-flex">
                                {{ column.label }}
                            </div>
                        </th>
                        <!-- Element TD -->
                        <td mat-cell *matCellDef="let element">
                            <!-- Element TD Text -->
                            <div *ngIf="column.type === 'text'">
                                <span>{{ checkAndReturnColumns(element, column.id) }}</span>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns[TabDetails.TAB_CLOSURES]"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns[TabDetails.TAB_CLOSURES]"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9999">
                            {{ 'PAGE.EMPTY_TABLE.NOT_AVAILABLE_DATA' | translate }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="divForPaginator">
            <mat-paginator
                [showFirstLastButtons]="true"
                [length]="totalRegistersClosures"
                [pageSize]="pageSizeClosures"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChangeClosures($event)"
            >
            </mat-paginator>
        </div>
    </mat-tab>
</mat-tab-group>
