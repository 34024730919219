import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TablaMaestroComponent } from '../tabla-maestro.component';
import { KeysMaestro, MaestrosCRM, MaestrosAWS, PuntoFronteraBooleans } from '../../../../../shared/enums/maestros';
import { MaestroService } from '../../../services/maestro.service';
import {
    BoundaryPointsToSend,
    FestivesToSend,
    MeasurePointToSend,
    ParametersToSend,
    PriceRegulatedToSend,
} from 'src/app/shared/interfaces/maestros-interface';
import * as moment from 'moment';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'edit-modal',
    templateUrl: './edit-modal.component.html',
    styleUrls: ['./edit-modal.component.scss'],
})
export class EditModalComponent {
    copyData: any;
    copyDataMapped: any;
    isPuntoMedida: boolean = false;
    isFestivos: boolean = false;
    isPuntoFrontera: boolean = false;
    isPreciosRegulados: boolean = false;
    isParametros: boolean = false;
    isAutonomic: boolean = false;

    festivesToSend: FestivesToSend = { festiveDate: '', festiveDescription: '', festiveType: '', regionCode: '' };
    measurePointToSend: MeasurePointToSend = { cecoerActiveHub: false };
    boundaryPointsToSend?: BoundaryPointsToSend;
    priceRegulatedToSend?: PriceRegulatedToSend;
    parametersToSend?: ParametersToSend;

    inputIdNotShowedPuntoFrontera: string[] = [
        'description',
        'boundaryPointCode',
        'objections',
        'claims',
        'prelations',
    ];
    inputIdNotShowedPriceRegulated: string[] = ['code', 'rate'];
    inputIdNotShowedGeneral: string[] = [
        'creationDate',
        'updateUser',
        'creationUser',
        'updateDate',
        'lastModify',
        'modificationUser',
        'edit',
    ];
    diasDiferencia: number;
    dateInit: Date | undefined;
    dateEnd: Date;
    isIncorrectDate: boolean = false;

    constructor(
        public DialogEdit: MatDialogRef<TablaMaestroComponent>,
        protected readonly _headerTitleService: HeaderTitleService,
        private _maestroService: MaestroService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.copyData = JSON.parse(JSON.stringify(data));
        this.isIncorrectDate = false;
        this.copyDataMapped = this.copyData.header.map((data: any) => {
            let value;
            const filterType = data.filter.toLowerCase();
            if (filterType === 'date') {
                value = new Date(this.copyData.value[data.id]);
            } else if (filterType === 'select') {
                if (data.id === 'regionCode') {
                    value = data?.data?.find(
                        (d: any) => (d.value?.toString() as string)?.padStart(2, '0') === this.copyData?.value[data.id]
                    );
                } else {
                    value = data?.data?.find((d: any) => d.id === this.copyData.value[data.id]);
                }
            } else {
                value = this.copyData.value[data.id];
            }
            return { ...data, value };
        });

        if (this.data.name === 'puntoMedida') {
            this.isPuntoMedida = true;
            this.measurePointToSend = { cecoerActiveHub: this.copyData.value.cecoerActiveHub };
        }

        if (this.data.name === 'festivos') {
            this.isFestivos = true;
            this.copyDataMapped.forEach((element: any) => {
                if (element.id === 'festiveType') {
                    let indexElectricity: number = element.data.findIndex((item: any) => item.name === 'Electricos');
                    if (indexElectricity >= 0) {
                        element.data.splice(indexElectricity, 1);
                    }

                    if (element.value.id === 'Autonomicos') {
                        this.isAutonomic = true;
                    } else {
                        this.isAutonomic = false;
                    }
                }
            });
            this.festivesToSend = {
                festiveDate: this.copyData.value.festiveDate,
                festiveDescription: this.copyData.value.festiveDescription,
                festiveType: this.copyData.value.festiveType,
                regionCode: this.copyData.value.regionCode,
            };
        }

        if (this.data.name === 'puntoFrontera') {
            this.isPuntoFrontera = true;
            this.boundaryPointsToSend = {
                boundaryPointCode: this.copyData.value.boundaryPointCode,
                description: this.copyData.value.description,
                initValidityDate: this.copyData.value.initValidityDate,
                instalationCode: this.copyData.value.boundaryPointCode,
                locationCommentsBP: this.copyData.value.locationCommentsBP,
                finalValidityDate: this.copyData.value.finalValidityDate,
                offeringUnitCode: this.copyData.value.offeringUnitCode,
                formulaInEnergy: this.copyData.value.formulaInEnergy,
                booleanFormulaInEnergy: '0',
                formulaOutEnergy: this.copyData.value.formulaOutEnergy,
                booleanFormulaOutEnergy: '0',
                formulaReactEnergyQ1: this.copyData.value.formulaReactEnergyQ1,
                formulaReactEnergyQ2: this.copyData.value.formulaReactEnergyQ2,
                formulaReactEnergyQ3: this.copyData.value.formulaReactEnergyQ3,
                formulaReactEnergyQ4: this.copyData.value.formulaReactEnergyQ4,
                booleanFormulaReactEnergyQ1: '0',
                booleanFormulaReactEnergyQ2: '0',
                booleanFormulaReactEnergyQ3: '0',
                booleanFormulaReactEnergyQ4: '0',
                correctingRateCloseFileSecondConcentrator:
                    this.copyData.value.correctingRateCloseFileSecondConcentrator,
                distributorCode: this.copyData.value.distributorCode,
                clientCode: this.copyData.value.clientCode,
                tensionLevelCode: this.copyData.value.tensionLevelCode,
                accessRateCode: this.copyData.value.accessRateCode,
                hourDiscriminationCode: this.copyData.value.hourDiscriminationCode,
                measurePointTypeCode: this.copyData.value.measurePointTypeCode,
                provinceSubsystemCode: this.copyData.value.provinceSubsystemCode,
                agreeLossPercentaje: this.copyData.value.agreeLossPercentaje,
                creationUser: this.copyData.value.creationUser,
                creationDate: this.copyData.value.creationDate,
                updateUser: this.copyData.value.updateUser,
                updateDate: this.copyData.value.updateDate,
            };
        }

        if (this.data.name === 'parametros') {
            this.isParametros = true;
            this.parametersToSend = {
                description: this.copyData.value.description,
                value: this.copyData.value.area,
                area: this.copyData.value.area,
            };
        }

        if (this.data.name === 'preciosRegulados') {
            this.isPreciosRegulados = true;
            this.priceRegulatedToSend = {
                rate: this.copyData.value.rate,
                code: this.copyData.value.code,
                initialDate: this.copyData.value.initialDate,
                endDate: this.copyData.value.endDate,
                valueP1: this.copyData.value.valueP1,
                valueP2: this.copyData.value.valueP2,
                valueP3: this.copyData.value.valueP3,
                valueP4: this.copyData.value.valueP4,
                valueP5: this.copyData.value.valueP5,
                valueP6: this.copyData.value.valueP6,
            };
        }
    }

    get KeysMaestro() {
        return KeysMaestro;
    }

    close(resp: boolean) {
        let newData;
        switch (this.data.name) {
            case 'festivos':
                if (this.festivesToSend.festiveType === 'Nacionales' && this.festivesToSend.regionCode) {
                    delete this.festivesToSend.regionCode;
                }
                newData = { resp: resp, data: this.festivesToSend, festiveId: this.copyData.value.festiveId };
                break;
            case 'puntoMedida':
                newData = {
                    resp: resp,
                    data: this.measurePointToSend,
                    cups: this.copyData.value.cups,
                    cupsMP: this.copyData.value.cupsMP,
                };
                break;
            case 'puntoFrontera':
                newData = { resp: resp, data: this.boundaryPointsToSend };
                break;
            case 'preciosRegulados':
                newData = { resp: resp, data: this.priceRegulatedToSend };
                break;
            case 'parametros':
                newData = { resp: resp, data: this.parametersToSend, code: this.copyData.value.code };
                break;
            default:
                break;
        }
        this.DialogEdit.close(newData);
    }

    valueChange(event: any) {
        this.isIncorrectDate = false;
        if (this.data.name === MaestrosAWS.PUNTO_FRONTERA || this.data.name === MaestrosAWS.PRECIOS_REGULADOS) {
            if (event.name === 'initValidityDate' || event.name === 'initialDate') {
                if (event.name === 'initValidityDate') {
                    this.dateEnd = this.boundaryPointsToSend!.finalValidityDate;
                    this.dateInit = event.newValue;
                } else if (this.priceRegulatedToSend !== undefined && event.name === 'initialDate') {
                    this.dateInit = this.priceRegulatedToSend.initialDate;
                }
            } else if (event.name === 'finalValidityDate' || event.name === 'endDate') {
                if (event.name === 'finalValidityDate') {
                    this.dateInit = this.boundaryPointsToSend!.initValidityDate;
                } else if (this.priceRegulatedToSend !== undefined && event.name === 'endDate') {
                    this.dateInit = this.priceRegulatedToSend.initialDate;
                }
                this.dateEnd = event.newValue;
            }
            if (
                (this.boundaryPointsToSend !== undefined || this.priceRegulatedToSend !== undefined) &&
                this.dateInit !== undefined &&
                this.dateEnd !== undefined
            ) {
                let fecha1 = moment(this.dateInit);
                let fecha2 = moment(this.dateEnd);

                (this.diasDiferencia = fecha2.diff(fecha1, 'days')), ' dias de diferencia';
                if (this.diasDiferencia < 0) {
                    this.isIncorrectDate = true;
                } else {
                    this.isIncorrectDate = false;
                }
            }
        }
        let eventValue = event.newValue || event.eventValue || event.value;
        if (event.name !== undefined) {
            switch (this.data.name) {
                case MaestrosAWS.FESTIVOS:
                    if (event.name === 'festiveType' && event.newValue === 'Autonomicos') {
                        this.isAutonomic = true;
                    } else if (event.name === 'festiveType' && event.newValue !== 'Autonomicos') {
                        this.isAutonomic = false;
                    }
                    (this.festivesToSend as any)[event.name] = eventValue;
                    break;
                case MaestrosAWS.PRECIOS_REGULADOS:
                    (this.priceRegulatedToSend as any)[event.name] = eventValue;
                    break;
                case MaestrosCRM.PUNTO_MEDIDA:
                    let res = null;
                    if (event.name === 'connectionIssue' || event.name === 'cecoerActiveHub') {
                        res = event.eventValue.id;
                    } else {
                        res = eventValue;
                    }
                    (this.measurePointToSend as any)[event.name] = res;
                    break;
                case MaestrosAWS.PUNTO_FRONTERA:
                    if (event.name === PuntoFronteraBooleans.BOOLEAN_Q1) {
                        (this.boundaryPointsToSend as any)[PuntoFronteraBooleans.BOOLEAN_Q1] = '1';
                    } else if (event.name === PuntoFronteraBooleans.BOOLEAN_Q2) {
                        (this.boundaryPointsToSend as any)[PuntoFronteraBooleans.BOOLEAN_Q2] = '1';
                    } else if (event.name === PuntoFronteraBooleans.BOOLEAN_Q3) {
                        (this.boundaryPointsToSend as any)[PuntoFronteraBooleans.BOOLEAN_Q3] = '1';
                    } else if (event.name === PuntoFronteraBooleans.BOOLEAN_Q4) {
                        (this.boundaryPointsToSend as any)[PuntoFronteraBooleans.BOOLEAN_Q4] = '1';
                    } else if (event.name === PuntoFronteraBooleans.BOOLEAN_IN_FORMULA) {
                        (this.boundaryPointsToSend as any)[PuntoFronteraBooleans.BOOLEAN_IN_FORMULA] = '1';
                    } else if (event.name === PuntoFronteraBooleans.BOOLEAN_OUT_FORMULA) {
                        (this.boundaryPointsToSend as any)[PuntoFronteraBooleans.BOOLEAN_OUT_FORMULA] = '1';
                    } else {
                        (this.boundaryPointsToSend as any)[event.name] = eventValue;
                    }
                    break;
                case MaestrosAWS.PARAMETROS:
                    (this.parametersToSend as any)[event.name] = eventValue;
                    break;
                default:
                    break;
            }
        }
    }
}
