import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class LoginService {
    private allFilters$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public readonly allFilters: Observable<string> = this.allFilters$.asObservable();
    private authUser: any;
    constructor(private _http: HttpClient, private _router: Router) {}

    login() {
        return new Promise((resolve, reject) => {
            if (this.getTokenSession()) {
                resolve(true);
            } else {
                const token = this.getTokenSession();
                if (!token) {
                    this._router.navigate(['login']);
                    resolve(false);
                } else {
                    resolve(true);
                }
            }
        });
    }

    isAuthenticated() {
        return this.getTokenSession() ? true : false;
    }

    getTokenAccessSession(): any {
        const name =
            'CognitoIdentityServiceProvider.' +
            environment.awsClientId +
            '.' +
            this.getUserNameSession() +
            '.accessToken';
        return localStorage.getItem(name);
    }

    getTokenSession() {
        const name =
            'CognitoIdentityServiceProvider.' + environment.awsClientId + '.' + this.getUserNameSession() + '.idToken';
        return localStorage.getItem(name);
    }

    setTokenSession(token: any) {
        const name =
            'CognitoIdentityServiceProvider.' + environment.awsClientId + '.' + this.getUserNameSession() + '.idToken';
        return localStorage.setItem(name, token);
    }

    getUserNameSession(): any {
        return localStorage.getItem('CognitoIdentityServiceProvider.' + environment.awsClientId + '.LastAuthUser');
    }

    setUserActive(userActive: any) {
        return sessionStorage.setItem('userActive', userActive);
    }

    getUserActive() {
        return sessionStorage.getItem('userActive');
    }

    setUserActiveRoles(userActive: any) {
        return sessionStorage.setItem('userActiveRole', userActive);
    }

    getUserActiveRoles() {
        return sessionStorage.getItem('userActiveRole');
    }

    setRolesTotal(roles: any) {
        return sessionStorage.setItem('roles', roles);
    }

    getRolesTotal() {
        return sessionStorage.getItem('roles');
    }

    setFiltersTotal(filters: any) {
        this.allFilters$.next(filters);
        return sessionStorage.setItem('filters', filters);
    }

    getFiltersTotal() {
        return sessionStorage.getItem('filters');
    }

    setHeaderSession(header: any) {
        return sessionStorage.setItem('headers', header);
    }

    getHeaderSession() {
        return sessionStorage.getItem('headers');
    }

    deleteSession(item: string) {
        return sessionStorage.removeItem(item);
    }

    removeUserSession() {
        sessionStorage.removeItem('userToken');
        sessionStorage.removeItem('userSession');
        sessionStorage.removeItem('userActive');
    }
}
