<div class="container">
    <!-- Filtro SISTEMAS -->
    <mat-form-field appearance="standard" class="select">
        <mat-label>{{ 'PAGE.LOADS.SYSTEMS' | translate }}</mat-label>
        <mat-select
            #matRefSystem
            placeholder="{{ 'PAGE.LOADS.SELECT_SYSTEM' | translate }}"
            [value]="selectSystems.length === 1 ? selectSystems[0].name : ''"
        >
            <ng-container *ngIf="selectSystems.length > 1 ? selectSystems[0].name : ''">
                <mat-option [value]="" (click)="onSelectChangeTypes('reset')" id="0">
                    {{ 'PAGE.LOADS.ALL' | translate }}
                </mat-option>
            </ng-container>
            <ng-container *ngFor="let system of selectSystems">
                <mat-option [value]="system.name" (click)="onSelectChangeTypes(system.name)" id="{{ system.id }}">
                    {{ 'PAGE.LOADS.' + system.translation | translate }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <!-- Filtro TIPO FICHEROS -->
    <mat-form-field appearance="standard" class="select">
        <mat-label>Tipo de fichero</mat-label>
        <mat-select
            #matRefFileType
            [disabled]="disabled"
            placeholder="Tipo fichero"
            (selectSelected)="checkValueSelects($event)"
            (selectionChange)="restarValueFileFilter($event, 2, 'file')"
        >
            <mat-option
                *ngFor="let opcion of filteredFilesList"
                [value]="opcion.id"
                id="valueSelect"
                name="valueSelect"
            >
                {{ opcion.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="subfilter" *ngFor="let filtro of subfilters; let i = index">
        <!-- Filtro FECHA -->
        <form>
            <app-filtro-date
                *ngIf="filtro.type === 'Date' && showSelect"
                [name]="filtro.name"
                (select)="receiveDate($event, 1, '')"
            ></app-filtro-date>
        </form>
        <!-- Filtro ESTADOS -->
        <form>
            <app-filtro-select
                *ngIf="
                    filtro.type === 'Select' &&
                    filtro.name == 'Estados' &&
                    checkConditions(filtro.displayConditions) &&
                    showSelect
                "
                [filtroUrl]="filtro.data"
                [title]="filtro.name"
                [id]="filtro.id"
                (selectSelected)="checkValueSelects($event)"
                (select)="restarValue($event, i, filtro.key)"
            ></app-filtro-select>
        </form>
    </div>
    <div class="buttonsFilter">
        <button class="delete" (click)="clearFilters()" (mouseup)="hideSelect()" mat-button>
            {{ 'PAGE.ACTIONS.DELETE' | translate }} <app-base-icon iconName="filter_alt_off" class=""></app-base-icon>
        </button>
        <button class="search" mat-flat-button color="warn" (click)="loadTable()">
            {{ 'PAGE.ACTIONS.FILTER' | translate | uppercase }}<app-base-icon iconName="search"> </app-base-icon>
        </button>
    </div>
    <hr />
</div>
