<div *ngIf="isPageEnabled">
    <app-select-informes-resultado
        (optionSelectedId)="onClickLoadTable($event)"
        (resetTable)="onClickClearTable($event)"
        (resetFilters)="onChangeResetFilters()"
    ></app-select-informes-resultado>
    <app-tabla-informes-resultado
        [dataBusiness]="dataBusiness"
        [filtersTable]="filtersTable"
        [dataHeaders]="dataHeaders"
        [columns]="columns"
        [totalPages]="totalPages"
        [pageSize]="pageSize"
        [numPage]="numPage"
        [totalRegisters]="totalRegisters"
        [permissions]="permissions"
        [filters]="filters"
        [idFilters]="idFilters"
        (sort)="sort($event, idFilters)"
        (pagination)="pagination($event)"
        (deletingReport)="deletingReport()"
    ></app-tabla-informes-resultado>
</div>
<app-not-implemented *ngIf="!isPageEnabled"></app-not-implemented>
