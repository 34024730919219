import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TablaMaestroComponent } from '../tabla-maestro.component';
import {
    FestivesToSend,
    PriceRegulatedToSend,
    ParametersToSend,
} from '../../../../../shared/interfaces/maestros-interface';
import { MaestrosAWS } from 'src/app/shared/enums/maestros';
import * as moment from 'moment';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'add-modal',
    templateUrl: './add-modal.component.html',
    styleUrls: ['./add-modal.component.scss'],
})
export class AddModalComponent {
    copyData: any;
    copyDataMapped: any;
    isPuntoMedida: boolean = false;
    isFestivos: boolean = false;
    isParametros: boolean = false;
    isPreciosRegulados: boolean = false;
    festivesToSend: FestivesToSend = { festiveDate: '', festiveDescription: '', festiveType: '', regionCode: '' };
    priceRegulatedToSend?: PriceRegulatedToSend;
    parametersToSend?: ParametersToSend;
    dateInit: Date;
    dateEnd: Date;
    code: boolean = false;
    rate: boolean = false;
    valueP1: boolean = false;
    valueP2: boolean = false;
    initDateForm: boolean = false;
    endDateForm: boolean = false;
    isAutonomic: boolean | undefined = undefined;
    diasDiferencia: number;
    isIncorrectDate: boolean = false;
    emptyRequired: boolean = false;

    constructor(
        public DialogAdd: MatDialogRef<TablaMaestroComponent>,
        protected readonly _headerTitleService: HeaderTitleService,
        private _translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.copyData = JSON.parse(JSON.stringify(data));
        this.copyDataMapped = this.copyData.header.map((data: any) => ({
            ...data,
            value:
                data.filter.toLowerCase() === 'date'
                    ? new Date(this.copyData.value[data.id])
                    : this.copyData.value[data.id],
            dataSelect: data.data,
        }));

        if (this.data.name === 'festivos') {
            //Quitamos la opción de eléctricos
            this.copyDataMapped.forEach((element: any) => {
                if (element.id === 'festiveType') {
                    let indexElectricity: number = element.data.findIndex((item: any) => item.name === 'Electricos');
                    if (indexElectricity >= 0) {
                        element.data.splice(indexElectricity, 1);
                    }
                }
            });

            this.isFestivos = true;
            this.festivesToSend = {
                festiveDate: this.copyData.value.festiveDate,
                festiveDescription: this.copyData.value.festiveDescription,
                festiveType: this.copyData.value.festiveType,
                regionCode: this.copyData.value.regionCode,
            };

            this.isAutonomic = false;
        }

        if (this.data.name === 'preciosRegulados') {
            this.emptyRequired = true;
            this.isPreciosRegulados = true;

            this.priceRegulatedToSend = {
                rate: this.copyData.value.rate,
                code: this.copyData.value.code,
                initialDate: this.copyData.value.initialDate,
                endDate: this.copyData.value.endDate,
                valueP1: 0,
                valueP2: 0,
                valueP3: 0,
                valueP4: 0,
                valueP5: 0,
                valueP6: 0,
            };
        }

        if (this.data.name === 'parametros') {
            this.isParametros = true;
            this.parametersToSend = {
                code: this.copyData.value.code,
                description: this.copyData.value.description,
                value: this.copyData.value.area,
                area: this.copyData.value.area,
            };
        }
    }

    close(resp: boolean) {
        let newData;
        switch (this.data.name) {
            case 'festivos':
                if (this.festivesToSend.festiveType === 'Nacionales' && this.festivesToSend.regionCode) {
                    delete this.festivesToSend.regionCode;
                }
                newData = { resp: resp, data: this.festivesToSend, festiveId: this.copyData.value.festiveId };
                break;
            case 'preciosRegulados':
                newData = { resp: resp, data: this.priceRegulatedToSend };
                break;
            case 'parametros':
                newData = { resp: resp, data: this.parametersToSend };
                break;
            default:
                break;
        }
        this.DialogAdd.close(newData);
    }

    valueChange(event: any) {
        this.isIncorrectDate = false;
        if (this.data.name === MaestrosAWS.PUNTO_FRONTERA || this.data.name === MaestrosAWS.PRECIOS_REGULADOS) {
            if (event.name === 'initValidityDate' || event.name === 'initialDate') {
                this.dateInit = event.newValue;
            } else if (event.name === 'finalValidityDate' || event.name === 'endDate') {
                this.dateEnd = event.newValue;
            }
            if (this.dateInit !== undefined && this.dateEnd !== undefined) {
                let fecha1 = moment(this.dateInit);
                let fecha2 = moment(this.dateEnd);
                const differenceDaysMessage = this._translateService.instant(
                    'PAGE.MASTER.MASTER_AWS.HOLIDAYS.DAYS_DIFFERENCE'
                );

                (this.diasDiferencia = fecha2.diff(fecha1, 'days')), ` ${differenceDaysMessage}`;
                if (this.diasDiferencia < 0) {
                    this.isIncorrectDate = true;
                } else {
                    this.isIncorrectDate = false;
                }
            }
        }
        if (this.data.name === MaestrosAWS.PUNTO_FRONTERA) {
            if (this.isIncorrectDate === false) {
                this.emptyRequired = false;
            } else {
                this.emptyRequired = true;
            }
        }
        if (this.data.name === MaestrosAWS.PRECIOS_REGULADOS) {
            switch (event.name) {
                case 'initialDate':
                    event.newValue !== undefined || null ? (this.initDateForm = true) : (this.initDateForm = false);
                    break;
                case 'endDate':
                    event.newValue !== undefined || null ? (this.endDateForm = true) : (this.endDateForm = false);
                    break;
                case 'code':
                    event.newValue !== '' ? (this.code = true) : (this.code = false);
                    break;
                case 'rate':
                    event.newValue !== '' ? (this.rate = true) : (this.rate = false);
                    break;
                case 'valueP1':
                    event.newValue !== '' || null ? (this.valueP1 = true) : (this.valueP1 = false);
                    break;
                case 'valueP2':
                    event.newValue !== '' || null ? (this.valueP2 = true) : (this.valueP2 = false);
                    break;
            }
            if (
                this.initDateForm &&
                this.endDateForm &&
                this.code &&
                this.rate &&
                this.valueP1 &&
                this.valueP2 &&
                !this.isIncorrectDate
            ) {
                this.emptyRequired = false;
            } else {
                this.emptyRequired = true;
            }
        }
        let eventValue = event.newValue || event.eventValue || event.value;
        if (event.name !== undefined) {
            switch (this.data.name) {
                case MaestrosAWS.FESTIVOS:
                    if (event.name === 'festiveType' && event.newValue === 'Autonomicos') {
                        this.isAutonomic = true;
                    } else if (event.name === 'festiveType' && event.newValue !== 'Autonomicos') {
                        this.isAutonomic = false;
                    }
                    (this.festivesToSend as any)[event.name] = eventValue;
                    break;
                case MaestrosAWS.PRECIOS_REGULADOS:
                    (this.priceRegulatedToSend as any)[event.name] = eventValue;
                    break;
                case MaestrosAWS.PARAMETROS:
                    (this.parametersToSend as any)[event.name] = eventValue;
                    break;
            }
        }
    }
}
