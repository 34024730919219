import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, AbstractControl, FormGroup } from '@angular/forms';

export interface TimePickerModel {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

function* range(start: number, end: number) {
    for (let i = start; i <= end; i++) {
        yield i;
    }
}

@Component({
    selector: 'app-custom-cron-time-picker',
    templateUrl: './custom-cron-time-picker.component.html',
    styleUrls: ['./custom-cron-time-picker.component.scss'],
})
export class CustomCronTimePickerComponent {
    @Input() public disabled: any;
    @Input() public use24HourTime = true;
    @Input() public hideHours = false;
    @Input() public hideMinutes = false;
    @Output() onTimeChange: EventEmitter<string> = new EventEmitter();

    public minutes = [...range(0, 59)];
    public hourTypes = ['AM', 'PM'];

    get hours(): number[] {
        return this.use24HourTime ? [...range(0, 23)] : [...range(0, 12)];
    }

    getFormGroup(control: AbstractControl<any, any> | null) {
        return control as FormGroup;
    }

    constructor(public parent: ControlContainer) {}

    protected onValueChanged(value: any) {
        this.onTimeChange.emit(value);
    }
}
