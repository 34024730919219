import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ItemMenu } from '../../interfaces/itemMenu.interface';

@Component({
    selector: 'app-sidebar-menu-item',
    templateUrl: './sidebar-menu-item.component.html',
    styleUrls: ['./sidebar-menu-item.component.scss'],
})
export class SidebarMenuItemComponent implements OnInit {
    @Input() item: ItemMenu | undefined = undefined;
    @Input() hierarchicalLevel: number = 0;
    @Input() opened: boolean;

    hasChildren: boolean = false;
    isOpen: boolean = false;

    constructor(private _router: Router) {}

    ngOnInit(): void {
        this.hasChildren = false;
        if (this.item && this.item.subItems.length) {
            this.hasChildren = true;
        }
    }

    openCloseSubItems() {
        this.isOpen = !this.isOpen;
    }

    closeSubItem() {
        this.isOpen = false;
    }

    navigate(): void {
        this._router.navigate([this.item?.route]);
    }
}
